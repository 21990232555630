import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { changePageTransaction } from '../../../../../../../store/Routing/transactionRouting';
import { useDispatch, useSelector } from 'react-redux';
import Subsidiary from '../../../../../../../components/shared/Subsidiary';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import ChartPerfomanceStock from '../../../Stock/Component/Chart';
import TableTransactionMobile from '../Component/TableTransaction';
import dayjs from 'dayjs';
import {
  AddIcon,
  ExportIcon,
  FilterAltIcon,
  SearchIcon,
} from '../../../../../../../assets/Icons';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import debounce from 'lodash.debounce';
import { useRef } from 'react';
import {
  getAllNewDataTransaction,
  getDataFilterFrom,
  getDataFilterTo,
  getTransactionRequest,
  getTransactionRequestIsLoading,
  getTransactionRequestTotalItem,
} from '../../../../../../../store/transaction/request/dataTableRequest';
import { useEffect } from 'react';
import {
  changeIdTransactionRequest,
  changeUserEmployee,
} from '../../../../../../../store/transaction/request/detailTransactionRequest';
import DialogFilterRequestTransactionMobile from './DialogFilter';
import InfiniteScroll from 'react-infinite-scroll-component';

const RequestTransactionMobile = () => {
  const dispatch = useDispatch();
  const [isFilter, setIsFilter] = useState(false);
  const isLoading = useSelector(getTransactionRequestIsLoading);
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');
  const datas = useSelector(getTransactionRequest);
  const allDatas = useSelector(getTransactionRequestTotalItem);
  const [datasToView, setDataToView] = useState(datas);
  const from = useSelector(getDataFilterFrom);
  const to = useSelector(getDataFilterTo);
  const [pages, setPages] = useState(1);
  const [notif, setNotif] = useState('Loading');

  useEffect(() => {
    setTimeout(() => {
      const mergedData = datas.filter(
        (item) => !datasToView.some((data) => data.idOrders === item.idOrders)
      ).length;
      if (mergedData.length !== 0) {
        setDataToView(datasToView.concat(datas));
      }
    }, 1000);
    // setTimeout(() => {
    //   setDataToView(datasToView.concat(datas));
    // }, 1000);
  }, [datas]);

  //! search

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  // ! --------- Subsidiary ---------
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);

  // ! grafik & table

  const [timeFrame, setTimeFrame] = useState('daily');

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  //!  ----------------------------- Toolbabr ----------------------------
  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row-reverse',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* addd */}
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '20px',
          height: '20px',
          bgcolor: '#51B15C',
          color: '#ffffff',
          borderRadius: '4px',
          '&:hover': {
            bgcolor: '#51B15C',
            color: '#ffffff',
          },
          fontSize: '0.875rem',
          display: 'flex',
          justifyContent: 'center',
        }}
        // startIcon={}
        onClick={() => {
          handleAddRequest();
        }}
      >
        <AddIcon sx={{ width: '14px', color: '#FFFFFF' }} />
      </CustomButton>
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <ExportIcon />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ width: '14px' }} />
      </CustomButton>
      <DialogFilterRequestTransactionMobile {...{ isFilter, setIsFilter }} />
    </Box>
  );

  // ! ----------------------------- API ----------------
  useEffect(() => {
    let datas = {};
    datas.pageSize = 4;
    datas.page = pages;
    datas.search = purchaseKeyword;
    datas.from = from;
    datas.to = to;
    datas.status = ['baru'];
    dispatch(getAllNewDataTransaction(datas));
  }, [purchaseKeyword, pages, from, to]);

  // !----------------- Handler --------------------------

  const handleAddRequest = () => {
    dispatch(changePageTransaction('addRequest'));
  };
  const handleBackButton = () => {
    dispatch(changePageTransaction(''));
  };

  const fetchMoreData = () => {
    if (datasToView.length < allDatas) {
      setPages(pages + 1);
    } else {
      setNotif('No more datas');
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <BackButtonHub onClick={handleBackButton} />
        <Typography
          sx={{
            color: '#000000',
            fontSize: '16px',
            fontWeight: 600,
          }}
        >
          Stock Request
        </Typography>
      </Box>

      <Grid container columns={12} spacing={3}>
        {/* subsidiary */}
        <Grid item xs={12}>
          <Subsidiary
            subsidiaryChecked={filterSubsidiaryArray}
            setSubsidiaryChecked={setFilterSubsidiaryArray}
            pad={1}
          />
        </Grid>
      </Grid>

      <CustomCard sx={{ mt: 2, p: 2 }}>
        <Grid container columns={12} spacing={3}>
          <Grid item xs={12}>
            <ChartPerfomanceStock
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TableTransactionMobile />
          </Grid>

          <Grid item xs={12}>
            <CustomCard sx={{ p: '10px 10px' }}>
              <Grid container columns={12} spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      display: 'inline-flex',
                      fontSize: '12px',
                      fontWeight: 600,
                    }}
                  >
                    Request List
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    placeholder={'Search'}
                    size="small"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      debounceOnChange(e.target.value);
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingLeft: '6px',
                        fontSize: '7px',
                      },
                      bgcolor: '#FAFAFA',
                      minHeight: '22px',
                      //   height: '22px',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ width: '12px' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  {toolbar}
                </Grid>

                {/* mapping data */}
                {isLoading && datasToView.length === 0 ? (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : datas.length === 0 ? (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        fontSize: '8px',
                        display: 'flex',
                        color: '#F33A3A',
                        justifyContent: 'center',
                      }}
                    >
                      No Request Purchase
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Box
                      id="scrollableDiv"
                      sx={{
                        height: '336px',
                        overflow: 'auto',
                      }}
                    >
                      <InfiniteScroll
                        dataLength={datasToView.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={
                          notif === 'Loading' ? (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <CircularProgress size={12} />
                              <Typography
                                sx={{
                                  padding: '16px 0px',
                                  fontSize: '12px',
                                  fontWeight: 600,
                                  color: '#000',
                                }}
                              >
                                {notif} ...
                              </Typography>
                            </Box>
                          ) : (
                            <Typography
                              sx={{
                                padding: '16px 0px',
                                fontSize: '12px',
                                fontWeight: 600,
                                color: '#A33A3A',
                              }}
                            >
                              {notif} ...
                            </Typography>
                          )
                        }
                        scrollableTarget="scrollableDiv"
                      >
                        <Grid container columns={12} spacing={2}>
                          {datasToView?.map((item, idx) => (
                            <Grid item xs={6} key={idx}>
                              <Box
                                sx={{
                                  background: '#FAFAFA',
                                  borderRadius: '8px',
                                  p: '10px',
                                }}
                                onClick={() => {
                                  dispatch(
                                    changePageTransaction(
                                      'detailRequestTransaction'
                                    )
                                  );
                                  dispatch(
                                    changeIdTransactionRequest(item?.idOrders)
                                  );
                                  dispatch(
                                    changeUserEmployee(item?.userEmploye)
                                  );
                                }}
                              >
                                <Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontSize: '10px',
                                          fontWeight: 600,
                                        }}
                                      >
                                        Req-
                                        {item?.idOrders !== ''
                                          ? item?.idOrders
                                          : '-'}{' '}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontSize: '8px',
                                        fontWeight: 500,
                                        mt: 1,
                                      }}
                                    >
                                      {item?.tglOrder} / {item?.jamOrder}
                                    </Typography>
                                  </Box>
                                </Box>

                                {/* details product */}
                                {item?.details?.map((detailProduct, index) => (
                                  <Box
                                    sx={{
                                      mt: 2,
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                    }}
                                    key={index}
                                  >
                                    <Box
                                      component="img"
                                      src={
                                        process.env
                                          .REACT_APP_API_IMAGE_BASE_URL +
                                        '/' +
                                        detailProduct?.product?.gambar
                                      }
                                      sx={{
                                        width: '34px',
                                        height: '34px',
                                        borderRadius: '5px',
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        justifyContent: 'center',
                                        gap: 1,
                                        height: '34px',
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '10px' }}>
                                        {detailProduct?.product?.namaProduk}
                                      </Typography>
                                      <Typography sx={{ fontSize: '8px' }}>
                                        + {detailProduct?.jumlah}{' '}
                                        {detailProduct?.unit}
                                      </Typography>
                                    </Box>
                                  </Box>
                                ))}

                                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      flexFlow: 'column',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        gap: 1,
                                        height: '24px',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexFlow: 'column',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Box sx={{ height: '8px' }}></Box>
                                        <Box
                                          sx={{
                                            width: '8px',
                                            height: '8px',
                                            background: '#D9D9D9',
                                            borderRadius: '50%',
                                          }}
                                        ></Box>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              width: '1px',
                                              height: '8px',
                                              background: '#D9D9D9',
                                            }}
                                          ></Box>
                                        </Box>
                                      </Box>
                                      <Typography sx={{ fontSize: '8px' }}>
                                        {item?.namaPemberi}
                                      </Typography>
                                    </Box>

                                    <Box
                                      sx={{
                                        display: 'flex',
                                        gap: 1,
                                        height: '24px',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexFlow: 'column',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              width: '1px',
                                              height: '8px',
                                              background: '#D9D9D9',
                                            }}
                                          ></Box>
                                        </Box>
                                        <Box
                                          sx={{
                                            width: '8px',
                                            height: '8px',
                                            background: '#51B15C',
                                            borderRadius: '50%',
                                          }}
                                        ></Box>
                                        <Box sx={{ height: '8px' }}></Box>
                                      </Box>
                                      <Typography sx={{ fontSize: '8px' }}>
                                        {item?.namaTujuan}
                                      </Typography>
                                    </Box>
                                    {/* <Box>
                              <Box></Box>
                              <Typography sx={{ fontSize: '8px' }}>
                                outlet
                              </Typography>
                            </Box> */}
                                  </Box>

                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {/* <SkuIcon /> */}
                                    <Typography
                                      sx={{ fontSize: '8px', ml: '5px' }}
                                    >
                                      {/* {item?.sku} */}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </InfiniteScroll>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CustomCard>
          </Grid>
        </Grid>
      </CustomCard>
    </>
  );
};

export default RequestTransactionMobile;
