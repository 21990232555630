import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { ChevronRightIcon } from '../../../../../../assets/Icons';
import BackButton from '../../../../../../components/shared/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePage,
  getAllPurchaseRouting,
} from '../../../../../../store/Routing/purchaseRouting';
import FinanceBackButton from './FinanceBackButton';
import LineDiv from '../../../../../../components/shared/LineDiv';
import CustomCard from '../../../../../../components/shared/CustomCard';
import BottomMenu from '../../../../../../components/shared/BottomMenu';
import { useEffect, useState } from 'react';
import {
  FillText,
  LoadingComponent,
} from '../../../../../../components/shared/shared2';
import { getPurchaseRequestDetail } from '../../../../../../api/purchase';
import { goToPurchaseAdd } from '../../../../../../store/purchase/purchaseFinance/purchaseAddFinance';

const RequestDetailFinance = () => {
  const dispatch = useDispatch();
  const { idRequest: id } = useSelector(getAllPurchaseRouting);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [datas, setDatas] = useState(null);

  const fetchApi = async (id) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getPurchaseRequestDetail(id);
      setDatas(data);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApi(id);
  }, [id]);

  const handlePurchase = () => {
    dispatch(
      goToPurchaseAdd({
        description: datas?.keterangan || '',
        products: datas?.details || [],
        idRequest: id,
      })
    );
    dispatch(changePage('purchaseAddFinance'));
  };

  const desktopMode = useMediaQuery('(min-width:832px)');

  return (
    <Box sx={{ mt: '16px' }}>
      {!isLoading && !isError && (
        <>
          {desktopMode && (
            <>
              <FinanceBackButton />
              <Grid
                container
                columns={1}
                rowSpacing={4}
                sx={{ mt: '16px', pb: '54px' }}
              >
                <Grid item xs={1}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      mb: '12px',
                    }}
                  >
                    Description
                  </Typography>
                  <LineDiv value={datas?.keterangan || '-'} />
                </Grid>
                <Grid item xs={1}>
                  <CustomCard>
                    <CustomCard sx={{ p: '20px' }}>
                      <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                        Item(s)
                      </Typography>
                    </CustomCard>
                    <Grid
                      container
                      columns={1}
                      rowSpacing={4}
                      sx={{ p: '20px' }}
                    >
                      {datas?.details.map((list, index) => {
                        return (
                          <Grid item xs={1} key={index}>
                            <Grid container columns={2} columnSpacing={4}>
                              <Grid item xs={1}>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    mb: '12px',
                                  }}
                                >
                                  Product
                                </Typography>
                                <LineDiv
                                  value={list?.product?.namaProduk || '-'}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <Box
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    mb: '12px',
                                    display: 'flex',
                                    gap: 0.5,
                                  }}
                                >
                                  <Typography>Qty </Typography>
                                  <Typography sx={{ color: '#51B15C' }}>
                                    (Pcs)
                                  </Typography>
                                </Box>
                                <LineDiv value={list?.qty || '-'} />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </CustomCard>
                </Grid>
              </Grid>
              <BottomMenu
                isGreen={true}
                greenText="Purchase"
                handleGreen={handlePurchase}
              />
            </>
          )}
          {!desktopMode && (
            <Box sx={{ minHeight: '100vh', bgcolor: 'white' }}>
              <FinanceBackButton />
              <Grid
                container
                columns={1}
                rowSpacing={4}
                sx={{ mt: '8px', pb: '54px' }}
              >
                <Grid item xs={1}>
                  <FillText
                    value={datas?.keterangan || '-'}
                    title={'Description*'}
                  />
                </Grid>
                <Grid item xs={1}>
                  <CustomCard>
                    <CustomCard sx={{ p: '16px' }}>
                      <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                        Item(s)
                      </Typography>
                    </CustomCard>
                    <Grid
                      container
                      columns={1}
                      rowSpacing={4}
                      sx={{ p: '20px 16px' }}
                    >
                      {datas?.details.map((list, index) => {
                        return (
                          <Grid item xs={1} key={index}>
                            <Grid container columns={5} columnSpacing={4}>
                              <Grid item xs={3}>
                                <FillText
                                  title={'Product'}
                                  value={list?.product?.namaProduk || '-'}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <FillText
                                  title={'Qty'}
                                  value={list?.qty || '-'}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </CustomCard>
                </Grid>
              </Grid>
              <BottomMenu
                isGreen={true}
                greenText="Purchase"
                handleGreen={handlePurchase}
              />
            </Box>
          )}
        </>
      )}
      {isLoading && <LoadingComponent />}
    </Box>
  );
};
export default RequestDetailFinance;
