import React from 'react';
import { Box, Typography } from '@mui/material';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckIcon from '@mui/icons-material/Check';

const RoadMap = ({
  isCreateGridOpen,
  setIsCreateGridOpen,
  isSelectRacksOpen,
  setIsSelectRacksOpen,
  isPreviewOpen,
  setIsPreviewOpen,
  numberOfColumns,
  numberOfRows,
}) => {
  return (
    <Box>
      <Box
        sx={{ display: 'flex', gap: 1, alignItems: 'center', width: '100%' }}
      >
        <CustomCard
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            alignItems: 'center',
            p: 2,
            width: '30%',
            cursor: 'pointer',
            border: isCreateGridOpen && '1px solid #51B15C',
            bgcolor:
              isCreateGridOpen === false &&
              numberOfColumns &&
              numberOfRows &&
              'rgba(81, 177, 92, 0.1)',
          }}
          // onClick={() => {
          //   setIsCreateGridOpen(true);
          //   setIsSelectRacksOpen(false);
          //   setIsPreviewOpen(false);
          // }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography
              sx={{
                color: 'primary.black',
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >
              Create Grid
            </Typography>
            <Typography sx={{ fontSize: '0.75rem', fontWeight: 500 }}>
              Enter number of column and rows
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor:
                isCreateGridOpen === false && numberOfColumns && numberOfRows
                  ? '#51B15C'
                  : '#F5F5F5',
              borderRadius: '50%',
              height: '30px',
              width: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isCreateGridOpen === false && numberOfColumns && numberOfRows ? (
              <CheckIcon sx={{ color: 'primary.contrastText' }} />
            ) : (
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 600,
                  color: 'primary.black',
                }}
              >
                1
              </Typography>
            )}
          </Box>
        </CustomCard>

        <KeyboardArrowRightIcon sx={{ color: '#323232', width: '5%' }} />

        <CustomCard
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            alignItems: 'center',
            p: 2,
            width: '30%',
            cursor: 'pointer',
            border: isSelectRacksOpen && '1px solid #51B15C',
          }}
          // onClick={() => {
          //   setIsCreateGridOpen(false);
          //   setIsSelectRacksOpen(true);
          //   setIsPreviewOpen(false);
          // }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography
              sx={{
                color: 'primary.black',
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >
              Select Racks
            </Typography>
            <Typography sx={{ fontSize: '0.75rem', fontWeight: 500 }}>
              Drag the area and enter the column
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: '#F5F5F5',
              borderRadius: '50%',
              height: '30px',
              width: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.75rem',
                fontWeight: 600,
                color: 'primary.black',
              }}
            >
              2
            </Typography>
          </Box>
        </CustomCard>

        <KeyboardArrowRightIcon sx={{ color: '#323232', width: '5%' }} />

        <CustomCard
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            alignItems: 'center',
            p: 2,
            width: '30%',
            cursor: 'pointer',
            border: isPreviewOpen && '1px solid #51B15C',
          }}
          // onClick={() => {
          //   setIsCreateGridOpen(false);
          //   setIsSelectRacksOpen(false);
          //   setIsPreviewOpen(true);
          // }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography
              sx={{
                color: 'primary.black',
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >
              Preview
            </Typography>
            <Typography sx={{ fontSize: '0.75rem', fontWeight: 500 }}>
              Preview map and finish
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: '#F5F5F5',
              borderRadius: '50%',
              height: '30px',
              width: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.75rem',
                fontWeight: 600,
                color: 'primary.black',
              }}
            >
              3
            </Typography>
          </Box>
        </CustomCard>
      </Box>
    </Box>
  );
};

export default RoadMap;
