import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import BackButton from '../../../../../../../../../components/shared/BackButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePageMasterHubRack,
  getAllMasterHubState,
  inputIdMasterRack,
} from '../../../../../../../../../store/racks/masterHubRack/masterHubRack';
import { useEffect, useState } from 'react';
import { getRackDetail } from '../../../../../../../../../api/rack';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ListAlphabetExcelFormater from '../../../../../../../../../hooks/ListAlphabetExcelFormater';
import CustomCard from '../../../../../../../../../components/shared/CustomCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LineDiv from '../../../../../../../../../components/shared/LineDiv';
import PhotoView from '../../../../../../../../../components/shared/PhotoView';
import VideoView from '../../../../../../../../../components/shared/VideoView';
import BottomMenu from '../../../../../../../../../components/shared/BottomMenu';
import EditMasterHubRack from '../Edit/EditMasterHubRack';
import Racks from '../Components/Racks';
import { editRack } from '../../../../../../../../../store/racks/masterHubRack/masterHubRackEdit';
const DetailMasterHubRack = () => {
  const dispatch = useDispatch();
  const {
    id: idRack,
    cell,
    rackSelected: rackSelectedPack,
    page,
  } = useSelector(getAllMasterHubState);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [datas, setDatas] = useState({});
  const [rackSelected, setRackSelected] = useState([]);
  const [isZoom, setIsZoom] = useState(false);

  const handleBackButton = () => {
    dispatch(inputIdMasterRack(0));
  };

  const [cellSelectedAll, setCellSelectedAll] = useState([]);

  const fetchApi = async (id) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getRackDetail(id);

      setRackSelected(data.columnPosition.split(','));
      setDatas(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setDatas(null);
      setIsError(true);
    }
  };

  useEffect(() => {
    fetchApi(idRack);
  }, [idRack]);

  useEffect(() => {
    let collection = rackSelectedPack.flat();
    let collectionNew = collection.filter(
      (item) => !rackSelected.includes(item)
    );
    setCellSelectedAll(collectionNew);
  }, [rackSelectedPack, rackSelected]);

  const color = {
    selected: '#51B15C',
    contained: '#FEDD25',
    full: '#F33A3A',
    empty: '#FFFFFF',
  };

  const handleEdit = () => {
    dispatch(changePageMasterHubRack('edit'));
    const dataForEdit = {
      rackSelected: rackSelected,
      name: datas.name || '',
      width: datas.width || 0,
      length: datas.length || 0,
      height: datas.height || 0,
      weight: datas.weight || 0,
      photo: datas.images ? datas.images.split(',') : [],
      video: datas.videos ? datas.videos.split(',') : [],
      description: datas.description || '',
      row: datas.row,
      column: datas.column,
    };
    dispatch(editRack(dataForEdit));
  };

  return (
    <>
      {page === '' && (
        <>
          {!isLoading && !isError && (
            <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
              {/* //* BackButton */}
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                <BackButton handleClick={handleBackButton} />

                <Box>
                  <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                    Cekk Details
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      color: '#9E9D9D',

                      alignItems: 'center',
                      mt: '4px',
                      gap: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      cekk
                    </Typography>
                    <ChevronRightIcon sx={{ fontSize: '16px' }} />
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      cekk
                    </Typography>
                    <ChevronRightIcon sx={{ fontSize: '16px' }} />
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      cekk
                    </Typography>
                    {/* {hirarkiDetails?.miniSubCategory !== '' && (
                    <>
                      <ChevronRightIcon sx={{ fontSize: '16px' }} />
                      <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                        {hirarkiDetails?.miniSubCategory}
                      </Typography>
                    </>
                  )} */}
                  </Box>
                </Box>
              </Grid>
              {/* //* Body */}
              <Grid item xs={12} container columns={12} columnSpacing={4}>
                <Grid
                  item
                  xs={5}
                  sx={{
                    bgcolor: '#FAFAFA',
                    borderRadius: '12px',
                    px: '12px',
                    py: '16px',
                  }}
                >
                  {/* <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: '#E4E4E4',
                        width: '26px',
                        height: '26px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        borderRadius: '8px',
                      }}
                      onClick={() => setIsZoom(!isZoom)}
                    >
                      {!isZoom ? <ZoomInIcon /> : <ZoomOutIcon />}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {!isZoom ? (
                      <Box>
                        {cell.map((row, rowIndex) => (
                          <Box sx={{ display: 'flex' }} key={rowIndex}>
                            {row.map((col, colIndex) => (
                              <Box
                                // onClick={() => dispatch(seeDetailRack())}
                                // onMouseOver={() => {
                                //   if (cellSet.flat().includes(col)) {
                                //     let getPos = cellSelectedDetails.find((value) =>
                                //       value.columnPosition.split(',').includes(col)
                                //     );
                                //     setCellSelected(getPos.columnPosition.split(','));
                                //   } else if (cellSelectedNew.flat().includes(col)) {
                                //     let getPos = cellSelectedDetailsNew.find((value) =>
                                //       value.columnPosition.split(',').includes(col)
                                //     );
                                //     setCellSelected(getPos.columnPosition.split(','));
                                //   }
                                // }}
                                // onMouseOut={() => setCellSelected([])}
                                sx={[
                                  {
                                    width: '36px',
                                    height: '36px',
                                  },
                                  cellSelectedAll.includes(col) && {
                                    bgcolor: '#E4E4E4',
                                  },
                                  // entrance.includes(col) && {
                                  //   bgcolor: '#171717',
                                  // },
                                  rackSelected.includes(col) && {
                                    bgcolor: '#51B15C',
                                  },
                                ]}
                                key={colIndex}
                              ></Box>
                            ))}
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Box>
                        <Box>
                          <Box sx={{ py: '20px' }}>
                            {[...Array(datas.row)]
                              .map((row, rowIndex) => (
                                <Box
                                  sx={{ display: 'flex', maxHeight: '36px' }}
                                  key={rowIndex}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '40px',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography sx={{ color: '#EACD57' }}>
                                      {rowIndex + 1}
                                    </Typography>
                                  </Box>
                                  <Box key={rowIndex} sx={{ display: 'flex' }}>
                                    {[...Array(datas.column)].map(
                                      (_, colIndex) => (
                                        <Box
                                          key={colIndex}
                                          sx={[
                                            {
                                              minWidth: '36px',
                                              height: '36px',
                                              borderLeft: 2,
                                              borderBottom: 2,
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              fontSize: '12px',
                                            },
                                            colIndex === datas.column - 1 && {
                                              borderRight: 2,
                                            },
                                            rowIndex === datas.row - 1 && {
                                              borderTop: 2,
                                            },
                                          ]}
                                        ></Box>
                                      )
                                    )}
                                  </Box>
                                </Box>
                              ))
                              .reverse()}
                            <Box
                              sx={{ pl: '40px', display: 'flex', pt: '20px' }}
                            >
                              {[...Array(datas.column)].map((_, colIndex) => (
                                <Box
                                  sx={[
                                    {
                                      minWidth: '36px',
                                      minHeight: '36px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                    },
                                  ]}
                                  key={colIndex}
                                >
                                  <Typography sx={{ color: '#4339F2' }}>
                                    <ListAlphabetExcelFormater
                                      angka={colIndex}
                                    />
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            mt: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 0.5,
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '4px',
                                bgcolor: color.selected,
                              }}
                            ></Box>
                            <Typography sx={{ fontSize: '12px' }}>
                              Selected
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 0.5,
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '4px',
                                bgcolor: color.contained,
                              }}
                            ></Box>
                            <Typography sx={{ fontSize: '12px' }}>
                              Contained
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 0.5,
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '4px',
                                bgcolor: color.full,
                              }}
                            ></Box>
                            <Typography sx={{ fontSize: '12px' }}>
                              Full
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 0.5,
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '4px',
                                bgcolor: color.empty,
                                border: 1,
                                borderColor: 'black',
                              }}
                            ></Box>
                            <Typography sx={{ fontSize: '12px' }}>
                              Empty
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box> */}
                  <Racks
                    col={datas.column}
                    row={datas.row}
                    rackSelected={rackSelected}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Grid container columns={2} rowSpacing={2}>
                    {/* //* Code */}
                    <Grid item xs={2}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          mb: '4px',
                          mb: '12px',
                        }}
                      >
                        Code
                      </Typography>

                      <LineDiv value={datas.name} />
                    </Grid>
                    {/* //* Card */}
                    <Grid item xs={2}>
                      <CustomCard
                        sx={{
                          p: '16px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{}}>
                          <Typography>Size</Typography>
                          <Typography sx={{ mt: '4px' }}>
                            {datas?.width} - {datas?.height} - {datas?.length} -{' '}
                            {datas?.weight}
                          </Typography>
                        </Box>
                        <Box>
                          <KeyboardArrowDownIcon />
                        </Box>
                      </CustomCard>
                    </Grid>
                    {/* //* Preview Photo */}
                    <Grid item xs={2} container columns={2}>
                      <Grid item xs={1}>
                        <PhotoView data={[]} />
                      </Grid>
                      <Grid item xs={1}>
                        <VideoView data={[]} />
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        sx={{ fontSize: '16px', fontWeight: '500', mb: '12px' }}
                      >
                        Description
                      </Typography>
                      <LineDiv value={datas?.description} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <BottomMenu
                isPublish
                isGreen={true}
                greenText="Edit"
                isRed={true}
                redText="Delete"
                handleGreen={handleEdit}
              />
            </Grid>
          )}
          {isLoading && (
            <Box
              sx={{
                widht: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      )}
      {page === 'edit' && <EditMasterHubRack />}
    </>
  );
};
export default DetailMasterHubRack;
