import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePageAdjustment,
  setNotifAdjusment,
} from '../../../../../../../store/Routing/addjustment';
import {
  fetchDataDetailAdjustment,
  getDetailDataAdjustment,
  getIdDetailAdjustment,
  getIsLoadingAdjustment,
} from '../../../../../../../store/adjustment/detailAdjustment';
import { useEffect, useState } from 'react';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import BottomMenu from '../../../../../../../components/shared/BottomMenu';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import EditAdjustmentMobile from '../Edit/EditAdjustmentMobile';
import {
  changeEditNotesAdjustment,
  changeEditTypeAdjustment,
  changeEditlocationAdjustment,
  goToAdjustmentEdit,
  goToEditAdjustment,
} from '../../../../../../../store/adjustment/editAdjustment';
import CustomDialog from '../../../../../../../components/shared/CustomDialog';
import { CloseIcon, DeleteIcon } from '../../../../../../../assets/Icons';
import {
  acceptAdjustment,
  cancelAdjustment,
  deleteAdjustment,
} from '../../../../../../../api/adjustment';
import EditMissingQrAdjustmentMobile from '../Edit/EditMissingQrAdjustmentMobile';

const colorData = {
  cancle: '#F33A3A',
  accept: '#51B15C',
  pending: '#FD7900',
};

const background = {
  cancle: 'rgba(243, 58, 58, 0.1)',
  accept: 'rgba(81, 177, 92, 0.1)',
  pending: 'rgba(253, 121, 0, 0.1)',
};

const DetailAdjustmentMobile = () => {
  const dispatch = useDispatch();
  const idAdjustment = useSelector(getIdDetailAdjustment);
  const datas = useSelector(getDetailDataAdjustment);
  const isLoading = useSelector(getIsLoadingAdjustment);
  const role = localStorage.role;

  // ! ------------------------- API --------------------
  useEffect(() => {
    dispatch(fetchDataDetailAdjustment(idAdjustment));
  }, [idAdjustment]);

  // ! ----------------------- Validation -----------------
  const [isDisableDelete, setIsDisableDelete] = useState(false);
  const [isDisableEdit, setIsDisableEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isFilterCancel, setIsFilterCancel] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isOpenAccept, setIsOpenAccept] = useState(false);
  const [isLoadingAccepted, setIsLoadingAccepted] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  // ! ------------------------- Handler -----------------
  const handleEdit = () => {
    setIsEdit(true);
    dispatch(goToEditAdjustment(datas?.detail));
    dispatch(goToAdjustmentEdit(datas));
    dispatch(changeEditlocationAdjustment(datas?.location));
    dispatch(changeEditNotesAdjustment(datas?.notes));
    dispatch(changeEditTypeAdjustment(datas?.type));
  };
  const handleDelete = async () => {
    setIsLoadingDelete(true);
    try {
      await deleteAdjustment(idAdjustment);
      setIsLoadingDelete(false);
      setIsDelete(false);
      dispatch(changePageAdjustment(''));
      dispatch(
        setNotifAdjusment({
          key: 'deleteAdjustment',
          text: 'Adjustment has been deleted',
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleAccept = async () => {
    setIsLoadingAccepted(true);
    try {
      await acceptAdjustment(idAdjustment);
      setIsLoadingAccepted(false);
      setIsOpenAccept(false);
      dispatch(changePageAdjustment(''));
      dispatch(
        setNotifAdjusment({
          key: 'editAdjustment',
          text: 'Adjustment has been accepted',
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingAccepted(false);
    }
  };

  const handleCancel = async () => {
    setIsLoadingCancel(true);
    try {
      await cancelAdjustment(idAdjustment);
      setIsLoadingCancel(false);
      setIsFilterCancel(false);
      dispatch(changePageAdjustment(''));
      dispatch(
        setNotifAdjusment({
          key: 'editAdjustment',
          text: 'Adjustment has been canceled',
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingCancel(false);
    }
  };

  return isEdit ? (
    datas?.type === 'difference' ? (
      <EditAdjustmentMobile setIsEdit={setIsEdit} />
    ) : (
      <EditMissingQrAdjustmentMobile setIsEdit={setIsEdit} />
    )
  ) : (
    <Box sx={{ minHeight: '84vh', position: 'relative' }}>
      <Grid container columns={12} spacing={3} sx={{ pb: '62px' }}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mt: 2 }}>
            <BackButtonHub onClick={() => dispatch(changePageAdjustment(''))} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#000' }}
              >
                {idAdjustment} Details
              </Typography>
              <Box
                sx={{
                  padding: '4px 10px',
                  background:
                    datas?.status === 'pending'
                      ? background.pending
                      : datas?.status === 'accepted'
                      ? background.accept
                      : datas?.status === 'cancelled'
                      ? background.cancle
                      : '',
                  borderRadius: '5px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '10px',
                    fontWeight: 600,
                    color:
                      datas?.status === 'pending'
                        ? colorData.pending
                        : datas?.status === 'accepted'
                        ? colorData.accept
                        : datas?.status === 'cancelled'
                        ? colorData.cancle
                        : '#000',
                  }}
                >
                  {datas?.status}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {/* location */}
            <Grid item xs={12}>
              <Box
                sx={{
                  background: '#FAFAFA',
                  borderRadius: '8px 8px 0px 0px',
                  padding: '8px 16px',
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 500, color: '#9E9E9E' }}
                >
                  Location
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#000',
                    mt: 1,
                  }}
                >
                  {datas?.location}
                </Typography>
              </Box>
            </Grid>

            {/* notes */}
            <Grid item xs={12}>
              <Box
                sx={{
                  background: '#FAFAFA',
                  borderRadius: '8px 8px 0px 0px',
                  padding: '8px 16px',
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 500, color: '#9E9E9E' }}
                >
                  Notes
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#000',
                    mt: 1,
                  }}
                >
                  {datas?.notes}
                </Typography>
              </Box>
            </Grid>

            {/* items */}
            <Grid item xs={12}>
              <CustomCard>
                <Box
                  sx={{
                    background: '#FFF',
                    padding: '16px',
                    borderRadius: '8px 8px 0px 0px',
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    mb: 1.5,
                  }}
                >
                  <Typography
                    sx={{ fontSize: '14px', fontWeight: 600, color: '#000' }}
                  >
                    Item(s)
                  </Typography>
                </Box>
                {/* detail produts */}
                {datas?.detail.map((item, idx) => (
                  <Box key={idx}>
                    <Box sx={{ padding: '14px 12px 14px 12px' }}>
                      {datas?.type === 'difference' ? (
                        <Box
                          sx={{
                            background: '#FAFAFA',
                            borderRadius: '8px 8px 0px 0px',
                            padding: '8px 16px',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: 500,
                              color: '#9E9E9E',
                            }}
                          >
                            Product
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              color: '#000',
                              mt: 1,
                            }}
                          >
                            {item.namaProduk || '-'}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'cener',
                              gap: 2,
                              mt: 2,
                            }}
                          >
                            <Box
                              sx={{
                                background: '#FAFAFA',
                                borderRadius: '8px 8px 0px 0px',
                                padding: '8px 16px',
                                width: '100%',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  color: '#9E9E9E',
                                }}
                              >
                                System
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  color: '#000',
                                  mt: 1,
                                }}
                              >
                                {item.system} {item.systemUnit}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                background: '#FAFAFA',
                                borderRadius: '8px 8px 0px 0px',
                                padding: '8px 16px',
                                width: '100%',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  color: '#9E9E9E',
                                }}
                              >
                                Actual
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  color: '#000',
                                  mt: 1,
                                }}
                              >
                                {item.actual} {item.actualUnit}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                background: '#FAFAFA',
                                borderRadius: '8px 8px 0px 0px',
                                padding: '8px 16px',
                                width: '100%',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  color: '#9E9E9E',
                                }}
                              >
                                Difference
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  color: '#000',
                                  mt: 1,
                                }}
                              >
                                {item.difference} {item.systemUnit}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 2,
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            sx={{
                              background: '#FAFAFA',
                              borderRadius: '8px 8px 0px 0px',
                              padding: '8px 16px',
                              width: '65%',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#9E9E9E',
                              }}
                            >
                              Product
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                color: '#000',
                                mt: 1,
                              }}
                            >
                              {item.namaProduk || '-'}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              background: '#FAFAFA',
                              borderRadius: '8px 8px 0px 0px',
                              padding: '8px 16px',
                              width: '35%',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#9E9E9E',
                              }}
                            >
                              Missing
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                color: '#000',
                                mt: 1,
                              }}
                            >
                              {item.system} {item.systemUnit}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    {idx + 1 < datas?.detail.length && (
                      <Box sx={{ padding: '0px 15px' }}>
                        <hr />
                      </Box>
                    )}
                  </Box>
                ))}
              </CustomCard>
            </Grid>
          </>
        )}
      </Grid>

      {/* modal/ popup cancel adjustment */}
      <CustomDialog
        isOpen={isFilterCancel}
        setIsOpen={setIsFilterCancel}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                background: 'rgba(243, 58, 58, 0.1)',
                borderRadius: '100px',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CloseIcon sx={{ fontSize: '1.25rem', color: '#F33A3A' }} />
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}
            >
              Cancel Adjustment
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, color: '#000', mt: 2 }}
          >
            Are you sure you want to cancel the adjustment ?
          </Typography>

          {/* button */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 3,
              marginTop: '56px',
            }}
          >
            <Typography
              onClick={() => setIsFilterCancel(false)}
              sx={{ fontSize: '14px', fontWeight: 600, color: '#9D9D9D' }}
            >
              Back
            </Typography>
            <CustomButton
              variant="contained"
              color={'error'}
              sx={{ width: '100px', height: '37px' }}
              onClick={handleCancel}
            >
              {isLoadingCancel ? (
                <CircularProgress size={20} style={{ color: '#FFF' }} />
              ) : (
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
                >
                  Cancel
                </Typography>
              )}
            </CustomButton>
          </Box>
        </DialogContent>
      </CustomDialog>

      {/* modal/ popup accept adjustment */}
      <CustomDialog
        isOpen={isOpenAccept}
        setIsOpen={setIsOpenAccept}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                background: 'rgba(243, 58, 58, 0.1)',
                borderRadius: '100px',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CloseIcon sx={{ fontSize: '1.25rem', color: '#F33A3A' }} />
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}
            >
              Accept Adjustment
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, color: '#000', mt: 2 }}
          >
            Are you sure you want to accept the adjustment ?
          </Typography>

          {/* button */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 3,
              marginTop: '56px',
            }}
          >
            <Typography
              onClick={() => setIsOpenAccept(false)}
              sx={{ fontSize: '14px', fontWeight: 600, color: '#9D9D9D' }}
            >
              Back
            </Typography>
            <CustomButton
              variant="contained"
              sx={{ width: '100px', height: '37px' }}
              onClick={handleAccept}
            >
              {isLoadingAccepted ? (
                <CircularProgress size={20} style={{ color: '#FFF' }} />
              ) : (
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
                >
                  Accept
                </Typography>
              )}
            </CustomButton>
          </Box>
        </DialogContent>
      </CustomDialog>

      {/* modal / popup delete adjustment */}
      <CustomDialog
        isOpen={isDelete}
        setIsOpen={setIsDelete}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                background: 'rgba(243, 58, 58, 0.1)',
                borderRadius: '100px',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DeleteIcon sx={{ fontSize: '1.25rem', color: '#F33A3A' }} />
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}
            >
              Delete Adjustment
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, color: '#000', mt: 2 }}
          >
            Are you sure you want to delete {idAdjustment} ?
          </Typography>

          {/* button */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 3,
              marginTop: '56px',
            }}
          >
            <Typography
              onClick={() => setIsDelete(false)}
              sx={{ fontSize: '14px', fontWeight: 600, color: '#9D9D9D' }}
            >
              Back
            </Typography>
            <CustomButton
              variant="contained"
              color={'error'}
              sx={{ width: '100px', height: '37px' }}
              onClick={handleDelete}
            >
              {isLoadingDelete ? (
                <CircularProgress size={20} style={{ color: '#FFF' }} />
              ) : (
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
                >
                  Delete
                </Typography>
              )}
            </CustomButton>
          </Box>
        </DialogContent>
      </CustomDialog>

      {/* Button edit & detail */}

      {role == 2 || role === 3 ? (
        datas?.status === 'pending' && (
          <Box
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: 0,
              display: 'flex',
              gap: 2,
            }}
          >
            <CustomButton
              variant="contained"
              color={'error'}
              sx={{ width: '100%', height: '40px' }}
              onClick={() => setIsDelete(true)}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
              >
                Delete
              </Typography>
            </CustomButton>
            <CustomButton
              variant="contained"
              sx={{ width: '100%', height: '40px' }}
              onClick={handleEdit}
              disabled={isDisableEdit}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
              >
                Edit
              </Typography>
            </CustomButton>
          </Box>
        )
      ) : role == 4 ? (
        datas?.status === 'pending' && (
          <Box
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: 0,
              display: 'flex',
              gap: 2,
            }}
          >
            <CustomButton
              variant="contained"
              color={'error'}
              sx={{ width: '100%', height: '40px' }}
              onClick={() => setIsFilterCancel(true)}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
              >
                Cancel
              </Typography>
            </CustomButton>
            <CustomButton
              variant="contained"
              sx={{ width: '100%', height: '40px' }}
              onClick={() => setIsOpenAccept(true)}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
              >
                Accept
              </Typography>
            </CustomButton>
          </Box>
        )
      ) : (
        <></>
      )}
    </Box>
  );
};
export default DetailAdjustmentMobile;
