import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
  Autocomplete,
} from '@mui/material';
import CustomInput from '../../../../../../components/shared/CustomInput';
import BackButton from '../../../../../../components/shared/BackButton';
import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useRef, useState } from 'react';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddSubCategoryInCategory from './Desktop/AddSubCategoryInCategory';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CustomButton from '../../../../../../components/shared/CustomButton';

import {
  addNewCategoryProduct,
  addImageCategory,
  addNewSubCategoryProduct,
  allTypeCategoryProduct,
} from '../../../../../../api/products';
import CategoryProductAddMobile from './CategoryProductAddMobile';
import { uploadImage } from '../../../../../../api/storage';
import debounce from 'lodash.debounce';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const CategoryProductAdd = (props) => {
  const {
    isLoadingSubsidiary,
    subsidiaryDatas,
    // *---
    page,
    setPage,
    setIsCategoryProductAdd,
    setIsCategoryProductEdit,
    categoryAddInputDatas,
    setCategoryAddInputDatas,
    // *----- notif --------
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
  } = props;
  const today = dayjs(new Date());

  const theme = useTheme();
  const [sub1, setSub1] = useState({
    id: 1,
    name: '',
    photo: [],
    photoUrls: [],
    photoUrl: '',
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });

  const [sub2, setSub2] = useState({
    id: 2,
    name: '',
    photo: [],
    photoUrls: [],
    photoUrl: '',
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });

  const [sub3, setSub3] = useState({
    id: 3,
    name: '',
    photo: [],
    photoUrls: [],
    photoUrl: '',
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  const [indexSubCategory, setIndexSubCategory] = useState([]);

  const [isSubmitAvailable, setIsSubmitAvailable] = useState(true);
  // *--------- type category -------------
  const [typeCategoryOptions, setTypeCategoryOptions] = useState([]);

  useEffect(() => {
    const getAllTypeCategory = async () => {
      setCategoryAddInputDatas({ ...categoryAddInputDatas, typeLoading: true });
      let datas = {};
      datas.pageSize = 100;
      datas.page = 1;

      try {
        const {
          data: {
            data: { categoryOptions: dataType },
          },
        } = await allTypeCategoryProduct(datas);

        setTypeCategoryOptions(
          dataType.filter((item) => item.nmOpsi !== 'menu')
        );
      } catch (err) {
        console.error();
        setTypeCategoryOptions([]);
      } finally {
        setCategoryAddInputDatas({
          ...categoryAddInputDatas,
          typeLoading: false,
        });
      }
    };

    getAllTypeCategory();
  }, []);

  // *---------Validasi submit ----------
  useEffect(() => {
    if (
      categoryAddInputDatas.subsidiaryId &&
      categoryAddInputDatas.name.length >= 5
    ) {
      if (
        categoryAddInputDatas.description === '' ||
        categoryAddInputDatas.description.length >= 20
      ) {
        if (categoryAddInputDatas.subCategoryIndex.length > 0) {
          if (
            sub1.available === true &&
            sub2.available === true &&
            sub3.available === true
          ) {
            return setIsSubmitAvailable(true);
          } else {
            return setIsSubmitAvailable(false);
          }
        } else {
          return setIsSubmitAvailable(true);
        }
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [categoryAddInputDatas, sub1, sub2, sub3]);

  useEffect(() => {
    categoryAddInputDatas.subCategoryFormat
      .filter(
        (item) =>
          !categoryAddInputDatas.subCategoryIndex.includes(item) &&
          !indexSubCategory.includes(item)
      )
      .map((value) => {
        return setIndexSubCategory([...indexSubCategory, value]);
      });
  }, [categoryAddInputDatas, indexSubCategory, sub1, sub2, sub3]);

  // !---------------------- HANDLER -----------------------------------

  const handleAddCategory = () => {
    let hasil = {
      namaKategori: categoryAddInputDatas.name,
      status: `${categoryAddInputDatas.publish ? 'Y' : 'N'}`,
      subsidiaryId: parseInt(categoryAddInputDatas.subsidiaryId),
      opsiKategori: 1,
      schedule: dayjs(categoryAddInputDatas.schedule.$d).format('YYYY-MM-DD'),
    };
    if (categoryAddInputDatas.description !== '') {
      hasil['des'] = categoryAddInputDatas.description;
    }
    if (categoryAddInputDatas.photoUrls.length !== 0) {
      hasil['gambar'] = categoryAddInputDatas.photoUrls.join(',');
    }

    let subCategories = [{ ...sub1 }, { ...sub2 }, { ...sub3 }];

    addNewCategoryProduct(hasil)
      .then((result) => {
        subCategories
          .filter((subItem) =>
            categoryAddInputDatas.subCategoryIndex.includes(subItem.id)
          )
          .map((subItem) => {
            let sub = {
              idKategori: result.data.data.idKategori,
              subkategori: subItem.name,
              subStatus: 'Y',
              schedule: dayjs(subItem.schedule.$d).format('YYYY-MM-DD'),
            };
            if (subItem.description !== '') {
              sub.informasi = subItem.description;
            }
            if (subItem.photoUrls.length !== 0) {
              sub['gbrsub'] = subItem.photoUrls.join(',');
            }

            addNewSubCategoryProduct(sub)
              .then()
              .catch((err) => console.error());
          });
        setNotif('addcategory');
        setNotifTitle(
          `${result.data.data.namaKategori} has been successfully added`
        );
        setIsCategoryProductAdd(false);
        setPage('category');
      })
      .catch((err) => {
        console.error();
      });
  };

  const handleBackButton = () => {
    setPage('category');
    setIsCategoryProductAdd(false);
    setIsCategoryProductEdit(false);
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setCategoryAddInputDatas({
        ...categoryAddInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'category',
      };
      uploadImage(datas).then((res) => {
        setCategoryAddInputDatas({
          ...categoryAddInputDatas,
          photo: [...categoryAddInputDatas.photo, e.target.files[0]],
          photoUrls: [...categoryAddInputDatas.photoUrls, res.data.data.path],
          isMaxSizePhoto: false,
        });
      });
    }
  };

  const handleDeleteImage = (input, index) => {
    setCategoryAddInputDatas({
      ...categoryAddInputDatas,
      photo: categoryAddInputDatas.photo.filter((data) => data !== input),
      photoUrls: categoryAddInputDatas.photoUrls.filter(
        (data, idx) => idx !== index
      ),
      indexPreviePhoto: 0,
    });
  };

  return (
    <>
      {desktopMode ? (
        <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
              Add Category
            </Typography>
          </Grid>
          {/* Subsidiary */}
          <Grid item xs={12}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Subsidiary*
            </Typography>
            <FormControl>
              <RadioGroup
                row
                value={categoryAddInputDatas.subsidiaryId}
                onChange={(e) => {
                  setCategoryAddInputDatas({
                    ...categoryAddInputDatas,
                    subsidiaryId: e.target.value,
                  });
                }}
              >
                {!isLoadingSubsidiary && (
                  <>
                    {subsidiaryDatas.map((data, index) => (
                      <FormControlLabel
                        value={data.id}
                        control={<Radio />}
                        label={data.name}
                        key={index}
                      />
                    ))}
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* Name */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Name*
            </Typography>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter category name"
              value={categoryAddInputDatas.name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setCategoryAddInputDatas({
                    ...categoryAddInputDatas,
                    name: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 5 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {categoryAddInputDatas.name.length}/30
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={6} sx={{ minHeight: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pb: `${
                  categoryAddInputDatas.photo.length === 0 ? '16px' : '9px'
                }`,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Photo
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#9E9D9D',
                }}
              >
                ( max size : 2MB )
              </Typography>
              {categoryAddInputDatas.isMaxSizePhoto && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                      mr: '4px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                    }}
                  >
                    The photo can't be more than 2MB
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {categoryAddInputDatas.photo.length === 0 && (
                  <Box
                    sx={{
                      height: '54px',
                      width: '54px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      bgcolor: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderStyle: 'dashed',
                      borderColor: '#9E9D9D',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('input_img').click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '28px' }}
                    />
                  </Box>
                )}
                {categoryAddInputDatas.photo.map((input, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      padding: '7px',
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: 'absolute',
                        fontSize: '20px',
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeleteImage(input, index)}
                    />
                    <Box
                      component="img"
                      src={URL.createObjectURL(input)}
                      sx={{
                        height: '54px',
                        width: '54px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setCategoryAddInputDatas({
                          ...categoryAddInputDatas,
                          indexPreviePhoto: index + 1,
                          isPreviewPhoto: 'true',
                        });
                      }}
                    />
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  mb: '7px',
                  ml: '20px',
                  alignItems: 'center',
                }}
              >
                {categoryAddInputDatas.photo.length < 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('input_img').click();
                    }}
                  >
                    <input
                      accept="image/*"
                      id="input_img"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleAddImage}
                    />
                    <AddIcon />
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                      Add More
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/* DIalog Photo */}
          {categoryAddInputDatas.indexPreviePhoto !== 0 && (
            <Dialog
              open={categoryAddInputDatas.isPreviewPhoto}
              onClose={() => {
                setCategoryAddInputDatas({
                  ...categoryAddInputDatas,
                  isPreviewPhoto: !categoryAddInputDatas.isPreviewPhoto,
                });
              }}
            >
              <Box
                component="img"
                src={URL.createObjectURL(
                  categoryAddInputDatas.photo[
                    categoryAddInputDatas.indexPreviePhoto - 1
                  ]
                )}
                sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
              />
            </Dialog>
          )}
          {/* Type */}
          {/* <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Type*
            </Typography>

            <Autocomplete
              options={typeCategoryOptions}
              getOptionLabel={(option) => option.nmOpsi}
              inputValue={categoryAddInputDatas.typeInput}
              onInputChange={(_, newValue) => {
                setCategoryAddInputDatas({
                  ...categoryAddInputDatas,
                  typeInput: newValue,
                });
              }}
              value={categoryAddInputDatas.type}
              onChange={(_, newValue) => {
                setCategoryAddInputDatas({
                  ...categoryAddInputDatas,
                  type: newValue,
                  typeId: newValue.iDopsi,
                  typeName: newValue.nmOpsi,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ bgcolor: '#FAFAFA' }}
                  placeholder="Select Type"
                />
              )}
              loading={categoryAddInputDatas.typeLoading}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {categoryAddInputDatas.description.length}/250
              </Typography>
            </Box>
          </Grid> */}
          {/* description */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Description
            </Typography>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter description"
              value={categoryAddInputDatas.description}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  setCategoryAddInputDatas({
                    ...categoryAddInputDatas,
                    description: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {categoryAddInputDatas.description.length}/250
              </Typography>
            </Box>
          </Grid>
          {/* Schedule */}
          <Grid item xs={6} sx={{}}>
            <Grid container>
              <Grid
                item
                alignItems="center"
                sx={{ height: '100%', mr: '20px' }}
              >
                <Typography
                  sx={{
                    mb: '16px',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  Schedule
                </Typography>
              </Grid>
              <Grid item alignItems="center" sx={{ height: '100%' }}>
                <RadioGroup
                  row
                  value={categoryAddInputDatas.scheduleType}
                  onChange={(e) => {
                    setCategoryAddInputDatas({
                      ...categoryAddInputDatas,
                      schedule: today,
                      scheduleType: e.target.value,
                    });
                  }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '16px',
                    },
                    '.MuiRadio-root': {
                      padding: '0 8px',
                    },
                    '.MuiTypography-root': {
                      fontSize: '12px',
                    },
                  }}
                >
                  <FormControlLabel
                    value="now"
                    control={<Radio />}
                    label="Now"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', minWidth: '100%' }}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={categoryAddInputDatas.schedule}
                onChange={(newValue) => {
                  if (categoryAddInputDatas.scheduleType !== 'now') {
                    setCategoryAddInputDatas({
                      ...categoryAddInputDatas,
                      schedule: newValue,
                    });
                  } else {
                    setCategoryAddInputDatas({
                      ...categoryAddInputDatas,
                      schedule: today,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                  />
                )}
                disabled={
                  categoryAddInputDatas.scheduleType === 'now' ? true : false
                }
              />
            </Box>
          </Grid>
          {/* Add Sub Category */}
          {categoryAddInputDatas.subCategoryIndex.length === 0 && (
            <Grid item xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.primary.main,
                }}
                onClick={() => {
                  const value = indexSubCategory[0];
                  setCategoryAddInputDatas({
                    ...categoryAddInputDatas,
                    subCategoryIndex: [
                      ...categoryAddInputDatas.subCategoryIndex,
                      value,
                    ],
                  });

                  setIndexSubCategory(
                    indexSubCategory.filter((nilai) => nilai !== value)
                  );
                }}
              >
                <AddIcon
                  sx={{
                    fontSize: '20px',
                    cursor: 'pointer',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    cursor: 'pointer',
                  }}
                >
                  Add Sub
                </Typography>
              </Box>
            </Grid>
          )}
          {categoryAddInputDatas.subCategoryIndex.length !== 0 && (
            <Grid item xs={12}>
              <CustomCard sx={{ padding: '20px 16px ' }}>
                <Grid container columns={12} rowSpacing={5}>
                  {indexSubCategory.length !== 0 && (
                    <Grid item xs={12} sx={{ mb: '5px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: theme.palette.primary.main,
                        }}
                        onClick={() => {
                          const value = indexSubCategory[0];
                          setCategoryAddInputDatas({
                            ...categoryAddInputDatas,
                            subCategoryIndex: [
                              ...categoryAddInputDatas.subCategoryIndex,
                              value,
                            ],
                          });
                          setIndexSubCategory(
                            indexSubCategory.filter((nilai) => nilai !== value)
                          );
                        }}
                      >
                        <AddIcon
                          sx={{
                            fontSize: '20px',
                            cursor: 'pointer',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            cursor: 'pointer',
                          }}
                        >
                          Add Sub
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {categoryAddInputDatas.subCategoryIndex.map(
                    (nilai, index) => (
                      <Grid
                        container
                        item
                        xs={12}
                        columns={12}
                        columnSpacing={4}
                        rowSpacing={2}
                        key={index}
                      >
                        <AddSubCategoryInCategory
                          {...{
                            page,
                            setPage,
                            categoryAddInputDatas,
                            setCategoryAddInputDatas,
                            indexSubCategory,
                            setIndexSubCategory,
                            isSubmitAvailable,
                            setIsSubmitAvailable,
                          }}
                          sub={nilai === 1 ? sub1 : nilai === 2 ? sub2 : sub3}
                          setSub={
                            nilai === 1
                              ? setSub1
                              : nilai === 2
                              ? setSub2
                              : setSub3
                          }
                          id={nilai.toString()}
                        />
                        {categoryAddInputDatas.subCategoryIndex[
                          categoryAddInputDatas.subCategoryIndex.length - 1
                        ] !== nilai && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </Grid>
                    )
                  )}
                </Grid>
              </CustomCard>
            </Grid>
          )}
          <Box
            sx={{
              width: '100%',
              bgcolor: 'white',
              position: 'fixed',
              bottom: '0px',
              right: '0px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '18px 32px 18px 240px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Publish
              </Typography>
              <AntSwitch
                checked={categoryAddInputDatas.publish}
                onChange={(e) => {
                  setCategoryAddInputDatas({
                    ...categoryAddInputDatas,
                    publish: !categoryAddInputDatas.publish,
                  });
                }}
              />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <CustomButton
                variant="contained"
                disabled={!isSubmitAvailable}
                onClick={handleAddCategory}
                sx={{ width: '140px', height: '36px' }}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Submit
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      ) : (
        <CategoryProductAddMobile
          {...{
            handleBackButton,
            categoryAddInputDatas,
            subsidiaryDatas,
            setCategoryAddInputDatas,
            theme,
            today,
            AntSwitch,
            isSubmitAvailable,
            handleAddCategory,
            handleAddImage,
            handleDeleteImage,
          }}
          {...{
            typeCategoryOptions,
          }}
        />
      )}
    </>
  );
};
export default CategoryProductAdd;
