import { Box, Divider, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getAllSupplierScan } from '../../../../../../../../store/purchase/purchaseSupplier/purchaseSubmitSupplier';
import { NoImage } from '../../../../../../../../assets/Icons';

const ScannedDataAcceptTransaction = () => {
  const { isExist, scanned, allData } = useSelector(getAllSupplierScan); //nanti diganti ke redux transaction

  // console.log(allData);

  return (
    <Grid container columns={1}>
      {scanned.map((list, index) => (
        <Grid item xs={1} key={index}>
          <Box
            sx={{
              p: '14px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              {list?.gambar ? (
                <Box
                  sx={{
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#F5F5F5',
                    width: '45px',
                    height: '45px',
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_API_IMAGE_BASE_URL +
                      '/' +
                      list?.gambar
                    }
                    style={{
                      width: '45px',
                      height: '45px',
                      borderRadius: '8px',
                    }}
                    alt="gbr"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#F5F5F5',
                    width: '45px',
                    height: '45px',
                  }}
                >
                  <NoImage />
                </Box>
              )}
              <Box>
                <Typography sx={{ fontSize: '12px' }}>
                  {list?.namaProduk}
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '600', mt: '4px' }}
                >
                  1 {list?.unit}
                </Typography>
              </Box>
            </Box>
            <Box sx={{}}>
              {/* <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                Rp.1.000.000
              </Typography> */}
            </Box>
          </Box>
          <Divider />
        </Grid>
      ))}

      {/* dummy */}
      <Grid item xs={1}>
          <Box
            sx={{
              p: '14px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Box
                  sx={{
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#F5F5F5',
                    width: '45px',
                    height: '45px',
                  }}
                >
                  <NoImage />
                </Box>
              <Box>
                <Typography sx={{ fontSize: '12px' }}>
                  nama Produk
                </Typography>
              </Box>
            </Box>
            <Box sx={{}}>
              <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                1 Box
              </Typography> 
            </Box>
          </Box>
          <Divider />
        </Grid>
    </Grid>
  );
};
export default ScannedDataAcceptTransaction;
