import { Box, Grid, Typography } from '@mui/material';
import BackButton from '../../../../../../../../../components/shared/BackButton';

import { useDispatch, useSelector } from 'react-redux';
import { changePageMasterHubRack } from '../../../../../../../../../store/racks/masterHubRack/masterHubRack';
import {
  addPhoto,
  addVideo,
  changeDescription,
  changeName,
  deletePhoto,
  deleteVideo,
  getAllMasterHubRackEditState,
} from '../../../../../../../../../store/racks/masterHubRack/masterHubRackEdit';
import {
  ChevronRightIcon,
  KeyboardArrowDownIcon,
} from '../../../../../../../../../assets/Icons';
import Racks from '../Components/Racks';
import CustomInputWithMin from '../../../../../../../../../components/shared/CustomInputWithMin';
import CustomCard from '../../../../../../../../../components/shared/CustomCard';
import PhotoAddComponent from '../../../../../../../../../components/shared/PhotoAddComponent';
import VideoAddComponent from '../../../../../../../../../components/shared/VideoAddComponent';

const EditMasterHubRack = () => {
  const dispatch = useDispatch();
  const {
    rackSelected,
    row,
    column,
    name,
    width,
    height,
    length,
    weight,
    description,
    photo,
    video,
  } = useSelector(getAllMasterHubRackEditState);
  const handleBackButton = () => {
    dispatch(changePageMasterHubRack(''));
  };

  return (
    <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
      {/* //* BackButton */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />

        <Box>
          <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
            Edit Rack
          </Typography>
          <Box
            sx={{
              display: 'flex',
              color: '#9E9D9D',

              alignItems: 'center',
              mt: '4px',
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              cekk
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '16px' }} />
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              cekk
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '16px' }} />
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              cekk
            </Typography>
            {/* {hirarkiDetails?.miniSubCategory !== '' && (
                    <>
                      <ChevronRightIcon sx={{ fontSize: '16px' }} />
                      <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                        {hirarkiDetails?.miniSubCategory}
                      </Typography>
                    </>
                  )} */}
          </Box>
        </Box>
      </Grid>
      {/* //* Body */}
      <Grid
        item
        xs={12}
        container
        columns={12}
        columnSpacing={4}
        sx={{ ml: '12px' }}
      >
        <Grid
          item
          xs={5}
          sx={{
            bgcolor: '#FAFAFA',
            borderRadius: '12px',
            px: '12px',
            py: '16px',
          }}
        >
          <Racks col={column} row={row} rackSelected={rackSelected} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columns={2} rowSpacing={2}>
            {/* //* Code */}
            <Grid item xs={2}>
              <CustomInputWithMin
                title="Code"
                value={name}
                onChange={(e) => dispatch(changeName(e.target.value))}
                placeholder="Enter code"
              />
            </Grid>
            {/* //* Card */}
            <Grid item xs={2}>
              <CustomCard
                sx={{
                  p: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{}}>
                  <Typography>Size</Typography>
                  <Typography sx={{ mt: '4px' }}>
                    {width} - {height} - {length} - {weight}
                  </Typography>
                </Box>
                <Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
            {/* //* Photo */}
            <Grid item xs={1}>
              <PhotoAddComponent
                resource={'masterHubRack'}
                data={photo}
                handleAddImage={(link) => dispatch(addPhoto(link))}
                isEdit={true}
                handleDeleteImage={(link) => dispatch(deletePhoto(link))}
              />
            </Grid>
            {/* //* Video */}
            <Grid item xs={1}>
              <VideoAddComponent
                resource={'masterHubRack'}
                data={video}
                handleAddImage={(link) => dispatch(addVideo(link))}
                isEdit={true}
                handleDeleteImage={(link) => dispatch(deleteVideo(link))}
              />
            </Grid>
            {/* //* Description */}
            <Grid item xs={2}>
              <CustomInputWithMin
                title="Description"
                value={description}
                onChange={(e) => dispatch(changeDescription(e.target.value))}
                placeholder="Enter Description"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EditMasterHubRack;
