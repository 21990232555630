import {
  Box,
  Chip,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../../../../../components/shared/CustomDialog";
import { CloseIcon } from "../../../../../../assets/Icons";
import {
  changeFilterStatus,
  getDataHubTransaction,
  getDataSubHubTransaction,
} from "../../../../../../store/transaction/request/dataTableRequest";
import { getDataMiniHubTransaction } from "../../../../../../store/transaction/request/dataTableRequest";
import { useState } from "react";
import { useEffect } from "react";
import { getAllMasterHub } from "../../../../../../store/transaction/request/dataTableRequest";
import { getAllSubHubData } from "../../../../../../store/transaction/request/dataTableRequest";
import { getAllMiniHubData } from "../../../../../../store/transaction/request/dataTableRequest";
import { changeFilterFrom } from "../../../../../../store/transaction/request/dataTableRequest";
import { changeFilterTo } from "../../../../../../store/transaction/request/dataTableRequest";
import CustomButton from "../../../../../../components/shared/CustomButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DialogFilterTransactionMobile = ({ isFilter, setIsFilter }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const allDataHubs = useSelector(getDataHubTransaction);
  const allDataSubHubs = useSelector(getDataSubHubTransaction);
  const allDataMiniHubs = useSelector(getDataMiniHubTransaction);
  const [mixData, setMixData] = useState([]);
  const [fixMixData, setFixMixData] = useState();
  const dataStatus = [
    {
      label: "accept",
    },
    {
      label: "sent",
    },
  ];
  const [status, setStatus] = useState([]);

  useEffect(() => {
    setFixMixData(mixData.flat());
  }, [mixData]);

  useEffect(() => {
    dispatch(getAllMasterHub({ pageSize: 1000 }));
    dispatch(getAllSubHubData({ pageSize: 1000 }));
    dispatch(getAllMiniHubData({ pageSize: 1000 }));
  }, []);

  // ! mix data
  useEffect(() => {
    const mixs = [].concat(allDataHubs, allDataSubHubs, allDataMiniHubs);
    setMixData(mixs);
  }, [allDataHubs, allDataSubHubs, allDataMiniHubs]);

  // ! from
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFrom(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeTo = (event) => {
    const {
      target: { value },
    } = event;
    setTo(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(typeof value === "string" ? value.split(",") : value);
  };

  const handleSaveFilter = () => {
    dispatch(changeFilterFrom(from));
    dispatch(changeFilterTo(to));
    dispatch(changeFilterStatus(status));
    setIsFilter(false);
  };

  const handleResetFilter = () => {
    setFrom([]);
    setTo([]);
    setStatus([]);
    dispatch(changeFilterFrom([]));
    dispatch(changeFilterTo([]));
    dispatch(changeFilterStatus([]));
  };
  return (
    <CustomDialog
      isOpen={isFilter}
      setIsOpen={setIsFilter}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle
        fullWidth
        sx={{
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px 12px 0px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            alignItems: "center",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setIsFilter(!isFilter)}
            sx={{
              color: "#323232",
              p: 0,
            }}
          >
            <CloseIcon sx={{ fontSize: "1.25rem" }} />
          </IconButton>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#000000",
            }}
            component="span"
          >
            Filter
          </Typography>
          <Typography
            onClick={handleResetFilter}
            sx={{ color: "#51B15C", fontSize: "16px", fontWeight: 600 }}
          >
            Reset
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container columns={12} spacing={3} sx={{ mt: 1 }}>
          {/* from */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
              From
            </Typography>
            <FormControl fullWidth>
              <Select
                multiple
                value={from}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {mixData.map((name, index) => (
                  <MenuItem
                    key={index}
                    value={name?.label}
                    style={getStyles(name?.label, from, theme)}
                  >
                    {name?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* to */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
              To
            </Typography>
            <FormControl fullWidth>
              <Select
                multiple
                value={to}
                onChange={handleChangeTo}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {mixData.map((name, index) => (
                  <MenuItem
                    key={index}
                    value={name?.label}
                    style={getStyles(name?.label, from, theme)}
                  >
                    {name?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* status */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
              status
            </Typography>
            <FormControl fullWidth>
              <Select
                multiple
                value={status}
                onChange={handleChangeStatus}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {dataStatus.map((name, index) => (
                  <MenuItem
                    key={index}
                    value={name?.label}
                    style={getStyles(name?.label, from, theme)}
                  >
                    {name?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* button event */}
        <Box>
          <CustomButton
            fullWidth
            sx={{
              bgcolor: "#51B15C",
              color: "#ffffff",
              fontWeight: 600,
              fontSize: "0.875rem",
              borderRadius: "8px",
              "&:hover": {
                bgcolor: "#51B15C",
                color: "#ffffff",
              },
              px: 4,
              py: 1,
              mt: 2,
            }}
            onClick={handleSaveFilter}
          >
            Save
          </CustomButton>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};

export default DialogFilterTransactionMobile;
