import { useDispatch } from 'react-redux';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import {
  deleteCellFix,
  editCellFix,
  hoverCellFix,
  unHoverCellFix,
} from '../../../../../../../../store/hub/subHub/subHubAdd';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Box, Typography } from '@mui/material';

const ListRackSubHub = (props) => {
  const { name, index } = props;
  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(unHoverCellFix());
    dispatch(editCellFix(index));
  };
  const handleDelete = () => {
    dispatch(unHoverCellFix());
    dispatch(deleteCellFix(index));
  };

  return (
    <CustomCard
      sx={{ p: '16px 12px', width: '238px', mb: '8px', bgcolor: 'white' }}
      onMouseOver={() => dispatch(hoverCellFix(index))}
      onMouseOut={() => dispatch(unHoverCellFix())}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: '12px' }}>{name}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ModeEditRoundedIcon
            onClick={handleEdit}
            sx={{ color: '#FD7900', fontSize: '16px', cursor: 'pointer' }}
          />
          <DeleteRoundedIcon
            onClick={handleDelete}
            sx={{ color: '#F33A3A', fontSize: '16px', cursor: 'pointer' }}
          />
        </Box>
      </Box>
    </CustomCard>
  );
};
export default ListRackSubHub;
