import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { ReactComponent as Cogs } from '../../../../../../../assets/cogs.svg';
import { ReactComponent as Sales } from '../../../../../../../assets/sales.svg';
import { ReactComponent as Used } from '../../../../../../../assets/used2.svg';
import { ReactComponent as Order } from '../../../../../../../assets/order.svg';

const CustomTableCellHead = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12 px',
  textTransform: 'capitalize',

  padding: '8px 0px',
});
const CustomTableCellBody = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12px',
  height: '10px',
  textTransform: 'capitalize',

  padding: '0px',
});
function TablePurchaseDashboardMobile() {
  return (
    <Box>
      {/* table */}
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#FAFAFA' }}>
            <CustomTableCellHead></CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '8px' }}>
                  Product
                </Typography>
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '8px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '8px',
                    fontWeight: 500,
                    color: '#51B15C',
                  }}
                >
                  COGS (Rp)*
                </Typography>
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '8px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '8px',
                    fontWeight: 500,
                    color: '#F1CC00',
                  }}
                >
                  Qty
                </Typography>
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '8px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(5)].map((_, index) => (
            <TableRow>
              <CustomTableCellBody>
                <Checkbox
                  defaultChecked
                  sx={{
                    height: '25px',
                    '&:before': {
                      display: 'block',
                      width: 10,
                      height: 10,
                    },
                  }}
                />
              </CustomTableCellBody>
              <CustomTableCellBody sx={{ fontSize: '8px', fontWeight: 400 }}>
                Product {index + 1}
              </CustomTableCellBody>
              <CustomTableCellBody
                align="center"
                sx={{ fontSize: '8px', fontWeight: 400 }}
              >
                300
              </CustomTableCellBody>
              <CustomTableCellBody
                align="center"
                sx={{ fontSize: '8px', fontWeight: 400 }}
              >
                300
              </CustomTableCellBody>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
        }}
      >
        <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>
          Total data * from *
        </Typography>
        <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>
          {' '}
          1 of *{' '}
        </Typography>
      </Box>
    </Box>
  );
}
export default TablePurchaseDashboardMobile;
