import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Subsidiary from "../../../../../components/shared/Subsidiary";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeSubsidiaryIdStock } from "../../../../../store/stock/stock";
import CustomCard from "../../../../../components/shared/CustomCard";
import TableTransaction from "./Table";
import { changePageTransaction } from "../../../../../store/Routing/transactionRouting";
import { getListRequestDashboard } from "../../../../../api/transaction";
import { ChevronRightIcon } from "../../../../../assets/Icons";
import dayjs from "dayjs";
import ChartPerfomanceStock from "../Stock/Component/Chart";
import DashboardTransactionMobile from "./TtansactionMobile";

const color = {
  lesstwo: "#000000",
  lesssix: "#FD7900",
  mostseven: "#F33A3A",
};

const InventoryTransaction = () => {
  const matchTablet = useMediaQuery("(min-width:832px");
  const dispatch = useDispatch();
  const [dataTransaction, setDataTransaction] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  // ! --------- Subsidiary ---------
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);
  useEffect(() => {
    dispatch(changeSubsidiaryIdStock(filterSubsidiaryArray));
  }, [filterSubsidiaryArray]);

  const handleToRequestTransaction = () => {
    dispatch(changePageTransaction("request"));
  };
  // ! grafik & table

  const [timeFrame, setTimeFrame] = useState("daily");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  // ! list request dashboard

  // ! ------------------  API -------------

  const fetchListTransaction = async () => {
    let params = {};
    params.nameHub = "subhub"; // untuk nameHub masih kasus super admin, harusnya dibaca berdasarkan letak hub si user yg login
    params.idHub = 3;

    setIsloading(true);
    try {
      const {
        data: { data },
      } = await getListRequestDashboard(params);
      if (data?.statusCode !== 500) {
        setDataTransaction(data);
        setIsloading(false);
      } else {
        setDataTransaction([]);
        setIsloading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchListTransaction();
  }, []);

  return (
    <>
      {!matchTablet ? (
        <>
          <DashboardTransactionMobile />
        </>
      ) : (
        <Box sx={{ mt: 2 }}>
          <Grid container columns={12} spacing={3}>
            {/* subsidiary */}
            <Grid item xs={12}>
              <Subsidiary
                subsidiaryChecked={filterSubsidiaryArray}
                setSubsidiaryChecked={setFilterSubsidiaryArray}
              />
            </Grid>

            {/* grafik */}
            <Grid item xs={8}>
              <CustomCard sx={{ p: 2 }}>
                <ChartPerfomanceStock
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                />
              </CustomCard>
            </Grid>

            {/* list stock request */}
            <Grid item xs={4} sx={{ cursor: "pointer" }}>
              <CustomCard
                sx={{ p: "10px 20px", height: "100%", cursor: "pointer" }}
                onClick={handleToRequestTransaction}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Stock Request
                  <ChevronRightIcon sx={{ color: "#00FF00" }} />
                </Typography>
                {isLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  dataTransaction?.map((item, index) => (
                    <Box sx={{ mt: 2 }} key={index}>
                      <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                        Request {item?.idOrders}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mt: 1,
                        }}
                      >
                        <Typography sx={{ color: "#aaa", fontSize: "12px" }}>
                          {item?.namaPemberi || "_"}
                        </Typography>
                        <ChevronRightIcon sx={{ fontSize: "12px" }} />
                        <Typography sx={{ color: "#aaa", fontSize: "12px" }}>
                          {item?.namaTujuan || "_"}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 600,
                            color:
                              item.dateDays <= 2
                                ? color.lesstwo
                                : item.dateDays <= 6
                                ? color.lesssix
                                : color.mostseven,
                          }}
                        >
                          {item?.dateDays} days ago
                        </Typography>
                      </Box>
                    </Box>
                  ))
                )}
              </CustomCard>
            </Grid>

            {/* list data table */}
            <Grid item xs={12}>
              <TableTransaction />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
export default InventoryTransaction;
