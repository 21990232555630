/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  ArrowDownward as ArrowDownwardIcon,
  OpenInNew as OpenInNewIcon,
  InfoOutlined as InfoIcon,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Stairs as StairsIcon,
  Close as CloseIcon,
  North as ArrowUpIcon,
  South as ArrowDownIcon,
  LocalBar as BeverageIcon,
  LocalDining as FoodIcon,
  List as ListIcon,
  PersonOutlineOutlined as PersonIcon,
  Star as StarIcon,
} from "@mui/icons-material";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ReactComponent as OrderIcon } from "../assets/order.svg";
import { ReactComponent as SalesIcon } from "../assets/sales.svg";
// import { ReactComponent as AssetIcon } from '../assets/asset.svg';
// import { ReactComponent as EquityIcon } from '../assets/equity.svg';
// import { ReactComponent as ExpenseIcon } from '../assets/expense.svg';
// import { ReactComponent as LiabilityIcon } from '../assets/liability.svg';
// import { ReactComponent as RevenueIcon } from '../assets/revenue.svg';
import { ReactComponent as LineChartIcon } from "../assets/line-chart.svg";

import {
  Box,
  ButtonBase,
  Divider,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { hidePhoneNumber, kanpaiOrderStatusColor } from "../utils/helpers";
import { Fragment, useEffect, useRef, useState } from "react";
import { Bar, getElementAtEvent, Line } from "react-chartjs-2";
import {
  fetchBedSummary,
  fetchIncentives,
  fetchOrderActivities,
  fetchOrderCount,
  fetchOrders,
  fetchPatientStatistics,
  fetchPopularOrders,
  fetchRevenueSummary,
  fetchSalesTotal,
  fetchStaffPerformances,
  fetchStocks,
  fetchTreatmentProcessTimes,
  fetchTreatmentWaitingTimes,
} from "../api/assets";
import { formatNumberToCurrency } from "../utils/currency";
import useAuth from "../hooks/useAuth";
import {
  kanpaiCustomerAddClick,
  kanpaiCustomerDetails,
  kanpaiCustomerStatistic,
  kanpaiDineIn,
  kanpaiLeastPopularMenu,
  kanpaiOperationalHours,
  kanpaiOrderCount,
  kanpaiOrderDetails,
  kanpaiOrderPredictions,
  kanpaiOrderStatus,
  kanpaiPopularMenu,
  kanpaiRecentOrders,
  kanpaiRevenueSummary,
  kanpaiSalesTotal,
  kanpaiTableDetails,
  kanpaiTableSummary,
  kanpaiTopCustomers,
  kanpaiTableHistories,
  kanpaiKitchenProcessingTime,
  kanpaiTopSoldPerformance,
  kanpaiTablePerformance,
  kanpaiCustomerRatings,
} from "../api/kanpai";
import CustomBottomDrawer from "../components/shared/Kanpai/CustomBottomDrawer";
import TableCustomerHistories from "../components/TableCustomerHistories";
import CustomDateRange from "../components/shared/CustomDateRange";
import CustomTab from "../components/shared/Kanpai/CustomTab";

const getXAxisLabel = (timeframe) => {
  switch (timeframe) {
    case "daily":
      return "Day of Month";
    case "monthly":
      return "Month";
    case "weekly":
      return "Week";
    default:
      return "";
  }
};

const ordinalSuffixOf = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
};

const average = (ctx, index) => {
  if (ctx.chart.data.datasets.length > 0) {
    const values = ctx.chart.data.datasets[index].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }

  return 0;
};

export default function MobileDashboard() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { auth } = useAuth();

  const patientStatisticChartRef = useRef(null);
  const [patientStatisticChartData, setPatientStatisticChartData] = useState({
    datasets: [],
  });
  const staffRatingChartRef = useRef(null);
  const [staffRatingChartData, setStaffRatingChartData] = useState({
    datasets: [],
  });
  const waitingTimeChartRef = useRef(null);
  const [waitingTimeChartData, setWaitingTimeChartData] = useState({
    datasets: [],
  });
  const treatmentTimeChartRef = useRef(null);
  const [treatmentTimeChartData, setTreatmentTimeChartData] = useState({
    datasets: [],
  });
  const salesPerformanceChartRef = useRef(null);
  const [salesPerformanceChartData, setSalesPerformanceChartData] = useState({
    datasets: [],
  });

  const [revenueSummary, setRevenueSummary] = useState();
  const [bedSummary, setBedSummary] = useState();
  const [orderActivities, setOrderActivities] = useState([]);
  const [popularOrders, setPopularOrders] = useState([]);
  const [incentives, setIncentives] = useState([]);
  const [stocks, setStocks] = useState([]);

  const [dateRangePatient, setDateRangePatient] = useState([
    {
      startDate: new Date(dayjs().subtract(6, "d")),
      endDate: new Date(dayjs()),
      key: "selection",
    },
  ]);
  const [saveDateRangePatient, setSaveDateRangePatient] = useState([
    {
      startDate: new Date(dayjs().subtract(6, "d")),
      endDate: new Date(dayjs()),
      key: "selection",
    },
  ]);

  const [dateRangeOrderActivities, setDateRangeOrderActivities] = useState([
    {
      startDate: new Date(dayjs().subtract(6, "d")),
      endDate: new Date(dayjs()),
      key: "selection",
    },
  ]);
  const [saveDateRangeOrderActivities, setSaveDateRangeOrderActivities] =
    useState([
      {
        startDate: new Date(dayjs().subtract(6, "d")),
        endDate: new Date(dayjs()),
        key: "selection",
      },
    ]);

  const [ordersData, setOrdersData] = useState();
  const [newOrdersData, setNewOrdersData] = useState();
  const [ordersPage, setOrdersPage] = useState(1);
  const [newOrdersPage, setNewOrdersPage] = useState(1);
  const [
    treatmentWaitingTimeChangePercentage,
    setTreatmentWaitingTimeChangePercentage,
  ] = useState(0);
  const [averageWaitingTime, setAverageWaitingTime] = useState(0);
  const [
    treatmentProcessTimeChangePercentage,
    setTreatmentProcessTimeChangePercentage,
  ] = useState(0);
  const [averageProcessTime, setAverageProcessTime] = useState(0);
  const [totalPatient, setTotalPatient] = useState(0);

  const [
    patientStatisticsChangePercentage,
    setPatientStatisticsChangePercentage,
  ] = useState(0);

  const [dateRangeSalesPerformance, setDateRangeSalesPerformance] = useState([
    {
      startDate: new Date(dayjs().subtract(6, "d")),
      endDate: new Date(dayjs()),
      key: "selection",
    },
  ]);
  const [saveDateRangeSalesPerformance, setSaveDateRangeSalesPerformance] =
    useState([
      {
        startDate: new Date(dayjs().subtract(6, "d")),
        endDate: new Date(dayjs()),
        key: "selection",
      },
    ]);

  const [salesPerformanceTimeframe, setSalesPerformanceTimeframe] =
    useState("daily");

  const [
    staffPerformancesRatingChangePercentage,
    setStaffPerformancesRatingChangePercentage,
  ] = useState(0);
  const [staffPerformancesAverageRating, setStaffPerformancesAverageRating] =
    useState(0);
  const [staffPerformancesTotalCustomers, setStaffPerformancesTotalCustomers] =
    useState(0);
  const [
    staffPerformancesLatestCustomerRatings,
    setStaffPerformancesLatestCustomerRatings,
  ] = useState([]);

  const [selectedCategoryTopSold, setSelectedCategoryTopSold] =
    useState("food");
  const [selectedCategoryRecentOrders, setSelectedCategoryRecentOrders] =
    useState("food");
  const [
    selectedCategoryOrderPredictions,
    setSelectedCategoryOrderPredictions,
  ] = useState("food");

  const [saveDateRangeOperational, setSaveDateRangeOperational] = useState([
    {
      startDate: new Date(dayjs().subtract(6, "d")),
      endDate: new Date(dayjs()),
      key: "selection",
    },
  ]);

  const [dateRangeOperational, setDateRangeOperational] = useState([
    {
      startDate: new Date(dayjs().subtract(6, "d")),
      endDate: new Date(dayjs()),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      const params = {
        startDate: dayjs(saveDateRangeSalesPerformance[0].startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: dayjs(saveDateRangeSalesPerformance[0].endDate).format(
          "YYYY-MM-DD"
        ),
      };
      try {
        if (auth?.companyId === 9) {
          const {
            data: { data: fetchedRevenueSummary },
          } = await kanpaiRevenueSummary(params);
          setRevenueSummary(fetchedRevenueSummary);
        } else {
          const {
            data: { data: fetchedRevenueSummary },
          } = await fetchRevenueSummary(params);

          setRevenueSummary(fetchedRevenueSummary);
        }
      } catch (error) {
        console.error(error);
        setRevenueSummary(null);
      }
    };

    bootstrapAsync();
  }, [saveDateRangeSalesPerformance]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedOrdersData },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiRecentOrders({
                page: ordersPage,
                pageSize: 5,
                category: selectedCategoryRecentOrders,
              })
            : fetchOrders({ page: ordersPage, pageSize: 3 }),
        ]);

        if (auth?.companyId === 9) {
          let tempOrders = { ...fetchedOrdersData };
          tempOrders.orders = tempOrders.orders.map(
            ({ outlet, dateTime, ...props }) => ({
              outlet: { name: outlet },
              purchaseDateTime: dateTime,
              ...props,
            })
          );
          setOrdersData(tempOrders);
        } else {
          setOrdersData(fetchedOrdersData);
        }
      } catch (error) {
        console.error(error);
        setOrdersData(null);
      }
    };

    bootstrapAsync();
  }, [ordersPage, selectedCategoryRecentOrders]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedOrdersData },
          },
        ] = await Promise.all([
          kanpaiRecentOrders({
            page: newOrdersPage,
            pageSize: 10,
            category: selectedCategoryRecentOrders,
          }),
        ]);

        let tempOrders = { ...fetchedOrdersData };
        tempOrders.orders = tempOrders.orders.map(
          ({ outlet, dateTime, ...props }) => ({
            outlet: { name: outlet },
            purchaseDateTime: dateTime,
            ...props,
          })
        );
        setNewOrdersData(tempOrders);
      } catch (error) {
        console.error(error);
        setNewOrdersData(null);
      }
    };

    auth?.companyId === 9 && bootstrapAsync();
  }, [newOrdersPage, selectedCategoryRecentOrders]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        if (auth?.companyId === 9) {
          const {
            data: {
              data: { orders: operationalHours },
            },
          } = await kanpaiOperationalHours({
            startDate: dayjs(saveDateRangeOperational[0].startDate).format(
              "YYYY-MM-DD"
            ),
            endDate: dayjs(saveDateRangeOperational[0].endDate).format(
              "YYYY-MM-DD"
            ),
          });

          setStocks(operationalHours);
        } else {
          const {
            data: {
              data: { usedStocks },
            },
          } = await fetchStocks({
            startDate: dayjs(saveDateRangeOperational[0].startDate).format(
              "YYYY-MM-DD"
            ),
            endDate: dayjs(saveDateRangeOperational[0].endDate).format(
              "YYYY-MM-DD"
            ),
          });
          setStocks(usedStocks);
        }
      } catch (error) {
        console.error(error);
        setStocks([]);
      }
    };

    bootstrapAsync();
  }, [saveDateRangeOperational]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedIncentives },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiLeastPopularMenu({
                startDate: dayjs(saveDateRangeOperational[0].startDate).format(
                  "YYYY-MM-DD"
                ),
                endDate: dayjs(saveDateRangeOperational[0].endDate).format(
                  "YYYY-MM-DD"
                ),
                category: selectedCategoryTopSold,
              })
            : fetchIncentives({
                startDate: dayjs(saveDateRangeOperational[0].startDate).format(
                  "YYYY-MM-DD"
                ),
                endDate: dayjs(saveDateRangeOperational[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              }),
        ]);
        setIncentives(
          auth?.companyId === 9
            ? fetchedIncentives?.minProductSales?.products
            : fetchedIncentives
        );
      } catch (error) {
        console.error(error);
        setIncentives([]);
      }
    };

    bootstrapAsync();
  }, [saveDateRangeOperational, selectedCategoryTopSold]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedBedSummary },
          },
        ] = await Promise.all([
          auth?.companyId === 9 ? kanpaiTableSummary() : fetchBedSummary(),
        ]);
        setBedSummary(fetchedBedSummary);

        if (auth?.companyId === 9) {
          setMainFloorTables({
            free: fetchedBedSummary.mainFloor.free.tables.map((table) => ({
              id: table.id,
              name: parseInt(table.name.split("-")[1]),
              table: table.name,
            })),
            occupied: fetchedBedSummary.mainFloor.occupied.tables.map(
              (table) => ({
                id: table.id,
                name: parseInt(table.name.split("-")[1]),
                table: table.name,
              })
            ),
          });

          setBobShopTables({
            free: fetchedBedSummary.bob.free.tables.map((table) => ({
              id: table.id,
              name: parseInt(table.name.split("-")[1]),
              table: table.name,
            })),
            occupied: fetchedBedSummary.bob.occupied.tables.map((table) => ({
              id: table.id,
              name: parseInt(table.name.split("-")[1]),
              table: table.name,
            })),
          });

          setVipRoomTables({
            free: fetchedBedSummary.vip.free.tables.map((table) => ({
              id: table.id,
              name: parseInt(table.name.split("-")[2]),
              table: table.name,
            })),
            occupied: fetchedBedSummary.vip.occupied.tables.map((table) => ({
              id: table.id,
              name: parseInt(table.name.split("-")[2]),
              table: table.name,
            })),
          });
        }
      } catch (error) {
        console.error(error);
        setBedSummary(null);
      }
    };

    bootstrapAsync();
  }, []);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedOrderActivities },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiOrderStatus({
                startDate: dayjs(
                  saveDateRangeOrderActivities[0].startDate
                ).format("YYYY-MM-DD"),
                endDate: dayjs(saveDateRangeOrderActivities[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              })
            : fetchOrderActivities({
                startDate: dayjs(
                  saveDateRangeOrderActivities[0].startDate
                ).format("YYYY-MM-DD"),
                endDate: dayjs(saveDateRangeOrderActivities[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              }),
        ]);

        setOrderActivities(fetchedOrderActivities);
      } catch (error) {
        console.error(error);
        setOrderActivities([]);
      }
    };

    bootstrapAsync();
  }, [saveDateRangeOrderActivities]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        if (auth?.companyId === 9) {
          const {
            data: {
              data: {
                maxProductSales: { products },
              },
            },
          } = await kanpaiPopularMenu({
            startDate: dayjs(saveDateRangeOperational[0].startDate).format(
              "YYYY-MM-DD"
            ),
            endDate: dayjs(saveDateRangeOperational[0].endDate).format(
              "YYYY-MM-DD"
            ),
            category: selectedCategoryTopSold,
          });
          setPopularOrders(products);
        } else {
          const {
            data: {
              data: { treatments },
            },
          } = await fetchPopularOrders({
            startDate: dayjs(saveDateRangeOperational[0].startDate).format(
              "YYYY-MM-DD"
            ),
            endDate: dayjs(saveDateRangeOperational[0].endDate).format(
              "YYYY-MM-DD"
            ),
          });
          setPopularOrders(treatments);
        }
      } catch (error) {
        console.error(error);
        setPopularOrders([]);
      }
    };

    bootstrapAsync();
  }, [saveDateRangeOperational, selectedCategoryTopSold]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedSalesTotal },
          },
          {
            data: { data: fetchedOrderCount },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiSalesTotal({
                startDate: dayjs(
                  saveDateRangeSalesPerformance[0].startDate
                ).format("YYYY-MM-DD"),
                endDate: dayjs(saveDateRangeSalesPerformance[0].endDate).format(
                  "YYYY-MM-DD"
                ),
                timeframe: salesPerformanceTimeframe,
              })
            : fetchSalesTotal({
                startDate: dayjs(
                  saveDateRangeSalesPerformance[0].startDate
                ).format("YYYY-MM-DD"),
                endDate: dayjs(saveDateRangeSalesPerformance[0].endDate).format(
                  "YYYY-MM-DD"
                ),
                timeframe: salesPerformanceTimeframe,
              }),
          auth?.companyId === 9
            ? kanpaiOrderCount({
                startDate: dayjs(
                  saveDateRangeSalesPerformance[0].startDate
                ).format("YYYY-MM-DD"),
                endDate: dayjs(saveDateRangeSalesPerformance[0].endDate).format(
                  "YYYY-MM-DD"
                ),
                timeframe: salesPerformanceTimeframe,
              })
            : fetchOrderCount({
                startDate: dayjs(
                  saveDateRangeSalesPerformance[0].startDate
                ).format("YYYY-MM-DD"),
                endDate: dayjs(saveDateRangeSalesPerformance[0].endDate).format(
                  "YYYY-MM-DD"
                ),
                timeframe: salesPerformanceTimeframe,
              }),
        ]);

        const chart = salesPerformanceChartRef.current;

        if (chart) {
          const salesPerformanceChartData = {
            labels: fetchedSalesTotal.values.map((value) =>
              salesPerformanceTimeframe === "monthly"
                ? dayjs(value.xAxisValue).format("MMM") +
                  " " +
                  dayjs(value.xAxisValue).format("YYYY")
                : salesPerformanceTimeframe === "daily"
                ? value.xAxisValue
                : dayjs(value.xAxisValue).week() +
                  " " +
                  dayjs(value.xAxisValue).format("YYYY")
            ),
            datasets: [
              {
                label: "Sales",
                data: fetchedSalesTotal.values.map((value) => value.yAxisValue),
                yAxisID: "y1",
                backgroundColor: theme.palette.primary.main,
                borderRadius: 4,
              },
              {
                label: "Orders",
                data: fetchedOrderCount.values.map((value) => value.yAxisValue),
                yAxisID: "y2",
                backgroundColor: "#FFCF56",
                borderRadius: 4,
              },
            ],
          };

          setDates(fetchedSalesTotal.values.map((value) => value.xAxisValue));

          setSalesPerformanceChartData(salesPerformanceChartData);
        }
      } catch (error) {
        console.error(error);
        setSalesPerformanceChartData({ datasets: [] });
      }
    };

    bootstrapAsync();
  }, [
    saveDateRangeSalesPerformance,
    salesPerformanceTimeframe,
    theme.palette.primary.main,
  ]);

  useEffect(() => {
    const chart = salesPerformanceChartRef.current;

    if (!chart) return;

    salesPerformanceChartData.datasets.forEach((_, index) => {
      chart.options.scales[`y${index + 1}`].display =
        chart.isDatasetVisible(index);
      chart.options.plugins.annotation.annotations[
        `annotation${index + 1}`
      ].display = chart.isDatasetVisible(index);
      chart.update();
    });
  });

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedTreatmentWaitingTimes },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiDineIn({
                startDate: dayjs(saveDateRangeOperational[0].startDate).format(
                  "YYYY-MM-DD"
                ),
                endDate: dayjs(saveDateRangeOperational[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              })
            : fetchTreatmentWaitingTimes({
                startDate: dayjs(saveDateRangeOperational[0].startDate).format(
                  "YYYY-MM-DD"
                ),
                endDate: dayjs(saveDateRangeOperational[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              }),
        ]);

        const chart = waitingTimeChartRef.current;

        if (chart) {
          const gradient = chart.ctx.createLinearGradient(
            0,
            chart.chartArea.bottom,
            0,
            chart.chartArea.top
          );
          gradient.addColorStop(0.95, "#84E8F419");
          gradient.addColorStop(0.05, "#84E8F400");

          const waitingTimeChartData = {
            labels: fetchedTreatmentWaitingTimes.map((time) => time.dateTime),
            datasets: [
              {
                label: auth?.companyId === 9 ? "Orders" : "Waiting Duration",
                data: fetchedTreatmentWaitingTimes.map((time) =>
                  auth?.companyId === 9
                    ? time.amount
                    : dayjs.duration(time.duration, "seconds").asMinutes()
                ),
                backgroundColor: gradient,
                fill: true,
              },
            ],
          };

          setWaitingTimeChartData(waitingTimeChartData);
        }

        if (fetchedTreatmentWaitingTimes.length > 0) {
          setTreatmentWaitingTimeChangePercentage(
            auth?.companyId === 9
              ? ((fetchedTreatmentWaitingTimes[
                  fetchedTreatmentWaitingTimes.length - 1
                ].amount -
                  fetchedTreatmentWaitingTimes[0].amount) /
                  fetchedTreatmentWaitingTimes[0].amount) *
                  100
              : ((fetchedTreatmentWaitingTimes[
                  fetchedTreatmentWaitingTimes.length - 1
                ].duration -
                  fetchedTreatmentWaitingTimes[0].duration) /
                  fetchedTreatmentWaitingTimes[0].duration) *
                  100
          );
          setAverageWaitingTime(
            auth?.companyId === 9
              ? fetchedTreatmentWaitingTimes.reduce(
                  (previousCumulativeWaitingTimes, currentWaitingTime) =>
                    previousCumulativeWaitingTimes + currentWaitingTime.amount,
                  0
                ) / fetchedTreatmentWaitingTimes.length
              : dayjs
                  .duration(
                    fetchedTreatmentWaitingTimes.reduce(
                      (previousCumulativeWaitingTimes, currentWaitingTime) =>
                        previousCumulativeWaitingTimes +
                        currentWaitingTime.duration,
                      0
                    ) / fetchedTreatmentWaitingTimes.length,
                    "seconds"
                  )
                  .asMinutes()
          );
        } else {
          setTreatmentWaitingTimeChangePercentage(0);
          setAverageWaitingTime(0);
        }
      } catch (error) {
        console.error(error);
        setWaitingTimeChartData({ datasets: [] });
        setTreatmentWaitingTimeChangePercentage(0);
        setAverageWaitingTime(0);
      }
    };

    bootstrapAsync();
  }, [saveDateRangeOperational]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedTreatmentProcessTimes },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiKitchenProcessingTime({
                startDate: dayjs(saveDateRangeOperational[0].startDate).format(
                  "YYYY-MM-DD"
                ),
                endDate: dayjs(saveDateRangeOperational[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              })
            : fetchTreatmentProcessTimes({
                startDate: dayjs(saveDateRangeOperational[0].startDate).format(
                  "YYYY-MM-DD"
                ),
                endDate: dayjs(saveDateRangeOperational[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              }),
        ]);

        const chart = treatmentTimeChartRef.current;

        if (chart) {
          const gradient = chart.ctx.createLinearGradient(
            0,
            chart.chartArea.bottom,
            0,
            chart.chartArea.top
          );
          gradient.addColorStop(0.95, "#84E8F419");
          gradient.addColorStop(0.05, "#84E8F400");

          const treatmentTimeChartData = {
            labels: fetchedTreatmentProcessTimes.map((time) =>
              auth?.companyId === 9 ? time.date : time.dateTime
            ),
            datasets: [
              {
                label: "Duration",
                data: fetchedTreatmentProcessTimes.map((time) =>
                  auth?.companyId === 9
                    ? dayjs.duration(time.avgTime, "seconds").asMinutes()
                    : dayjs.duration(time.duration, "seconds").asMinutes()
                ),
                backgroundColor: gradient,
                fill: true,
              },
            ],
          };

          setTreatmentTimeChartData(treatmentTimeChartData);
        }

        if (fetchedTreatmentProcessTimes.length > 0) {
          setTreatmentProcessTimeChangePercentage(
            auth?.companyId === 9
              ? ((fetchedTreatmentProcessTimes[
                  fetchedTreatmentProcessTimes.length - 1
                ].avgTime -
                  fetchedTreatmentProcessTimes[0].avgTime) /
                  fetchedTreatmentProcessTimes[0].avgTime) *
                  100
              : ((fetchedTreatmentProcessTimes[
                  fetchedTreatmentProcessTimes.length - 1
                ].duration -
                  fetchedTreatmentProcessTimes[0].duration) /
                  fetchedTreatmentProcessTimes[0].duration) *
                  100
          );
          setAverageProcessTime(
            auth?.companyId === 9
              ? dayjs
                  .duration(
                    fetchedTreatmentProcessTimes.reduce(
                      (previousCumulativeProcessTimes, currentProcessTime) =>
                        previousCumulativeProcessTimes +
                        currentProcessTime.avgTime,
                      0
                    ) / fetchedTreatmentProcessTimes.length,
                    "seconds"
                  )
                  .asMinutes()
              : dayjs
                  .duration(
                    fetchedTreatmentProcessTimes.reduce(
                      (previousCumulativeProcessTimes, currentProcessTime) =>
                        previousCumulativeProcessTimes +
                        currentProcessTime.duration,
                      0
                    ) / fetchedTreatmentProcessTimes.length,
                    "seconds"
                  )
                  .asMinutes()
          );
        } else {
          setTreatmentProcessTimeChangePercentage(0);
          setAverageProcessTime(0);
        }
      } catch (error) {
        console.error(error);
        setTreatmentTimeChartData({ datasets: [] });
        setTreatmentProcessTimeChangePercentage(0);
        setAverageProcessTime(0);
      }
    };

    bootstrapAsync();
  }, [saveDateRangeOperational]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedPatientStatistics },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiCustomerStatistic({
                startDate: dayjs(saveDateRangePatient[0].startDate).format(
                  "YYYY-MM-DD"
                ),
                endDate: dayjs(saveDateRangePatient[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              })
            : fetchPatientStatistics({
                startDate: dayjs(saveDateRangePatient[0].startDate).format(
                  "YYYY-MM-DD"
                ),
                endDate: dayjs(saveDateRangePatient[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              }),
        ]);

        const chart = patientStatisticChartRef.current;

        if (chart) {
          const gradient = chart.ctx.createLinearGradient(
            0,
            chart.chartArea.bottom,
            0,
            chart.chartArea.top
          );
          gradient.addColorStop(0.95, "#84E8F419");
          gradient.addColorStop(0.05, "#84E8F400");

          const patientStatisticChartData = {
            labels:
              auth?.companyId === 9
                ? fetchedPatientStatistics.dailyCustomerCount.map(
                    (count) => count.dateTime
                  )
                : fetchedPatientStatistics.dailyPatientCount.map(
                    (count) => count.dateTime
                  ),
            datasets: [
              {
                label:
                  auth?.companyId === 9 ? "Customer Count" : "Patient Count",
                data:
                  auth?.companyId === 9
                    ? fetchedPatientStatistics.dailyCustomerCount.map(
                        (count) => count.customerCount
                      )
                    : fetchedPatientStatistics.dailyPatientCount.map(
                        (count) => count.patientCount
                      ),
                backgroundColor: gradient,
                fill: true,
              },
            ],
          };

          setPatientStatisticChartData(patientStatisticChartData);
        }

        setTotalPatient(
          auth?.companyId === 9
            ? fetchedPatientStatistics.totalCustomer
            : fetchedPatientStatistics.totalPatients
        );

        if (auth?.companyId === 9) {
          if (fetchedPatientStatistics.dailyCustomerCount.length > 0) {
            if (
              fetchedPatientStatistics.dailyCustomerCount[0].customerCount === 0
            ) {
              setPatientStatisticsChangePercentage(
                fetchedPatientStatistics.dailyCustomerCount[
                  fetchedPatientStatistics.dailyCustomerCount.length - 1
                ].customerCount * 100
              );
            } else {
              setPatientStatisticsChangePercentage(
                ((fetchedPatientStatistics.dailyCustomerCount[
                  fetchedPatientStatistics.dailyCustomerCount.length - 1
                ].customerCount -
                  fetchedPatientStatistics.dailyCustomerCount[0]
                    .customerCount) /
                  fetchedPatientStatistics.dailyCustomerCount[0]
                    .customerCount) *
                  100
              );
            }
          } else {
            setPatientStatisticsChangePercentage(0);
          }
        } else {
          if (fetchedPatientStatistics.dailyPatientCount.length > 0) {
            if (
              fetchedPatientStatistics.dailyPatientCount[0].patientCount === 0
            ) {
              setPatientStatisticsChangePercentage(
                fetchedPatientStatistics.dailyPatientCount[
                  fetchedPatientStatistics.dailyPatientCount.length - 1
                ].patientCount * 100
              );
            } else {
              setPatientStatisticsChangePercentage(
                ((fetchedPatientStatistics.dailyPatientCount[
                  fetchedPatientStatistics.dailyPatientCount.length - 1
                ].patientCount -
                  fetchedPatientStatistics.dailyPatientCount[0].patientCount) /
                  fetchedPatientStatistics.dailyPatientCount[0].patientCount) *
                  100
              );
            }
          } else {
            setPatientStatisticsChangePercentage(0);
          }
        }
      } catch (error) {
        console.error(error);
        setPatientStatisticChartData({ datasets: [] });
        setTotalPatient(0);
        setPatientStatisticsChangePercentage(0);
      }
    };

    bootstrapAsync();
  }, [saveDateRangePatient]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedStaffPerformances },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiCustomerRatings({
                startDate: dayjs(saveDateRangePatient[0].startDate).format(
                  "YYYY-MM-DD"
                ),
                endDate: dayjs(saveDateRangePatient[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              })
            : fetchStaffPerformances({
                startDate: dayjs(saveDateRangePatient[0].startDate).format(
                  "YYYY-MM-DD"
                ),
                endDate: dayjs(saveDateRangePatient[0].endDate).format(
                  "YYYY-MM-DD"
                ),
              }),
        ]);

        const chart = staffRatingChartRef.current;

        if (chart) {
          const gradient = chart.ctx.createLinearGradient(
            0,
            chart.chartArea.bottom,
            0,
            chart.chartArea.top
          );
          gradient.addColorStop(0.95, "#84E8F419");
          gradient.addColorStop(0.05, "#84E8F400");

          const staffRatingChartData = {
            labels:
              auth?.companyId === 9
                ? fetchedStaffPerformances.dailyRatings.map(
                    (daily) => daily.date
                  )
                : fetchedStaffPerformances.map(
                    (performance) => performance.dateTime
                  ),
            datasets: [
              {
                label: auth?.companyId === 9 ? "Rating" : "Staff Rating",
                data:
                  auth?.companyId === 9
                    ? fetchedStaffPerformances.dailyRatings.map(
                        (daily) => daily.dailyRating
                      )
                    : fetchedStaffPerformances.map(
                        (performance) => performance.rating
                      ),
                backgroundColor: gradient,
                fill: true,
              },
            ],
          };

          setStaffRatingChartData(staffRatingChartData);
        }

        if (auth?.companyId === 9) {
          if (fetchedStaffPerformances.dailyRatings.length > 0) {
            if (fetchedStaffPerformances.dailyRatings[0].dailyRating === 0) {
              setStaffPerformancesRatingChangePercentage(
                fetchedStaffPerformances.dailyRatings[
                  fetchedStaffPerformances.dailyRatings.length - 1
                ].dailyRating * 100
              );
            } else {
              setStaffPerformancesRatingChangePercentage(
                ((fetchedStaffPerformances.dailyRatings[
                  fetchedStaffPerformances.dailyRatings.length - 1
                ].dailyRating -
                  fetchedStaffPerformances.dailyRatings[0].dailyRating) /
                  fetchedStaffPerformances.dailyRatings[0].dailyRating) *
                  100 || 0
              );
            }

            setStaffPerformancesAverageRating(
              fetchedStaffPerformances.averageRating
            );
            setStaffPerformancesTotalCustomers(
              fetchedStaffPerformances.customerAmount
            );
            setStaffPerformancesLatestCustomerRatings(
              fetchedStaffPerformances.customers
            );
          } else {
            setStaffPerformancesRatingChangePercentage(0);
            setStaffPerformancesAverageRating(0);
            setStaffPerformancesTotalCustomers(0);
            setStaffPerformancesLatestCustomerRatings([]);
          }
        } else {
          if (fetchedStaffPerformances.length > 0) {
            if (fetchedStaffPerformances[0].rating === 0) {
              setStaffPerformancesRatingChangePercentage(
                fetchedStaffPerformances[fetchedStaffPerformances.length - 1]
                  .rating * 100
              );
              setStaffPerformancesAverageRating(
                fetchedStaffPerformances.reduce(
                  (previousCumulativeTotalPatients, currentOngoingTime) =>
                    previousCumulativeTotalPatients + currentOngoingTime.rating,
                  0
                ) / fetchedStaffPerformances.length
              );
            } else {
              setStaffPerformancesRatingChangePercentage(
                ((fetchedStaffPerformances[fetchedStaffPerformances.length - 1]
                  .rating -
                  fetchedStaffPerformances[0].rating) /
                  fetchedStaffPerformances[0].rating) *
                  100 || 0
              );
              setStaffPerformancesAverageRating(
                fetchedStaffPerformances.reduce(
                  (previousCumulativeTotalPatients, currentOngoingTime) =>
                    previousCumulativeTotalPatients + currentOngoingTime.rating,
                  0
                ) / fetchedStaffPerformances.length
              );
            }
          } else {
            setStaffPerformancesRatingChangePercentage(0);
            setStaffPerformancesAverageRating(0);
          }
        }
      } catch (error) {
        console.error(error);
        setStaffRatingChartData({ datasets: [] });
        setStaffPerformancesRatingChangePercentage(0);
        setStaffPerformancesAverageRating(0);
      }
    };

    bootstrapAsync();
  }, [saveDateRangePatient]);

  const [topCustomers, setTopCustomers] = useState([]);
  const [isOpenTopCustomers, setIsOpenTopCustomers] = useState(false);

  const fetchKanpaiTopCustomers = async ({ startDate, endDate }) => {
    const {
      data: {
        data: { orders },
      },
    } = await kanpaiTopCustomers({
      startDate,
      endDate,
      pageSize: 5,
      orderBy: "visit",
      direction: "desc",
    });

    setTopCustomers(orders);
  };

  useEffect(() => {
    auth?.companyId === 9 &&
      fetchKanpaiTopCustomers({
        startDate: dayjs(saveDateRangePatient[0].startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: dayjs(saveDateRangePatient[0].endDate).format("YYYY-MM-DD"),
      });
  }, [saveDateRangePatient]);

  const [selectedCustomerDetailsId, setSelectedCustomerDetailsId] =
    useState(null);
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState(null);
  const [selectedCustomerMostOrderedMenu, setSelectedCustomerMostOrderedMenu] =
    useState(null);
  const [
    selectedCustomerRecentOrderedMenu,
    setSelectedCustomerRecentOrderedMenu,
  ] = useState(null);
  const [selectedTabCustomerDetails, setSelectedTabCustomerDetails] =
    useState("most");
  const [isOpenCustomerDetails, setIsOpenCustomerDetails] = useState(false);
  const [isLoadingCustomerDetails, setIsLoadingCustomerDetails] =
    useState(false);

  const handleOpenCustomerDetails = (customerDetail) => {
    setSelectedCustomerDetailsId(customerDetail.customerId);
    setIsOpenCustomerDetails(true);
  };

  const fetchCustomerDetails = async ({ customerId, startDate, endDate }) => {
    try {
      const {
        data: {
          data: {
            customerDetail: customerDetails,
            mostOrderMenu: mostOrderedMenu,
            recentOrders: recentOrderedMenu,
          },
        },
      } = await kanpaiCustomerDetails({
        customerId,
        startDate,
        endDate,
      });

      setSelectedCustomerDetails(customerDetails);
      setSelectedCustomerMostOrderedMenu(mostOrderedMenu);
      setSelectedCustomerRecentOrderedMenu(recentOrderedMenu);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCustomerDetails(false);
    }
  };

  useEffect(() => {
    if (isOpenCustomerDetails) {
      setIsLoadingCustomerDetails(true);
      fetchCustomerDetails({
        customerId: selectedCustomerDetailsId,
        startDate: dayjs(saveDateRangePatient[0].startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: dayjs(saveDateRangePatient[0].endDate).format("YYYY-MM-DD"),
      });
    } else {
      setSelectedCustomerDetailsId(null);
      setSelectedCustomerDetails(null);
    }
  }, [isOpenCustomerDetails]);

  const [anchorElCustomerDetailsClick, setAnchorElCustomerDetailsClick] =
    useState(null);
  const isOpenCustomerDetailsClick = Boolean(anchorElCustomerDetailsClick);

  const [selectedTableMap, setSelectedTableMap] = useState("main");

  const tableMapLegends = [
    { icon: CheckCircleIcon, name: "Delivered", color: "primary.main" },
    { icon: ErrorIcon, name: "Alert", color: "#F33A3A" },
    { icon: CancelIcon, name: "Timeout", color: "#F33A3A" },
  ];

  const [mainFloorTables, setMainFloorTables] = useState(null);
  const [bobShopTables, setBobShopTables] = useState(null);
  const [vipRoomTables, setVipRoomTables] = useState(null);

  const [isMainFloorTableGrid, setIsMainFloorTableGrid] = useState(false);

  const mainFloorTable1 = [
    [10, 11, 12, null, 13, 14, null, 15, 16, 17],
    [null, null, null, null, null, null, null, null, null, null],
    [null, null, 9, null, null, 8, null, null, 7, null],
    [null, null, null, null, null, null, null, null, null, null],
    [null, 1, 2, null, 3, 4, null, 5, 6, null],
  ];

  const mainFloorTable2 = [
    [25, 26, 27, 28, 29, 30, 31, 32],
    [null, null, null, null, null, null, null, null],
    [null, 18, null, 19, null, 20, null, 21],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, 22, 23, 24],
  ];

  const mainFloorTable3 = [
    [null, null, 33, null, null],
    [null, 34, null, 40, null],
    [35, null, null, null, 39],
    [null, 36, null, 38, null],
    [null, null, 37, null, null],
  ];

  const [selectedTable, setSelectedTable] = useState(null);
  // const [tableDetails, setTableDetails] = useState([]);
  const [tableHistories, setTableHistories] = useState([]);
  const [tableHistoriesDate, setTableHistoriesDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [tableHistoriesInputDate, setTableHistoriesInputDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [isLoadingTableDetails, setIsLoadingTableDetails] = useState(false);
  const [isOpenTableDetails, setIsOpenTableDetails] = useState(false);

  const fetchTableDetails = async ({ tableId, date }) => {
    try {
      setIsLoadingTableDetails(true);
      const {
        data: { data: details },
      } = await kanpaiTableDetails({ tableId });

      // setTableDetails(details);

      const {
        data: { data: histories },
      } = await kanpaiTableHistories({ tableId, date });

      setTableHistories([...details, ...histories]);
    } catch (error) {
      setTableHistories([]);
      console.error(error);
    } finally {
      setIsLoadingTableDetails(false);
    }
  };

  useEffect(() => {
    if (isOpenTableDetails) {
      fetchTableDetails({
        tableId: selectedTable?.id,
        date: dayjs(tableHistoriesDate).format("YYYY-MM-DD"),
      });
    } else {
      setTableHistories([]);
      setTableHistoriesInputDate(dayjs().format("YYYY-MM-DD"));
      setTableHistoriesDate(dayjs().format("YYYY-MM-DD"));
    }
  }, [isOpenTableDetails, tableHistoriesDate]);

  const [isOpenOrderDetails, setIsOpenOrderDetails] = useState(false);
  const [selectedOrderDetailsDate, setSelectedOrderDetailsDate] =
    useState(null);
  const [dates, setDates] = useState([]);
  const [orderDetailsChartData, setOrderDetailsChartData] = useState({
    datasets: [],
  });
  const [orderDetailsQuantity, setOrderDetailsQuantity] = useState(null);
  const orderDetailsChartRef = useRef(null);

  const fetchOrderDetails = async ({ startDate, endDate, timeframe }) => {
    try {
      const {
        data: { data: details },
      } = await kanpaiOrderDetails({
        startDate,
        endDate,
        timeframe,
      });

      const chart = orderDetailsChartRef.current;

      const food = details.values.find(
        (value) => value.categoryProduct === "food"
      );
      const beverage = details.values.find(
        (value) => value.categoryProduct === "drink"
      );

      setOrderDetailsQuantity({ food, beverage });

      if (chart) {
        const chartData = {
          labels: ["Food", "Beverage"],
          datasets: [
            {
              label: "Orders",
              data: [food?.amount || 0, beverage?.amount || 0],
              yAxisID: "y1",
              backgroundColor: "#FEDB25",
              borderRadius: 4,
            },
            {
              label: "Customers",
              data: [food?.customer || 0, beverage?.customer || 0],
              yAxisID: "y2",
              backgroundColor: "#F17604",
              borderRadius: 4,
            },
          ],
        };

        setOrderDetailsChartData(chartData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [ordersPredictionChartData, setOrdersPredictionChartData] = useState({
    datasets: [],
  });
  const ordersPredictionChartRef = useRef(null);
  const orderPredictionsTypes = [
    { name: "Actual", value: "actual" },
    { name: "5%", value: "percentage5" },
    { name: "10%", value: "percentage10" },
    { name: "15%", value: "percentage15" },
    { name: "20%", value: "percentage20" },
  ];
  const [selectedOrderPredictionsType, setSelectedOrderPredictionsType] =
    useState({ name: "Actual", value: "actual" });

  const [anchorElOrderPredictionsType, setAnchorElOrderPredictionsType] =
    useState(null);
  const isOpenOrderPredictionsType = Boolean(anchorElOrderPredictionsType);
  const handleClickOrderPredictionsType = (event) => {
    setAnchorElOrderPredictionsType(event.currentTarget);
  };
  const handleCloseOrderPredictionsType = (value) => {
    setAnchorElOrderPredictionsType(null);
    setSelectedOrderPredictionsType(value);
  };

  const fetchBeverageOrdersPrediction = async ({
    startDate,
    endDate,
    timeframe,
    category,
  }) => {
    try {
      const {
        data: { data: predictions },
      } = await kanpaiOrderPredictions({
        startDate,
        endDate,
        timeframe,
        category,
      });

      const chart = ordersPredictionChartRef.current;

      const handlePredictions = (selectedPrediction) => {
        switch (selectedPrediction) {
          case "percentage5":
            return {
              label: "5%",
              data: predictions.yAxisValues.map((value) => value.percentage5),
            };
          case "percentage10":
            return {
              label: "10%",
              data: predictions.yAxisValues.map((value) => value.percentage10),
            };
          case "percentage15":
            return {
              label: "15%",
              data: predictions.yAxisValues.map((value) => value.percentage15),
            };
          case "percentage20":
            return {
              label: "20%",
              data: predictions.yAxisValues.map((value) => value.percentage20),
            };
          default:
            return {
              label: "Actual",
              data: predictions.yAxisValues.map((value) => value.actual),
            };
        }
      };

      if (chart) {
        const chartData = {
          labels: predictions.yAxisValues.map((value) => value.time),
          datasets: [
            {
              label: handlePredictions(selectedOrderPredictionsType?.value)
                .label,
              data: handlePredictions(selectedOrderPredictionsType?.value).data,
              yAxisID: "y1",
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
            },
            {
              label: "Customers",
              data: predictions.yAxisValues.map((value) => value.customer),
              yAxisID: "y2",
              backgroundColor: "#FEDD25",
              borderColor: "#FEDD25",
            },
          ],
        };

        setOrdersPredictionChartData(chartData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth?.companyId === 9 && isOpenOrderDetails) {
      fetchOrderDetails({
        startDate: selectedOrderDetailsDate?.startDate,
        endDate: selectedOrderDetailsDate?.endDate,
        timeframe: salesPerformanceTimeframe,
      });
    }
  }, [isOpenOrderDetails]);

  useEffect(() => {
    if (auth?.companyId === 9 && isOpenOrderDetails) {
      fetchBeverageOrdersPrediction({
        startDate: selectedOrderDetailsDate?.startDate,
        endDate: selectedOrderDetailsDate?.endDate,
        timeframe:
          salesPerformanceTimeframe === "daily"
            ? "hourly"
            : salesPerformanceTimeframe === "weekly"
            ? "daily"
            : "weekly",
        category: selectedCategoryOrderPredictions,
      });
    }
  }, [
    isOpenOrderDetails,
    selectedCategoryOrderPredictions,
    selectedOrderPredictionsType,
  ]);

  const [isSortDescTopSold, setIsSortDescTopSold] = useState(true);

  const [isExpandedTopSold, setIsExpandedTopSold] = useState(false);

  const [isOpenRecentOrders, setIsOpenRecentOrders] = useState(false);

  const [isChartTopSold, setIsChartTopSold] = useState(false);

  const [topSoldTimeframe, setTopSoldTimeframe] = useState("daily");
  const [topSoldChartData, setTopSoldChartData] = useState({
    datasets: [],
  });
  const topSoldChartRef = useRef(null);

  const fetchTopSoldChart = async ({
    startDate,
    endDate,
    timeframe,
    category,
  }) => {
    try {
      const {
        data: {
          data: { values },
        },
      } = await kanpaiTopSoldPerformance({
        startDate,
        endDate,
        timeframe,
        category,
      });

      const chart = topSoldChartRef.current;

      const colors = [
        theme.palette.primary.main,
        "#4339F2",
        "#FEDD25",
        "#D839F2",
        "#FD3A29",
      ];

      if (chart) {
        const chartData = {
          labels: values[0].value.map((value) => value.dateTime),
          datasets: values.map((each, index) => ({
            label: each.name,
            data: each.value.map((value) => value.amount),
            yAxisID: "y1",
            backgroundColor: colors[index],
            borderColor: colors[index],
          })),
        };

        setTopSoldChartData(chartData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth?.companyId === 9 && isChartTopSold)
      fetchTopSoldChart({
        startDate: dayjs(saveDateRangeOperational[0].startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: dayjs(saveDateRangeOperational[0].endDate).format(
          "YYYY-MM-DD"
        ),
        timeframe: topSoldTimeframe,
        category: selectedCategoryTopSold,
      });
  }, [
    topSoldTimeframe,
    saveDateRangeOperational,
    selectedCategoryTopSold,
    isChartTopSold,
  ]);

  const [dateRangeTablePerformance, setDateRangeTablePerformance] = useState([
    {
      startDate: new Date(dayjs().subtract(6, "d")),
      endDate: new Date(dayjs()),
      key: "selection",
    },
  ]);
  const [saveDateRangeTablePerformance, setSaveDateRangeTablePerformance] =
    useState([
      {
        startDate: new Date(dayjs().subtract(6, "d")),
        endDate: new Date(dayjs()),
        key: "selection",
      },
    ]);

  const [isShownTablePerformance, setIsShownTablePerformance] = useState(false);

  const [tablePerformanceChartData, setTablePerformanceChartData] = useState({
    datasets: [],
  });
  const tablePerformanceChartRef = useRef(null);

  const fetchTablePerformanceChart = async ({ startDate, endDate }) => {
    try {
      const {
        data: { data: tables },
      } = await kanpaiTablePerformance({ startDate, endDate });

      const chart = tablePerformanceChartRef.current;

      const colors = [
        theme.palette.primary.main,
        "#4339F2",
        "#FEDD25",
        "#D839F2",
        "#FD3A29",
      ];

      if (chart) {
        const chartData = {
          labels: tables[0].value.map((value) => value.time),
          datasets: tables.map((table, index) => ({
            label:
              table.name.split("-")[0] === "VIP"
                ? `VIP ${table.name.split("-")[2]}`
                : table.name.split("-")[0] === "BS"
                ? `Bob Shop ${table.name.split("-")[1]}`
                : `Main Floor ${table.name.split("-")[1]}`,
            data: table.value.map((value) => value.amount),
            yAxisID: "y1",
            backgroundColor: colors[index],
            borderColor: colors[index],
          })),
        };

        // console.log(chartData);
        setTablePerformanceChartData(chartData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth?.companyId === 9 && isShownTablePerformance) {
      fetchTablePerformanceChart({
        startDate: dayjs(saveDateRangeTablePerformance[0].startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: dayjs(saveDateRangeTablePerformance[0].endDate).format(
          "YYYY-MM-DD"
        ),
      });
    }
  }, [saveDateRangeTablePerformance, isShownTablePerformance]);

  const [isOpenLatestCustomerRatings, setIsOpenLatestCustomerRatings] =
    useState(false);

  // menampilkan start browser
  return (
    <>
      <Box
        sx={{
          bgcolor: "background.default",
          px: 1,
          mt: 1,
          pt: 1,
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <ButtonBase
              sx={{
                flexDirection: "column",
                p: 1,
                width: 1,
                alignItems: "initial",
              }}
              onClick={() => {
                setDateRangeSalesPerformance([
                  {
                    startDate: new Date(dayjs().subtract(6, "d")),
                    endDate: new Date(dayjs()),
                    key: "selection",
                  },
                ]);
                setSaveDateRangeSalesPerformance([
                  {
                    startDate: new Date(dayjs().subtract(6, "d")),
                    endDate: new Date(dayjs()),
                    key: "selection",
                  },
                ]);
                setSalesPerformanceTimeframe("daily");
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.625rem",
                  fontWeight: "semiBold",
                  color: "secondary.main",
                  textAlign: "initial",
                }}
              >
                Today's Revenue
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: "semiBold",
                  mt: 1,
                  textAlign: "initial",
                }}
              >
                {revenueSummary?.todayRevenue &&
                  formatNumberToCurrency(revenueSummary?.todayRevenue)}
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid item xs={6}>
            <ButtonBase
              sx={{
                flexDirection: "column",
                p: 1,
                width: 1,
                alignItems: "initial",
              }}
              onClick={() => {
                setDateRangeSalesPerformance([
                  {
                    startDate: new Date(dayjs().startOf("month")),
                    endDate: new Date(dayjs()),
                    key: "selection",
                  },
                ]);
                setSaveDateRangeSalesPerformance([
                  {
                    startDate: new Date(dayjs().startOf("month")),
                    endDate: new Date(dayjs()),
                    key: "selection",
                  },
                ]);
                setSalesPerformanceTimeframe("weekly");
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.625rem",
                  fontWeight: "semiBold",
                  color: "secondary.main",
                  textAlign: "initial",
                }}
              >
                This Month Revenue
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: "semiBold",
                  mt: 1,
                  textAlign: "initial",
                }}
              >
                {revenueSummary?.currentMonthRevenue &&
                  formatNumberToCurrency(revenueSummary?.currentMonthRevenue)}
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid item xs={6}>
            <ButtonBase
              sx={{
                flexDirection: "column",
                p: 1,
                width: 1,
                alignItems: "initial",
              }}
              onClick={() => {
                setDateRangeSalesPerformance([
                  {
                    startDate: new Date(dayjs().startOf("year")),
                    endDate: new Date(dayjs()),
                    key: "selection",
                  },
                ]);
                setSaveDateRangeSalesPerformance([
                  {
                    startDate: new Date(dayjs().startOf("year")),
                    endDate: new Date(dayjs()),
                    key: "selection",
                  },
                ]);
                setSalesPerformanceTimeframe("monthly");
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.625rem",
                  fontWeight: "semiBold",
                  color: "secondary.main",
                  textAlign: "initial",
                }}
              >
                This Year Revenue
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: "semiBold",
                  mt: 1,
                  textAlign: "initial",
                }}
              >
                {revenueSummary?.currentYearRevenue &&
                  formatNumberToCurrency(revenueSummary?.currentYearRevenue)}
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid item xs={6}>
            <ButtonBase
              sx={{
                flexDirection: "column",
                p: 1,
                width: 1,
                alignItems: "initial",
              }}
              onClick={() => {
                setDateRangeSalesPerformance([
                  {
                    startDate: new Date(
                      dayjs().subtract(1, "month").startOf("month")
                    ),
                    endDate: new Date(
                      dayjs().subtract(1, "month").endOf("month")
                    ),
                    key: "selection",
                  },
                ]);
                setSaveDateRangeSalesPerformance([
                  {
                    startDate: new Date(
                      dayjs().subtract(1, "month").startOf("month")
                    ),
                    endDate: new Date(
                      dayjs().subtract(1, "month").endOf("month")
                    ),
                    key: "selection",
                  },
                ]);
                setSalesPerformanceTimeframe("weekly");
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.625rem",
                  fontWeight: "semiBold",
                  color: "secondary.main",
                  textAlign: "initial",
                }}
              >
                Last Month Revenue
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: "semiBold",
                  mt: 1,
                  textAlign: "initial",
                }}
              >
                {revenueSummary?.lastMonthRevenue &&
                  formatNumberToCurrency(revenueSummary?.lastMonthRevenue)}
              </Typography>
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ bgcolor: "background.default", px: 2, pb: 1, pt: 2 }}>
        <Grid container direction="column" rowSpacing={1.875}>
          <Grid
            container
            item
            columnSpacing={1}
            wrap="nowrap"
            sx={{ alignItems: "center" }}
          >
            <Grid item xs>
              <Box
                sx={{
                  display: "inline-block",
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontWeight: "semiBold",
                    pb: 0.5,
                  }}
                >
                  Sales
                </Typography>
              </Box>
            </Grid>
            <Grid item xs="auto">
              <FormControl>
                <Select
                  inputProps={{
                    sx: {
                      fontSize: "0.75rem",
                      width: "1rem",
                      display: "flex",
                    },
                  }}
                  sx={{
                    height: "1.6875rem",
                  }}
                  onChange={(event) => {
                    setSalesPerformanceTimeframe(event.target.value);
                  }}
                  value={salesPerformanceTimeframe}
                >
                  <MenuItem value="daily">D</MenuItem>
                  <MenuItem value="weekly">W</MenuItem>
                  <MenuItem value="monthly">M</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="auto">
              <CustomDateRange
                dateRange={dateRangeSalesPerformance}
                setDateRange={setDateRangeSalesPerformance}
                saveDateRange={saveDateRangeSalesPerformance}
                setSaveDateRange={setSaveDateRangeSalesPerformance}
                sx={{
                  fontSize: "0.625rem",
                  fontWeight: "medium",
                  "& .MuiOutlinedInput-input": {
                    px: 0,
                    pt: 1,
                    pb: 0.625,
                  },
                  pl: 1,
                  maxWidth: "150px",
                }}
                iconSize="0.75rem"
              />
            </Grid>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
            >
              <SalesIcon
                width="16px"
                height="16px"
                fill={theme.palette.primary.main}
              />
              <OrderIcon width="16px" height="16px" fill="#FFCF56" />
            </Box>
            {/* order details */}

            <Box
              sx={{
                height: 1,
                // mx: '-16px',
              }}
            >
              <Bar
                style={{ overflow: "visible" }}
                data={salesPerformanceChartData}
                ref={salesPerformanceChartRef}
                onClick={(event) => {
                  if (auth?.companyId === 9) {
                    const element = getElementAtEvent(
                      salesPerformanceChartRef.current,
                      event
                    );

                    if (element[0].datasetIndex === 1) {
                      setIsOpenOrderDetails(true);

                      if (salesPerformanceTimeframe === "daily") {
                        setSelectedOrderDetailsDate({
                          startDate: dates[element[0].index],
                          endDate: dates[element[0].index],
                        });
                      } else if (salesPerformanceTimeframe === "weekly") {
                        setSelectedOrderDetailsDate({
                          startDate:
                            dayjs(saveDateRangeSalesPerformance[0].startDate) >
                            dayjs(dates[element[0].index])
                              ? dayjs(
                                  saveDateRangeSalesPerformance[0].startDate
                                ).format("YYYY-MM-DD")
                              : dayjs(dates[element[0].index]).format(
                                  "YYYY-MM-DD"
                                ),
                          endDate:
                            dayjs(dates[element[0].index]).add(6, "day") >
                            dayjs(saveDateRangeSalesPerformance[0].endDate)
                              ? dayjs(
                                  saveDateRangeSalesPerformance[0].endDate
                                ).format("YYYY-MM-DD")
                              : dayjs(dates[element[0].index])
                                  .add(6, "day")
                                  .format("YYYY-MM-DD"),
                        });
                      } else {
                        setSelectedOrderDetailsDate({
                          startDate:
                            dayjs(saveDateRangeSalesPerformance[0].startDate) >
                            dayjs(dates[element[0].index])
                              ? dayjs(
                                  saveDateRangeSalesPerformance[0].startDate
                                ).format("YYYY-MM-DD")
                              : dayjs(dates[element[0].index]).format(
                                  "YYYY-MM-DD"
                                ),
                          endDate:
                            dayjs(dates[element[0].index])
                              .add(1, "month")
                              .subtract(1, "day") >
                            dayjs(saveDateRangeSalesPerformance[0].endDate)
                              ? dayjs(
                                  saveDateRangeSalesPerformance[0].endDate
                                ).format("YYYY-MM-DD")
                              : dayjs(dates[element[0].index])
                                  .add(1, "month")
                                  .subtract(1, "day")
                                  .format("YYYY-MM-DD"),
                        });
                      }
                    }
                  }
                }}
                plugins={[
                  {
                    afterDraw: (chart) => {
                      const ctx = chart.ctx;
                      ctx.save();
                      ctx.font = "600 12px Poppins";
                      ctx.fillStyle = theme.palette.primary.main;

                      ctx.textAlign = "center";
                      ctx.fillStyle = "#B8B8B8";
                      ctx.fillText(
                        chart.scales.x.options.title.text,
                        chart.width / 2,
                        chart.height - 29
                      );
                      ctx.restore();
                    },
                  },
                ]}
                options={{
                  aspectRatio: 1,
                  layout: {
                    padding: {
                      left: 16,
                      right: 16,
                    },
                  },
                  plugins: {
                    annotation: {
                      // clip: false,
                      annotations: {
                        annotation1: {
                          type: "line",
                          borderColor: theme.palette.primary.main,
                          borderDash: [5, 5],
                          borderDashOffset: 0,
                          borderWidth: 1,
                          label: {
                            backgroundColor: theme.palette.primary.main,
                            enabled: true,
                            content: (ctx) =>
                              (average(ctx, 0) / 1000000).toFixed(2),
                            position: "start",
                            xAdjust: (ctx) =>
                              -24 -
                              (average(ctx, 0) / 1000000).toFixed(2).length * 5,
                          },
                          yScaleID: "y1",
                          yMin: (ctx) => average(ctx, 0),
                          xMax: (ctx) =>
                            ctx.chart.data.datasets.length > 0
                              ? ctx.chart.data.datasets[0].data.length - 1
                              : 0,
                          yMax: (ctx) => average(ctx, 0),
                        },
                        annotation2: {
                          type: "line",
                          borderColor: "#FFCF56",
                          borderDash: [5, 5],
                          borderDashOffset: 0,
                          borderWidth: 1,
                          label: {
                            backgroundColor: "#FFCF56",
                            color: "black",
                            enabled: true,
                            content: (ctx) => average(ctx, 1).toFixed(2),
                            position: "end",
                            xAdjust: (ctx) =>
                              24 + average(ctx, 1).toFixed(2).length * 5,
                          },
                          yScaleID: "y2",
                          yMin: (ctx) => average(ctx, 1),
                          xMin: 0,
                          xMax: (ctx) =>
                            ctx.chart.data.datasets.length > 0
                              ? ctx.chart.data.datasets[1].data.length
                              : 0,
                          yMax: (ctx) => average(ctx, 1),
                        },
                      },
                    },
                    legend: {
                      labels: {
                        color: "#B8B8B8",
                        font: {
                          size: 12,
                          weight: 600,
                          family: "Poppins",
                        },
                        usePointStyle: true,
                        pointStyle: "rect",
                      },
                      position: "bottom",
                      onClick: function (e, legendItem, legend) {
                        const index = legendItem.datasetIndex;
                        const ci = legend.chart;
                        ci.options.scales[`y${index + 1}`].display =
                          !ci.options.scales[`y${index + 1}`].display;
                        ci.options.plugins.annotation.annotations[
                          `annotation${index + 1}`
                        ].display =
                          !ci.options.plugins.annotation.annotations[
                            `annotation${index + 1}`
                          ].display;
                        ci.update();

                        if (ci.isDatasetVisible(index)) {
                          ci.hide(index);
                          legendItem.hidden = true;
                        } else {
                          ci.show(index);
                          legendItem.hidden = false;
                        }
                      },
                    },
                    tooltip: {
                      callbacks: {
                        title: (tooltipItems) => {
                          const date = tooltipItems[0].label;
                          const day = dayjs(date).format("D");
                          const month = dayjs(date).format("MMM");

                          switch (salesPerformanceTimeframe) {
                            case "daily":
                              return day + ordinalSuffixOf(day) + " " + month;

                            case "weekly":
                              return `${
                                tooltipItems[0].label.split(" ")[0]
                              }${ordinalSuffixOf(
                                tooltipItems[0].label.split(" ")[0]
                              )} Week ${tooltipItems[0].label.split(" ")[1]}`;

                            default:
                              return tooltipItems[0].label;
                          }
                        },
                      },
                    },
                  },
                  interaction: {
                    intersect: true,
                  },
                  scales: {
                    y1: {
                      ticks: {
                        color: "#B8B8B8",
                        font: {
                          size: 12,
                          weight: 600,
                          family: "Poppins",
                        },
                        callback: function (value) {
                          return value / 1000000;
                        },
                      },
                    },
                    y2: {
                      ticks: {
                        color: "#B8B8B8",
                        font: {
                          size: 12,
                          weight: 600,
                          family: "Poppins",
                        },
                      },
                      position: "right",
                    },
                    x: {
                      ticks: {
                        color: "#B8B8B8",
                        font: {
                          size: 12,
                          weight: 600,
                          family: "Poppins",
                        },
                        callback(value) {
                          const newLabel = this.getLabelForValue(value);
                          const day = dayjs(newLabel).format("D");

                          return salesPerformanceTimeframe === "monthly"
                            ? `${newLabel}`.split(" ")[0]
                            : salesPerformanceTimeframe === "daily"
                            ? day + ordinalSuffixOf(day)
                            : `${`${newLabel}`.split(" ")[0]}${ordinalSuffixOf(
                                `${newLabel}`.split(" ")[0]
                              )}`;
                        },
                      },
                      title: {
                        color: "#B8B8B8",
                        font: {
                          size: 12,
                          weight: 600,
                          family: "Poppins",
                        },
                        text: getXAxisLabel(salesPerformanceTimeframe),
                      },
                    },
                  },
                }}
              />
              <CustomBottomDrawer
                open={isOpenOrderDetails}
                onClose={() => setIsOpenOrderDetails(false)}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                      }}
                    >
                      <Typography sx={{ fontWeight: "semiBold" }}>
                        Order Details
                      </Typography>
                      <Typography
                        sx={{ color: "#9E9D9D", fontSize: "0.625rem" }}
                      >
                        {dayjs(selectedOrderDetailsDate?.startDate).format("D")}
                        {ordinalSuffixOf(
                          dayjs(selectedOrderDetailsDate?.startDate).format("D")
                        )}{" "}
                        {dayjs(selectedOrderDetailsDate?.startDate).format(
                          `MMM 'YY`
                        )}
                        {salesPerformanceTimeframe !== "daily" &&
                          ` - ${dayjs(selectedOrderDetailsDate?.endDate).format(
                            "D"
                          )}${ordinalSuffixOf(
                            dayjs(selectedOrderDetailsDate?.endDate).format("D")
                          )} ${dayjs(selectedOrderDetailsDate?.endDate).format(
                            `MMM 'YY`
                          )}`}
                      </Typography>
                    </Box>
                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setIsOpenOrderDetails(false)}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Bar
                      data={orderDetailsChartData}
                      ref={orderDetailsChartRef}
                      style={{ height: 200 }}
                      plugins={[
                        {
                          afterDraw: (chart) => {
                            const ctx = chart.ctx;
                            ctx.save();
                            ctx.font = "600 12px Poppins";
                            ctx.fillStyle = theme.palette.primary.main;

                            ctx.textAlign = "center";
                            ctx.fillStyle = "#B8B8B8";
                            ctx.fillText(
                              chart.scales.x.options.title.text,
                              chart.width / 2,
                              chart.height - 29
                            );
                            ctx.restore();
                          },
                        },
                      ]}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            labels: {
                              color: "#303030",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              usePointStyle: true,
                              pointStyle: "rect",
                            },
                            position: "bottom",
                            align: "center",
                          },
                          tooltip: {
                            callbacks: {
                              title: (tooltipItems) => tooltipItems[0].label,
                            },
                          },
                        },
                        interaction: {
                          intersect: true,
                        },
                        scales: {
                          y1: {
                            ticks: {
                              color: "#9E9D9D",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              callback: (value) => {
                                return value;
                              },
                            },
                          },
                          y2: {
                            position: "right",
                            ticks: {
                              color: "#9E9D9D",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              callback: (value) => {
                                return value;
                              },
                            },
                          },
                          x: {
                            ticks: {
                              color: "#9E9D9D",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              callback(value) {
                                return this.getLabelForValue(value);
                              },
                            },
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Grid container alignItems={"center"} columnSpacing={1}>
                      <Grid item xs>
                        <Box sx={{ height: 1 }}>
                          <Typography
                            sx={{ fontWeight: "semiBold", fontSize: "0.75rem" }}
                          >
                            Order Prediction
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs="auto">
                        <Box
                          sx={{
                            display: "inline-flex",
                            borderRadius: "5px",
                            p: 0.5,
                            bgcolor: "#F5F5F5",
                            height: 1,
                          }}
                        >
                          <Box
                            sx={{
                              py: 0.5,
                              px: 1,
                              borderRadius: "5px",
                              textAlign: "center",
                              fontWeight: "semiBold",
                              fontSize: "0.625rem",
                              color:
                                selectedCategoryOrderPredictions === "food" &&
                                "primary.main",
                              bgcolor:
                                selectedCategoryOrderPredictions === "food" &&
                                "#fff",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedCategoryOrderPredictions("food");
                            }}
                          >
                            Food
                          </Box>
                          <Box
                            sx={{
                              py: 0.5,
                              px: 1,
                              borderRadius: "5px",
                              textAlign: "center",
                              fontWeight: "semiBold",
                              fontSize: "0.625rem",
                              color:
                                selectedCategoryOrderPredictions ===
                                  "beverage" && "primary.main",
                              bgcolor:
                                selectedCategoryOrderPredictions ===
                                  "beverage" && "#fff",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedCategoryOrderPredictions("beverage");
                            }}
                          >
                            Beverage
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs="auto" height={"31px"}>
                        <Box
                          sx={{
                            bgcolor: "#F5F5F5",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            height: 1,
                            p: 1,
                          }}
                          onClick={handleClickOrderPredictionsType}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.625rem",
                              fontWeight: "semiBold",
                              textTransform: "capitalize",
                            }}
                          >
                            {selectedOrderPredictionsType?.name}
                          </Typography>
                          <ArrowDropDownIcon sx={{ fontSize: "0.75rem" }} />
                        </Box>
                        <Menu
                          open={isOpenOrderPredictionsType}
                          anchorEl={anchorElOrderPredictionsType}
                          onClose={() => setAnchorElOrderPredictionsType(null)}
                        >
                          {orderPredictionsTypes.map((type, index) => (
                            <MenuItem
                              key={index}
                              onClick={() =>
                                handleCloseOrderPredictionsType(type)
                              }
                            >
                              {type.name}
                            </MenuItem>
                          ))}
                        </Menu>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Line
                      data={ordersPredictionChartData}
                      ref={ordersPredictionChartRef}
                      style={{ height: 200 }}
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              color: "#303030",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              usePointStyle: true,
                              pointStyle: "rect",
                            },
                            position: "bottom",
                            align: "center",
                          },
                          tooltip: {
                            displayColors: false,
                            callbacks: {
                              title: (tooltipItems) => {
                                const label = tooltipItems[0].label;
                                switch (salesPerformanceTimeframe) {
                                  case "daily":
                                    return label.substring(0, 5);
                                  case "weekly":
                                    return `${
                                      dayjs(label).format("MMM DD") +
                                      ordinalSuffixOf(dayjs(label).format("D"))
                                    }`;
                                  default:
                                    return label + ordinalSuffixOf(label);
                                }
                              },
                            },
                          },
                        },
                        interaction: {
                          intersect: false,
                        },
                        scales: {
                          y1: {
                            ticks: {
                              color: theme.palette.primary.main,
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              callback: (value) => value,
                            },
                          },
                          y2: {
                            position: "right",
                            ticks: {
                              color: "#FEDB25",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              callback: (value) => value,
                            },
                          },
                          x: {
                            title: {
                              color: "#9E9D9D",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              text:
                                salesPerformanceTimeframe === "daily"
                                  ? "Hour"
                                  : salesPerformanceTimeframe === "weekly"
                                  ? "Day of Month"
                                  : "Week",
                            },
                            ticks: {
                              color: "#9E9D9D",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              callback(value) {
                                const label = this.getLabelForValue(value);

                                switch (salesPerformanceTimeframe) {
                                  case "daily":
                                    return label.substring(0, 5);
                                  case "weekly":
                                    return (
                                      dayjs(label).format("D") +
                                      ordinalSuffixOf(dayjs(label).format("D"))
                                    );
                                  default:
                                    return label + ordinalSuffixOf(label);
                                }
                              },
                            },
                          },
                        },
                        elements: {
                          point: {
                            radius: 0,
                          },
                          line: {
                            tension: 0.5,
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "semiBold",
                        fontSize: "0.75rem",
                        textTransform: "capitalize",
                      }}
                    >
                      {selectedCategoryOrderPredictions} Orders Prediction
                    </Typography>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        fontSize: "0.625rem",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <Typography
                          sx={{ fontWeight: "medium", fontSize: "0.625rem" }}
                        >
                          Actual
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "semiBold", fontSize: "0.625rem" }}
                        >
                          {selectedCategoryOrderPredictions === "food"
                            ? parseInt(orderDetailsQuantity?.food?.amount)
                            : parseInt(orderDetailsQuantity?.beverage?.amount)}
                        </Typography>
                      </Box>
                      |
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <Typography
                          sx={{ fontWeight: "medium", fontSize: "0.625rem" }}
                        >
                          5%
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "semiBold", fontSize: "0.625rem" }}
                        >
                          {selectedCategoryOrderPredictions === "food"
                            ? parseInt(orderDetailsQuantity?.food?.persentage5)
                            : parseInt(
                                orderDetailsQuantity?.beverage?.persentage5
                              )}
                        </Typography>
                      </Box>
                      |
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <Typography
                          sx={{ fontWeight: "medium", fontSize: "0.625rem" }}
                        >
                          10%
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "semiBold", fontSize: "0.625rem" }}
                        >
                          {selectedCategoryOrderPredictions === "food"
                            ? parseInt(orderDetailsQuantity?.food?.persentage10)
                            : parseInt(
                                orderDetailsQuantity?.beverage?.persentage10
                              )}
                        </Typography>
                      </Box>
                      |
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <Typography
                          sx={{ fontWeight: "medium", fontSize: "0.625rem" }}
                        >
                          15%
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "semiBold", fontSize: "0.625rem" }}
                        >
                          {selectedCategoryOrderPredictions === "food"
                            ? parseInt(orderDetailsQuantity?.food?.persentage15)
                            : parseInt(
                                orderDetailsQuantity?.beverage?.persentage15
                              )}
                        </Typography>
                      </Box>
                      |
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <Typography
                          sx={{ fontWeight: "medium", fontSize: "0.625rem" }}
                        >
                          20%
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "semiBold", fontSize: "0.625rem" }}
                        >
                          {selectedCategoryOrderPredictions === "food"
                            ? parseInt(orderDetailsQuantity?.food?.persentage20)
                            : parseInt(
                                orderDetailsQuantity?.beverage?.persentage20
                              )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </CustomBottomDrawer>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {auth?.role !== "investment" && (
        <>
          <Box sx={{ bgcolor: "background.default", p: 2, mt: 1 }}>
            <Grid container sx={{ alignItems: "center" }} columnSpacing={1}>
              <Grid item xs>
                <Typography
                  sx={{
                    fontWeight: "semiBold",
                  }}
                >
                  {auth?.companyId === 9 ? "Patron" : "Patient"}(s)
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <CustomDateRange
                  dateRange={dateRangePatient}
                  setDateRange={setDateRangePatient}
                  saveDateRange={saveDateRangePatient}
                  setSaveDateRange={setSaveDateRangePatient}
                  sx={{
                    fontSize: "0.625rem",
                    fontWeight: "medium",
                    "& .MuiOutlinedInput-input": {
                      px: 0,
                      pt: 1,
                      pb: 0.75,
                    },
                    pl: 1,
                    maxWidth: "150px",
                  }}
                  iconSize="0.75rem"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minHeight: "1.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        patientStatisticsChangePercentage > 0
                          ? "success.main"
                          : patientStatisticsChangePercentage < 0
                          ? "error.main"
                          : "grey.500",

                      fontWeight: "medium",
                    }}
                  >
                    {`${patientStatisticsChangePercentage.toFixed(2)}%`}
                  </Typography>
                  {patientStatisticsChangePercentage < 0 ? (
                    <ArrowDropDownIcon
                      sx={{
                        color: "error.main",
                        ml: 0.5,
                      }}
                    />
                  ) : patientStatisticsChangePercentage > 0 ? (
                    <ArrowDropUpIcon
                      sx={{
                        color: "success.main",
                        ml: 0.5,
                      }}
                    />
                  ) : null}
                </Box>
                <Line
                  data={patientStatisticChartData}
                  ref={patientStatisticChartRef}
                  options={{
                    plugins: {
                      legend: { display: false },
                      tooltip: {
                        displayColors: false,
                        callbacks: {
                          title: (tooltipItems) =>
                            dayjs(tooltipItems[0].label).format("DD/MMM/YY"),
                        },
                      },
                    },
                    interaction: {
                      intersect: false,
                    },
                    scales: {
                      yAxes: {
                        display: false,
                      },
                      xAxes: {
                        display: false,
                      },
                    },
                    elements: {
                      point: {
                        radius: 0,
                      },
                      line: {
                        tension: 0.4,
                        borderColor: "#84E8F4",
                      },
                    },
                  }}
                />
                <Box
                  onClick={() => {
                    auth?.companyId === 9 &&
                      setIsOpenTopCustomers(!isOpenTopCustomers);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: "medium",
                      mt: 1,
                      color: "ternary.main",
                    }}
                  >
                    {totalPatient}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 1,
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.75rem",
                        fontWeight: "medium",
                        color: "grey.500",
                      }}
                    >
                      Total {auth?.companyId === 9 ? "Customers" : "Patients"}
                    </Typography>
                    {auth?.companyId === 9 && (
                      <OpenInNewIcon
                        color="primary"
                        sx={{ fontSize: "16px" }}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minHeight: "1.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        staffPerformancesRatingChangePercentage > 0
                          ? "success.main"
                          : staffPerformancesRatingChangePercentage < 0
                          ? "error.main"
                          : "grey.500",

                      fontWeight: "medium",
                    }}
                  >
                    {`${staffPerformancesRatingChangePercentage.toFixed(2)}%`}
                  </Typography>
                  {staffPerformancesRatingChangePercentage < 0 ? (
                    <ArrowDropDownIcon
                      sx={{
                        color: "error.main",
                        ml: 0.5,
                      }}
                    />
                  ) : staffPerformancesRatingChangePercentage > 0 ? (
                    <ArrowDropUpIcon
                      sx={{
                        color: "success.main",
                        ml: 0.5,
                      }}
                    />
                  ) : null}
                </Box>
                <Line
                  data={staffRatingChartData}
                  ref={staffRatingChartRef}
                  options={{
                    plugins: {
                      legend: { display: false },
                      tooltip: {
                        displayColors: false,
                        callbacks: {
                          title: (tooltipItems) =>
                            dayjs(tooltipItems[0].label).format("DD/MMM/YY"),
                        },
                      },
                    },
                    interaction: {
                      intersect: false,
                    },
                    scales: {
                      yAxes: {
                        display: false,
                      },
                      xAxes: {
                        display: false,
                      },
                    },
                    elements: {
                      point: {
                        radius: 0,
                      },
                      line: {
                        tension: 0.4,
                        borderColor: "#84E8F4",
                      },
                    },
                  }}
                />
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: "medium",
                      color: "ternary.main",
                    }}
                  >
                    {staffPerformancesAverageRating.toFixed(2)}
                  </Typography>
                  {auth?.companyId === 9 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        bgcolor: "primary.main",
                        color: "#fff",
                        borderRadius: "5px",
                        height: "20px",
                        px: 0.5,
                        cursor: "pointer",
                      }}
                      onClick={() => setIsOpenLatestCustomerRatings(true)}
                    >
                      <PersonIcon sx={{ fontSize: "0.75rem" }} />
                      <Typography sx={{ fontSize: "0.625rem" }}>
                        {staffPerformancesTotalCustomers}
                      </Typography>
                    </Box>
                  )}
                </Box>
                {/* bottom drawer latest customer ratings */}
                {auth?.companyId === 9 && (
                  <CustomBottomDrawer
                    open={isOpenLatestCustomerRatings}
                    onClose={() => setIsOpenLatestCustomerRatings(false)}
                    padding={0}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        alignItems: "center",
                        p: 2,
                      }}
                    >
                      <Typography sx={{ fontWeight: "semiBold" }}>
                        Customer Rating
                      </Typography>
                      <CloseIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => setIsOpenLatestCustomerRatings(false)}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      {staffPerformancesLatestCustomerRatings.length > 0 ? (
                        <Grid container columnSpacing={1}>
                          <Grid item container xs={12} sx={{ mx: 2 }}>
                            <Grid item xs={5.5}>
                              <Typography
                                sx={{
                                  fontWeight: "semiBold",
                                  fontSize: "0.75rem",
                                }}
                              >
                                Name
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography
                                sx={{
                                  fontWeight: "semiBold",
                                  fontSize: "0.75rem",
                                }}
                              >
                                Rating
                              </Typography>
                            </Grid>
                            <Grid item xs="auto">
                              <Typography
                                align="right"
                                sx={{
                                  fontWeight: "semiBold",
                                  fontSize: "0.75rem",
                                }}
                              >
                                Date
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sx={{ maxHeight: "40vh", overflowY: "scroll" }}
                            columnSpacing={1}
                          >
                            {staffPerformancesLatestCustomerRatings.map(
                              (customer, index) => (
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  alignItems="center"
                                  key={index}
                                  sx={{ mt: 2 }}
                                >
                                  <Grid item xs={5.5} paddingLeft={2}>
                                    <Typography sx={{ fontSize: "0.75rem" }}>
                                      {customer.customerName}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Box
                                      sx={{
                                        fontSize: "0.75rem",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <StarIcon
                                        sx={{
                                          fontSize: "0.75rem",
                                          color: "#EACD57",
                                        }}
                                      />
                                      {customer.averageRating.toFixed(2)}
                                    </Box>
                                  </Grid>
                                  <Grid item xs="auto" paddingRight={2}>
                                    <Typography
                                      align="right"
                                      sx={{ fontSize: "0.75rem" }}
                                    >
                                      {dayjs(customer.date).format("DD/MMM/YY")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                      ) : (
                        <Typography sx={{ fontSize: "0.75rem" }}>
                          No data
                        </Typography>
                      )}
                    </Box>
                  </CustomBottomDrawer>
                )}
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "medium",
                    mt: 1,
                    color: "grey.500",
                  }}
                >
                  Average {auth?.companyId !== 9 && "Staff"} Rating
                </Typography>
              </Grid>
            </Grid>
            {/* Top 5 Customers */}
            {auth?.companyId === 9 && isOpenTopCustomers && (
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "inline-block",
                        borderBottom: 1,
                        borderColor: "divider",
                        textDecoration: "initial",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          fontWeight: "semiBold",
                        }}
                      >
                        Top 5 Customer Visit
                        <Typography
                          component={"span"}
                          sx={{ fontWeight: "semiBold", fontSize: "0.5rem" }}
                        >
                          (S)
                        </Typography>
                      </Typography>
                    </Box>
                    <KeyboardArrowUpIcon
                      sx={{ cursor: "pointer", zIndex: 2 }}
                      onClick={() => {
                        setIsOpenTopCustomers(false);
                      }}
                    />
                  </Box>
                  <Box>
                    <Grid container columnSpacing={2}>
                      {topCustomers.map((customer) => (
                        <Grid
                          item
                          xs={12}
                          container
                          key={customer.customerId}
                          sx={{ mt: 2 }}
                          columnSpacing={2}
                          onClick={() => handleOpenCustomerDetails(customer)}
                        >
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: "medium",
                                fontSize: "0.75rem",
                              }}
                              noWrap
                            >
                              {customer.name?.toLowerCase().split(" ")[0]}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                fontWeight: "medium",
                                fontSize: "0.75rem",
                                color: "primary.main",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                kanpaiCustomerAddClick({
                                  customerId: customer.customerId,
                                });
                                window.open(
                                  `https://api.whatsapp.com/send/?phone=%2B${
                                    customer.userKada?.countryCode +
                                    customer.userKada?.phoneNumber
                                  }&text&type=phone_number&app_absent=0`
                                );
                              }}
                              // align="right"
                            >
                              {hidePhoneNumber(customer.userKada?.phoneNumber)}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              sx={{ fontWeight: "medium", fontSize: "0.75rem" }}
                              align="right"
                            >
                              {customer.visit || "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            )}

            {auth?.companyId === 9 && (
              <CustomBottomDrawer
                open={isOpenCustomerDetails}
                onClose={() => {
                  setIsOpenCustomerDetails(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "semiBold" }}>
                    Customer Details
                  </Typography>
                  <CloseIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsOpenCustomerDetails(false)}
                  />
                </Box>
                {!isLoadingCustomerDetails &&
                  selectedCustomerDetails &&
                  selectedCustomerMostOrderedMenu &&
                  selectedCustomerRecentOrderedMenu && (
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "0.75rem", fontWeight: "medium" }}
                        >
                          Name
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "0.75rem",
                            fontWeight: "semiBold",
                            textTransform: "capitalize",
                          }}
                        >
                          {selectedCustomerDetails?.userDetail?.name || "-"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "0.75rem", fontWeight: "medium" }}
                          >
                            Phone number
                          </Typography>
                          {/* Click Amount */}
                          {selectedCustomerDetails?.click?.amount > 0 && (
                            <>
                              <Box
                                onClick={(event) => {
                                  setAnchorElCustomerDetailsClick(
                                    event.currentTarget
                                  );
                                }}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 0.5,
                                  bgcolor: "primary.main",
                                  borderRadius: "5px",
                                  p: 0.5,
                                }}
                              >
                                <Typography
                                  sx={{ color: "#fff", fontSize: "0.625rem" }}
                                >
                                  {selectedCustomerDetails?.click?.amount}x
                                </Typography>
                                <InfoIcon
                                  sx={{ color: "#fff", fontSize: "0.625rem" }}
                                />
                              </Box>
                              <Menu
                                anchorEl={anchorElCustomerDetailsClick}
                                open={isOpenCustomerDetailsClick}
                                onClose={() => {
                                  setAnchorElCustomerDetailsClick(null);
                                }}
                              >
                                <Box
                                  sx={{
                                    px: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                  }}
                                >
                                  {selectedCustomerDetails?.click?.detail.map(
                                    (detail) => (
                                      <Typography
                                        key={detail.id}
                                        sx={{
                                          fontWeight: "semiBold",
                                          fontSize: "0.625rem",
                                          color: "#9E9D9D",
                                        }}
                                      >
                                        {dayjs(detail.createdAt).format(
                                          "DD/MMM/YY, HH:mm"
                                        )}
                                      </Typography>
                                    )
                                  )}
                                </Box>
                              </Menu>
                            </>
                          )}
                        </Box>
                        <Typography
                          sx={{
                            fontWeight: "semiBold",
                            fontSize: "0.75rem",
                            color: "primary.main",
                          }}
                          onClick={() => {
                            kanpaiCustomerAddClick({
                              customerId:
                                selectedCustomerDetails?.userDetail?.customerId,
                            });
                            window.open(
                              `https://api.whatsapp.com/send/?phone=%2B${
                                selectedCustomerDetails?.userDetail?.userKada
                                  ?.countryCode +
                                selectedCustomerDetails?.userDetail?.userKada
                                  ?.phoneNumber
                              }&text&type=phone_number&app_absent=0`
                            );
                          }}
                          // align="right"
                        >
                          {hidePhoneNumber(
                            selectedCustomerDetails?.userDetail?.userKada
                              ?.phoneNumber
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "0.75rem", fontWeight: "medium" }}
                        >
                          Number of visit(s)
                        </Typography>
                        <Typography
                          sx={{ fontSize: "0.75rem", fontWeight: "semiBold" }}
                        >
                          {selectedCustomerDetails?.visit || "-"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "0.75rem", fontWeight: "medium" }}
                        >
                          Total Spent
                        </Typography>
                        <Typography
                          sx={{ fontSize: "0.75rem", fontWeight: "semiBold" }}
                        >
                          {formatNumberToCurrency(
                            selectedCustomerDetails?.spent
                          ) || "-"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "0.75rem", fontWeight: "medium" }}
                        >
                          Average Spent
                        </Typography>
                        <Typography
                          sx={{ fontSize: "0.75rem", fontWeight: "semiBold" }}
                        >
                          {formatNumberToCurrency(
                            selectedCustomerDetails?.avgBuyed
                          ) || "-"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          borderRadius: "8px",
                          bgcolor: "#FAFAFA",
                          p: 2,
                          pb: 0,
                          mt: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            width: 1,
                            bgcolor: "#F5F5F5",
                            borderRadius: "8px",
                            p: 0.5,
                            display: "flex",
                            flexGrow: 1,
                          }}
                        >
                          <Box
                            sx={{
                              flexGrow: 1,
                              bgcolor:
                                selectedTabCustomerDetails === "most" && "#fff",
                              color:
                                selectedTabCustomerDetails === "most" &&
                                "primary.main",
                              textAlign: "center",
                              fontSize: "12px",
                              py: 0.5,
                              borderRadius: "8px",
                            }}
                            onClick={() =>
                              setSelectedTabCustomerDetails("most")
                            }
                          >
                            Most Order
                          </Box>
                          <Box
                            sx={{
                              flexGrow: 1,
                              bgcolor:
                                selectedTabCustomerDetails === "recent" &&
                                "#fff",
                              color:
                                selectedTabCustomerDetails === "recent" &&
                                "primary.main",
                              textAlign: "center",
                              fontSize: "12px",
                              py: 0.5,
                              borderRadius: "8px",
                            }}
                            onClick={() =>
                              setSelectedTabCustomerDetails("recent")
                            }
                          >
                            Recent Order
                          </Box>
                        </Box>
                        {selectedTabCustomerDetails === "most" &&
                          selectedCustomerMostOrderedMenu.map((menu, index) => (
                            <Box key={index}>
                              <Box sx={{ py: 2 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: 1,
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "primary.main",
                                      bgcolor: `${theme.palette.primary.main}1A`,
                                      textTransform: "capitalize",
                                      px: 0.625,
                                      py: 0.375,
                                      fontSize: "0.625rem",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {menu.productCategory}
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: "semiBold",
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {menu.amount} time{menu.amount > 1 && "s"}
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: 1,
                                    alignItems: "center",
                                    textTransform: "capitalize",
                                    mt: 1,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "0.75rem",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {menu.name}
                                  </Typography>
                                </Box>
                              </Box>
                              {selectedCustomerMostOrderedMenu.length - 1 !==
                                index && <Divider />}
                            </Box>
                          ))}
                        {selectedTabCustomerDetails === "recent" &&
                          selectedCustomerRecentOrderedMenu.map(
                            (menu, index) => (
                              <Box key={index}>
                                <Box sx={{ py: 2 }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: 1,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        textTransform: "capitalize",
                                        fontSize: "0.75rem",
                                      }}
                                    >
                                      {menu.name} ({menu.quantity}x)
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontSize: "0.625rem",
                                        color: "#9E9D9D",
                                      }}
                                    >
                                      {dayjs(menu.dateTime).format("DD/MMM/YY")}
                                      {", "}
                                      <Typography
                                        component="span"
                                        sx={{
                                          fontSize: "0.625rem",
                                          color: "#9E9D9D",
                                          fontWeight: "semiBold",
                                        }}
                                      >
                                        {dayjs(menu.dateTime).format("HH:mm")}
                                      </Typography>
                                    </Typography>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: 1,
                                      alignItems: "center",
                                      mt: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: "0.75rem" }}
                                    ></Typography>

                                    <Typography
                                      sx={{
                                        fontSize: "0.75rem",
                                        fontWeight: "semiBold",
                                      }}
                                    >
                                      {formatNumberToCurrency(menu.price)}
                                    </Typography>
                                  </Box>
                                </Box>
                                {selectedCustomerMostOrderedMenu.length - 1 !==
                                  index && <Divider />}
                              </Box>
                            )
                          )}
                      </Box>
                    </Box>
                  )}
              </CustomBottomDrawer>
            )}
            <Box sx={{ mt: 4 }}>
              <Grid container columnSpacing={0.5} alignItems="center">
                <Grid item xs>
                  <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontWeight: "semiBold",
                        alignItems: "center",
                      }}
                    >
                      Operational
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: "semiBold",
                        color: "#9E9D9D",
                        fontSize: "0.625rem",
                      }}
                    >
                      (Average)
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs="auto">
                  <CustomDateRange
                    dateRange={dateRangeOperational}
                    setDateRange={setDateRangeOperational}
                    saveDateRange={saveDateRangeOperational}
                    setSaveDateRange={setSaveDateRangeOperational}
                    sx={{
                      fontSize: "0.625rem",
                      fontWeight: "medium",
                      "& .MuiOutlinedInput-input": {
                        px: 0,
                        pt: 1,
                        pb: 0.75,
                      },
                      pl: 1,
                      maxWidth: "150px",
                    }}
                    iconSize="0.75rem"
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container columnSpacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minHeight: "1.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        auth?.companyId === 9
                          ? treatmentWaitingTimeChangePercentage < 0
                            ? "error.main"
                            : treatmentWaitingTimeChangePercentage > 0
                            ? "success.main"
                            : "grey.500"
                          : treatmentWaitingTimeChangePercentage < 0
                          ? "success.main"
                          : treatmentWaitingTimeChangePercentage > 0
                          ? "error.main"
                          : "grey.500",

                      fontWeight: "medium",
                    }}
                  >
                    {`${treatmentWaitingTimeChangePercentage.toFixed(2)}%`}
                  </Typography>
                  {treatmentWaitingTimeChangePercentage < 0 ? (
                    <ArrowDropDownIcon
                      sx={{
                        color:
                          auth?.companyId === 9 ? "error.main" : "success.main",
                        ml: 0.5,
                      }}
                    />
                  ) : treatmentWaitingTimeChangePercentage > 0 ? (
                    <ArrowDropUpIcon
                      sx={{
                        color:
                          auth?.companyId === 9 ? "success.main" : "error.main",
                        ml: 0.5,
                      }}
                    />
                  ) : null}
                </Box>
                <Line
                  data={waitingTimeChartData}
                  ref={waitingTimeChartRef}
                  options={{
                    plugins: {
                      legend: { display: false },
                      tooltip: {
                        displayColors: false,
                        callbacks: {
                          title: (tooltipItems) =>
                            dayjs(tooltipItems[0].label).format("DD/MMM/YY"),
                        },
                      },
                    },
                    interaction: {
                      intersect: false,
                    },
                    scales: {
                      yAxes: {
                        display: false,
                      },
                      xAxes: {
                        display: false,
                      },
                    },
                    elements: {
                      point: {
                        radius: 0,
                      },
                      line: {
                        tension: 0.4,
                        borderColor: "#84E8F4",
                      },
                    },
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                    fontWeight: "medium",
                    mt: 1,
                    color: "ternary.main",
                  }}
                >
                  {averageWaitingTime.toFixed(2)}{" "}
                  {auth?.companyId !== 9 && "mins"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "medium",
                    mt: 1,
                    color: "grey.500",
                  }}
                >
                  {auth?.companyId === 9 ? "Dine In Orders" : "Waiting Time"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minHeight: "1.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        treatmentProcessTimeChangePercentage > 0
                          ? "error.main"
                          : treatmentProcessTimeChangePercentage < 0
                          ? "success.main"
                          : "grey.500",

                      fontWeight: "medium",
                    }}
                  >
                    {`${treatmentProcessTimeChangePercentage.toFixed(2)}%`}
                  </Typography>
                  {treatmentProcessTimeChangePercentage < 0 ? (
                    <ArrowDropDownIcon
                      sx={{
                        color: "success.main",
                        ml: 0.5,
                      }}
                    />
                  ) : treatmentProcessTimeChangePercentage > 0 ? (
                    <ArrowDropUpIcon
                      sx={{
                        color: "error.main",
                        ml: 0.5,
                      }}
                    />
                  ) : null}
                </Box>
                <Line
                  data={treatmentTimeChartData}
                  ref={treatmentTimeChartRef}
                  options={{
                    plugins: {
                      legend: { display: false },
                      tooltip: {
                        displayColors: false,
                        callbacks: {
                          title: (tooltipItems) =>
                            dayjs(tooltipItems[0].label).format("DD/MMM/YY"),
                        },
                      },
                    },
                    interaction: {
                      intersect: false,
                    },
                    scales: {
                      yAxes: {
                        display: false,
                      },
                      xAxes: {
                        display: false,
                      },
                    },
                    elements: {
                      point: {
                        radius: 0,
                      },
                      line: {
                        tension: 0.4,
                        borderColor: "#84E8F4",
                      },
                    },
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                    fontWeight: "medium",
                    mt: 1,
                    color: "ternary.main",
                  }}
                >
                  {averageProcessTime.toFixed(2)} mins
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "medium",
                    mt: 1,
                    color: "grey.500",
                  }}
                >
                  {auth?.companyId === 9
                    ? "Kitchen Processing Time"
                    : "Treatment Time"}
                </Typography>
              </Grid>
            </Grid>
            {auth?.companyId === 9 ? (
              <Grid
                container
                columnSpacing={1}
                sx={{ mt: 2 }}
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        display: "inline-flex",
                        borderBottom: 1,
                        borderColor: "divider",
                        textDecoration: "initial",
                        alignItems: "end",
                        pb: 0.5,
                      }}
                    >
                      <Typography
                        component="span"
                        noWrap
                        sx={{
                          fontWeight: "semiBold",
                        }}
                      >
                        Top Sold
                      </Typography>

                      <Typography
                        component={"span"}
                        sx={{
                          fontWeight: "semiBold",
                          fontSize: "0.5rem",
                          mr: 0.25,
                          ml: 0.125,
                          pb: 0.25,
                        }}
                      >
                        (S)
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {isExpandedTopSold &&
                        !isChartTopSold &&
                        isSortDescTopSold && (
                          <ArrowDownIcon
                            sx={{ cursor: "pointer", fontSize: "0.875rem" }}
                            onClick={() => setIsSortDescTopSold(false)}
                            color="primary"
                          />
                        )}
                      {isExpandedTopSold &&
                        !isChartTopSold &&
                        !isSortDescTopSold && (
                          <ArrowUpIcon
                            sx={{ cursor: "pointer", fontSize: "0.875rem" }}
                            onClick={() => setIsSortDescTopSold(true)}
                            color="primary"
                          />
                        )}
                    </Box>

                    {/* top sold isChart */}
                    {isExpandedTopSold && (
                      <Box
                        sx={{
                          borderRadius: "5px",
                          bgcolor: theme.palette.primary.main,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          color: "#fff",
                          height: "26px",
                          minWidth: "26px",
                          px: 0.5,
                          ml: 1,
                        }}
                        onClick={() => setIsChartTopSold(!isChartTopSold)}
                      >
                        {!isChartTopSold ? (
                          <LineChartIcon
                            width="16px"
                            height="16px"
                            fill="#fff"
                          />
                        ) : (
                          <ListIcon sx={{ fontSize: "20px" }} />
                        )}
                      </Box>
                    )}

                    {/* top sold category */}
                    {isExpandedTopSold && (
                      <CustomTab
                        sx={{ ml: 1 }}
                        tabs={[
                          {
                            id: "food",
                            name: <FoodIcon sx={{ fontSize: "0.875rem" }} />,
                          },
                          {
                            id: "beverage",
                            name: (
                              <BeverageIcon sx={{ fontSize: "0.875rem" }} />
                            ),
                          },
                        ]}
                        px={0.5}
                        py={0.25}
                        value={selectedCategoryTopSold}
                        setValue={setSelectedCategoryTopSold}
                      />
                    )}
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    sx={{
                      height: 1,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      justifyContent: "flex-end",
                    }}
                  >
                    {isExpandedTopSold ? (
                      <KeyboardArrowUpIcon
                        onClick={() => setIsExpandedTopSold(false)}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        onClick={() => setIsExpandedTopSold(true)}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                columnSpacing={1}
                sx={{ mt: 2 }}
                alignItems="center"
              >
                <Grid item xs>
                  <Box
                    onClick={() =>
                      navigate({
                        pathname: "/treatment-dashboard",
                        search: createSearchParams({ s: 3 }).toString(),
                      })
                    }
                    sx={{
                      display: "inline-flex",
                      borderBottom: 1,
                      borderColor: "divider",
                      textDecoration: "initial",
                      alignItems: "end",
                      pb: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "primary.main",
                        fontWeight: "semiBold",
                      }}
                    >
                      Top 3 Treatment
                      <Typography
                        component={"span"}
                        sx={{
                          color: "primary.main",
                          fontWeight: "semiBold",
                          fontSize: "0.5rem",
                          mr: 0.25,
                          ml: 0.125,
                          pb: 0.25,
                        }}
                      >
                        (S)
                      </Typography>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
            <Box sx={{ mt: 1 }}>
              {/* top sold list */}
              {(auth?.companyId !== 9 || isExpandedTopSold) && (
                <Grid container columnSpacing={2} rowSpacing={1}>
                  {(auth?.companyId !== 9 ||
                    (isSortDescTopSold && !isChartTopSold)) &&
                    popularOrders.map((popularOrder, index) => (
                      <Fragment key={index}>
                        <Grid
                          item
                          xs={8}
                          sx={{ fontSize: "0.75rem", fontWeight: "medium" }}
                        >
                          {popularOrder.productName || popularOrder.name}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            fontSize: "0.75rem",
                            fontWeight: "medium",
                            textAlign: "right",
                          }}
                        >
                          {popularOrder.orderCount || popularOrder.total}
                        </Grid>
                      </Fragment>
                    ))}
                </Grid>
              )}

              {/* top sold line chart */}
              {auth?.companyId === 9 && isExpandedTopSold && isChartTopSold && (
                <Box sx={{ mt: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "semiBold", fontSize: "0.75rem" }}
                        >
                          Menu Performance
                        </Typography>
                        <FormControl>
                          <Select
                            inputProps={{ sx: { fontSize: "0.75rem" } }}
                            sx={{ height: "1.6875rem" }}
                            onChange={(event) => {
                              setTopSoldTimeframe(event.target.value);
                            }}
                            value={topSoldTimeframe}
                          >
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Line
                        data={topSoldChartData}
                        ref={topSoldChartRef}
                        options={{
                          plugins: {
                            legend: {
                              labels: {
                                color: "#303030",
                                font: {
                                  size: 8,
                                  family: "Poppins",
                                },
                                usePointStyle: true,
                                pointStyle: "rect",
                              },
                              position: "bottom",
                              align: "center",
                            },
                            tooltip: {
                              displayColors: false,
                              callbacks: {
                                title: (tooltipItems) => {
                                  const label = tooltipItems[0].label;
                                  switch (topSoldTimeframe) {
                                    case "daily":
                                      return `${dayjs(label).format(
                                        "MMM"
                                      )} ${dayjs(label).format(
                                        "D"
                                      )}${ordinalSuffixOf(
                                        dayjs(label).format("D")
                                      )}, ${dayjs(label).format("YYYY")}`;
                                    case "weekly":
                                      return `${dayjs(
                                        label
                                      ).week()}${ordinalSuffixOf(
                                        dayjs(label).week()
                                      )} week ${dayjs(label).format("YYYY")}`;
                                    default:
                                      return `${dayjs(label).format(
                                        "MMM"
                                      )} ${dayjs(label).format("YYYY")}`;
                                  }
                                },
                                label: (tooltipItem) => {
                                  return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} sold`;
                                },
                              },
                            },
                          },
                          interaction: {
                            intersect: false,
                          },
                          scales: {
                            y1: {
                              ticks: {
                                color: "#9E9D9D",
                                font: {
                                  size: 8,
                                  family: "Poppins",
                                },
                                callback: (value) => value,
                              },
                            },
                            x: {
                              title: {
                                color: "#9E9D9D",
                                font: {
                                  size: 8,
                                  family: "Poppins",
                                },
                                text:
                                  topSoldTimeframe === "daily"
                                    ? "Day of Month"
                                    : topSoldTimeframe === "weekly"
                                    ? "Week"
                                    : "Month",
                              },
                              ticks: {
                                color: "#9E9D9D",
                                font: {
                                  size: 8,
                                  family: "Poppins",
                                },
                                callback(value) {
                                  const label = this.getLabelForValue(value);

                                  switch (topSoldTimeframe) {
                                    case "daily":
                                      return `${dayjs(label).format(
                                        "D"
                                      )}${ordinalSuffixOf(
                                        dayjs(label).format("D")
                                      )}`;
                                    case "weekly":
                                      return `${dayjs(
                                        label
                                      ).week()}${ordinalSuffixOf(
                                        dayjs(label).week()
                                      )}`;
                                    default:
                                      return dayjs(label).format("MMM");
                                  }
                                },
                              },
                            },
                          },
                          elements: {
                            point: {
                              radius: 0,
                            },
                            line: {
                              tension: 0.5,
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>

            {auth?.companyId !== 9 && (
              <Box
                sx={{
                  mt: 2,
                  display: "inline-block",
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontWeight: "semiBold",
                    pb: 0.5,
                  }}
                >
                  Top 3 Sellers
                </Typography>
              </Box>
            )}
            <Box sx={{ mt: auth?.companyId !== 9 && 1 }}>
              {(auth?.companyId !== 9 ||
                (!isSortDescTopSold && isExpandedTopSold)) && (
                <Grid container columnSpacing={2} rowSpacing={0.75}>
                  {incentives.map((incentive) => (
                    <Fragment key={incentive.id}>
                      <Grid
                        item
                        xs={8}
                        sx={{ fontSize: "0.75rem", fontWeight: "medium" }}
                      >
                        {incentive.name}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: "medium",
                          textAlign: "right",
                        }}
                      >
                        {auth?.companyId === 9
                          ? `${incentive.total}`
                          : formatNumberToCurrency(incentive.totalSales)}
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              )}
            </Box>

            {auth?.companyId !== 9 && (
              <>
                <Box
                  sx={{
                    mt: 2,
                    display: "inline-block",
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "semiBold",
                      pb: 0.5,
                    }}
                  >
                    Top 3 Used Stocks
                  </Typography>
                </Box>

                <Box sx={{ mt: 1 }}>
                  <Grid
                    container
                    item
                    columnSpacing={2}
                    rowSpacing={1}
                    sx={{ flexGrow: 1 }}
                  >
                    {stocks.map((stock, index) => (
                      <Fragment key={index}>
                        <Grid
                          item
                          xs={8}
                          sx={{ fontSize: "0.75rem", fontWeight: "medium" }}
                        >
                          {stock.productName}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            fontSize: "0.75rem",
                            fontWeight: "medium",
                            textAlign: "right",
                          }}
                        >
                          {stock.productCount}
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                </Box>
              </>
            )}
            <Grid
              container
              sx={{ alignItems: "center", mt: 2 }}
              columnSpacing={1}
            >
              <Grid item xs>
                <Typography
                  sx={{
                    fontWeight: "semiBold",
                  }}
                >
                  Orders Status
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <CustomDateRange
                  dateRange={dateRangeOrderActivities}
                  setDateRange={setDateRangeOrderActivities}
                  saveDateRange={saveDateRangeOrderActivities}
                  setSaveDateRange={setSaveDateRangeOrderActivities}
                  sx={{
                    fontSize: "0.625rem",
                    fontWeight: "medium",
                    "& .MuiOutlinedInput-input": {
                      px: 0,
                      pt: 1,
                      pb: 0.625,
                    },
                    pl: 1,
                    maxWidth: "150px",
                  }}
                  iconSize="0.75rem"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={2} rowSpacing={0.75} sx={{ mt: 1 }}>
              {orderActivities.map((orderActivity, index) => (
                <Fragment key={index}>
                  <Grid
                    item
                    xs={9}
                    sx={{ fontSize: "0.75rem", fontWeight: "medium" }}
                  >
                    {orderActivity.status}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      fontSize: "0.75rem",
                      fontWeight: "medium",
                      textAlign: "right",
                      color:
                        auth?.companyId === 9 && kanpaiOrderStatusColor(index),
                    }}
                  >
                    {orderActivity.orderCount}
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Box>
          <Box sx={{ bgcolor: "background.default", p: 2, mt: 1 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  mb: 0.5,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontWeight: "semiBold" }}>
                    {auth?.companyId === 9 ? "Table" : "Bed"}
                  </Typography>

                  {/* isShown table performance chart */}
                  {auth?.companyId === 9 && (
                    <Box
                      sx={{
                        borderRadius: "5px",
                        bgcolor: isShownTablePerformance
                          ? theme.palette.primary.main
                          : "#fff",
                        border: isShownTablePerformance
                          ? "1px solid #fff"
                          : `1px solid ${theme.palette.primary.main}`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        p: 0.5,
                        ml: 2,
                      }}
                      onClick={() =>
                        setIsShownTablePerformance(!isShownTablePerformance)
                      }
                    >
                      <LineChartIcon
                        width="12px"
                        height="12px"
                        fill={
                          isShownTablePerformance
                            ? "#fff"
                            : theme.palette.primary.main
                        }
                      />
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* table performance chart */}
              {auth?.companyId === 9 && isShownTablePerformance && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: "semiBold", fontSize: "0.75rem" }}
                    >
                      Table Performance
                    </Typography>

                    <CustomDateRange
                      dateRange={dateRangeTablePerformance}
                      setDateRange={setDateRangeTablePerformance}
                      saveDateRange={saveDateRangeTablePerformance}
                      setSaveDateRange={setSaveDateRangeTablePerformance}
                      sx={{
                        fontSize: "0.625rem",
                        fontWeight: "medium",
                        "& .MuiOutlinedInput-input": {
                          px: 0,
                          pt: 1,
                          pb: 0.75,
                        },
                        pl: 1,
                        maxWidth: "150px",
                      }}
                      iconSize="0.75rem"
                    />
                  </Box>

                  <Typography
                    sx={{
                      fontWeight: "semiBold",
                      fontSize: "0.625rem",
                      color: "primary.main",
                      mt: 1,
                    }}
                  >
                    Occupied
                  </Typography>

                  <Box sx={{ mt: 1 }}>
                    <Line
                      data={tablePerformanceChartData}
                      ref={tablePerformanceChartRef}
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              color: "#303030",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              usePointStyle: true,
                              pointStyle: "rect",
                            },
                            position: "bottom",
                            align: "center",
                          },
                          tooltip: {
                            displayColors: false,
                            callbacks: {
                              title: (tooltipItems) => {
                                const label = tooltipItems[0].label;

                                return label.substring(0, 5);
                              },
                              label: (tooltipItem) => {
                                const label = tooltipItem.dataset.label;
                                const value = tooltipItem.formattedValue;

                                return `${label}: ${value} times`;
                              },
                            },
                          },
                        },
                        interaction: {
                          intersect: false,
                        },
                        scales: {
                          y1: {
                            ticks: {
                              color: "#9E9D9D",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              callback: (value) => value,
                            },
                          },
                          x: {
                            title: {
                              color: "#9E9D9D",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                            },
                            ticks: {
                              color: "#9E9D9D",
                              font: {
                                size: 8,
                                family: "Poppins",
                              },
                              callback(value) {
                                const label = this.getLabelForValue(value);

                                return label.substring(0, 5);
                              },
                            },
                          },
                        },
                        elements: {
                          point: {
                            radius: 0,
                          },
                          line: {
                            tension: 0.5,
                          },
                        },
                      }}
                    />
                  </Box>
                </Grid>
              )}

              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: 2.5,
                    boxShadow: (theme) =>
                      `0px 0px 6px ${theme.palette.grey[300]}BF`,
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.25rem",
                        color: "error.main",
                      }}
                    >
                      {auth?.companyId === 9
                        ? bedSummary?.mainFloor?.occupied?.count +
                            bedSummary?.vip?.occupied?.count || 0
                        : bedSummary?.occupied}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: "semiBold",
                          color: "ternary.main",
                          fontSize: "0.75rem",
                        }}
                      >
                        Occupied
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              {auth?.companyId === 9 && (
                <Grid item xs={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      borderRadius: 2.5,
                      boxShadow: (theme) =>
                        `0px 0px 6px ${theme.palette.grey[300]}BF`,
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 50,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.25rem",
                        color: "#FEDD25",
                      }}
                    >
                      0
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 0.25,
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "semiBold",
                          color: "ternary.main",
                          fontSize: "0.75rem",
                        }}
                      >
                        Reserved
                      </Typography>
                      <Typography sx={{ color: "#9E9D9D", fontSize: "0.5rem" }}>
                        within 3 hours
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: 2.5,
                    boxShadow: (theme) =>
                      `0px 0px 6px ${theme.palette.grey[300]}BF`,
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.25rem",
                        color: "primary.main",
                      }}
                    >
                      {auth?.companyId === 9
                        ? bedSummary?.mainFloor?.free?.count +
                            bedSummary?.vip?.free?.count || 0
                        : bedSummary?.empty}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: "semiBold",
                          color: "ternary.main",
                          fontSize: "0.75rem",
                        }}
                      >
                        Available
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              {auth?.companyId === 9 && (
                <Grid item xs={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      borderRadius: 2.5,
                      boxShadow: (theme) =>
                        `0px 0px 6px ${theme.palette.grey[300]}BF`,
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.25rem",
                        color: "ternary.main",
                      }}
                    >
                      0
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "semiBold",
                        color: "ternary.main",
                        fontSize: "0.75rem",
                      }}
                    >
                      Cleaning
                    </Typography>
                  </Paper>
                </Grid>
              )}
              {auth?.companyId !== 9 && (
                <Grid item xs={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      borderRadius: 2.5,
                      boxShadow: (theme) =>
                        `0px 0px 6px ${theme.palette.grey[300]}BF`,
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.25rem",
                        color: "error.main",
                      }}
                    >
                      {bedSummary?.anomaly}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "semiBold",
                        color: "ternary.main",
                        fontSize: "0.75rem",
                      }}
                    >
                      Anomaly
                    </Typography>
                  </Paper>
                </Grid>
              )}
              {/* <Grid item xs={6}>
              <Paper
                elevation={0}
                sx={{
                  borderRadius: 2.5,
                  boxShadow: (theme) =>
                    `0px 0px 6px ${theme.palette.grey[300]}BF`,
                  p: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.25rem',
                    color: 'ternary.main',
                  }}
                >
                  15
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 'medium',
                    fontSize: '0.75rem',
                    color: 'ternary.main',
                  }}
                >
                  Off Duty
                </Typography>
              </Paper>
            </Grid> */}
            </Grid>
            {/* Table Map */}
            {auth?.companyId === 9 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: "semiBold",
                      lineHeight: 1.5,
                    }}
                  >
                    Table Map
                  </Typography>
                  {selectedTableMap === "main" && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography
                        sx={{
                          fontWeight: "medium",
                          fontSize: "0.75rem",
                          color: "#9E9D9D",
                        }}
                      >
                        Grid
                      </Typography>
                      <Switch
                        size="small"
                        checked={isMainFloorTableGrid}
                        onChange={(event) => {
                          setIsMainFloorTableGrid(event.target.checked);
                        }}
                      />
                    </Box>
                  )}
                </Box>

                <CustomTab
                  tabs={[
                    { id: "main", name: "Main floor" },
                    { id: "bob", name: "BOB Shop" },
                    { id: "vip", name: "VIP Room" },
                  ]}
                  value={selectedTableMap}
                  setValue={setSelectedTableMap}
                  sx={{ mt: 2 }}
                />
                <Box sx={{ mt: 2 }}>
                  <CustomBottomDrawer
                    open={isOpenTableDetails}
                    onClose={() => setIsOpenTableDetails(false)}
                  >
                    {/* title */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Typography sx={{ fontWeight: "semiBold" }}>
                          Table{" "}
                          {selectedTable?.table.split("-").length === 3
                            ? selectedTable?.table.split("-")[0] +
                              " " +
                              selectedTable?.table.split("-")[2]
                            : selectedTable?.table.split("-")[1]}
                        </Typography>
                        <MobileDatePicker
                          value={tableHistoriesInputDate}
                          onChange={(newValue) => {
                            setTableHistoriesInputDate(newValue);
                          }}
                          onAccept={(newValue) => {
                            setTableHistoriesDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              inputProps={{
                                ...params.inputProps,
                                sx: {
                                  textAlign: "center",
                                  fontSize: "0.75rem",
                                  p: 0.6875,
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                sx: {
                                  width: "6rem",
                                },
                              }}
                            />
                          )}
                        />
                      </Box>
                      <CloseIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => setIsOpenTableDetails(false)}
                      />
                    </Box>
                    {isLoadingTableDetails && (
                      <Typography sx={{ fontSize: "0.75rem", mt: 2 }}>
                        Loading..
                      </Typography>
                    )}
                    {!isLoadingTableDetails && (
                      <TableCustomerHistories histories={tableHistories} />
                    )}
                  </CustomBottomDrawer>
                  {/* main floor map */}
                  {selectedTableMap === "main" && (
                    <Grid container columnSpacing={1} rowSpacing={2}>
                      <Grid item container xs={7}>
                        {isMainFloorTableGrid
                          ? mainFloorTable1.map((row, rowIndex) =>
                              row.map((column, columnIndex) => {
                                const table = mainFloorTables?.occupied?.find(
                                  (table) => table.name === column
                                );
                                const freeTable = mainFloorTables?.free?.find(
                                  (table) => table.name === column
                                );

                                return (
                                  <Grid
                                    item
                                    xs={1.2}
                                    key={columnIndex}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      bgcolor: !column
                                        ? "#D8D8D8"
                                        : table
                                        ? "#F33A3A"
                                        : "#fff",
                                      color: table && "#fff",
                                      borderTop:
                                        rowIndex === 0 && "0.75px solid #000",
                                      borderLeft:
                                        columnIndex === 0 &&
                                        "0.75px solid #000",
                                      borderRight: "0.75px solid #000",
                                      borderBottom:
                                        rowIndex ===
                                          mainFloorTable1.length - 1 &&
                                        columnIndex === 0
                                          ? "5px solid #000"
                                          : "0.75px solid #000",
                                      fontSize: "0.5rem",
                                      pt: "2px",
                                      minHeight: "20px",
                                    }}
                                    onClick={() => {
                                      setIsOpenTableDetails(true);

                                      if (column) {
                                        table
                                          ? setSelectedTable(table)
                                          : setSelectedTable(freeTable);
                                      }
                                    }}
                                  >
                                    {column}
                                  </Grid>
                                );
                              })
                            )
                          : mainFloorTable1.map((row, rowIndex) =>
                              row.map((column, columnIndex) => {
                                const table = mainFloorTables?.occupied?.find(
                                  (table) => table.name === column
                                );
                                const freeTable = mainFloorTables?.free?.find(
                                  (table) => table.name === column
                                );

                                return (
                                  <Grid
                                    item
                                    xs={1.2}
                                    key={columnIndex}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      bgcolor: table && "#F33A3A",
                                      color: table && "#fff",
                                      borderBlock:
                                        column && "0.75px solid #000",
                                      borderLeft: column && "0.75px solid #000",
                                      borderRight:
                                        column &&
                                        !row[columnIndex + 1] &&
                                        "0.75px solid #000",
                                      borderBottom:
                                        rowIndex ===
                                          mainFloorTable1.length - 1 &&
                                        columnIndex === 0 &&
                                        "5px solid #000",
                                      fontSize: "0.5rem",
                                      pt: "2px",
                                      minHeight: "20px",
                                    }}
                                    onClick={() => {
                                      setIsOpenTableDetails(true);

                                      if (column) {
                                        table
                                          ? setSelectedTable(table)
                                          : setSelectedTable(freeTable);
                                      }
                                    }}
                                  >
                                    {column}
                                  </Grid>
                                );
                              })
                            )}
                      </Grid>
                      <Grid item container xs={5}>
                        {isMainFloorTableGrid
                          ? mainFloorTable2.map((row, rowIndex) =>
                              row.map((column, columnIndex) => {
                                const table = mainFloorTables?.occupied?.find(
                                  (table) => table.name === column
                                );
                                const freeTable = mainFloorTables?.free?.find(
                                  (table) => table.name === column
                                );

                                return (
                                  <Grid
                                    item
                                    xs={1.5}
                                    key={columnIndex}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      bgcolor: !column
                                        ? "#D8D8D8"
                                        : table
                                        ? "#F33A3A"
                                        : "#fff",
                                      color: table && "#fff",
                                      borderTop:
                                        rowIndex === 0 && "0.75px solid #000",
                                      borderLeft:
                                        columnIndex === 0 &&
                                        "0.75px solid #000",
                                      borderRight: "0.75px solid #000",
                                      borderBottom:
                                        rowIndex ===
                                          mainFloorTable1.length - 1 &&
                                        columnIndex === 0
                                          ? "5px solid #000"
                                          : "0.75px solid #000",
                                      fontSize: "0.5rem",
                                      pt: "2px",
                                      minHeight: "20px",
                                    }}
                                    onClick={() => {
                                      setIsOpenTableDetails(true);

                                      if (column) {
                                        table
                                          ? setSelectedTable(table)
                                          : setSelectedTable(freeTable);
                                      }
                                    }}
                                  >
                                    {column}
                                  </Grid>
                                );
                              })
                            )
                          : mainFloorTable2.map((row, rowIndex) =>
                              row.map((column, columnIndex) => {
                                const table = mainFloorTables?.occupied?.find(
                                  (table) => table.name === column
                                );
                                const freeTable = mainFloorTables?.free?.find(
                                  (table) => table.name === column
                                );

                                return (
                                  <Grid
                                    item
                                    xs={1.5}
                                    key={columnIndex}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      bgcolor: table && "#F33A3A",
                                      color: table && "#fff",
                                      borderBlock:
                                        column && "0.75px solid #000",
                                      borderLeft: column && "0.75px solid #000",
                                      borderRight:
                                        column &&
                                        !row[columnIndex + 1] &&
                                        "0.75px solid #000",
                                      borderBottom:
                                        rowIndex ===
                                          mainFloorTable1.length - 1 &&
                                        columnIndex === 0 &&
                                        "5px solid #000",
                                      fontSize: "0.5rem",
                                      pt: "2px",
                                      minHeight: "20px",
                                    }}
                                    onClick={() => {
                                      setIsOpenTableDetails(true);

                                      if (column) {
                                        table
                                          ? setSelectedTable(table)
                                          : setSelectedTable(freeTable);
                                      }
                                    }}
                                  >
                                    {column}
                                  </Grid>
                                );
                              })
                            )}
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 1,
                            cursor: "pointer",
                          }}
                          onClick={() => setSelectedTableMap("vip")}
                        >
                          <StairsIcon sx={{ color: "primary.main" }} />
                          <Typography
                            sx={{
                              fontWeight: "semiBold",
                              color: "primary.main",
                              fontSize: "0.75rem",
                            }}
                          >
                            VIP
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sx={{ mt: 1 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "end",
                            height: 1,
                            gap: 0.5,
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "0.5rem", fontWeight: "medium" }}
                          >
                            Toilet
                          </Typography>
                          <ArrowDownwardIcon sx={{ fontSize: "0.625rem" }} />
                        </Box>
                      </Grid>
                      <Grid item container xs={4} sx={{ mt: 1 }}>
                        {isMainFloorTableGrid
                          ? mainFloorTable3.map((row, rowIndex) =>
                              row.map((column, columnIndex) => {
                                const table = mainFloorTables?.occupied?.find(
                                  (table) => table.name === column
                                );
                                const freeTable = mainFloorTables?.free?.find(
                                  (table) => table.name === column
                                );

                                return (
                                  <Grid
                                    item
                                    xs={2.4}
                                    key={columnIndex}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      bgcolor: !column
                                        ? "#D8D8D8"
                                        : table
                                        ? "#F33A3A"
                                        : "#fff",
                                      color: table && "#fff",
                                      borderTop:
                                        rowIndex === 0 && "0.75px solid #000",
                                      borderLeft:
                                        columnIndex === 0 &&
                                        "0.75px solid #000",
                                      borderRight: "0.75px solid #000",
                                      borderBottom: "0.75px solid #000",
                                      fontSize: "0.5rem",
                                      pt: "2px",
                                      minHeight: "20px",
                                    }}
                                    onClick={() => {
                                      setIsOpenTableDetails(true);

                                      if (column) {
                                        table
                                          ? setSelectedTable(table)
                                          : setSelectedTable(freeTable);
                                      }
                                    }}
                                  >
                                    {column}
                                  </Grid>
                                );
                              })
                            )
                          : mainFloorTable3.map((row, rowIndex) =>
                              row.map((column, columnIndex) => {
                                const table = mainFloorTables?.occupied?.find(
                                  (table) => table.name === column
                                );
                                const freeTable = mainFloorTables?.free?.find(
                                  (table) => table.name === column
                                );

                                return (
                                  <Grid
                                    item
                                    xs={2.4}
                                    key={columnIndex}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      bgcolor: table && "#F33A3A",
                                      color: table && "#fff",
                                      borderBlock:
                                        column && "0.75px solid #000",
                                      borderLeft: column && "0.75px solid #000",
                                      borderRight:
                                        column &&
                                        !row[columnIndex + 1] &&
                                        "0.75px solid #000",
                                      fontSize: "0.5rem",
                                      pt: "2px",
                                      minHeight: "20px",
                                    }}
                                    onClick={() => {
                                      setIsOpenTableDetails(true);

                                      if (column) {
                                        table
                                          ? setSelectedTable(table)
                                          : setSelectedTable(freeTable);
                                      }
                                    }}
                                  >
                                    {column}
                                  </Grid>
                                );
                              })
                            )}
                      </Grid>
                      <Grid item xs={3} sx={{ mt: 1 }}>
                        <Box
                          sx={{
                            bgcolor: "#51B15C26",
                            height: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography sx={{ fontSize: "0.5rem" }}>
                            STAGE
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {/* bob shop map */}
                  {selectedTableMap === "bob" && (
                    <Grid container columnSpacing={3} rowSpacing={2}>
                      {/* 1st floor */}
                      <Grid item xs={6}>
                        <Box sx={{ bgcolor: "#FAFAFA", height: 1 }}>
                          <Typography
                            sx={{
                              fontWeight: "medium",
                              fontSize: "0.625rem",
                              pt: 1,
                              px: 1,
                            }}
                          >
                            1st Floor
                          </Typography>
                          <Grid
                            container
                            padding={2}
                            justifyContent="space-around"
                          >
                            <Grid item xs={3}>
                              <Box
                                sx={{
                                  minHeight: 85,
                                  border: "0.75px solid #000",
                                  bgcolor:
                                    bobShopTables?.occupied.find(
                                      (table) => table.name === 44
                                    ) && "#F33A3A",
                                  color:
                                    bobShopTables?.occupied.find(
                                      (table) => table.name === 44
                                    ) && "#fff",
                                  height: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "0.625rem",
                                }}
                                onClick={() => {
                                  const table =
                                    bobShopTables?.occupied.find(
                                      (table) => table.name === 44
                                    ) ||
                                    bobShopTables?.free?.find(
                                      (table) => table.name === 44
                                    );

                                  if (table) {
                                    setIsOpenTableDetails(true);
                                    setSelectedTable(table);
                                  }
                                }}
                              >
                                44
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box
                                sx={{
                                  minHeight: 85,
                                  border: "0.75px solid #000",
                                  bgcolor:
                                    bobShopTables?.occupied.find(
                                      (table) => table.name === 45
                                    ) && "#F33A3A",
                                  color:
                                    bobShopTables?.occupied.find(
                                      (table) => table.name === 45
                                    ) && "#fff",
                                  height: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "0.625rem",
                                }}
                                onClick={() => {
                                  const table =
                                    bobShopTables?.occupied.find(
                                      (table) => table.name === 45
                                    ) ||
                                    bobShopTables?.free?.find(
                                      (table) => table.name === 45
                                    );

                                  if (table) {
                                    setIsOpenTableDetails(true);
                                    setSelectedTable(table);
                                  }
                                }}
                              >
                                45
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      {/* 2nd floor */}
                      <Grid item xs={6}>
                        <Box sx={{ bgcolor: "#FAFAFA", height: 1 }}>
                          <Typography
                            sx={{
                              fontWeight: "medium",
                              fontSize: "0.625rem",
                              pt: 1,
                              px: 1,
                            }}
                          >
                            2nd Floor
                          </Typography>
                          <Grid
                            container
                            paddingX={2}
                            paddingBottom={2}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Box
                                sx={{
                                  minHeight: 55,
                                  border: "0.75px solid #000",
                                  bgcolor:
                                    bobShopTables?.occupied.find(
                                      (table) => table.name === 41
                                    ) && "#F33A3A",
                                  color:
                                    bobShopTables?.occupied.find(
                                      (table) => table.name === 41
                                    ) && "#fff",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "0.625rem",
                                }}
                                onClick={() => {
                                  const table =
                                    bobShopTables?.occupied.find(
                                      (table) => table.name === 41
                                    ) ||
                                    bobShopTables?.free?.find(
                                      (table) => table.name === 41
                                    );

                                  if (table) {
                                    setIsOpenTableDetails(true);
                                    setSelectedTable(table);
                                  }
                                }}
                              >
                                41
                              </Box>
                            </Grid>
                            <Grid item xs={4}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 3,
                                }}
                              >
                                <Box
                                  sx={{
                                    minHeight: 55,
                                    border: "0.75px solid #000",
                                    bgcolor:
                                      bobShopTables?.occupied.find(
                                        (table) => table.name === 42
                                      ) && "#F33A3A",
                                    color:
                                      bobShopTables?.occupied.find(
                                        (table) => table.name === 42
                                      ) && "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "0.625rem",
                                  }}
                                  onClick={() => {
                                    const table =
                                      bobShopTables?.occupied.find(
                                        (table) => table.name === 42
                                      ) ||
                                      bobShopTables?.free?.find(
                                        (table) => table.name === 42
                                      );

                                    if (table) {
                                      setIsOpenTableDetails(true);
                                      setSelectedTable(table);
                                    }
                                  }}
                                >
                                  42
                                </Box>
                                <Box
                                  sx={{
                                    minHeight: 55,
                                    border: "0.75px solid #000",
                                    bgcolor:
                                      bobShopTables?.occupied.find(
                                        (table) => table.name === 43
                                      ) && "#F33A3A",
                                    color:
                                      bobShopTables?.occupied.find(
                                        (table) => table.name === 43
                                      ) && "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "0.625rem",
                                  }}
                                  onClick={() => {
                                    const table =
                                      bobShopTables?.occupied.find(
                                        (table) => table.name === 43
                                      ) ||
                                      bobShopTables?.free?.find(
                                        (table) => table.name === 43
                                      );

                                    if (table) {
                                      setIsOpenTableDetails(true);
                                      setSelectedTable(table);
                                    }
                                  }}
                                >
                                  43
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {/* vip room map */}
                  {selectedTableMap === "vip" && (
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={2}
                      justifyContent="center"
                    >
                      {/* VIP 1 */}
                      <Grid
                        item
                        xs={6}
                        onClick={() => {
                          const table =
                            vipRoomTables?.occupied.find(
                              (table) => table.name === 1
                            ) ||
                            vipRoomTables?.free?.find(
                              (table) => table.name === 1
                            );

                          if (table) {
                            setIsOpenTableDetails(true);
                            setSelectedTable(table);
                          }
                        }}
                      >
                        <Box
                          sx={{
                            bgcolor: "#FAFAFA",
                            position: "relative",
                            height: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "medium",
                              fontSize: "0.625rem",
                              pt: 1,
                              px: 1,
                            }}
                          >
                            VIP 1
                          </Typography>
                          <Grid
                            container
                            paddingX={2}
                            paddingTop={1}
                            paddingBottom={5}
                          >
                            <Grid item xs={6}>
                              <Box
                                sx={{
                                  minHeight: 20,
                                  border: "0.75px solid #000",
                                  mr: 1,
                                  bgcolor:
                                    vipRoomTables?.occupied.find(
                                      (table) => table.name === 1
                                    ) && "#F33A3A",
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                sx={{
                                  minHeight: 20,
                                  border: "0.75px solid #000",
                                  ml: 1,
                                  bgcolor:
                                    vipRoomTables?.occupied.find(
                                      (table) => table.name === 1
                                    ) && "#F33A3A",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              borderRight: "3px solid #000",
                              position: "absolute",
                              top: "81.25%",
                              bottom: 0,
                              width: 1,
                            }}
                          />
                        </Box>
                      </Grid>
                      {/* VIP 2 */}
                      <Grid
                        item
                        xs={6}
                        onClick={() => {
                          const table =
                            vipRoomTables?.occupied.find(
                              (table) => table.name === 2
                            ) ||
                            vipRoomTables?.free?.find(
                              (table) => table.name === 2
                            );

                          if (table) {
                            setIsOpenTableDetails(true);
                            setSelectedTable(table);
                          }
                        }}
                      >
                        <Box sx={{ bgcolor: "#FAFAFA", position: "relative" }}>
                          <Typography
                            sx={{
                              fontWeight: "medium",
                              fontSize: "0.625rem",
                              pt: 1,
                              px: 1,
                            }}
                          >
                            VIP 2
                          </Typography>
                          <Grid
                            container
                            paddingX={2}
                            paddingTop={1}
                            paddingBottom={5}
                            justifyContent="center"
                          >
                            <Grid item xs={8}>
                              <Box
                                sx={{
                                  minHeight: 20,
                                  border: "0.75px solid #000",
                                  bgcolor:
                                    vipRoomTables?.occupied.find(
                                      (table) => table.name === 2
                                    ) && "#F33A3A",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              borderBottom: "3px solid #000",
                              right: "90%",
                              position: "absolute",
                              left: 0,
                              bottom: 0,
                            }}
                          />
                        </Box>
                      </Grid>
                      {/* VIP 3 */}
                      <Grid
                        item
                        xs={7}
                        onClick={() => {
                          const table =
                            vipRoomTables?.occupied.find(
                              (table) => table.name === 3
                            ) ||
                            vipRoomTables?.free?.find(
                              (table) => table.name === 3
                            );

                          if (table) {
                            setIsOpenTableDetails(true);
                            setSelectedTable(table);
                          }
                        }}
                      >
                        <Box sx={{ bgcolor: "#FAFAFA", position: "relative" }}>
                          <Typography
                            sx={{
                              fontWeight: "medium",
                              fontSize: "0.625rem",
                              pt: 1,
                              px: 1,
                            }}
                          >
                            VIP 3
                          </Typography>
                          <Grid
                            container
                            paddingX={2}
                            paddingTop={1}
                            paddingBottom={2}
                            justifyContent="space-around"
                          >
                            <Grid item xs={1.75}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    minHeight: 40,
                                    border: "0.75px solid #000",
                                    bgcolor:
                                      vipRoomTables?.occupied.find(
                                        (table) => table.name === 3
                                      ) && "#F33A3A",
                                  }}
                                />
                                <Box
                                  sx={{
                                    minHeight: 40,
                                    border: "0.75px solid #000",
                                    bgcolor:
                                      vipRoomTables?.occupied.find(
                                        (table) => table.name === 3
                                      ) && "#F33A3A",
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={1.75}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  height: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    minHeight: 54,
                                    border: "0.75px solid #000",
                                    bgcolor:
                                      vipRoomTables?.occupied.find(
                                        (table) => table.name === 3
                                      ) && "#F33A3A",
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              borderTop: "3px solid #000",
                              position: "absolute",
                              right: "45%",
                              left: "45%",
                              top: 0,
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {/* table map legends */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    {tableMapLegends.map((legend) => {
                      const Icon = legend.icon;
                      return (
                        <Box
                          key={legend.name}
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Icon
                            sx={{ color: legend.color, fontSize: "0.75rem" }}
                          />
                          <Typography sx={{ fontSize: "0.625rem", pt: 0.25 }}>
                            {legend.name}
                          </Typography>
                        </Box>
                      );
                    })}
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box
                        sx={{
                          bgcolor: "#414141",
                          width: "0.625rem",
                          height: "0.625rem",
                          borderRadius: "2.5px",
                        }}
                      />
                      <Typography sx={{ fontSize: "0.625rem", pt: 0.25 }}>
                        Entrance
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>

          {/* Recent Orders Bottom Drawer */}
          {auth?.companyId === 9 && (
            <CustomBottomDrawer
              open={isOpenRecentOrders}
              onClose={() => setIsOpenRecentOrders(false)}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography sx={{ fontWeight: "semiBold" }}>
                    Recent Orders
                  </Typography>
                  <CustomTab
                    tabs={[
                      {
                        id: "food",
                        name: <FoodIcon sx={{ fontSize: "0.875rem" }} />,
                      },
                      {
                        id: "beverage",
                        name: <BeverageIcon sx={{ fontSize: "0.875rem" }} />,
                      },
                    ]}
                    px={0.5}
                    py={0.25}
                    value={selectedCategoryRecentOrders}
                    setValue={setSelectedCategoryRecentOrders}
                  />
                </Box>
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setIsOpenRecentOrders(false)}
                />
              </Box>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                {newOrdersData?.orders.map((order, index) => (
                  <Grid container item key={index}>
                    <Grid
                      item
                      xs={8}
                      sx={{ fontWeight: "medium", fontSize: "0.75rem" }}
                    >
                      {order.productName}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        textAlign: "right",
                        fontWeight: "medium",
                        fontSize: "0.75rem",
                      }}
                    >
                      {formatNumberToCurrency(order.price)}
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sx={{
                        fontSize: "0.75rem",
                        fontWeight: "light",
                        color: "grey.400",
                      }}
                    >
                      {order.outlet?.name || "-"}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        fontSize: "0.75rem",
                        textAlign: "right",
                        fontWeight: "light",
                        color: "grey.400",
                      }}
                    >
                      {dayjs(order.purchaseDateTime).fromNow()}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {newOrdersData && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 1.625,
                  }}
                >
                  <Pagination
                    count={newOrdersData.lastPage}
                    color="primary"
                    shape="rounded"
                    onChange={(event, page) => setNewOrdersPage(page)}
                    sx={{
                      "&	.MuiPagination-ul": {
                        flexWrap: "initial",
                      },
                    }}
                  />
                </Box>
              )}
            </CustomBottomDrawer>
          )}

          <Box sx={{ bgcolor: "background.default", p: 2, mt: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography sx={{ fontWeight: "semiBold" }}>
                  Recent Orders
                </Typography>
                {auth?.companyId === 9 && (
                  <CustomTab
                    tabs={[
                      {
                        id: "food",
                        name: <FoodIcon sx={{ fontSize: "0.875rem" }} />,
                      },
                      {
                        id: "beverage",
                        name: <BeverageIcon sx={{ fontSize: "0.875rem" }} />,
                      },
                    ]}
                    px={0.5}
                    py={0.25}
                    value={selectedCategoryRecentOrders}
                    setValue={setSelectedCategoryRecentOrders}
                  />
                )}
              </Box>
              {auth?.companyId === 9 && (
                <Typography
                  color="primary"
                  sx={{
                    fontSize: "0.625rem",
                    fontWeight: "semiBold",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsOpenRecentOrders(true)}
                >
                  View All
                </Typography>
              )}
            </Box>

            <Grid container sx={{ mt: 1 }} rowSpacing={1.625}>
              {ordersData?.orders.map((order, index) => (
                <Grid container item key={index}>
                  <Grid
                    item
                    xs={8}
                    sx={{ fontWeight: "medium", fontSize: "0.75rem" }}
                  >
                    {order.productName}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: "right",
                      fontWeight: "medium",
                      fontSize: "0.75rem",
                    }}
                  >
                    {formatNumberToCurrency(order.price)}
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      fontSize: "0.75rem",
                      fontWeight: "light",
                      color: "grey.400",
                    }}
                  >
                    {order.outlet?.name || "-"}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      fontSize: "0.75rem",
                      textAlign: "right",
                      fontWeight: "light",
                      color: "grey.400",
                    }}
                  >
                    {dayjs(order.purchaseDateTime).fromNow()}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {ordersData && auth?.companyId !== 9 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 1.625,
                }}
              >
                <Pagination
                  count={ordersData.lastPage}
                  color="primary"
                  shape="rounded"
                  onChange={(event, page) => setOrdersPage(page)}
                  sx={{
                    "&	.MuiPagination-ul": {
                      flexWrap: "initial",
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
}
