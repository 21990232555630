import React, { useState } from 'react';
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography,
  Popover,
  Button,
  TextField,
} from '@mui/material';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import CustomMenu from '../../../../../../../../components/shared/CustomMenu';
import { ReactComponent as EntranceIcon } from '../../../../../../../../assets/entrance.svg';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import {
  addNewRackMasterHub,
  editMasterHub,
} from '../../../../../../../../api/hubs';

const SelectRacksComponent = ({
  setIsCreateGridOpen,
  setIsSelectRacksOpen,
  setIsPreviewOpen,
  numberOfRows = 1,
  numberOfColumns = 1,
  masterHubData,
}) => {
  const [openInsert, setOpenInsert] = useState(null);
  const isOpenInsert = Boolean(openInsert);

  const [openRows, setOpenRows] = useState(null);
  const isOpenRows = Boolean(openRows);

  const [openColumns, setOpenColumns] = useState(null);
  const isOpenColumns = Boolean(openColumns);

  const idOpenRows = isOpenRows ? 'row-popover' : undefined;
  const idOpenColumns = isOpenColumns ? 'column-popover' : undefined;

  const newData = new Array(numberOfRows + 2)
    .fill(0)
    .map(() => new Array(numberOfColumns + 2).fill(0));

  const [rowChecked, setRowChecked] = useState([]);

  const [allColRow, setAllColRow] = useState([]);
  const [mapMoment, setMapMoment] = useState([]);
  const [mapMomentAwait, setMapMomentAwait] = useState([]);

  const [isaddEnterence, setIsAddEnterence] = useState(false);
  const [allCellEnterence, setAllCellEnterence] = useState([]);
  const [cellEntrance, setCellEntrance] = useState([]);

  const [rackSelect, setRackSelect] = useState([]);

  useEffect(() => {
    let datas = [...rowChecked, mapMoment];
    if (mapMoment !== mapMomentAwait) {
      setAllColRow(datas.flat());
      setMapMomentAwait(mapMoment);
    }
  }, [rowChecked, allColRow, mapMoment, mapMomentAwait]);

  useEffect(() => {
    let hasil = [];
    newData.map((row, rowIndex) => {
      row.map((_, colIndex) => {
        if (
          colIndex === 0 ||
          colIndex === row.length - 1 ||
          rowIndex === 0 ||
          rowIndex === newData.length - 1
        ) {
          hasil.push(`c${colIndex + 1}r${rowIndex + 1}`);
        }
      });
    });
    setAllCellEnterence(hasil);
  }, []);

  const handleRowChecked = (rowIndex, colIndex) => {
    if (allCellEnterence.includes(`c${colIndex}r${rowIndex}`)) {
      if (isaddEnterence) {
        if (cellEntrance.includes(`c${colIndex}r${rowIndex}`)) {
          setCellEntrance(
            cellEntrance.filter((item) => item != `c${colIndex}r${rowIndex}`)
          );
        } else {
          setCellEntrance([...cellEntrance, `c${colIndex}r${rowIndex}`]);
        }
      }
    } else {
      if (mapMoment.includes(`c${colIndex}r${rowIndex}`)) {
        setMapMoment(
          mapMoment.filter((item) => item != `c${colIndex}r${rowIndex}`)
        );
      } else {
        setMapMoment([...mapMoment, `c${colIndex}r${rowIndex}`]);
      }
    }
  };

  const handleSelectOk = () => {
    if (mapMoment.length !== 0) {
      setRowChecked([...rowChecked, mapMoment]);
      setRackSelect([
        ...rackSelect,
        {
          name: '',
          col: 5,
          level: 5,
          des: '',
        },
      ]);
      setMapMoment([]);
    }
  };

  const handleDeleteMap = (input, index) => {
    setAllColRow(allColRow.filter((el) => !input.includes(el)));
    setRowChecked(rowChecked.filter((item) => item !== input));
    setRackSelect(rackSelect.filter((_, idx) => idx !== index));
  };

  const handleAddEnterence = () => {
    setIsAddEnterence(!isaddEnterence);
  };

  const handleNext = () => {
    let hasil = rowChecked.map((item) => {
      return item.join(',');
    });
    rackSelect.map((item, index) => {
      let datas = {
        idToko: masterHubData.masterHubId,
        name: item.name,
        column: item.col,
        row: item.level,
        columnPosition: hasil.find((_, idx) => idx === index),
        description: item.des,
      };
      addNewRackMasterHub(datas)
        .then()
        .catch((err) => console.error(err));
    });
    if (isaddEnterence) {
      editMasterHub(cellEntrance, masterHubData.masterHubId)
        .then(() => {
          setIsCreateGridOpen(false);
          setIsSelectRacksOpen(false);
          setIsPreviewOpen(true);
        })
        .catch((err) => console.error(err));
    }
    setIsCreateGridOpen(false);
    setIsSelectRacksOpen(false);
    setIsPreviewOpen(true);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex', gap: 6, pb: 8 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <CustomButton
              sx={{ border: '1px solid #51B15C', color: '#51B15C' }}
              endIcon={<ArrowDropDownRoundedIcon />}
              onClick={(e) => setOpenInsert(e.currentTarget)}
            >
              Insert
            </CustomButton>
            <CustomMenu
              open={isOpenInsert}
              openMenu={openInsert}
              setOpenMenu={setOpenInsert}
              sx={{
                '.MuiMenuItem-root': {
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  paddingTop: 0,
                  paddingBottom: 0,
                  borderRadius: '8px',
                },
              }}
            >
              <MenuItem>
                <ListItemIcon
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  onClick={handleAddEnterence}
                >
                  <EntranceIcon fontSize="small" />
                  <Typography
                    sx={{ fontSize: '0.75rem', color: 'primary.black' }}
                  >
                    Entrance
                  </Typography>
                </ListItemIcon>
              </MenuItem>
              <MenuItem
                sx={{
                  mt: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: '0.75rem',
                }}
                onClick={(e) => setOpenRows(e.currentTarget)}
                aria-describedby={idOpenRows}
              >
                <Typography
                  sx={{ fontSize: '0.75rem', color: 'primary.black' }}
                >
                  Rows
                </Typography>

                <ArrowRightRoundedIcon sx={{ padding: 0 }} />
              </MenuItem>
              <Popover
                id={idOpenRows}
                open={isOpenRows}
                anchorEl={openRows}
                onClose={() => setOpenRows(null)}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ py: 0.5, borderRadius: '8px' }}>
                  <MenuItem sx={{ fontSize: '0.75rem' }}>
                    Insert 1 row left
                  </MenuItem>
                  <MenuItem sx={{ fontSize: '0.75rem' }}>
                    Insert 1 row right
                  </MenuItem>
                </Box>
              </Popover>
              <MenuItem
                sx={{
                  mt: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: '0.75rem',
                }}
                onClick={(e) => setOpenColumns(e.currentTarget)}
                aria-describedby={idOpenColumns}
              >
                <Typography
                  sx={{ fontSize: '0.75rem', color: 'primary.black' }}
                >
                  Columns
                </Typography>

                <ArrowRightRoundedIcon sx={{ padding: 0 }} />
              </MenuItem>
              <Popover
                id={idOpenColumns}
                open={isOpenColumns}
                anchorEl={openColumns}
                onClose={() => setOpenColumns(null)}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ py: 0.5, borderRadius: '8px' }}>
                  <MenuItem sx={{ fontSize: '0.75rem' }}>
                    Insert 1 column left
                  </MenuItem>
                  <MenuItem sx={{ fontSize: '0.75rem' }}>
                    Insert 1 column right
                  </MenuItem>
                </Box>
              </Popover>
            </CustomMenu>

            <Button
              // sx={{
              //   bgcolor: 'primary.main',
              //   color: 'primary.contrastText',
              //   '&:hover': {
              //     bgcolor: 'primary.main',
              //     color: 'primary.contrastText',
              //   },
              // }}
              variant="contained"
              disabled={mapMoment.length === 0}
              onClick={handleSelectOk}
            >
              Add Rack
            </Button>
          </Box>
          <Box>
            <Box>
              {newData.map((row, rowIndex) => (
                <Box key={rowIndex} sx={{ display: 'flex', cursor: 'pointer' }}>
                  {row.map((column, columnIndex) => {
                    return (
                      <Box key={columnIndex} sx={{ flexGrow: 1 }}>
                        <Box
                          onClick={() =>
                            handleRowChecked(rowIndex + 1, columnIndex + 1)
                          }
                          sx={[
                            {
                              borderTop: '0.75px solid #000',
                              borderLeft: '0.75px solid #000',
                              borderRight: '0.75px solid #000',
                              borderBottom: '0.75px solid #000',
                              textAlign: 'center',
                              width: '30px',
                              height: '30px',
                              color: '#fff',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            },
                            allColRow.includes(
                              `c${columnIndex + 1}r${rowIndex + 1}`
                            ) && {
                              bgcolor: 'primary.main',
                              color: 'primary.main',
                            },
                            isaddEnterence
                              ? cellEntrance.includes(
                                  `c${columnIndex + 1}r${rowIndex + 1}`
                                )
                                ? {
                                    bgcolor: '#E4E4E4',
                                    color: '#E4E4E4',
                                    borderColor: '#E4E4E4',
                                  }
                                : allCellEnterence.includes(
                                    `c${columnIndex + 1}r${rowIndex + 1}`
                                  ) && {
                                    bgcolor: '#F5F5F5',
                                    color: '#F5F5F5',
                                    borderColor: '#F5F5F5',
                                  }
                              : allCellEnterence.includes(
                                  `c${columnIndex + 1}r${rowIndex + 1}`
                                ) && {
                                  bgcolor: 'white',
                                  color: 'white',
                                  borderColor: 'white',
                                },
                          ]}
                          noWrap
                        >
                          {/* {column} */}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ position: 'absolute', right: '1px', top: '1px', pb: '80px' }}
        >
          {rowChecked.map((item, index) => (
            <CustomCard sx={{ p: '12px', mb: '8px', bgcolor: 'white' }}>
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>Rack {index + 1} </Box>
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleDeleteMap(item, index)}
                >
                  <DeleteIcon color="error" />
                </Box>
              </Box>
              <Box>
                <Box>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Rack Name
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <TextField
                      value={rackSelect[index].name}
                      onChange={(e) => {
                        let value = e.target.value;
                        let res = rackSelect.map((item, idx) => {
                          if (idx === index) {
                            return {
                              ...item,
                              name: value,
                            };
                          } else {
                            return item;
                          }
                        });
                        setRackSelect(res);
                      }}
                      placeholder="Enter Name"
                      sx={{
                        bgcolor: '#FAFAFA',
                        width: '100%',
                        '.MuiOutlinedInput-root': {
                          fontSize: '12px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 16px',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '4px',
                          '&::placeholder': {
                            color: '#9E9D9D',
                            fontSize: '0.75rem',
                            fontWeight: 500,
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: '16px' }}>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Number of columns
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <TextField
                      value={rackSelect[index].col}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (value >= 0 && value <= 50) {
                          let res = rackSelect.map((item, idx) => {
                            if (idx === index) {
                              return {
                                ...item,
                                col: parseInt(value),
                              };
                            } else {
                              return item;
                            }
                          });
                          setRackSelect(res);
                        }
                      }}
                      type="number"
                      placeholder="Enter Column"
                      sx={{
                        bgcolor: '#FAFAFA',
                        width: '130px',
                        '.MuiOutlinedInput-root': {
                          fontSize: '12px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 16px',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '4px',
                          '&::placeholder': {
                            color: '#9E9D9D',
                            fontSize: '0.75rem',
                            fontWeight: 500,
                          },
                        },
                      }}
                    />
                    <Box
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (rackSelect[index].col >= 0) {
                          let res = rackSelect.map((item, idx) => {
                            if (idx === index) {
                              return {
                                ...item,
                                col: parseInt(rackSelect[index].col) - 1,
                              };
                            } else {
                              return item;
                            }
                          });
                          setRackSelect(res);
                        }
                      }}
                    >
                      -
                    </Box>
                    <Box
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (rackSelect[index].col <= 50) {
                          let res = rackSelect.map((item, idx) => {
                            if (idx === index) {
                              return {
                                ...item,
                                col: parseInt(rackSelect[index].col) + 1,
                              };
                            } else {
                              return item;
                            }
                          });
                          setRackSelect(res);
                        }
                      }}
                    >
                      +
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: '16px' }}>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Number of Level
                  </Typography>
                  <Box
                    sx={{ display: 'flex', gap: 1, justifyContent: 'start' }}
                  >
                    <TextField
                      value={rackSelect[index].level}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (value >= 0 && value <= 50) {
                          let res = rackSelect.map((item, idx) => {
                            if (idx === index) {
                              return {
                                ...item,
                                level: parseInt(value),
                              };
                            } else {
                              return item;
                            }
                          });
                          setRackSelect(res);
                        }
                      }}
                      type="number"
                      placeholder="Enter Level"
                      sx={{
                        bgcolor: '#FAFAFA',
                        width: '130px',
                        '.MuiOutlinedInput-root': {
                          fontSize: '12px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 16px',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '4px',
                          '&::placeholder': {
                            color: '#9E9D9D',
                            fontSize: '0.75rem',
                            fontWeight: 500,
                          },
                        },
                      }}
                    />
                    <Box
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (rackSelect[index].level >= 0) {
                          let res = rackSelect.map((item, idx) => {
                            if (idx === index) {
                              return {
                                ...item,
                                level: parseInt(rackSelect[index].level) - 1,
                              };
                            } else {
                              return item;
                            }
                          });
                          setRackSelect(res);
                        }
                      }}
                    >
                      -
                    </Box>
                    <Box
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (rackSelect[index].level >= 0) {
                          let res = rackSelect.map((item, idx) => {
                            if (idx === index) {
                              return {
                                ...item,
                                level: parseInt(rackSelect[index].level) + 1,
                              };
                            } else {
                              return item;
                            }
                          });
                          setRackSelect(res);
                        }
                      }}
                    >
                      +
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: '16px' }}>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Description
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <TextField
                      value={rackSelect[index].des}
                      onChange={(e) => {
                        let value = e.target.value;
                        let res = rackSelect.map((item, idx) => {
                          if (idx === index) {
                            return {
                              ...item,
                              des: value,
                            };
                          } else {
                            return item;
                          }
                        });
                        setRackSelect(res);
                      }}
                      placeholder="Enter Descripition"
                      sx={{
                        bgcolor: '#FAFAFA',
                        width: '100%',
                        '.MuiOutlinedInput-root': {
                          fontSize: '12px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 16px',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '4px',
                          '&::placeholder': {
                            color: '#9E9D9D',
                            fontSize: '0.75rem',
                            fontWeight: 500,
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </CustomCard>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '18px 32px 18px 240px',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <CustomButton
            sx={{
              bgcolor: 'primary.contrastText',
              color: 'primary.main',
              border: '1px solid #51B15C',
              '&:hover': {
                bgcolor: 'primary.contrastText',
                color: 'primary.main',
                border: '1px solid #51B15C',
              },
              fontWeight: 600,
              fontSize: '0.875rem',
              px: 6,
              py: 1,
            }}
            onClick={() => {
              setIsCreateGridOpen(true);
              setIsSelectRacksOpen(false);
              setIsPreviewOpen(false);
            }}
          >
            Back
          </CustomButton>
          <CustomButton
            sx={{
              fontWeight: 600,
              fontSize: '0.875rem',
              px: 6,
              py: 1,
            }}
            variant="contained"
            disabled={
              rackSelect.filter(
                (item) => item.name === '' || item.col === 0 || item.level === 0
              ).length !== 0 || rackSelect.length === 0
            }
            onClick={handleNext}
          >
            Next
          </CustomButton>
          {/* <CustomButton onClick={handleSelectMap}>
            {isSelectMap ? 'matikan' : 'hidupkan'}
          </CustomButton>
          <CustomButton onClick={handleSelectOk} variant="contained">
            kumpul
          </CustomButton> */}
          {/* <CustomButton onClick={() => console.log('cekk ', rackSelect)}>
            Cekk
          </CustomButton> */}
        </Box>
      </Box>
    </Box>
  );
};

export default SelectRacksComponent;
