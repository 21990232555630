import {
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeIdDetailAdjustment,
  getIdDetailAdjustment,
} from '../../../../../../../store/adjustment/detailAdjustment';
import {
  addNewItemAdjustment,
  changeEditNotesAdjustment,
  getDataEditAdjustment,
  getDataToEditAdjustment,
  getLocationEditAdjustment,
  getNotesEditAdjustment,
  getTypeEditAdjustment,
  goToEditAdjustment,
} from '../../../../../../../store/adjustment/editAdjustment';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import CustomInput from '../../../../../../../components/shared/CustomInput';
import CustomSelectProduct from '../Common/CustomSelectproduct';
import {
  AddIcon,
  CloseIcons,
  DeleteIcon,
  EditPen,
} from '../../../../../../../assets/Icons';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  deleteAdjustment,
  getDifferentProductAdjustment,
  updateAdjustment,
} from '../../../../../../../api/adjustment';
import {
  changePageAdjustment,
  setNotifAdjusment,
} from '../../../../../../../store/Routing/addjustment';
import CustomDialog from '../../../../../../../components/shared/CustomDialog';

const EditAdjustmentMobile = ({ setIsEdit }) => {
  const dispatch = useDispatch();
  const datas = useSelector(getDataEditAdjustment);
  const temporaryData = useSelector(getDataToEditAdjustment);
  const idAdjustment = useSelector(getIdDetailAdjustment);
  const location = useSelector(getLocationEditAdjustment);
  const notes = useSelector(getNotesEditAdjustment);
  const type = useSelector(getTypeEditAdjustment);
  const idAudit = datas?.idAudit;
  const [tambahItem, setTambahItem] = useState([]);

  // store data to option select
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setTambahItem(temporaryData);
  }, [temporaryData]);

  // validation
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isDisableEdit, setIsDisableEdit] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  useEffect(() => {
    const cek = tambahItem.find((item, _) => item?.auditQrId === 0);
    if (notes !== '' && notes.length >= 5 && cek === undefined) {
      setIsDisableEdit(true);
    } else {
      setIsDisableEdit(false);
    }
  }, [tambahItem, notes]);

  // ! ----------------------- API ------------------------------

  // get data not selected
  useEffect(() => {
    const fetchDifferentAdjust = async () => {
      const id = temporaryData
        .map((item, _) => {
          if (item?.auditQrId !== 0) {
            return item?.auditQrId;
          }
          return null;
        })
        .filter((id) => id !== null);
      const params = {};
      params.audit_qr_id = id;
      params.audit_id = idAudit;
      try {
        const {
          data: { data },
        } = await getDifferentProductAdjustment(params);
        console.log({ data });
        setNewData(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDifferentAdjust();
  }, [temporaryData]);

  // ! -------------------- Handler -------------------------
  const handleAddItem = () => {
    dispatch(
      addNewItemAdjustment({
        namaProduk: '',
        idProduk: 0,
        system: 0,
        systemUnit: '',
        hubRackItemId: 0,
        different: 0,
        auditQrId: 0,
        auditId: 0,
        actual: 0,
        actualUnit: '',
        qty: 0,
        unit: '',
      })
    );
  };

  const handleEdit = async () => {
    const params = {};
    const newHubRackItemId = tambahItem?.map((item) => item.hubRackItemId);
    const newAuditQrId = tambahItem?.map((item) => item.auditQrId);

    params.hub_rack_item_id = newHubRackItemId;
    params.id_audit_qrs = newAuditQrId;
    params.notes = notes;
    // params.idAudit = idAudit;
    setIsLoadingSubmit(true);
    try {
      const data = await updateAdjustment(params, idAdjustment);
      if (data.status === 200) {
        setIsLoadingSubmit(false);
        dispatch(changePageAdjustment(''));
        dispatch(
          setNotifAdjusment({
            key: 'editAdjustment',
            text: 'Adjustment uptodate',
          })
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    try {
      await deleteAdjustment(idAdjustment);
      setIsLoadingDelete(false);
      setIsDelete(false);
      dispatch(changePageAdjustment(''));
      dispatch(
        setNotifAdjusment({
          key: 'deleteAdjustment',
          text: 'Adjustment has been deleted',
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <Box sx={{ mt: 2, position: 'relative', minHeight: '82vh' }}>
      <Grid container columns={12} spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <BackButtonHub onClick={() => setIsEdit(false)} />
            <Typography
              sx={{ fontSize: '14px', fontWeight: 600, color: '#000' }}
            >
              {idAdjustment} Edit
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              gap: 1,
              height: '54px',
              background: '#FAFAFA',
              padding: '0px 16px',
              borderRadius: '8px',
              mt: 2,
            }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, color: '#9E9E9E' }}
            >
              Location
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
            >
              {location}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}>
            Condition*
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', p: 0, m: 0 }}>
            <Radio checked size="small" sx={{ paddingLeft: '0px' }} />
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, color: '#000' }}
            >
              {type}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <CustomInput
            label={'Notes*'}
            variant={'filled'}
            fullWidth
            sx={{
              bgcolor: '#fafafa',
              '& .MuiOutlinedInput-input': {
                padding: '17px 16px',
                borderRadius: '10px',
                '&::placeholder': {
                  color: 'red',
                  fontSize: '4px',
                },
              },
              borderRadius: '5px',
            }}
            value={notes}
            onChange={(e) => {
              if (e.target.value.length <= 100) {
                dispatch(changeEditNotesAdjustment(e.target.value));
              }
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: '10px',
                mt: '5px',
                color: '#9E9D9D',
              }}
            >
              {notes.length > 5 ? '' : 'Enter at least 5 characters'}
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                mt: '5px',
                ml: '15px',
                color: '#9E9D9D',
                fontWeight: 400,
              }}
            >
              {notes.length}/100
            </Typography>
          </Box>
        </Grid>

        {/* items */}
        <Grid item xs={12} sx={{ mt: 2, marginBottom: '56px' }}>
          <CustomCard>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '15px',
                boxShadow: '0px 1px 5px #ccc',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                Item(s)
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  color: '#51B15C',
                  gap: 1,
                  cursor: 'pointer',
                }}
                onClick={handleAddItem}
              >
                <AddIcon />
                Add more
              </Typography>
            </Box>

            <Box>
              <Grid container columns={12} spacing={2} sx={{ p: '15px 10px' }}>
                {tambahItem.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Grid container columns={12} spacing={2}>
                      {/* product */}
                      <Grid item xs={10}>
                        <CustomSelectProduct
                          newData={newData}
                          defaultValue={item}
                          handleValue={(dataUpdate) => {
                            const memo = tambahItem.map((item, idx) => {
                              if (index === idx) {
                                return {
                                  ...item,
                                  ...dataUpdate,
                                };
                              } else {
                                return item;
                              }
                            });
                            setTambahItem(memo);
                            dispatch(goToEditAdjustment(memo));
                          }}
                        />
                      </Grid>
                      {/* hapus item */}
                      <Grid
                        item
                        xs={2}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          // mt: 4,
                          color: 'red',
                        }}
                      >
                        {tambahItem.length === 1 ? (
                          <></>
                        ) : (
                          <CloseIcons
                            sx={{
                              cursor: 'pointer',
                              width: '20px',
                            }}
                            onClick={() => {
                              const newItem = [...temporaryData];
                              newItem.splice(index, 1);
                              dispatch(goToEditAdjustment(newItem));
                            }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 2,
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #E4E4E4',
                            pb: 2,
                          }}
                        >
                          <Box
                            sx={{
                              background: '#FAFAFA',
                              padding: '8px 16px',
                              borderRadius: '8px',
                              width: '100%',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#9E9E9E',
                              }}
                            >
                              System
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                color: '#000000',
                                mt: 0.5,
                              }}
                            >
                              {item?.system || 0} {item?.systemUnit || 'unit'}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              background: '#FAFAFA',
                              padding: '8px 16px',
                              borderRadius: '8px',
                              width: '100%',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#9E9E9E',
                              }}
                            >
                              Actual
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                color: '#000000',
                                mt: 0.5,
                              }}
                            >
                              {item?.actual || 0} {item?.actualUnit || 'unit'}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              background: '#FAFAFA',
                              padding: '8px 16px',
                              borderRadius: '8px',
                              width: '100%',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#9E9E9E',
                              }}
                            >
                              Difference
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                color: '#000000',
                                mt: 0.5,
                              }}
                            >
                              {item?.difference || 0}{' '}
                              {item?.systemUnit || 'unit'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </CustomCard>
        </Grid>
      </Grid>
      {/* modal/ popup edit adjustment */}
      <CustomDialog
        isOpen={isOpenEdit}
        setIsOpen={setIsOpenEdit}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                background: 'rgba(243, 58, 58, 0.1)',
                borderRadius: '100px',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EditPen sx={{ fontSize: '1.25rem', color: '#F33A3A' }} />
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}
            >
              Edit Adjustment
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, color: '#000', mt: 2 }}
          >
            Are you sure you want to save the changes ?
          </Typography>

          {/* button */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 3,
              marginTop: '56px',
            }}
          >
            <Typography
              onClick={() => setIsOpenEdit(false)}
              sx={{ fontSize: '14px', fontWeight: 600, color: '#9D9D9D' }}
            >
              Back
            </Typography>
            <CustomButton
              variant="contained"
              sx={{ width: '100px', height: '37px' }}
              onClick={handleEdit}
            >
              {isLoadingSubmit ? (
                <CircularProgress size={20} style={{ color: '#FFF' }} />
              ) : (
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
                >
                  Save
                </Typography>
              )}
            </CustomButton>
          </Box>
        </DialogContent>
      </CustomDialog>
      {/* modal / popup delete adjustment */}
      <CustomDialog
        isOpen={isDelete}
        setIsOpen={setIsDelete}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                background: 'rgba(243, 58, 58, 0.1)',
                borderRadius: '100px',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DeleteIcon sx={{ fontSize: '1.25rem', color: '#F33A3A' }} />
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}
            >
              Delete Adjustment
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, color: '#000', mt: 2 }}
          >
            Are you sure you want to delete {idAdjustment} ?
          </Typography>

          {/* button */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 3,
              marginTop: '56px',
            }}
          >
            <Typography
              onClick={() => setIsDelete(false)}
              sx={{ fontSize: '14px', fontWeight: 600, color: '#9D9D9D' }}
            >
              Back
            </Typography>
            <CustomButton
              variant="contained"
              color={'error'}
              sx={{ width: '100px', height: '37px' }}
              onClick={handleDelete}
            >
              {isLoadingDelete ? (
                <CircularProgress size={20} style={{ color: '#FFF' }} />
              ) : (
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
                >
                  Delete
                </Typography>
              )}
            </CustomButton>
          </Box>
        </DialogContent>
      </CustomDialog>
      {/* button event */}
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: 0,
          display: 'flex',
          gap: 2,
        }}
      >
        <CustomButton
          variant="contained"
          color={'error'}
          sx={{ width: '100%', height: '40px' }}
          onClick={() => setIsDelete(true)}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}>
            Delete
          </Typography>
        </CustomButton>
        <CustomButton
          variant="contained"
          sx={{ width: '100%', height: '40px' }}
          onClick={() => setIsOpenEdit(true)}
          disabled={!isDisableEdit}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}>
            Save
          </Typography>
        </CustomButton>
      </Box>
    </Box>
  );
};
export default EditAdjustmentMobile;
