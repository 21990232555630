import { Autocomplete, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDataSelectedAdjustment,
  getIdDetailAuditToAdjustment,
} from '../../../../../../../store/adjustment/addAdjustment';
import { getDifferentProductAdjustment } from '../../../../../../../api/adjustment';

const CustomSelectProduct = ({
  newData,
  handleValue,
  isReset,
  setIsReset,
  isDisableProduct,
  defaultValue,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [inputDebounce, setInputDebounce] = useState('');

  const inputDebounceChange = useRef(
    debounce((value) => {
      setInputDebounce(value);
    }, 1000)
  ).current;

  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [productFix, setProductFix] = useState(null);

  useEffect(() => {
    if (isReset) {
      setIsReset(false);
      return setProduct(defaultValue);
    }
  }, [isReset]);

  useEffect(() => {
    if (defaultValue?.auditQrId !== 0) {
      return setProduct(defaultValue);
    }
  }, []);

  useEffect(() => {
    if (product !== productFix) {
      return handleValue(product);
      return setProductFix(product);
    }
  }, [product, productFix]);

  useEffect(() => {
    if (product !== productFix) {
      return handleValue(product);
      return setProductFix(product);
    }
  }, [product, productFix]);

  return (
    <Autocomplete
      disabled={isDisableProduct}
      sx={{ minWidth: '100%', bgcolor: '#FAFAFA' }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        inputDebounceChange(newInputValue);
      }}
      options={newData}
      getOptionLabel={(option) => option.namaProduk}
      value={product}
      onChange={(e, newValue) => setProduct(newValue)}
      loading={isLoading}
      renderInput={(params) => <TextField {...params} placeholder="Product*" />}
    />
  );
};

export default CustomSelectProduct;
