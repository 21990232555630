import { useDispatch, useSelector } from 'react-redux';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import { getAllAdjustmentRouting, setCloseNotifAdjustment } from '../../../../../../store/Routing/addjustment';

const NotifAdjustment = () => {
  const dispatch = useDispatch();
  const { keyNotif, textNotif } = useSelector(getAllAdjustmentRouting);

  const keys = ['addAdjustment', 'editAdjustment', 'deleteAdjustment'];
  return (
    <>
      {keys.map((key, index) => (
        <CustomNotifSuccess
          text={textNotif}
          open={keyNotif === key}
          onClose={() => {
            dispatch(setCloseNotifAdjustment());
          }}
          key={index}
        />
      ))}
    </>
  );
};
export default NotifAdjustment;
