import { createSlice } from '@reduxjs/toolkit';
import { getAllHubs, getAllMiniHub, getAllSubHub } from '../../../api/hubs';

export const AddTransactionState = createSlice({
  name: 'addTransaction',
  initialState: {
    isLoading: false,
    hub: [],
    subHub: [],
    miniHub: [],
  },
  reducers: {
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadAllDataHub: (state, action) => {
      state.hub = action.payload;
    },
    loadAllDataSubHub: (state, action) => {
      state.subHub = action.payload;
    },
    loadAllDataMiniHub: (state, action) => {
      state.miniHub = action.payload;
    },
  },
});

export const {
  changeIsLoading,
  loadAllDataHub,
  loadAllDataSubHub,
  loadAllDataMiniHub,
} = AddTransactionState.actions;

export const getDataHubTransaction = (state) => state.addTransaction.hub;
export const getDataSubHubTransaction = (state) => state.addTransaction.subHub;
export const getDataMiniHubTransaction = (state) =>
  state.addTransaction.miniHub;

// ! -------------------- API -----------------
// get hub
export const getAllMasterHub = (params) => (dispatch) => {
  const fetchDataHub = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: {
          data: { hubs },
        },
      } = await getAllHubs(params);
      let sliceData = hubs.map((hub, _) => {
        return { ...hub, typeHub: 'hub' };
      });

      let fixDatas = sliceData?.map((data, _) => {
        return {
          id: data?.idToko,
          label: data?.namaToko,
          typeHub: data?.typeHub,
        };
      });
      dispatch(loadAllDataHub(fixDatas));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };
  fetchDataHub();
};

// get sub hub
export const getAllSubHubData = (params) => (dispatch) => {
  const fetchDataSubHub = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllSubHub(params);
      let sliceDataSubHub = subHubs.map((subHub, _) => {
        return { ...subHub, typeHub: 'subhub' };
      });

      let fixDatasSubHub = sliceDataSubHub?.map((data, _) => {
        return {
          id: data?.id,
          label: data?.name,
          typeHub: data?.typeHub,
        };
      });
      dispatch(loadAllDataSubHub(fixDatasSubHub));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };
  fetchDataSubHub();
};

// get mini hub
export const getAllMiniHubData = (params) => (dispatch) => {
  const fetchDataMiniHub = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllMiniHub(params);
      let sliceDataMiniHub = subHubs.map((miniHub, _) => {
        return { ...miniHub, typeHub: 'minihub' };
      });

      let fixDatasMiniHub = sliceDataMiniHub?.map((data, _) => {
        return {
          id: data?.id,
          label: data?.name,
          typeHub: data?.typeHub,
        };
      });
      dispatch(loadAllDataMiniHub(fixDatasMiniHub));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };
  fetchDataMiniHub();
};

export default AddTransactionState.reducer;
