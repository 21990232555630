import { Box, Typography } from '@mui/material';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';

const ListRackMiniHub = ({ name, handleEdit, handleDelete, ...props }) => {
  return (
    <CustomCard
      sx={{ p: '16px 12px', width: '238px', mb: '8px', bgcolor: 'white' }}
      {...props}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: '12px' }}>{name}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ModeEditRoundedIcon
            onClick={handleEdit}
            sx={{ color: '#FD7900', fontSize: '16px', cursor: 'pointer' }}
          />
          <DeleteRoundedIcon
            onClick={handleDelete}
            sx={{ color: '#F33A3A', fontSize: '16px', cursor: 'pointer' }}
          />
        </Box>
      </Box>
    </CustomCard>
  );
};
export default ListRackMiniHub;
