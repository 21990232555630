import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  Actual,
  ChevronRightIcon,
  Different,
  ExportIcon,
  FilterAltIcon,
  SearchIcon,
  StockAlert,
  System,
  ToBarChart,
  ToLineChart,
} from '../../../../../../../assets/Icons';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import { changePageAudit } from '../../../../../../../store/Routing/auditRouting';
import {
  getHubDetailAudit,
  getIdDetailAudit,
} from '../../../../../../../store/audit/detailAudit';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import CustomLineChart from '../../../../../../../components/shared/CustomLineChart';
import CustomBarChart from '../../../../../../../components/shared/CustomBarChart';
import { useEffect, useState } from 'react';
import CustomTableMobile from '../../../../../../../components/shared/CustomTableMobile';
import { useRef } from 'react';
import debounce from 'lodash.debounce';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import {
  getAmountMissingQrCode,
  getSpesifikDataAudit,
} from '../../../../../../../api/audit';
import { changePageAdjustment } from '../../../../../../../store/Routing/addjustment';
import {
  changeIdAuditDetailToAdjustment,
  changeLocationAuditToAdjustment,
  goToAddAdjustment,
} from '../../../../../../../store/adjustment/addAdjustment';

// data dummy chart
const datas = {
  labels: ['Product 1', 'Product 2', 'Product 3', 'Product 4', 'Product 5'],
  data: [
    {
      label: 'pcs',
      chart: [30, 130, 70, 100, 240],
    },
    {
      label: 'ml',
      chart: [0, 35, 100, 90, 150],
    },
  ],
};
const color = ['#6B4BB4', '#EACD57'];

const DetailAuditMobile = ({ setValue }) => {
  const options = {
    scales: {
      y: {
        ticks: {
          color: '#B8B8B8',
          font: {
            size: 8,
            weight: 600,
            family: 'Poppins',
          },
        },
        title: {
          color: 'yellow',
          font: {
            size: 8,
            weight: 600,
            family: 'Poppins',
          },
        },
      },
      x: {
        ticks: {
          color: '#B8B8B8',
          font: {
            size: 8,
            weight: 600,
            family: 'Poppins',
          },
        },
        title: {
          display: true,
          color: '#B8B8B8',
          font: {
            size: 8,
            weight: 600,
            family: 'Poppins',
          },
        },
      },
      y1: {
        position: 'right',
        ticks: {
          color: '#B8B8B8',
          font: {
            size: 8,
            weight: 600,
            family: 'Poppins',
          },
        },
        title: {
          color: 'blue',
          font: {
            size: 8,
            weight: 600,
            family: 'Poppins',
          },
        },
      },
    },
  };
  const dispatch = useDispatch();
  const id = useSelector(getIdDetailAudit);
  const [chartType, setChartType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataDetails, setDataDetails] = useState([]);
  const [amountMissingQrCode, setAmountMissingQrCode] = useState(0);
  const [adjusData, setAdjustData] = useState([]);
  const location = useSelector(getHubDetailAudit);

  // ! seacrh
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  // ! ---------------- fetch API ------------------
  // get amount missing qr code
  useEffect(() => {
    const fetchAmountMissingQrCode = async () => {
      try {
        const {
          data: { data },
        } = await getAmountMissingQrCode(id);
        setAmountMissingQrCode(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchAmountMissingQrCode();
  }, [id]);

  const fetchSpesifikDataDetailAudit = async (id, params) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getSpesifikDataAudit(id, params);
      setDataDetails(data?.audits);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let params = {};
    params.missing = true;
    fetchSpesifikDataDetailAudit(id, params);
  }, [id]);

  // !----------------- Toolbar -------------------------------
  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        // justifyContent: 'center',
      }}
    >
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
        // onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ width: '14px' }} />
      </CustomButton>
      {/* <DialogFilterAuditMobile {...{ isFilter, setIsFilter }} /> */}
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <ExportIcon />
      </CustomButton>
    </Box>
  );

  // ! handler
  const handleSelectToAdjust = (item, id) => {
    if (adjusData.filter((val) => val.auditQrId === id).length === 0) {
      setAdjustData((prev) => [...prev, item]);
    } else {
      setAdjustData(adjusData.filter((val) => val.auditQrId !== id));
    }
  };

  const handleCancelSelect = () => {
    setAdjustData([]);
  };

  const handleToAdjust = () => {
    dispatch(goToAddAdjustment(adjusData));
    dispatch(changePageAdjustment('addDifferenceAdjustment'));
    setValue(7);
    dispatch(changeIdAuditDetailToAdjustment(id));
    dispatch(changeLocationAuditToAdjustment(location));
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <BackButtonHub onClick={() => dispatch(changePageAudit(''))} />
          <Box>
            <Typography
              sx={{
                color: '#000000',
                fontSize: '14px',
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              {id} Details
            </Typography>

            <Typography
              sx={{
                fontSize: '10px',
                display: 'flex',
                alignItems: 'center',
                color: '#9E9D9D',
                fontWeight: 600,
                mt: 0.5,
              }}
            >
              {/* subsidiary
              <ChevronRightIcon sx={{ width: '16px' }} /> */}
              {location}
              {/* hub */}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            minWidth: '62px',
            height: '39px',
            borderRadius: '8px',
            border: '1px solid #F33A3A',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
            padding: '0px 8px',
          }}
          onClick={() => {
            dispatch(changePageAudit('missingQrCode'));
          }}
        >
          <StockAlert sx={{ fontSize: '14px' }} />
          <Box
            sx={{
              width: '14px',
              height: '14px',
              background: '#F33A3A',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#FFFFFF',
            }}
          >
            <Typography sx={{ fontSize: '8px', fontWeight: 600 }}>
              {amountMissingQrCode}
            </Typography>
          </Box>
          <ChevronRightIcon sx={{ color: '#F33A3A', fontSize: '18px' }} />
        </Box>
      </Box>

      <Grid container columns={12} spacing={2}>
        {/* grafik */}
        <Grid item xs={12}>
          <CustomCard sx={{ p: 2 }}>
            <Typography
              sx={{ color: '#000', fontSize: '10px', fontWeight: 600, mb: 2 }}
            >
              Quantity Difference
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  fontSize: '8px',
                  color: '#6B4BB4',
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                pcs
              </Typography>
              <Box
                sx={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setChartType(!chartType)}
              >
                {chartType ? (
                  <ToLineChart stroke="#51B15C" />
                ) : (
                  <ToBarChart stroke="#51B15C" />
                )}
              </Box>
              <Typography
                sx={{
                  fontSize: '8px',
                  color: '#EACD57',
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                ml
              </Typography>
            </Box>
            {chartType ? (
              <CustomBarChart
                datas={datas}
                color={color}
                customOptions={options}
              />
            ) : (
              <CustomLineChart
                datas={datas}
                color={color}
                customOptions={options}
              />
            )}
          </CustomCard>
        </Grid>

        {/* table grafik */}
        <Grid item xs={12}>
          <CustomTableMobile
            rootValue={datas?.labels}
            datas={datas.data}
            color={color}
          />
        </Grid>

        {/* list data prduct details audit */}
        <Grid item xs={12}>
          <CustomCard sx={{ p: 2 }}>
            {/* title */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  gap: 2,
                }}
              >
                <Box>
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 600, color: '#000000' }}
                  >
                    Product
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    placeholder={'Search'}
                    size="small"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      debounceOnChange(e.target.value);
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingLeft: '6px',
                        fontSize: '7px',
                      },
                      bgcolor: '#FAFAFA',
                      minHeight: '22px',
                      height: '22px',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ width: '12px' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box>
                {adjusData.length === 0 ? (
                  toolbar
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        color: '#F33A3A',
                        fontSize: '8px',
                        fontWeight: 600,
                      }}
                      onClick={handleCancelSelect}
                    >
                      Cancel
                    </Typography>
                    <Box onClick={handleToAdjust}>
                      <Typography
                        sx={{
                          background: '#51B15C',
                          color: '#FFFFFF',
                          fontWeight: 600,
                          fontSize: '8px',
                          cursor: 'pointer',
                          borderRadius: '4px',
                          padding: '0px 4px',
                          minWidth: '32px',
                          height: '20px',
                          letterSpacing: '1px',
                          textAlign: 'center',
                          lineHeight: '20px',
                        }}
                      >
                        Adjust
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>

            {/* type */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <System />
                <Typography sx={{ fontSize: '8px', fontWeight: 400 }}>
                  System
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Actual stroke="#51B15C" />
                <Typography sx={{ fontSize: '8px', fontWeight: 400 }}>
                  Actual
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Different />
                <Typography sx={{ fontSize: '8px', fontWeight: 400 }}>
                  Different
                </Typography>
              </Box>
            </Box>

            {/* list data */}
            <Grid container columns={12} spacing={2} sx={{ mt: 1 }}>
              {isLoading ? (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : dataDetails.length === 0 ? (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{ fontSize: '10px', color: 'red' }}>
                      No data found
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                dataDetails?.map((item, idx) => (
                  <Grid item xs={6} key={idx}>
                    <Box
                      sx={{
                        background:
                          adjusData.filter(
                            (val) => val.auditQrId === item.auditQrId
                          ).length !== 0
                            ? 'rgba(81, 177, 92, 0.2)'
                            : '#FAFAFA',
                        borderRadius: '8px',
                        p: 2,
                      }}
                      onClick={() =>
                        handleSelectToAdjust(item, item?.auditQrId)
                      }
                    >
                      <Box
                        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                      >
                        <Box>
                          <Box
                            component="img"
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              '/' +
                              item?.gambar
                            }
                            sx={{
                              width: '28px',
                              height: '28px',
                              borderRadius: '8px',
                            }}
                          />
                        </Box>
                        <Typography sx={{ fontSize: '10px', fontWeight: 600 }}>
                          {item?.namaProduk}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          mt: 1,
                        }}
                      >
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <System />
                          <Typography sx={{ fontSize: '8px', fontWeight: 400 }}>
                            {item?.system}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Actual stroke="#51B15C" />
                          <Typography sx={{ fontSize: '8px', fontWeight: 400 }}>
                            {item?.actual}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Different />
                          <Typography sx={{ fontSize: '8px', fontWeight: 400 }}>
                            {item?.difference} {item?.systemUnit}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          </CustomCard>
        </Grid>
      </Grid>
    </>
  );
};
export default DetailAuditMobile;
