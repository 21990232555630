import { useSelector } from 'react-redux';
import { getAllTransactionRouting } from '../../../../../../store/Routing/transactionRouting';
import { Box } from '@mui/material';
import InventoryTransaction from '..';
import TransactionRequest from '../Request';
import AddTransactionRequest from '../Request/Add/AddTransactionRequest';
import NotifTransaction from './NotifTransaction';
import DetailRequestTransaction from '../Request/Detail/DetailRequestTransaction';
import SendTransactionMobile from '../TtansactionMobile/Request/Send/SendTransactionMobile';
import AcceptTransactionMobile from '../TtansactionMobile/Request/Accept/AcceptTransactionMobile';
import ProccessScanAccept from '../TtansactionMobile/Request/Accept/ProccessScanAccept';
import EditTransactionRequest from '../Request/Edit/EditTransactionRequest';
import EditTransactionMobile from '../TtansactionMobile/Request/Edit/EditTransactionMobile';

const RoutingTransaction = () => {
  const { page } = useSelector(getAllTransactionRouting);

  return (
    <Box>
      <Box>
        <NotifTransaction />
      </Box>
      {page === '' && <InventoryTransaction />}
      {page === 'request' && <TransactionRequest />}
      {page === 'addRequest' && <AddTransactionRequest />}
      {page === 'detailRequestTransaction' && <DetailRequestTransaction />}
      {page === 'sendTransactionMobile' && <SendTransactionMobile />}
      {page === 'acceptTransactionMobile' && <AcceptTransactionMobile />}
      {page === 'ProccessScanAccept' && <ProccessScanAccept />}
      {page === 'editTransactionRequest' && <EditTransactionMobile />}
    </Box>
  );
};
export default RoutingTransaction;
