import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { changePage } from '../../../../../../store/Routing/purchaseRouting';
import { ArrowBackIosIcon } from '../../../../../../assets/Icons';

const BackButtonPurchaseProcessScanSupplier = () => {
  const dispatch = useDispatch();
  const handleBackButton = () => {
    dispatch(changePage('purchaseProcessDetailSupplier'));
  };
  return (
    <Box
      sx={{ py: '17px', display: 'flex', gap: 1, alignItems: 'center' }}
      onClick={handleBackButton}
    >
      <Box
        sx={{
          width: '24px',
          height: '24px',
          bgcolor: '#51B15C0D',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ArrowBackIosIcon sx={{ fontSize: '14px', color: '#51B15C' }} />
      </Box>
      <Typography sx={{ fontWeight: '600' }}>Scan</Typography>
    </Box>
  );
};
export default BackButtonPurchaseProcessScanSupplier;
