import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import CustomButton from '../../../../../../../../components/shared/CustomButton';

const CreateGridComponent = (props) => {
  const {
    numberOfColumns,
    setNumberOfColumns,
    numberOfRows,
    setNumberOfRows,
    setIsCreateGridOpen,
    setIsSelectRacksOpen,
    setIsPreviewOpen,
  } = props;

  const handleOnChangeNumberOfColumns = (event) => {
    setNumberOfColumns(event.target.value && parseInt(event.target.value));
  };

  const handleOnChangeNumberOfRows = (event) => {
    setNumberOfRows(event.target.value && parseInt(event.target.value));
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const [isLimitNumberOfColumns, setIsLimitNumberOfColumns] = useState(false);
  const [isLimitNumberOfRows, setIsLimitNumberOfRows] = useState(false);

  useEffect(() => {
    if (numberOfColumns && numberOfRows) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [numberOfColumns, numberOfRows]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: 'primary.black',
              fontWeight: 500,
            }}
          >
            Number of columns
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
              mt: 3,
            }}
          >
            <CustomInput
              fullWidth
              sx={{
                '& .MuiOutlinedInput-input': { padding: '8px 12px 8px 12px' },
              }}
              value={numberOfColumns}
              onChange={handleOnChangeNumberOfColumns}
              type="number"
              error={isLimitNumberOfColumns}
              helperText={
                isLimitNumberOfColumns &&
                'The number of column is over the limit'
              }
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box
                sx={{
                  bgcolor: 'rgba(81, 177, 92, 0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (numberOfColumns > 1) {
                    setNumberOfColumns(parseInt(numberOfColumns - 1));
                  } else {
                    setNumberOfColumns(parseInt(1));
                  }
                }}
              >
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.75rem',
                  }}
                >
                  -
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: 'rgba(81, 177, 92, 0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  setNumberOfColumns(parseInt(numberOfColumns + 1))
                }
              >
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.75rem',
                  }}
                >
                  +
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: 'primary.black',
              fontWeight: 500,
            }}
          >
            Number of rows
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
              mt: 3,
            }}
          >
            <CustomInput
              fullWidth
              sx={{
                '& .MuiOutlinedInput-input': { padding: '8px 12px 8px 12px' },
              }}
              value={numberOfRows}
              onChange={handleOnChangeNumberOfRows}
              type="number"
              error={isLimitNumberOfRows}
              helperText={
                isLimitNumberOfRows && 'The number of rows is over the limit'
              }
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box
                sx={{
                  bgcolor: 'rgba(81, 177, 92, 0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (numberOfRows > 1) {
                    setNumberOfRows(parseInt(numberOfRows - 1));
                  } else {
                    setNumberOfRows(parseInt(1));
                  }
                }}
              >
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.75rem',
                  }}
                >
                  -
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: 'rgba(81, 177, 92, 0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => setNumberOfRows(numberOfRows + 1)}
              >
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.75rem',
                  }}
                >
                  +
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '18px 32px 18px 240px',
          alignItems: 'center',
        }}
      >
        <CustomButton
          sx={{
            bgcolor: isDisabled ? '#C8C8CC' : '#51B15C',
            color: isDisabled ? '#FAFAFA' : 'primary.contrastText',
            '&:hover': {
              bgcolor: isDisabled ? '#C8C8CC' : '#51B15C',
              color: isDisabled ? '#FAFAFA' : 'primary.contrastText',
            },
            fontWeight: 600,
            fontSize: '0.875rem',
            px: 6,
            py: 1,
          }}
          disabled={isDisabled}
          onClick={() => {
            setIsCreateGridOpen(false);
            setIsSelectRacksOpen(true);
            setIsPreviewOpen(false);
          }}
        >
          Next
        </CustomButton>
      </Box>
    </Box>
  );
};

export default CreateGridComponent;
