import React, { useRef, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import BackButton from '../../../../../../../../components/shared/BackButton';
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import DetailMasterHubChart from './DetailMasterHubChart';
import { getDetailHubs } from '../../../../../../../../api/hubs';
import debounce from 'lodash.debounce';

const MasterHubRackDetails = ({
  setMasterHubIdRack,
  setMasterHubIdRackDetail,
  masterHubRackDetailSelect,
  setMasterHubRackDetailSelect,
  saveDateRangeMasterHubRackDetail,
  setSaveDateRangeMasterHubRackDetail,
  dateRangeMasterHubRackDetail,
  setDateRangeMasterHubRackDetail,
  masterHubRackDetail,
  setMasterHubRackDetail,
  rowPerPageMasterHubRackDetail,
  setRowPerPageMasterHubRackDetail,
  totalTableItemsMasterHubRackDetail,
  setTotalTableItemsMasterHubRackDetail,
  currentPageTableMasterHubRackDetail,
  setCurrentPageTableMasterHubRackDetail,
  isLoadingMasterHubRackDetail,
  setIsLoadingMasterHubRackDetail,
  masterHubRackKeywordDetail,
  setMasterHubRackKeywordDetail,
  sortTableMasterHubRackDetail,
  setSortTableMasterHubRackDetail,
  isSortAscMasterHubRackDetail,
  setIsSortAscMasterHubRackDetail,
  rowPerPageValues,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const masterHubRackId = useRef(
    searchParams.get('masterhub-idrack-detail') || null
  );

  const [
    masterHubRackMiniTableSearchDetail,
    setMasterHubRackMiniTableSearchDetail,
  ] = useState('');

  const [
    saveMasterHubRackMiniTableSearchDetail,
    setSaveMasterHubRackMiniTableSearchDetail,
  ] = useState('');

  const debounceMasterHubRackMiniTableDetail = useRef(
    debounce((value) => {
      setSaveMasterHubRackMiniTableSearchDetail(value);
    }, 1000)
  ).current;

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <BackButton
          handleClick={() => {
            setMasterHubIdRackDetail(null);
            setMasterHubIdRack(masterHubRackId.current);
            updatedSearchParams.set(
              'masterhub-idrack',
              masterHubRackId.current
            );
            updatedSearchParams.delete('masterhub-idrack-detail');
            setSearchParams(updatedSearchParams);
          }}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography
            sx={{
              color: 'primary.black',
              fontSize: '1.125rem',
              fontWeight: 600,
            }}
          >
            (Column Name) Details
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                color: 'secondary.light',
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >
              Subsidiary
            </Typography>
            <KeyboardArrowRightIcon
              sx={{
                fontSize: '1rem',
                color: 'secondary.light',
                fontWeight: 600,
              }}
            />
            <Typography
              sx={{
                color: 'secondary.light',
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >
              Hub Name
            </Typography>
            <KeyboardArrowRightIcon
              sx={{
                fontSize: '1rem',
                color: 'secondary.light',
                fontWeight: 600,
              }}
            />
            <Typography
              sx={{
                color: 'secondary.light',
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >
              Rack Name
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <DetailMasterHubChart
          masterHubRackDetailSelect={masterHubRackDetailSelect}
          setMasterHubRackDetailSelect={setMasterHubRackDetailSelect}
          dateRangeMasterHubRackDetail={dateRangeMasterHubRackDetail}
          setDateRangeMasterHubRackDetail={setDateRangeMasterHubRackDetail}
          saveDateRangeMasterHubRackDetail={saveDateRangeMasterHubRackDetail}
          setSaveDateRangeMasterHubRackDetail={
            setSaveDateRangeMasterHubRackDetail
          }
          miniTableDetail={masterHubRackMiniTableSearchDetail}
          setMiniTableDetail={setSaveMasterHubRackMiniTableSearchDetail}
          debounceMasterHubRackMiniTableDetail={
            debounceMasterHubRackMiniTableDetail
          }
        />
      </Box>
    </Box>
  );
};

export default MasterHubRackDetails;
