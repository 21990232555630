import {
  Autocomplete,
  Box,
  Button,
  Chip,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import { CalenderIcon, CloseIcon } from '../../../../../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { DateRange } from 'react-date-range';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useRef } from 'react';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {
  changeEndDateAdjustment,
  changeLocationFilter,
  changeStartDateAdjustment,
  changeStatusFilter,
  changeUserFilter,
  getAllMasterHubAdjustment,
  getAllMiniHubAdjustment,
  getAllSubHubAdjustment,
  getDataHubAdjustment,
  getDataMiniHubAdjustment,
  getDataSubHubAdjustment,
  locationFilterAdjustment,
  statusFilterAdjustment,
  userFilterAdjustment,
} from '../../../../../../store/adjustment/dataAdjustment';
import { useEffect } from 'react';
import { getUsersAdjustment } from '../../../../../../api/adjustment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const statuses = ['accepted', 'canceled', 'pending'];

const DialogFilterAdjustmentDashboard = ({ isFilter, setIsFilter }) => {
  const dispatch = useDispatch();
  const dateRangeRef = useRef(null);
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const allDataHubs = useSelector(getDataHubAdjustment);
  const allDataSubHubs = useSelector(getDataSubHubAdjustment);
  const allDataMiniHubs = useSelector(getDataMiniHubAdjustment);
  const [mixData, setMixData] = useState([]);

  useEffect(() => {
    dispatch(getAllMasterHubAdjustment({ pageSize: 1000 }));
    dispatch(getAllSubHubAdjustment({ pageSize: 1000 }));
    dispatch(getAllMiniHubAdjustment({ pageSize: 1000 }));
  }, []);

  // ! mix data
  useEffect(() => {
    const mixs = [].concat(allDataHubs, allDataSubHubs, allDataMiniHubs);
    setMixData(mixs);
  }, [allDataHubs, allDataSubHubs, allDataMiniHubs]);

  // ! filter data
  const [dates, setDates] = useState(false);
  const [open, setOpen] = useState(false);
  const fixedLocation = useSelector(locationFilterAdjustment);
  const fixedStatus = useSelector(statusFilterAdjustment);
  const fixedUser = useSelector(userFilterAdjustment);
  const [location, setLocation] = useState(fixedLocation);
  const [status, setStatus] = useState(fixedStatus);
  const [user, setUser] = useState(fixedUser);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  // ! ----------------- Handler -----------------------------
  const handleOpen = () => {
    setOpen(true);
    setDates(true);
  };
  const handleClose = () => setOpen(false);
  const handleSaveFilter = () => {
    dispatch(
      changeStartDateAdjustment(
        dayjs(dateRange[0].startDate).format('YYYY-MM-DD')
      )
    );
    dispatch(
      changeEndDateAdjustment(dayjs(dateRange[0].endDate).format('YYYY-MM-DD'))
    );
    dispatch(changeLocationFilter(location));
    dispatch(changeStatusFilter(status));
    dispatch(changeUserFilter(user));
    setIsFilter(false);
  };
  const handleResetFilter = () => {
    setDates(false);
    setLocation([]);
    setStatus([]);
    setUser([]);
    dispatch(changeStartDateAdjustment(''));
    dispatch(changeEndDateAdjustment(''));
    dispatch(changeLocationFilter([]));
    dispatch(changeStatusFilter([]));
    dispatch(changeUserFilter([]));
  };
  const isOptionEqualToId = (option, value) => {
    return option.id === value?.id && option.label === value?.label;
  };
  const isOptionEqualToValue = (option, value) => {
    return option.title === value?.title;
  };
  const isOptionEqualToName = (option, value) => {
    return option.id === value?.id;
  };

  // ! ------------------------------- API ------------------
  // get user
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const {
          data: { data },
        } = await getUsersAdjustment();

        const setToUser = data.map((item, _) => {
          return {
            id: item?.kustomer.idKustomer,
            name: item?.kustomer.namaLengkap,
          };
        });
        setUsers(setToUser);
      } catch (err) {
        console.error(err);
      }
    };

    fetchUser();
  }, []);

  return (
    <CustomDialog isOpen={isFilter} setIsOpen={setIsFilter} fullWidth>
      <DialogTitle
        sx={{
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px 12px 0px 0px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setIsFilter(!isFilter)}
            sx={{
              color: '#323232',
              p: 0,
            }}
          >
            <CloseIcon sx={{ fontSize: '20px' }} />
          </IconButton>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: '#000000',
            }}
            component="span"
          >
            Filter
          </Typography>
          <Typography
            onClick={handleResetFilter}
            sx={{ color: '#51B15C', fontSize: '16px', fontWeight: 600 }}
          >
            Reset
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container columns={12} spacing={2} sx={{ mt: 2 }}>
          {/* location */}
          <Grid item xs={12}>
            <Autocomplete
              multiple
              value={location}
              onChange={(event, newValue) => {
                setLocation(newValue);
              }}
              options={mixData}
              isOptionEqualToValue={isOptionEqualToId}
              getOptionLabel={(option) => option.label}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Location"
                  placeholder="location"
                />
              )}
            />
          </Grid>

          {/* status */}
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={statuses}
              value={status}
              onChange={(event, newValue) => {
                setStatus(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Status"
                  placeholder="status"
                />
              )}
            />
          </Grid>

          {/* user */}
          <Grid item xs={12}>
            <Autocomplete
              multiple
              value={user}
              isOptionEqualToValue={isOptionEqualToName}
              onChange={(event, newValue) => {
                setUser(newValue);
              }}
              options={users}
              getOptionLabel={(option) => option.name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="User"
                  placeholder="user"
                />
              )}
            />
          </Grid>

          {/* date */}
          <Grid item xs={12}>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
              onClick={handleOpen}
            >
              <CalenderIcon />
              <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                {dates ? (
                  <>
                    {dayjs(dateRange[0].startDate).format('YYYY-MM-DD')} -{' '}
                    {dayjs(dateRange[0].endDate).format('YYYY-MM-DD')}
                  </>
                ) : (
                  'select date'
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* modal Date */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  maxWidth: '444px',
                  width: 1,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Box ref={dateRangeRef}>
                  <DateRange
                    ranges={dateRange}
                    onChange={(item) => {
                      setDateRange([item.selection]);
                    }}
                    rangeColors={[theme.palette.primary.main]}
                  />
                </Box>
              </Box>
            </Box>
            <Button
              variant="contained"
              fullWidth
              onClick={handleClose}
              sx={{ p: '12px 16px' }}
            >
              <Typography sx={{ fontSize: '0.875rem', lineHeight: 1 }}>
                Submit
              </Typography>
            </Button>
          </Box>
        </Modal>

        {/* button event */}
        <Box>
          <CustomButton
            fullWidth
            sx={{
              bgcolor: '#51B15C',
              color: '#ffffff',
              fontWeight: 600,
              fontSize: '0.875rem',
              borderRadius: '8px',
              '&:hover': {
                bgcolor: '#51B15C',
                color: '#ffffff',
              },
              px: 4,
              py: 1,
              mt: 2,
            }}
            onClick={handleSaveFilter}
          >
            Save
          </CustomButton>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
export default DialogFilterAdjustmentDashboard;
