import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  AddIcon,
  ArrowForwardIosIcon,
  CloseIcon,
  DeleteIcon,
  KeyboardArrowDownIcon,
} from '../../../../../../../assets/Icons';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlaceIcon from '@mui/icons-material/Place';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllMasterHubEdit,
  getAllMiniHubDataEdit,
  getAllSubHubDataEdit,
  getDataEditTransactionRequest,
  getDataHubTransactionEdit,
  getDataMiniHubTransactionEdit,
  getDataSubHubTransactionEdit,
} from '../../../../../../../store/transaction/request/editTransactionRequest';
import QtySelect from '../../../Purchase/Request/Add/component/QtySelect';
import ProductSelect from '../../../Purchase/Request/Add/component/ProductSelect';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import {
  editDetailTransactionRequest,
  getDestinationTransaction,
} from '../../../../../../../api/transaction';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import {
  changePageTransaction,
  setNotifTransaction,
} from '../../../../../../../store/Routing/transactionRouting';
import EditTransactionMobile from '../../TtansactionMobile/Request/Edit/EditTransactionMobile';

const EditTransactionRequest = () => {
  const matchTablet = useMediaQuery('(min-width:832px');
  const dispatch = useDispatch();
  const dataDetailToEdit = useSelector(getDataEditTransactionRequest);
  const idTransaction = dataDetailToEdit?.idOrders;
  const allDataHubs = useSelector(getDataHubTransactionEdit);
  const allDataSubHubs = useSelector(getDataSubHubTransactionEdit);
  const allDataMiniHubs = useSelector(getDataMiniHubTransactionEdit);
  const [mixData, setMixData] = useState([]);
  const [fromDataList, setFromDataList] = useState([]);
  const [isLoadingDestination, setIsLoadingDestination] = useState(false);
  const [isloadingSubmitTransaction, setIsloadingSubmitTransaction] =
    useState(false);

  const [detailDestinationHub, setDetailDestinationHub] = useState(false);
  const [dataDetailDestination, setDataDetailDestination] = useState();

  // ! mix data
  useEffect(() => {
    const mixs = [].concat(allDataHubs, allDataSubHubs, allDataMiniHubs);
    setMixData(mixs);
  }, [allDataHubs, allDataSubHubs, allDataMiniHubs]);

  // ! form add
  const [fromChoose, setFromChoose] = useState(null);
  const [toChoose, setToChoose] = useState({
    id: dataDetailToEdit?.lokasiTujuan,
    label: dataDetailToEdit?.namaTujuan,
    typeHub: dataDetailToEdit?.tabelTujuan === 'toko' && 'hub',
  });
  // items
  // const [tambahItem, setTambahItem] = useState([
  //   {
  //     namaProduk: '',
  //     idProduk: 0,
  //     jumlah: 0,
  //     unit: '',
  //   },
  // ]);
  const [tambahItem, setTambahItem] = useState(dataDetailToEdit?.details);

  const handleAddItemCardIngredient = () => {
    setTambahItem([
      ...tambahItem,
      { namaProduk: '', idProduk: 0, jumlah: 0, unit: '' },
    ]);
  };

  // ! validation
  const [isDisableTo, setIsDisableTo] = useState(false);
  const [isDisableProduct, setIsDisableProduct] = useState(false);
  const [isDisableQty, setIsDisableQty] = useState(false);
  const [isDisableUnit, setIsDisableUnit] = useState(false);
  const [isFieldTo, setIsFieldTo] = useState(true);
  const [isFieldFrom, setIsFieldFrom] = useState(false);
  const [isDeleteIngredientRow, setIsDeleteIngredientRow] = useState(false);
  const [allowNext, setAllowNext] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);

  useEffect(() => {
    dispatch(getAllMasterHubEdit({ pageSize: 1000 }));
    dispatch(getAllSubHubDataEdit({ pageSize: 1000 }));
    dispatch(getAllMiniHubDataEdit({ pageSize: 1000 }));
  }, []);

  useEffect(() => {
    if (
      toChoose !== null &&
      tambahItem[0].idProduk !== 0 &&
      tambahItem[0].namaProduk !== '' &&
      tambahItem[0].jumlah !== 0 &&
      tambahItem[0].unit !== ''
    ) {
      setAllowNext(true);
    } else {
      setAllowNext(false);
    }
  }, [toChoose, tambahItem]);

  useEffect(() => {
    if (
      toChoose !== null &&
      fromChoose !== null &&
      tambahItem[0].idProduk !== 0 &&
      tambahItem[0].namaProduk !== '' &&
      tambahItem[0].jumlah !== 0 &&
      tambahItem[0].unit !== ''
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [toChoose, tambahItem, fromChoose]);

  const handleChange = (event) => {
    setFromChoose(event.target.value);
  };

  const handleBack = () => {
    setIsFieldFrom(false);
    setIsFieldTo(true);
    setIsDisableTo(false);
    setIsDisableProduct(false);
    setIsDisableQty(false);
    setIsDisableUnit(false);
    setFromChoose(null);
  };

  const handleNext = () => {
    setIsLoadingDestination(true);
    const fetchDestination = async () => {
      let params = {};
      params.to = toChoose?.typeHub;
      params.id = toChoose?.id;
      params.products = tambahItem;
      try {
        const {
          data: { data },
        } = await getDestinationTransaction(params);
        setFromDataList(data);
        setIsFieldFrom(true);
        setIsFieldTo(false);
        setIsDisableTo(true);
        setIsDisableProduct(true);
        setIsDisableQty(true);
        setIsDisableUnit(true);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingDestination(false);
      }
    };
    fetchDestination();
  };

  const handleSubmit = () => {
    let datas = {};
    datas.pemberi = fromChoose?.hubType;
    datas.namaPemberi = fromChoose?.hubName;
    datas.lokasiPemberi = Number(fromChoose?.hubId);
    datas.products = tambahItem;
    datas.tujuan = toChoose?.typeHub;
    datas.namaTujuan = toChoose?.label;
    datas.lokasiTujuan = toChoose?.id;

    setIsloadingSubmitTransaction(true);

    const updateData = async () => {
      try {
        await editDetailTransactionRequest(idTransaction, datas);
        setIsloadingSubmitTransaction(false);
        dispatch(
          setNotifTransaction({
            key: 'editTransaction',
            text: 'transaction has been uptodate',
          })
        );
        dispatch(changePageTransaction('request'));
      } catch (err) {
        console.error(err);
      } finally {
        setIsloadingSubmitTransaction(false);
      }
    };

    updateData();
  };

  return (
    <>
     {!matchTablet ? (
      <EditTransactionMobile/>
    ) : (
      <Box>
      <Grid container columns={12} spacing={3} sx={{ marginBottom: '100px' }}>
        {/* head */}
        <Grid item xs={12}>
          <Box
            sx={{
              height: '120px',
              background: '#FAFAFA',
              padding: '20px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 4,
            }}
          >
            <Box
              sx={{
                padding: '16px',
                background: isFieldTo ? '#FFFFFF' : 'rgba(81, 177, 92, 0.1)',
                border: isFieldTo && '1px solid #51B15C',
                borderRadius: '8px',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                  select destination and items
                </Typography>
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    background: isFieldTo ? '#F5F5F5' : '#51B15C',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                    {isFieldTo ? '1' : <CheckIcon sx={{ color: '#FFFFFF' }} />}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <ArrowForwardIosIcon sx={{ height: '14px' }} />
            <Box
              sx={{
                padding: '16px',
                background: '#FFFFFF',
                border: isFieldFrom && '1px solid #51B15C',
                borderRadius: '8px',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                  select available hub
                </Typography>
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    background: '#F5F5F5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                    2
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* to */}
        <Grid item xs={12}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }}>
            To*
          </Typography>
          <Autocomplete
            disabled={isDisableTo}
            sx={{ width: '100%' }}
            options={mixData}
            value={toChoose}
            getOptionLabel={(option) => option.label}
            onChange={(_, newValue) => {
              setToChoose(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Location"
                sx={{
                  bgcolor: '#fafafa',
                  borderRadius: '5px',
                  fontSize: '14px',
                  color: '#9D9D9D',
                }}
              />
            )}
          />
        </Grid>

        {/* items */}
        <Grid item xs={12}>
          <CustomCard>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '20px',
                boxShadow: '0px 1px 5px #ccc',
              }}
            >
              <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                Item(s)
              </Typography>
              {isFieldTo ? (
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#51B15C',
                    gap: 1,
                    cursor: 'pointer',
                  }}
                  onClick={handleAddItemCardIngredient}
                >
                  <AddIcon />
                  Add more
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#51B15C',
                    gap: 1,
                  }}
                >
                  <AddIcon />
                  Add more
                </Typography>
              )}
            </Box>

            <Box>
              <Grid container columns={12} spacing={2} sx={{ p: '30px 20px' }}>
                {tambahItem.map((item, index) => (
                  <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Grid container columns={12} gap={3}>
                      {/* product */}
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            mb: 2,
                          }}
                        >
                          Product*
                        </Typography>
                        <ProductSelect
                          isDisableProduct={isDisableProduct}
                          firstValueId={item?.product?.idProduk || 0}
                          firstValue={item?.product?.namaProduk || ''}
                          isReset={isDeleteIngredientRow}
                          setIsReset={setIsDeleteIngredientRow}
                          handleValue={(name, id) => {
                            const memo = tambahItem.map((item, idx) => {
                              if (index === idx) {
                                return {
                                  ...item,
                                  idProduk: id,
                                  namaProduk: name,
                                };
                              } else {
                                return item;
                              }
                            });
                            setTambahItem(memo);
                          }}
                        />
                      </Grid>
                      {/* jumlah */}
                      <Grid item xs={5}>
                        <Box sx={{ display: 'flex' }}>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: 500,
                              mb: 2,
                            }}
                          >
                            Qty*
                          </Typography>
                        </Box>
                        <QtySelect
                          isDisableQty={isDisableQty}
                          isDisableUnit={isDisableUnit}
                          firstValueQty={item.jumlah}
                          firstValueUnit={item.unit}
                          isReset={isDeleteIngredientRow}
                          setIsReset={setIsDeleteIngredientRow}
                          handleValue={(jumlah, unit) => {
                            const memo = tambahItem.map((item, idx) => {
                              if (index === idx) {
                                return {
                                  ...item,
                                  jumlah: jumlah,
                                  unit: unit,
                                };
                              } else {
                                return item;
                              }
                            });
                            setTambahItem(memo);
                          }}
                        />
                      </Grid>

                      {/* hapus item */}
                      {isFieldTo ? (
                        <Grid
                          item
                          xs="auto"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 4,
                            color: 'red',
                          }}
                        >
                          {tambahItem.length === 1 ? (
                            <></>
                          ) : (
                            <DeleteIcon
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setTambahItem(
                                  tambahItem.filter((_, idx) => idx !== index)
                                );
                              }}
                            />
                          )}
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </CustomCard>
        </Grid>

        {/* form */}

        {isFieldFrom && (
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }}>
              From*
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fromChoose}
                onChange={handleChange}
              >
                {fromDataList?.map((item, index) => {
                  return (
                    <MenuItem value={item}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          gap: 2,
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            width: '54px',
                            height: '54px',
                            borderRadius: '5px',
                            background: '#9D9D9E',
                          }}
                        >
                          <img
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              '/' +
                              item.image
                            }
                            style={{
                              width: '54px',
                              height: '54px',
                              borderRadius: '5px',
                            }}
                            alt="pic"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: 600 }}
                          >
                            {item?.hubName}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexFlow: 'column',
                                gap: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <PlaceIcon
                                  sx={{
                                    color: '#51B15C',
                                    width: '15px',
                                    height: '15px',
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    color: '#000000',
                                  }}
                                >
                                  {Number(
                                    (
                                      item?.distance.split(' ', 1) * 1000
                                    ).toFixed(2)
                                  )}{' '}
                                  m
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color: '#51B15C',
                                    width: '15px',
                                    height: '15px',
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    color: '#000000',
                                  }}
                                >
                                  {item?.produk?.jumlah?.jumlah} products
                                  available
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              onClick={(e) => {
                                e.stopPropagation();
                                setDetailDestinationHub(true);
                                setDataDetailDestination(item?.produk);
                              }}
                            >
                              <KeyboardArrowDownIcon />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      {/* Button event */}
      {isFieldFrom === false && isFieldTo === true ? (
        <Box
          sx={{
            width: '100%',
            bgcolor: '#FFFFFF',
            position: 'fixed',
            bottom: '0px',
            right: '0px',
            display: 'flex',
            justifyContent: 'end',
            gap: 3,
            padding: '18px 32px 18px 240px',
            alignItems: 'center',
          }}
        >
          <CustomButton
            variant="contained"
            onClick={handleNext}
            disabled={!allowNext}
            sx={{ width: '190px', height: '48px' }}
          >
            <Typography
              sx={{ color: '#FFFFFF', fontSize: '16px', fontWeight: 500 }}
            >
              {isLoadingDestination ? (
                <CircularProgress sx={{ color: '#FFFFFF', width: '15px' }} />
              ) : (
                'Next'
              )}
            </Typography>
          </CustomButton>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            bgcolor: '#FFFFFF',
            position: 'fixed',
            bottom: '0px',
            right: '0px',
            display: 'flex',
            justifyContent: 'end',
            gap: 3,
            padding: '18px 32px 18px 240px',
            alignItems: 'center',
          }}
        >
          <CustomButton
            variant="outlined"
            onClick={handleBack}
            sx={{ width: '190px', height: '48px' }}
          >
            <Typography
              sx={{ color: '#51B15C', fontSize: '16px', fontWeight: 500 }}
            >
              Back
            </Typography>
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={handleSubmit}
            disabled={!allowSubmit}
            sx={{ width: '190px', height: '48px' }}
          >
            {isloadingSubmitTransaction ? (
              <CircularProgress sx={{ color: '#FFFFFF', width: '15px' }} />
            ) : (
              <Typography
                sx={{ color: '#FFFFFF', fontSize: '16px', fontWeight: 500 }}
              >
                Submit
              </Typography>
            )}
          </CustomButton>
        </Box>
      )}

      {/* dialog detail destination hub */}
      <Dialog
        fullWidth
        open={detailDestinationHub}
        onClose={() => {
          setDetailDestinationHub(false);
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}>
            Select Hub
          </Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setDetailDestinationHub(false)}
          />
        </DialogTitle>
        <DialogContent dividers>
          {dataDetailDestination?.map((item, idx) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  gap: 2,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    width: '54px',
                    height: '54px',
                    borderRadius: '5px',
                    background: '#9D9D9E',
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_API_IMAGE_BASE_URL +
                      '/' +
                      item.gambar
                    }
                    style={{
                      width: '54px',
                      height: '54px',
                      borderRadius: '5px',
                    }}
                    alt="pic"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {item?.namaProduk}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#51B15C',
                    }}
                  >
                    {item?.jumlah} {item?.unit?.name}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </DialogContent>
      </Dialog>
    </Box>
    )}
    </>
    
  );
};
export default EditTransactionRequest;
