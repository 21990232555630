import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MobileStepper,
  Typography,
  useTheme,
} from '@mui/material';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import { changePageMasterHub } from '../../../../../../../../store/Routing/hubs';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDataDetailHubs,
  getAllMasterHubDetail,
} from '../../../../../../../../store/hub/masterHub/masterHubDetail';
import { useEffect } from 'react';
import {
  ChevronRightIcon,
  CloseIcon,
  DeleteIcon,
  KeyboardArrowDownIcon,
  RackIcon,
} from '../../../../../../../../assets/Icons';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useState } from 'react';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import { Map, Marker, ZoomControl } from 'pigeon-maps';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CustomDialog from '../../../../../../../../components/shared/CustomDialog';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import CustomAntSwitch from '../../../../../../../../components/shared/CustomAntSwitch';
import {
  deleteMasterHub,
  editMasterHubStatus,
} from '../../../../../../../../api/hubs';
import RackMapPreviewMobile from './RackMapPreview';
import {
  fetchDetailsDataMasterHub,
  fetchMasterHubRacksPreview,
  getRacksPositionPreviewMasterHub,
  getRacksPreviewMasterHub,
} from '../../../../../../../../store/hub/masterHub/masterHub';
import {
  changeIsEdit,
  getIsMasterHubEdit,
  goToMasterHubEdit,
} from '../../../../../../../../store/hub/masterHub/masterHubEdit';
import EditMasterHubMobile from '../Edit/EditMasterHubMobile';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const DetailMasterHub = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isPreviewAddressMap, setIsPreviewAddressMap] = useState(false);
  const [isPreviewRackMap, setIsPreviewRackMap] = useState(false);
  const isEdit = useSelector(getIsMasterHubEdit);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const rackSelected = useSelector(getRacksPositionPreviewMasterHub);
  const racksData = useSelector(getRacksPreviewMasterHub);

  const [addressMapZoom, setAddressMapZoom] = useState(17);
  const [addressMapCenter, setAddressMapCenter] = useState([
    3.5465944896879704, 98.68976198831375,
  ]);
  const [statusHub, setStatusHub] = useState(false);

  const { idHubs, datas, isLoading } = useSelector(getAllMasterHubDetail);
  const [image, setImage] = useState([]);
  const maxSteps = image?.length;
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    const img = datas?.gambar?.split(',');
    setImage(img);
  }, [datas]);

  useEffect(() => {
    dispatch(fetchDataDetailHubs(idHubs));
  }, [idHubs]);

  useEffect(() => {
    dispatch(fetchDetailsDataMasterHub(idHubs));
    dispatch(fetchMasterHubRacksPreview(idHubs));
  }, [dispatch, idHubs]);

  useEffect(() => {
    if (!isLoading) {
      if (datas.status === 'aktif') {
        return setStatusHub(true);
      } else {
        return setStatusHub(false);
      }
    }
  }, [isLoading]);

  // ! ---------------------- Handler -------------------
  const handleEditStatus = () => {
    if (statusHub) {
      editMasterHubStatus(datas.idToko, 'pending');
      setStatusHub(false);
    } else {
      editMasterHubStatus(datas.idToko, 'aktif');
      setStatusHub(true);
    }
  };

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    try {
      await deleteMasterHub(idHubs);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleToEdit = () => {
    dispatch(
      goToMasterHubEdit({
        idMasterHub: datas?.idToko,
        name: datas?.namaToko,
        col: datas?.mapColumn,
        row: datas?.mapRow,
        rackDetails: racksData,
        rackSelected: rackSelected,
        pic: datas?.pic,
        picNumber: datas?.nohp,
        status: statusHub,
        entrance: datas?.entrancePosition
          ? datas?.entrancePosition.split(',')
          : [],
        subsidiary: datas?.subsidiary,
        subsidiaryId: datas?.subsidiaryId,
        photo: datas?.gambar ? datas?.gambar.split(',') : [],
        video: datas?.videos ? datas?.videos.split(',') : [],
        address: datas?.alamat,
        coordinate: [Number(datas?.mapCor?.lat), Number(datas?.mapCor?.long)],
        typeMap: datas?.mapCor.typeInput,
        citydistrict: datas?.mapCor.cityDistrict,
      })
    );
    dispatch(changeIsEdit);
  };

  return isEdit ? (
    <EditMasterHubMobile />
  ) : (
    <Box sx={{ position: 'relative' }}>
      <Grid container columns={12} spacing={2}>
        {/* back button */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 2,
              mb: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <BackButtonHub
                onClick={() => dispatch(changePageMasterHub(''))}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 600, color: '#000' }}
                >
                  {datas?.namaToko || '...'} Details
                </Typography>
                <Typography
                  sx={{ fontSize: '10px', fontWeight: 600, color: '#9E9D9D' }}
                >
                  {datas?.subsidiary?.name || '_'}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography
                sx={{ fontSize: '12px', fontWeight: 600, color: '#51B15C' }}
              >
                Hub Racks
              </Typography>
              <ChevronRightIcon sx={{ color: '#51B15C' }} />
            </Box>
          </Box>
        </Grid>

        {isLoading ? (
          <Grid item xs={12}>
            <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {image?.map((step, index) => (
                  <Box key={index}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="img"
                        sx={{
                          height: 156,
                          display: 'block',
                          overflow: 'hidden',
                          width: '100%',
                        }}
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + step
                        }
                        alt={step}
                      />
                    ) : null}
                  </Box>
                ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              />
            </Grid>

            {/* name */}
            <Grid item xs={12}>
              <Box
                sx={{
                  background: '#FAFAFA',
                  borderRadius: '8px 8px 0px 0px',
                  padding: '16px 12px 14px 14px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    marginBottom: '8px',
                    color: '#9E9E9E',
                  }}
                >
                  Name
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
                >
                  {datas?.namaToko || '_'}
                </Typography>
              </Box>
            </Grid>

            {/* pic */}
            <Grid item xs={12}>
              <Box
                sx={{
                  background: '#FAFAFA',
                  borderRadius: '8px 8px 0px 0px',
                  padding: '16px 12px 14px 14px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    marginBottom: '8px',
                    color: '#9E9E9E',
                  }}
                >
                  PIC
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
                >
                  {datas?.pic?.namaLengkap || '_'}
                </Typography>
              </Box>
            </Grid>

            {/* phone number */}
            <Grid item xs={12}>
              <Box
                sx={{
                  background: '#FAFAFA',
                  borderRadius: '8px 8px 0px 0px',
                  padding: '16px 12px 14px 14px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    marginBottom: '8px',
                    color: '#9E9E9E',
                  }}
                >
                  Phone Number
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
                >
                  {datas?.nohp}
                </Typography>
              </Box>
            </Grid>

            {/* address */}
            <Grid item xs={12}>
              <CustomCard
                sx={{ p: '8px 16px', cursor: 'pointer' }}
                onClick={() => setIsPreviewAddressMap(true)}
              >
                <Box
                  sx={{
                    display: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        width: '40px',
                        height: '40px',
                        bgcolor: 'gray',
                        borderRadius: '8px',
                        position: 'relative',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          bgcolor: 'white',
                          width: '100%',
                          height: '100%',
                          zIndex: '+2',
                          opacity: 0.5,
                        }}
                      ></Box>
                      <Box
                        sx={{
                          maxHeight: '40px',
                          overflow: 'hidden',
                          borderRadius: '8px',
                        }}
                      >
                        {datas?.mapCor && (
                          <Map
                            defaultCenter={[
                              Number(datas?.mapCor?.lat),
                              Number(datas?.mapCor?.long),
                            ]}
                            height={130}
                            defaultZoom={17}
                            width={66}
                            style={{
                              borderRadius: '8px',
                            }}
                          ></Map>
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontWeight: '500', mb: '4px', ml: '8px' }}
                      >
                        Address
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.4,
                        }}
                      >
                        <LocationOnIcon color="primary" />
                        <Typography>
                          {datas?.alamat ? datas?.alamat : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>

            {/* //* Rack  */}
            <Grid item xs={12}>
              <CustomCard
                sx={{ p: '6px 16px', cursor: 'pointer' }}
                onClick={() => setIsPreviewRackMap(!isPreviewRackMap)}
              >
                <Box
                  sx={{
                    display: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '52px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <RackIcon />
                    <Box>
                      <Typography sx={{ fontWeight: '500', mb: '4px' }}>
                        Racks Map
                      </Typography>
                      <Typography sx={{ color: '#9E9D9D', fontSize: '12px' }}>
                        Click to see map
                      </Typography>
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'space-between',
                  mb: '12px',
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
                >
                  Publish
                </Typography>
                <CustomAntSwitch
                  checked={statusHub}
                  onChange={(e) => handleEditStatus(e)}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>

      {/* button Event */}

      {!isLoading && (
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            padding: '16px 0px',
          }}
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <CustomButton
              variant="contained"
              color="error"
              sx={{ width: '100%', height: '36px' }}
              onClick={() => setIsDelete(true)}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '12px',
                  fontWeight: '500',
                }}
              >
                Delete
              </Typography>
            </CustomButton>
            <CustomButton
              variant="contained"
              sx={{ width: '100%', height: '36px' }}
              onClick={handleToEdit}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '12px',
                  fontWeight: '500',
                }}
              >
                Edit
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      )}
      {/*  Dialog Addres Map */}
      <Dialog
        maxWidth="sm"
        open={isPreviewAddressMap}
        onClose={() => setIsPreviewAddressMap(!isPreviewAddressMap)}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Address</Typography>
            <CloseIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsPreviewAddressMap(!isPreviewAddressMap)}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Map
            height={256}
            width={256}
            center={addressMapCenter}
            zoom={addressMapZoom}
            onBoundsChanged={({ center, zoom }) => {
              setAddressMapCenter(center);
              setAddressMapZoom(zoom);
            }}
          >
            <ZoomControl />
            <Marker
              anchor={[Number(datas?.mapCor?.lat), Number(datas?.mapCor?.long)]}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LocationOnRoundedIcon
                  color="error"
                  sx={{
                    fontSize: `${30 - (20 - Math.floor(addressMapZoom))}px`,
                  }}
                />
                {addressMapZoom >= 17 && (
                  <Typography
                    color="error"
                    sx={{ fontSize: '12px', textAlign: 'center' }}
                  >
                    {datas.namaToko}
                  </Typography>
                )}
              </Box>
            </Marker>
          </Map>
          <Typography sx={{ mt: '12px' }}>{datas?.alamat}</Typography>
        </DialogContent>
      </Dialog>

      {/*  Dialog Rack Map */}
      <CustomDialog
        fullWidth
        isOpen={isPreviewRackMap}
        setIsOpen={setIsPreviewRackMap}
      >
        <RackMapPreviewMobile
          col={datas?.mapColumn}
          row={datas?.mapRow}
          entrance={datas?.entrancePosition}
          setIsOpen={setIsPreviewRackMap}
        />
      </CustomDialog>

      {/* Dialog Delete */}
      <Dialog fullWidth open={isDelete} onClose={() => setIsDelete(!isDelete)}>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: '44px',
                height: '40px',
                background: 'rgba(243, 58, 58, 0.1)',
                borderRadius: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DeleteIcon sx={{ color: '#F33A3A' }} />
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}
            >
              Delete Hub
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography
              sx={{ fontWeight: 400, color: '#000', fontSize: '14px' }}
            >
              are you sure you want to delete
            </Typography>
            <Typography
              sx={{ fontWeight: 600, color: '#000', fontSize: '14px' }}
            >
              " {datas?.idToko} "
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mt: 5,
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{ color: '#9E9D9D', fontSize: '14px', fontWeight: 600 }}
              onClick={() => setIsDelete(!isDelete)}
            >
              Cancel
            </Typography>

            <CustomButton
              variant="contained"
              color="error"
              sx={{ width: '100px', height: '36px', borderRadius: '8px' }}
              onClick={handleDelete}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                {isLoadingDelete ? (
                  <CircularProgress size={20} sx={{ color: '#FFF' }} />
                ) : (
                  'Delete'
                )}
              </Typography>
            </CustomButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default DetailMasterHub;
