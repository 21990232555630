import { createSlice } from '@reduxjs/toolkit';
import {
  getPlacesByCoordinate,
  getPlacesByName,
} from '../../../api/AddressMap';
import {
  addMasterHubRacksMultiple,
  deleteMasterHubRacksMultiple,
  deleteRackMaster,
  editMasterHub,
  editMasterHubRacksMultiple,
} from '../../../api/hubs';
import { deleteFile, uploadImage, uploadVideo } from '../../../api/storage';

export const masterHubEditState = createSlice({
  name: 'masterHubEdit',
  initialState: {
    isEdit: false,
    subsidiary: null,
    subsidiaryId: 0,
    idMasterHub: 0,
    name: '',
    // * --- Photo ---
    isLoadingMasterHub: false,
    photo: [],
    isMaxSizePhoto: false,
    photoPreview: '',
    isPhotoPreview: false,
    video: [],
    isLoadingVideo: false,
    isMaxSizeVideo: false,
    videoPreview: '',
    isVideoPreview: false,
    // * --- PIC --
    picChoose: null,
    picSearch: '',
    picSearchDebounce: '',
    picNumber: '',
    isCreateMap: false,
    createMapPage: 'preview', //? -- grid, racks, preview --
    status: false,
    // *--- RACK
    colBefore: 0,
    rowBefore: 0,
    col: 5,
    row: 5,
    cell: [],
    isEntrance: true,
    entrance: [],
    cellSelectedAll: [],
    cellSelected: [],
    cellSelectedDetails: [],
    cellSelectedEditTemporary: {},
    isCellSelectedEdit: false,
    cellSelectedMoment: [],
    isDoneEdit: true,
    cellSelectedNew: [],
    cellSelectedDetailsNew: [],
    cellSelectedNewTemporary: {},
    rackDeletes: [],
    rackSelectedAddMoment: [],
    // *--Address
    mapInputType: 'manual',
    coordinate: [],
    center: [3.546728899138594, 98.69014558305145],
    placesSearchByName: [],
    placesSearchByNameLoading: false,
    placeGetByName: null,
    placeGetByCoordinate: null,
    placeGetByCoordinateLoading: false,
    address: '',
    adressFix: '',
    coordinateFix: [],
    // *-- LAste
    isLoadingUpdate: false,
    errorEmpty: '',
  },
  reducers: {
    goToMasterHubEdit: (state, action) => {
      const {
        idMasterHub,
        subsidiary,
        subsidiaryId,
        name,
        col,
        row,
        rackSelected,
        rackDetails,
        pic,
        picNumber,
        status,
        entrance,
        photo,
        video,
        address,
        coordinate,
        typeMap,
        citydistrict,
      } = action.payload;
      state.subsidiary = subsidiary;
      state.subsidiaryId = subsidiaryId;
      state.idMasterHub = idMasterHub;
      state.name = name;
      state.photo = photo;
      state.video = video;
      state.picChoose = pic;
      state.picNumber = picNumber;
      state.status = status;
      state.entrance = entrance;

      state.coordinate = coordinate;
      state.coordinateFix = coordinate;
      state.center = coordinate;
      state.mapInputType = typeMap;
      const manualInput = {
        search: '',
        state: '',
        city: '',
        postcode: '',
        district: '',
        lon: coordinate[1],
        lat: coordinate[0],
        formatted: citydistrict,
      };
      const mapInput = {
        state: '',
        city: '',
        postcode: '',
        district: '',
        lon: coordinate[1],
        lat: coordinate[0],
        formatted: citydistrict,
      };
      if (typeMap === 'manual') {
        state.placeGetByName = manualInput;
      } else {
        state.placeGetByCoordinate = mapInput;
      }
      state.address = address;

      let newCell = [...Array(row)].map((_, idxr) => {
        let v = [...Array(col)].map((_, idxc) => {
          return `r${idxr + 1}c${idxc + 1}`;
        });
        return v;
      });
      state.cell = newCell;
      state.colBefore = col;
      state.rowBefore = row;
      state.col = col;
      state.row = row;
      state.cellSelected = rackSelected;
      state.cellSelectedDetails = rackDetails;
      state.isEdit = true;
    },
    changeIsEdit: (state) => {
      state.isEdit = !state.isEdit;
    },
    changeSubsidiaryId: (state, action) => {
      state.subsidiaryId = action.payload;
    },
    changeName: (state, action) => {
      state.name = action.payload;
    },
    inputPhoto: (state, action) => {
      state.photo = [...state.photo, action.payload];
    },
    changeIsLoadingMasterHub: (state, action) => {
      state.isLoadingMasterHub = action.payload;
    },
    deletePhoto: (state, action) => {
      state.photo = state.photo.filter((item) => item !== action.payload);
    },
    changeIsMaxSizePhoto: (state, action) => {
      state.isMaxSizePhoto = action.payload;
    },
    changePhotoPreview: (state, action) => {
      state.photoPreview = action.payload;
    },
    clickIsPreviewPhoto: (state) => {
      state.isPhotoPreview = !state.isPhotoPreview;
    },
    inputVideo: (state, action) => {
      state.video = [...state.video, action.payload];
    },
    changeIsLoadingVideo: (state, action) => {
      state.isLoadingVideo = action.payload;
    },
    deleteVideo: (state, action) => {
      state.video = state.video.filter((item) => item !== action.payload);
    },
    changeIsMaxSizeVideo: (state, action) => {
      state.isMaxSizeVideo = action.payload;
    },
    changeVideoPreview: (state, action) => {
      state.videoPreview = action.payload;
    },
    clickIsPreviewVideo: (state) => {
      state.isVideoPreview = !state.isVideoPreview;
    },
    // *---------PIC ------------
    changePicChoose: (state, action) => {
      state.picChoose = action.payload;
    },
    changePicSearch: (state, action) => {
      state.picSearch = action.payload;
    },
    changePicSearchDebounce: (state, action) => {
      state.picSearchDebounce = action.payload;
    },
    changePicNumber: (state, action) => {
      state.picNumber = action.payload;
    },
    changeStatus: (state) => {
      state.status = !state.status;
    },
    // * --------- Rack Map ----------------
    changeIsCreateMap: (state) => {
      state.isCreateMap = !state.isCreateMap;
    },
    changeCreateMapPage: (state, action) => {
      state.createMapPage = action.payload;
    },
    changeIsEntrance: (state) => {
      if (state.isEntrance) {
        state.entrance = [];
      }
      state.isEntrance = !state.isEntrance;
    },
    addCellSelectedMoment: (state, action) => {
      if (state.cellSelectedMoment.includes(action.payload)) {
        state.cellSelectedMoment = state.cellSelectedMoment.filter(
          (v) => v !== action.payload
        );
      } else {
        state.cellSelectedMoment = [
          ...state.cellSelectedMoment,
          action.payload,
        ];
      }
    },
    inputRackTemporary: (state, action) => {
      const { columnPosition } = action.payload;
      let cell = columnPosition.split(',');
      let cellAvoid = state.cellSelected.filter(
        (val) => val.filter((x) => cell.includes(x)).length === 0
      );
      state.cellSelectedEditTemporary = action.payload;
      state.cellSelected = cellAvoid;
      state.cellSelectedDetails = state.cellSelectedDetails.filter(
        (item) => item.columnPosition !== columnPosition
      );
      state.isDoneEdit = false;
    },
    cancelRackTemporary: (state) => {
      let CellBack = state.cellSelectedEditTemporary;
      state.cellSelectedDetails = [...state.cellSelectedDetails, CellBack];
      state.cellSelected = [
        ...state.cellSelected,
        CellBack.columnPosition.split(','),
      ];
    },
    saveRackTemporary: (state, action) => {
      const { name, description, columnPosition } = action.payload;
      const rackTempo = state.cellSelectedEditTemporary;
      const Rack = {
        id: rackTempo.id,
        name: name,
        column: rackTempo.column,
        row: rackTempo.row,
        columnPosition: columnPosition,
        description: description,
      };
      const CellTempo = columnPosition.split(',');

      state.cellSelectedDetails = [...state.cellSelectedDetails, Rack];
      state.cellSelected = [...state.cellSelected, CellTempo];
    },
    addNewRack: (state, action) => {
      const { columnPosition, details, id } = action.payload;
      const detail = {
        id_toko: id,
        subHubId: null,
        miniHubId: null,
        name: details.name,
        images: '',
        videos: '',
        column: details.column,
        row: details.row,
        columnPosition: columnPosition.join(','),
        description: details.description,
        width: 0.0,
        height: 0.0,
        length: 0.0,
        weight: 0,
      };

      state.cellSelectedNew = [...state.cellSelectedNew, columnPosition];
      state.cellSelectedDetailsNew = [...state.cellSelectedDetailsNew, detail];
    },
    inputNewRackTemporary: (state, action) => {
      const { columnPosition } = action.payload;
      let cell = columnPosition.split(',');
      let cellAvoid = state.cellSelectedNew.filter(
        (val) => val.filter((x) => cell.includes(x)).length === 0
      );
      state.cellSelectedNewTemporary = action.payload;
      state.cellSelectedNew = cellAvoid;
      state.cellSelectedDetailsNew = state.cellSelectedDetailsNew.filter(
        (item) => item.columnPosition !== columnPosition
      );
    },
    cancelNewRackTemporary: (state) => {
      let CellBack = state.cellSelectedNewTemporary;
      state.cellSelectedDetailsNew = [
        ...state.cellSelectedDetailsNew,
        CellBack,
      ];
      state.cellSelectedNew = [
        ...state.cellSelectedNew,
        CellBack.columnPosition.split(','),
      ];
    },
    saveNewRackTemporary: (state, action) => {
      const { id, columnPosition, data } = action.payload;
      const { name, description, row, column } = data;
      const Rack = {
        id_toko: id,
        subHubId: null,
        miniHubId: null,
        name: name,
        images: '',
        videos: '',
        column: column,
        row: row,
        columnPosition: columnPosition.join(','),
        description: description,
        width: 0.0,
        height: 0.0,
        length: 0.0,
        weight: 0,
      };
      // const CellTempo = columnPosition.split(',');
      state.cellSelectedDetailsNew = [...state.cellSelectedDetailsNew, Rack];
      state.cellSelectedNew = [...state.cellSelectedNew, columnPosition];
    },
    deleteCellNewFix: (state, action) => {
      state.cellSelectedNew = state.cellSelectedNew.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedDetailsNew = state.cellSelectedDetailsNew.filter(
        (_, idx) => idx !== action.payload
      );
    },
    deleteCellFix: (state, action) => {
      const { index, id } = action.payload;
      state.cellSelected = state.cellSelected.filter((_, idx) => idx !== index);
      state.cellSelectedDetails = state.cellSelectedDetails.filter(
        (_, idx) => idx !== index
      );
      state.rackDeletes = [...state.rackDeletes, id];
    },
    inputEntrance: (state, action) => {
      let value = action.payload;
      if (state.entrance.includes(value)) {
        state.entrance = state.entrance.filter((item) => item != value);
      } else {
        state.entrance = [...state.entrance, value];
      }
    },

    changeRackSelectedAddMoment: (state, action) => {
      state.rackSelectedAddMoment = action.payload;
    },
    // !------------- Add Col or Row Map -----------------
    setUpCell: (state) => {
      let nilai = [...Array(state.row)].map((_, idxr) => {
        let v = [...Array(state.col)].map((_, idxc) => {
          return `r${idxr + 1}c${idxc + 1}`;
        });
        return v;
      });
      state.cell = nilai;
    },
    changeColCreateMap: (state, action) => {
      if (action.payload <= 50 && action.payload >= state.colBefore) {
        state.col = action.payload;
      }
    },
    changeRowCreateMap: (state, action) => {
      if (action.payload <= 50 && action.payload >= state.rowBefore) {
        state.row = action.payload;
      }
    },
    addColCreateMapRight: (state) => {
      if (state.col < 52 && state.col > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row)].map((_, idxr) => {
          let v = [...Array(state.col + 1)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });

        state.col = state.col + 1;
        state.cell = nilai;
      }
    },
    reduceColCreateMapRight: (state) => {
      if (state.col <= 52 && state.col > 1) {
        let limit = `c${state.col - 1}`;
        let allBefore = state.cellSelected.flat();
        let allAfter = state.cellSelectedNew.flat();
        let all = [...allBefore, ...allAfter];
        if (all.filter((item) => item.includes(limit)).length === 0) {
          state.entrance = [];
          let nilai = [...Array(state.row)].map((_, idxr) => {
            let v = [...Array(state.col - 1)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          });

          state.col = state.col - 1;
          state.cell = nilai;
        }
      }
    },
    addColCreateMapLeft: (state) => {
      if (state.col < 52 && state.col > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row)].map((_, idxr) => {
          let v = [...Array(state.col + 1)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });
        state.col = state.col + 1;
        state.cell = nilai;
        let dataCell = state.cellSelected.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split('r')[1].split('c');
            let childValue = `r${Number(child[0])}c${Number(child[1]) + 1}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetails = state.cellSelectedDetails.map((item) => {
          let position = item.columnPosition.split(',');
          let child = position.map((v) => {
            let cols = v.split('r')[1].split('c');
            let colsValue = `r${Number(cols[0])}c${Number(cols[1]) + 1}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child.join(','),
          };
        });
        // * new
        let dataCellNew = state.cellSelectedNew.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split('r')[1].split('c');
            let childValue = `r${Number(child[0])}c${Number(child[1]) + 1}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetailsNew = state.cellSelectedDetailsNew.map((item) => {
          let position = item.columnPosition.split(',');
          let child = position.map((v) => {
            let cols = v.split('r')[1].split('c');
            let colsValue = `r${Number(cols[0])}c${Number(cols[1]) + 1}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child.join(','),
          };
        });
        // let dataCellMoment = state.cellSelectedMoment.map((item) => {
        //   let child = item.split('r')[1].split('c');
        //   let childValue = `r${Number(child[0])}c${Number(child[1]) + 1}`;
        //   return childValue;
        // });
        // state.cellSelectedMoment = dataCellMoment;
        state.cellSelected = dataCell;
        state.cellSelectedDetails = dataCellDetails;

        state.cellSelectedNew = dataCellNew;
        state.cellSelectedDetailsNew = dataCellDetailsNew;
      }
    },
    reduceColCreateMapLeft: (state) => {
      if (state.col <= 52 && state.col > 1) {
        let allBefore = state.cellSelected.flat();
        let allAfter = state.cellSelectedNew.flat();
        let all = [...allBefore, ...allAfter];
        if (
          all.filter((item) => item.split('r')[1].split('c')[1] === '2')
            .length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row)].map((_, idxr) => {
            let v = [...Array(state.col - 1)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          });
          state.col = state.col - 1;
          state.cell = nilai;
          let dataCell = state.cellSelected.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split('r')[1].split('c');
              let childValue = `r${Number(child[0])}c${Number(child[1]) - 1}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetails = state.cellSelectedDetails.map((item) => {
            let position = item.columnPosition.split(',');
            let child = position.map((v) => {
              let cols = v.split('r')[1].split('c');
              let colsValue = `r${Number(cols[0])}c${Number(cols[1]) - 1}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child.join(','),
            };
          });
          let dataCellNew = state.cellSelectedNew.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split('r')[1].split('c');
              let childValue = `r${Number(child[0])}c${Number(child[1]) - 1}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetailsNew = state.cellSelectedDetailsNew.map((item) => {
            let position = item.columnPosition.split(',');
            let child = position.map((v) => {
              let cols = v.split('r')[1].split('c');
              let colsValue = `r${Number(cols[0])}c${Number(cols[1]) - 1}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child.join(','),
            };
          });
          // let dataCellMoment = state.cellSelectedMoment.map((item) => {
          //   let child = item.split('r')[1].split('c');
          //   let childValue = `r${Number(child[0])}c${Number(child[1]) - 1}`;
          //   return childValue;
          // });
          // state.cellSelectedMoment = dataCellMoment;
          state.cellSelected = dataCell;
          state.cellSelectedDetails = dataCellDetails;

          state.cellSelectedNew = dataCellNew;
          state.cellSelectedDetailsNew = dataCellDetailsNew;
        }
      }
    },
    addRowCreateMapTop: (state) => {
      if (state.row < 52 && state.row > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 1)].map((_, idxr) => {
          let v = [...Array(state.col)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });
        state.row = state.row + 1;
        state.cell = nilai;
        let dataCell = state.cellSelected.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split('r')[1].split('c');
            let childValue = `r${Number(child[0]) + 1}c${Number(child[1])}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetails = state.cellSelectedDetails.map((item) => {
          let position = item.columnPosition.split(',');
          let child = position.map((v) => {
            let cols = v.split('r')[1].split('c');
            let colsValue = `r${Number(cols[0]) + 1}c${Number(cols[1])}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child.join(','),
          };
        });
        let dataCellNew = state.cellSelectedNew.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split('r')[1].split('c');
            let childValue = `r${Number(child[0]) + 1}c${Number(child[1])}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetailsNew = state.cellSelectedDetailsNew.map((item) => {
          let position = item.columnPosition.split(',');
          let child = position.map((v) => {
            let cols = v.split('r')[1].split('c');
            let colsValue = `r${Number(cols[0]) + 1}c${Number(cols[1])}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child.join(','),
          };
        });

        state.cellSelected = dataCell;
        state.cellSelectedDetails = dataCellDetails;

        state.cellSelectedNew = dataCellNew;
        state.cellSelectedDetailsNew = dataCellDetailsNew;
      }
    },
    reduceRowCreateMapTop: (state) => {
      if (state.row <= 52 && state.row > 1) {
        let allBefore = state.cellSelected.flat();
        let allAfter = state.cellSelectedNew.flat();
        let all = [...allBefore, ...allAfter];
        if (
          all.filter((item) => item.split('r')[1].split('c')[0] === '2')
            .length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row - 1)].map((_, idxr) => {
            let v = [...Array(state.col)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          });
          state.row = state.row - 1;
          state.cell = nilai;

          let dataCell = state.cellSelected.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split('r')[1].split('c');
              let childValue = `r${Number(child[0]) - 1}c${Number(child[1])}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetails = state.cellSelectedDetails.map((item) => {
            let positon = item.columnPosition.split(',');
            let child = positon.map((v) => {
              let cols = v.split('r')[1].split('c');
              let colsValue = `r${Number(cols[0]) - 1}c${Number(cols[1])}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child.join(','),
            };
          });

          let dataCellNew = state.cellSelectedNew.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split('r')[1].split('c');
              let childValue = `r${Number(child[0]) - 1}c${Number(child[1])}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetailsNew = state.cellSelectedDetailsNew.map((item) => {
            let positon = item.columnPosition.split(',');
            let child = positon.map((v) => {
              let cols = v.split('r')[1].split('c');
              let colsValue = `r${Number(cols[0]) - 1}c${Number(cols[1])}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child.join(','),
            };
          });

          state.cellSelected = dataCell;
          state.cellSelectedDetails = dataCellDetails;

          state.cellSelectedNew = dataCellNew;
          state.cellSelectedDetailsNew = dataCellDetailsNew;
        }
      }
    },
    addRowCreateMapBottom: (state) => {
      if (state.row < 52 && state.row > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 1)].map((_, idxr) => {
          let v = [...Array(state.col)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });

        state.row = state.row + 1;
        state.cell = nilai;
      }
    },
    reduceRowCreateMapBottom: (state) => {
      if (state.row <= 50 && state.row > 1) {
        let limit = `r${state.row - 1}`;
        let allBefore = state.cellSelected.flat();
        let allAfter = state.cellSelectedNew.flat();
        let all = [...allBefore, ...allAfter];
        if (all.filter((item) => item.includes(limit)).length === 0) {
          state.entrance = [];
          let nilai = [...Array(state.row - 1)].map((_, idxr) => {
            let v = [...Array(state.col)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          });
          let entranceNew = state.entrance.map((ent) => {
            let valueArr = ent.split('r')[1].split('c');
            return `r${Number(valueArr[0]) - 1}c${Number(valueArr[1])}`;
          });
          state.entrance = entranceNew;
          state.row = state.row - 1;
          state.cell = nilai;
        }
      }
    },
    // !----- Address Map ------
    changeMapType: (state, action) => {
      state.mapInputType = action.payload;
    },
    changeCoordinate: (state, action) => {
      state.coordinate = action.payload;
    },
    changeCenter: (state, action) => {
      state.center = action.payload;
    },
    loadPlaceSearchByName: (state, action) => {
      state.placesSearchByName = action.payload;
    },
    loadPlaceSearchByNameLoading: (state, action) => {
      state.placesSearchByNameLoading = action.payload;
    },
    changePlaceGetByName: (state, action) => {
      let result = action.payload;
      state.placeGetByName = result;
      state.coordinate = [result.lat, result.lon];
      state.center = [result.lat, result.lon];
    },
    changePlaceGetByCoordinate: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinate = result;
    },
    changeIsPlaceGetByCoordinateLoading: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinateLoading = result;
    },
    changeAddress: (state, action) => {
      let result = action.payload;
      state.address = result;
    },
    submitAddresMap: (state, action) => {
      const { address, coordinate } = action.payload;
      state.adressFix = address;
      state.coordinateFix = coordinate;
    },
    // * ---  laste
    // * ---  last
    changeIsLoadingUpdate: (state, action) => {
      state.isLoadingUpdate = action.payload;
    },
    changeErrorEmptyEditHub: (state, action) => {
      state.errorEmpty = action.payload;
    },
    clearData: (state) => {
      state.isEdit = false;
      state.subsidiaryId = 0;
      state.name = '';
      // * --- Photo ---
      state.photo = [];
      state.isMaxSizePhoto = false;
      state.photoPreview = '';
      state.isPhotoPreview = false;
      state.video = [];
      state.isMaxSizeVideo = false;
      state.videoPreview = '';
      state.isVideoPreview = false;
      // * --- PIC --
      state.picChoose = null;
      state.picSearch = '';
      state.picSearchDebounce = '';
      state.picNumber = '';
      state.isCreateMap = false;
      state.createMapPage = 'preview'; //? -- grid; racks; preview --
      state.status = false;
      // *--- RACK
      state.col = 5;
      state.row = 5;
      state.cell = [];
      state.isEntrance = true;
      state.entrance = [];
      state.cellSelectedAll = [];
      state.cellSelected = [];
      state.cellSelectedDetails = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.cellSelectedMoment = [];
      state.isDoneEdit = true;
      state.cellSelectedNew = [];
      state.cellSelectedDetailsNew = [];
      state.cellSelectedNewTemporary = {};
      state.rackDeletes = [];
      // *-- LAste
      state.isLoadingUpdate = false;
      state.errorEmpty = '';
      state.rackSelectedAddMoment = [];
    },
  },
});

export const {
  goToMasterHubEdit,
  changeIsEdit,
  changeSubsidiaryId,
  changeName,
  // *--- Photo n Video
  changeIsLoadingMasterHub,
  inputPhoto,
  deletePhoto,
  changeIsMaxSizePhoto,
  changePhotoPreview,
  clickIsPreviewPhoto,
  inputVideo,
  changeIsLoadingVideo,
  deleteVideo,
  changeIsMaxSizeVideo,
  changeVideoPreview,
  clickIsPreviewVideo,
  //*---PIC---
  changePicChoose,
  changePicSearch,
  changePicSearchDebounce,
  changePicNumber,
  // * --- Status --
  changeStatus,
  //*--- Rack Map ---
  changeIsCreateMap,
  changeCreateMapPage,
  changeIsEntrance,
  inputRackTemporary,
  cancelRackTemporary,
  saveRackTemporary,
  addNewRack,
  inputNewRackTemporary,
  cancelNewRackTemporary,
  saveNewRackTemporary,
  deleteCellNewFix,
  deleteCellFix,
  inputEntrance,
  changeRackSelectedAddMoment,
  // *--- Add Row n Col Rack ---
  setUpCell,
  changeColCreateMap,
  changeRowCreateMap,
  addColCreateMapRight,
  reduceColCreateMapRight,
  addRowCreateMapBottom,
  reduceRowCreateMapBottom,
  addColCreateMapLeft,
  reduceColCreateMapLeft,
  addRowCreateMapTop,
  reduceRowCreateMapTop,
  // *--- Address Map
  changeMapType,
  changeCoordinate,
  changeCenter,
  loadPlaceSearchByName,
  loadPlaceSearchByNameLoading,
  changePlaceGetByName,
  changePlaceGetByCoordinate,
  changeIsPlaceGetByCoordinateLoading,
  changeAddress,
  submitAddresMap,
  // *--- Last
  changeIsLoadingUpdate,
  changeErrorEmptyEditHub,
  clearData,
} = masterHubEditState.actions;

export const getIsMasterHubEdit = (state) => state.masterHubEdit.isEdit;
export const getMasterHubEditAll = (state) => state.masterHubEdit;
export const getNameMasterHubEdit = (state) => state.masterHubEdit.name;
export const getIsloadingImage = (state) =>
  state.masterHubEdit.isLoadingMasterHub;

export const inputImage = (e) => (dispatch) => {
  dispatch(changeIsLoadingMasterHub(true));
  const img = e.target.files[0];
  if (img.size > 2000000) {
    dispatch(changeIsMaxSizePhoto(true));
  } else {
    let datas = {
      image: img,
      resource: 'masterHub',
    };
    uploadImage(datas).then((res) => {
      dispatch(inputPhoto(res.data.data.path));
      dispatch(changeIsMaxSizePhoto(false));
    });
    dispatch(changeIsLoadingMasterHub(false));
  }
};

export const deleteImage = (link) => (dispatch) => {
  // deleteFile(link).then(() => {
  //   dispatch(deletePhoto(link));
  // });
  dispatch(deletePhoto(link));
};

export const inputTayangan = (e) => (dispatch) => {
  const vd = e.target.files[0];
  if (vd.size > 100000000) {
    dispatch(changeIsMaxSizeVideo(true));
  } else {
    dispatch(changeIsLoadingVideo(true));
    let datas = {
      video: vd,
      resource: 'masterHub',
    };
    uploadVideo(datas).then((res) => {
      dispatch(inputVideo(res.data.data.path));
      dispatch(changeIsMaxSizeVideo(false));
    });
    dispatch(changeIsLoadingVideo(false));
  }
};

export const deleteTayangan = (link) => (dispatch) => {
  // deleteFile(link).then(() => {
  //   dispatch(deleteVideo(link));
  // });
  dispatch(deleteVideo(link));
};

export const saveEditMasterHub = (data) => (dispatch) => {
  const {
    id,
    subsidiary,
    name,
    pic,
    gambar,
    video,
    column,
    row,
    entrance,
    rackDelete,
    rackUpdate,
    rackAdd,
  } = data;
  const { idKustomer } = pic;
  const upadeteHub = {
    subsidiaryId: subsidiary,
    namaToko: name,
    idKustomer: idKustomer,
    gambar: gambar,
    videos: video,
    mapColumn: column,
    mapRow: row,
    entrancePosition: entrance,
  };

  const addRackData = rackAdd.map((item) => {
    return {
      idToko: id,
      subHubId: null,
      miniHubId: null,
      name: item.name,
      images: '',
      videos: '',
      column: item.column,
      row: item.row,
      columnPosition: item.columnPosition,
      description: item.description,
      width: 0.0,
      height: 0.0,
      length: 0.0,
      weight: 0,
    };
  });
  dispatch(changeIsLoadingUpdate(true));
  const updateRackData = rackUpdate.map((item) => {
    return {
      id: item.id,
      name: item.name,
      description: item.description,
      columnPosition: item.columnPosition,
    };
  });
  const deleteRackData = {
    hubRackId: rackDelete,
  };
  // const saveEdit = async () => {
  //   try {
  //     const { data } = await editMasterHub();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const addRacks = async () => {
    try {
      await addMasterHubRacksMultiple(addRackData);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(changeIsLoadingUpdate(false));
      dispatch(clearData(false));
    }
  };
  const updateRack = async () => {
    try {
      await editMasterHubRacksMultiple(updateRackData);
    } catch (error) {
      console.error(error);
    } finally {
      if (rackAdd.length !== 0) {
        addRacks();
      } else {
        dispatch(changeIsLoadingUpdate(false));
        dispatch(clearData(false));
      }
    }
  };
  const deleteRack = async () => {
    try {
      await deleteMasterHubRacksMultiple(deleteRackData);
    } catch (error) {
      console.error(error);
    } finally {
      updateRack();
    }
  };

  const updateMasterHub = async () => {
    try {
      await editMasterHub(id, upadeteHub);
    } catch (error) {
      console.error(error);
    } finally {
      if (rackDelete.length !== 0) {
        deleteRack();
      } else {
        updateRack();
      }
    }
  };

  updateMasterHub();
};

export const fetchPlacesSearchByName = (name) => (dispatch) => {
  const fetchData = async () => {
    dispatch(loadPlaceSearchByNameLoading(true));
    try {
      const { data } = await getPlacesByName(name);
      dispatch(loadPlaceSearchByName(data));
    } catch (error) {
      dispatch(loadPlaceSearchByName([]));
    } finally {
      dispatch(loadPlaceSearchByNameLoading(false));
    }
  };

  fetchData();
};

export const fetchPlacesSearchByCoordinate = (coor) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsPlaceGetByCoordinateLoading(true));
    try {
      const { data } = await getPlacesByCoordinate(coor);
      dispatch(changePlaceGetByCoordinate(data));
    } catch (error) {
      dispatch(changePlaceGetByCoordinate([]));
    } finally {
      dispatch(changeIsPlaceGetByCoordinateLoading(false));
    }
  };

  fetchData();
};

export default masterHubEditState.reducer;
