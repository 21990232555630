import { createSlice } from '@reduxjs/toolkit';
import {
  getPlacesByCoordinate,
  getPlacesByName,
} from '../../../api/AddressMap';
import { deleteFile, uploadImage, uploadVideo } from '../../../api/storage';

export const subHubAddState = createSlice({
  name: 'subHubAdd',
  initialState: {
    subsidiaryId: null,
    isLoading: false,
    isUploadImage: false,
    name: '',
    image: [],
    isMaxSizeImage: false,
    imagePreview: '',
    isImagePreview: false,
    video: [],
    isUploadVideo: false,
    isMaxSizeVideo: false,
    videoPreview: '',
    isVideoPreview: false,
    picChoose: null,
    picSearch: '',
    picSearchDebounce: '',
    picPhone: '',

    // rak map
    isCreateMap: false,
    createMapPage: 'grid',
    col: 5,
    row: 5,
    cell: [],
    isEntrance: false,
    entrance: [],
    cellSelectedAll: [],
    cellSelected: [],
    cellSelectedDetails: [],
    cellSelectedMoment: [],
    cellSelectedNameMoment: '',
    cellSelectedColMoment: 5,
    cellSelectedRowMoment: 5,
    cellSelectedDesMoment: '',
    cellSelectedHover: [],
    cellSelectedEditTemporary: {},
    isCellSelectedEdit: false,
    idDetailHover: 0,
    dataDetailRack: null,

    // address map
    address: '',
    addressFix: '',
    coordinateFix: [],
    mapInputType: 'manual',
    placeGetByName: null,
    coordinate: [],
    center: [3.546728899138594, 98.69014558305145],
    placesSearchByName: [],
    placesSearchByNameLoading: false,
    placeGetByCoordinate: null,
    placeGetByCoordinateLoading: false,

    status: false,
    idToko: null,
  },
  reducers: {
    changeSubsidiaryId: (state, action) => {
      state.subsidiaryId = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeIsUploadImage: (state, action) => {
      state.isUploadImage = action.payload;
    },
    changeSubHubName: (state, action) => {
      state.name = action.payload;
    },
    changeIdToko: (state, action) => {
      state.idToko = action.payload;
    },

    // add image
    inputImage: (state, action) => {
      state.image = [...state.image, action.payload];
    },
    deleteImage: (state, action) => {
      state.image = state.image.filter((item) => item !== action.payload);
    },
    changeIsMaxSizeSubHubImage: (state, action) => {
      state.isMaxSizeImage = action.payload;
    },
    changeImagePreview: (state, action) => {
      state.imagePreview = action.payload;
    },
    clickIsPreviewImage: (state) => {
      state.isImagePreview = !state.isImagePreview;
    },

    // add video
    inputVideo: (state, action) => {
      state.video = [...state.video, action.payload];
    },
    changeIsUploadVideoSubHub: (state, action) => {
      state.isUploadVideo = action.payload;
    },
    deleteVideo: (state, action) => {
      state.video = state.video.filter((item) => item !== action.payload);
    },
    changeIsMaxSizeVideo: (state, action) => {
      state.isMaxSizeVideo = action.payload;
    },
    changeVideoPreview: (state, action) => {
      state.videoPreview = action.payload;
    },
    clickIsVideoPreview: (state) => {
      state.isVideoPreview = !state.isVideoPreview;
    },

    // add PIC
    changePicChoose: (state, action) => {
      state.picChoose = action.payload;
    },
    changePicSearch: (state, action) => {
      state.picSearch = action.payload;
    },
    changePicSearchDebounce: (state, action) => {
      state.picSearchDebounce = action.payload;
    },
    changePicPhone: (state, action) => {
      state.picPhone = action.payload;
    },

    // --------------Rak Map -------------
    changeIsCreateMap: (state) => {
      state.isCreateMap = !state.isCreateMap;
    },
    setUpCell: (state) => {
      let nilai = [...Array(state.row + 2)].map((_, idxr) => {
        let v = [...Array(state.col + 2)].map((_, idxc) => {
          return `r${idxr + 1}c${idxc + 1}`;
        });
        return v;
      });
      state.cell = nilai;
    },
    changeCreateMapPage: (state, action) => {
      state.createMapPage = action.payload;
    },
    changeColCreateMap: (state, action) => {
      if (action.payload <= 50 && action.payload > 0) {
        state.col = action.payload;
      }
    },
    changeRowCreateMap: (state, action) => {
      if (action.payload <= 50 && action.payload > 0) {
        state.row = action.payload;
      }
    },
    changeNameCellSelectedMoment: (state, action) => {
      state.cellSelectedNameMoment = action.payload;
    },
    changeColCellSelectedMoment: (state, action) => {
      if (action.payload > 0 && action.payload <= 50) {
        state.cellSelectedColMoment = action.payload;
      }
    },
    changeRowCellSelectedMoment: (state, action) => {
      if (action.payload > 0 && action.payload <= 50) {
        state.cellSelectedRowMoment = action.payload;
      }
    },
    changeDesCellSelectedMoment: (state, action) => {
      state.cellSelectedDesMoment = action.payload;
    },
    submitEditCellFix: (state) => {
      let data = {
        id: state.cellSelectedEditTemporary.id,
        name: state.cellSelectedNameMoment,
        column: state.cellSelectedColMoment,
        row: state.cellSelectedRowMoment,
        description: state.cellSelectedDesMoment,
        columnPosition: state.cellSelectedMoment,
      };
      state.cellSelected = [...state.cellSelected, state.cellSelectedMoment];
      state.cellSelectedDetails = [...state.cellSelectedDetails, data];
      state.cellSelectedNameMoment = '';
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = '';
      state.cellSelectedMoment = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.cellSelectedAll = state.cellSelected.flat();
    },
    cancelEditCellFix: (state) => {
      state.cellSelected = [
        ...state.cellSelected,
        state.cellSelectedEditTemporary.columnPosition,
      ];
      state.cellSelectedDetails = [
        ...state.cellSelectedDetails,
        state.cellSelectedEditTemporary,
      ];
      state.cellSelectedNameMoment = '';
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = '';
      state.cellSelectedMoment = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.cellSelectedAll = state.cellSelected.flat();
    },
    makeCellFix: (state) => {
      let data = {
        id: state.cellSelectedDetails.length + 1,
        name: state.cellSelectedNameMoment,
        column: state.cellSelectedColMoment,
        row: state.cellSelectedRowMoment,
        description: state.cellSelectedDesMoment,
        columnPosition: state.cellSelectedMoment,
      };
      state.cellSelected = [...state.cellSelected, state.cellSelectedMoment];
      state.cellSelectedDetails = [...state.cellSelectedDetails, data];
      state.cellSelectedNameMoment = '';
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = '';
      state.cellSelectedMoment = [];
      state.cellSelectedAll = state.cellSelected.flat();
    },
    deleteCellFix: (state, action) => {
      state.cellSelected = state.cellSelected.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedDetails = state.cellSelectedDetails.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedAll = state.cellSelected.flat();
    },
    editCellFix: (state, action) => {
      let data = state.cellSelectedDetails.find(
        (_, idx) => idx === action.payload
      );
      state.isCellSelectedEdit = true;
      state.cellSelectedEditTemporary = data;
      state.cellSelected = state.cellSelected.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedDetails = state.cellSelectedDetails.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedAll = state.cellSelected.flat();

      state.cellSelectedNameMoment = data.name;
      state.cellSelectedColMoment = data.column;
      state.cellSelectedRowMoment = data.row;
      state.cellSelectedDesMoment = data.description;
      state.cellSelectedMoment = data.columnPosition;
    },
    hoverCellFix: (state, action) => {
      let data = state.cellSelected.find((_, idx) => idx === action.payload);
      let detail = state.cellSelectedDetails.find(
        (_, idx) => idx === action.payload
      );
      state.cellSelectedHover = data;
      state.idDetailHover = detail.id;
    },
    unHoverCellFix: (state) => {
      state.cellSelectedHover = [];
      state.idDetailHover = 0;
    },
    changeIsEntrance: (state) => {
      if (state.isEntrance) {
        state.entrance = [];
      }
      state.isEntrance = !state.isEntrance;
    },
    addRowCreateMapTop: (state, action) => {
      if (state.row < 50 && state.row > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2 + action.payload)].map(
          (_, idxr) => {
            let v = [...Array(state.col + 2)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          }
        );
        state.row = state.row + action.payload;
        state.cell = nilai;
        let dataCell = state.cellSelected.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split('r')[1].split('c');
            let childValue = `r${Number(child[0]) + action.payload}c${Number(
              child[1]
            )}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetails = state.cellSelectedDetails.map((item) => {
          let child = item.columnPosition.map((v) => {
            let cols = v.split('r')[1].split('c');
            let colsValue = `r${Number(cols[0]) + action.payload}c${Number(
              cols[1]
            )}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child,
          };
        });
        let dataCellMoment = state.cellSelectedMoment.map((item) => {
          let child = item.split('r')[1].split('c');
          let childValue = `r${Number(child[0]) + action.payload}c${Number(
            child[1]
          )}`;
          return childValue;
        });
        state.cellSelectedMoment = dataCellMoment;
        state.cellSelected = dataCell;
        state.cellSelectedDetails = dataCellDetails;
        state.cellSelectedAll = state.cellSelected.flat();
      }
    },
    reduceRowCreateMapTop: (state, action) => {
      if (state.row <= 50 && state.row > 1) {
        if (
          state.cellSelectedAll.filter(
            (item) => item.split('r')[1].split('c')[0] === '2'
          ).length === 0 &&
          state.cellSelectedMoment.filter(
            (item) => item.split('r')[1].split('c')[0] === '2'
          ).length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2 - action.payload)].map(
            (_, idxr) => {
              let v = [...Array(state.col + 2)].map((_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              });
              return v;
            }
          );
          state.row = state.row - action.payload;
          state.cell = nilai;
          let dataCell = state.cellSelected.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split('r')[1].split('c');
              let childValue = `r${Number(child[0]) - action.payload}c${Number(
                child[1]
              )}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetails = state.cellSelectedDetails.map((item) => {
            let child = item.columnPosition.map((v) => {
              let cols = v.split('r')[1].split('c');
              let colsValue = `r${Number(cols[0]) - action.payload}c${Number(
                cols[1]
              )}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child,
            };
          });
          let dataCellMoment = state.cellSelectedMoment.map((item) => {
            let child = item.split('r')[1].split('c');
            let childValue = `r${Number(child[0]) - action.payload}c${Number(
              child[1]
            )}`;
            return childValue;
          });
          state.cellSelectedMoment = dataCellMoment;
          state.cellSelected = dataCell;
          state.cellSelectedDetails = dataCellDetails;
          state.cellSelectedAll = state.cellSelected.flat();
        }
      }
    },
    addRowCreateMapBottom: (state, action) => {
      if (state.row < 50 && state.row > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2 + action.payload)].map(
          (_, idxr) => {
            let v = [...Array(state.col + 2)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          }
        );

        state.row = state.row + action.payload;
        state.cell = nilai;
      }
    },
    reduceRowCreateMapBottom: (state, action) => {
      if (state.row <= 50 && state.row > 1) {
        let limit = `r${state.row + 2 - action.payload}`;
        if (
          state.cellSelectedAll.filter((item) => item.includes(limit))
            .length === 0 &&
          state.cellSelectedMoment.filter((item) => item.includes(limit))
            .length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2 - action.payload)].map(
            (_, idxr) => {
              let v = [...Array(state.col + 2)].map((_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              });
              return v;
            }
          );
          let entranceNew = state.entrance.map((ent) => {
            let valueArr = ent.split('r')[1].split('c');
            return `r${Number(valueArr[0]) - 1}c${Number(valueArr[1])}`;
          });
          state.entrance = entranceNew;
          state.row = state.row - action.payload;
          state.cell = nilai;
        }
      }
    },
    addColCreateMapLeft: (state, action) => {
      if (state.col < 50 && state.col > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2)].map((_, idxr) => {
          let v = [...Array(state.col + 2 + action.payload)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });
        state.col = state.col + action.payload;
        state.cell = nilai;
        let dataCell = state.cellSelected.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split('r')[1].split('c');
            let childValue = `r${Number(child[0])}c${
              Number(child[1]) + action.payload
            }`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetails = state.cellSelectedDetails.map((item) => {
          let child = item.columnPosition.map((v) => {
            let cols = v.split('r')[1].split('c');
            let colsValue = `r${Number(cols[0])}c${
              Number(cols[1]) + action.payload
            }`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child,
          };
        });
        let dataCellMoment = state.cellSelectedMoment.map((item) => {
          let child = item.split('r')[1].split('c');
          let childValue = `r${Number(child[0])}c${
            Number(child[1]) + action.payload
          }`;
          return childValue;
        });
        state.cellSelectedMoment = dataCellMoment;
        state.cellSelected = dataCell;
        state.cellSelectedDetails = dataCellDetails;
        state.cellSelectedAll = state.cellSelected.flat();
      }
    },
    reduceColCreateMapLeft: (state, action) => {
      if (state.col <= 50 && state.col > 1) {
        if (
          state.cellSelectedAll.filter(
            (item) => item.split('r')[1].split('c')[1] === '2'
          ).length === 0 &&
          state.cellSelectedMoment.filter(
            (item) => item.split('r')[1].split('c')[1] === '2'
          ).length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2)].map((_, idxr) => {
            let v = [...Array(state.col + 2 - action.payload)].map(
              (_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              }
            );
            return v;
          });
          state.col = state.col - action.payload;
          state.cell = nilai;
          let dataCell = state.cellSelected.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split('r')[1].split('c');
              let childValue = `r${Number(child[0])}c${
                Number(child[1]) - action.payload
              }`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetails = state.cellSelectedDetails.map((item) => {
            let child = item.columnPosition.map((v) => {
              let cols = v.split('r')[1].split('c');
              let colsValue = `r${Number(cols[0])}c${
                Number(cols[1]) - action.payload
              }`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child,
            };
          });
          let dataCellMoment = state.cellSelectedMoment.map((item) => {
            let child = item.split('r')[1].split('c');
            let childValue = `r${Number(child[0])}c${
              Number(child[1]) - action.payload
            }`;
            return childValue;
          });
          state.cellSelectedMoment = dataCellMoment;
          state.cellSelected = dataCell;
          state.cellSelectedDetails = dataCellDetails;
          state.cellSelectedAll = state.cellSelected.flat();
        }
      }
    },
    addColCreateMapRight: (state, action) => {
      if (state.col < 50 && state.col > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2)].map((_, idxr) => {
          let v = [...Array(state.col + 2 + action.payload)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });

        state.col = state.col + action.payload;
        state.cell = nilai;
      }
    },
    reduceColCreateMapRight: (state, action) => {
      if (state.col <= 50 && state.col > 1) {
        let limit = `c${state.col + 2 - action.payload}`;
        if (
          state.cellSelectedAll.filter((item) => item.includes(limit))
            .length === 0 &&
          state.cellSelectedMoment.filter((item) => item.includes(limit))
            .length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2)].map((_, idxr) => {
            let v = [...Array(state.col + 2 - action.payload)].map(
              (_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              }
            );
            return v;
          });

          state.col = state.col - action.payload;
          state.cell = nilai;
        }
      }
    },
    addEntrance: (state, action) => {
      if (state.isEntrance) {
        if (state.entrance.includes(action.payload)) {
          state.entrance = state.entrance.filter((v) => v !== action.payload);
        } else {
          state.entrance = [...state.entrance, action.payload];
        }
      }
    },
    addCellSelectedMoment: (state, action) => {
      if (state.cellSelectedAll.includes(action.payload)) {
      } else {
        if (state.cellSelectedMoment.includes(action.payload)) {
          state.cellSelectedMoment = state.cellSelectedMoment.filter(
            (v) => v !== action.payload
          );
        } else {
          state.cellSelectedMoment = [
            ...state.cellSelectedMoment,
            action.payload,
          ];
        }
      }
    },
    addCellSelectedMomentHold: (state, action) => {
      if (state.cellSelectedMoment.includes(action.payload)) {
      } else {
        if (state.cellSelectedMoment.includes(action.payload)) {
        } else {
          state.cellSelectedMoment = [
            ...state.cellSelectedMoment,
            action.payload,
          ];
        }
      }
    },
    unSeeDetailRack: (state) => {
      state.dataDetailRack = null;
    },
    seeDetailRack: (state) => {
      if (state.idDetailHover !== 0) {
        state.dataDetailRack = state.cellSelectedDetails.find(
          (detail) => detail.id === state.idDetailHover
        );
      }
    },
    hoverRacks: (state, action) => {
      if (state.cellSelectedAll.includes(action.payload)) {
        let data = state.cellSelected.find((item) =>
          item.includes(action.payload)
        );
        let detals = state.cellSelectedDetails.find((item) =>
          item.columnPosition.includes(action.payload)
        );
        state.cellSelectedHover = data;
        state.idDetailHover = detals.id;
      }
    },
    unHoverRacks: (state) => {
      state.cellSelectedHover = [];
      state.idDetailHover = 0;
    },

    // ------------- Address ----------
    changeMapTypeSubHub: (state, action) => {
      state.mapInputType = action.payload;
    },
    changePlaceGetByName: (state, action) => {
      let result = action.payload;
      state.placeGetByName = result;
      state.coordinate = [result.lat, result.lon];
      state.center = [result.lat, result.lon];
    },
    changeCoordinate: (state, action) => {
      state.coordinate = action.payload;
    },
    changeCenter: (state, action) => {
      state.center = action.payload;
    },
    changeAddress: (state, action) => {
      let result = action.payload;
      state.address = result;
    },
    submitAddresMap: (state, action) => {
      const { address, coordinate } = action.payload;
      state.addressFix = address;
      state.coordinateFix = coordinate;
    },
    loadPlaceSearchByName: (state, action) => {
      state.placesSearchByName = action.payload;
    },
    loadPlaceSearchByNameLoading: (state, action) => {
      state.placesSearchByNameLoading = action.payload;
    },
    changeIsPlaceGetByCoordinateLoading: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinateLoading = result;
    },
    changePlaceGetByCoordinate: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinate = result;
    },
    // status
    changeStatus: (state) => {
      state.status = !state.status;
    },
    clearData: (state) => {
      state.subsidiaryId = null;
      state.isLoading = false;
      state.isUploadImage = false;
      state.name = '';
      state.image = [];
      state.isMaxSizeImage = false;
      state.imagePreview = '';
      state.isImagePreview = false;
      state.video = [];
      state.isMaxSizeVideo = false;
      state.videoPreview = '';
      state.isVideoPreview = false;
      state.picChoose = null;
      state.picSearch = '';
      state.picSearchDebounce = '';
      state.picPhone = '';
      state.isCreateMap = false;

      // rak map
      state.createMapPage = 'grid';
      state.col = 5;
      state.row = 5;
      state.cell = [];
      state.isEntrance = false;
      state.entrance = [];
      state.cellSelectedAll = [];
      state.cellSelected = [];
      state.cellSelectedDetails = [];
      state.cellSelectedMoment = [];
      state.cellSelectedNameMoment = '';
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = '';
      state.cellSelectedHover = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.idDetailHover = 0;
      state.dataDetailRack = null;

      // address map
      state.address = '';
      state.addressFix = '';
      state.coordinateFix = [];
      state.mapInputType = 'manual';
      state.placeGetByName = null;
      state.coordinate = [];
      state.center = [3.546728899138594, 98.69014558305145];
      state.placesSearchByName = [];
      state.placesSearchByNameLoading = false;
      state.placeGetByCoordinate = null;
      state.placeGetByCoordinateLoading = false;

      state.status = false;
      state.idToko = null;
    },
  },
});

export const {
  changeidTokoSubHub,
  changeSubsidiaryId,
  changeIsLoading,
  changeIsUploadImage,
  changeSubHubName,
  changeIdToko,
  changeIsMaxSizeSubHubImage,
  changeImagePreview,
  clickIsPreviewImage,
  inputImage,
  deleteImage,
  inputVideo,
  changeIsUploadVideoSubHub,
  deleteVideo,
  changeIsMaxSizeVideo,
  changeVideoPreview,
  clickIsVideoPreview,
  changePicChoose,
  changePicSearch,
  changePicSearchDebounce,
  changePicPhone,

  // rak map
  changeIsCreateMap,
  setUpCell,
  changeCreateMapPage,
  changeColCreateMap,
  changeNameCellSelectedMoment,
  changeColCellSelectedMoment,
  changeRowCellSelectedMoment,
  changeDesCellSelectedMoment,
  submitEditCellFix,
  cancelEditCellFix,
  makeCellFix,
  hoverCellFix,
  unHoverCellFix,
  deleteCellFix,
  editCellFix,
  changeIsEntrance,
  addRowCreateMapTop,
  reduceRowCreateMapTop,
  addRowCreateMapBottom,
  reduceRowCreateMapBottom,
  addColCreateMapLeft,
  reduceColCreateMapLeft,
  addColCreateMapRight,
  reduceColCreateMapRight,
  addEntrance,
  addCellSelectedMoment,
  addCellSelectedMomentHold,
  unSeeDetailRack,
  seeDetailRack,
  hoverRacks,
  unHoverRacks,
  changeRowCreateMap,

  // address map
  changeMapTypeSubHub,
  changePlaceGetByName,
  changeAddress,
  changeCoordinate,
  changeCenter,
  submitAddresMap,
  loadPlaceSearchByName,
  loadPlaceSearchByNameLoading,
  changeIsPlaceGetByCoordinateLoading,
  changePlaceGetByCoordinate,

  changeStatus,
  clearData,
} = subHubAddState.actions;

export const getSubHubAddAllState = (state) => state.subHubAdd;
export const getIsLoadig = (state) => state.subHubAdd.isLoading;
export const getIsUploadImage = (state) => state.subHubAdd.isUploadImage;
export const getIdToko = (state) => state.subHubAdd.idToko;
export const getSubsidiaryIdSubHub = (state) => state.subHubAdd.subsidiaryId;

// add sub hub
export const getSubHubName = (state) => state.subHubAdd.name;
export const getIsMaxSizeImageSubHubAdd = (state) =>
  state.subHubAdd.isMaxSizeImage;
export const getImagePreviewSubHubAdd = (state) => state.subHubAdd.imagePreview;
export const getIsImagePreviewSubHubAdd = (state) =>
  state.subHubAdd.isImagePreview;
// add image sub hub
export const getSubHubImage = (state) => state.subHubAdd.image;
export const inputSubHubImage = (e) => (dispatch) => {
  const img = e.target.files[0];
  if (img.size > 2000000) {
    dispatch(changeIsMaxSizeSubHubImage(true));
  } else {
    dispatch(changeIsUploadImage(true));
    let datas = {
      image: img,
      resource: 'subHub',
    };
    uploadImage(datas).then((res) => {
      dispatch(inputImage(res.data.data.path));
      dispatch(changeIsMaxSizeSubHubImage(false));
      dispatch(changeIsUploadImage(false));
    });
  }
};
// export const deleteSubHubImage = (link) => (dispatch) => {
//   deleteFile(link).then(() => {
//     dispatch(deleteImage(link));
//   });
// };
export const deleteSubHubImage = (link) => (dispatch) => {
  dispatch(deleteImage(link));
};
// add video sub hub
export const getVideoSubHubAdd = (state) => state.subHubAdd.video;
export const getIsMaxSizeVideoSubHubAdd = (state) =>
  state.subHubAdd.isMaxSizeVideo;
export const getVideoPreviewSubHubAdd = (state) => state.subHubAdd.videoPreview;
export const getIsVideoPreviewSubHubAdd = (state) =>
  state.subHubAdd.isVideoPreview;

export const inputVideoPreview = (e) => (dispatch) => {
  const vd = e.target.files[0];
  if (vd.size > 1000000000) {
    dispatch(changeIsMaxSizeVideo(true));
  } else {
    dispatch(changeIsLoading(true));
    let datas = {
      video: vd,
      resource: 'subHub',
    };
    uploadVideo(datas).then((res) => {
      dispatch(inputVideo(res.data.data.path));
      dispatch(changeIsMaxSizeVideo(false));
      dispatch(changeIsLoading(false));
    });
  }
};
// export const deleteVideoPreview = (link) => (dispatch) => {
//   deleteFile(link).then(() => {
//     dispatch(deleteVideo(link));
//   });
// };
export const deleteVideoPreview = (link) => (dispatch) => {
  dispatch(deleteVideo(link));
};

// add PIC sub hub
export const getPicChooseSubHubAdd = (state) => state.subHubAdd.picChoose;
export const getPicSearchSubHubAdd = (state) => state.subHubAdd.picSearch;
export const getPicSearchDebounceSubHubAdd = (state) =>
  state.subHubAdd.picSearchDebounce;
export const getPicPhoneSubHubAdd = (state) => state.subHubAdd.picPhone;

// add create map
export const getIsCreateMap = (state) => state.subHubAdd.isCreateMap;
export const getCreateMapPage = (state) => state.subHubAdd.createMapPage;
export const getColCreateMap = (state) => state.subHubAdd.col;
export const getRowCreateMap = (state) => state.subHubAdd.row;
export const getCellCreateMap = (state) => state.subHubAdd.cell;
export const getCellSelectedAllCreateMap = (state) =>
  state.subHubAdd.cellSelectedAll;
export const getCellSelectedHoverCreateMap = (state) =>
  state.subHubAdd.cellSelectedHover;
export const getIsCellSelectedEditCreateMap = (state) =>
  state.subHubAdd.isCellSelectedEdit;
export const getCellSelectedMomentCreateMap = (state) =>
  state.subHubAdd.cellSelectedMoment;
export const getCellSelectedDetailsCreateMap = (state) =>
  state.subHubAdd.cellSelectedDetails;
export const getCellSelectedNameMomentCreateMap = (state) =>
  state.subHubAdd.cellSelectedNameMoment;
export const getCellSelectedColMomentCreateMap = (state) =>
  state.subHubAdd.cellSelectedColMoment;
export const getCellSelectedRowMomentCreateMap = (state) =>
  state.subHubAdd.cellSelectedRowMoment;
export const getCellSelectedDesMomentCreateMap = (state) =>
  state.subHubAdd.cellSelectedDesMoment;
export const getIsEntranceCreateMap = (state) => state.subHubAdd.isEntrance;
export const getEntranceCreateMap = (state) => state.subHubAdd.entrance;
export const getDetailRackCreateMap = (state) => state.subHubAdd.dataDetailRack;
export const getDetailHoverCreateMap = (state) => state.subHubAdd.idDetailHover;

//  address map
export const getMapTypeSubHubAdd = (state) => state.subHubAdd.mapInputType;
export const getPlaceGetByName = (state) => state.subHubAdd.placeGetByName;
export const getPlacesSearchByName = (state) =>
  state.subHubAdd.placesSearchByName;
export const getLoadingPlacesSearchByName = (state) =>
  state.subHubAdd.placesSearchByNameLoading;
export const getAddres = (state) => state.subHubAdd.address;
export const getCenterSubHubAdd = (state) => state.subHubAdd.center;
export const getAddressFix = (state) => state.subHubAdd.addressFix;
export const getCoordinateeSubHubAdd = (state) => state.subHubAdd.coordinate;
export const getCoordinateFix = (state) => state.subHubAdd.coordinateFix;
export const getPlaceGetByCoorninate = (state) =>
  state.subHubAdd.placeGetByCoordinate;
export const getLaodingPlaceGetByCoorninate = (state) =>
  state.subHubAdd.placeGetByCoordinateLoading;

// status
export const getstatusSubHubAdd = (state) => state.subHubAdd.status;

// --------------- Get API -------------
export const inputImages = (e) => (dispatch) => {
  const img = e.target.files[0];
  if (img.size > 2000000) {
    dispatch(changeIsMaxSizeSubHubImage(true));
  } else {
    dispatch(changeIsUploadImage(true));
    let datas = {
      image: img,
      resource: 'subHub',
    };
    uploadImage(datas).then((res) => {
      dispatch(inputImage(res.data.data.path));
      dispatch(changeIsMaxSizeSubHubImage(false));
      dispatch(changeIsUploadImage(false));
    });
  }
};

export const deleteImages = (link) => (dispatch) => {
  deleteFile(link).then(() => {
    dispatch(deleteImage(link));
  });
};

export const inputTayangan = (e) => (dispatch) => {
  const vd = e.target.files[0];
  if (vd.size > 100000000) {
    dispatch(changeIsMaxSizeVideo(true));
  } else {
    dispatch(changeIsUploadVideoSubHub(true));
    let datas = {
      video: vd,
      resource: 'subHub',
    };
    uploadVideo(datas).then((res) => {
      dispatch(inputVideo(res.data.data.path));
      dispatch(changeIsMaxSizeVideo(false));
      dispatch(changeIsUploadVideoSubHub(false));
    });
  }
};

export const deleteTayangan = (link) => (dispatch) => {
  deleteFile(link).then(() => {
    dispatch(deleteVideo(link));
  });
};

export const fetchPlacesSearchByName = (name) => (dispatch) => {
  const fetchData = async () => {
    dispatch(loadPlaceSearchByNameLoading(true));
    try {
      const { data } = await getPlacesByName(name);
      dispatch(loadPlaceSearchByName(data));
    } catch (error) {
      dispatch(loadPlaceSearchByName([]));
    } finally {
      dispatch(loadPlaceSearchByNameLoading(false));
    }
  };

  fetchData();
};

export const fetchPlacesSearchByCoordinate = (coor) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsPlaceGetByCoordinateLoading(true));
    try {
      const { data } = await getPlacesByCoordinate(coor);
      dispatch(changePlaceGetByCoordinate(data));
    } catch (error) {
      dispatch(changePlaceGetByCoordinate([]));
    } finally {
      dispatch(changeIsPlaceGetByCoordinateLoading(false));
    }
  };

  fetchData();
};

export default subHubAddState.reducer;
