import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import { changePageAudit } from '../../../../../../store/Routing/auditRouting';
import {
  addNewItemAdjustment,
  changeAddNotesAdjustment,
  getDataSelectedAdjustment,
  getDataToAddAdjustment,
  getIdDetailAuditToAdjustment,
  getIdQrAddAdjustment,
  getNotesAddAdjustment,
  getlocationDetailAuditToAdjustment,
  goToAddAdjustment,
} from '../../../../../../store/adjustment/addAdjustment';
import {
  AddIcon,
  ChevronRightIcon,
  CloseIcons,
  DeleteIcon,
} from '../../../../../../assets/Icons';
import CustomInput from '../../../../../../components/shared/CustomInput';
import { useEffect, useState } from 'react';
import {
  createAdjustment,
  getDifferentProductAdjustment,
} from '../../../../../../api/adjustment';
import CustomCard from '../../../../../../components/shared/CustomCard';
import AddDifferentAdjustmentMobile from '../AdjustmentMobile/Add/AddDifferentAdjustmentMobile';
import {
  changePageAdjustment,
  setNotifAdjusment,
} from '../../../../../../store/Routing/addjustment';
import BottomMenu from '../../../../../../components/shared/BottomMenu';
import CustomSelectProduct from '../AdjustmentMobile/Common/CustomSelectproduct';

const AddDifferenceAdjustment = ({ setValue }) => {
  const matchTablet = useMediaQuery('(min-width:832px)');
  const dispatch = useDispatch();
  const temporaryData = useSelector(getDataSelectedAdjustment);
  const location = useSelector(getlocationDetailAuditToAdjustment);
  const idAudit = useSelector(getIdDetailAuditToAdjustment);
  const [notes, setNotes] = useState('');
  const [tambahItem, setTambahItem] = useState([]);
  // store data to option select
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setTambahItem(temporaryData);
  }, [temporaryData]);

  // validation
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);

  useEffect(() => {
    const cek = tambahItem.find((item, _) => item?.auditQrId === 0);
    if (notes !== '' && notes.length >= 5 && cek === undefined) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [tambahItem, notes]);

  // ! ----------------------- API ------------------------------

  // get data not selected
  useEffect(() => {
    const fetchDifferentAdjust = async () => {
      const id = temporaryData
        .map((item, _) => {
          if (item?.auditQrId !== 0) {
            return item?.auditQrId;
          }
          return null;
        })
        .filter((id) => id !== null);
      const params = {};
      params.audit_qr_id = id;
      params.audit_id = idAudit;
      try {
        const {
          data: { data },
        } = await getDifferentProductAdjustment(params);
        setNewData(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDifferentAdjust();
  }, [temporaryData]);

  // ! -----------------  handler ------------------------

  const handleAddItem = () => {
    dispatch(
      addNewItemAdjustment({
        namaProduk: '',
        idProduk: 0,
        system: 0,
        systemUnit: '',
        hubRackItemId: 0,
        different: 0,
        auditQrId: 0,
        auditId: 0,
        actual: 0,
        actualUnit: '',
        qty: 0,
        unit: '',
      })
    );
  };

  const handleCancelAdjust = () => {
    setValue(6);
    dispatch(changePageAudit('detailsAudits'));
    dispatch(changePageAdjustment(''));
  };

  const handleSubmit = async () => {
    const params = {};
    const newHubRackItemId = tambahItem?.map((item) => item.hubRackItemId);
    const newAuditQrId = tambahItem?.map((item) => item.auditQrId);

    params.hub_rack_item_id = newHubRackItemId;
    params.id_audit_qrs = newAuditQrId;
    params.notes = notes;
    params.idAudit = idAudit;
    setIsLoadingSubmit(true);
    try {
      const data = await createAdjustment(params);
      if (data.status === 200) {
        setIsLoadingSubmit(false);
        dispatch(changePageAdjustment(''));
        dispatch(
          setNotifAdjusment({
            key: 'addAdjustment',
            text: 'Adjustment successfully added',
          })
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSubmit(false);
    }
  };
  return !matchTablet ? (
    <AddDifferentAdjustmentMobile setValue={setValue} />
  ) : (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Grid container columns={12} spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
              mt: 2,
            }}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <BackButtonHub onClick={handleCancelAdjust} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={{
                    color: '#9E9D9D',
                    fontSize: '24px',
                    fontWeight: 600,
                    cursor: 'pointer',
                  }}
                >
                  Details
                </Typography>
                <ChevronRightIcon sx={{ width: '24px' }} />
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '24px',
                    fontWeight: 600,
                    cursor: 'pointer',
                  }}
                >
                  Add Adjustment
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 500, color: '#9E9E9E' }}
          >
            Location
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '54px',
              background: '#FAFAFA',
              padding: '0px 16px',
              borderRadius: '8px',
              mt: 1,
            }}
          >
            <Typography
              sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
            >
              {location}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 500, color: '#9E9E9E' }}
          >
            Condition
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '54px',
              background: '#FAFAFA',
              padding: '0px 16px',
              borderRadius: '8px',
              mt: 1,
            }}
          >
            <Typography
              sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
            >
              Difference
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 500, color: '#9E9E9E', mb: 1 }}
          >
            Notes
          </Typography>
          <CustomInput
            label={''}
            variant={'filled'}
            fullWidth
            sx={{
              bgcolor: '#fafafa',
              '& .MuiOutlinedInput-input': {
                padding: '0px 16px',
                borderRadius: '10px',
                '&::placeholder': {
                  color: 'red',
                  fontSize: '12px',
                },
              },
              borderRadius: '5px',
            }}
            value={notes}
            onChange={(e) => {
              if (e.target.value.length <= 100) {
                setNotes(e.target.value);
              }
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                mt: '5px',
                color: '#9E9D9D',
              }}
            >
              {notes.length > 5 ? '' : 'Enter at least 5 characters'}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                mt: '5px',
                ml: '15px',
                color: '#9E9D9D',
                fontWeight: 400,
              }}
            >
              {notes.length}/100
            </Typography>
          </Box>
        </Grid>

        {/* items */}
        <Grid item xs={12} sx={{ mt: 2, marginBottom: '56px' }}>
          <CustomCard>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '15px',
                boxShadow: '0px 1px 5px #ccc',
              }}
            >
              <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                Item(s)
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  color: '#51B15C',
                  gap: 1,
                  cursor: 'pointer',
                }}
                onClick={handleAddItem}
              >
                <AddIcon />
                Add more
              </Typography>
            </Box>

            <Box>
              <Grid container columns={12} spacing={2} sx={{ p: '15px 10px' }}>
                {tambahItem.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Grid container columns={12} spacing={2}>
                      {/* product */}
                      <Grid item xs={3.5}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            color: '#9E9E9E',
                            mb: 1,
                          }}
                        >
                          Product
                        </Typography>
                        <CustomSelectProduct
                          newData={newData}
                          defaultValue={item}
                          handleValue={(dataUpdate) => {
                            const memo = tambahItem.map((item, idx) => {
                              if (index === idx) {
                                return {
                                  ...item,
                                  ...dataUpdate,
                                };
                              } else {
                                return item;
                              }
                            });
                            setTambahItem(memo);
                            dispatch(goToAddAdjustment(memo));
                          }}
                        />
                      </Grid>

                      <Grid item xs={2.5}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            color: '#9E9E9E',
                          }}
                        >
                          System
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '54px',
                            background: '#FAFAFA',
                            padding: '0px 16px',
                            borderRadius: '8px',
                            mt: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              color: '#000',
                            }}
                          >
                            {item?.system || 0} {item?.systemUnit || 'unit'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            color: '#9E9E9E',
                          }}
                        >
                          Actual
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '54px',
                            background: '#FAFAFA',
                            padding: '0px 16px',
                            borderRadius: '8px',
                            mt: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              color: '#000',
                            }}
                          >
                            {item?.actual || 0} {item?.actualUnit || 'unit'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            color: '#9E9E9E',
                          }}
                        >
                          Difference
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '54px',
                            background: '#FAFAFA',
                            padding: '0px 16px',
                            borderRadius: '8px',
                            mt: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              color: '#000',
                            }}
                          >
                            {item?.difference || 0} {item?.systemUnit || 'unit'}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* hapus item */}
                      <Grid
                        item
                        xs={1}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          // mt: 4,
                          color: 'red',
                        }}
                      >
                        {tambahItem.length === 1 ? (
                          <></>
                        ) : (
                          <CloseIcons
                            sx={{
                              cursor: 'pointer',
                              width: '20px',
                            }}
                            onClick={() => {
                              const newItem = [...temporaryData];
                              newItem.splice(index, 1);
                              dispatch(goToAddAdjustment(newItem));
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </CustomCard>
        </Grid>

        {/* button event */}

        <BottomMenu
          handleGreen={handleSubmit}
          disableGreen={!allowSubmit}
          isGreen={true}
          greenText={
            isLoadingSubmit ? (
              <CircularProgress size={20} sx={{ color: '#FFFFFF' }} />
            ) : (
              'Submit'
            )
          }
        />
      </Grid>
    </Box>
  );
};
export default AddDifferenceAdjustment;
