import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AddMissingQrAdjustmentMobile from '../AdjustmentMobile/Add/AddMissingQrAdjustmentMobile';
import BottomMenu from '../../../../../../components/shared/BottomMenu';
import {
  AddIcon,
  ChevronRightIcon,
  CloseIcons,
} from '../../../../../../assets/Icons';
import CustomSelectProduct from '../AdjustmentMobile/Common/CustomSelectproduct';
import CustomCard from '../../../../../../components/shared/CustomCard';
import CustomInput from '../../../../../../components/shared/CustomInput';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import { changePageAudit } from '../../../../../../store/Routing/auditRouting';
import {
  changePageAdjustment,
  setNotifAdjusment,
} from '../../../../../../store/Routing/addjustment';
import {
  addNewItemAdjustmentMissingQr,
  getDataMissing,
  getIdAudiToMissingAdjustment,
  getLocationHub,
  goToAdjustMissingQr,
} from '../../../../../../store/adjustment/addMissingQrAdjustment';
import {
  createAdjustment,
  getAuditMissingProductList,
} from '../../../../../../api/adjustment';
import { useEffect } from 'react';
import { useState } from 'react';
import { goToAddAdjustment } from '../../../../../../store/adjustment/addAdjustment';
import QRCode from 'react-qr-code';

const AddMissingQrAdjustment = ({ setValue }) => {
  const matchTablet = useMediaQuery('(min-width:832px)');
  const dispatch = useDispatch();
  const temporaryData = useSelector(getDataMissing);
  const idAudit = useSelector(getIdAudiToMissingAdjustment);
  const locationHub = useSelector(getLocationHub);
  const [notes, setNotes] = useState('');
  const [tambahItem, setTambahItem] = useState([]);

  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setTambahItem(temporaryData);
  }, [temporaryData]);

  // validation
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);

  useEffect(() => {
    const cek = tambahItem.find((item, _) => item?.hubRackItemId === '');
    if (notes !== '' && notes.length >= 5 && cek === undefined) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [tambahItem, notes]);

  // ! ----------------------- API ------------------------------

  // get data not selected
  useEffect(() => {
    const fetchDifferentAdjust = async () => {
      const id = temporaryData
        .map((item, _) => {
          if (item?.hubRackItemId !== '') {
            return item?.hubRackItemId;
          }
          return null;
        })
        .filter((id) => id !== null);
      const params = {};
      params.hub_rack_item_id = id;
      params.audit_id = idAudit;
      try {
        const {
          data: { data },
        } = await getAuditMissingProductList(params);
        setNewData(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDifferentAdjust();
  }, [temporaryData]);

  // ! -------------------------- Handler ------------------------
  const handleAddItem = () => {
    dispatch(
      addNewItemAdjustmentMissingQr({
        auditId: '',
        auditQrId: '',
        cogs: '',
        encript: '',
        gambar: '',
        hub: '',
        hubRackItemId: '',
        idProduk: '',
        namaProduk: '',
        qty: '',
        rackName: '',
        systemUnit: '',
      })
    );
  };

  const handleBackButton = () => {
    setValue(6);
    dispatch(changePageAudit('missingQrCode'));
    dispatch(changePageAdjustment(''));
  };

  const handleSubmit = async () => {
    const params = {};
    const newHubRackItemId = tambahItem?.map((item) => item.hubRackItemId);

    params.hub_rack_item_id = newHubRackItemId;
    params.notes = notes;
    params.idAudit = idAudit;
    params.missing = 'y';
    setIsLoadingSubmit(true);
    try {
      const data = await createAdjustment(params);

      if (data.status === 200) {
        setIsLoadingSubmit(false);
        dispatch(changePageAdjustment(''));
        dispatch(
          setNotifAdjusment({
            key: 'addAdjustment',
            text: 'Adjustment successfully added',
          })
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  return !matchTablet ? (
    <AddMissingQrAdjustmentMobile {...{ setValue }} />
  ) : (
    <Grid container columns={12} spacing={3}>
      {/* back button */}
      <Grid item xs={12}>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
          <BackButtonHub onClick={handleBackButton} />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{ color: '#9D9D9D', fontSize: '14px', fontWeight: 600 }}
            >
              {idAudit} Details
            </Typography>
            <ChevronRightIcon
              style={{ color: '#9D9D9D', fontSize: '14px', fontWeight: 600 }}
            />
            <Typography
              sx={{ color: '#000', fontSize: '14px', fontWeight: 600 }}
            >
              Add Adjustment
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography
          sx={{ fontSize: '16px', fontWeight: 500, color: '#9E9E9E' }}
        >
          Location
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '54px',
            background: '#FAFAFA',
            padding: '0px 16px',
            borderRadius: '8px',
            mt: 1,
          }}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}>
            {locationHub}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography
          sx={{ fontSize: '16px', fontWeight: 500, color: '#9E9E9E' }}
        >
          Condition
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '54px',
            background: '#FAFAFA',
            padding: '0px 16px',
            borderRadius: '8px',
            mt: 1,
          }}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}>
            Missing
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography
          sx={{ fontSize: '16px', fontWeight: 500, color: '#9E9E9E', mb: 1 }}
        >
          Notes
        </Typography>
        <CustomInput
          label={''}
          variant={'filled'}
          fullWidth
          sx={{
            bgcolor: '#fafafa',
            '& .MuiOutlinedInput-input': {
              padding: '0px 16px',
              borderRadius: '10px',
              '&::placeholder': {
                color: 'red',
                fontSize: '12px',
              },
            },
            borderRadius: '5px',
          }}
          value={notes}
          onChange={(e) => {
            if (e.target.value.length <= 100) {
              setNotes(e.target.value);
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              mt: '5px',
              color: '#9E9D9D',
            }}
          >
            {notes.length > 5 ? '' : 'Enter at least 5 characters'}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              mt: '5px',
              ml: '15px',
              color: '#9E9D9D',
              fontWeight: 400,
            }}
          >
            {notes.length}/100
          </Typography>
        </Box>
      </Grid>

      {/* item */}
      <Grid item xs={12} sx={{ mt: 2, marginBottom: '116px' }}>
        <CustomCard>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '15px',
              boxShadow: '0px 1px 5px #ccc',
            }}
          >
            <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
              Item(s)
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                color: '#51B15C',
                gap: 1,
                cursor: 'pointer',
              }}
              onClick={handleAddItem}
            >
              <AddIcon />
              Add more
            </Typography>
          </Box>

          <Box>
            <Grid container columns={12} spacing={2} sx={{ p: '15px 10px' }}>
              {tambahItem.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Grid container columns={12} spacing={2}>
                    {/* product */}

                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          color: '#000',
                          mb: 1,
                        }}
                      >
                        Product
                      </Typography>
                      <CustomSelectProduct
                        newData={newData}
                        defaultValue={item}
                        handleValue={(dataUpdate) => {
                          const memo = tambahItem.map((item, idx) => {
                            if (index === idx) {
                              return {
                                ...item,
                                ...dataUpdate,
                              };
                            } else {
                              return item;
                            }
                          });
                          setTambahItem(memo);
                          dispatch(goToAdjustMissingQr(memo));
                        }}
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          color: '#000',
                          mb: 1,
                        }}
                      >
                        QrCode
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexFlow: 'column',
                          justifyContent: 'center',
                          gap: 1,
                          height: '58px',
                          borderRadius: '8px',
                        }}
                      >
                        <QRCode
                          size={40}
                          style={{
                            height: '40px',
                            maxWidth: '40px',
                            width: '100%',
                            borderRadius: '4px',
                          }}
                          value={item?.encript}
                          viewBox={`0 0 40 40`}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          mb: 1,
                          fontWeight: 500,
                          color: '#000',
                        }}
                      >
                        Rack
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexFlow: 'column',
                          justifyContent: 'center',
                          gap: 1,
                          height: '58px',
                          background: '#FAFAFA',
                          padding: '0px 16px',
                          borderRadius: '8px',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            color: '#000',
                          }}
                        >
                          {item?.rackName || '-'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          mb: 1,
                          fontWeight: 500,
                          color: '#000',
                        }}
                      >
                        Missing
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexFlow: 'column',
                          justifyContent: 'center',
                          gap: 1,
                          height: '58px',
                          background: '#FAFAFA',
                          padding: '0px 16px',
                          borderRadius: '8px',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            color: '#000',
                          }}
                        >
                          {item?.qty || 0}{' '}
                          {item?.systemUnit || item?.unit || 'unit'}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* hapus item */}
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        // mt: 4,
                        color: 'red',
                      }}
                    >
                      {tambahItem.length === 1 ? (
                        <></>
                      ) : (
                        <CloseIcons
                          sx={{
                            cursor: 'pointer',
                            width: '20px',
                          }}
                          onClick={() => {
                            const newItem = [...temporaryData];
                            newItem.splice(index, 1);
                            dispatch(goToAdjustMissingQr(newItem));
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CustomCard>
      </Grid>
      <BottomMenu
        handleGreen={handleSubmit}
        disableGreen={!allowSubmit}
        isGreen={true}
        greenText={
          isLoadingSubmit ? (
            <CircularProgress size={20} sx={{ color: '#FFFFFF' }} />
          ) : (
            'Submit'
          )
        }
      />
    </Grid>
  );
};
export default AddMissingQrAdjustment;
