import { Box, Grid, Typography } from '@mui/material';
import {
  ArrowBackIosIcon,
  ChevronRightIcon,
  QrCodeScannerRoundedIcon,
} from '../../../../../../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePage,
  setNotifPurchase,
} from '../../../../../../../store/Routing/purchaseRouting';
import {
  getAllAdminFinishScan,
  getRackIdFinishScanAdmin,
} from '../../../../../../../store/purchase/purchaseAdmin/purchaseFinishAdmin';
import { useState } from 'react';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import FinishScannedDataAdmin from './FinishScannedDataAdmin';
import FinishUnscannedDataAdmin from './FinishUnscannedDataAdmin';
import { updateSentPurchaseSupplier } from '../../../../../../../api/rack';
import { getIdPurchaseDetail } from '../../../../../../../store/purchase/detailPurchase';

const PurchaseFinishScanMobile = () => {
  const dispatch = useDispatch();
  const rackId = useSelector(getRackIdFinishScanAdmin);
  const { isExist, scanned, idScanned, allData, detail, dataRackDetail } =
    useSelector(getAllAdminFinishScan);
  const [isScanned, setIsScanned] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const idPurchase = useSelector(getIdPurchaseDetail);

  const handleToScanMore = () => {
    dispatch(changePage('purchaseProsesScanFinishMobile'));
  };

  const fetchUpdatePurchase = async (data) => {
    setIsLoading(true);
    try {
      await updateSentPurchaseSupplier(data);
      dispatch(
        setNotifPurchase({
          key: 'purchaseProcess',
          text: 'purchase has been finished',
        })
      );
      dispatch(changePage('detail'));
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = () => {
    const collect = {
      rackPositionId: rackId,
      qrCodeId: idScanned,
      purchaseId: idPurchase,
      status: 'finish',
    };

    fetchUpdatePurchase(collect);
  };

  return (
    <Box>
      {/* back button */}
      <Box
        sx={{ py: '17px', display: 'flex', gap: 1, alignItems: 'center' }}
        onClick={() => dispatch(changePage('detail'))}
      >
        <Box
          sx={{
            width: '24px',
            height: '24px',
            bgcolor: '#51B15C0D',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: '14px', color: '#51B15C' }} />
        </Box>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#9E9D9D',
                display: 'flex',
                alignItems: 'center',
                p: 0,
              }}
            >
              Purchase Details <ChevronRightIcon />
            </Typography>
            <Typography sx={{ fontSize: '14px', color: '#000000', p: 0 }}>
              Finish
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: 600,
                color: '#9E9D9D',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {dataRackDetail?.namaHub}
              <ChevronRightIcon sx={{ width: '12px' }} />
            </Typography>

            {dataRackDetail?.namaSubHub !== null && (
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 600,
                  color: '#9E9D9D',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {dataRackDetail?.namaSubHub}
                <ChevronRightIcon sx={{ width: '12px' }} />
              </Typography>
            )}

            {dataRackDetail?.namaMiniHub !== null && (
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 600,
                  color: '#9E9D9D',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {dataRackDetail?.namaMiniHub}
                <ChevronRightIcon sx={{ width: '12px' }} />
              </Typography>
            )}

            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: 600,
                color: '#9E9D9D',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {dataRackDetail?.namaRack}
              <ChevronRightIcon sx={{ width: '12px' }} />
            </Typography>

            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: 600,
                color: '#9E9D9D',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {String.fromCharCode(Number(dataRackDetail?.posisiX) + 64)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Grid
        container
        columns={2}
        columnSpacing={0.5}
        sx={{
          mt: '16px',
          p: '4px',
          bgcolor: '#F5F5F5',
          borderRadius: '8px',
        }}
      >
        {/* scanned */}
        <Grid item xs={1}>
          <Box
            sx={{
              py: '8px',
              borderRadius: '8px',
              bgcolor: isScanned ? 'white' : '#F5F5F5',
            }}
            onClick={() => setIsScanned(true)}
          >
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: isScanned ? '600' : '500',
                color: isScanned ? '#51B15C' : 'black',
              }}
            >
              Scanned ({scanned.length})
            </Typography>
          </Box>
        </Grid>

        {/* unscanned */}
        <Grid item xs={1}>
          <Box
            sx={{
              py: '8px',
              borderRadius: '8px',
              bgcolor: !isScanned ? 'white' : '#F5F5F5',
            }}
            onClick={() => setIsScanned(false)}
          >
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: !isScanned ? '600' : '500',
                color: !isScanned ? '#51B15C' : 'black',
              }}
            >
              Unscanned (
              {
                allData.filter((list) => !idScanned.includes(list.encript))
                  .length
              }
              )
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* view result scanned & unscanned */}
      <Box sx={{ minHeight: '70vh', bgcolor: 'white' }}>
        {isScanned ? <FinishScannedDataAdmin /> : <FinishUnscannedDataAdmin />}
      </Box>

      {/* button */}

      {scanned.length === 0 ? (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            bgcolor: 'white',
            p: '20px',
            width: '100%',
            display: 'flex',
            gap: 0.5,
          }}
        >
          <CustomButton
            variant="contained"
            sx={{ width: '100%' }}
            onClick={() => handleToScanMore()}
          >
            <QrCodeScannerRoundedIcon
              sx={{ color: '#FFFFFF', marginRight: '8px' }}
            />
            Scan Product
          </CustomButton>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            bgcolor: 'white',
            p: '20px',
            width: '100%',
            display: 'flex',
            gap: 0.5,
          }}
        >
          <CustomButton
            variant="contained"
            sx={{ width: '100%' }}
            onClick={() => handleSend()}
            disabled={
              allData.filter((list) => !idScanned.includes(list.encript))
                .length !== 0
            }
          >
            Locate
          </CustomButton>
          <Box
            sx={{
              border: 2,
              borderRadius: '8px',
              borderColor: '#51B15C',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => handleToScanMore()}
          >
            <QrCodeScannerRoundedIcon sx={{ color: '#51B15C' }} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default PurchaseFinishScanMobile;
