import { Box, Divider, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { changePage } from '../../../../../../../store/Routing/purchaseRouting';
import {
  ArrowBackIosIcon,
  DataNotFound,
  FlashIcon,
  ScanTargetIcon,
} from '../../../../../../../assets/Icons';
import CustomBottomDrawer from '../../../../../../../components/shared/CustomBottomDrawer';
import {
  LoadingComponent,
  ScanQrTool,
} from '../../../../../../../components/shared/shared2';
import useWindowDimensions from '../../../../../../../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import {
  changeScanRackIdAdmin,
  loadSpesifikDataQrRack,
} from '../../../../../../../store/purchase/purchaseAdmin/purchaseFinishAdmin';
import { getSpesifikDimensionRack } from '../../../../../../../api/rack';

const ScanRackPurchaseMobile = () => {
  const dispatch = useDispatch();
  const { width: widthScreen } = useWindowDimensions();
  const [isQrNotExist, setIsQrNotExist] = useState(false);
  const [isLoadingScan, setIsLoadingScan] = useState(false);

  const getSpesifikRackDimension = async (qr) => {
    let params = {};
    params.qrCode = qr;
    try {
      const {
        data: { data },
      } = await getSpesifikDimensionRack(params);
      dispatch(changePage('purchaseFinishScanAdminMobile'));
      dispatch(loadSpesifikDataQrRack(data));
      changeScanRackIdAdmin(qr);
    } catch (err) {
      console.error(err);
      setIsQrNotExist(true);
    }
  };

  return (
    <Box>
      {/* back button */}
      <Box
        sx={{ py: '17px', display: 'flex', gap: 1, alignItems: 'center' }}
        onClick={() => dispatch(changePage('detail'))}
      >
        <Box
          sx={{
            width: '24px',
            height: '24px',
            bgcolor: '#51B15C0D',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: '14px', color: '#51B15C' }} />
        </Box>
        <Typography sx={{ fontWeight: '600' }}>Scan Rack</Typography>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          minHeight: '80vh',
          width: '100%',
          left: 0,
          bgcolor: 'black',
        }}
      >
        <Box sx={{ width: '100%', position: 'realtive' }}>
          {isLoadingScan && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                zIndex: 10,
                width: widthScreen,
                height: '600px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'white',
                opacity: 0.5,
              }}
            >
              <LoadingComponent />
            </Box>
          )}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ScanTargetIcon />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '315px',
                height: '370px',
                borderRadius: '25px',
                bgcolor: 'white',
                opacity: 0.2,
              }}
            ></Box>
          </Box>
          <ScanQrTool
            width={widthScreen}
            height={600}
            onChange={(value) => {
              dispatch(changeScanRackIdAdmin(value));
              getSpesifikRackDimension(value);
            }}
            delay={1000}
          />
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 20,
          left: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '42px',
            height: '42px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            bgcolor: 'white',
          }}
          // onClick={handleTorchlight}
        >
          <FlashIcon />
        </Box>
      </Box>

      <CustomBottomDrawer
        open={isQrNotExist}
        title={'Opps'}
        handleClose={() => setIsQrNotExist(false)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <Box
            sx={{
              width: '45px',
              height: '45px',
              bgcolor: '#F33A3A1A',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DataNotFound />
          </Box>
          <Typography>Rack not found.</Typography>
        </Box>
        <Divider sx={{ my: '20px' }} />
        <Box></Box>
      </CustomBottomDrawer>
    </Box>
  );
};
export default ScanRackPurchaseMobile;
