import axios from 'axios';
import { alereAxios } from '../utils/api';
import { changeIsLoadingMasterHub } from '../store/hub/masterHub/masterHubEdit';

export const uploadImage = (data) => {
  const accessToken = localStorage.getItem('accessToken');

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/storages/images`,
    data: data,
    headers: {
      Access: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const uploadVideo = (data) => {
  const accessToken = localStorage.getItem('accessToken');

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/storages/videos`,
    data: data,
    headers: {
      Access: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const deleteFile = (file) =>
  alereAxios.delete('storages/files', {
    params: {
      path: file,
    },
  });
