import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import {
  AddIcon,
  AlertCircle,
  CancelIcon,
  CloseIcon,
  ErrorOutlineIcon,
  ImageOutlinedIcon,
  KeyboardArrowDownIcon,
  PreviewIcon,
  SearchIcon,
  VideocamOutlinedIcon,
} from '../../../../../../../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePageSubHub,
  setNotifMasterHub,
} from '../../../../../../../../store/Routing/hubs';
import { useTheme } from '@emotion/react';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import CustomAntSwitch from '../../../../../../../../components/shared/CustomAntSwitch';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import { useState } from 'react';
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from '../../../../../../../../store/subsidiary/subsidiary';
import {
  changeImagePreview,
  changePicChoose,
  changePicSearch,
  changePicSearchDebounce,
  changeStatus,
  changeSubHubName,
  changeVideoPreview,
  clearData,
  clickIsPreviewImage,
  clickIsVideoPreview,
  deleteImages,
  deleteTayangan,
  getSubHubAddAllState,
  inputImages,
  inputTayangan,
} from '../../../../../../../../store/hub/subHub/subHubAdd';
import debounce from 'lodash.debounce';
import {
  fetchPic,
  getPicList,
  getPicLoading,
} from '../../../../../../../../store/pic/pic';
import { Map } from 'pigeon-maps';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import AddressSubHubMobile from './AddressSubHubMobile';
import { getIdToko } from '../../../../../../../../store/hub/subHub/subHub';
import { addNewSubHub } from '../../../../../../../../api/hubs';

const AddSubHub = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const listSubsidiary = useSelector(getSubsidiaryList);
  const [newSubsidiary, setNewSubsidiary] = useState(null);

  const {
    subsidiaryId,
    name,
    picChoose,
    picSearchDebounce,
    picPhone,
    picSearch,
    image,
    isMaxSizeImage,
    isUploadImage,
    isImagePreview,
    imagePreview,
    video,
    isMaxSizeVideo,
    isUploadVideo,
    isVideoPreview,
    videoPreview,
    addressFix,
    mapInputType,
    placeGetByName,
    placeGetByCoordinate,
    address,
    status,
    coordinate,
  } = useSelector(getSubHubAddAllState);
  const picList = useSelector(getPicList);
  const picLoading = useSelector(getPicLoading);
  const idToko = useSelector(getIdToko);

  // !--------------------- Map Address ---------------------------
  const [isMapOpen, setIsMapOpen] = useState(false);

  const heandSubHub = () => {
    dispatch(changePageSubHub('subHub'));
  };

  // ! subsidiary
  useEffect(() => {
    dispatch(fetchSubsidiaryList());
  }, []);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounce(value));
    }, 1000)
  ).current;

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  // heandle save

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const heandleSave = () => {
    const params = {};
    params.subsidiaryId = newSubsidiary?.id;
    params.name = name;
    params.idToko = idToko;
    params.idKustomer = picChoose.idKustomer;
    params.status = status ? 'active' : 'inactive';
    params.address = addressFix === '' ? address : addressFix;
    params.nohp = picChoose?.telpon;
    params.lat = coordinate[0];
    params.lon = coordinate[1];
    params.typeInput = mapInputType === 'manual' ? 'manual' : 'map';

    if (image.length !== 0) {
      params['images'] = image.join(',');
    }
    if (video.length !== 0) {
      params['videos'] = video.join(',');
    }

    if (mapInputType === 'manual') {
      const { formatted: citydistric } = placeGetByName;
      params['cityDistrict'] = citydistric;
    } else {
      if (!placeGetByCoordinate?.statusCode) {
        const { formatted: citydistric } = placeGetByCoordinate;
        params['cityDistrict'] = citydistric;
      }
    }

    const createHub = async () => {
      setIsLoadingSubmit(true);
      try {
        await addNewSubHub(params);
        dispatch(clearData());
        dispatch(changePageSubHub('subHub'));
        dispatch(
          setNotifMasterHub({
            key: 'addSubHub',
            text: 'sub hub success added',
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingSubmit(false);
      }
    };

    createHub();
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid container columns={12} spacing={3} sx={{ mt: '0px' }}>
        <Grid item xs={8}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <BackButtonHub onClick={heandSubHub} />
            <Box>
              <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                Add SubHub
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            gap: 1,
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <PreviewIcon sx={{ width: '18.33px', height: '12.5px' }} />
          <Box>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 600, color: '#51B15C' }}
            >
              Preview
            </Typography>
          </Box>
        </Grid>

        {/* subsidiary */}
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={newSubsidiary}
            options={listSubsidiary}
            getOptionLabel={(option) => option.name}
            onChange={(_, newValue) => setNewSubsidiary(newValue)}
            sx={{ width: '100%', bgcolor: '#FAFAFA' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Subsidiary"
                sx={{ width: 342, color: '#9E9E9E' }}
              />
            )}
          />
        </Grid>

        {/* Name */}
        <Grid item xs={12}>
          <CustomInput
            label="Name*"
            variant="filled"
            sx={{ width: '100%', bgcolor: '#FAFAFA', fontSize: '14px' }}
            placeholder="Enter hub name"
            value={name}
            onChange={(e) => {
              if (e.target.value.length <= 30) {
                dispatch(changeSubHubName(e.target.value));
              }
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: '400',
                color:
                  name.length > 0 && name.length < 5 ? '#F33A3A' : '#9E9D9D',
                mt: '4px',
              }}
            >
              Enter at least 5 character
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: '400',
                color: '#9E9D9D',
                mt: '4px',
              }}
            >
              {name.length}/30
            </Typography>
          </Box>
        </Grid>

        {/*  Image */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              pb: `${image.length === 0 ? '16px' : '9px'}`,
            }}
          >
            <Typography
              sx={{ fontSize: '14px', fontWeight: 400, color: '#000' }}
            >
              Photo*
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: '#9E9D9D',
              }}
            >
              ( max size : 2MB, max file : 3 )
            </Typography>
            {isMaxSizeImage && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: theme.palette.warning.main,
                    mr: '4px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '10px',
                    fontWeight: 500,
                    color: theme.palette.warning.main,
                  }}
                >
                  The photo can't be more than 2MB
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {image.length === 0 && (
                <Box
                  sx={{
                    height: '46px',
                    width: '46px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    bgcolor: '#F5F5F5',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderStyle: 'dashed',
                    borderColor: '#9E9D9D',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('input_img').click();
                  }}
                >
                  <ImageOutlinedIcon
                    sx={{ color: '#9E9D9D', fontSize: '28px' }}
                  />
                </Box>
              )}
              {image.map((input, index) => (
                <Box
                  sx={{
                    position: 'relative',
                    overflow: 'visible',
                    padding: '7px',
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: 'absolute',
                      fontSize: '20px',
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: 'pointer',
                    }}
                    onClick={() => dispatch(deleteImages(input))}
                  />
                  <Box
                    component="img"
                    src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + input}
                    sx={{
                      height: '46px',
                      width: '46px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      dispatch(clickIsPreviewImage());
                      dispatch(changeImagePreview(input));
                    }}
                  />
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                mb: '7px',
                ml: '20px',
                alignItems: 'center',
              }}
            >
              {image.length < 3 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('input_img').click();
                  }}
                >
                  <input
                    accept="image/*"
                    id="input_img"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => dispatch(inputImages(e))}
                  />
                  {isUploadImage ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <CircularProgress size={18} />
                      <Typography> uploading...</Typography>
                    </Box>
                  ) : (
                    <>
                      <AddIcon />
                      <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                        Add More
                      </Typography>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        {/*  Video */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              pb: `${video.length === 0 ? '16px' : '9px'}`,
            }}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
              Video
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: '#9E9D9D',
              }}
            >
              ( max size : 100MB , max file : 3 )
            </Typography>
            {isMaxSizeVideo && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: theme.palette.warning.main,
                    mr: '4px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '8px',
                    fontWeight: '500',
                    color: theme.palette.warning.main,
                  }}
                >
                  The video is too big
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {video.length === 0 && (
                <Box
                  sx={{
                    height: '46px',
                    width: '46px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    bgcolor: '#F5F5F5',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderStyle: 'dashed',
                    borderColor: '#9E9D9D',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('inputMenu_video').click();
                  }}
                >
                  <VideocamOutlinedIcon
                    sx={{ color: '#9E9D9D', fontSize: '28px' }}
                  />
                </Box>
              )}
              {video.map((input, index) => (
                <Box
                  sx={{
                    position: 'relative',
                    overflow: 'visible',
                    padding: '7px',
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: 'absolute',
                      fontSize: '20px',
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: 'pointer',
                    }}
                    onClick={() => dispatch(deleteTayangan(input))}
                  />
                  <Box
                    onClick={() => {
                      dispatch(clickIsVideoPreview());
                      dispatch(changeVideoPreview(input));
                    }}
                  >
                    <video
                      style={{
                        maxHeight: '46px',
                        maxWidth: '46px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        backgroundColor: 'black',
                      }}
                    >
                      <source
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                          '/' +
                          input +
                          '#t=0.5'
                        }
                      />
                    </video>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                mb: '7px',
                ml: '20px',
                alignItems: 'center',
              }}
            >
              {video.length < 3 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('inputMenu_video').click();
                  }}
                >
                  <input
                    // accept="video/*"
                    id="inputMenu_video"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => dispatch(inputTayangan(e))}
                  />
                  {isUploadVideo ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <CircularProgress size={18} />
                      <Typography> uploading...</Typography>
                    </Box>
                  ) : (
                    <>
                      <AddIcon />
                      <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                        Add More
                      </Typography>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        {/*  Pic */}
        <Grid item xs={12}>
          <Autocomplete
            options={picList}
            getOptionLabel={(option) => option.namaLengkap}
            value={picChoose}
            onChange={(_, newValue) => {
              dispatch(changePicChoose(newValue));
            }}
            inputValue={picSearch}
            loading={picLoading}
            onInputChange={(_, newInputValue) => {
              dispatch(changePicSearch(newInputValue));
              picSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idKustomer} {...props}>
                {option.namaLengkap}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="PIC*"
                variant="filled"
                sx={{ width: '100%', bgcolor: '#FAFAFA', m: 0, p: 0 }}
              />
            )}
          />
        </Grid>

        {/* Phone Number */}
        <Grid item xs={12}>
          <Box
            sx={{
              background: '#F0F0F0',
              borderRadius: '8px 8px 0px 0px',
              padding: '16px 12px 14px 14px',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                marginBottom: '8px',
                color: '#9E9E9E',
              }}
            >
              Phone Number
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
            >
              {picChoose?.telpon || '-'}
            </Typography>
          </Box>
        </Grid>

        {/* Address */}
        <Grid item xs={12}>
          <CustomCard
            sx={{ p: '8px 16px', cursor: 'pointer' }}
            onClick={() => setIsMapOpen(!isMapOpen)}
          >
            <Box
              sx={{
                display: 'center',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    width: '66px',
                    height: '66px',
                    bgcolor: 'gray',
                    borderRadius: '8px',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      bgcolor: 'white',
                      width: '100%',
                      height: '100%',
                      zIndex: '+2',
                      opacity: 0.5,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      maxHeight: '66px',
                      overflow: 'hidden',
                      borderRadius: '8px',
                    }}
                  >
                    <Map
                      defaultCenter={[3.546958571415798, 98.6901696840363]}
                      height={130}
                      defaultZoom={17}
                      width={66}
                      style={{
                        borderRadius: '8px',
                      }}
                    ></Map>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      mb: '4px',
                      ml: '8px',
                    }}
                  >
                    Address
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.4,
                    }}
                  >
                    <LocationOnIcon color="primary" />
                    <Typography>
                      {addressFix ? addressFix : 'Click to enter address'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>

        {/* Button switch */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              justifyContent: 'space-between',
              mb: 3,
              mt: '8px',
            }}
          >
            <Typography
              sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
            >
              Publish
            </Typography>
            <CustomAntSwitch
              value={status}
              onChange={() => dispatch(changeStatus())}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Butto Submit */}
      <Box
        sx={{
          position: 'sticky',
          zIndex: 99,
          bottom: 0,
          width: '100%',
        }}
      >
        <CustomButton
          variant="contained"
          sx={{
            width: '100%',
            height: '36px',
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontSize: '12px',
              fontWeight: '500',
            }}
            onClick={heandleSave}
          >
            {isLoadingSubmit ? (
              <CircularProgress size={18} sx={{ color: '#FFF' }} />
            ) : (
              'Submits'
            )}
          </Typography>
        </CustomButton>
      </Box>

      {/* dialogs */}

      {/* image */}
      <Dialog
        open={isImagePreview}
        onClose={() => {
          dispatch(clickIsPreviewImage());
          dispatch(changeImagePreview(''));
        }}
      >
        <Box
          component="img"
          src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + imagePreview}
          sx={{ maxWidth: '300px', maxHeight: '300px' }}
        />
      </Dialog>

      {/* Video  */}
      <Dialog
        open={isVideoPreview}
        onClose={() => {
          dispatch(clickIsVideoPreview());
          dispatch(changeVideoPreview(''));
        }}
      >
        <Box sx={{ bgcolor: 'black' }}>
          <video
            style={{
              maxWidth: '300px',
              maxHeight: '300px',
              backgroundColor: 'black',
            }}
            controls
          >
            <source
              src={
                process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + videoPreview
              }
            />
          </video>
        </Box>
      </Dialog>

      {/*  Address */}
      <Dialog
        open={isMapOpen}
        onClose={() => {
          setIsMapOpen(!isMapOpen);
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>Input Address</Box>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsMapOpen(false)}
          />
        </DialogTitle>
        <DialogContent dividers>
          <AddressSubHubMobile setIsOpen={setIsMapOpen} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddSubHub;
