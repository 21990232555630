import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { Box, AppBar, Toolbar, useTheme, Typography } from "@mui/material";
import HeaderComponent from "../../../../components/shared/HeaderComponent";
import TabMenu from "../../../../components/shared/TabMenu";
import MasterHub from "./Hub/MasterHub/Desktop";
import InventoryDashboard from "../../CurrentAssets/Inventory/Dashboard";
import InventoryProduct from "../../CurrentAssets/Inventory/Product";
import InventoryMenu from "../../CurrentAssets/Inventory/Menu";

import dayjs from "dayjs";
import { fetchTreatmentGraph } from "../../../../api/treatment";
import DetailMasterHub from "./Hub/MasterHub/Desktop/DetailMasterHub";
import { getAllHubs, getAllSubHub, getAllMiniHub } from "../../../../api/hubs";
import AddMasterHub from "./Hub/MasterHub/Desktop/AddMasterHub";
import CreateMap from "./Hub/MasterHub/Desktop/CreateMap";
import SubHub from "./Hub/SubHub";
import DetailSubHub from "./Hub/SubHub/DetailSubHub";
import AddNewSubHub from "./Hub/SubHub/AddNewSubHub";
import MiniHub from "./Hub/MiniHub";
import MiniHubDetail from "./Hub/MiniHub/MiniHubDetail";
import AddNewMiniHub from "./Hub/MiniHub/AddNewMiniHub";
import debounce from "lodash.debounce";
import { getSubsidiary } from "../../../../api/subsidiary";
import MobileHeaderComponent from "../../../MobileHeaderComponent";
import MasterHubRacks from "./Hub/MasterHub/Desktop/MasterHubRacks";
import MasterHubRackDetails from "./Hub/MasterHub/Desktop/MasterHubDetails";
import InventoryPurchase from "./Purchase";
import DetailsMasterHub from "./Hub/MasterHub/Desktop/Details/DetailsMasterHub";
import MasterHubAdd from "../../../../pages/Assets/CurrentAssets/Inventory/Hub/MasterHub/Desktop/Add/MasterHubAdd";
import { useSelector } from "react-redux";
// import {
//   getIsAsc,
//   getSortTable,
// } from '../../../../store/hub/masterHub/masterHubListHub';
import AddSubHub from "./Hub/SubHub/AddSubHub";
import AddMiniHub from "./Hub/MiniHub/AddMiniHub";
import DetailsSubHub from "./Hub/SubHub/DetailsSubHub";
import DetailsMiniHub from "./Hub/MiniHub/DetailsMiniHub";
import { getNotifHub } from "../../../../store/hub/notifHub";
import PurchaseRequest from "./Purchase/Request/PurchaseRequest";
import AddRequestPurchase from "./Purchase/Request/Add/AddRequestPurchase";
import PurchaseRequestDetails from "./Purchase/Request/PurchaseRequestDetails/PurchaseDetails";
import { getAllRackhub } from "../../../../store/racks/rackHub";
import RackHub from "./Racks/RackHub";
import RoutingPurchase from "./Purchase/Routing/RoutingPurchase";
import RoutingStock from "./Stock/Routing/RoutingStock";
import RoutingTransaction from "./Transaction/Routing/RoutingTransaction";
import RoutingAudit from "./Audit/Routing/RoutingAudit";
import RoutingAdjustment from "./Adjustment/Routing/RoutingAdjustment";
import RoutingHub from "./Hub/Routing/RoutingHub";

const drawerWidth = "13rem";
const colors = ["#C85178", "#4994B7", "#812089", "#468F36", "#E19F4F"];

const labelTabsArray = [
  {
    to: "/hub",
    label: "Hub",
  },
  {
    to: "/product",
    label: "Product",
  },
  {
    to: "/menu",
    label: "Menu",
  },
  // {
  //   to: '/assets/inventory/hub',
  //   label: 'Hub',
  // },
  // {
  //   to: '/product',
  //   label: 'Product',
  // },
  // {
  //   to: '/menu',
  //   label: 'Menu',
  // },
  {
    to: "/purchases",
    label: "Purchases",
  },
  {
    to: "/stock",
    label: "Stock",
  },
  {
    to: "/transaction",
    label: "Transaction",
  },
  {
    to: "/audit",
    label: "Audit",
  },
  {
    to: "adjustment",
    label: "Adjustment",
  },
];

export const TabPanel = (props) => {
  const { children, value, index, sx, ...other } = props;

  return (
    <Box hidden={value !== index} sx={{ ...sx }} {...other}>
      {value === index && children}
    </Box>
  );
};

const Inventory = ({ matchTablet }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const [value, setValue] = useState(parseInt(searchParams.get("tab")) || 0);
  const theme = useTheme();

  const [treatments, setTreatments] = useState([]);
  const [treatmentsStartDate, setTreatmentsStartDate] = useState(
    dayjs().subtract(6, "day")
  );
  const [treatmentEndDate, setTreatmentEndDate] = useState(dayjs());
  const [treatmentsTimeframe, setTreatmentsTimeframe] = useState("daily");
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("sales");
  const [
    barChartShownTreatmentAttributes,
    setBarChartShownTreatmentAttributes,
  ] = useState([0, 1]);
  const [
    treatmentPerformanceBarChartData,
    setTreatmentPerformanceBarChartData,
  ] = useState({
    datasets: [],
  });

  useEffect(() => {
    const fetchLineChart = async () => {
      try {
        const {
          data: { data: fetchedTreatmentGraphs },
        } = await fetchTreatmentGraph({
          startDate: treatmentsStartDate.format("YYYY-MM-DD"),
          endDate: treatmentEndDate.format("YYYY-MM-DD"),
          timeframe: treatmentsTimeframe,
        });
        setTreatments(fetchedTreatmentGraphs);
      } catch (err) {
        console.error(err);
        setTreatments([]);
      }
    };
    fetchLineChart();
  }, [treatmentEndDate, treatmentsStartDate, treatmentsTimeframe]);

  const [treatmentChartType, setTreatmentChartType] = useState("bar");
  const [shownTreatmentAttribute, setShownTreatmentAttribute] =
    useState("sales");
  const [
    treatmentPerformanceLineChartData,
    setTreatmentPerformanceLineChartData,
  ] = useState({
    datasets: [],
  });

  useEffect(() => {
    if (treatmentChartType !== "line") return;

    if (treatments.length > 0) {
      const treatmentPerformanceChartData = {
        labels: treatments[0].details.map((detail) =>
          treatmentsTimeframe === "monthly"
            ? dayjs(detail.dateTime).format("MMM") +
              " " +
              dayjs(detail.dateTime).format("YYYY")
            : treatmentsTimeframe === "daily"
            ? dayjs(detail.dateTime).format("D") +
              " " +
              dayjs(detail.dateTime).format("MMM")
            : dayjs(detail.dateTime).week() +
              " " +
              dayjs(detail.dateTime).format("YYYY")
        ),
        datasets: treatments
          .filter((treatment) => selected.includes(treatment.id))
          .reduce((previousGraphs, currentGraph, index) => {
            const color = colors[index];

            return [
              ...previousGraphs,
              {
                label: currentGraph.name.split(" ")[0],
                data: currentGraph.details.map((detail) => {
                  switch (shownTreatmentAttribute) {
                    case "sales":
                      return detail.earned;
                    case "order":
                      return detail.orderCount;
                    case "used":
                      return detail.usedCount;
                    default:
                      return 0;
                  }
                }),
                backgroundColor: color,
                borderColor: color,
              },
            ];
          }, []),
      };

      setTreatmentPerformanceLineChartData(treatmentPerformanceChartData);
    } else {
      setTreatmentPerformanceLineChartData({ datasets: [] });
    }
  }, [
    selected,
    shownTreatmentAttribute,
    treatmentChartType,
    treatments,
    treatmentsTimeframe,
  ]);

  useEffect(() => {
    if (treatments.length > 0) {
      setSelected(
        treatments
          .sort((treatment1, treatment2) => {
            switch (orderBy) {
              case "sales":
                return order === "asc"
                  ? treatment1.totalEarned - treatment2.totalEarned
                  : treatment2.totalEarned - treatment1.totalEarned;
              case "order":
                return order === "asc"
                  ? treatment1.totalOrderCount - treatment2.totalOrderCount
                  : treatment2.totalOrderCount - treatment1.totalOrderCount;
              case "used":
                return order === "asc"
                  ? treatment1.totalUsedCount - treatment2.totalUsedCount
                  : treatment2.totalUsedCount - treatment1.totalUsedCount;
              case "price":
                return order === "asc"
                  ? treatment1.unitPrice - treatment2.unitPrice
                  : treatment2.unitPrice - treatment1.unitPrice;
              case "name":
                return order === "asc"
                  ? treatment1.name.localeCompare(treatment2.name)
                  : treatment2.name.localeCompare(treatment1.name);
              default:
                return 0;
            }
          })
          .slice(0, searchParams.get("s") || 5)
          .map((treatment) => treatment.id)
      );
    }
  }, [order, orderBy, searchParams, treatments]);

  useEffect(() => {
    if (treatmentChartType !== "bar") return;

    if (treatments.length > 0) {
      const treatmentPerformanceChartData = {
        labels: treatments
          .filter((treatment) => selected.includes(treatment.id))
          .map((treatment) => treatment.name),
        datasets: [
          {
            label: "Sales",
            yAxisID: "y1",
            backgroundColor: theme.palette.primary.main,
            borderRadius: 4,
            type: "bar",
            data: treatments
              .filter((treatment) => selected.includes(treatment.id))
              .map((treatment) => treatment.totalEarned),
            order: 0,
            hidden: !barChartShownTreatmentAttributes.includes(0),
          },
          {
            label: "Order",
            data: treatments
              .filter((treatment) => selected.includes(treatment.id))
              .map((treatment) => treatment.totalOrderCount),
            borderRadius: 4,
            type: "bar",
            yAxisID: "y2",
            backgroundColor: "#FFCF46",
            order: 1,
            hidden: !barChartShownTreatmentAttributes.includes(1),
          },
          {
            label: "Used",
            data: treatments
              .filter((treatment) => selected.includes(treatment.id))
              .map((treatment) => treatment.totalUsedCount),
            type: "line",
            yAxisID: "y3",
            borderColor: "#F28AAE",
            backgroundColor: "#F28AAE",
            order: -1,
            hidden: !barChartShownTreatmentAttributes.includes(2),
          },
        ],
      };

      setTreatmentPerformanceBarChartData(treatmentPerformanceChartData);
    } else {
      setTreatmentPerformanceBarChartData({ datasets: [] });
    }
  }, [
    barChartShownTreatmentAttributes,
    selected,
    theme.palette.primary.main,
    treatmentChartType,
    treatments,
  ]);

  const [subsidiaryDatas, setSubsidiaryDatas] = useState([]);

  useEffect(() => {
    const fetchSubsidiary = async () => {
      try {
        const {
          data: {
            data: { subsidiaries },
          },
        } = await getSubsidiary();
        setSubsidiaryDatas(subsidiaries);
      } catch (err) {
        console.error(err);
      }
    };
    fetchSubsidiary();
  }, []);

  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);

  const [rowSelected, setRowSelected] = useState("");

  const [subHubSelected, setSubHubSelected] = useState(
    parseInt(searchParams.get("subhub-overview")) || null
  );
  const [subHubDetailSelected, setSubHubDetailSelected] = useState(
    parseInt(searchParams.get("subhub-detail")) || null
  );

  const [miniSubSelected, setMiniSubSelected] = useState(
    parseInt(searchParams.get("minihub-overview")) || null
  );

  const [miniHubDetailSelected, setMiniHubDetailSelected] = useState(
    parseInt(searchParams.get("minihub-detail")) || null
  );

  const [isOpenMasterHub, setIsOpenMasterHub] = useState(false);

  //Integration Hub Master List Table
  const [hubsData, setHubsData] = useState([]);
  const rowPerPageValues = [5, 10, 15, 20, 25];
  const [rowPerPageTable, setRowPerPageTable] = useState(rowPerPageValues[0]);
  const [currentPageTable, setCurrentPageTable] = useState(1);
  const [totalTableItems, setTotalTableItems] = useState(1);
  const [isLoadingMasterHub, setIsLoadingMasterHub] = useState(true);
  const [masterHubKeyword, setMasterHubKeyword] = useState("");

  const [sortTableMasterHub, setSortTableMasterHub] = useState("name");
  const [isSortAscMasterHub, setIsSortAscMasterHub] = useState(true);

  const { key: indikator } = useSelector(getNotifHub);

  const fetchMasterListTable = async () => {
    setIsLoadingMasterHub(true);

    let params = {};

    params.page = currentPageTable;
    params.pageSize = rowPerPageTable;
    params.search = masterHubKeyword;
    params.subsidiaryId = filterSubsidiaryArray;

    if (sortTableMasterHub !== 0) {
      params[sortTableMasterHub] = isSortAscMasterHub ? "asc" : "desc";
    }

    try {
      const {
        data: {
          data: { hubs, pageInfo },
        },
      } = await getAllHubs(params);
      setHubsData(hubs);
      setTotalTableItems(pageInfo.total);
    } catch (err) {
      console.error(err);
      setIsLoadingMasterHub(true);
    } finally {
      setIsLoadingMasterHub(false);
    }
  };

  useEffect(() => {
    fetchMasterListTable(
      currentPageTable,
      rowPerPageTable,
      masterHubKeyword,
      filterSubsidiaryArray,
      sortTableMasterHub,
      isSortAscMasterHub
    );
    if (indikator === "addhub") {
      fetchMasterListTable(
        currentPageTable,
        rowPerPageTable,
        masterHubKeyword,
        filterSubsidiaryArray,
        sortTableMasterHub,
        isSortAscMasterHub
      );
    }
  }, [
    currentPageTable,
    filterSubsidiaryArray,
    rowPerPageTable,
    masterHubKeyword,
    sortTableMasterHub,
    isSortAscMasterHub,
    indikator,
  ]);

  const [isOpenCreateMap, setIsOpenCreateMap] = useState(false);
  const [isOpenNewSubHub, setIsOpenNewSubHub] = useState(false);
  const [isOpenAddNewMiniHub, setIsOpenAddNewMiniHub] = useState(false);

  //SubHUb
  const [subHubData, setSubHubData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(5);
  const [totalTableItemsSubHub, setTotalTableItemsSubHub] = useState(1);
  const [currentPageTableSubHub, setCurrentPageTableSubHub] = useState(1);
  const [isLoadingSubHub, setIsLoadingSubHub] = useState(true);
  const [subHubKeyword, setSubHubKeyword] = useState("");

  const [sortTableSubHub, setSortTableSubHub] = useState("name");
  const [isSortAscSubHub, setIsSortAscSubHub] = useState(true);

  const fetchSubHub = async (page, pageSize, idToko, search) => {
    setIsLoadingSubHub(true);
    let params = {
      page,
      pageSize,
      idToko,
      search,
    };

    if (sortTableSubHub !== 0) {
      params[sortTableSubHub] = isSortAscSubHub ? "asc" : "desc";
    }

    try {
      const {
        data: {
          data: { subHubs, pageSize: pageInfo },
        },
      } = await getAllSubHub(params);
      setSubHubData(subHubs);
      setTotalTableItemsSubHub(pageInfo.total);
    } catch (err) {
      console.error(err);
      setIsLoadingSubHub(true);
    } finally {
      setIsLoadingSubHub(false);
    }
  };

  //Mini Hub
  const [miniHub, setMiniHub] = useState([]);
  const [rowPerPageMiniHub, setRowPerPageMiniHub] = useState(5);
  const [totalTableItemsMiniHub, setTotalTableItemsMiniHub] = useState(1);
  const [currentPageTableMiniHub, setCurrentPageTableMiniHub] = useState(1);
  const [isLoadingMiniHub, setIsLoadingMiniHub] = useState(true);
  const [miniHubKeyword, setMiniHubKeyword] = useState("");

  const [sortTableMiniHub, setSortTableMiniHub] = useState("name");
  const [isSortAscMiniHub, setIsSortAscMiniHub] = useState(true);

  const fetchMiniSubHub = async (page, pageSize, subHubId, search) => {
    let params = {
      page,
      pageSize,
      subHubId,
      search,
    };

    if (sortTableMiniHub !== 0) {
      params[sortTableMiniHub] = isSortAscMiniHub ? "asc" : "desc";
    }

    try {
      setIsLoadingMiniHub(true);
      const {
        data: {
          data: { pageSize: pageInfo, subHubs },
        },
      } = await getAllMiniHub(params);
      setMiniHub(subHubs);
      setTotalTableItemsMiniHub(pageInfo.total);
    } catch (err) {
      console.error(err);
      setIsLoadingMiniHub(true);
    } finally {
      setIsLoadingMiniHub(false);
    }
  };

  //Master Hub Rack
  const [masterHubIdRack, setMasterHubIdRack] = useState(
    parseInt(searchParams.get("masterhub-idrack")) || null
  );

  const [masterHubRackSelect, setMasterHubRackSelect] = useState("daily");
  const [saveDateRangeMasterHubRack, setSaveDateRangeMasterHubRack] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(1, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [dateRangeMasterHubRack, setDateRangeMasterHubRack] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(1, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [masterHubRack, setMasterHubRack] = useState([]);
  const [rowPerPageMasterHubRack, setRowPerPageMasterHubRack] = useState(5);
  const [totalTableItemsMasterHubRack, setTotalTableItemsMasterHubRack] =
    useState(1);
  const [currentPageTableMasterHubRack, setCurrentPageTableMasterHubRack] =
    useState(1);
  const [isLoadingMasterHubRack, setIsLoadingMasterHubRack] = useState(true);
  const [masterHubRackKeyword, setMasterHubRackKeyword] = useState("");

  const [sortTableMasterHubRack, setSortTableMasterHubRack] = useState("code");
  const [isSortAscMasterHubRack, setIsSortAscMasterHubRack] = useState(true);

  //masterHub Detail
  const [masterHubIdRackDetail, setMasterHubIdRackDetail] = useState(
    parseInt(searchParams.get("masterhub-idrack-detail")) || null
  );

  const [masterHubRackDetailSelect, setMasterHubRackDetailSelect] =
    useState("daily");
  const [
    saveDateRangeMasterHubRackDetail,
    setSaveDateRangeMasterHubRackDetail,
  ] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(1, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [dateRangeMasterHubRackDetail, setDateRangeMasterHubRackDetail] =
    useState([
      {
        startDate: new Date(dayjs(new Date()).subtract(1, "d")),
        endDate: new Date(dayjs(new Date())),
        key: "selection",
      },
    ]);

  const [masterHubRackDetail, setMasterHubRackDetail] = useState([]);
  const [rowPerPageMasterHubRackDetail, setRowPerPageMasterHubRackDetail] =
    useState(5);
  const [
    totalTableItemsMasterHubRackDetail,
    setTotalTableItemsMasterHubRackDetail,
  ] = useState(1);
  const [
    currentPageTableMasterHubRackDetail,
    setCurrentPageTableMasterHubRackDetail,
  ] = useState(1);
  const [isLoadingMasterHubRackDetail, setIsLoadingMasterHubRackDetail] =
    useState(true);
  const [masterHubRackKeywordDetail, setMasterHubRackKeywordDetail] =
    useState("");

  const { path: rackPage } = useSelector(getAllRackhub);

  const [sortTableMasterHubRackDetail, setSortTableMasterHubRackDetail] =
    useState("code");
  const [isSortAscMasterHubRackDetail, setIsSortAscMasterHubRackDetail] =
    useState(true);

  // --------------------------------- PURCHASE HANDLING ----------------------
  const [purchaseRequest, setPurchaseRequest] = useState();
  // add request purchase
  const [addRequestPurchase, setAddRequestPurchase] = useState();
  // detail request purchase
  const [isDetailRequestPurchase, setIsDetailRequestPurchase] = useState();

  // --------------------------------- END PURCHASE HANDLING ------------------

  return (
    <Box sx={{ bgcolor: "white" }}>
      {matchTablet && (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="fixed"
              sx={(theme) => ({
                width: `calc(100% - ${drawerWidth} - ${theme.spacing(6)})`,
                ml: `calc(${drawerWidth} + ${theme.spacing(3)})`,
                mr: 3,
                borderBottom: 1,
                // borderColor: 'divider',
              })}
            >
              <Toolbar disableGutters>
                <Box sx={{ flexGrow: 1, mt: 2 }}>
                  <HeaderComponent featureName="Inventory" />
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          <Toolbar />
        </>
      )}

      <Box sx={{ p: 3 }}>
        <TabMenu
          labelTabsArray={labelTabsArray}
          value={value}
          setValue={setValue}
          setRowSelected={setRowSelected}
        >
          {/* <TabPanel value={value} index={0}>
            <InventoryDashboard />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography>Stock</Typography>
          </TabPanel> */}
          <TabPanel value={value} index={0}>
            {!matchTablet ? (
              <RoutingHub />
            ) : (
              <>
                {rackPage === "" && (
                  <>
                    {rowSelected ? (
                      //   <DetailMasterHub
                      //   setRowSelected={setRowSelected}
                      //   rowSelected={rowSelected}
                      //   fetchMasterListTable={fetchMasterListTable}
                      //   setMasterHubIdRack={setMasterHubIdRack}
                      //   setIsOpenCreateMap={setIsOpenCreateMap}
                      // />
                      <DetailsMasterHub
                        setRowSelected={setRowSelected}
                        setMasterHubIdRack={setMasterHubIdRack}
                      />
                    ) : isOpenMasterHub ? (
                      // <AddMasterHub
                      //   setIsOpenMasterHub={setIsOpenMasterHub}
                      //   setIsOpenCreateMap={setIsOpenCreateMap}
                      //   isOpenCreateMap={isOpenCreateMap}
                      //   setRowSelected={setRowSelected}
                      // />
                      <MasterHubAdd setIsOpenMasterHub={setIsOpenMasterHub} />
                    ) : isOpenCreateMap ? (
                      <CreateMap
                        {...{
                          isOpenCreateMap,
                          setIsOpenCreateMap,
                          setIsOpenMasterHub,
                          setRowSelected,
                        }}
                      />
                    ) : subHubSelected ? (
                      // <SubHub
                      //   subHubSelected={subHubSelected}
                      //   setSubHubSelected={setSubHubSelected}
                      //   subHubDetailSelected={subHubDetailSelected}
                      //   setSubHubDetailSelected={setSubHubDetailSelected}
                      //   setIsOpenNewSubHub={setIsOpenNewSubHub}
                      //   subHubData={subHubData}
                      //   setRowPerPage={setRowPerPage}
                      //   rowPerPage={rowPerPage}
                      //   totalTableItems={totalTableItemsSubHub}
                      //   setTotalTableItems={setTotalTableItemsSubHub}
                      //   fetchSubHub={fetchSubHub}
                      //   currentPageTable={currentPageTableSubHub}
                      //   setCurrentPageTable={setCurrentPageTableSubHub}
                      //   isLoadingSubHub={isLoadingSubHub}
                      //   setMiniSubSelected={setMiniSubSelected}
                      //   subHubKeyword={subHubKeyword}
                      //   setSubHubKeyword={setSubHubKeyword}
                      //   sortTableSubHub={sortTableSubHub}
                      //   setSortTableSubHub={setSortTableSubHub}
                      //   isSortAscSubHub={isSortAscSubHub}
                      //   setIsSortAscSubHub={setIsSortAscSubHub}
                      // />
                      <SubHub
                        setRowSelected={setRowSelected}
                        setIsOpenNewSubHub={setIsOpenNewSubHub}
                        setMiniSubSelected={setMiniSubSelected}
                        setSubHubSelected={setSubHubSelected}
                        subHubSelected={subHubSelected}
                        setSubHubDetailSelected={setSubHubDetailSelected}
                        // rowPerPageTable={rowPerPageTable}
                      />
                    ) : subHubDetailSelected ? (
                      // <DetailSubHub
                      //   setSubHubDetailSelected={setSubHubDetailSelected}
                      //   subHubDetailSelected={subHubDetailSelected}
                      //   setSubHubSelected={setSubHubSelected}
                      //   fetchSubHub={fetchSubHub}
                      // />
                      <DetailsSubHub
                        setSubHubDetailSelected={setSubHubDetailSelected}
                        setSubHubSelected={setSubHubSelected}
                      />
                    ) : isOpenNewSubHub ? (
                      // <AddNewSubHub
                      //   setIsOpenNewSubHub={setIsOpenNewSubHub}
                      //   setSubHubSelected={setSubHubSelected}
                      //   setSubHubDetailSelected={setSubHubDetailSelected}
                      // />

                      <AddSubHub
                        setIsOpenNewSubHub={setIsOpenNewSubHub}
                        setSubHubSelected={setSubHubSelected}
                        setSubHubDetailSelected={setSubHubDetailSelected}
                      />
                    ) : miniSubSelected ? (
                      // <MiniHub
                      //   setMiniSubSelected={setMiniSubSelected}
                      //   setSubHubSelected={setSubHubSelected}
                      //   totalTableItemsMiniHub={totalTableItemsMiniHub}
                      //   setRowPerPageMiniHub={setRowPerPageMiniHub}
                      //   rowPerPageMiniHub={rowPerPageMiniHub}
                      //   fetchMiniSubHub={fetchMiniSubHub}
                      //   currentPageTableMiniHub={currentPageTableMiniHub}
                      //   setCurrentPageTableMiniHub={setCurrentPageTableMiniHub}
                      //   miniHub={miniHub}
                      //   isLoadingMiniHub={isLoadingMiniHub}
                      //   setIsOpenAddNewMiniHub={setIsOpenAddNewMiniHub}
                      //   setMiniHubDetailSelected={setMiniHubDetailSelected}
                      //   sortTableMiniHub={sortTableMiniHub}
                      //   setSortTableMiniHub={setSortTableMiniHub}
                      //   isSortAscMiniHub={isSortAscMiniHub}
                      //   setIsSortAscMiniHub={setIsSortAscMiniHub}
                      //   miniHubKeyword={miniHubKeyword}
                      //   setMiniHubKeyword={setMiniHubKeyword}
                      // />
                      <MiniHub
                        setSubHubSelected={setSubHubSelected}
                        setMiniSubSelected={setMiniSubSelected}
                        miniSubSelected={miniSubSelected}
                        setIsOpenAddNewMiniHub={setIsOpenAddNewMiniHub}
                        setMiniHubDetailSelected={setMiniHubDetailSelected}
                      />
                    ) : miniHubDetailSelected ? (
                      // <MiniHubDetail
                      //   setMiniHubDetailSelected={setMiniHubDetailSelected}
                      //   setMiniSubSelected={setMiniSubSelected}
                      //   fetchMiniSubHub={fetchMiniSubHub}
                      // />
                      <DetailsMiniHub
                        setMiniHubDetailSelected={setMiniHubDetailSelected}
                        setMiniSubSelected={setMiniSubSelected}
                        fetchMiniSubHub={fetchMiniSubHub}
                      />
                    ) : isOpenAddNewMiniHub ? (
                      // <AddNewMiniHub
                      //   setIsOpenAddNewMiniHub={setIsOpenAddNewMiniHub}
                      //   setSubHubSelected={setSubHubSelected}
                      //   setMiniSubSelected={setMiniSubSelected}
                      //   setMiniHubDetailSelected={setMiniHubDetailSelected}
                      // />
                      <AddMiniHub
                        setIsOpenNewSubHub={setIsOpenNewSubHub}
                        setIsOpenAddNewMiniHub={setIsOpenAddNewMiniHub}
                        setSubHubSelected={setSubHubSelected}
                        setMiniSubSelected={setMiniSubSelected}
                      />
                    ) : masterHubIdRack ? (
                      <MasterHubRacks
                        setMasterHubIdRack={setMasterHubIdRack}
                        setRowSelected={setRowSelected}
                        masterHubRackSelect={masterHubRackSelect}
                        setMasterHubRackSelect={setMasterHubRackSelect}
                        saveDateRangeMasterHubRack={saveDateRangeMasterHubRack}
                        setSaveDateRangeMasterHubRack={
                          setSaveDateRangeMasterHubRack
                        }
                        dateRangeMasterHubRack={dateRangeMasterHubRack}
                        setDateRangeMasterHubRack={setDateRangeMasterHubRack}
                        masterHubRack={masterHubRack}
                        setMasterHubRack={setMasterHubRack}
                        rowPerPageMasterHubRack={rowPerPageMasterHubRack}
                        setRowPerPageMasterHubRack={setRowPerPageMasterHubRack}
                        totalTableItemsMasterHubRack={
                          totalTableItemsMasterHubRack
                        }
                        setTotalTableItemsMasterHubRack={
                          setTotalTableItemsMasterHubRack
                        }
                        currentPageTableMasterHubRack={
                          currentPageTableMasterHubRack
                        }
                        setCurrentPageTableMasterHubRack={
                          setCurrentPageTableMasterHubRack
                        }
                        isLoadingMasterHubRack={isLoadingMasterHubRack}
                        setIsLoadingMasterHubRack={setIsLoadingMasterHubRack}
                        masterHubRackKeyword={masterHubRackKeyword}
                        setMasterHubRackKeyword={setMasterHubRackKeyword}
                        sortTableMasterHubRack={sortTableMasterHubRack}
                        setSortTableMasterHubRack={setSortTableMasterHubRack}
                        isSortAscMasterHubRack={isSortAscMasterHubRack}
                        setIsSortAscMasterHubRack={setIsSortAscMasterHubRack}
                        rowPerPageValues={rowPerPageValues}
                        setMasterHubIdRackDetail={setMasterHubIdRackDetail}
                      />
                    ) : masterHubIdRackDetail ? (
                      <MasterHubRackDetails
                        setMasterHubIdRackDetail={setMasterHubIdRackDetail}
                        setMasterHubIdRack={setMasterHubIdRack}
                        masterHubRackDetailSelect={masterHubRackDetailSelect}
                        setMasterHubRackDetailSelect={
                          setMasterHubRackDetailSelect
                        }
                        saveDateRangeMasterHubRackDetail={
                          saveDateRangeMasterHubRackDetail
                        }
                        setSaveDateRangeMasterHubRackDetail={
                          setSaveDateRangeMasterHubRackDetail
                        }
                        dateRangeMasterHubRackDetail={
                          dateRangeMasterHubRackDetail
                        }
                        setDateRangeMasterHubRackDetail={
                          setDateRangeMasterHubRackDetail
                        }
                        masterHubRackDetail={masterHubRackDetail}
                        setMasterHubRackDetail={setMasterHubRackDetail}
                        rowPerPageMasterHubRackDetail={
                          rowPerPageMasterHubRackDetail
                        }
                        setRowPerPageMasterHubRackDetail={
                          setRowPerPageMasterHubRackDetail
                        }
                        totalTableItemsMasterHubRackDetail={
                          totalTableItemsMasterHubRackDetail
                        }
                        setTotalTableItemsMasterHubRackDetail={
                          setTotalTableItemsMasterHubRackDetail
                        }
                        currentPageTableMasterHubRackDetail={
                          currentPageTableMasterHubRackDetail
                        }
                        setCurrentPageTableMasterHubRackDetail={
                          setCurrentPageTableMasterHubRackDetail
                        }
                        isLoadingMasterHubRackDetail={
                          isLoadingMasterHubRackDetail
                        }
                        setIsLoadingMasterHubRackDetail={
                          setIsLoadingMasterHubRackDetail
                        }
                        masterHubRackKeywordDetail={masterHubRackKeywordDetail}
                        setMasterHubRackKeywordDetail={
                          setMasterHubRackKeywordDetail
                        }
                        sortTableMasterHubRackDetail={
                          sortTableMasterHubRackDetail
                        }
                        setSortTableMasterHubRackDetail={
                          setSortTableMasterHubRackDetail
                        }
                        isSortAscMasterHubRackDetail={
                          isSortAscMasterHubRackDetail
                        }
                        setIsSortAscMasterHubRackDetail={
                          setIsSortAscMasterHubRackDetail
                        }
                        rowPerPageValues={rowPerPageValues}
                      />
                    ) : (
                      <MasterHub
                        setTreatmentsStartDate={setTreatmentsStartDate}
                        setTreatmentEndDate={setTreatmentEndDate}
                        setTreatmentsTimeframe={setTreatmentsTimeframe}
                        setTreatmentChartType={setTreatmentChartType}
                        setShownTreatmentAttribute={setShownTreatmentAttribute}
                        treatmentPerformanceLineChartData={
                          treatmentPerformanceLineChartData
                        }
                        treatmentsTimeframe={treatmentsTimeframe}
                        shownTreatmentAttribute={shownTreatmentAttribute}
                        selected={selected}
                        setSelected={setSelected}
                        treatmentChartType={treatmentChartType}
                        treatmentPerformanceBarChartData={
                          treatmentPerformanceBarChartData
                        }
                        barChartShownTreatmentAttributes={
                          barChartShownTreatmentAttributes
                        }
                        setBarChartShownTreatmentAttributes={
                          setBarChartShownTreatmentAttributes
                        }
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        treatments={treatments}
                        colors={colors}
                        setRowSelected={setRowSelected}
                        rowSelected={rowSelected}
                        hubsData={hubsData}
                        setHubsData={setHubsData}
                        rowPerPageTable={rowPerPageTable}
                        setRowPerPageTable={setRowPerPageTable}
                        rowPerPageValues={rowPerPageValues}
                        currentPageTable={currentPageTable}
                        setCurrentPageTable={setCurrentPageTable}
                        totalTableItems={totalTableItems}
                        setTotalTableItems={setTotalTableItems}
                        setIsOpenMasterHub={setIsOpenMasterHub}
                        subHubSelected={subHubSelected}
                        setSubHubSelected={setSubHubSelected}
                        isLoadingMasterHub={isLoadingMasterHub}
                        masterHubKeyword={masterHubKeyword}
                        setMasterHubKeyword={setMasterHubKeyword}
                        subsidiaryDatas={subsidiaryDatas}
                        setFilterSubsidiaryArray={setFilterSubsidiaryArray}
                        sortTableMasterHub={sortTableMasterHub}
                        setSortTableMasterHub={setSortTableMasterHub}
                        isSortAscMasterHub={isSortAscMasterHub}
                        setIsSortAscMasterHub={setIsSortAscMasterHub}
                        matchTablet={matchTablet}
                        filterSubsidiaryArray={filterSubsidiaryArray}
                      />
                    )}
                  </>
                )}
                {rackPage !== "" && <RackHub />}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <InventoryProduct />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <InventoryMenu />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <RoutingPurchase />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <RoutingStock />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <RoutingTransaction />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <RoutingAudit {...{ value, setValue }} />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <RoutingAdjustment {...{ value, setValue }} />
          </TabPanel>
        </TabMenu>
      </Box>
    </Box>
  );
};

export default Inventory;
