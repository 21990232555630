import React, { useEffect, useState, useRef } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { Box, Typography, Grid, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getAllSubHub, getDetailHubs } from '../../../../../../api/hubs';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomCard from '../../../../../../components/shared/CustomCard';
import CustomSelect from '../../../../../../components/shared/CustomSelect';
import CustomDateRange from '../../../../../../components/shared/CustomDateRange';
import dayjs from 'dayjs';
import SubHubTable from './SubHubTable';
import useHub from '../../../../../../hooks/useHub';
import { Bar, Line } from 'react-chartjs-2';
import BarChartIcon from '@mui/icons-material/BarChart';
import LineChartIcon from '@mui/icons-material/SsidChart';
import TableList from './TableList';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeIdToko,
  changeSubsidiaryId,
  getIdToko,
  getSubsidiaryIdSubHub,
} from '../../../../../../store/hub/subHub/subHubAdd';
import GrafikComparisonHub from '../Component/GrafikComparison';

// data dummy for grafik comparison
const datas = [
  { month: 'January', hub1: 65, hub2: 45 },
  { month: 'February', hub1: 59, hub2: 39 },
  { month: 'March', hub1: 80, hub2: 60 },
  { month: 'April', hub1: 81, hub2: 61 },
  { month: 'May', hub1: 56, hub2: 36 },
  { month: 'June', hub1: 55, hub2: 35 },
  { month: 'July', hub1: 40, hub2: 20 },
];
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
};
const state = {
  data: {
    labels: datas.map((d) => d.month),
    datasets: [
      {
        label: 'Hub 1',
        backgroundColor: 'rgba(000,255,132,0.2)',
        borderColor: 'rgba(000,255,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(000,255,132,0.4)',
        hoverBorderColor: 'rgba(000,255,132,1)',
        data: datas.map((d) => d.hub1),
      },
      {
        label: 'Hub 2',
        backgroundColor: 'rgba(000,000,255,0.2)',
        borderColor: 'rgba(000,000,255,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(000,000,255,0.4)',
        hoverBorderColor: 'rgba(000,000,255,1)',
        data: datas.map((d) => d.hub2),
      },
    ],
  },
};

// data dummy for mini table
const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'Hub', headerName: 'Hub' },
  { field: 'SKU', headerName: 'SKU', type: 'number' },
  {
    field: 'COGS',
    headerName: 'COGS',
    type: 'number',
  },
];

const rows = [
  { id: 1, Hub: 'Hub 1', SKU: 300, COGS: 3000 },
  { id: 2, Hub: 'Hub 2', SKU: 300, COGS: 3000 },
  { id: 3, Hub: 'Hub 3', SKU: 300, COGS: 3000 },
  { id: 4, Hub: 'Hub 4', SKU: 300, COGS: 3000 },
  { id: 5, Hub: 'Hub 5', SKU: 300, COGS: 3000 },
  { id: 6, Hub: 'Hub 6', SKU: 300, COGS: 3000 },
  { id: 7, Hub: 'Hub 7', SKU: 300, COGS: 3000 },
  { id: 8, Hub: 'Hub 8', SKU: 300, COGS: 3000 },
  { id: 9, Hub: 'Hub 9', SKU: 300, COGS: 3000 },
];

const SubHub = ({
  setSubHubSelected,
  // setSubHubDetailSelected,
  // subHubDetailSelected,
  setIsOpenSubHub,
  setIsOpenNewSubHub,
  // subHubData,
  // setRowPerPage,
  // rowPerPage,
  fetchSubHub,
  // isLoadingSubHub,
  miniSubSelected,
  setMiniSubSelected,
  // subHubKeyword,
  // setSubHubKeyword,
  // sortTableSubHub,
  // setSortTableSubHub,
  // isSortAscSubHub,
  // setIsSortAscSubHub,
  // new
  rowSelected,
  setRowSelected,
  hubsData,
  setHubsData,
  // rowPerPageTable,
  // setRowPerPageTable,
  subHubSelected,
  subsidiaryDatas,
  setFilterSubsidiaryArray,
  setSubHubDetailSelected,
  // rowPerPageValues,
}) => {
  const dispatch = useDispatch();
  const [defaultGrafick, setDefaultGrafik] = useState('line');
  // const [isOpenNewSubHub, setIsOpenNewSubHub] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const [subHubDetail, setSubHubDetail] = useState([]);
  // const hubsId = useRef(searchParams.get('subhub-overview') || null);
  const hubsId = subHubSelected;
  dispatch(changeIdToko(subHubSelected));

  // const idToko = useSelector(getIdToko);
  // const { setSubHubData } = useHub();

  // useEffect(() => {
  //   fetchSubHub(
  //     currentPageTable,
  //     rowPerPage,
  //     hubsId.current,
  //     subHubKeyword,
  //     sortTableSubHub,
  //     isSortAscSubHub
  //   );
  // }, [
  //   currentPageTable,
  //   rowPerPage,
  //   subHubKeyword,
  //   sortTableSubHub,
  //   isSortAscSubHub,
  // ]);

  useEffect(() => {
    const fetchDetailHubs = async () => {
      try {
        const {
          data: { data },
        } = await getDetailHubs(hubsId);
        setSubHubDetail(data);
        // setSubHubData({
        //   subsidiary: data?.subsidiary?.name,
        //   subName: data?.namaToko,
        //   subHubId: data?.idToko,
        // });
      } catch (err) {
        console.error(err);
      }
    };
    fetchDetailHubs();
  }, []);

  // useEffect(() => {
  //   fetchSubHub(currentPageTable, rowPerPage, hubsId.current);
  // }, [currentPageTable, rowPerPage]);

  // const [subHubSelect, setSubHubSelect] = useState('daily');
  // const rowPerPageValues = [5, 10, 15, 20, 25];

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(1, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(1, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  // change subsidiary id
  dispatch(changeSubsidiaryId(subHubDetail?.subsidiaryId));
  const idSubSidiary = useSelector(getSubsidiaryIdSubHub);

  // fetch all data sub hub
  const [subHubData, setSubHubData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(5);
  const [totalTableItems, setTotalTableItems] = useState(1);
  const [currentPageTable, setCurrentPageTable] = useState(1);
  const [isLoadingSubHub, setIsLoadingSubHub] = useState(true);
  const [subHubKeyword, setSubHubKeyword] = useState('');
  const rowPerPageValues = [5, 10, 15, 20, 25];

  const [rowPerPageTable, setRowPerPageTable] = useState(rowPerPageValues[0]);
  const [sortTableSubHub, setSortTableSubHub] = useState('name');
  const [isSortAscSubHub, setIsSortAscSubHub] = useState(true);

  useEffect(() => {
    const fetchAllSubHub = async () => {
      setIsLoadingSubHub(true);
      // let params = {};
      // params.page = currentPageTable;
      // params.pageSize = rowPerPageTable;
      // params.search = subHubKeyword;
      // params.idToko = hubsId;

      // if (sortTableSubHub !== 0) {
      //   params[sortTableSubHub] = isSortAscSubHub ? 'asc' : 'desc';
      // }

      try {
        const {
          data: {
            data: { subHubs, pageInfo },
          },
        } = await getAllSubHub({ idToko: hubsId });

        setSubHubData(subHubs);
        setTotalTableItems(pageInfo.total);
      } catch (err) {
        console.error(err);
        setIsLoadingSubHub(true);
      } finally {
        setIsLoadingSubHub(false);
      }
    };
    fetchAllSubHub();
  }, []);
  // ------ end -----
  // const [subHubDetailSelected, setSubHubDetailSelected] = useState(
  //   parseInt(searchParams.get('subhub-detail')) || null
  // );
  // const [miniSubSelected, setMiniSubSelected] = useState(
  //   parseInt(searchParams.get('minihub-overview')) || null
  // );

  return (
    <>
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <BackButtonHub
            onClick={() => {
              setSubHubSelected(null);
              updatedSearchParams.delete('subhub-overview');
              setSearchParams(updatedSearchParams);
            }}
          />
          <Typography
            sx={{
              color: '#9E9D9D',
              fontSize: '1.25rem',
              fontWeight: 600,
              cursor: 'pointer',
            }}
            onClick={() => {
              setSubHubSelected(null);
              updatedSearchParams.delete('subhub-overview');
              setSearchParams(updatedSearchParams);
            }}
          >
            Master Hub
          </Typography>
          <ArrowForwardIosIcon
            sx={{ color: '#9E9D9D', fontSize: '1rem', fontWeight: 600 }}
          />
          <Typography
            sx={{
              color: 'primary.black',
              fontSize: '1.25rem',
              fontWeight: 600,
            }}
          >
            {subHubDetail?.namaToko}
          </Typography>
        </Box>

        {/* master comparison */}
        <GrafikComparisonHub />

        {/* table list */}
        <Box sx={{ mt: 4 }}>
          {/* <SubHubTable
              subHubData={subHubData}
              totalTableItems={totalTableItems}
              setRowPerPage={setRowPerPage}
              rowPerPageValues={rowPerPageValues}
              setCurrentPageTable={setCurrentPageTable}
              rowPerPage={rowPerPage}
              currentPageTable={currentPageTable}
              subHubDetailSelected={subHubDetailSelected}
              setSubHubDetailSelected={setSubHubDetailSelected}
              setSubHubSelected={setSubHubSelected}
              setIsOpenNewSubHub={setIsOpenNewSubHub}
              setIsOpenSubHub={setIsOpenSubHub}
              setMiniSubSelected={setMiniSubSelected}
              subHubKeyword={subHubKeyword}
              setSubHubKeyword={setSubHubKeyword}
              isLoadingSubHub={isLoadingSubHub}
              sortTableSubHub={sortTableSubHub}
              setSortTableSubHub={setSortTableSubHub}
              isSortAscSubHub={isSortAscSubHub}
              setIsSortAscSubHub={setIsSortAscSubHub}
              hubsId={hubsId.current}
            /> */}
          <TableList
            rowSelected={rowSelected}
            setRowSelected={setRowSelected}
            hubsData={subHubData}
            setHubsData={setSubHubData}
            rowPerPageTable={rowPerPageTable}
            setRowPerPageTable={setRowPerPageTable}
            rowPerPageValues={rowPerPageValues}
            currentPageTable={currentPageTable}
            setCurrentPageTable={setCurrentPageTable}
            totalTableItems={totalTableItems}
            setTotalTableItems={setTotalTableItems}
            setSubHubDetail={setSubHubDetail}
            // setIsOpenSubHub={setIsOpenSubHub}
            setIsOpenNewSubHub={setIsOpenNewSubHub}
            // subHubSelected={subHubSelected}
            setSubHubSelected={setSubHubSelected}
            setSubHubDetailSelected={setSubHubDetailSelected}
            subHubKeyword={subHubKeyword}
            setSubHubKeyword={setSubHubKeyword}
            subsidiaryDatas={subsidiaryDatas}
            setFilterSubsidiaryArray={setFilterSubsidiaryArray}
            isLoadingSubHub={isLoadingSubHub}
            sortTableSubHub={sortTableSubHub}
            miniSubSelected={miniSubSelected}
            setMiniSubSelected={setMiniSubSelected}
            setSortTableSubHub={setSortTableSubHub}
            isSortAscSubHub={isSortAscSubHub}
            setIsSortAscSubHub={setIsSortAscSubHub}
          />
        </Box>
      </Box>
    </>
  );
};

export default SubHub;
