import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import { changePageAudit } from '../../../../../../store/Routing/auditRouting';
import { ChevronRightIcon, StockAlert } from '../../../../../../assets/Icons';
import ChartPerfomanceStock from '../../Stock/Component/Chart';
import CustomCard from '../../../../../../components/shared/CustomCard';
import TablePerfomanceStock from '../../Stock/Component/Table';
import { useState } from 'react';
import dayjs from 'dayjs';
import TableListDetailAudit from './TableListDetailAudit';
import {
  getHubDetailAudit,
  getIdDetailAudit,
} from '../../../../../../store/audit/detailAudit';
import DetailAuditMobile from '../AuditMobile/Details';

const DetailsAudit = ({
  labelTabsArray,
  children,
  value,
  setValue,
  setRowSelected,
}) => {
  const dispatch = useDispatch();
  const matchTablet = useMediaQuery('(min-width:832px');
  const id = useSelector(getIdDetailAudit);
  const hub = useSelector(getHubDetailAudit);
  const [isSelect, setIsSelect] = useState(false);

  // ! grafik & table

  const [timeFrame, setTimeFrame] = useState('daily');

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);
  return (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      {!matchTablet ? (
        <DetailAuditMobile setValue={setValue} />
      ) : (
        <>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <BackButtonHub onClick={() => dispatch(changePageAudit(''))} />
              <Box>
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '24px',
                    fontWeight: 600,
                    cursor: 'pointer',
                  }}
                >
                  {id} Details
                </Typography>

                <Typography
                  sx={{
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#9E9D9D',
                    fontWeight: 600,
                  }}
                >
                  {/* subsidiary
              <ChevronRightIcon sx={{ width: '16px' }} /> */}
                  {hub}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                minWidth: '183px',
                height: '45px',
                borderRadius: '8px',
                border: '1px solid #F33A3A',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
                padding: '0px 12px',
              }}
              onClick={() => {
                dispatch(changePageAudit('missingQrCode'));
              }}
            >
              <StockAlert sx={{ fontSize: '14px' }} />
              <Typography
                sx={{ color: '#F33A3A', fontSize: '14px', fontWeight: 600 }}
              >
                Missing QR Code
              </Typography>
              <Box
                sx={{
                  width: '20px',
                  height: '20px',
                  background: '#F33A3A',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#FFFFFF',
                }}
              >
                <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                  2
                </Typography>
              </Box>
              <ChevronRightIcon sx={{ color: '#F33A3A', fontSize: '18px' }} />
            </Box>
          </Box>

          <Grid container columns={12}>
            {!isSelect && (
              <Grid item xs={12}>
                <CustomCard sx={{ mt: 2, mb: 2, p: 2 }}>
                  <Grid container columns={12} spacing={4}>
                    {/* grafik */}
                    <Grid item xs={6}>
                      <ChartPerfomanceStock
                        {...{
                          timeFrame,
                          setTimeFrame,
                          dateRange,
                          setDateRange,
                          saveDateRange,
                          setSaveDateRange,
                        }}
                      />
                    </Grid>

                    {/* table */}
                    <Grid item xs={6}>
                      <TablePerfomanceStock />
                    </Grid>
                  </Grid>
                </CustomCard>
              </Grid>
            )}

            {/* list data table */}
            <Grid item xs={12}>
              <TableListDetailAudit
                {...{ isSelect, setIsSelect, value, setValue }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
export default DetailsAudit;
