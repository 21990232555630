import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import { Map } from 'pigeon-maps';
import CustomInput from '../../../../../../../components/shared/CustomInput';
import { useEffect, useRef, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import {
  changeImagePreviewEdit,
  changeIsCreateMap,
  changeIsEdit,
  changeIsLoadingUpdate,
  changeNameMiniHubEdit,
  changePicChooseEdit,
  changePicPhoneEdit,
  changePicSearchDebounceEdit,
  changePicSearchEdit,
  changeVideoPreviewEdit,
  clearData,
  clickIsPreviewImageEdit,
  clickIsVideoPreviewEdit,
  deleteMiniHubImageEdit,
  deleteTayangan,
  deleteVideoPreviewEdit,
  fetchDetailDataMiniHubEdit,
  getDetailDataMiniHubEdit,
  getIdMiniHubEdit,
  getIdSubHubMiniEdit,
  getIsMaxSizeImageEdit,
  getIsMaxSizeVideoMiniHubEdit,
  getIsVideoPreviewMiniHubEdit,
  getLoadingDetailMiniHubEdit,
  getMiniHubEditAll,
  getMiniHubImageEdit,
  getMiniHubPic,
  getNameMiniHubEdit,
  getPicChooseMiniHubEdit,
  getPicPhoneMiniHubEdit,
  getPicSearchDebounceMiniHubEdit,
  getPicSearchMiniHubEdit,
  getVideoMiniHubEdit,
  getVideoPreviewMiniHubEdit,
  inputMiniHubImageEdit,
  inputTayangan,
  inputVideoPreviewEdit,
} from '../../../../../../../store/hub/miniHub/miniHubEdit';
import {
  fetchPic,
  getPicList,
  getPicLoading,
} from '../../../../../../../store/pic/pic';
import debounce from 'lodash.debounce';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import CustomCard from '../../../../../../../components/shared/CustomCard';

import CustomAntSwitch from '../../../../../../../components/shared/CustomAntSwitch';
import {
  addSubHubRacksMultiple,
  deleteSubHubRacksMultiple,
  editMiniHub,
  editStatusMiniHub,
  editSubHubRacksMultiple,
} from '../../../../../../../api/hubs';
import CustomEditConfirm from '../../../../../../../components/shared/CustomEditConfirm';
import HubFamily from '../../HubFamily';
import SetupAddressMiniHub from './AddressMap/SetupAddress';
import CreateMapMiniHubEdit from './CreateMapMiniHubEdit';
import { inputNotifHub } from '../../../../../../../store/hub/notifHub';

function EditMiniHub() {
  const dispatch = useDispatch();
  const idMiniHub = useSelector(getIdMiniHubEdit);
  // const idSubHub = useSelector(getIdSubHubMiniEdit);
  const theme = useTheme();
  const miniHubNameEdit = useSelector(getNameMiniHubEdit);
  const dataDetailsMiniHub = useSelector(getDetailDataMiniHubEdit);
  const miniHubImageEdit = useSelector(getMiniHubImageEdit);
  const [isEditConfirm, setIsEditConfirm] = useState(false);
  const loading = useSelector(getLoadingDetailMiniHubEdit);
  const picList = useSelector(getPicList);
  const picLoading = useSelector(getPicLoading);

  // change params
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const subHubIdRef = useRef(searchParams.get('minihub-overview') || null);

  // --------- import store mini hub edits -------
  const {
    name,
    subsidiaryId,
    idMasterHub,
    idSubHub,
    isLoadingUpdate,
    isCreateMap,
    isLoading,
    isLoadingVideo,
    isUploadImage,

    // image
    image,
    isMaxSizeImage,
    isImagePreview,
    imagePreview: linkImagePreview,

    // video
    video,
    isMaxSizeVideo,
    isVideoPreview,
    videoPreview: linkVideoPreview,

    // pic/
    picChoose,
    picSearch,
    picSearchDebounce,
    picPhone,

    // rack
    col,
    row,
    entrance,
    status,
    cellSelectedDetails,
    cellSelectedDetailsNew,
    rackDeletes,

    // address
    coordinate,
    address,
  } = useSelector(getMiniHubEditAll);
  const [isLoadingOnUpdate, setIsLoadingOnUpdate] = useState(false);
  const handleSaveFix = () => {
    setIsLoadingOnUpdate(true);
    const { idKustomer } = picChoose;
    const upadeteHub = {
      subsidiaryId: subsidiaryId,
      name: name,
      idKustomer: idKustomer,
      images: image.length !== 0 ? image.join(',') : '',
      videos: video.length !== 0 ? video.join(',') : '',
      mapColumn: col,
      mapRow: row,
      nohp: picPhone,
      address: address,
      entrancePosition: entrance.length !== 0 ? entrance.join(',') : '',
    };
    const deleteRackData = {
      hubRackId: rackDeletes,
    };
    const updateRackData = cellSelectedDetails.map((item) => {
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        columnPosition: item.columnPosition,
      };
    });
    const addRackData = cellSelectedDetailsNew.map((item) => {
      return {
        idToko: idMasterHub,
        subHubId: idSubHub,
        miniHubId: idMiniHub,
        name: item.name,
        images: '',
        videos: '',
        column: item.column,
        row: item.row,
        columnPosition: item.columnPosition,
        description: item.description,
        width: 0.0,
        height: 0.0,
        length: 0.0,
        weight: 0,
      };
    });
    dispatch(changeIsLoadingUpdate(true));

    const updateMiniHub = async () => {
      try {
        await editMiniHub(idMiniHub, upadeteHub);
      } catch (error) {
        console.error(error);
      } finally {
        if (rackDeletes.length !== 0) {
          deleteRack();
        } else {
          updateRack();
        }
      }
    };
    const deleteRack = async () => {
      try {
        await deleteSubHubRacksMultiple(deleteRackData);
      } catch (error) {
        console.error(error);
      } finally {
        updateRack();
      }
    };
    const updateRack = async () => {
      try {
        await editSubHubRacksMultiple(updateRackData);
      } catch (error) {
        console.error(error);
      } finally {
        if (cellSelectedDetailsNew.length !== 0) {
          addRacks();
        } else {
          dispatch(changeIsLoadingUpdate(false));
          setIsEditConfirm(false);
          dispatch(clearData(false));
          dispatch(
            inputNotifHub({
              key: 'editmasterhub',
              text: `${name} has been success to be edited`,
            })
          );
        }
      }
    };
    const addRacks = async () => {
      try {
        await addSubHubRacksMultiple(addRackData);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(changeIsLoadingUpdate(false));
        setIsEditConfirm(false);
        dispatch(clearData(false));
        dispatch(
          inputNotifHub({
            key: 'editsubhub',
            text: `${name} has been success to be edited`,
          })
        );
      }
    };

    updateMiniHub();
  };

  // -------------- Status -------------------
  const [statusHub, setStatusHub] = useState(false);
  useEffect(() => {
    if (!loading) {
      if (dataDetailsMiniHub.status === 'active') {
        return setStatusHub(true);
      } else {
        return setStatusHub(false);
      }
    }
  }, [loading]);

  // get detail data
  useEffect(() => {
    dispatch(fetchDetailDataMiniHubEdit(idMiniHub));
  }, [idMiniHub]);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounceEdit(value));
    }, 1000)
  ).current;

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  const handleBackButton = () => {
    dispatch(changeIsEdit(false));
  };

  const handleEditStatus = () => {
    if (statusHub) {
      editStatusMiniHub(dataDetailsMiniHub.id, { status: 'inactive' });
      setStatusHub(false);
    } else {
      editStatusMiniHub(dataDetailsMiniHub.id, { status: 'active' });
      setStatusHub(true);
    }
  };

  const handleSave = () => {
    setIsEditConfirm(!isEditConfirm);
  };

  const [isMapOpen, setIsMapOpen] = useState(false);

  return (
    <>
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleSaveFix}
        title="Edit Mini Hub"
        body={
          <Box sx={{ textAlign: 'center', mb: '40px', mt: '8px' }}>
            <Typography>Are you sure you want to save the changes?</Typography>
            {isLoadingUpdate && (
              <CircularProgress size={16} sx={{ mt: '12px' }} />
            )}
          </Box>
        }
      />

      {/* edit data */}
      {!isCreateMap ? (
        <>
          {/* back button */}
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <BackButtonHub
              onClick={() => {
                dispatch(changeIsEdit());
                updatedSearchParams.delete('minihub-overview');
                updatedSearchParams.set(
                  'subhub-overview',
                  dataDetailsMiniHub?.idToko
                );
              }}
            />
            <Box>
              <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
                Edit mini hub
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  color: '#9E9D9D',
                  alignItems: 'center',
                  mt: '4px',
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                  {dataDetailsMiniHub?.subHub?.store?.subsidiary?.name}
                </Typography>
                <ArrowForwardIosIcon
                  sx={{
                    color: '#9E9D9D',
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                />
                <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                  {dataDetailsMiniHub?.subHub?.store?.namaToko}
                </Typography>
                <ArrowForwardIosIcon
                  sx={{
                    color: '#9E9D9D',
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                />
                <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                  {dataDetailsMiniHub?.subHub?.name}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', mt: '16px', gap: 3 }}>
            {/* hub family */}
            <Box sx={{ width: '200px', bgcolor: '#FAFAFA' }}>
              <HubFamily isEditMaster={true} idEditMaster={idMiniHub} />
            </Box>

            <Box sx={{ position: 'realtive' }}>
              <Grid container columns={12} spacing={4}>
                {/* name */}
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: '17px', fontWeight: 'bold', mb: 2 }}
                  >
                    Name*
                  </Typography>
                  <CustomInput
                    fullWidth
                    sx={{
                      with: '100%',
                      bgcolor: '#fafafa',
                      '& .MuiOutlinedInput-input': {
                        padding: '17px 16px',
                        borderRadius: '10px',
                        '&::placeholder': {
                          color: '#111',
                          fontSize: '16px',
                        },
                      },
                      borderRadius: '5px',
                    }}
                    placeholder="enter hub name"
                    value={miniHubNameEdit}
                    onChange={(e) => {
                      if (e.target.value.length <= 30) {
                        dispatch(changeNameMiniHubEdit(e.target.value));
                      }
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '17px',
                        mt: '5px',
                        ml: '15px',
                        color: '#9E9D9D',
                      }}
                    >
                      {miniHubNameEdit?.length > 5
                        ? ''
                        : 'Enter at least 5 characters'}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        mt: '5px',
                        ml: '15px',
                        color: '#9E9D9D',
                      }}
                    >
                      {miniHubNameEdit?.length}/30
                    </Typography>
                  </Box>
                </Grid>

                {/* image */}
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <Typography sx={{ fontSize: '17px', fontWeight: 'bold' }}>
                      Photo*
                    </Typography>
                    <Typography sx={{ fontSize: '17px' }}>
                      (max size: 2MB)
                    </Typography>
                    {isMaxSizeImage && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ErrorOutlineIcon
                          sx={{
                            fontSize: '16px',
                            fontWeight: '500',
                            color: theme.palette.warning.main,
                            mr: '4px',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            color: theme.palette.warning.main,
                          }}
                        >
                          The photo can't be more than 2MB
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  {/* add & preview */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    {/* preview */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {image.length === 0 && (
                        <Box
                          sx={{
                            height: '54px',
                            width: '54px',
                            aspectRatio: '1/1',
                            borderRadius: '8px',
                            bgcolor: '#F5F5F5',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderStyle: 'dashed',
                            borderColor: '#9E9D9D',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            document.getElementById('input_img').click();
                          }}
                        >
                          <ImageOutlinedIcon
                            sx={{ color: '#9E9D9D', fontSize: '25px' }}
                          />
                        </Box>
                      )}
                      {image.map((input, index) => (
                        <Box
                          sx={{
                            position: 'relative',
                            overflow: 'visible',
                            padding: '7px',
                          }}
                          key={index}
                        >
                          <CancelIcon
                            sx={{
                              position: 'absolute',
                              fontSize: '20px',
                              top: 1,
                              right: 1,
                              zIndex: +1,
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              dispatch(deleteMiniHubImageEdit(input))
                            }
                          />
                          <Box
                            component="img"
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              '/' +
                              input
                            }
                            sx={{
                              height: '54px',
                              width: '54px',
                              aspectRatio: '1/1',
                              borderRadius: '8px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              dispatch(clickIsPreviewImageEdit());
                              dispatch(changeImagePreviewEdit(input));
                            }}
                          />
                        </Box>
                      ))}
                    </Box>

                    {/* add image */}
                    <Box
                      sx={{
                        display: 'flex',
                        mb: '7px',
                        ml: '20px',
                        alignItems: 'center',
                      }}
                    >
                      {image.length < 3 && (
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: theme.palette.primary.main,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              document.getElementById('input_img').click();
                            }}
                          >
                            <input
                              accept="image/*"
                              id="input_img"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) =>
                                dispatch(inputMiniHubImageEdit(e))
                              }
                            />
                            {!isUploadImage ? (
                              <>
                                <AddIcon />
                                <Typography
                                  sx={{ fontSize: '17px', fontWeight: 'bold' }}
                                >
                                  Add More
                                </Typography>
                              </>
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <CircularProgress size={18} />
                                <Typography> uploading...</Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>

                {/* video */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      pb: `${video.length === 0 ? '16px' : '9px'}`,
                    }}
                  >
                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                      Video
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#9E9D9D',
                      }}
                    >
                      ( max size : 100MB )
                    </Typography>
                    {isMaxSizeVideo && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ErrorOutlineIcon
                          sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            color: theme.palette.warning.main,
                            mr: '4px',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '8px',
                            fontWeight: '500',
                            color: theme.palette.warning.main,
                          }}
                        >
                          The video is too big
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {video.length === 0 && (
                        <Box
                          sx={{
                            height: '54px',
                            width: '54px',
                            aspectRatio: '1/1',
                            borderRadius: '8px',
                            bgcolor: '#F5F5F5',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderStyle: 'dashed',
                            borderColor: '#9E9D9D',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            document.getElementById('inputMenu_video').click();
                          }}
                        >
                          <VideocamOutlinedIcon
                            sx={{ color: '#9E9D9D', fontSize: '28px' }}
                          />
                        </Box>
                      )}
                      {video.map((input, index) => (
                        <Box
                          sx={{
                            position: 'relative',
                            overflow: 'visible',
                            padding: '7px',
                          }}
                          key={index}
                        >
                          <CancelIcon
                            sx={{
                              position: 'absolute',
                              fontSize: '20px',
                              top: 1,
                              right: 1,
                              zIndex: +1,
                              cursor: 'pointer',
                            }}
                            onClick={() => dispatch(deleteTayangan(input))}
                          />
                          <Box
                            onClick={() => {
                              dispatch(clickIsVideoPreviewEdit());
                              dispatch(changeVideoPreviewEdit(input));
                            }}
                          >
                            <video
                              style={{
                                maxHeight: '54px',
                                maxWidth: '54px',
                                aspectRatio: '1/1',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                backgroundColor: 'black',
                              }}
                            >
                              <source
                                src={
                                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                                  '/' +
                                  input +
                                  '#t=0.5'
                                }
                              />
                            </video>
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        mb: '7px',
                        ml: '20px',
                        alignItems: 'center',
                      }}
                    >
                      {video.length < 3 && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.primary.main,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            document.getElementById('inputMenu_video').click();
                          }}
                        >
                          <input
                            // accept="video/*"
                            id="inputMenu_video"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => dispatch(inputTayangan(e))}
                          />
                          {!isLoadingVideo ? (
                            <>
                              <AddIcon />
                              <Typography
                                sx={{ fontSize: '17px', fontWeight: 'bold' }}
                              >
                                Add More
                              </Typography>
                            </>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <CircularProgress size={18} />
                              <Typography> uploading...</Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>

                {/* PIC */}
                <Grid item xs={6}>
                  <Typography
                    sx={{ mb: 2, fontSize: '17px', fontWeight: 'bold' }}
                  >
                    PIC*
                  </Typography>
                  <Autocomplete
                    options={picList}
                    value={picChoose}
                    inputValue={picSearch}
                    getOptionLabel={(option) => option.namaLengkap}
                    loading={picLoading}
                    onChange={(_, newValue) => {
                      dispatch(changePicChooseEdit(newValue));
                      dispatch(changePicPhoneEdit(newValue.telpon));
                    }}
                    onInputChange={(_, newInputValue) => {
                      dispatch(changePicSearchEdit(newInputValue));
                      picSearchInDebounce(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search"
                        sx={{ bgcolor: '#fafafa', borderRadius: '5px' }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li key={option.idKustomer} {...props}>
                        {option.namaLengkap}
                      </li>
                    )}
                  />
                </Grid>

                {/* phone number */}
                <Grid item xs={6}>
                  <Typography
                    sx={{ mb: 2, fontSize: '17px', fontWeight: 'bold' }}
                  >
                    Phone Number*
                  </Typography>
                  <CustomInput
                    sx={{
                      width: '100%',
                      bgcolor: '#fafafa',
                      borderRadius: '5px',
                    }}
                    type="number"
                    placeholder="Enter Phone Number"
                    value={picPhone}
                    onChange={(e) => {
                      dispatch(changePicPhoneEdit(e.target.value));
                    }}
                  />
                </Grid>

                {/* //* Address */}
                <Grid item xs={6} onClick={() => setIsMapOpen(!isMapOpen)}>
                  <CustomCard
                    sx={{ p: '8px 16px', cursor: 'pointer' }}
                    // onClick={() => setIsMapOpen(!isMapOpen)}
                  >
                    <Box
                      sx={{
                        display: 'center',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Box
                          sx={{
                            width: '66px',
                            height: '66px',
                            bgcolor: 'gray',
                            borderRadius: '8px',
                            position: 'relative',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              bgcolor: 'white',
                              width: '100%',
                              height: '100%',
                              zIndex: '+2',
                              opacity: 0.5,
                            }}
                          ></Box>
                          <Box
                            sx={{
                              maxHeight: '66px',
                              overflow: 'hidden',
                              borderRadius: '8px',
                            }}
                          >
                            <Map
                              defaultCenter={coordinate}
                              height={130}
                              defaultZoom={17}
                              width={66}
                              style={{
                                borderRadius: '8px',
                              }}
                            ></Map>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: '500', mb: '4px', ml: '8px' }}
                          >
                            Address
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.4,
                            }}
                          >
                            <LocationOnIcon color="primary" />
                            <Typography>{address}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </CustomCard>
                </Grid>

                {/* //* Rack  */}
                <Grid item xs={6}>
                  <CustomCard
                    sx={{ p: '8px 16px', cursor: 'pointer' }}
                    onClick={() => dispatch(changeIsCreateMap())}
                  >
                    <Box
                      sx={{
                        display: 'center',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Box
                          sx={{
                            height: '66px',
                            borderRadius: '8px',
                          }}
                        ></Box>
                        <Box sx={{ ml: '8px' }}>
                          <Typography sx={{ fontWeight: '500', mb: '4px' }}>
                            Racks Map*
                          </Typography>
                          <Typography
                            sx={{ color: '#9E9D9D', fontSize: '12px' }}
                          >
                            Click to create map
                          </Typography>
                        </Box>
                      </Box>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </CustomCard>
                </Grid>

                {/* event Button */}
                <Box
                  sx={{
                    width: '100%',
                    bgcolor: 'white',
                    position: 'fixed',
                    bottom: '0px',
                    right: '0px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '18px 32px 18px 240px',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                      Status
                    </Typography>
                    <CustomAntSwitch
                      checked={statusHub}
                      onChange={(e) => handleEditStatus(e)}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      sx={{ width: '140px', height: '36px' }}
                      // onClick={handleDelete}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          letterSpacing: '1px',
                        }}
                      >
                        Delete
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      sx={{ width: '140px', height: '36px' }}
                      onClick={() => handleSave()}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          letterSpacing: '1px',
                        }}
                      >
                        Save
                      </Typography>
                    </CustomButton>
                  </Box>
                </Box>

                {/* //* Dialog Photo */}
                <Dialog
                  open={isImagePreview}
                  onClose={() => {
                    dispatch(clickIsPreviewImageEdit());
                    dispatch(changeImagePreviewEdit(''));
                  }}
                >
                  <Box
                    component="img"
                    src={
                      process.env.REACT_APP_API_IMAGE_BASE_URL +
                      '/' +
                      linkImagePreview
                    }
                    sx={{
                      width: '600px',
                      height: '600px',
                      aspectRation: '1/1',
                    }}
                  />
                </Dialog>

                {/* //* Video Dialoge */}
                <Dialog
                  open={isVideoPreview}
                  onClose={() => {
                    dispatch(clickIsVideoPreviewEdit());
                    dispatch(changeVideoPreviewEdit(''));
                  }}
                >
                  <Box sx={{ bgcolor: 'black' }}>
                    <video
                      style={{
                        width: '600px',
                        height: '600px',
                        backgroundColor: 'black',
                      }}
                      controls
                    >
                      <source
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                          '/' +
                          linkVideoPreview
                        }
                      />
                    </video>
                  </Box>
                </Dialog>

                {/* //* Dialog Address */}
                <Dialog
                  open={isMapOpen}
                  onClose={() => {
                    setIsMapOpen(!isMapOpen);
                  }}
                >
                  <DialogTitle
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box>Input Address</Box>
                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setIsMapOpen(false)}
                    />
                  </DialogTitle>
                  <DialogContent dividers>
                    <SetupAddressMiniHub setIsOpen={setIsMapOpen} />
                  </DialogContent>
                </Dialog>
              </Grid>
            </Box>
          </Box>
        </>
      ) : (
        <CreateMapMiniHubEdit />
      )}
    </>
  );
}
export default EditMiniHub;
