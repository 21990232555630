import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import CustomButton from '../../../../../../components/shared/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import CustomTable from '../../../../../../components/shared/CustomTable';
import { ReactComponent as ColumnFilterIcon } from '../../../../../../assets/colum-filter-icon.svg';
import {
  FilterAlt as FilterAltIcon,
  ArrowRightAlt as ArrowRightAltIcon,
  SyncAlt as SyncAltIcon,
} from '@mui/icons-material';
import CustomMenu from '../../../../../../components/shared/CustomMenu';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeIsSortAscRequestPurchase,
  getAllDataPurchaseRequest,
  getEndDateFilterFilter,
  getIsLoadingPurchase,
  getIsSortAscRequestPurchase,
  getListPurchaseRequest,
  getPageInfoPurchaseRequest,
  getStartDateFilterFilter,
  getSubsidiaryDetailRequest,
} from '../../../../../../store/purchase/purchaseAdmin/purchaseRequest';
import { changeIdPurchase } from '../../../../../../store/purchase/purchaseAdmin/detailRequestPurchase';
import {
  changePage,
  changePurchaseRoutingState,
  setHirarkiPurchase,
} from '../../../../../../store/Routing/purchaseRouting';

import DialogFilterPurchaseRequest from './DialogFilterPurchaseRequest';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
});

const color = {
  lesstwo: '#000000',
  lesssix: '#FD7900',
  mostseven: '#F33A3A',
};
const TablePurchase = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const listData = useSelector(getAllDataPurchaseRequest);
  const isLoadingPurchase = useSelector(getIsLoadingPurchase);
  const role = localStorage.getItem('role');
  const [isFilter, setIsFilter] = useState(false);
  const subsidiary = useSelector(getSubsidiaryDetailRequest);
  const startDateFilter = useSelector(getStartDateFilterFilter);
  const endDateFilter = useSelector(getEndDateFilterFilter);

  const [openColumnFilter, setOpenColumnFilter] = useState(null);
  const isOpenColumnFilter = Boolean(openColumnFilter);

  // table
  const [purchaseKeyword, setPurchaseKeyword] = useState('');
  const isSortAsc = useSelector(getIsSortAscRequestPurchase);
  const pageInfo = useSelector(getPageInfoPurchaseRequest);
  const totalTableItems = pageInfo?.total;

  const rowPerPageValues = [5, 10, 15, 20, 25];
  const [rowPerPageTable, setRowPerPageTable] = useState(rowPerPageValues[0]);
  const [currentPageTable, setCurrentPageTable] = useState(1);

  const [columnArray, setColumnArray] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState([]);
  const isSelected = (name) => selectedColumn.indexOf(name) !== -1;

  const handleSelectedColumn = (id) => {
    if (selectedColumn.includes(id)) {
      setSelectedColumn([...selectedColumn].filter((value) => value !== id));
    } else {
      setSelectedColumn([...selectedColumn, id]);
    }
  };

  const [sortTable, setSortTable] = useState('');
  const [asc, setAsc] = useState('asc');

  // console.log({ sortTable: asc });
  const [filterAdjustSelected, setFilterAdjustSelected] = useState([
    'reqId',
    'subsidiary',
    'user',
    'sku',
    'date',
  ]);

  useEffect(() => {
    let params = {};
    if (subsidiary !== '' && startDateFilter !== '') {
      params.page = currentPageTable;
      params.pageSize = rowPerPageTable;
      params.search = purchaseKeyword;
      params.subsidiary = subsidiary;
      params.startDate = startDateFilter;
      params.endDate = endDateFilter;
    } else if (subsidiary !== '') {
      params.page = currentPageTable;
      params.pageSize = rowPerPageTable;
      params.search = purchaseKeyword;
      params.subsidiary = subsidiary;
    } else if (startDateFilter !== '') {
      params.page = currentPageTable;
      params.pageSize = rowPerPageTable;
      params.search = purchaseKeyword;
      params.startDate = startDateFilter;
      params.endDate = endDateFilter;
    } else {
      params.page = currentPageTable;
      params.pageSize = rowPerPageTable;
      params.search = purchaseKeyword;
      params.sortSku = sortTable !== 'sortSku' ? '' : asc;
      params.sortDate = sortTable !== 'sortDate' ? '' : asc;
      params.sortSubsidiary = sortTable !== 'sortSubsidiary' ? '' : asc;
      params.sortRequest = sortTable !== 'sortRequest' ? '' : asc;
      params.sortUser = sortTable !== 'sortUser' ? '' : asc;
    }
    dispatch(getListPurchaseRequest(params));
  }, [
    currentPageTable,
    subsidiary,
    startDateFilter,
    endDateFilter,
    rowPerPageTable,
    purchaseKeyword,
    sortTable,
    asc,
  ]);

  const handleAddRequest = () => {
    dispatch(changePage('requestAdd'));
  };

  const handleClickRow = (iDpurchase, subsidiaryName, hubName) => {
    if (Number(role) === 4) {
      dispatch(changePage('requestDetailFinance'));
      dispatch(
        changePurchaseRoutingState({ key: 'idRequest', value: iDpurchase })
      );
      dispatch(
        setHirarkiPurchase({ subsidiary: subsidiaryName, hub: hubName })
      );
    } else {
      dispatch(changePage('requestDetail'));
      dispatch(changeIdPurchase(iDpurchase));
      dispatch(
        setHirarkiPurchase({ subsidiary: subsidiaryName, hub: hubName })
      );
    }
  };

  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'row-reverse',
        alignItems: 'center',
      }}
    >
      {role == 2 || role == 3 ? (
        <CustomButton
          sx={{
            bgcolor: '#51B15C',
            color: '#ffffff',
            borderRadius: '8px',
            '&:hover': {
              bgcolor: '#51B15C',
              color: '#ffffff',
            },
            fontSize: '0.875rem',
            px: 2,
          }}
          startIcon={<AddIcon sx={{ width: '14px' }} />}
          onClick={() => {
            handleAddRequest();
          }}
        >
          Add
        </CustomButton>
      ) : (
        <></>
      )}

      <IconButton
        sx={{
          border: '1.5px solid #51B15C',
          borderRadius: '8px',
          p: 1,
        }}
        onClick={(e) => setOpenColumnFilter(e.currentTarget)}
        aria-haspopup="true"
      >
        <ColumnFilterIcon fill="#51B15C" />
      </IconButton>
      <CustomMenu
        open={isOpenColumnFilter}
        openMenu={openColumnFilter}
        setOpenMenu={setOpenColumnFilter}
        sx={{
          '.MuiMenuItem-root': {
            paddingLeft: 0,
            paddingRight: '20px',
            paddingTop: 0,
            paddingBottom: 0,
            borderRadius: '8px',
          },
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', p: '12px' }}
        >
          <Typography sx={{ fontWeight: '400', fontSize: '12px' }}>
            Max 7
          </Typography>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '12px',
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
            onClick={() =>
              setFilterAdjustSelected([
                'sku',
                'date',
                'user',
                'subsidiary',
                'reqId',
              ])
            }
          >
            Reset
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem>
          <Checkbox checked={true} />
          Request ID*
        </MenuItem>
        <MenuItem>
          <Checkbox checked={true} />
          Subsidiary*
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('user')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'user')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'user']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('user')} />
          User
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('sku')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'sku')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'sku']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('sku')} />
          SKU
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('date')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'date')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'date']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('date')} />
          Date
        </MenuItem>

        {columnArray.map((headCell, index) => {
          const isItemSelected = isSelected(headCell.id);
          return (
            <MenuItem key={index}>
              <Checkbox
                checked={isItemSelected}
                onChange={() => handleSelectedColumn(headCell.id)}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={!isItemSelected && selectedColumn.length >= 7}
              />
              {headCell.title}
            </MenuItem>
          );
        })}
      </CustomMenu>

      <CustomButton
        sx={{
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          fontSize: '0.875rem',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
          px: 2,
          py: 0.8,
        }}
        startIcon={<FilterAltIcon />}
        onClick={() => setIsFilter(!isFilter)}
      >
        Filter
      </CustomButton>
      <DialogFilterPurchaseRequest {...{ isFilter, setIsFilter }} />
    </Box>
  );

  return (
    <CustomTable
      rowPerPage={rowPerPageTable}
      handleRowPerPage={(event) => {
        setRowPerPageTable(event.target.value);
        setCurrentPageTable(1);
      }}
      rowPerPageValues={rowPerPageValues}
      currentPage={currentPageTable}
      totalValueItems={totalTableItems}
      handleInputCurrentPage={(event) => {
        if (
          event.target.value >= Math.ceil(totalTableItems / rowPerPageTable)
        ) {
          setCurrentPageTable(Math.ceil(totalTableItems / rowPerPageTable));
        } else if (event.target.value <= 1) {
          setCurrentPageTable(1);
        } else {
          setCurrentPageTable(event.target.value);
        }
      }}
      handleBackArrow={() =>
        setCurrentPageTable(
          currentPageTable <= 1 ? currentPageTable : currentPageTable - 1
        )
      }
      handleForwardArrow={() =>
        setCurrentPageTable(
          currentPageTable >= Math.ceil(totalTableItems / rowPerPageTable)
            ? currentPageTable
            : currentPageTable + 1
        )
      }
      name="request purchase"
      toolbar={toolbar}
      isLoadingTable={isLoadingPurchase}
      searchValue={purchaseKeyword}
      setSearchValue={setPurchaseKeyword}
      isNoData={!isLoadingPurchase && listData?.length === 0 ? true : false}
      noDataText="No Request Puerchase"
    >
      <TableHead>
        <TableRow sx={{ bgcolor: '#FAFAFA' }}>
          <CustomTableCells>No</CustomTableCells>
          <CustomTableCells>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSortTable('sortRequest');
                dispatch(changeIsSortAscRequestPurchase(!isSortAsc));
                if (isSortAsc === true) {
                  setAsc('desc');
                } else {
                  setAsc('asc');
                }
              }}
            >
              <Typography
                sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
              >
                Request Id
              </Typography>
              {sortTable === 'sortRequest' ? (
                isSortAsc ? (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(270deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )
              ) : (
                <SyncAltIcon
                  sx={{
                    fontSize: '12px',
                    transform: 'rotate(90deg)',
                    color: '#9E9D9D',
                  }}
                />
              )}
            </Box>
          </CustomTableCells>
          <CustomTableCells>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSortTable('sortSubsidiary');
                dispatch(changeIsSortAscRequestPurchase(!isSortAsc));
                if (isSortAsc === true) {
                  setAsc('desc');
                } else {
                  setAsc('asc');
                }
              }}
            >
              <Typography
                sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
              >
                Subsidiary
              </Typography>
              {sortTable === 'sortSubsidiary' ? (
                isSortAsc ? (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(270deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )
              ) : (
                <SyncAltIcon
                  sx={{
                    fontSize: '12px',
                    transform: 'rotate(90deg)',
                    color: '#9E9D9D',
                  }}
                />
              )}
            </Box>
          </CustomTableCells>
          <CustomTableCells>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSortTable('sortUser');
                dispatch(changeIsSortAscRequestPurchase(!isSortAsc));
                if (isSortAsc === true) {
                  setAsc('desc');
                } else {
                  setAsc('asc');
                }
              }}
            >
              <Typography
                sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
              >
                User
              </Typography>
              {sortTable === 'sortUser' ? (
                isSortAsc ? (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(270deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )
              ) : (
                <SyncAltIcon
                  sx={{
                    fontSize: '12px',
                    transform: 'rotate(90deg)',
                    color: '#9E9D9D',
                  }}
                />
              )}
            </Box>
          </CustomTableCells>
          {filterAdjustSelected.includes('sku') && (
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortSku');
                  dispatch(changeIsSortAscRequestPurchase(!isSortAsc));
                  if (isSortAsc === true) {
                    setAsc('desc');
                  } else {
                    setAsc('asc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 500, mr: '2px' }}
                >
                  SKU
                </Typography>
                {sortTable === 'sortSku' ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          )}

          {filterAdjustSelected.includes('date') && (
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortDate');
                  dispatch(changeIsSortAscRequestPurchase(!isSortAsc));
                  if (isSortAsc === true) {
                    setAsc('desc');
                  } else {
                    setAsc('asc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Date
                </Typography>
                {sortTable === 'sortDate' ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          )}
        </TableRow>
      </TableHead>

      {!isLoadingPurchase ? (
        <TableBody>
          {listData?.map((dataTable, index) => {
            const {
              date,
              iDpurchase,
              subsidiary,
              user,
              sku,
              dateDays,
              location,
            } = dataTable;
            return (
              <TableRow
                key={index}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClickRow(iDpurchase, subsidiary, location);
                }}
              >
                <CustomTableCells>
                  {(currentPageTable - 1) * rowPerPageTable + (index + 1)}
                </CustomTableCells>
                {filterAdjustSelected.includes('reqId') && (
                  <CustomTableCells>{iDpurchase || '-'}</CustomTableCells>
                )}
                {filterAdjustSelected.includes('subsidiary') && (
                  <CustomTableCells>{subsidiary || '-'}</CustomTableCells>
                )}
                {filterAdjustSelected.includes('user') && (
                  <CustomTableCells>{user || '-'}</CustomTableCells>
                )}
                {filterAdjustSelected.includes('sku') && (
                  <CustomTableCells>{sku || '-'}</CustomTableCells>
                )}
                {filterAdjustSelected.includes('date') && (
                  <CustomTableCells
                    sx={{
                      fontSize: '12px',
                      color:
                        dateDays <= 2
                          ? color.lesstwo
                          : dateDays <= 6
                          ? color.lesssix
                          : color.mostseven,
                    }}
                    onClick={(event) => {
                      // setSubHubSelected(null);
                      // dispatch(changeIdSubHub(id));
                      // setSubHubDetailSelected(id);
                      // setRowSelected(idToko);
                      // dispatch(changeMasterHub(idToko));
                      // dispatch(
                      //   loadSubHubPack({
                      //     subsidiary: subsidiary,
                      //     idSubsidiary: subsidiaryId,
                      //     subHub: name,
                      //     idSubHub: id,
                      //   })
                      // );

                      event.stopPropagation();
                    }}
                  >
                    {date || '-'}
                  </CustomTableCells>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <TableBody>
          {[...Array(rowPerPageTable)].map((_, x) => (
            <TableRow key={x}>
              {filterAdjustSelected.map((_, i) => (
                <TableCell key={i}>-</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      )}
    </CustomTable>
  );
};
export default TablePurchase;
