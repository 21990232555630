import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import Subsidiary from "../../../../../../components/shared/Subsidiary";
import { useState } from "react";
import dayjs from "dayjs";
import {
  ChevronRightIcon,
  ExportIcon,
  FilterAltIcon,
  IsUser,
  SearchIcon,
} from "../../../../../../assets/Icons";
import CustomCard from "../../../../../../components/shared/CustomCard";
import ChartTransactionMobile from "./Component/ChartTransaction";
import TableTransactionMobile from "./Component/TableTransaction";
import CustomButton from "../../../../../../components/shared/CustomButton";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { getAllDataTransaction } from "../../../../../../store/transaction/dataTable";
import { getTransactionTableIsLoading } from "../../../../../../store/transaction/dataTable";
import { getTransactionTable } from "../../../../../../store/transaction/dataTable";
import {
  getDataFilterFrom,
  getDataFilterStatus,
  getDataFilterTo,
} from "../../../../../../store/transaction/request/dataTableRequest";
import DialogFilterTransactionMobile from "./DialogFilter";
import { changePageTransaction } from "../../../../../../store/Routing/transactionRouting";
import { getListRequestDashboard } from "../../../../../../api/transaction";

import {
  changeIdTransactionRequest,
  changeUserEmployee,
} from "../../../../../../store/transaction/request/detailTransactionRequest";

const background = {
  sent: "rgba(234, 205, 87, 0.1)",
  accept: "rgba(81, 177, 92, 0.1)",
};

const color = {
  sent: "#E8C32C",
  accept: "#51B15C",
};

const DashboardTransactionMobile = () => {
  const dispatch = useDispatch();
  const [isFilter, setIsFilter] = useState(false);
  const isLoading = useSelector(getTransactionTableIsLoading);
  const datas = useSelector(getTransactionTable);
  const from = useSelector(getDataFilterFrom);
  const to = useSelector(getDataFilterTo);
  const status = useSelector(getDataFilterStatus);
  const [search, setSearch] = useState("");
  const [purchaseKeyword, setPurchaseKeyword] = useState("");
  const [dataRequest, setDataRequest] = useState(0);

  //! search

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  // ! --------- Subsidiary ---------
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);

  // ---- Grafik ----
  const [timeFrame, setTimeFrame] = useState("daily");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const startDateFilter = dayjs(saveDateRange[0].startDate).format(
    "YYYY-MM-DD"
  );
  const endDateFilter = dayjs(saveDateRange[0].endDate).format("YYYY-MM-DD");

  //!  ----------------------------- Toolbabr ----------------------------
  const toolbar = (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row-reverse",
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "22px",
          height: "22px",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <ExportIcon />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "22px",
          height: "22px",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ width: "14px" }} />
      </CustomButton>
      <DialogFilterTransactionMobile {...{ isFilter, setIsFilter }} />
    </Box>
  );

  // ! ------------------  API -------------

  const fetchListTransaction = async () => {
    let params = {};
    params.nameHub = "subhub"; // untuk nameHub masih kasus super admin, harusnya dibaca berdasarkan letak hub si user yg login
    params.idHub = 3;

    try {
      const {
        data: { data },
      } = await getListRequestDashboard(params);
      if (data?.statusCode !== 500) {
        setDataRequest(data.length);
      } else {
        setDataRequest(0);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchListTransaction();
  }, []);

  useEffect(() => {
    let datas = {};
    datas.pageSize = 4;
    datas.search = purchaseKeyword;
    datas.from = from;
    datas.to = to;
    datas.status = status;
    dispatch(getAllDataTransaction(datas));
  }, [purchaseKeyword, from, to, status]);

  const handleToRequestTransaction = () => {
    dispatch(changePageTransaction("request"));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container columns={12} spacing={3}>
        {/* subsidiary */}
        <Grid item xs={8}>
          <Subsidiary
            subsidiaryChecked={filterSubsidiaryArray}
            setSubsidiaryChecked={setFilterSubsidiaryArray}
            pad={1}
          />
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              p: 2,
              background: "rgba(81, 177, 92, 0.1)",
              display: "flex",
              alignItems: "center",
              gap: 1,
              borderRadius: "8px",
            }}
            onClick={handleToRequestTransaction}
          >
            <IsUser />
            <Box
              sx={{
                width: "14px",
                height: "14px",
                background: "#51B15C",
                borderRadius: "3px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ color: "#FFFFFF", fontSize: "8px" }}>
                {dataRequest}
              </Typography>
            </Box>
            <ChevronRightIcon sx={{ color: "#51B15C" }} />
          </Box>
        </Grid>
      </Grid>

      {/* grafik & table */}
      <Box sx={{ mt: 3 }}>
        {/* grafik */}
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12}>
            <CustomCard sx={{ p: "10px 20px" }}>
              <ChartTransactionMobile
                {...{
                  timeFrame,
                  setTimeFrame,
                  dateRange,
                  setDateRange,
                  saveDateRange,
                  setSaveDateRange,
                }}
              />
            </CustomCard>
          </Grid>

          {/* table */}
          <Grid item xs={12}>
            <TableTransactionMobile />
          </Grid>

          <Grid item xs={12}>
            <CustomCard sx={{ p: "10px 10px" }}>
              <Grid container columns={12} spacing={2}>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      display: "inline-flex",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    Transaction List
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    placeholder={"Search"}
                    size="small"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      debounceOnChange(e.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        paddingLeft: "6px",
                        fontSize: "7px",
                      },
                      bgcolor: "#FAFAFA",
                      minHeight: "22px",
                      //   height: '22px',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ width: "12px" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  {toolbar}
                </Grid>

                {/* mapping data */}
                {isLoading ? (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : datas.length === 0 ? (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        fontSize: "8px",
                        display: "flex",
                        color: "#F33A3A",
                        justifyContent: "center",
                      }}
                    >
                      No Request Purchase
                    </Box>
                  </Grid>
                ) : (
                  datas.map((item, idx) => (
                    <Grid item xs={6} key={idx}>
                      <Box
                        sx={{
                          background: "#FAFAFA",
                          borderRadius: "8px",
                          p: "10px",
                        }}
                        onClick={() => {
                          dispatch(
                            changePageTransaction("detailRequestTransaction")
                          );
                          dispatch(changeIdTransactionRequest(item?.idOrders));
                          dispatch(changeUserEmployee(item?.userEmploye));
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{ fontSize: "10px", fontWeight: 600 }}
                              >
                                Order-
                                {item?.idOrders !== ""
                                  ? item?.idOrders
                                  : "-"}{" "}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: "52px",
                                height: "17px",
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: 600,
                                background:
                                  item?.statusOrder === "accept"
                                    ? background.accept
                                    : item?.statusOrder === "sent"
                                    ? background.sent
                                    : "transparent",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "6px",
                                  color:
                                    item?.statusOrder === "accept"
                                      ? color.accept
                                      : item?.statusOrder === "sent"
                                      ? color.sent
                                      : "#000",
                                }}
                              >
                                {item?.statusOrder}
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontSize: "8px", fontWeight: 500, mt: 1 }}
                            >
                              {item?.tglOrder} / {item?.jamOrder}
                            </Typography>
                          </Box>
                        </Box>

                        {/* details product */}
                        {item?.details?.map((detailProduct, index) => (
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                            key={index}
                          >
                            <Box
                              component="img"
                              src={
                                process.env.REACT_APP_API_IMAGE_BASE_URL +
                                "/" +
                                detailProduct?.product?.gambar
                              }
                              sx={{
                                width: "34px",
                                height: "34px",
                                borderRadius: "5px",
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexFlow: "column",
                                justifyContent: "center",
                                gap: 1,
                                height: "34px",
                              }}
                            >
                              <Typography sx={{ fontSize: "10px" }}>
                                {detailProduct?.product?.namaProduk}
                              </Typography>
                              <Typography sx={{ fontSize: "8px" }}>
                                + {detailProduct?.jumlah} {detailProduct?.unit}
                              </Typography>
                            </Box>
                          </Box>
                        ))}

                        <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              flexFlow: "column",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                height: "24px",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexFlow: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ height: "8px" }}></Box>
                                <Box
                                  sx={{
                                    width: "8px",
                                    height: "8px",
                                    background: "#D9D9D9",
                                    borderRadius: "50%",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "1px",
                                      height: "8px",
                                      background: "#D9D9D9",
                                    }}
                                  ></Box>
                                </Box>
                              </Box>
                              <Typography sx={{ fontSize: "8px" }}>
                                {item?.namaPemberi}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                height: "24px",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexFlow: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "1px",
                                      height: "8px",
                                      background: "#D9D9D9",
                                    }}
                                  ></Box>
                                </Box>
                                <Box
                                  sx={{
                                    width: "8px",
                                    height: "8px",
                                    background: "#51B15C",
                                    borderRadius: "50%",
                                  }}
                                ></Box>
                                <Box sx={{ height: "8px" }}></Box>
                              </Box>
                              <Typography sx={{ fontSize: "8px" }}>
                                {item?.namaTujuan}
                              </Typography>
                            </Box>
                            {/* <Box>
                              <Box></Box>
                              <Typography sx={{ fontSize: '8px' }}>
                                outlet
                              </Typography>
                            </Box> */}
                          </Box>

                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {/* <SkuIcon /> */}
                            <Typography sx={{ fontSize: "8px", ml: "5px" }}>
                              {/* {item?.sku} */}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                )}
              </Grid>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DashboardTransactionMobile;
