import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import { changePageAudit } from '../../../../../../../store/Routing/auditRouting';
import {
  ChevronRightIcon,
  Different,
  Dolar,
  ExportIcon,
  FilterAltIcon,
  SearchIcon,
} from '../../../../../../../assets/Icons';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  getHubDetailAudit,
  getIdDetailAudit,
} from '../../../../../../../store/audit/detailAudit';
import {
  getMissingQrCode,
  getMissingQrCodeGroup,
} from '../../../../../../../api/audit';
import { BorderBottom } from '@mui/icons-material';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import { useRef } from 'react';
import debounce from 'lodash.debounce';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import QRCode from 'react-qr-code';
import {
  changeIdAuditAdjustment,
  changeLocationHub,
  goToAdjustMissingQr,
} from '../../../../../../../store/adjustment/addMissingQrAdjustment';
import { changePageAdjustment } from '../../../../../../../store/Routing/addjustment';

const MissingQrCodeMobile = ({ setValue }) => {
  const dispatch = useDispatch();
  const idAudit = useSelector(getIdDetailAudit);
  const location = useSelector(getHubDetailAudit);
  const [dataMissingQr, setDataMissingQr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAdjust, setIsLoadingAdjust] = useState(false);
  const [adjustMissingQr, setAdjustMissingQr] = useState([]);
  const [missingQr, setMissingQr] = useState([]);

  // ! seacrh
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  // ! --------------------------------- API --------------------

  useEffect(() => {
    const fetchMissingQrCodeGroup = async (id) => {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getMissingQrCodeGroup(id);
        setDataMissingQr(data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMissingQrCodeGroup(idAudit);
  }, [idAudit]);

  useEffect(() => {
    const fetchMissingQrCode = async (id) => {
      setIsLoadingAdjust(true);
      try {
        const {
          data: { data },
        } = await getMissingQrCode(id);
        setMissingQr(data?.missing);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingAdjust(false);
      }
    };
    fetchMissingQrCode(idAudit);
  }, [idAudit]);

  // !----------------- Toolbar -------------------------------
  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        // justifyContent: 'center',
      }}
    >
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
        // onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ width: '14px' }} />
      </CustomButton>
      {/* <DialogFilterAuditMobile {...{ isFilter, setIsFilter }} /> */}
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <ExportIcon />
      </CustomButton>
    </Box>
  );

  const handleCancelSelect = () => {
    setAdjustMissingQr([]);
  };

  const handleToAdjust = () => {
    dispatch(goToAdjustMissingQr(adjustMissingQr));
    setValue(7);
    dispatch(changePageAdjustment('addMissingQrAdjustment'));
    dispatch(changeIdAuditAdjustment(idAudit));
    dispatch(changeLocationHub(location)); 
  };

  // ! handler
  const handleSelectToAdjust = (item, id) => {
    if (adjustMissingQr.filter((val) => val.auditQrId === id).length === 0) {
      setAdjustMissingQr((prev) => [...prev, item]);
    } else {
      setAdjustMissingQr(adjustMissingQr.filter((val) => val.auditQrId !== id));
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <BackButtonHub
            onClick={() => dispatch(changePageAudit('detailsAudits'))}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                color: '#9E9D9D',
                fontSize: '14px',
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              {idAudit} Details
            </Typography>
            <ChevronRightIcon style={{ color: '#9E9D9D' }} />
            <Typography
              sx={{
                color: '#000000',
                fontSize: '14px',
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              Missing Qr Code
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* list group product */}
      <Box
        sx={{ background: '#FAFAFA', borderRadius: '12px', padding: '16px' }}
      >
        <Box>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 500, color: '#000000' }}
          >
            Missing Product
          </Typography>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : dataMissingQr.length !== 0 ? (
            dataMissingQr?.map((item, index) => (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  mt: 2,
                  borderBottom: '1.5px solid #F1F1F1',
                  paddingBottom: '8px',
                }}
                key={index}
              >
                <Box
                  component="img"
                  src={
                    process.env.REACT_APP_API_IMAGE_BASE_URL +
                    '/' +
                    item?.gambar
                  }
                  sx={{
                    width: '45px',
                    height: '45px',
                    borderRadius: '12px',
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '80%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexFlow: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                      padding: '8px 0px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#000000',
                      }}
                    >
                      {item?.namaProduk}
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 400,
                          color: '#9E9D9D',
                        }}
                      >
                        {item?.hub}
                      </Typography>
                      <ChevronRightIcon
                        sx={{
                          fontSize: '12px',
                          fontWeight: 400,
                          color: '#9E9D9D',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 400,
                          color: '#9E9D9D',
                        }}
                      >
                        {item?.rackName}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 600, color: '#000000' }}
                  >
                    {item?.jumlah} Qr Code
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100px',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ color: '#F33A3A', fontSize: '12px' }}>
                No data found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* list missing qr code */}
      <CustomCard sx={{ p: 2 }}>
        {/* title */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 2,
            }}
          >
            <Box>
              <Typography
                sx={{ fontSize: '12px', fontWeight: 600, color: '#000000' }}
              >
                Product
              </Typography>
            </Box>
            <Box>
              <TextField
                placeholder={'Search'}
                size="small"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  debounceOnChange(e.target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    paddingLeft: '6px',
                    fontSize: '7px',
                  },
                  bgcolor: '#FAFAFA',
                  minHeight: '22px',
                  height: '22px',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ width: '12px' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box>
            {adjustMissingQr.length === 0 ? (
              toolbar
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography
                  sx={{
                    cursor: 'pointer',
                    color: '#F33A3A',
                    fontSize: '8px',
                    fontWeight: 600,
                  }}
                  onClick={handleCancelSelect}
                >
                  Cancel
                </Typography>
                <Box onClick={handleToAdjust}>
                  <Typography
                    sx={{
                      background: '#51B15C',
                      color: '#FFFFFF',
                      fontWeight: 600,
                      fontSize: '8px',
                      cursor: 'pointer',
                      borderRadius: '4px',
                      padding: '0px 4px',
                      minWidth: '32px',
                      height: '20px',
                      letterSpacing: '1px',
                      textAlign: 'center',
                      lineHeight: '20px',
                    }}
                  >
                    Adjust
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        {/* type */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mt: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Dolar stroke="#51B15C" />
            <Typography sx={{ fontSize: '8px', fontWeight: 400 }}>
              COGS(Rp)*
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Different />
            <Typography sx={{ fontSize: '8px', fontWeight: 400 }}>
              qty
            </Typography>
          </Box>
        </Box>

        <Grid container columns={12} spacing={2}>
          {isLoadingAdjust ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 3,
                width: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : missingQr.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography sx={{ color: '#F33A3A', fontSize: '12px' }}>
                Data not found
              </Typography>
            </Box>
          ) : (
            missingQr?.map((item, idx) => (
              <Grid item xs={6} key={idx}>
                <Box
                  sx={{
                    background:
                      adjustMissingQr.filter(
                        (val) => val.auditQrId === item.auditQrId
                      ).length !== 0
                        ? 'rgba(81, 177, 92, 0.2)'
                        : '#FAFAFA',
                    borderRadius: '8px',
                    p: 1,
                    mt: 3,
                  }}
                  onClick={() => handleSelectToAdjust(item, item?.auditQrId)}
                >
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <QRCode
                      size={40}
                      style={{
                        height: '40px',
                        maxWidth: '40px',
                        width: '100%',
                        borderRadius: '4px',
                      }}
                      value={item?.encript}
                      viewBox={`0 0 40 40`}
                    /> 
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          sx={{
                            fontSize: '8px',
                            fontWeight: 600,
                            color: '#9E9D9D',
                          }}
                        >
                          {item?.hub.slice(0, 12)}...
                        </Typography>
                        <ChevronRightIcon
                          sx={{
                            fontSize: '8px',
                            fontWeight: 600,
                            color: '#9E9D9D',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '8px',
                            fontWeight: 600,
                            color: '#9E9D9D',
                          }}
                        >
                          {item?.rackName}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '10px',
                          fontWeight: 600,
                          color: '#000000',
                          mt: 1,
                        }}
                      >
                        {item?.namaProduk}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1.5,
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <Dolar stroke="#51B15C" />
                      <Typography sx={{ fontSize: '8px', fontWeight: 400 }}>
                        {item?.cogs}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Different />
                      <Typography sx={{ fontSize: '8px', fontWeight: 400 }}>
                        {item?.qty} {item?.systemUnit}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
      </CustomCard>
    </>
  );
};
export default MissingQrCodeMobile;
