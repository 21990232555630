import React from "react";
import {
  AppBar as MuiAppBar,
  Box,
  CssBaseline,
  Typography,
  Toolbar,
  IconButton,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  SettingsSharp as SettingsSharpIcon,
  SortSharp as SortSharpIcon,
} from "@mui/icons-material";
import { bob } from "../assets";
import LogoAlere from "../assets/logo.png";
import logoImage from "../assets/logo.png";
import dayjs from "dayjs";
import { ReactComponent as ProfileImage } from "../assets/profile-picture.svg";
import useAuth from "../hooks/useAuth";
import { useLocation } from "react-router-dom";

const lastUpdatedDateTime = dayjs().format("DD/MMM/YY, HH:mm");

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MobileHeaderComponent = ({ open, setOpen }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const companyIdThis = localStorage.getItem("companyId");

  // console.log(auth);

  const handleChangeCompany = ({ companyId }) => {
    // console.log(companyId);
    if (companyId === 9) {
      localStorage.setItem("companyId", 7);
      window.location.reload();
    } else {
      localStorage.setItem("companyId", 9);
      window.location.reload();
    }
    // console.log(companyId);
    // switch (companyId) {
    //   case 7:
    //     localStorage.setItem('companyId', 9);
    //     break;
    //   case 9:
    //     localStorage.setItem('companyId', 7);
    //     break;
    //   default:
    //     localStorage.setItem('companyId', 7);
    //     break;
    // }
    // window.location.reload();
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            px: 2.5,
            color: "primary.black",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: 1,
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="start"
              sx={{
                mr: 2,
              }}
            >
              <Badge
                badgeContent="New"
                overlap="circular"
                componentsProps={{
                  badge: {
                    sx: {
                      fontSize: "0.375rem",
                      width: "0.625rem",
                      height: "0.625rem",
                      bgcolor: "#F28AAE",
                      color: "common.white",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    height: "1.75rem",
                    width: "1.75rem",
                    borderRadius: 1,
                  }}
                  component="img"
                  src={logoImage}
                />
              </Badge>
            </IconButton>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {location?.pathname === "/sales-dashboard" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "semiBold",
                      pb: 0.5,
                      color: "primary.main",
                      mt: 0.5,
                    }}
                  >
                    Sales
                    <span
                      style={{
                        fontWeight: "inherit",
                        fontSize: "0.6rem",
                      }}
                    >
                      (s)
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0.25,
                      color: "grey.500",
                      fontWeight: "semiBold",
                      fontSize: "0.5rem",
                    }}
                  >
                    as at {lastUpdatedDateTime.split(" ")[0]}{" "}
                    <span
                      style={{
                        color: "black",
                        fontWeight: "inherit",
                        fontSize: "inherit",
                      }}
                    >
                      {lastUpdatedDateTime.split(" ")[1]}
                    </span>
                  </Typography>
                </Box>
              ) : location?.pathname === "/treatment-dashboard" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "semiBold",
                      pb: 0.5,
                      color: "primary.main",
                      mt: 0.5,
                    }}
                  >
                    Treatment
                    <span
                      style={{
                        fontWeight: "inherit",
                        fontSize: "0.6rem",
                      }}
                    >
                      (s)
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0.25,
                      color: "grey.500",
                      fontWeight: "semiBold",
                      fontSize: "0.5rem",
                    }}
                  >
                    as at {lastUpdatedDateTime.split(" ")[0]}{" "}
                    <span
                      style={{
                        color: "black",
                        fontWeight: "inherit",
                        fontSize: "inherit",
                      }}
                    >
                      {lastUpdatedDateTime.split(" ")[1]}
                    </span>
                  </Typography>
                </Box>
              ) : location?.pathname === "/dashboard" ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: "semiBold",
                        pb: 0.5,
                        color: "primary.main",
                        mt: 0.5,
                      }}
                    >
                      Main Dashboard
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.25,
                        color: "grey.500",
                        fontWeight: "semiBold",
                        fontSize: "0.5rem",
                      }}
                    >
                      as at {lastUpdatedDateTime.split(" ")[0]}{" "}
                      <span
                        style={{
                          color: "black",
                          fontWeight: "inherit",
                          fontSize: "inherit",
                        }}
                      >
                        {lastUpdatedDateTime.split(" ")[1]}
                      </span>
                    </Typography>
                  </Box>
                  {auth?.role === "vip" && (
                    <Box component={"img"} src={bob} sx={{ width: "36px" }} />
                  )}
                </Box>
              ) : location.pathname === "/assets/inventory" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "semiBold",
                      pb: 0.5,
                      color: "primary.main",
                      mt: 0.5,
                    }}
                  >
                    Inventory
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0.25,
                      color: "grey.500",
                      fontWeight: "semiBold",
                      fontSize: "0.5rem",
                    }}
                  >
                    as at {lastUpdatedDateTime.split(" ")[0]}{" "}
                    <span
                      style={{
                        color: "black",
                        fontWeight: "inherit",
                        fontSize: "inherit",
                      }}
                    >
                      {lastUpdatedDateTime.split(" ")[1]}
                    </span>
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() =>
                (auth?.role === "vip" ||
                  auth?.email === "investment@alere.id") &&
                handleChangeCompany({ companyId: auth?.companyId })
              }
            >
              <Box>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "0.875rem",
                    color: auth?.companyId === 9 ? "#EACB01" : "#F1202C",
                  }}
                >
                  {auth?.companyId === 9 ? "KANPAI" : "MRI"}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "0.5rem",
                    color: auth?.companyId === 9 ? "#EACB01" : "#F1202C",
                  }}
                >
                  .id
                </Typography>
              </Box>
              <Typography sx={{ fontSize: "0.5rem" }} color="primary">
                by Alere
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MobileHeaderComponent;
