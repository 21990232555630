import { createSlice } from '@reduxjs/toolkit';
import { getAllHubs, getAllMiniHub, getAllSubHub } from '../../api/hubs';
import { getAllListProduct } from '../../api/products';

export const AddRequestPurchase = createSlice({
  name: 'requestpurchase',
  initialState: {
    isLoading: false,
    hubLoading: false,
    name: '',
    qty: '',
    subsidiaryId: 1,
    description: '',

    // Hubs
    masterHub: [],
    masterHubId: 0,
    subHub: [],
    subHubId: null,
    miniHub: [],
    miniHubId: null,
    hubs: [],

    hubChoose: null,
    hubSearch: '',
    hubSearchDebounce: '',
    subHubChoose: null,
    subHubSearch: '',
    subHubSearchDebounce: '',
    miniHubChoose: null,
    miniHubSearch: '',
    miniHubSearchDebounce: '',

    // category
    isHubOpen: false,

    // product
    product: [],
    productChoose: null,
    productTest: [],
    productSearch: '',
    productSearchDebounce: '',

    items: [],
    units: [],
  },
  reducers: {
    changeSubsidiaryId: (state, action) => {
      state.subsidiaryId = action.payload;
    },
    changeDescription: (state, action) => {
      state.description = action.payload;
    },

    // ------- hubs -------
    changeMasterHub: (state, action) => {
      state.masterHub = action.payload;
    },
    changeMasterHubId: (state, action) => {
      state.masterHubId = action.payload;
    },
    changeSubHub: (state, action) => {
      state.subHub = action.payload;
    },
    changeSubHubId: (state, action) => {
      state.subHubId = action.payload;
    },
    changeMiniHub: (state, action) => {
      state.miniHub = action.payload;
    },
    changeMiniHubId: (state, action) => {
      state.miniHubId = action.payload;
    },

    changePurchaseName: (state, action) => {
      state.name = action.payload;
    },
    changePurchaseQty: (state, action) => {
      state.qty = action.payload;
    },

    changeHubs: (state, action) => {
      state.hubs = action.payload;
    },

    // hub select
    changeHubChoose: (state, action) => {
      state.hubChoose = action.payload;
    },
    changeHubSearch: (state, action) => {
      state.hubSearch = action.payload;
    },
    changeHubSearchDebounce: (state, action) => {
      state.hubSearchDebounce = action.payload;
    },
    changeSubHubChoose: (state, action) => {
      state.subHubChoose = action.payload;
    },
    changeSubHubSearch: (state, action) => {
      state.subHubSearch = action.payload;
    },
    changeSubHubSearchDebounce: (state, action) => {
      state.subHubSearchDebounce = action.payload;
    },
    changeMiniHubChoose: (state, action) => {
      state.miniHubChoose = action.payload;
    },
    changeMiniHubSearch: (state, action) => {
      state.miniHubSearch = action.payload;
    },
    changeMiniHubSearchDebounce: (state, action) => {
      state.miniHubSearchDebounce = action.payload;
    },

    changeIsHubOpen: (state, action) => {
      state.isHubOpen = action.payload;
    },

    changeLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    // product
    changeProduct: (state, action) => {
      state.product = action.payload;
    },
    changeProductChoose: (state, action) => {
      state.productChoose = action.payload;
    },
    changeProductSearch: (state, action) => {
      state.productSearch = action.payload;
    },
    changeProductSearchDebounce: (state, action) => {
      state.productSearchDebounce = action.payload;
    },
    changeProductTest: (state, action) => {
      state.productTest = action.payload;
    },

    // items
    changeItems: (state, action) => {
      state.items = action.payload;
    },
    changeUnits: (state, action) => {
      state.units = action.payload;
    },
    clearData: (state) => {
      state.name = '';
      state.qty = '';
      state.description = '';

      // Hubs
      state.masterHub = [];
      state.masterHubId = 0;
      state.subHub = [];
      state.subHubId = 0;
      state.miniHub = [];
      state.miniHubId = 0;
      state.hubs = [];

      state.hubChoose = null;
      state.hubSearch = '';
      state.hubSearchDebounce = '';
      state.subHubChoose = null;
      state.subHubSearch = '';
      state.subHubSearchDebounce = '';
      state.miniHubChoose = null;
      state.miniHubSearch = '';
      state.miniHubSearchDebounce = '';

      // category
      state.isHubOpen = false;

      // product
      state.product = [];
      state.productChoose = null;
      state.productTest = [];
      state.productSearch = '';
      state.productSearchDebounce = '';

      state.items = [];
      state.units = [];
    },
  },
});

export const {
  changeSubsidiaryId,
  changePurchaseName,
  changePurchaseQty,
  changeDescription,

  // hubs
  changeMasterHub,
  changeMasterHubId,
  changeSubHub,
  changeSubHubId,
  changeMiniHub,
  changeMiniHubId,
  changeHubs,

  changeHubChoose,
  changeHubSearch,
  changeHubSearchDebounce,
  changeSubHubChoose,
  changeSubHubSearch,
  changeSubHubSearchDebounce,
  changeMiniHubChoose,
  changeMiniHubSearch,
  changeMiniHubSearchDebounce,

  changeLoading,

  // category
  changeIsHubOpen,

  // product
  changeProduct,
  changeProductChoose,
  changeProductSearch,
  changeProductSearchDebounce,
  changeProductTest,

  // items
  changeItems,
  changeUnits,
  clearData,
} = AddRequestPurchase.actions;

export const getAllPurchase = (state) => state.requestpurchase;
export const getSubsidiaryId = (state) => state.requestpurchase.subsidiaryId;
export const getPurchaseName = (state) => state.requestpurchase.name;
export const getPurchaseQty = (state) => state.requestpurchase.qty;
export const getDescriptionPurchase = (state) =>
  state.requestpurchase.description;

// -------------- hub -----------------------
export const getMasterHubId = (state) => state.requestpurchase.masterHubId;
export const getMasterHubPurchase = (state) => state.requestpurchase.masterHub;
export const getSubHubId = (state) => state.requestpurchase.subHubId;
export const getSubHubPurchase = (state) => state.requestpurchase.subHub;
export const getMiniHubId = (state) => state.requestpurchase.miniHubId;
export const getMiniHubPurchase = (state) => state.requestpurchase.miniHub;
export const getAllFixHubs = (state) => state.requestpurchase.hubs;

export const getHubLoading = (state) => state.requestpurchase.hubLoading;
export const getHubChoosePurchase = (state) => state.requestpurchase.hubChoose;
export const getHubSearchPurchase = (state) => state.requestpurchase.hubSearch;
export const getHubSearchDebouncePurchase = (state) =>
  state.requestpurchase.hubSearchDebounce;

export const getSubHubChoosePurchase = (state) =>
  state.requestpurchase.subHubChoose;
export const getSubHubSearchPurchase = (state) =>
  state.requestpurchase.subHubSearch;
export const getSubHubSearchDebouncePurchase = (state) =>
  state.requestpurchase.subHubSearchDebounce;

export const getMiniHubChoosePurchase = (state) =>
  state.requestpurchase.miniHubChoose;
export const getMiniHubSearchPurchase = (state) =>
  state.requestpurchase.miniHubSearch;
export const getMiniHubSearchDebouncePurchase = (state) =>
  state.requestpurchase.miniHubSearchDebounce;

export const getIsHubOpen = (state) => state.requestpurchase.isHubOpen;

// product
export const getListProduct = (state) => state.requestpurchase.product;
export const getProductLoading = (state) => state.requestpurchase.isLoading;
export const getProductChoosePurchase = (state) =>
  state.requestpurchase.productChoose;
export const getProductSearchPurchase = (state) =>
  state.requestpurchase.productSearch;
export const getProductSearchDebouncePurchase = (state) =>
  state.requestpurchase.productSearchDebounce;
export const getProductPhonePurchase = (state) =>
  state.requestpurchase.productPhone;

export const getProductTest = (state) => state.requestpurchase.productTest;

// ---- items ----
export const getItemsPurchase = (state) => state.requestpurchase.items;

// units

export const getDataUnits = (state) => state.requestpurchase.units;

// ---------------- API ----------------

// hub
export const getDataMasterHubPurchase = (id, search) => (dispatch) => {
  const fetchMasterHub = async () => {
    let params = {};
    params.subsidiaryId = [id];
    params.search = search;
    params.page = 1;
    params.pageSize = 5;

    try {
      const {
        data: {
          data: { hubs },
        },
      } = await getAllHubs(params);
      dispatch(changeMasterHub(hubs));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  fetchMasterHub();
};

export const getDataSubHubPurchase = (id) => (dispatch) => {
  const fetchSubHub = async () => {
    let params = {};
    params.idToko = id;
    params.page = 1;
    params.pageSize = 5;

    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllSubHub(params);
      dispatch(changeSubHub(subHubs));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  fetchSubHub();
};

export const getDataMiniHubPurchase = (id) => (dispatch) => {
  const fetchMiniHub = async () => {
    let params = {};
    params.subHubId = id;
    params.page = 1;
    params.pageSize = 5;

    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllMiniHub(params);
      dispatch(changeMiniHub(subHubs));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  fetchMiniHub();
};

// product
export const getDatalistProduct = () => (dispatch) => {
  const fetchProduct = async () => {
    let params = {};
    params.page = 1;
    params.pageSize = 5;

    try {
      const {
        data: {
          data: { products },
        },
      } = await getAllListProduct(params);
      dispatch(changeProduct(products));
    } catch (err) {
      console.error(err);
    }
  };
  fetchProduct();
};

export default AddRequestPurchase.reducer;
