import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, MenuItem, useMediaQuery } from '@mui/material';
import Subsidiary from '../../../../../../../components/shared/Subsidiary';
import CustomSelect from '../../../../../../../components/shared/CustomSelect';
import dayjs from 'dayjs';
import CustomDateRange from '../../../../../../../components/shared/CustomDateRange';
import Table from './Table';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import GrafikComparisonHub from '../../Component/GrafikComparison';
import CustomNotifSuccess from '../../../../../../../components/shared/CustomNotifSuccess';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearNotifHub,
  getNotifHub,
} from '../../../../../../../store/hub/notifHub';
import MasterHubMobile from '../Mobile';

const MasterHub = ({
  setTreatmentsTimeframe,
  treatmentsTimeframe,
  rowSelected,
  setRowSelected,
  hubsData,
  setHubsData,
  rowPerPageTable,
  setRowPerPageTable,
  rowPerPageValues,
  currentPageTable,
  setCurrentPageTable,
  totalTableItems,
  setTotalTableItems,
  setIsOpenMasterHub,
  subHubSelected,
  setSubHubSelected,
  isLoadingMasterHub,
  masterHubKeyword,
  setMasterHubKeyword,
  subsidiaryDatas,
  setFilterSubsidiaryArray,
  sortTableMasterHub,
  setSortTableMasterHub,
  isSortAscMasterHub,
  setIsSortAscMasterHub,
  filterSubsidiaryArray,
}) => {
  const dispatch = useDispatch();
  const matchTablet = useMediaQuery('(min-width:832px)');
  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(1, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(1, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const { key, text: notifText } = useSelector(getNotifHub);

  return (
    <>
      <CustomNotifSuccess
        text={notifText}
        open={key === 'addhub'}
        onClose={() => {
          dispatch(clearNotifHub());
        }}
      />
      {!matchTablet ? (
        <MasterHubMobile />
      ) : (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Subsidiary
            subsidiaryChecked={filterSubsidiaryArray}
            setSubsidiaryChecked={setFilterSubsidiaryArray}
          />
          {/* <CustomCard sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '1rem',
                  color: 'primary.black',
                }}
              >
                Master Comparison
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <CustomSelect
                  inputProps={{
                    sx: {
                      fontSize: '0.75rem',
                      fontWeight: 500,
                    },
                  }}
                  sx={{
                    '& .MuiSelect-icon': {
                      right: '4px',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#000',
                      fontSize: '1.25rem',
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '5px 6px 5px 10px',
                    },
                  }}
                  value={treatmentsTimeframe}
                  onChange={(e) => setTreatmentsTimeframe(e.target.value)}
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </CustomSelect>
                <CustomDateRange
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  saveDateRange={saveDateRange}
                  setSaveDateRange={setSaveDateRange}
                  sx={{ maxHeight: '34px', pl: '10px', fontSize: '12px' }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
          </Box>
        </CustomCard> */}

          {/* grafik & table  */}
          <GrafikComparisonHub />

          <Table
            rowSelected={rowSelected}
            setRowSelected={setRowSelected}
            hubsData={hubsData}
            setHubsData={setHubsData}
            rowPerPageTable={rowPerPageTable}
            setRowPerPageTable={setRowPerPageTable}
            rowPerPageValues={rowPerPageValues}
            currentPageTable={currentPageTable}
            setCurrentPageTable={setCurrentPageTable}
            totalTableItems={totalTableItems}
            setTotalTableItems={setTotalTableItems}
            setIsOpenMasterHub={setIsOpenMasterHub}
            subHubSelected={subHubSelected}
            setSubHubSelected={setSubHubSelected}
            masterHubKeyword={masterHubKeyword}
            setMasterHubKeyword={setMasterHubKeyword}
            subsidiaryDatas={subsidiaryDatas}
            setFilterSubsidiaryArray={setFilterSubsidiaryArray}
            isLoadingMasterHub={isLoadingMasterHub}
            sortTableMasterHub={sortTableMasterHub}
            setSortTableMasterHub={setSortTableMasterHub}
            isSortAscMasterHub={isSortAscMasterHub}
            setIsSortAscMasterHub={setIsSortAscMasterHub}
          />
        </Box>
      )}
    </>
  );
};

export default MasterHub;
