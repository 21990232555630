import React, { useState, useEffect } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Divider,
  MenuItem,
  Checkbox,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomTable from '../../../../../../components/shared/CustomTable';
import CustomButton from '../../../../../../components/shared/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as ColumnFilterIcon } from '../../../../../../assets/colum-filter-icon.svg';
import CustomMenu from '../../../../../../components/shared/CustomMenu';
import {
  ExitToApp as ExitToAppIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Person as PersonIcon,
  ArrowRightAlt as ArrowRightAltIcon,
  SyncAlt as SyncAltIcon,
} from '@mui/icons-material';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import { getAllSubHub } from '../../../../../../api/hubs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { async } from 'q';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
});

const headCells = [
  {
    id: 1,
    numeric: false,
    disablePadding: false,
    title: 'No',
    icon: false,
  },
  {
    id: 2,
    numeric: false,
    disablePadding: false,
    title: 3,
    icon: false,
  },
  {
    id: 4,
    numeric: false,
    disablePadding: false,
    title: 'Name',
    icon: true,
    sortName: 'name',
  },
  {
    id: 5,
    numeric: false,
    disablePadding: false,
    title: 'Subsidiary',
  },
  {
    id: 6,
    numeric: false,
    disablePadding: false,
    title: 'PIC',
  },
  {
    id: 7,
    numeric: false,
    disablePadding: false,
    title: 'Phone Number',
  },
  {
    id: 8,
    numeric: false,
    disablePadding: false,
    title: 'SKU',
    icon: true,
    sortName: 'sku',
  },
  {
    id: 9,
    numeric: false,
    disablePadding: false,
    title: 'COGS(Rp)*',
    icon: true,
    sortName: 'cogs',
  },
  {
    id: 10,
    numeric: false,
    disablePadding: false,
    title: 'Address',
  },
];
const SubHubTable = ({
  subHubData,
  totalTableItems,
  setRowPerPage,
  rowPerPageValues,
  setCurrentPageTable,
  rowPerPage,
  currentPageTable,
  setSubHubDetailSelected,
  setSubHubSelected,
  setIsOpenNewSubHub,
  setIsOpenSubHub,
  setMiniSubSelected,
  subHubKeyword,
  setSubHubKeyword,
  isLoadingSubHub,
  sortTableSubHub,
  setSortTableSubHub,
  isSortAscSubHub,
  setIsSortAscSubHub,
  hubsId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const [openColumnFilter, setOpenColumnFilter] = useState(null);
  const isOpenColumnFilter = Boolean(openColumnFilter);
  const [columnArray, setColumnArray] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState([]);

  // useEffect(() => {
  //   const fetchSubHub = async () => {
  //     try {
  //       const {
  //         data: {
  //           data: { subHubs },
  //         },
  //       } = await getAllSubHub({ idToko: 99 });
  //       // console.log('cek', subHubs);
  //       return setColumnArray(subHubs);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchSubHub();
  // }, []);

  useEffect(() => {
    setColumnArray(headCells);
  }, []);

  useEffect(() => {
    setSelectedColumn(
      columnArray
        .slice(0, searchParams.get('s') || 8)
        .map((headCell) => headCell.id)
    );
  }, [columnArray, searchParams]);

  const handleSelectedColumn = (id) => {
    if (selectedColumn.includes(id)) {
      setSelectedColumn([...selectedColumn].filter((value) => value !== id));
    } else {
      setSelectedColumn([...selectedColumn, id]);
    }
  };

  const isSelected = (name) => selectedColumn.indexOf(name) !== -1;

  const [openExport, setOpenExport] = useState(null);
  const isOpenExport = Boolean(openExport);

  const [isSubHubDataExist, setIsSubHubData] = useState(true);

  useEffect(() => {
    if (subHubData?.length === 0) {
      setIsSubHubData(false);
    } else {
      setIsSubHubData(true);
    }
  }, [subHubData?.length]);

  const [hubsToExport, setHubsToExport] = useState([]);

  useEffect(() => {
    const fetchSubHubList = async () => {
      let params = {};

      params.page = 1;
      params.pageSize = totalTableItems;
      params.name = 'asc';
      params.idToko = hubsId;

      try {
        const {
          data: {
            data: { subHubs },
          },
        } = await getAllSubHub(params);
        const bodyTable = subHubs.map(
          (
            { name, subsidiary, picPhone, picName, SKU, COGS, address },
            index
          ) => ({
            number: index + 1,
            name: name || '-',
            subsidiary: subsidiary || '-',
            pic: picName || '-',
            phoneNumber: picPhone || '-',
            sku: SKU || '-',
            cogs: COGS || '-',
            address: address || '-',
          })
        );
        setHubsToExport(bodyTable);
      } catch (err) {
        console.error(err);
        setHubsToExport([]);
      }
    };

    if (subHubData?.length) {
      if (openExport) {
        if (hubsToExport?.length === 0) {
          fetchSubHubList();
        }
      }
    }
  }, [subHubData, hubsToExport, openExport, totalTableItems]);

  const tempColumns = [...headCells];

  const columnWithoutPhoto = tempColumns.splice(1, 1);

  const downloadPdf = () => {
    const doc = new jsPDF();
    doc.text('Table Master Hub', 20, 10);
    doc.autoTable({
      theme: 'grid',
      columns: tempColumns.map((col) => ({
        ...col,
        dataKey: col.id,
      })),
      body: hubsToExport,
    });
    doc.save('table.pdf');
    setOpenExport(null);
  };

  const theme = useTheme();
  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'row-reverse',
        alignItems: 'center',
      }}
    >
      <CustomButton
        sx={{
          bgcolor: '#51B15C',
          color: '#ffffff',
          borderRadius: '8px',
          '&:hover': {
            bgcolor: '#51B15C',
            color: '#ffffff',
          },
          fontSize: '0.875rem',
          px: 2,
        }}
        startIcon={<AddIcon sx={{ width: '14px' }} />}
        onClick={() => {
          setIsOpenNewSubHub(true);
          setSubHubSelected(null);
          // updatedSearchParams.delete('subhub-overview');
          // setSearchParams(updatedSearchParams);
        }}
      >
        Add
      </CustomButton>
      <IconButton
        sx={{
          border: '1.5px solid #51B15C',
          borderRadius: '8px',
          p: 1,
        }}
        onClick={(e) => setOpenColumnFilter(e.currentTarget)}
        aria-haspopup="true"
      >
        <ColumnFilterIcon fill="#51B15C" />
      </IconButton>
      <CustomMenu
        open={isOpenColumnFilter}
        openMenu={openColumnFilter}
        setOpenMenu={setOpenColumnFilter}
        sx={{
          '.MuiMenuItem-root': {
            paddingLeft: 0,
            paddingRight: '20px',
            paddingTop: 0,
            paddingBottom: 0,
            borderRadius: '8px',
          },
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', p: '12px' }}
        >
          <Typography sx={{ fontWeight: '400', fontSize: '12px' }}>
            Max 7
          </Typography>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '12px',
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedColumn(
                columnArray
                  .slice(0, searchParams.get('s') || 8)
                  .map((headCell) => headCell.id)
              );
            }}
          >
            Reset
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />

        {columnArray.slice(1).map((headCell, index) => {
          const isItemSelected = isSelected(headCell.id);
          return (
            <MenuItem key={index}>
              <Checkbox
                checked={isItemSelected}
                onChange={() => handleSelectedColumn(headCell.id)}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={
                  !isItemSelected && selectedColumn.slice(1).length >= 7
                }
              />
              {headCell.label}
            </MenuItem>
          );
        })}
      </CustomMenu>
      <CustomButton
        startIcon={<ExitToAppIcon />}
        endIcon={<ArrowDropDownIcon />}
        onClick={(e) => setOpenExport(e.currentTarget)}
        aria-haspopup="true"
        sx={{
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          fontSize: '0.875rem',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
          px: 2,
          py: 0.8,
        }}
      >
        Export
      </CustomButton>
      <CustomMenu
        open={isOpenExport}
        openMenu={openExport}
        setOpenMenu={setOpenExport}
        PaperProps={{ sx: { minWidth: '121px' } }}
      >
        <MenuItem
          onClick={() => {
            downloadPdf();
          }}
        >
          PDF
        </MenuItem>
        <CSVLink
          style={{ color: '#000000', textDecoration: 'none' }}
          data={hubsToExport}
        >
          <MenuItem onClick={() => setOpenExport(null)}>CSV</MenuItem>
        </CSVLink>
      </CustomMenu>
    </Box>
  );

  return (
    <CustomTable
      rowPerPage={rowPerPage}
      handleRowPerPage={(event) => {
        setRowPerPage(event.target.value);
        setCurrentPageTable(1);
      }}
      currentPage={currentPageTable}
      rowPerPageValues={rowPerPageValues}
      totalValueItems={totalTableItems}
      handleInputCurrentPage={(event) => {
        if (event.target.value >= Math.ceil(totalTableItems / rowPerPage)) {
          setCurrentPageTable(Math.ceil(totalTableItems / rowPerPage));
        } else if (event.target.value <= 1) {
          setCurrentPageTable(1);
        } else {
          setCurrentPageTable(event.target.value);
        }
      }}
      handleBackArrow={() =>
        setCurrentPageTable(
          currentPageTable <= 1 ? currentPageTable : currentPageTable - 1
        )
      }
      handleForwardArrow={() =>
        setCurrentPageTable(
          currentPageTable >= Math.ceil(totalTableItems / rowPerPage)
            ? currentPageTable
            : currentPageTable + 1
        )
      }
      name="Sub Hub List"
      toolbar={toolbar}
      isDataExist={isSubHubDataExist}
      isLoadingTable={isLoadingSubHub}
      searchValue={subHubKeyword}
      setSearchValue={setSubHubKeyword}
      isNoData={!isLoadingSubHub && subHubData.length === 0 ? true : false}
      noDataText="No Sub Hub Data"
    >
      <TableHead>
        <TableRow>
          {columnArray
            .filter((value) => selectedColumn.includes(value.id))
            .map((headCell) => (
              <CustomTableCells
                sx={{
                  ':last-child': {
                    pr: 0.5,
                  },
                  color: headCell.color ? headCell.color : '#000000',
                  fontWeight: 500,
                }}
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.icon === true ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSortTableSubHub(headCell.sortName);
                      setIsSortAscSubHub(!isSortAscSubHub);
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '0.75rem',
                      }}
                    >
                      {headCell.title}
                    </Typography>

                    {sortTableSubHub === headCell.sortName ? (
                      isSortAscSubHub ? (
                        <ArrowRightAltIcon
                          sx={{
                            fontSize: '12px',
                            transform: 'rotate(90deg)',
                            color: theme.palette.primary.main,
                          }}
                        />
                      ) : (
                        <ArrowRightAltIcon
                          sx={{
                            fontSize: '12px',
                            transform: 'rotate(270deg)',
                            color: theme.palette.primary.main,
                          }}
                        />
                      )
                    ) : (
                      <SyncAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: '#9E9D9D',
                        }}
                      />
                    )}
                  </Box>
                ) : (
                  headCell.title
                )}
              </CustomTableCells>
            ))}
        </TableRow>
      </TableHead>

      {!isLoadingSubHub ? (
        <TableBody>
          {subHubData?.length > 0 &&
            subHubData?.map((subHub, index) => {
              const {
                name,
                picName,
                address,
                images,
                id,
                subsidiary,
                picPhone,
                sku,
                COGS,
              } = subHub;
              return (
                <TableRow
                  key={index}
                  sx={{ cursor: 'pointer' }}
                  onClick={(event) => {
                    setSubHubSelected(null);
                    setMiniSubSelected(id);
                    updatedSearchParams.delete('subhub-overview');
                    updatedSearchParams.set('minihub-overview', id);
                    setSearchParams(updatedSearchParams);
                  }}
                >
                  <CustomTableCells>{index + 1}</CustomTableCells>
                  {selectedColumn.includes('photo') && (
                    <CustomTableCells>
                      <Box
                        component="img"
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                            '/' +
                            images.split(',')[0] || <NoImage />
                        }
                        sx={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '8px',
                        }}
                      />
                    </CustomTableCells>
                  )}

                  {selectedColumn.includes('name') && (
                    <CustomTableCells
                      onClick={(event) => {
                        setSubHubDetailSelected(id);
                        setSubHubSelected(null);
                        updatedSearchParams.set('subhub-detail', id);
                        updatedSearchParams.delete('subhub-overview');
                        setSearchParams(updatedSearchParams);
                        event.stopPropagation();
                      }}
                    >
                      {name || '-'}
                    </CustomTableCells>
                  )}

                  {selectedColumn.includes('subsidiary') && (
                    <CustomTableCells>{subsidiary || '-'}</CustomTableCells>
                  )}

                  {selectedColumn.includes('pic') && (
                    <CustomTableCells>
                      {picName ? (
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <PersonIcon sx={{ fontSize: '1rem' }} />
                          <Typography sx={{ fontSize: '0.75rem' }}>
                            {picName}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography sx={{ fontSize: '0.75rem' }}>
                          {picName}
                        </Typography>
                      )}
                    </CustomTableCells>
                  )}

                  {selectedColumn.includes('phoneNumber') && (
                    <CustomTableCells sx={{ color: 'primary.main' }}>
                      {picPhone || '-'}
                    </CustomTableCells>
                  )}

                  {selectedColumn.includes('sku') && (
                    <CustomTableCells>{sku || '-'}</CustomTableCells>
                  )}

                  {selectedColumn.includes('cogs') && (
                    <CustomTableCells>{COGS || '-'}</CustomTableCells>
                  )}

                  {selectedColumn.includes('address') && (
                    <CustomTableCells>{address || '-'}</CustomTableCells>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      ) : (
        <TableBody>
          {[...Array(rowPerPage)].map((_, x) => (
            <TableRow key={x}>
              {selectedColumn.map((_, i) => (
                <TableCell key={i}>-</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      )}
    </CustomTable>
  );
};

export default SubHubTable;
