import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../assets/deleteIcon.svg';
import CustomButton from './CustomButton';

const CustomDeleteConfirm = ({
  open,
  onClose,
  handleClickDelete,
  handleClickCancel,
  title,
  body,
  redText = 'Reject',
}) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{ borderRadius: '12px' }}>
      <DialogTitle>
        <Box sx={{ display: 'center', alignItems: 'center' }}>
          <Box
            sx={{
              bgcolor: '#F33A3A1A',
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mr: '20px',
            }}
          >
            <DeleteIcon />
          </Box>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {body}
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
        >
          <Typography
            sx={{
              color: '#9E9D9D',
              fontSize: '12px',
              fontWeight: '600',
              mr: '20px',
              cursor: 'pointer',
            }}
            onClick={handleClickCancel}
          >
            Cancel
          </Typography>
          <CustomButton
            variant="contained"
            color="warning"
            sx={{
              bgcolor: '#F33A3A',
              width: '100px',
              height: '40px',
              fontSize: '12px',
              fontWeight: '600',
            }}
            onClick={handleClickDelete}
          >
            {redText}
          </CustomButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default CustomDeleteConfirm;
