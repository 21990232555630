import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
  DialogTitle,
  DialogContent,
  MenuItem,
  Input,
  InputAdornment,
  OutlinedInput,
  Select,
  Autocomplete,
} from '@mui/material';
import CustomInput from '../../../../../../components/shared/CustomInput.jsx';
import BackButton from '../../../../../../components/shared/BackButton';
import dayjs from 'dayjs';

import {
  deleteProductProduct,
  updateProductProduct,
} from '../../../../../../api/products.js';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import useGetDifferent2Arrays from '../../../../../../hooks/useGetDifferent2Arrays';
import CustomButton from '../../../../../../components/shared/CustomButton';
import CustomTextFormatingInput from '../../../../../../components/shared/CustomTextFormatingInput/CustomTextFormatingInput';
import CustomSelect from '../../../../../../components/shared/CustomSelect';
import CustomBottomDrawer from '../../../../../../components/shared/CustomBottomDrawer.jsx';
import CustomEditConfirm from '../../../../../../components/shared/CustomEditConfirm.jsx';
import ClearIcon from '@mui/icons-material/Clear';
import { uploadImage } from '../../../../../../api/storage.js';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm.jsx';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const ProductProductEdit = (props) => {
  const {
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    isProductProductAdd,
    setIsProductProductAdd,
    isProductProductEdit,
    setIsProductProductEdit,
    isProductProductDetails,
    setIsProductProductDetails,
    // *---------
    productEditInputDatas,
    setProductEditInputDatas,
    dummyDataTable,
    //* Data Unit
    unitDatas,
    unitLoading,
    // *--------
    catSubMiniProduct,
    setCatSubMiniProduct,
    categoryOption,
    setCategoryOption,
    subCategoryOption,
    setSubCategoryOption,
    miniSubCategoryOption,
    setMiniSubCategoryOption,
    categoryOptionLoading,
    setCategoryOptionLoading,
    subCategoryOptionLoading,
    setSubCategoryOptionLoading,
    miniSubCategoryOptionLoading,
    setMiniSubCategoryOptionLoading,
    // *--------
    categoryDataAwaitEdit,
    setCategoryDataAwaitEdit,
    unitDataAwaitEdit,
    setUnitDataAwaitEdit,
    variatnDataAwaitEdit,
    setVariantDataAwaitEdit,
    stockLimitDataAwaitEdit,
    setStockLimitDataAwaitEdit,
    // *----------
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
  } = props;

  const today = dayjs(new Date());
  const theme = useTheme();

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  const [selectOpen, setSelectOpen] = useState('category');

  const [descriptionProduct, setDescriptionProduct] = useState(
    productEditInputDatas.description
  );
  const [isMinDescriptionProduct, setIsMinDescriptionProduct] = useState(true);

  // !-------------------- Confirm -----------------------

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isEditConfirm, setIsEditConfirm] = useState(false);

  // !-------------------- Validation ---------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
  // *---------- validasi Stock Limit ---------
  useEffect(() => {
    if (
      stockLimitDataAwaitEdit.minStock !== '' &&
      stockLimitDataAwaitEdit.minStockValue !== '' &&
      stockLimitDataAwaitEdit.maxStock !== '' &&
      stockLimitDataAwaitEdit.maxStockValue !== ''
    ) {
      return setStockLimitDataAwaitEdit({
        ...stockLimitDataAwaitEdit,
        isCheck: true,
      });
    } else {
      return setStockLimitDataAwaitEdit({
        ...stockLimitDataAwaitEdit,
        isCheck: false,
      });
    }
  }, [
    stockLimitDataAwaitEdit.minStock,
    stockLimitDataAwaitEdit.minStockValue,
    stockLimitDataAwaitEdit.maxStock,
    stockLimitDataAwaitEdit.maxStockValue,
  ]);

  // *---------- validasi Unit ---------
  useEffect(() => {
    if (
      unitDataAwaitEdit.unitValue1name !== '' &&
      unitDataAwaitEdit.unitValue1 !== ''
    ) {
      if (unitDataAwaitEdit.unitValue2name === '') {
        return setUnitDataAwaitEdit({
          ...unitDataAwaitEdit,
          unitValueSubmitedCheck: true,
        });
      } else {
        if (unitDataAwaitEdit.unitValue2 !== '') {
          if (unitDataAwaitEdit.unitValue3name === '') {
            return setUnitDataAwaitEdit({
              ...unitDataAwaitEdit,
              unitValueSubmitedCheck: true,
            });
          } else {
            if (unitDataAwaitEdit.unitValue3 !== '') {
              if (unitDataAwaitEdit.unitValue4name === '') {
                return setUnitDataAwaitEdit({
                  ...unitDataAwaitEdit,
                  unitValueSubmitedCheck: true,
                });
              } else {
                if (unitDataAwaitEdit.unitValue4 !== '') {
                  return setUnitDataAwaitEdit({
                    ...unitDataAwaitEdit,
                    unitValueSubmitedCheck: true,
                  });
                } else {
                  return setUnitDataAwaitEdit({
                    ...unitDataAwaitEdit,
                    unitValueSubmitedCheck: false,
                  });
                }
              }
            } else {
              return setUnitDataAwaitEdit({
                ...unitDataAwaitEdit,
                unitValueSubmitedCheck: false,
              });
            }
          }
        } else {
          return setUnitDataAwaitEdit({
            ...unitDataAwaitEdit,
            unitValueSubmitedCheck: false,
          });
        }
      }
    } else {
      return setUnitDataAwaitEdit({
        ...unitDataAwaitEdit,
        unitValueSubmitedCheck: false,
      });
    }
  }, [
    unitDataAwaitEdit.unitValue1name,
    unitDataAwaitEdit.unitValue1,
    unitDataAwaitEdit.unitValue2name,
    unitDataAwaitEdit.unitValue2,
    unitDataAwaitEdit.unitValue3name,
    unitDataAwaitEdit.unitValue3,
    unitDataAwaitEdit.unitValue4name,
    unitDataAwaitEdit.unitValue4,
  ]);

  // *--------------- validasi Submit ------------------
  useEffect(() => {
    if (productEditInputDatas.name.length >= 5) {
      if (isMinDescriptionProduct) {
        if (
          productEditInputDatas.categoryId === 0 ||
          productEditInputDatas.subCategoryId === 0
        ) {
          return setIsSubmitAvailable(false);
        } else {
          if (productEditInputDatas.unitValueSubmited.length === 0) {
            return setIsSubmitAvailable(false);
          } else {
            if (
              productEditInputDatas.minStock === '' ||
              productEditInputDatas.minStockValue === '' ||
              productEditInputDatas.maxStock === '' ||
              productEditInputDatas.maxStockValue === ''
            ) {
              return setIsSubmitAvailable(false);
            } else {
              if (
                productEditInputDatas.metaDescription.length > 0 &&
                productEditInputDatas.metaDescription.length < 20
              ) {
                return setIsSubmitAvailable(false);
              } else {
                return setIsSubmitAvailable(true);
              }
            }
          }
        }
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [
    productEditInputDatas.name,
    isMinDescriptionProduct,
    productEditInputDatas.categoryId,
    productEditInputDatas.subCategoryId,
    productEditInputDatas.unitValueSubmited,
    productEditInputDatas.minStock,
    productEditInputDatas.minStockValue,
    productEditInputDatas.maxStock,
    productEditInputDatas.maxStockValue,
    productEditInputDatas.metaDescription,
  ]);

  // !-------------------- Handler ---------------------
  const handleEdit = () => {
    setIsEditConfirm(true);
  };

  const handleEditFix = () => {
    let data = {
      idSubkategori: productEditInputDatas.subCategoryId,
      namaProduk: productEditInputDatas.name,
      deskripsi: descriptionProduct,
      satuan: productEditInputDatas.unitValueSubmited,
      status: productEditInputDatas.publish ? 'aktif' : 'pending',
      schedule: dayjs(productEditInputDatas.schedule.$d).format('YYYY-MM-DD'),
      stokMin: productEditInputDatas.minStockValue,
      stokMinUnit: productEditInputDatas.minStock,
      stokMax: productEditInputDatas.maxStockValue,
      stokMaxUnit: productEditInputDatas.maxStock,
    };
    if (productEditInputDatas.miniSubCategoryId !== 0) {
      data['miniCategoryId'] = productEditInputDatas.miniSubCategoryId;
    }
    if (productEditInputDatas.metaDescription !== '') {
      data['metaDesk'] = productEditInputDatas.metaDescription;
    }
    if (productEditInputDatas.size !== 0) {
      data['berat'] = productEditInputDatas.size;
    }
    if (productEditInputDatas.color !== '') {
      data['warna'] = productEditInputDatas.color;
    }
    if (productEditInputDatas.photoUrls.length !== 0) {
      data['gambar'] = productEditInputDatas.photoUrls.join(',');
    }
    updateProductProduct(roadHirarki.productId, data)
      .then((result) => {
        setRoadHirarki({
          ...roadHirarki,
          product: result.data.data.namaProduk,
          productId: result.data.data.idProduk,
        });
        setNotif('editproduct');
        setNotifTitle(
          `${result.data.data.namaProduk} has been successfully edited`
        );
        setIsProductProductEdit(false);
        setIsProductProductDetails(true);
        setIsEditConfirm(false);
      })
      .catch((err) => console.error());
  };

  const handleDelete = () => {
    setIsDeleteConfirm(!isDeleteConfirm);
  };

  const handleDeleteFix = () => {
    deleteProductProduct(roadHirarki.productId).then((res) => {
      setRoadHirarki({ ...roadHirarki, product: '', productId: 0 });
      setIsDeleteConfirm(false);
      setIsProductProductDetails(false);
      setIsProductProductEdit(false);
      setNotif('deleteproduct');
      setNotifTitle(
        `${res.data.data.namaProduk} has been successfully deleted`
      );
    });
  };

  const handleBackButton = () => {
    setPage('product');
    setIsProductProductAdd(false);
    setIsProductProductEdit(false);
    setIsProductProductDetails(true);
  };

  const handleAddUnit = () => {
    let units = [
      {
        unitId: parseInt(unitDataAwaitEdit.unitValue1Id),
        value: parseInt(unitDataAwaitEdit.unitValue1),
      },
      {
        unitId: parseInt(unitDataAwaitEdit.unitValue2Id),
        value: parseInt(unitDataAwaitEdit.unitValue2),
      },
      {
        unitId: parseInt(unitDataAwaitEdit.unitValue3Id),
        value: parseInt(unitDataAwaitEdit.unitValue3),
      },
      {
        unitId: parseInt(unitDataAwaitEdit.unitValue4Id),
        value: parseInt(unitDataAwaitEdit.unitValue4),
      },
    ];
    let unitFix = units.filter((item) => item.unitId !== 0);
    setProductEditInputDatas({
      ...productEditInputDatas,
      unitValueSubmited: unitFix,
      unitValue1: parseInt(unitDataAwaitEdit.unitValue1),
      unitValue1Id: parseInt(unitDataAwaitEdit.unitValue1Id),
      unitValue1name: unitDataAwaitEdit.unitValue1name,
      unitValue2: parseInt(unitDataAwaitEdit.unitValue2),
      unitValue2Id: parseInt(unitDataAwaitEdit.unitValue2Id),
      unitValue2name: unitDataAwaitEdit.unitValue2name,
      unitValue3: parseInt(unitDataAwaitEdit.unitValue3),
      unitValue3Id: parseInt(unitDataAwaitEdit.unitValue3Id),
      unitValue3name: unitDataAwaitEdit.unitValue3name,
      unitValue4: parseInt(unitDataAwaitEdit.unitValue4),
      unitValue4Id: parseInt(unitDataAwaitEdit.unitValue4Id),
      unitValue4name: unitDataAwaitEdit.unitValue4name,
      isUnitOpen: false,
    });
  };

  const handleClickStockLimit = () => {
    setProductEditInputDatas({
      ...productEditInputDatas,
      minStockValue: stockLimitDataAwaitEdit.minStockValue,
      minStock: stockLimitDataAwaitEdit.minStock,
      maxStockValue: stockLimitDataAwaitEdit.maxStockValue,
      maxStock: stockLimitDataAwaitEdit.maxStock,
      isLimitStockOpen: false,
    });
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setProductEditInputDatas({
        ...productEditInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'product',
      };
      uploadImage(datas).then((res) => {
        setProductEditInputDatas({
          ...productEditInputDatas,
          photoUrls: [...productEditInputDatas.photoUrls, res.data.data.path],
          isMaxSizePhoto: false,
        });
      });
    }
  };

  const handleDeleteImage = (link) => {
    setProductEditInputDatas({
      ...productEditInputDatas,
      photoUrls: productEditInputDatas.photoUrls.filter(
        (item) => item !== link
      ),
    });
  };

  const handleClickImage = (link) => {
    setProductEditInputDatas({
      ...productEditInputDatas,
      linkPreviewPhoto: link,
      isPreviewPhoto: true,
    });
  };

  return (
    <>
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleEditFix}
        title="Edit Product"
        body={
          <Box sx={{ textAlign: 'center', mb: '40px', mt: '8px' }}>
            <Typography>Are you sure you want to save the changes?</Typography>
          </Box>
        }
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Product"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {productEditInputDatas.name}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      {desktopMode ? (
        <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
          {/* BackButton */}
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Box>
              <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                Edit Product
              </Typography>
              {roadHirarki.category !== '' &&
                roadHirarki.subCategory !== '' && (
                  <Box
                    sx={{
                      display: 'flex',
                      color: '#9E9D9D',

                      alignItems: 'center',
                      mt: '4px',
                      gap: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      Subsidiary
                    </Typography>
                    <ChevronRightIcon sx={{ fontSize: '16px' }} />
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      {roadHirarki.category}
                    </Typography>
                    <ChevronRightIcon sx={{ fontSize: '16px' }} />
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      {roadHirarki.subCategory}
                    </Typography>
                  </Box>
                )}
            </Box>
          </Grid>
          {/* Name */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Product Name*
            </Typography>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter product name"
              value={productEditInputDatas.name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    name: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 5 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {productEditInputDatas.name.length}/30
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pb: `${
                  productEditInputDatas.photo.length === 0 ? '16px' : '9px'
                }`,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Photo
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#9E9D9D',
                }}
              >
                ( max size : 2MB )
              </Typography>
              {productEditInputDatas.isMaxSizePhoto && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                      mr: '4px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                    }}
                  >
                    The photo can't be more than 2MB
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {productEditInputDatas.photoUrls.length === 0 && (
                  <Box
                    sx={{
                      height: '54px',
                      width: '54px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      bgcolor: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderStyle: 'dashed',
                      borderColor: '#9E9D9D',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputProduct_img').click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '28px' }}
                    />
                  </Box>
                )}
                {productEditInputDatas.photoUrls.map((input, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      padding: '7px',
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: 'absolute',
                        fontSize: '20px',
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeleteImage(input)}
                    />
                    <Box onClick={() => handleClickImage(input)}>
                      <img
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + input
                        }
                        style={{
                          height: '54px',
                          width: '54px',
                          aspectRatio: '1/1',
                          borderRadius: '8px',
                          cursor: 'pointer',
                        }}
                        alt="pic"
                      />
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  mb: '7px',
                  ml: '20px',
                  alignItems: 'center',
                }}
              >
                {productEditInputDatas.photoUrls.length < 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputProduct_img').click();
                    }}
                  >
                    <input
                      accept="image/*"
                      id="inputProduct_img"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleAddImage}
                    />
                    <AddIcon />
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                      Add More
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/* Photo Dialoge */}
          {productEditInputDatas.linkPreviewPhoto !== '' && (
            <Dialog
              open={productEditInputDatas.isPreviewPhoto}
              onClose={() => {
                setProductEditInputDatas({
                  ...productEditInputDatas,
                  isPreviewPhoto: !productEditInputDatas.isPreviewPhoto,
                  linkPreviewPhoto: '',
                });
              }}
            >
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  '/' +
                  productEditInputDatas.linkPreviewPhoto
                }
                sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
              />
            </Dialog>
          )}
          {/* Category , UNIT, Variant */}
          <Grid container item xs={12} columns={12} spacing={4}>
            {/* Category */}
            <Grid item xs={6}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isCategoryOpen: !productEditInputDatas.isCategoryOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography>Category*</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productEditInputDatas.category === '' &&
                      productEditInputDatas.subCategory === '' ? (
                        <Typography
                          sx={{ fontSize: '12px', fontWeight: '500' }}
                        >
                          Click to Select category
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: '12px' }}>
                            {productEditInputDatas.category}
                          </Typography>
                          <ChevronRightIcon sx={{ fontSize: '12px' }} />
                          <Typography sx={{ fontSize: '12px' }}>
                            {productEditInputDatas.subCategory}
                          </Typography>
                          {productEditInputDatas.miniSubCategory !== '' && (
                            <>
                              <ChevronRightIcon sx={{ fontSize: '12px' }} />
                              <Typography sx={{ fontSize: '12px' }}>
                                {productEditInputDatas.miniSubCategory}
                              </Typography>
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
            {/* VAriant */}
            <Grid item xs={6}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isVariantOpen: !productEditInputDatas.isVariantOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography>Variant</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productEditInputDatas.color === '' &&
                      productEditInputDatas.size === 0 ? (
                        <Typography
                          sx={{ fontSize: '12px', fontWeight: '500' }}
                        >
                          Click to select variant (color,size)
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          {productEditInputDatas.color !== '' &&
                          productEditInputDatas.size !== '' ? (
                            <Typography sx={{ fontSize: '12px' }}>
                              {productEditInputDatas.color},{' '}
                              {productEditInputDatas.size}
                            </Typography>
                          ) : productEditInputDatas.color !== '' &&
                            productEditInputDatas.size === '' ? (
                            <Typography sx={{ fontSize: '12px' }}>
                              {productEditInputDatas.color}
                            </Typography>
                          ) : (
                            <Typography sx={{ fontSize: '12px' }}>
                              {productEditInputDatas.size}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
            {/* UNit */}
            <Grid item xs={6}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isUnitOpen: !productEditInputDatas.isUnitOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography>Unit*</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productEditInputDatas.unitValue1 === '' &&
                      productEditInputDatas.unitValue1name === '' ? (
                        <>
                          {productEditInputDatas.unitValueSubmited.length !==
                          0 ? (
                            <Box sx={{ display: 'flex', gap: 2 }}>
                              {productEditInputDatas.unitValueSubmited.map(
                                (value, index) => (
                                  <Typography
                                    sx={{ fontSize: '12px', fontWeight: '500' }}
                                    key={index}
                                  >
                                    {value}
                                  </Typography>
                                )
                              )}
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex' }}>
                              <Typography
                                sx={{ fontSize: '12px', fontWeight: '500' }}
                              >
                                Click to Select Unit
                              </Typography>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: '12px' }}>
                            {productEditInputDatas.unitValue1}{' '}
                            {productEditInputDatas.unitValue1name}
                          </Typography>
                          {productEditInputDatas.unitValue2name !== '' && (
                            <Typography sx={{ fontSize: '12px' }}>
                              - {productEditInputDatas.unitValue2}{' '}
                              {productEditInputDatas.unitValue2name}
                            </Typography>
                          )}
                          {productEditInputDatas.unitValue3name !== '' && (
                            <Typography sx={{ fontSize: '12px' }}>
                              - {productEditInputDatas.unitValue3}{' '}
                              {productEditInputDatas.unitValue3name}
                            </Typography>
                          )}
                          {productEditInputDatas.unitValue4name !== '' && (
                            <Typography sx={{ fontSize: '12px' }}>
                              - {productEditInputDatas.unitValue4}{' '}
                              {productEditInputDatas.unitValue4name}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
            {/* limit stock */}
            <Grid item xs={6}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isLimitStockOpen: !productEditInputDatas.isCategoryOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography>Stock Limit*</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productEditInputDatas.minStockValue === '' &&
                      productEditInputDatas.maxStockValue === '' ? (
                        <Typography
                          sx={{ fontSize: '12px', fontWeight: '500' }}
                        >
                          Click to determine min and max stock
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: '12px' }}>
                            {productEditInputDatas.minStockValue}{' '}
                            {productEditInputDatas.minStock}
                          </Typography>
                          <Typography sx={{ fontSize: '12px' }}>
                            - {productEditInputDatas.maxStockValue}{' '}
                            {productEditInputDatas.maxStock}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
          </Grid>
          {/* Dialog choosen */}
          {/*------------------- Dialog Category------------------- */}
          <Dialog
            open={productEditInputDatas.isCategoryOpen}
            onClose={() =>
              setProductEditInputDatas({
                ...productEditInputDatas,
                isCategoryOpen: !productEditInputDatas.isCategoryOpen,
              })
            }
            maxWidth="md"
          >
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{productEditInputDatas.name} Category</Typography>
              <CloseIcon
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isCategoryOpen: !productEditInputDatas.isCategoryOpen,
                  })
                }
                sx={{ cursor: 'pointer' }}
              />
            </DialogTitle>
            <DialogContent dividers sx={{ minWidth: '100%' }}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItem: 'center',
                    gap: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  {/* Category Choose */}
                  <Box>
                    <Typography
                      sx={{ mb: '12px', fontSize: '16px', fontWeight: '500' }}
                    >
                      Category*
                    </Typography>
                    <Autocomplete
                      sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                      open={selectOpen === 'category' && true}
                      onFocus={() => setSelectOpen('category')}
                      onBlur={() => setSelectOpen('')}
                      options={categoryOption}
                      inputValue={productEditInputDatas.categoryInput}
                      onInputChange={(event, newInputValue) => {
                        setProductEditInputDatas({
                          ...productEditInputDatas,
                          categoryInput: newInputValue,
                        });
                      }}
                      value={categoryDataAwaitEdit.categoryChoose}
                      onChange={(event, newValue) => {
                        setCategoryDataAwaitEdit({
                          ...categoryDataAwaitEdit,
                          categoryChoose: newValue,
                          categoryId: newValue.idKategori,
                          category: newValue.namaKategori,
                          subCategoryChoose: null,
                          subCategoryId: 0,
                          subCategory: '',
                          miniSubCategoryChoose: null,
                          miniSubCategoryId: 0,
                          miniSubCategory: '',
                        });
                        setSelectOpen('subCategory');
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.idKategori}
                        >
                          {option.namaKategori}
                        </Box>
                      )}
                      clearIcon={
                        <ClearIcon
                          fontSize="small"
                          onClick={() => {
                            setProductEditInputDatas({
                              ...productEditInputDatas,
                              categoryInput: '',
                            });
                            setCategoryDataAwaitEdit({
                              ...categoryDataAwaitEdit,
                              categoryChoose: null,
                              categoryId: 0,
                              category: '',
                              subCategoryChoose: null,
                              subCategoryId: 0,
                              subCategory: '',
                              miniSubCategoryChoose: null,
                              miniSubCategoryId: 0,
                              miniSubCategory: '',
                            });
                          }}
                        />
                      }
                      getOptionLabel={(option) => option.namaKategori}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Categories"
                          onFocus={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              categoryOpen: true,
                            });
                          }}
                          onBlur={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              categoryOpen: false,
                            });
                          }}
                        />
                      )}
                      loading={categoryOptionLoading}
                    />
                  </Box>
                  {/* Sub Category Choose */}
                  <Box>
                    <Typography
                      sx={{ mb: '12px', fontSize: '16px', fontWeight: '500' }}
                    >
                      Sub Category*
                    </Typography>
                    <Autocomplete
                      sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                      open={selectOpen === 'subCategory' && true}
                      onFocus={() => setSelectOpen('subCategory')}
                      onBlur={() => setSelectOpen('')}
                      options={subCategoryOption}
                      inputValue={productEditInputDatas.subCategoryInput}
                      onInputChange={(event, newInputValue) => {
                        setProductEditInputDatas({
                          ...productEditInputDatas,
                          subCategoryInput: newInputValue,
                        });
                      }}
                      value={categoryDataAwaitEdit.subCategoryChoose}
                      onChange={(event, newValue) => {
                        setCategoryDataAwaitEdit({
                          ...categoryDataAwaitEdit,
                          subCategoryChoose: newValue,
                          subCategoryId: newValue.idSubkategori,
                          subCategory: newValue.subkategori,
                          miniSubCategoryChoose: null,
                          miniSubCategoryId: 0,
                          miniSubCategory: '',
                        });
                        setSelectOpen('mini');
                      }}
                      clearIcon={
                        <ClearIcon
                          fontSize="small"
                          onClick={() => {
                            setProductEditInputDatas({
                              ...productEditInputDatas,
                              subCategoryInput: '',
                            });
                            setCategoryDataAwaitEdit({
                              ...categoryDataAwaitEdit,
                              subCategoryChoose: null,
                              subCategoryId: 0,
                              subCategory: '',
                              miniSubCategoryChoose: null,
                              miniSubCategoryId: 0,
                              miniSubCategory: '',
                            });
                          }}
                        />
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.isSubkategori}
                        >
                          {option.subkategori}
                        </Box>
                      )}
                      getOptionLabel={(option) => option.subkategori}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Subcategory"
                          onFocus={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              subCategoryOpen: true,
                            });
                          }}
                          onBlur={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              subCategoryOpen: false,
                            });
                          }}
                        />
                      )}
                      loading={subCategoryOptionLoading}
                    />
                  </Box>
                  {/* Mini Sub Category Choose */}
                  <Box>
                    <Typography
                      sx={{ mb: '12px', fontSize: '16px', fontWeight: '500' }}
                    >
                      Mini
                    </Typography>
                    <Autocomplete
                      sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                      open={selectOpen === 'mini' && true}
                      onFocus={() => setSelectOpen('mini')}
                      onBlur={() => setSelectOpen('')}
                      options={miniSubCategoryOption}
                      inputValue={productEditInputDatas.miniSubCategoryInput}
                      onInputChange={(event, newInputValue) => {
                        setProductEditInputDatas({
                          ...productEditInputDatas,
                          miniSubCategoryInput: newInputValue,
                        });
                      }}
                      value={categoryDataAwaitEdit.miniSubCategoryChoose}
                      onChange={(event, newValue) => {
                        setCategoryDataAwaitEdit({
                          ...categoryDataAwaitEdit,
                          miniSubCategoryChoose: newValue,
                          miniSubCategoryId: newValue.id,
                          miniSubCategory: newValue.name,
                        });
                        setSelectOpen('');
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.id}
                        >
                          {option.name}
                        </Box>
                      )}
                      clearIcon={
                        <ClearIcon
                          fontSize="small"
                          onClick={() => {
                            setProductEditInputDatas({
                              ...productEditInputDatas,
                              miniSubCategoryInput: '',
                            });
                            setCategoryDataAwaitEdit({
                              ...categoryDataAwaitEdit,
                              miniSubCategoryChoose: null,
                              miniSubCategoryId: 0,
                              miniSubCategory: '',
                            });
                          }}
                        />
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Subcategory"
                          onFocus={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              miniSubCategoryOpen: true,
                            });
                          }}
                          onBlur={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              miniSubCategoryOpen: false,
                            });
                          }}
                        />
                      )}
                      loading={miniSubCategoryOptionLoading}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}
                >
                  <CustomButton
                    variant="contained"
                    disabled={
                      categoryDataAwaitEdit.categoryChoose === null ||
                      categoryDataAwaitEdit.subCategoryChoose == null
                        ? true
                        : false
                    }
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      height: '48px',
                      width: '190px',
                    }}
                    onClick={() => {
                      setProductEditInputDatas({
                        ...productEditInputDatas,
                        category: categoryDataAwaitEdit.category,
                        subCategory: categoryDataAwaitEdit.subCategory,
                        miniSubCategory: categoryDataAwaitEdit.miniSubCategory,
                        categoryId: categoryDataAwaitEdit.categoryId,
                        subCategoryId: categoryDataAwaitEdit.subCategoryId,
                        miniSubCategoryId:
                          categoryDataAwaitEdit.miniSubCategoryId,
                        isCategoryOpen: false,
                      });
                    }}
                  >
                    <Typography sx={{ color: 'white' }}>Enter</Typography>
                  </CustomButton>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
          {/*------------------- Dialog unit------------------- */}
          <Dialog
            open={productEditInputDatas.isUnitOpen}
            maxWidth="md"
            onClose={() => {
              setProductEditInputDatas({
                ...productEditInputDatas,
                isUnitOpen: !productEditInputDatas.isUnitOpen,
              });
            }}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{productEditInputDatas.name} Unit</Typography>
              <CloseIcon
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isUnitOpen: !productEditInputDatas.isUnitOpen,
                  })
                }
                sx={{ cursor: 'pointer' }}
              />
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  Unit*
                </Typography>
                {/* unit select 1 */}
                <FormControl variant="outlined" sx={{ mt: '12px' }}>
                  <OutlinedInput
                    sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                    placeholder="Enter Qty"
                    type="number"
                    value={unitDataAwaitEdit.unitValue1}
                    onChange={(e) =>
                      setUnitDataAwaitEdit({
                        ...unitDataAwaitEdit,
                        unitValue1: e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Select
                          value={unitDataAwaitEdit.unitValue1name}
                          onChange={(e) =>
                            setUnitDataAwaitEdit({
                              ...unitDataAwaitEdit,
                              unitValue1name: e.target.value,
                              unitValue1level: unitDatas.find(
                                (item) => item.name === e.target.value
                              ).level,
                              unitValue1Id: unitDatas.find(
                                (item) => item.name === e.target.value
                              ).level,
                              unitValue2: '',
                              unitValue2name: '',
                              unitValue2level: 0,
                              unitValue2Id: 0,
                              unitValue3: '',
                              unitValue3name: '',
                              unitValue3level: 0,
                              unitValue3Id: 0,
                              unitValue4: '',
                              unitValue4name: '',
                              unitValue4level: 0,
                              unitValue4Id: 0,
                              unitValue1: '',
                              unitValue2: '',
                              unitValue3: '',
                              unitValue4: '',
                            })
                          }
                          label="Age"
                          sx={{
                            minWidth: '130px',
                            bgcolor: 'white',
                            '.MuiOutlinedInput-input': {
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              paddingLeft: '12px',
                              paddingRight: '0',
                              borderRadius: '5px',
                            },
                          }}
                        >
                          {/* {unitDataAwaitEdit.unit1Open ? (
                          <MenuItem value="">none</MenuItem>
                        ) : (
                          <MenuItem value="">unit</MenuItem>
                        )} */}
                          {unitDatas.map((unitData, index) => (
                            <MenuItem value={unitData.name} key={index}>
                              {unitData.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
                {/* unit select 2 */}
                {unitDataAwaitEdit.unitValue1name !== '' && (
                  <FormControl variant="outlined" sx={{ mt: '12px' }}>
                    <OutlinedInput
                      sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                      placeholder="Enter Qty"
                      type="number"
                      value={unitDataAwaitEdit.unitValue2}
                      onChange={(e) =>
                        setUnitDataAwaitEdit({
                          ...unitDataAwaitEdit,
                          unitValue2: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Select
                            value={unitDataAwaitEdit.unitValue2name}
                            onChange={(e) =>
                              setUnitDataAwaitEdit({
                                ...unitDataAwaitEdit,
                                unitValue2name: e.target.value,
                                unitValue2level: unitDatas.find(
                                  (item) => item.name === e.target.value
                                ).level,
                                unitValue2Id: unitDatas.find(
                                  (item) => item.name === e.target.value
                                ).id,
                                unitValue3: '',
                                unitValue3name: '',
                                unitValue3level: 0,
                                unitValue3Id: 0,
                                unitValue4: '',
                                unitValue4name: '',
                                unitValue4level: 0,
                                unitValue4Id: 0,
                                unitValue2: '',
                                unitValue3: '',
                                unitValue4: '',
                              })
                            }
                            label="Age"
                            sx={{
                              minWidth: '130px',
                              bgcolor: 'white',
                              '.MuiOutlinedInput-input': {
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingLeft: '12px',
                                paddingRight: '0',
                                borderRadius: '5px',
                              },
                            }}
                          >
                            {unitDatas.map((unitData, index) => (
                              <MenuItem value={unitData.name} key={index}>
                                {unitData.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                )}
                {/* unit select 3 */}
                {unitDataAwaitEdit.unitValue2name !== '' && (
                  <FormControl variant="outlined" sx={{ mt: '12px' }}>
                    <OutlinedInput
                      sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                      placeholder="Enter Qty"
                      type="number"
                      value={unitDataAwaitEdit.unitValue3}
                      onChange={(e) =>
                        setUnitDataAwaitEdit({
                          ...unitDataAwaitEdit,
                          unitValue3: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Select
                            value={unitDataAwaitEdit.unitValue3name}
                            onChange={(e) =>
                              setUnitDataAwaitEdit({
                                ...unitDataAwaitEdit,
                                unitValue3name: e.target.value,
                                unitValue3level: unitDatas.find(
                                  (item) => item.name === e.target.value
                                ).level,
                                unitValue3Id: unitDatas.find(
                                  (item) => item.name === e.target.value
                                ).id,
                                unitValue4: '',
                                unitValue4name: '',
                                unitValue4level: 0,
                                unitValue4Id: 0,
                                unitValue3: '',
                                unitValue4: '',
                              })
                            }
                            label="Age"
                            sx={{
                              minWidth: '130px',
                              bgcolor: 'white',
                              '.MuiOutlinedInput-input': {
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingLeft: '12px',
                                paddingRight: '0',
                                borderRadius: '5px',
                              },
                            }}
                          >
                            {unitDatas.map((unitData, index) => (
                              <MenuItem value={unitData.name} key={index}>
                                {unitData.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                )}
                {/* Unit select 4 */}
                {unitDataAwaitEdit.unitValue3name !== '' && (
                  <FormControl variant="outlined" sx={{ mt: '12px' }}>
                    <OutlinedInput
                      sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                      placeholder="Enter Qty"
                      type="number"
                      value={unitDataAwaitEdit.unitValue4}
                      onChange={(e) =>
                        setUnitDataAwaitEdit({
                          ...unitDataAwaitEdit,
                          unitValue4: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Select
                            value={unitDataAwaitEdit.unitValue4name}
                            onChange={(e) =>
                              setUnitDataAwaitEdit({
                                ...unitDataAwaitEdit,
                                unitValue4name: e.target.value,
                                unitValue4level: unitDatas.find(
                                  (item) => item.name === e.target.value
                                ).level,
                                unitValue4Id: unitDatas.find(
                                  (item) => item.name === e.target.value
                                ).id,
                                unitValue4: '',
                              })
                            }
                            label="Age"
                            sx={{
                              minWidth: '130px',
                              bgcolor: 'white',
                              '.MuiOutlinedInput-input': {
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingLeft: '12px',
                                paddingRight: '0',
                                borderRadius: '5px',
                              },
                            }}
                          >
                            {unitDatas.map((unitData, index) => (
                              <MenuItem value={unitData.name} key={index}>
                                {unitData.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}>
                <CustomButton
                  variant="contained"
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    height: '48px',
                    width: '190px',
                  }}
                  disabled={!unitDataAwaitEdit.unitValueSubmitedCheck}
                  onClick={handleAddUnit}
                >
                  <Typography sx={{ color: 'white' }}>Enter</Typography>
                </CustomButton>
              </Box>
            </DialogContent>
          </Dialog>
          {/*------------------- Dialog variant -------------------*/}
          <Dialog
            open={productEditInputDatas.isVariantOpen}
            maxWidth="md"
            onClose={() => {
              setProductEditInputDatas({
                ...productEditInputDatas,
                isVariantOpen: !productEditInputDatas.isVariantOpen,
              });
            }}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{productEditInputDatas.name} Variant</Typography>
              <CloseIcon
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isVariantOpen: !productEditInputDatas.isVariantOpen,
                  })
                }
                sx={{ cursor: 'pointer' }}
              />
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box>
                  <Typography sx={{ mb: '12px' }}>Color</Typography>
                  <CustomInput
                    placeholder="Enter Color"
                    sx={{ bgcolor: '#FAFAFA', minWidth: '340px' }}
                    value={variatnDataAwaitEdit.color}
                    onChange={(e) =>
                      setVariantDataAwaitEdit({
                        ...variatnDataAwaitEdit,
                        color: e.target.value,
                      })
                    }
                  />
                </Box>
                <Box>
                  <Typography sx={{ mb: '12px' }}>Size</Typography>
                  <CustomInput
                    placeholder="Enter Size"
                    sx={{ bgcolor: '#FAFAFA', minWidth: '340px' }}
                    value={variatnDataAwaitEdit.size}
                    type="number"
                    onChange={(e) =>
                      setVariantDataAwaitEdit({
                        ...variatnDataAwaitEdit,
                        size: e.target.value,
                      })
                    }
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}>
                <CustomButton
                  variant="contained"
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    height: '48px',
                    width: '190px',
                  }}
                  onClick={() => {
                    setProductEditInputDatas({
                      ...productEditInputDatas,
                      color: variatnDataAwaitEdit.color,
                      size: variatnDataAwaitEdit.size,
                      isVariantOpen: false,
                    });
                  }}
                >
                  <Typography sx={{ color: 'white' }}>Enter</Typography>
                </CustomButton>
              </Box>
            </DialogContent>
          </Dialog>
          {/*------------------- Dialog limit stock -------------------*/}
          <Dialog
            open={productEditInputDatas.isLimitStockOpen}
            maxWidth="md"
            onClose={() => {
              setProductEditInputDatas({
                ...productEditInputDatas,
                isLimitStockOpen: !productEditInputDatas.isLimitStockOpen,
              });
            }}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{productEditInputDatas.name} Stock Limit</Typography>
              <CloseIcon
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isLimitStockOpen: !productEditInputDatas.isLimitStockOpen,
                  })
                }
                sx={{ cursor: 'pointer' }}
              />
            </DialogTitle>
            <DialogContent dividers>
              <Grid container columns={12} spacing={4}>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: '500' }}>
                    Min. Stock*
                  </Typography>
                  <FormControl variant="outlined" sx={{ mt: '12px' }}>
                    <OutlinedInput
                      sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                      placeholder="Enter Qty"
                      type="number"
                      value={stockLimitDataAwaitEdit.minStockValue}
                      onChange={(e) =>
                        setStockLimitDataAwaitEdit({
                          ...stockLimitDataAwaitEdit,
                          minStockValue: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Select
                            value={stockLimitDataAwaitEdit.minStock}
                            onChange={(e) =>
                              setStockLimitDataAwaitEdit({
                                ...stockLimitDataAwaitEdit,
                                minStock: e.target.value,
                              })
                            }
                            label="Age"
                            sx={{
                              minWidth: '130px',
                              bgcolor: 'white',
                              '.MuiOutlinedInput-input': {
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingLeft: '12px',
                                paddingRight: '0',
                                borderRadius: '5px',
                              },
                            }}
                          >
                            {/* {unitDataAwaitEdit.unitValue1name === '' && (
                        <MenuItem value="unit">Unit</MenuItem>
                      )} */}
                            {unitDatas.map((unitData, index) => (
                              <MenuItem value={unitData.name} key={index}>
                                {unitData.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: '500' }}>
                    Max. Stock*
                  </Typography>
                  <FormControl variant="outlined" sx={{ mt: '12px' }}>
                    <OutlinedInput
                      sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                      placeholder="Enter Qty"
                      type="number"
                      value={stockLimitDataAwaitEdit.maxStockValue}
                      onChange={(e) =>
                        setStockLimitDataAwaitEdit({
                          ...stockLimitDataAwaitEdit,
                          maxStockValue: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Select
                            value={stockLimitDataAwaitEdit.maxStock}
                            onChange={(e) =>
                              setStockLimitDataAwaitEdit({
                                ...stockLimitDataAwaitEdit,
                                maxStock: e.target.value,
                              })
                            }
                            label="Age"
                            sx={{
                              minWidth: '130px',
                              bgcolor: 'white',
                              '.MuiOutlinedInput-input': {
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingLeft: '12px',
                                paddingRight: '0',
                                borderRadius: '5px',
                              },
                            }}
                          >
                            {/* {unitDataAwaitEdit.unitValue1name === '' && (
                        <MenuItem value="unit">Unit</MenuItem>
                      )} */}
                            {unitDatas.map((unitData, index) => (
                              <MenuItem value={unitData.name} key={index}>
                                {unitData.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  mt: '20px',
                }}
              >
                <CustomButton
                  disabled={!stockLimitDataAwaitEdit.isCheck}
                  variant="contained"
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    height: '48px',
                    width: '190px',
                  }}
                  onClick={handleClickStockLimit}
                >
                  <Typography sx={{ color: 'white' }}>Enter</Typography>
                </CustomButton>
              </Box>
            </DialogContent>
          </Dialog>
          {/* Meta Description */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Meta Description
            </Typography>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter Meta Description"
              value={productEditInputDatas.metaDescription}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    metaDescription: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {productEditInputDatas.metaDescription.length}/250
              </Typography>
            </Box>
          </Grid>
          {/* Schedule */}
          <Grid item xs={6}>
            <Grid container>
              <Grid
                item
                alignItems="center"
                sx={{ height: '100%', mr: '20px' }}
              >
                <Typography
                  sx={{
                    mb: '16px',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  Schedule
                </Typography>
              </Grid>
              <Grid item alignItems="center" sx={{ height: '100%' }}>
                <RadioGroup
                  row
                  value={productEditInputDatas.scheduleType}
                  onChange={(e) => {
                    setProductEditInputDatas({
                      ...productEditInputDatas,
                      schedule: today,
                      scheduleType: e.target.value,
                    });
                  }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '16px',
                    },
                    '.MuiRadio-root': {
                      padding: '0 8px',
                    },
                    '.MuiTypography-root': {
                      fontSize: '12px',
                    },
                  }}
                >
                  <FormControlLabel
                    value="now"
                    control={<Radio />}
                    label="Now"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', minWidth: '100%' }}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={productEditInputDatas.schedule}
                onChange={(newValue) => {
                  if (productEditInputDatas.scheduleType !== 'now') {
                    setProductEditInputDatas({
                      ...productEditInputDatas,
                      schedule: newValue,
                    });
                  } else {
                    setProductEditInputDatas({
                      ...productEditInputDatas,
                      schedule: today,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                  />
                )}
                disabled={
                  productEditInputDatas.scheduleType === 'now' ? true : false
                }
              />
            </Box>
          </Grid>
          {/* Descripiton */}
          <Grid item xs={12}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Description*
            </Typography>
            <CustomTextFormatingInput
              setEditorContent={setDescriptionProduct}
              isError={false}
              helperText=""
              firstValue={productEditInputDatas.description}
              setIsMinInputText={setIsMinDescriptionProduct}
              desktopMode={desktopMode}
            />
          </Grid>
          {/* Bot */}
          <Box
            sx={{
              width: '100%',
              bgcolor: 'white',
              position: 'fixed',
              bottom: '0px',
              right: '0px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '18px 32px 18px 240px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Publish
              </Typography>
              <AntSwitch
                checked={productEditInputDatas.publish}
                onChange={(e) => {
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    publish: !productEditInputDatas.publish,
                  });
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <CustomButton
                variant="contained"
                onClick={handleDelete}
                // disabled={!isSubmitAvailable}
                sx={{ width: '140px', height: '36px' }}
                color="error"
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Delete
                </Typography>
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={handleEdit}
                disabled={!isSubmitAvailable}
                sx={{ width: '140px', height: '36px' }}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Save
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid container columns={12} spacing={2}>
          {/* back button */}
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Box>
              <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
                Edit Product
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  color: '#9E9D9D',

                  alignItems: 'center',
                  mt: '4px',
                  gap: 0.25,
                }}
              >
                <Typography sx={{ fontSize: '8px', fontWeight: '100' }}>
                  Subsidiary
                </Typography>
                <ChevronRightIcon sx={{ fontSize: '4px' }} />
                <Typography
                  sx={{ fontSize: '8px', fontWeight: '100', color: '#9E9D9D' }}
                >
                  {roadHirarki.category}
                </Typography>
                <ChevronRightIcon sx={{ fontSize: '4px' }} />
                <Typography sx={{ fontSize: '8px', fontWeight: '100' }}>
                  {roadHirarki.subCategory}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* Name */}
          <Grid item xs={12}>
            <CustomInput
              sx={{
                width: '100%',
                bgcolor: '#FAFAFA',
                fontSize: '12px',
                '.MuiOutlinedInput-input': {
                  fontSize: '12px',
                  padding: '12px',
                },
              }}
              placeholder="Name*"
              value={productEditInputDatas.name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    name: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 5 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {productEditInputDatas.name.length}/30
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pb: `${
                  productEditInputDatas.photoUrls.length === 0 ? '16px' : '9px'
                }`,
              }}
            >
              <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                Photo
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  color: '#9E9D9D',
                }}
              >
                ( max size : 2MB )
              </Typography>
              {productEditInputDatas.isMaxSizePhoto && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: '8px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                      mr: '4px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '8px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                    }}
                  >
                    The photo can't be more than 2MB
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {productEditInputDatas.photoUrls.length === 0 && (
                  <Box
                    sx={{
                      height: '54px',
                      width: '54px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      bgcolor: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderStyle: 'dashed',
                      borderColor: '#9E9D9D',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputProduct_img').click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '28px' }}
                    />
                  </Box>
                )}
                {productEditInputDatas.photoUrls.map((input, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      padding: '7px',
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: 'absolute',
                        fontSize: '20px',
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeleteImage(input)}
                    />

                    <Box onClick={() => handleClickImage(input)}>
                      <img
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + input
                        }
                        style={{
                          height: '54px',
                          width: '54px',
                          aspectRatio: '1/1',
                          borderRadius: '8px',
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  mb: '7px',
                  ml: '20px',
                  alignItems: 'center',
                }}
              >
                {productEditInputDatas.photoUrls.length < 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputProduct_img').click();
                    }}
                  >
                    <input
                      accept="image/*"
                      id="inputProduct_img"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleAddImage}
                    />
                    <AddIcon sx={{ fontSize: '18px' }} />
                    <Typography sx={{ fontSize: '8px', fontWeight: '600' }}>
                      Add More
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/*  Dialoge Photo */}
          {productEditInputDatas.linkPreviewPhoto !== '' && (
            <Dialog
              open={productEditInputDatas.isPreviewPhoto}
              onClose={() => {
                setProductEditInputDatas({
                  ...productEditInputDatas,
                  isPreviewPhoto: !productEditInputDatas.isPreviewPhoto,
                  linkPreviewPhoto: '',
                });
              }}
            >
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  '/' +
                  productEditInputDatas.linkPreviewPhoto
                }
                sx={{ aspectRation: '1/1' }}
              />
            </Dialog>
          )}
          {/* Category , UNIT, Variant */}
          <Grid container item xs={12} columns={12} spacing={2}>
            {/* Category */}
            <Grid item xs={12}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isCategoryOpen: !productEditInputDatas.isCategoryOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography sx={{ fontSize: '12px' }}>Category</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productEditInputDatas.category === '' &&
                      productEditInputDatas.subCategory === '' ? (
                        <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                          Click to Select category
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: '8px' }}>
                            {productEditInputDatas.category}
                          </Typography>
                          <ChevronRightIcon sx={{ fontSize: '8px' }} />
                          <Typography sx={{ fontSize: '8px' }}>
                            {productEditInputDatas.subCategory}
                          </Typography>
                          {productEditInputDatas.miniSubCategory !== '' && (
                            <>
                              <ChevronRightIcon sx={{ fontSize: '8px' }} />
                              <Typography sx={{ fontSize: '8px' }}>
                                {productEditInputDatas.miniSubCategory}
                              </Typography>
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
            {/* UNit */}
            <Grid item xs={12}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isUnitOpen: !productEditInputDatas.isUnitOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography sx={{ fontSize: '12px' }}>Unit*</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productEditInputDatas.unitValue1 === '' &&
                      productEditInputDatas.unitValue1name === '' ? (
                        <>
                          {productEditInputDatas.unitValueSubmited.length !==
                          0 ? (
                            <Box sx={{ display: 'flex', gap: 2 }}>
                              {productEditInputDatas.unitValueSubmited.map(
                                (value, index) => (
                                  <Typography
                                    sx={{ fontSize: '8px', fontWeight: '500' }}
                                    key={index}
                                  >
                                    {value}
                                  </Typography>
                                )
                              )}
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex' }}>
                              <Typography
                                sx={{ fontSize: '8px', fontWeight: '500' }}
                              >
                                Click to Select Unit
                              </Typography>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: '12px' }}>
                            {productEditInputDatas.unitValue1}{' '}
                            {productEditInputDatas.unitValue1name}
                          </Typography>
                          {productEditInputDatas.unitValue2name !== '' && (
                            <Typography sx={{ fontSize: '12px' }}>
                              - {productEditInputDatas.unitValue2}{' '}
                              {productEditInputDatas.unitValue2name}
                            </Typography>
                          )}
                          {productEditInputDatas.unitValue3name !== '' && (
                            <Typography sx={{ fontSize: '12px' }}>
                              - {productEditInputDatas.unitValue3}{' '}
                              {productEditInputDatas.unitValue3name}
                            </Typography>
                          )}
                          {productEditInputDatas.unitValue4name !== '' && (
                            <Typography sx={{ fontSize: '12px' }}>
                              - {productEditInputDatas.unitValue4}{' '}
                              {productEditInputDatas.unitValue4name}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
            {/* VAriant */}
            <Grid item xs={12}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isVariantOpen: !productEditInputDatas.isVariantOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography sx={{ fontSize: '12px' }}>Variant</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productEditInputDatas.color === '' &&
                      productEditInputDatas.size === 0 ? (
                        <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                          Click to select variant (color,size)
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          {productEditInputDatas.color !== '' &&
                          productEditInputDatas.size !== '' ? (
                            <Typography sx={{ fontSize: '8px' }}>
                              {productEditInputDatas.color},{' '}
                              {productEditInputDatas.size}
                            </Typography>
                          ) : productEditInputDatas.color !== '' &&
                            productEditInputDatas.size === '' ? (
                            <Typography sx={{ fontSize: '8px' }}>
                              {productEditInputDatas.color}
                            </Typography>
                          ) : (
                            <Typography sx={{ fontSize: '8px' }}>
                              {productEditInputDatas.size}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
            {/* limit stock */}
            <Grid item xs={12}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    isLimitStockOpen: !productEditInputDatas.isCategoryOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '12px', fontWeight: '500' }}>
                    <Typography sx={{ fontSize: '12px' }}>
                      Stock Limit*
                    </Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productEditInputDatas.minStockValue === '' &&
                      productEditInputDatas.maxStockValue === '' ? (
                        <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                          Click to determine min and max stock
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: '8px' }}>
                            {productEditInputDatas.minStockValue}{' '}
                            {productEditInputDatas.minStock}
                          </Typography>
                          <Typography sx={{ fontSize: '8px' }}>
                            - {productEditInputDatas.maxStockValue}{' '}
                            {productEditInputDatas.maxStock}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
          </Grid>
          {/* Dialog choosen */}
          {/*------------------- Drawer Category------------------- */}
          <CustomBottomDrawer
            open={productEditInputDatas.isCategoryOpen}
            onClose={() =>
              setProductEditInputDatas({
                ...productEditInputDatas,
                isCategoryOpen: !productEditInputDatas.isCategoryOpen,
              })
            }
            handleClose={() =>
              setProductEditInputDatas({
                ...productEditInputDatas,
                isCategoryOpen: !productEditInputDatas.isCategoryOpen,
              })
            }
            title="Category"
          >
            <Box
              sx={{
                display: 'flex',
                alignItem: 'center',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'space-between',
              }}
            >
              {/* Category Choose */}
              <Box>
                <Autocomplete
                  sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                  open={selectOpen === 'category' && true}
                  onFocus={() => setSelectOpen('category')}
                  onBlur={() => setSelectOpen('')}
                  options={categoryOption}
                  inputValue={productEditInputDatas.categoryInput}
                  onInputChange={(event, newInputValue) => {
                    setProductEditInputDatas({
                      ...productEditInputDatas,
                      categoryInput: newInputValue,
                    });
                  }}
                  clearIcon={
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        setProductEditInputDatas({
                          ...productEditInputDatas,
                          categoryInput: '',
                        });
                        setCategoryDataAwaitEdit({
                          ...categoryDataAwaitEdit,
                          categoryChoose: null,
                          categoryId: 0,
                          category: '',
                          subCategoryChoose: null,
                          subCategoryId: 0,
                          subCategory: '',
                          miniSubCategoryChoose: null,
                          miniSubCategoryId: 0,
                          miniSubCategory: '',
                        });
                      }}
                    />
                  }
                  value={categoryDataAwaitEdit.categoryChoose}
                  onChange={(event, newValue) => {
                    setCategoryDataAwaitEdit({
                      ...categoryDataAwaitEdit,
                      categoryChoose: newValue,
                      categoryId: newValue.idKategori,
                      category: newValue.namaKategori,
                      subCategoryChoose: null,
                      subCategoryId: 0,
                      subCategory: '',
                      miniSubCategoryChoose: null,
                      miniSubCategoryId: 0,
                      miniSubCategory: '',
                    });
                    setSelectOpen('subCategory');
                    setSubCategoryOptionLoading(true);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                      key={option.idKategori}
                    >
                      {option.namaKategori}
                    </Box>
                  )}
                  getOptionLabel={(option) => option.namaKategori}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category*"
                      placeholder="Select Categories"
                      variant="filled"
                      sx={{
                        bgcolor: '#FAFAFA',
                        '.MuiFilledInput-root:before': {
                          borderBottom: 'none',
                        },
                        '.MuiFilledInput-root': {
                          bgcolor: '#FAFAFA',
                        },
                      }}
                      onFocus={() => {
                        setCatSubMiniProduct({
                          ...catSubMiniProduct,
                          categoryOpen: true,
                        });
                      }}
                      onBlur={() => {
                        setCatSubMiniProduct({
                          ...catSubMiniProduct,
                          categoryOpen: false,
                        });
                      }}
                    />
                  )}
                  loading={categoryOptionLoading}
                />
              </Box>
              {/* Sub Category Choose */}
              <Box>
                <Autocomplete
                  sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                  open={selectOpen === 'subCategory' && true}
                  onFocus={() => setSelectOpen('subCategory')}
                  onBlur={() => setSelectOpen('')}
                  options={subCategoryOption}
                  inputValue={productEditInputDatas.subCategoryInput}
                  onInputChange={(event, newInputValue) => {
                    setProductEditInputDatas({
                      ...productEditInputDatas,
                      subCategoryInput: newInputValue,
                    });
                  }}
                  clearIcon={
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        setProductEditInputDatas({
                          ...productEditInputDatas,
                          subCategoryInput: '',
                        });
                        setCategoryDataAwaitEdit({
                          ...categoryDataAwaitEdit,
                          subCategoryChoose: null,
                          subCategoryId: 0,
                          subCategory: '',
                          miniSubCategoryChoose: null,
                          miniSubCategoryId: 0,
                          miniSubCategory: '',
                        });
                      }}
                    />
                  }
                  value={categoryDataAwaitEdit.subCategoryChoose}
                  onChange={(event, newValue) => {
                    setCategoryDataAwaitEdit({
                      ...categoryDataAwaitEdit,
                      subCategoryChoose: newValue,
                      subCategoryId: newValue.idSubkategori,
                      subCategory: newValue.subkategori,
                      miniSubCategoryChoose: null,
                      miniSubCategoryId: 0,
                      miniSubCategory: '',
                    });
                    setSelectOpen('mini');
                    setMiniSubCategoryOptionLoading(true);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                      key={option.isSubkategori}
                    >
                      {option.subkategori}
                    </Box>
                  )}
                  getOptionLabel={(option) => option.subkategori}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sub Category*"
                      placeholder="Select Subcategory"
                      variant="filled"
                      sx={{
                        bgcolor: '#FAFAFA',
                        '.MuiFilledInput-root:before': {
                          borderBottom: 'none',
                        },
                        '.MuiFilledInput-root': {
                          bgcolor: '#FAFAFA',
                        },
                      }}
                      onFocus={() => {
                        setCatSubMiniProduct({
                          ...catSubMiniProduct,
                          subCategoryOpen: true,
                        });
                      }}
                      onBlur={() => {
                        setCatSubMiniProduct({
                          ...catSubMiniProduct,
                          subCategoryOpen: false,
                        });
                      }}
                    />
                  )}
                  loading={subCategoryOptionLoading}
                />
              </Box>
              {/* Mini Sub Category Choose */}
              <Box>
                <Autocomplete
                  sx={{ minWidth: '260px' }}
                  open={selectOpen === 'mini' && true}
                  onFocus={() => setSelectOpen('mini')}
                  onBlur={() => setSelectOpen('')}
                  options={miniSubCategoryOption}
                  inputValue={productEditInputDatas.miniSubCategoryInput}
                  onInputChange={(event, newInputValue) => {
                    setProductEditInputDatas({
                      ...productEditInputDatas,
                      miniSubCategoryInput: newInputValue,
                    });
                  }}
                  clearIcon={
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        setProductEditInputDatas({
                          ...productEditInputDatas,
                          miniSubCategoryInput: '',
                        });
                        setCategoryDataAwaitEdit({
                          ...categoryDataAwaitEdit,
                          miniSubCategoryChoose: null,
                          miniSubCategoryId: 0,
                          miniSubCategory: '',
                        });
                      }}
                    />
                  }
                  value={categoryDataAwaitEdit.miniSubCategoryChoose}
                  onChange={(event, newValue) => {
                    setCategoryDataAwaitEdit({
                      ...categoryDataAwaitEdit,
                      miniSubCategoryChoose: newValue,
                      miniSubCategoryId: newValue.id,
                      miniSubCategory: newValue.name,
                    });
                    setSelectOpen('');
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                      key={option.id}
                    >
                      {option.name}
                    </Box>
                  )}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Mini"
                      variant="filled"
                      sx={{
                        bgcolor: '#FAFAFA',
                        '.MuiFilledInput-root:before': {
                          borderBottom: 'none',
                        },
                        '.MuiFilledInput-root': {
                          bgcolor: '#FAFAFA',
                        },
                      }}
                      onFocus={() => {
                        setCatSubMiniProduct({
                          ...catSubMiniProduct,
                          miniSubCategoryOpen: true,
                        });
                      }}
                      onBlur={() => {
                        setCatSubMiniProduct({
                          ...catSubMiniProduct,
                          miniSubCategoryOpen: false,
                        });
                      }}
                    />
                  )}
                  loading={miniSubCategoryOptionLoading}
                />
              </Box>
            </Box>
            <Box sx={{ mt: '28px' }}>
              <CustomButton
                variant="contained"
                disabled={
                  categoryDataAwaitEdit.categoryChoose === null ||
                  categoryDataAwaitEdit.subCategoryChoose == null
                    ? true
                    : false
                }
                sx={{
                  bgcolor: theme.palette.primary.main,
                  height: '48px',
                  width: '100%',
                }}
                onClick={() => {
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    category: categoryDataAwaitEdit.category,
                    subCategory: categoryDataAwaitEdit.subCategory,
                    miniSubCategory: categoryDataAwaitEdit.miniSUbCategory,
                    categoryId: categoryDataAwaitEdit.categoryId,
                    subCategoryId: categoryDataAwaitEdit.subCategoryId,
                    miniSubCategoryId: categoryDataAwaitEdit.miniSUbCategoryId,
                    isCategoryOpen: false,
                  });
                }}
              >
                <Typography sx={{ color: 'white' }}>Enter</Typography>
              </CustomButton>
            </Box>
          </CustomBottomDrawer>
          {/*------------------- Drawer  unit------------------- */}
          <CustomBottomDrawer
            open={productEditInputDatas.isUnitOpen}
            onClose={() => {
              setProductEditInputDatas({
                ...productEditInputDatas,
                isUnitOpen: !productEditInputDatas.isUnitOpen,
              });
            }}
            handleClose={() => {
              setProductEditInputDatas({
                ...productEditInputDatas,
                isUnitOpen: !productEditInputDatas.isUnitOpen,
              });
            }}
            title="Unit"
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {/* unit select 1 */}
              <FormControl variant="outlined" sx={{ mt: '12px' }}>
                <OutlinedInput
                  sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                  placeholder="Enter Qty"
                  type="number"
                  value={unitDataAwaitEdit.unitValue1}
                  onChange={(e) =>
                    setUnitDataAwaitEdit({
                      ...unitDataAwaitEdit,
                      unitValue1: e.target.value,
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <Select
                        value={
                          unitDataAwaitEdit.unitValue1name === ''
                            ? 'unit'
                            : unitDataAwaitEdit.unitValue1name
                        }
                        onChange={(e) =>
                          setUnitDataAwaitEdit({
                            ...unitDataAwaitEdit,
                            unitValue1name: e.target.value,
                            unitValue1level: unitDatas.find(
                              (item) => item.name === e.target.value
                            ).level,
                            unitValue1Id: unitDatas.find(
                              (item) => item.name === e.target.value
                            ).level,
                            unitValue2: '',
                            unitValue2name: '',
                            unitValue2level: 0,
                            unitValue2Id: 0,
                            unitValue3: '',
                            unitValue3name: '',
                            unitValue3level: 0,
                            unitValue3Id: 0,
                            unitValue4: '',
                            unitValue4name: '',
                            unitValue4level: 0,
                            unitValue4Id: 0,
                            unitValue1: '',
                            unitValue2: '',
                            unitValue3: '',
                            unitValue4: '',
                          })
                        }
                        label="Age"
                        sx={{
                          minWidth: '130px',
                          bgcolor: 'white',
                          '.MuiOutlinedInput-input': {
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            paddingLeft: '12px',
                            paddingRight: '0',
                            borderRadius: '5px',
                          },
                        }}
                      >
                        {unitDataAwaitEdit.unitValue1name === '' && (
                          <MenuItem value="unit">Unit</MenuItem>
                        )}
                        {unitDatas.map((unitData, index) => (
                          <MenuItem value={unitData.name} key={index}>
                            {unitData.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
              </FormControl>
              {/* unit select 2 */}
              {unitDataAwaitEdit.unitValue1name !== '' && (
                <FormControl variant="outlined" sx={{ mt: '12px' }}>
                  <OutlinedInput
                    sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                    placeholder="Enter Qty"
                    type="number"
                    value={unitDataAwaitEdit.unitValue2}
                    onChange={(e) =>
                      setUnitDataAwaitEdit({
                        ...unitDataAwaitEdit,
                        unitValue2: e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Select
                          value={unitDataAwaitEdit.unitValue2name}
                          onChange={(e) =>
                            setUnitDataAwaitEdit({
                              ...unitDataAwaitEdit,
                              unitValue2name: e.target.value,
                              unitValue2level: unitDatas.find(
                                (item) => item.name === e.target.value
                              ).level,
                              unitValue2Id: unitDatas.find(
                                (item) => item.name === e.target.value
                              ).id,
                              unitValue3: '',
                              unitValue3name: '',
                              unitValue3level: 0,
                              unitValue3Id: 0,
                              unitValue4: '',
                              unitValue4name: '',
                              unitValue4level: 0,
                              unitValue4Id: 0,
                              unitValue2: '',
                              unitValue3: '',
                              unitValue4: '',
                            })
                          }
                          label="Age"
                          sx={{
                            minWidth: '130px',
                            bgcolor: 'white',
                            '.MuiOutlinedInput-input': {
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              paddingLeft: '12px',
                              paddingRight: '0',
                              borderRadius: '5px',
                            },
                          }}
                        >
                          {unitDatas.map((unitData, index) => (
                            <MenuItem value={unitData.name} key={index}>
                              {unitData.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
              )}
              {/* unit select 3 */}
              {unitDataAwaitEdit.unitValue2name !== '' && (
                <FormControl variant="outlined" sx={{ mt: '12px' }}>
                  <OutlinedInput
                    sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                    placeholder="Enter Qty"
                    type="number"
                    value={unitDataAwaitEdit.unitValue3}
                    onChange={(e) =>
                      setUnitDataAwaitEdit({
                        ...unitDataAwaitEdit,
                        unitValue3: e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Select
                          value={unitDataAwaitEdit.unitValue3name}
                          onChange={(e) =>
                            setUnitDataAwaitEdit({
                              ...unitDataAwaitEdit,
                              unitValue3name: e.target.value,
                              unitValue3level: unitDatas.find(
                                (item) => item.name === e.target.value
                              ).level,
                              unitValue3Id: unitDatas.find(
                                (item) => item.name === e.target.value
                              ).id,
                              unitValue4: '',
                              unitValue4name: '',
                              unitValue4level: 0,
                              unitValue4Id: 0,
                              unitValue3: '',
                              unitValue4: '',
                            })
                          }
                          label="Age"
                          sx={{
                            minWidth: '130px',
                            bgcolor: 'white',
                            '.MuiOutlinedInput-input': {
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              paddingLeft: '12px',
                              paddingRight: '0',
                              borderRadius: '5px',
                            },
                          }}
                        >
                          {unitDatas.map((unitData, index) => (
                            <MenuItem value={unitData.name} key={index}>
                              {unitData.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
              )}
              {/* Unit select 4 */}
              {unitDataAwaitEdit.unitValue3name !== '' && (
                <FormControl variant="outlined" sx={{ mt: '12px' }}>
                  <OutlinedInput
                    sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                    placeholder="Enter Qty"
                    type="number"
                    value={unitDataAwaitEdit.unitValue4}
                    onChange={(e) =>
                      setUnitDataAwaitEdit({
                        ...unitDataAwaitEdit,
                        unitValue4: e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Select
                          value={unitDataAwaitEdit.unitValue4name}
                          onChange={(e) =>
                            setUnitDataAwaitEdit({
                              ...unitDataAwaitEdit,
                              unitValue4name: e.target.value,
                              unitValue4level: unitDatas.find(
                                (item) => item.name === e.target.value
                              ).level,
                              unitValue4Id: unitDatas.find(
                                (item) => item.name === e.target.value
                              ).id,
                              unitValue4: '',
                            })
                          }
                          label="Age"
                          sx={{
                            minWidth: '130px',
                            bgcolor: 'white',
                            '.MuiOutlinedInput-input': {
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              paddingLeft: '12px',
                              paddingRight: '0',
                              borderRadius: '5px',
                            },
                          }}
                        >
                          {unitDatas.map((unitData, index) => (
                            <MenuItem value={unitData.name} key={index}>
                              {unitData.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
              )}
            </Box>
            <Box sx={{ mt: '28px' }}>
              <CustomButton
                variant="contained"
                sx={{
                  bgcolor: theme.palette.primary.main,
                  height: '48px',
                  width: '100%',
                }}
                disabled={!unitDataAwaitEdit.unitValueSubmitedCheck}
                onClick={() => {
                  let units = [
                    {
                      unitId: parseInt(unitDataAwaitEdit.unitValue1Id),
                      value: parseInt(unitDataAwaitEdit.unitValue1),
                    },
                    {
                      unitId: parseInt(unitDataAwaitEdit.unitValue2Id),
                      value: parseInt(unitDataAwaitEdit.unitValue2),
                    },
                    {
                      unitId: parseInt(unitDataAwaitEdit.unitValue3Id),
                      value: parseInt(unitDataAwaitEdit.unitValue3),
                    },
                    {
                      unitId: parseInt(unitDataAwaitEdit.unitValue4Id),
                      value: parseInt(unitDataAwaitEdit.unitValue4),
                    },
                  ];
                  let unitFix = units.filter((item) => item.unitId !== 0);
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    unitValueSubmited: unitFix,
                    unitValue1: parseInt(unitDataAwaitEdit.unitValue1),
                    unitValue1Id: parseInt(unitDataAwaitEdit.unitValue1Id),
                    unitValue1name: unitDataAwaitEdit.unitValue1name,
                    unitValue2: parseInt(unitDataAwaitEdit.unitValue2),
                    unitValue2Id: parseInt(unitDataAwaitEdit.unitValue2Id),
                    unitValue2name: unitDataAwaitEdit.unitValue2name,
                    unitValue3: parseInt(unitDataAwaitEdit.unitValue3),
                    unitValue3Id: parseInt(unitDataAwaitEdit.unitValue3Id),
                    unitValue3name: unitDataAwaitEdit.unitValue3name,
                    unitValue4: parseInt(unitDataAwaitEdit.unitValue4),
                    unitValue4Id: parseInt(unitDataAwaitEdit.unitValue4Id),
                    unitValue4name: unitDataAwaitEdit.unitValue4name,
                    isUnitOpen: false,
                  });
                }}
              >
                <Typography sx={{ color: 'white' }}>Enter</Typography>
              </CustomButton>
            </Box>
          </CustomBottomDrawer>
          {/*------------------- Drawer variant -------------------*/}
          <CustomBottomDrawer
            open={productEditInputDatas.isVariantOpen}
            onClose={() => {
              setProductEditInputDatas({
                ...productEditInputDatas,
                isVariantOpen: !productEditInputDatas.isVariantOpen,
              });
            }}
            handleClose={() => {
              setProductEditInputDatas({
                ...productEditInputDatas,
                isVariantOpen: !productEditInputDatas.isVariantOpen,
              });
            }}
            title={`${productEditInputDatas.name} Variant`}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                flexDirection: 'column',
              }}
            >
              <TextField
                label="Color"
                variant="filled"
                fullWidth
                sx={{
                  width: '100%',
                  bgcolor: '#FAFAFA',
                  '.MuiFilledInput-root:before': {
                    borderBottom: 'none',
                  },
                  '.MuiFilledInput-root': {
                    bgcolor: '#FAFAFA',
                    width: '100%',
                  },
                }}
                value={variatnDataAwaitEdit.color}
                onChange={(e) =>
                  setVariantDataAwaitEdit({
                    ...variatnDataAwaitEdit,
                    color: e.target.value,
                  })
                }
              />

              <TextField
                label="Size"
                variant="filled"
                fullWidth
                sx={{
                  width: '100%',
                  bgcolor: '#FAFAFA',
                  '.MuiFilledInput-root:before': {
                    borderBottom: 'none',
                  },
                  '.MuiFilledInput-root': {
                    bgcolor: '#FAFAFA',
                    width: '100%',
                  },
                }}
                value={variatnDataAwaitEdit.size}
                type="number"
                onChange={(e) =>
                  setVariantDataAwaitEdit({
                    ...variatnDataAwaitEdit,
                    size: e.target.value,
                  })
                }
              />
            </Box>
            <Box sx={{ mt: '28px' }}>
              <CustomButton
                variant="contained"
                sx={{
                  bgcolor: theme.palette.primary.main,
                  height: '48px',
                  width: '100%',
                }}
                onClick={() => {
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    color: variatnDataAwaitEdit.color,
                    size: variatnDataAwaitEdit.size,
                    isVariantOpen: false,
                  });
                }}
              >
                <Typography sx={{ color: 'white' }}>Enter</Typography>
              </CustomButton>
            </Box>
          </CustomBottomDrawer>
          {/*------------------- Drawer Limit Stock -------------------*/}
          <CustomBottomDrawer
            open={productEditInputDatas.isLimitStockOpen}
            onClose={() => {
              setProductEditInputDatas({
                ...productEditInputDatas,
                isLimitStockOpen: !productEditInputDatas.isLimitStockOpen,
              });
            }}
            handleClose={() => {
              setProductEditInputDatas({
                ...productEditInputDatas,
                isLimitStockOpen: !productEditInputDatas.isLimitStockOpen,
              });
            }}
            title={`${productEditInputDatas.name} Stock Limit`}
          >
            <Grid container columns={12} spacing={4}>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: '500' }}>Min. Stock*</Typography>
                <FormControl variant="outlined" sx={{ mt: '12px' }}>
                  <OutlinedInput
                    sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                    placeholder="Enter Qty"
                    type="number"
                    value={stockLimitDataAwaitEdit.minStockValue}
                    onChange={(e) =>
                      setStockLimitDataAwaitEdit({
                        ...stockLimitDataAwaitEdit,
                        minStockValue: e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Select
                          value={stockLimitDataAwaitEdit.minStock}
                          onChange={(e) =>
                            setStockLimitDataAwaitEdit({
                              ...stockLimitDataAwaitEdit,
                              minStock: e.target.value,
                            })
                          }
                          label="Age"
                          sx={{
                            minWidth: '130px',
                            bgcolor: 'white',
                            '.MuiOutlinedInput-input': {
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              paddingLeft: '12px',
                              paddingRight: '0',
                              borderRadius: '5px',
                            },
                          }}
                        >
                          {/* {unitDataAwaitEdit.unitValue1name === '' && (
                            <MenuItem value="unit">Unit</MenuItem>
                          )} */}
                          {unitDatas.map((unitData, index) => (
                            <MenuItem value={unitData.name} key={index}>
                              {unitData.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: '500' }}>Max. Stock*</Typography>
                <FormControl variant="outlined" sx={{ mt: '12px' }}>
                  <OutlinedInput
                    sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                    placeholder="Enter Qty"
                    type="number"
                    value={stockLimitDataAwaitEdit.maxStockValue}
                    onChange={(e) =>
                      setStockLimitDataAwaitEdit({
                        ...stockLimitDataAwaitEdit,
                        maxStockValue: e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Select
                          value={stockLimitDataAwaitEdit.maxStock}
                          onChange={(e) =>
                            setStockLimitDataAwaitEdit({
                              ...stockLimitDataAwaitEdit,
                              maxStock: e.target.value,
                            })
                          }
                          label="Age"
                          sx={{
                            minWidth: '130px',
                            bgcolor: 'white',
                            '.MuiOutlinedInput-input': {
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              paddingLeft: '12px',
                              paddingRight: '0',
                              borderRadius: '5px',
                            },
                          }}
                        >
                          {/* {unitDataAwaitEdit.unitValue1name === '' && (
                            <MenuItem value="unit">Unit</MenuItem>
                          )} */}
                          {unitDatas.map((unitData, index) => (
                            <MenuItem value={unitData.name} key={index}>
                              {unitData.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box sx={{ mt: '28px' }}>
              <CustomButton
                variant="contained"
                sx={{
                  bgcolor: theme.palette.primary.main,
                  height: '48px',
                  width: '100%',
                }}
                disabled={!stockLimitDataAwaitEdit.isCheck}
                onClick={handleClickStockLimit}
              >
                <Typography sx={{ color: 'white' }}>Enter</Typography>
              </CustomButton>
            </Box>
          </CustomBottomDrawer>
          {/* Meta Description */}
          <Grid item xs={12}>
            <CustomInput
              sx={{
                width: '100%',
                bgcolor: '#FAFAFA',
                fontSize: '12px',
                '.MuiOutlinedInput-input': {
                  fontSize: '12px',
                  padding: '12px',
                },
              }}
              placeholder="Meta Description"
              value={productEditInputDatas.metaDescription}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  setProductEditInputDatas({
                    ...productEditInputDatas,
                    metaDescription: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {productEditInputDatas.metaDescription.length}/250
              </Typography>
            </Box>
          </Grid>
          {/* Descripiton Mobile */}
          <Grid item xs={12}>
            <CustomTextFormatingInput
              setEditorContent={setDescriptionProduct}
              isError={false}
              helperText=""
              firstValue={productEditInputDatas.description}
              setIsMinInputText={setIsMinDescriptionProduct}
            />
          </Grid>
          {/* STATUS */}
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Publish
            </Typography>
            <AntSwitch
              checked={productEditInputDatas.publish}
              onChange={(e) => {
                setProductEditInputDatas({
                  ...productEditInputDatas,
                  publish: !productEditInputDatas.publish,
                });
              }}
            />
          </Grid>
          {/* SUBMIT */}
          <Grid item xs={12} sx={{ mt: '28px', display: 'flex', gap: 0.5 }}>
            <CustomButton
              // disabled={!isSubmitAvailable}
              onClick={handleDelete}
              variant="contained"
              color="error"
              sx={{
                width: '100%',
              }}
            >
              Delete
            </CustomButton>
            <CustomButton
              disabled={!isSubmitAvailable}
              onClick={handleEdit}
              variant="contained"
              sx={{
                width: '100%',
              }}
            >
              Save
            </CustomButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default ProductProductEdit;
