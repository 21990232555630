import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
  DialogTitle,
  DialogContent,
  MenuItem,
  Input,
  InputAdornment,
  OutlinedInput,
  Select,
  Autocomplete,
} from '@mui/material';
import CustomInput from '../../../../../../components/shared/CustomInput';
import BackButton from '../../../../../../components/shared/BackButton';
import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import useGetDifferent2Arrays from '../../../../../../hooks/useGetDifferent2Arrays';
import CustomButton from '../../../../../../components/shared/CustomButton';
import CustomTextFormatingInput from '../../../../../../components/shared/CustomTextFormatingInput/CustomTextFormatingInput';
import CustomSelect from '../../../../../../components/shared/CustomSelect';
import CustomAutocompleteDebounce from '../../../../../../components/shared/CustomAutocompleteDebounce';
import { addNewProductProduct } from '../../../../../../api/products';
import CustomBottomDrawer from '../../../../../../components/shared/CustomBottomDrawer';
import ClearIcon from '@mui/icons-material/Clear';
import ProductProductAddMobile from './ProductProductAddMobile';
import { uploadImage } from '../../../../../../api/storage';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const ProductProductAdd = (props) => {
  const {
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    isProductProductAdd,
    setIsProductProductAdd,
    isProductProductEdit,
    setIsProductProductEdit,
    productAddInputDatas,
    setProductAddInputDatas,
    // dummy Data
    dummyDataTable,
    // Data Unit
    unitDatas,
    unitLoading,
    // *- Cat Mini Sub
    catSubMiniProduct,
    setCatSubMiniProduct,
    categoryOption,
    setCategoryOption,
    subCategoryOption,
    setSubCategoryOption,
    miniSubCategoryOption,
    setMiniSubCategoryOption,
    categoryOptionLoading,
    setCategoryOptionLoading,
    subCategoryOptionLoading,
    setSubCategoryOptionLoading,
    miniSubCategoryOptionLoading,
    setMiniSubCategoryOptionLoading,
    categoryDataAwait,
    setCategoryDataAwait,
    unitDataAwait,
    setUnitDataAwait,
    variatnDataAwait,
    setVariantDataAwait,
    stockLimitDataAwait,
    setStockLimitDataAwait,
    // *---------
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
  } = props;

  const today = dayjs(new Date());
  const theme = useTheme();

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  const [descriptionProduct, setDescriptionProduct] = useState('');
  const [isMinDescriptionProduct, setIsMinDescriptionProduct] = useState(false);

  const [selectOpen, setSelectOpen] = useState('category');
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  // !----------  CATEGORY CHOOSE WITH SUB FIRST  ---------
  useEffect(() => {
    if (roadHirarki.subCategoryId !== 0) {
      setCategoryDataAwait({
        category: roadHirarki.category,
        subCategory: roadHirarki.subCategory,
        miniSubCategory: '',
        categoryId: roadHirarki.categoryId,
        subCategoryId: roadHirarki.subCategoryId,
        miniSubCategoryId: '',
        categoryChoose: {
          idKategori: roadHirarki.categoryId,
          namaKategori: roadHirarki.category,
        },
        subCategoryChoose: {
          idSubkategori: roadHirarki.idSubkategori,
          subkategori: roadHirarki.subCategory,
        },
        miniSubCategoryChoose: null,
      });
      setProductAddInputDatas({
        ...productAddInputDatas,
        category: roadHirarki.category,
        subCategory: roadHirarki.subCategory,
        miniSubCategory: '',
        categoryId: roadHirarki.categoryId,
        subCategoryId: roadHirarki.subCategoryId,
        miniSubCategoryId: '',
        categoryChoose: {
          idKategori: roadHirarki.categoryId,
          namaKategori: roadHirarki.category,
        },
        subCategoryChoose: {
          idSubkategori: roadHirarki.idSubkategori,
          subkategori: roadHirarki.subCategory,
        },
        miniSubCategoryChoose: null,
      });
    }
  }, [roadHirarki.subCategoryId]);

  // *---------- validasi limit stock ---------
  useEffect(() => {
    if (
      stockLimitDataAwait.minStock !== '' &&
      stockLimitDataAwait.minStockValue !== '' &&
      stockLimitDataAwait.maxStock !== '' &&
      stockLimitDataAwait.maxStockValue !== ''
    ) {
      return setStockLimitDataAwait({ ...stockLimitDataAwait, isCheck: true });
    } else {
      return setStockLimitDataAwait({ ...stockLimitDataAwait, isCheck: false });
    }
  }, [
    stockLimitDataAwait.minStock,
    stockLimitDataAwait.minStockValue,
    stockLimitDataAwait.maxStock,
    stockLimitDataAwait.maxStockValue,
  ]);

  // *---------- validasi Unit ---------

  useEffect(() => {
    if (
      unitDataAwait.unitValue1name !== '' &&
      unitDataAwait.unitValue1 !== ''
    ) {
      if (unitDataAwait.unitValue2name === '') {
        return setUnitDataAwait({
          ...unitDataAwait,
          unitValueSubmitedCheck: true,
        });
      } else {
        if (unitDataAwait.unitValue2 !== '') {
          if (unitDataAwait.unitValue3name === '') {
            return setUnitDataAwait({
              ...unitDataAwait,
              unitValueSubmitedCheck: true,
            });
          } else {
            if (unitDataAwait.unitValue3 !== '') {
              if (unitDataAwait.unitValue4name === '') {
                return setUnitDataAwait({
                  ...unitDataAwait,
                  unitValueSubmitedCheck: true,
                });
              } else {
                if (unitDataAwait.unitValue4 !== '') {
                  return setUnitDataAwait({
                    ...unitDataAwait,
                    unitValueSubmitedCheck: true,
                  });
                } else {
                  return setUnitDataAwait({
                    ...unitDataAwait,
                    unitValueSubmitedCheck: false,
                  });
                }
              }
            } else {
              return setUnitDataAwait({
                ...unitDataAwait,
                unitValueSubmitedCheck: false,
              });
            }
          }
        } else {
          return setUnitDataAwait({
            ...unitDataAwait,
            unitValueSubmitedCheck: false,
          });
        }
      }
    } else {
      return setUnitDataAwait({
        ...unitDataAwait,
        unitValueSubmitedCheck: false,
      });
    }
  }, [
    unitDataAwait.unitValue1name,
    unitDataAwait.unitValue1,
    unitDataAwait.unitValue2name,
    unitDataAwait.unitValue2,
    unitDataAwait.unitValue3name,
    unitDataAwait.unitValue3,
    unitDataAwait.unitValue4name,
    unitDataAwait.unitValue4,
  ]);

  // *--------------- validasi Submit ------------------
  useEffect(() => {
    if (productAddInputDatas.name.length >= 5) {
      if (isMinDescriptionProduct) {
        if (
          productAddInputDatas.categoryId === 0 ||
          productAddInputDatas.subCategoryId === 0
        ) {
          return setIsSubmitAvailable(false);
        } else {
          if (productAddInputDatas.unitValueSubmited.length === 0) {
            return setIsSubmitAvailable(false);
          } else {
            if (
              productAddInputDatas.minStock === '' ||
              productAddInputDatas.minStockValue === '' ||
              productAddInputDatas.maxStock === '' ||
              productAddInputDatas.maxStockValue === ''
            ) {
              return setIsSubmitAvailable(false);
            } else {
              if (
                productAddInputDatas.metaDescription.length > 0 &&
                productAddInputDatas.metaDescription.length < 20
              ) {
                return setIsSubmitAvailable(false);
              } else {
                return setIsSubmitAvailable(true);
              }
            }
          }
        }
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [
    productAddInputDatas.name,
    isMinDescriptionProduct,
    productAddInputDatas.categoryId,
    productAddInputDatas.subCategoryId,
    productAddInputDatas.unitValueSubmited,
    productAddInputDatas.minStock,
    productAddInputDatas.minStockValue,
    productAddInputDatas.maxStock,
    productAddInputDatas.maxStockValue,
    productAddInputDatas.metaDescription,
  ]);

  // !-------------- Handler ------------------

  const handleAddNewProduct = () => {
    let data = {
      idSubkategori: productAddInputDatas.subCategoryId,
      namaProduk: productAddInputDatas.name,
      deskripsi: descriptionProduct,
      satuan: productAddInputDatas.unitValueSubmited,
      status: productAddInputDatas.publish ? 'aktif' : 'pending',
      schedule: dayjs(productAddInputDatas.schedule.$d).format('YYYY-MM-DD'),
      stokMin: productAddInputDatas.minStockValue,
      stokMinUnit: productAddInputDatas.minStock,
      stokMax: productAddInputDatas.maxStockValue,
      stokMaxUnit: productAddInputDatas.maxStock,
    };
    if (productAddInputDatas.miniSubCategoryId !== 0) {
      data['miniCategoryId'] = productAddInputDatas.miniSubCategoryId;
    }
    if (productAddInputDatas.metaDescription !== '') {
      data['metaDesk'] = productAddInputDatas.metaDescription;
    }
    if (
      productAddInputDatas.size !== 0 &&
      productAddInputDatas.size !== null &&
      productAddInputDatas.size !== ''
    ) {
      data['berat'] = productAddInputDatas.size;
    }
    if (productAddInputDatas.color !== '') {
      data['warna'] = productAddInputDatas.color;
    }
    if (productAddInputDatas.photoUrls.length !== 0) {
      data['gambar'] = productAddInputDatas.photoUrls.join(',');
    }

    addNewProductProduct(data)
      .then((res) => {
        setIsProductProductAdd(false);
        setPage('product');
        setRoadHirarki({
          ...roadHirarki,
          product: '',
          productId: 0,
        });
        setNotifTitle(
          `${res.data.data.namaProduk} has been successfully added`
        );
        setNotif('addproduct');
      })
      .catch((err) => {
        console.error();
      });
  };

  const handleBackButton = () => {
    setPage('product');
    setIsProductProductAdd(false);
    setIsProductProductEdit(false);
  };

  const handleAddUnit = () => {
    let units = [
      {
        unitId: parseInt(unitDataAwait.unitValue1Id),
        value: parseInt(unitDataAwait.unitValue1),
      },
      {
        unitId: parseInt(unitDataAwait.unitValue2Id),
        value: parseInt(unitDataAwait.unitValue2),
      },
      {
        unitId: parseInt(unitDataAwait.unitValue3Id),
        value: parseInt(unitDataAwait.unitValue3),
      },
      {
        unitId: parseInt(unitDataAwait.unitValue4Id),
        value: parseInt(unitDataAwait.unitValue4),
      },
    ];
    let unitFix = units.filter((item) => item.unitId !== 0);
    setProductAddInputDatas({
      ...productAddInputDatas,
      unitValueSubmited: unitFix,
      unitValue1: parseInt(unitDataAwait.unitValue1),
      unitValue1Id: parseInt(unitDataAwait.unitValue1Id),
      unitValue1name: unitDataAwait.unitValue1name,
      unitValue2: parseInt(unitDataAwait.unitValue2),
      unitValue2Id: parseInt(unitDataAwait.unitValue2Id),
      unitValue2name: unitDataAwait.unitValue2name,
      unitValue3: parseInt(unitDataAwait.unitValue3),
      unitValue3Id: parseInt(unitDataAwait.unitValue3Id),
      unitValue3name: unitDataAwait.unitValue3name,
      unitValue4: parseInt(unitDataAwait.unitValue4),
      unitValue4Id: parseInt(unitDataAwait.unitValue4Id),
      unitValue4name: unitDataAwait.unitValue4name,
      isUnitOpen: false,
    });
  };

  const handleClickStockLimit = () => {
    setProductAddInputDatas({
      ...productAddInputDatas,
      minStockValue: stockLimitDataAwait.minStockValue,
      minStock: stockLimitDataAwait.minStock,
      maxStockValue: stockLimitDataAwait.maxStockValue,
      maxStock: stockLimitDataAwait.maxStock,
      isLimitStockOpen: false,
    });
  };

  // UPDATE 5 Nov 2022
  return (
    <>
      {desktopMode ? (
        <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
          {/* BackButton */}
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Box>
              <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                Add Product
              </Typography>
              {roadHirarki.category !== '' &&
                roadHirarki.subCategory !== '' && (
                  <Box
                    sx={{
                      display: 'flex',
                      color: '#9E9D9D',

                      alignItems: 'center',
                      mt: '4px',
                      gap: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      Subsidiary
                    </Typography>
                    <ChevronRightIcon sx={{ fontSize: '16px' }} />
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      {roadHirarki.category}
                    </Typography>
                    <ChevronRightIcon sx={{ fontSize: '16px' }} />
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      {roadHirarki.subCategory}
                    </Typography>
                  </Box>
                )}
            </Box>
          </Grid>
          {/* Name */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Product Name*
            </Typography>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter product name"
              value={productAddInputDatas.name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    name: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 5 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {productAddInputDatas.name.length}/30
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pb: `${
                  productAddInputDatas.photo.length === 0 ? '16px' : '9px'
                }`,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Photo
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#9E9D9D',
                }}
              >
                ( max size : 2MB )
              </Typography>
              {productAddInputDatas.isMaxSizePhoto && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                      mr: '4px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                    }}
                  >
                    The photo can't be more than 2MB
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {productAddInputDatas.photo.length === 0 && (
                  <Box
                    sx={{
                      height: '54px',
                      width: '54px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      bgcolor: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderStyle: 'dashed',
                      borderColor: '#9E9D9D',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputProduct_img').click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '28px' }}
                    />
                  </Box>
                )}
                {productAddInputDatas.photo.map((input, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      padding: '7px',
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: 'absolute',
                        fontSize: '20px',
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setProductAddInputDatas({
                          ...productAddInputDatas,
                          photo: productAddInputDatas.photo.filter(
                            (data) => data !== input
                          ),
                          photoUrls: productAddInputDatas.photoUrls.filter(
                            (data, idx) => idx !== index
                          ),
                          indexPreviePhoto: 0,
                        });
                      }}
                    />
                    <Box
                      component="img"
                      src={URL.createObjectURL(input)}
                      sx={{
                        height: '54px',
                        width: '54px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setProductAddInputDatas({
                          ...productAddInputDatas,
                          indexPreviePhoto: index + 1,
                          isPreviewPhoto: 'true',
                        });
                      }}
                    />
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  mb: '7px',
                  ml: '20px',
                  alignItems: 'center',
                }}
              >
                {productAddInputDatas.photo.length < 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputProduct_img').click();
                    }}
                  >
                    <input
                      accept="image/*"
                      id="inputProduct_img"
                      type="file"
                      style={{ display: 'none' }}
                      // *---------------- Upload image --------------
                      onChange={(e) => {
                        if (e.target.files[0].size > 2000000) {
                          setProductAddInputDatas({
                            ...productAddInputDatas,
                            isMaxSizePhoto: true,
                          });
                        } else {
                          let datas = {
                            image: e.target.files[0],
                            resource: 'product',
                          };
                          uploadImage(datas).then((res) => {
                            setProductAddInputDatas({
                              ...productAddInputDatas,
                              photo: [
                                ...productAddInputDatas.photo,
                                e.target.files[0],
                              ],
                              photoUrls: [
                                ...productAddInputDatas.photoUrls,
                                res.data.data.path,
                              ],
                              isMaxSizePhoto: false,
                            });
                          });
                        }
                      }}
                    />
                    <AddIcon />
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                      Add More
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/* Photo Dialoge */}
          {productAddInputDatas.indexPreviePhoto !== 0 && (
            <Dialog
              open={productAddInputDatas.isPreviewPhoto}
              onClose={() => {
                setProductAddInputDatas({
                  ...productAddInputDatas,
                  isPreviewPhoto: !productAddInputDatas.isPreviewPhoto,
                });
              }}
            >
              <Box
                component="img"
                src={URL.createObjectURL(
                  productAddInputDatas.photo[
                    productAddInputDatas.indexPreviePhoto - 1
                  ]
                )}
                sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
              />
            </Dialog>
          )}
          {/* Category , UNIT, Variant */}
          <Grid container item xs={12} columns={12} spacing={4}>
            {/* Category */}
            <Grid item xs={6}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    isCategoryOpen: !productAddInputDatas.isCategoryOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography>Category*</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productAddInputDatas.category === '' &&
                      productAddInputDatas.subCategory === '' ? (
                        <Typography
                          sx={{ fontSize: '12px', fontWeight: '500' }}
                        >
                          Click to Select category
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: '12px' }}>
                            {productAddInputDatas.category}
                          </Typography>
                          <ChevronRightIcon sx={{ fontSize: '12px' }} />
                          <Typography sx={{ fontSize: '12px' }}>
                            {productAddInputDatas.subCategory}
                          </Typography>
                          {productAddInputDatas.miniSubCategory !== '' && (
                            <>
                              <ChevronRightIcon sx={{ fontSize: '12px' }} />
                              <Typography sx={{ fontSize: '12px' }}>
                                {productAddInputDatas.miniSubCategory}
                              </Typography>
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
            {/* VAriant */}
            <Grid item xs={6}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    isVariantOpen: !productAddInputDatas.isVariantOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography>Variant</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productAddInputDatas.color === '' &&
                      productAddInputDatas.size === 0 ? (
                        <Typography
                          sx={{ fontSize: '12px', fontWeight: '500' }}
                        >
                          Click to select variant (color,size)
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          {productAddInputDatas.color !== '' &&
                          productAddInputDatas.size !== '' ? (
                            <Typography sx={{ fontSize: '12px' }}>
                              {productAddInputDatas.color},{' '}
                              {productAddInputDatas.size}
                            </Typography>
                          ) : productAddInputDatas.color !== '' &&
                            productAddInputDatas.size === '' ? (
                            <Typography sx={{ fontSize: '12px' }}>
                              {productAddInputDatas.color}
                            </Typography>
                          ) : (
                            <Typography sx={{ fontSize: '12px' }}>
                              {productAddInputDatas.size}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
            {/* UNit */}
            <Grid item xs={6}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    isUnitOpen: !productAddInputDatas.isUnitOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography>Unit*</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productAddInputDatas.unitValue1 === '' &&
                      productAddInputDatas.unitValue1name === '' ? (
                        <>
                          {productAddInputDatas.unitValueSubmited.length !==
                          0 ? (
                            <Box sx={{ display: 'flex', gap: 2 }}>
                              {productAddInputDatas.unitValueSubmited.map(
                                (value, index) => (
                                  <Typography
                                    sx={{ fontSize: '12px', fontWeight: '500' }}
                                    key={index}
                                  >
                                    {value}
                                  </Typography>
                                )
                              )}
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex' }}>
                              <Typography
                                sx={{ fontSize: '12px', fontWeight: '500' }}
                              >
                                Click to Select Unit
                              </Typography>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: '12px' }}>
                            {productAddInputDatas.unitValue1}{' '}
                            {productAddInputDatas.unitValue1name}
                          </Typography>
                          {productAddInputDatas.unitValue2name !== '' && (
                            <Typography sx={{ fontSize: '12px' }}>
                              - {productAddInputDatas.unitValue2}{' '}
                              {productAddInputDatas.unitValue2name}
                            </Typography>
                          )}
                          {productAddInputDatas.unitValue3name !== '' && (
                            <Typography sx={{ fontSize: '12px' }}>
                              - {productAddInputDatas.unitValue3}{' '}
                              {productAddInputDatas.unitValue3name}
                            </Typography>
                          )}
                          {productAddInputDatas.unitValue4name !== '' && (
                            <Typography sx={{ fontSize: '12px' }}>
                              - {productAddInputDatas.unitValue4}{' '}
                              {productAddInputDatas.unitValue4name}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>

            {/* limit stock */}
            <Grid item xs={6}>
              <CustomCard
                sx={{ padding: '16px 14px', cursor: 'pointer' }}
                onClick={() =>
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    isLimitStockOpen: !productAddInputDatas.isCategoryOpen,
                  })
                }
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <Typography>Stock Limit*</Typography>
                    <Box
                      sx={{
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {productAddInputDatas.minStockValue === '' &&
                      productAddInputDatas.maxStockValue === '' ? (
                        <Typography
                          sx={{ fontSize: '12px', fontWeight: '500' }}
                        >
                          Click to determine min and max stock
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: '12px' }}>
                            {productAddInputDatas.minStockValue}{' '}
                            {productAddInputDatas.minStock}
                          </Typography>
                          <Typography sx={{ fontSize: '12px' }}>
                            - {productAddInputDatas.maxStockValue}{' '}
                            {productAddInputDatas.maxStock}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>
          </Grid>
          {/* Dialog choosen */}
          {/*------------------- Dialog Category------------------- */}
          <Dialog
            open={productAddInputDatas.isCategoryOpen}
            onClose={() =>
              setProductAddInputDatas({
                ...productAddInputDatas,
                isCategoryOpen: !productAddInputDatas.isCategoryOpen,
              })
            }
            maxWidth="md"
          >
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{productAddInputDatas.name} Category</Typography>
              <CloseIcon
                onClick={() =>
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    isCategoryOpen: !productAddInputDatas.isCategoryOpen,
                  })
                }
                sx={{ cursor: 'pointer' }}
              />
            </DialogTitle>
            <DialogContent dividers sx={{ minWidth: '100%' }}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItem: 'center',
                    gap: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  {/* Category Choose */}
                  <Box>
                    <Typography
                      sx={{ mb: '12px', fontSize: '16px', fontWeight: '500' }}
                    >
                      Category*
                    </Typography>

                    <Autocomplete
                      sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                      open={selectOpen === 'category' && true}
                      onFocus={() => setSelectOpen('category')}
                      onBlur={() => setSelectOpen('')}
                      options={categoryOption}
                      inputValue={productAddInputDatas.categoryInput}
                      onInputChange={(event, newInputValue) => {
                        setProductAddInputDatas({
                          ...productAddInputDatas,
                          categoryInput: newInputValue,
                        });
                      }}
                      value={categoryDataAwait.categoryChoose}
                      onChange={(event, newValue) => {
                        setCategoryDataAwait({
                          ...categoryDataAwait,
                          categoryChoose: newValue,
                          categoryId: newValue.idKategori,
                          category: newValue.namaKategori,
                          subCategoryChoose: null,
                          subCategoryId: 0,
                          subCategory: '',
                          miniSubCategoryChoose: null,
                          miniSubCategoryId: 0,
                          miniSubCategory: '',
                        });
                        setSelectOpen('subCategory');
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.idKategori}
                        >
                          {option.namaKategori}
                        </Box>
                      )}
                      clearIcon={
                        <ClearIcon
                          fontSize="small"
                          onClick={() => {
                            setProductAddInputDatas({
                              ...productAddInputDatas,
                              categoryInput: '',
                            });
                            setCategoryDataAwait({
                              ...categoryDataAwait,
                              categoryChoose: null,
                              categoryId: 0,
                              category: '',
                              subCategoryChoose: null,
                              subCategoryId: 0,
                              subCategory: '',
                              miniSubCategoryChoose: null,
                              miniSubCategoryId: 0,
                              miniSubCategory: '',
                            });
                          }}
                        />
                      }
                      getOptionLabel={(option) => option.namaKategori}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Categories"
                          onFocus={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              categoryOpen: true,
                            });
                          }}
                          onBlur={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              categoryOpen: false,
                            });
                          }}
                        />
                      )}
                      loading={categoryOptionLoading}
                    />

                    {/* end */}
                  </Box>
                  {/* Sub Category Choose */}
                  <Box>
                    <Typography
                      sx={{ mb: '12px', fontSize: '16px', fontWeight: '500' }}
                    >
                      Sub Category*
                    </Typography>
                    <Autocomplete
                      sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                      open={selectOpen === 'subCategory' && true}
                      onFocus={() => setSelectOpen('subCategory')}
                      onBlur={() => setSelectOpen('')}
                      options={subCategoryOption}
                      inputValue={productAddInputDatas.subCategoryInput}
                      onInputChange={(event, newInputValue) => {
                        setProductAddInputDatas({
                          ...productAddInputDatas,
                          subCategoryInput: newInputValue,
                        });
                      }}
                      value={categoryDataAwait.subCategoryChoose}
                      onChange={(event, newValue) => {
                        setCategoryDataAwait({
                          ...categoryDataAwait,
                          subCategoryChoose: newValue,
                          subCategoryId: newValue.idSubkategori,
                          subCategory: newValue.subkategori,
                          miniSubCategoryChoose: null,
                          miniSubCategoryId: 0,
                          miniSubCategory: '',
                        });
                        setSelectOpen('mini');
                      }}
                      clearIcon={
                        <ClearIcon
                          fontSize="small"
                          onClick={() => {
                            setProductAddInputDatas({
                              ...productAddInputDatas,
                              subCategoryInput: '',
                            });
                            setCategoryDataAwait({
                              ...categoryDataAwait,
                              subCategoryChoose: null,
                              subCategoryId: 0,
                              subCategory: '',
                              miniSubCategoryChoose: null,
                              miniSubCategoryId: 0,
                              miniSubCategory: '',
                            });
                          }}
                        />
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.isSubkategori}
                        >
                          {option.subkategori}
                        </Box>
                      )}
                      getOptionLabel={(option) => option.subkategori}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Subcategory"
                          onFocus={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              subCategoryOpen: true,
                            });
                          }}
                          onBlur={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              subCategoryOpen: false,
                            });
                          }}
                        />
                      )}
                      loading={subCategoryOptionLoading}
                    />
                  </Box>
                  {/* Mini Sub Category Choose */}
                  <Box>
                    <Typography
                      sx={{ mb: '12px', fontSize: '16px', fontWeight: '500' }}
                    >
                      Mini
                    </Typography>
                    <Autocomplete
                      sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                      open={selectOpen === 'mini' && true}
                      onFocus={() => setSelectOpen('mini')}
                      onBlur={() => setSelectOpen('')}
                      options={miniSubCategoryOption}
                      inputValue={productAddInputDatas.miniSubCategoryInput}
                      onInputChange={(event, newInputValue) => {
                        setProductAddInputDatas({
                          ...productAddInputDatas,
                          miniSubCategoryInput: newInputValue,
                        });
                      }}
                      value={categoryDataAwait.miniSubCategoryChoose}
                      onChange={(event, newValue) => {
                        setCategoryDataAwait({
                          ...categoryDataAwait,
                          miniSubCategoryChoose: newValue,
                          miniSubCategoryId: newValue.id,
                          miniSubCategory: newValue.name,
                        });
                        setSelectOpen('');
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.id}
                        >
                          {option.name}
                        </Box>
                      )}
                      clearIcon={
                        <ClearIcon
                          fontSize="small"
                          onClick={() => {
                            setProductAddInputDatas({
                              ...productAddInputDatas,
                              miniSubCategoryInput: '',
                            });
                            setCategoryDataAwait({
                              ...categoryDataAwait,
                              miniSubCategoryChoose: null,
                              miniSubCategoryId: 0,
                              miniSubCategory: '',
                            });
                          }}
                        />
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Subcategory"
                          onFocus={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              miniSubCategoryOpen: true,
                            });
                          }}
                          onBlur={() => {
                            setCatSubMiniProduct({
                              ...catSubMiniProduct,
                              miniSubCategoryOpen: false,
                            });
                          }}
                        />
                      )}
                      loading={miniSubCategoryOptionLoading}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}
                >
                  <CustomButton
                    variant="contained"
                    disabled={
                      categoryDataAwait.categoryChoose === null ||
                      categoryDataAwait.subCategoryChoose == null
                        ? true
                        : false
                    }
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      height: '48px',
                      width: '190px',
                    }}
                    onClick={() => {
                      setProductAddInputDatas({
                        ...productAddInputDatas,
                        category: categoryDataAwait.category,
                        subCategory: categoryDataAwait.subCategory,
                        miniSubCategory: categoryDataAwait.miniSubCategory,
                        categoryId: categoryDataAwait.categoryId,
                        subCategoryId: categoryDataAwait.subCategoryId,
                        miniSubCategoryId: categoryDataAwait.miniSubCategoryId,
                        isCategoryOpen: false,
                      });
                    }}
                  >
                    <Typography sx={{ color: 'white' }}>Enter</Typography>
                  </CustomButton>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
          {/*------------------- Dialog unit------------------- */}
          <Dialog
            open={productAddInputDatas.isUnitOpen}
            maxWidth="md"
            onClose={() => {
              setProductAddInputDatas({
                ...productAddInputDatas,
                isUnitOpen: !productAddInputDatas.isUnitOpen,
              });
            }}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{productAddInputDatas.name} Unit</Typography>
              <CloseIcon
                onClick={() =>
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    isUnitOpen: !productAddInputDatas.isUnitOpen,
                  })
                }
                sx={{ cursor: 'pointer' }}
              />
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  Unit*
                </Typography>
                {/* unit select 1 */}
                <FormControl variant="outlined" sx={{ mt: '12px' }}>
                  <OutlinedInput
                    sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                    placeholder="Enter Qty"
                    type="number"
                    value={unitDataAwait.unitValue1}
                    onChange={(e) =>
                      setUnitDataAwait({
                        ...unitDataAwait,
                        unitValue1: e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Select
                          value={unitDataAwait.unitValue1name}
                          onChange={(e) =>
                            setUnitDataAwait({
                              ...unitDataAwait,
                              unitValue1name: e.target.value,
                              unitValue1Id: unitDatas.find(
                                (item) => item.name === e.target.value
                              ).id,
                              unitValue2: '',
                              unitValue2name: '',
                              unitValue2Id: 0,
                              unitValue3: '',
                              unitValue3name: '',
                              unitValue3Id: 0,
                              unitValue4: '',
                              unitValue4name: '',
                              unitValue4Id: 0,
                              unitValue1: '',
                              unitValue2: '',
                              unitValue3: '',
                              unitValue4: '',
                            })
                          }
                          label="Age"
                          sx={{
                            minWidth: '130px',
                            bgcolor: 'white',
                            '.MuiOutlinedInput-input': {
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              paddingLeft: '12px',
                              paddingRight: '0',
                              borderRadius: '5px',
                            },
                          }}
                        >
                          {/* {unitDataAwait.unit1Open ? (
                            <MenuItem value="">none</MenuItem>
                          ) : (
                            <MenuItem value="">unit</MenuItem>
                          )} */}
                          {unitDatas.map((unitData, index) => (
                            <MenuItem value={unitData.name} key={index}>
                              {unitData.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
                {/* unit select 2 */}
                {unitDataAwait.unitValue1name !== '' && (
                  <FormControl variant="outlined" sx={{ mt: '12px' }}>
                    <OutlinedInput
                      sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                      placeholder="Enter Qty"
                      type="number"
                      value={unitDataAwait.unitValue2}
                      onChange={(e) =>
                        setUnitDataAwait({
                          ...unitDataAwait,
                          unitValue2: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Select
                            value={unitDataAwait.unitValue2name}
                            onChange={(e) =>
                              setUnitDataAwait({
                                ...unitDataAwait,
                                unitValue2name: e.target.value,
                                unitValue2Id: unitDatas.find(
                                  (item) => item.name === e.target.value
                                ).id,
                                unitValue3: '',
                                unitValue3name: '',
                                unitValue3Id: 0,
                                unitValue4: '',
                                unitValue4name: '',
                                unitValue4Id: 0,
                                unitValue2: '',
                                unitValue3: '',
                                unitValue4: '',
                              })
                            }
                            label="Age"
                            sx={{
                              minWidth: '130px',
                              bgcolor: 'white',
                              '.MuiOutlinedInput-input': {
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingLeft: '12px',
                                paddingRight: '0',
                                borderRadius: '5px',
                              },
                            }}
                          >
                            {unitDatas.map((unitData, index) => (
                              <MenuItem value={unitData.name} key={index}>
                                {unitData.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                )}
                {/* unit select 3 */}
                {unitDataAwait.unitValue2name !== '' && (
                  <FormControl variant="outlined" sx={{ mt: '12px' }}>
                    <OutlinedInput
                      sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                      placeholder="Enter Qty"
                      type="number"
                      value={unitDataAwait.unitValue3}
                      onChange={(e) =>
                        setUnitDataAwait({
                          ...unitDataAwait,
                          unitValue3: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Select
                            value={unitDataAwait.unitValue3name}
                            onChange={(e) =>
                              setUnitDataAwait({
                                ...unitDataAwait,
                                unitValue3name: e.target.value,
                                unitValue3Id: unitDatas.find(
                                  (item) => item.name === e.target.value
                                ).id,
                                unitValue4: '',
                                unitValue4name: '',
                                unitValue4Id: 0,
                                unitValue3: '',
                                unitValue4: '',
                              })
                            }
                            label="Age"
                            sx={{
                              minWidth: '130px',
                              bgcolor: 'white',
                              '.MuiOutlinedInput-input': {
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingLeft: '12px',
                                paddingRight: '0',
                                borderRadius: '5px',
                              },
                            }}
                          >
                            {unitDatas.map((unitData, index) => (
                              <MenuItem value={unitData.name} key={index}>
                                {unitData.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                )}
                {/* Unit select 4 */}
                {unitDataAwait.unitValue3name !== '' && (
                  <FormControl variant="outlined" sx={{ mt: '12px' }}>
                    <OutlinedInput
                      sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                      placeholder="Enter Qty"
                      type="number"
                      value={unitDataAwait.unitValue4}
                      onChange={(e) =>
                        setUnitDataAwait({
                          ...unitDataAwait,
                          unitValue4: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Select
                            value={unitDataAwait.unitValue4name}
                            onChange={(e) =>
                              setUnitDataAwait({
                                ...unitDataAwait,
                                unitValue4name: e.target.value,
                                unitValue4Id: unitDatas.find(
                                  (item) => item.name === e.target.value
                                ).id,
                                unitValue4: '',
                              })
                            }
                            label="Age"
                            sx={{
                              minWidth: '130px',
                              bgcolor: 'white',
                              '.MuiOutlinedInput-input': {
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingLeft: '12px',
                                paddingRight: '0',
                                borderRadius: '5px',
                              },
                            }}
                          >
                            {unitDatas.map((unitData, index) => (
                              <MenuItem value={unitData.name} key={index}>
                                {unitData.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}>
                <CustomButton
                  variant="contained"
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    height: '48px',
                    width: '190px',
                  }}
                  disabled={!unitDataAwait.unitValueSubmitedCheck}
                  onClick={handleAddUnit}
                >
                  <Typography sx={{ color: 'white' }}>Enter</Typography>
                </CustomButton>
              </Box>
            </DialogContent>
          </Dialog>
          {/*------------------- Dialog variant -------------------*/}
          <Dialog
            open={productAddInputDatas.isVariantOpen}
            maxWidth="md"
            onClose={() => {
              setProductAddInputDatas({
                ...productAddInputDatas,
                isVariantOpen: !productAddInputDatas.isVariantOpen,
              });
            }}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{productAddInputDatas.name} Variant</Typography>
              <CloseIcon
                onClick={() =>
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    isVariantOpen: !productAddInputDatas.isVariantOpen,
                  })
                }
                sx={{ cursor: 'pointer' }}
              />
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box>
                  <Typography sx={{ mb: '12px' }}>Color</Typography>
                  <CustomInput
                    placeholder="Enter Color"
                    sx={{ bgcolor: '#FAFAFA', minWidth: '340px' }}
                    value={variatnDataAwait.color}
                    onChange={(e) =>
                      setVariantDataAwait({
                        ...variatnDataAwait,
                        color: e.target.value,
                      })
                    }
                  />
                </Box>
                <Box>
                  <Typography sx={{ mb: '12px' }}>Size</Typography>
                  <CustomInput
                    placeholder="Enter Size"
                    sx={{ bgcolor: '#FAFAFA', minWidth: '340px' }}
                    value={variatnDataAwait.size}
                    type="number"
                    onChange={(e) =>
                      setVariantDataAwait({
                        ...variatnDataAwait,
                        size: e.target.value,
                      })
                    }
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}>
                <CustomButton
                  variant="contained"
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    height: '48px',
                    width: '190px',
                  }}
                  onClick={() => {
                    setProductAddInputDatas({
                      ...productAddInputDatas,
                      color: variatnDataAwait.color,
                      size: variatnDataAwait.size,
                      isVariantOpen: false,
                    });
                  }}
                >
                  <Typography sx={{ color: 'white' }}>Enter</Typography>
                </CustomButton>
              </Box>
            </DialogContent>
          </Dialog>
          {/*------------------- Dialog limit stock -------------------*/}
          <Dialog
            open={productAddInputDatas.isLimitStockOpen}
            maxWidth="md"
            onClose={() => {
              setProductAddInputDatas({
                ...productAddInputDatas,
                isLimitStockOpen: !productAddInputDatas.isLimitStockOpen,
              });
            }}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{productAddInputDatas.name} Stock Limit</Typography>
              <CloseIcon
                onClick={() =>
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    isLimitStockOpen: !productAddInputDatas.isLimitStockOpen,
                  })
                }
                sx={{ cursor: 'pointer' }}
              />
            </DialogTitle>
            <DialogContent dividers>
              <Grid container columns={12} spacing={4}>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: '500' }}>
                    Min. Stock*
                  </Typography>
                  <FormControl variant="outlined" sx={{ mt: '12px' }}>
                    <OutlinedInput
                      sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                      placeholder="Enter Qty"
                      type="number"
                      value={stockLimitDataAwait.minStockValue}
                      onChange={(e) =>
                        setStockLimitDataAwait({
                          ...stockLimitDataAwait,
                          minStockValue: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Select
                            value={stockLimitDataAwait.minStock}
                            onChange={(e) =>
                              setStockLimitDataAwait({
                                ...stockLimitDataAwait,
                                minStock: e.target.value,
                              })
                            }
                            label="Age"
                            sx={{
                              minWidth: '130px',
                              bgcolor: 'white',
                              '.MuiOutlinedInput-input': {
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingLeft: '12px',
                                paddingRight: '0',
                                borderRadius: '5px',
                              },
                            }}
                          >
                            {/* {unitDataAwait.unitValue1name === '' && (
                          <MenuItem value="unit">Unit</MenuItem>
                        )} */}
                            {unitDatas.map((unitData, index) => (
                              <MenuItem value={unitData.name} key={index}>
                                {unitData.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: '500' }}>
                    Max. Stock*
                  </Typography>
                  <FormControl variant="outlined" sx={{ mt: '12px' }}>
                    <OutlinedInput
                      sx={{ bgcolor: '#FAFAFA', p: '4px 8px 4px 4px' }}
                      placeholder="Enter Qty"
                      type="number"
                      value={stockLimitDataAwait.maxStockValue}
                      onChange={(e) =>
                        setStockLimitDataAwait({
                          ...stockLimitDataAwait,
                          maxStockValue: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Select
                            value={stockLimitDataAwait.maxStock}
                            onChange={(e) =>
                              setStockLimitDataAwait({
                                ...stockLimitDataAwait,
                                maxStock: e.target.value,
                              })
                            }
                            label="Age"
                            sx={{
                              minWidth: '130px',
                              bgcolor: 'white',
                              '.MuiOutlinedInput-input': {
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                paddingLeft: '12px',
                                paddingRight: '0',
                                borderRadius: '5px',
                              },
                            }}
                          >
                            {/* {unitDataAwait.unitValue1name === '' && (
                          <MenuItem value="unit">Unit</MenuItem>
                        )} */}
                            {unitDatas.map((unitData, index) => (
                              <MenuItem value={unitData.name} key={index}>
                                {unitData.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  mt: '20px',
                }}
              >
                <CustomButton
                  disabled={!stockLimitDataAwait.isCheck}
                  variant="contained"
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    height: '48px',
                    width: '190px',
                  }}
                  onClick={handleClickStockLimit}
                >
                  <Typography sx={{ color: 'white' }}>Enter</Typography>
                </CustomButton>
              </Box>
            </DialogContent>
          </Dialog>
          {/* Meta Description */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Meta Description
            </Typography>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter Meta Description"
              value={productAddInputDatas.metaDescription}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    metaDescription: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {productAddInputDatas.metaDescription.length}/250
              </Typography>
            </Box>
          </Grid>
          {/* Schedule */}
          <Grid item xs={6}>
            <Grid container>
              <Grid
                item
                alignItems="center"
                sx={{ height: '100%', mr: '20px' }}
              >
                <Typography
                  sx={{
                    mb: '16px',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  Schedule
                </Typography>
              </Grid>
              <Grid item alignItems="center" sx={{ height: '100%' }}>
                <RadioGroup
                  row
                  value={productAddInputDatas.scheduleType}
                  onChange={(e) => {
                    setProductAddInputDatas({
                      ...productAddInputDatas,
                      schedule: today,
                      scheduleType: e.target.value,
                    });
                  }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '16px',
                    },
                    '.MuiRadio-root': {
                      padding: '0 8px',
                    },
                    '.MuiTypography-root': {
                      fontSize: '12px',
                    },
                  }}
                >
                  <FormControlLabel
                    value="now"
                    control={<Radio />}
                    label="Now"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', minWidth: '100%' }}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={productAddInputDatas.schedule}
                onChange={(newValue) => {
                  if (productAddInputDatas.scheduleType !== 'now') {
                    setProductAddInputDatas({
                      ...productAddInputDatas,
                      schedule: newValue,
                    });
                  } else {
                    setProductAddInputDatas({
                      ...productAddInputDatas,
                      schedule: today,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                  />
                )}
                disabled={
                  productAddInputDatas.scheduleType === 'now' ? true : false
                }
              />
            </Box>
          </Grid>
          {/* Descripiton */}
          <Grid item xs={12}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Description*
            </Typography>
            <CustomTextFormatingInput
              setEditorContent={setDescriptionProduct}
              isError={false}
              helperText=""
              setIsMinInputText={setIsMinDescriptionProduct}
              desktopMode={desktopMode}
            />
          </Grid>
          {/* footer */}
          <Box
            sx={{
              width: '100%',
              bgcolor: 'white',
              position: 'fixed',
              bottom: '0px',
              right: '0px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '18px 32px 18px 240px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Publish
              </Typography>
              <AntSwitch
                checked={productAddInputDatas.publish}
                onChange={(e) => {
                  setProductAddInputDatas({
                    ...productAddInputDatas,
                    publish: !productAddInputDatas.publish,
                  });
                }}
              />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <CustomButton
                variant="contained"
                onClick={handleAddNewProduct}
                disabled={!isSubmitAvailable}
                sx={{ width: '140px', height: '36px' }}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Add
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      ) : (
        <ProductProductAddMobile
          {...{
            handleBackButton,
            roadHirarki,
            productAddInputDatas,
            setProductAddInputDatas,
            theme,
            selectOpen,
            setSelectOpen,
            categoryOption,
            setCategoryDataAwait,
            categoryDataAwait,
            setSubCategoryOptionLoading,
            setCatSubMiniProduct,
            catSubMiniProduct,
            categoryOptionLoading,
            subCategoryOption,
            setMiniSubCategoryOptionLoading,
            subCategoryOptionLoading,
            miniSubCategoryOption,
            miniSubCategoryOptionLoading,
            unitDataAwait,
            setUnitDataAwait,
            unitDatas,
            variatnDataAwait,
            setVariantDataAwait,
            setDescriptionProduct,
            setIsMinDescriptionProduct,
            AntSwitch,
            handleAddNewProduct,
            stockLimitDataAwait,
            setStockLimitDataAwait,
            handleClickStockLimit,
            isSubmitAvailable,
          }}
        />
      )}
    </>
  );
};
export default ProductProductAdd;
