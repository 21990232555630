import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  AddIcon,
  ChevronRightIcon,
  Different,
  ExportIcon,
  FilterAltIcon,
  IsUser,
  SearchIcon,
  SkuIcon,
  ToBarChart,
  ToLineChart,
} from '../../../../../../assets/Icons';
import Subsidiary from '../../../../../../components/shared/Subsidiary';
import { useEffect, useRef, useState } from 'react';
import CustomCard from '../../../../../../components/shared/CustomCard';
import CustomLineChart from '../../../../../../components/shared/CustomLineChart';
import CustomBarChart from '../../../../../../components/shared/CustomBarChart';
import CustomTableMobile from '../../../../../../components/shared/CustomTableMobile';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';
import { getAllDataAudit } from '../../../../../../api/audit';
import CustomButton from '../../../../../../components/shared/CustomButton';
import DialogFilterAuditMobile from './DialogFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDataFilterHubs,
  getEndDateFilterAudit,
  getStartDateFilterAudit,
} from '../../../../../../store/audit/dataTableAudit';
import {
  changeHubDetailAudit,
  changeIdDetailAudit,
} from '../../../../../../store/audit/detailAudit';
import { changePageAudit } from '../../../../../../store/Routing/auditRouting';

// data dummy chart
const datas = {
  labels: ['Hub 1', 'Hub 2', 'Hub 3', 'Hub 4', 'Hub 5'],
  data: [
    {
      label: 'Equal',
      chart: [30, 110, 140, 100, 240],
    },
    {
      label: 'Different',
      chart: [0, 35, 100, 90, 150],
    },
    {
      label: 'Missing',
      chart: [40, 70, 160, 120, 200],
    },
  ],
};
const color = ['#6B4BB4', '#EACD57', '#FD7900'];

const newData = [
  {
    SKU: 1,
    type_hub: 'sub_hubs',
    hub_id: 3,
    nama: 'Hub 1',
    cogs: '7',
  },
  {
    SKU: 1,
    type_hub: 'sub_hubs',
    hub_id: 3,
    nama: 'Hub 2',
    cogs: '7',
  },
  {
    SKU: 1,
    type_hub: 'sub_hubs',
    hub_id: 3,
    nama: 'Hub 3',
    cogs: '7',
  },
  {
    SKU: 1,
    type_hub: 'sub_hubs',
    hub_id: 3,
    nama: 'Hub 4',
    cogs: '7',
  },
  {
    SKU: 1,
    type_hub: 'sub_hubs',
    hub_id: 3,
    nama: 'Hub 5',
    cogs: '7',
  },
];

const AuditInventoryMobile = () => {
  const dispatch = useDispatch();
  const [chartType, setChartType] = useState(false);
  const [dataAudit, setDataAudit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  // ! --------- Subsidiary ---------
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);
  // ! --------- chart grafik --------
  const [grafikData, setGrafikData] = useState(datas?.data);
  // !search
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');

  // !filter
  const hubs = useSelector(getDataFilterHubs);
  const startDate = useSelector(getStartDateFilterAudit);
  const endDate = useSelector(getEndDateFilterAudit);

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  // ! ---------------- fetch API ------------------
  const fetchAllDataAudits = async (params) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getAllDataAudit(params);
      setDataAudit(data?.audits);
      // setPages(data?.pageInfo);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let params = {};
    params.pageSize = 50;
    params.page = 1;
    params.startDate = startDate;
    params.endDate = endDate;
    // ! filter
    params.dataHub = hubs;
    // ! search
    params.search = purchaseKeyword;

    fetchAllDataAudits(params);
  }, [purchaseKeyword, hubs, startDate, endDate]);

  //!  ----------------------------- Toolbabr ----------------------------
  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row-reverse',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '20px',
          height: '20px',
          bgcolor: '#51B15C',
          color: '#ffffff',
          borderRadius: '4px',
          '&:hover': {
            bgcolor: '#51B15C',
            color: '#ffffff',
          },
          fontSize: '0.875rem',
          display: 'flex',
          justifyContent: 'center',
        }}
        // startIcon={}
        onClick={() => {
          handleAddRequest();
        }}
      >
        <AddIcon sx={{ width: '14px', color: '#FFFFFF' }} />
      </CustomButton>
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <ExportIcon />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ width: '14px' }} />
      </CustomButton>
      <DialogFilterAuditMobile {...{ isFilter, setIsFilter }} />
    </Box>
  );

  // ! handler
  const handleAddRequest = () => {
    dispatch(changePageAudit('addAudit'));
  };

  return (
    <>
      <Grid container columns={12} spacing={2}>
        {/* subsidiary */}
        <Grid item xs={12}>
          <Subsidiary
            subsidiaryChecked={filterSubsidiaryArray}
            setSubsidiaryChecked={setFilterSubsidiaryArray}
            pad={1}
          />
        </Grid>

        {/* grafik */}
        <Grid item xs={12}>
          <CustomCard sx={{ p: 2 }}>
            <Typography
              sx={{ color: '#000', fontSize: '10px', fontWeight: 600, mb: 2 }}
            >
              Hub Audit
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  fontSize: '8px',
                  color: '#6B4BB4',
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                SKU
              </Typography>
              <Box
                sx={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setChartType(!chartType)}
              >
                {chartType ? (
                  <ToLineChart stroke="#51B15C" />
                ) : (
                  <ToBarChart stroke="#51B15C" />
                )}
              </Box>
              <Box></Box>
            </Box>
            {chartType ? (
              <CustomBarChart datas={datas} color={color} />
            ) : (
              <CustomLineChart datas={datas} color={color} />
            )}
          </CustomCard>
        </Grid>

        {/* table grafik */}
        <Grid item xs={12}>
          <CustomTableMobile
            rootValue={datas?.labels}
            datas={datas.data}
            color={color}
          />
        </Grid>

        {/* list data audit */}
        <Grid item xs={12}>
          <CustomCard sx={{ p: '10px 10px' }}>
            <Grid container columns={12} spacing={2}>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    display: 'inline-flex',
                    fontSize: '12px',
                    fontWeight: 600,
                    color: '#000',
                  }}
                >
                  Audit List
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  placeholder={'Search'}
                  size="small"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debounceOnChange(e.target.value);
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: '6px',
                      fontSize: '7px',
                    },
                    bgcolor: '#FAFAFA',
                    minHeight: '22px',
                    height: '22px',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ width: '12px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                {toolbar}
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <SkuIcon />
                  <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                    SKU
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Different />
                  <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                    Difference
                  </Typography>
                </Box>
              </Grid>

              {/* mapping data */}
              {isLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <CircularProgress />
                </Grid>
              ) : dataAudit?.length === 0 ? (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      fontSize: '8px',
                      display: 'flex',
                      color: '#F33A3A',
                      justifyContent: 'center',
                    }}
                  >
                    No Request Purchase
                  </Box>
                </Grid>
              ) : (
                dataAudit?.map((item, idx) => (
                  <Grid item xs={6} key={idx}>
                    <Box
                      sx={{
                        background: '#FAFAFA',
                        borderRadius: '8px',
                        p: '10px',
                      }}
                      onClick={() => {
                        dispatch(changeIdDetailAudit(item?.id));
                        dispatch(changeHubDetailAudit(item?.hub));
                        dispatch(changePageAudit('detailsAudits'));
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{ fontSize: '10px', fontWeight: 600 }}
                            >
                              {item?.id !== '' ? item?.id : '-'}{' '}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: '8px' }}>
                            {dayjs(item?.createdAt).format(
                              ' DD/MMM/YYYY, mm:ss '
                            )}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ mt: '3px' }}>
                        <Typography sx={{ fontSize: '8px' }}>
                          {item?.hub}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <SkuIcon />
                          <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                            {item?.sku}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Different />
                          <Typography
                            sx={{
                              fontSize: '8px',
                              ml: '5px',
                              color:
                                item?.auditQrs?.filter(
                                  (item) => Number(item.difference) !== 0
                                ).length === 0
                                  ? '#000000'
                                  : '#F33A3A',
                            }}
                          >
                            {
                              item?.auditQrs?.filter(
                                (item) => Number(item.difference) !== 0
                              ).length
                            }{' '}
                            SKU
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          </CustomCard>
        </Grid>
      </Grid>
    </>
  );
};
export default AuditInventoryMobile;
