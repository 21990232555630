import {
  Box,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CustomCard from '../../../../../../components/shared/CustomCard';
import Subsidiary from '../../../../../../components/shared/Subsidiary';
import { ProductPerfomance, SeeProduct } from '../DesktopComponent';
import TableProduct from '../DesktopComponent/TableProduct';
import TableCategoryProduct from './Desktop/TableCategoryProduct';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as DollarIcon } from '../../../../../../assets/dollar.svg';
import { ReactComponent as BoxIcon } from '../../../../../../assets/box.svg';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  fetchCategories,
  fetchCategoryDetails,
  fetchSubCategoriesDetails,
} from '../../../../../../api/products';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import CategoryProductMobile from './CategoryProductMobile';
import SubsidiaryChoose from '../../../../../../components/shared/SubsidiaryChoose';

const allData = [{ name: 'All' }];
const allDataType = [{ nmOpsi: 'All' }];

const CategoryProduct = (props) => {
  const {
    // data
    isLoadingData,
    setIsLoadingData,
    // ----
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    setIsCategoryProductAdd,
    setIsCategoryProductEdit,
    setIsCategoryProductDetails,
    // *-date & time
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // Table
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    tableCategoryFilterDatas,
    setTableCategoryFilterDatas,
    isHasMorePage,
    setIsHasMorePage,
    // *----- type ----
    typeCategory,
    setTypeCategorys,
    typeCategoryLoading,
    setTypeCategoryLoading,
    // *-----
    setDetailsDatas,
    // *---------
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *--------
    isLoadingSubsidiary,
    subsidiaryDatas,
    // *---- filters
    filterCategorySubsidiary,
    setFilterCategorySubsidiary,
    filterCategoryType,
    setFilterCategoryType,
  } = props;

  const theme = useTheme();
  // !----------------------------------- Filter -----------------------------------------

  const [autoCompleteFilter, setAutoCompleteFilter] = useState({
    subsidiary: false,
    subsidiaryOpen: false,
    type: false,
    typeOpen: false,
  });
  const [isOpenTableFilter, setIsOpenTableFilter] = useState(false);
  const [subsidiaryFilterValue, setSubsidiaryFilterValue] = useState([]);
  const [typeFilterValue, setTypeFilterValue] = useState([]);

  const [subsidiaryAll, setSubsidiaryAll] = useState([]);
  const [subsidiaryMoment, setSubsidiaryMoment] = useState([]);

  // !-----------------------------------Subsidiary -----------------------------------------
  useEffect(() => {
    if (subsidiaryAll !== subsidiaryMoment) {
      let subsidiaryChoose = [];

      subsidiaryAll.map((item, index) => {
        subsidiaryChoose.push(item.id);
      });

      setFilterCategorySubsidiary(subsidiaryChoose);
      setSubsidiaryFilterValue(subsidiaryAll);
      setSubsidiaryMoment(subsidiaryAll);
    }
  }, [subsidiaryAll, subsidiaryMoment]);
  // !------------------------------------------------------------------------------------

  useEffect(() => {
    setRoadHirarki({
      category: '',
      categoryId: 0,
      subCategory: '',
      subCategoryId: 0,
    });
    setIsCategoryProductAdd(false);
    setIsCategoryProductEdit(false);
  }, []);

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  const [countPage, setCountPage] = useState(1);

  const fetchMoreData = async () => {
    let data = {};
    data.name = 'asc';
    data.page = countPage;
    data.subsidiaryId = filterCategorySubsidiary;
    data.opsiKategori = filterCategoryType;
    if (tableSearch !== '') {
      data['search'] = tableSearch;
    }

    const {
      data: { data: dataFetchCategories },
    } = await fetchCategories(data);

    return dataFetchCategories;
  };

  const nextData = async () => {
    const newCategoryData = await fetchMoreData();
    setTableDatas([...tableDatas, ...newCategoryData.categories]);

    if (
      newCategoryData.categories.length === 0 ||
      newCategoryData.categories.length < 4
    ) {
      setIsHasMorePage(false);
    }
  };

  const handleClickRow = (name, id, subsidiary) => {
    setRoadHirarki({
      subsidiary: subsidiary?.name,
      subsidiaryId: subsidiary?.id,
      category: name,
      categoryId: id,
      subCategory: '',
      subCategoryId: 0,
      product: '',
      productId: 0,
      miniSubCategory: '',
      miniSubCategoryId: 0,
    });
    setTableCurrentPage(1);
    setPage('subCategory');
  };

  const handleClickName = (event, name, id, subs) => {
    event.stopPropagation();
    setRoadHirarki({
      subsidiary: subs?.name,
      subsidiaryId: subs?.id,
      category: name,
      categoryId: id,
      subCategory: '',
      subCategoryId: 0,
      product: '',
      productId: 0,
      miniSubCategory: '',
      miniSubCategoryId: 0,
    });
    setIsCategoryProductDetails(true);
  };

  const handleToAllProduct = () => {
    setPage('product');
  };

  const handleClickSaveFilter = () => {
    let subsidiaryChoose = [];
    // let typeChoose = [];

    subsidiaryFilterValue.map((item, index) => {
      subsidiaryChoose.push(item.id);
    });
    // typeFilterValue.map((item, index) => {
    //   typeChoose.push(item.iDopsi);
    // });
    setFilterCategorySubsidiary(subsidiaryChoose);
    // setFilterCategoryType(typeChoose);
    setIsOpenTableFilter(false);
  };

  const handleClickResetFilter = () => {
    setSubsidiaryFilterValue(subsidiaryDatas);
    setTypeFilterValue(typeCategory);
    setAutoCompleteFilter({
      ...autoCompleteFilter,
      subsidiary: true,
      subsidiaryOpen: false,
      type: true,
      typeOpen: false,
    });
  };

  return (
    <>
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'deletecategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'addcategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      {desktopMode ? (
        <Grid container columns={12} spacing={2}>
          <Grid item xs={10}>
            <SubsidiaryChoose
              value={subsidiaryAll}
              setValue={setSubsidiaryAll}
            />
          </Grid>
          <Grid item xs={2}>
            <SeeProduct {...{ page, setPage }} />
          </Grid>
          <Grid item xs={12}>
            <ProductPerfomance
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <TableCategoryProduct
              {...{ handleClickRow, handleClickName }}
              {...{
                page,
                setPage,
                roadHirarki,
                setRoadHirarki,
                setIsCategoryProductAdd,
                setIsCategoryProductEdit,
                setIsCategoryProductDetails,
                isLoadingData,
              }}
              {...{
                tableRowPerPageValues,
                tableRowPerPage,
                setTableRowPerPage,
                tableCurrentPage,
                setTableCurrentPage,
                tableTotalItems,
                setTableTotalItems,
                tableSearch,
                setTableSearch,
                sortTable,
                setSortTable,
                isSortAsc,
                setIsSortAsc,
                tableDatas,
                setTableDatas,
                tableCategoryFilterDatas,
                setTableCategoryFilterDatas,
              }}
              {...{ isLoadingSubsidiary, subsidiaryDatas }}
              {...{
                typeCategory,
                setTypeCategorys,
                typeCategoryLoading,
                setTypeCategoryLoading,
              }}
              {...{
                filterCategorySubsidiary,
                setFilterCategorySubsidiary,
                filterCategoryType,
                setFilterCategoryType,
              }}
              {...{
                autoCompleteFilter,
                setAutoCompleteFilter,
                isOpenTableFilter,
                setIsOpenTableFilter,
                subsidiaryFilterValue,
                setSubsidiaryFilterValue,
                typeFilterValue,
                setTypeFilterValue,

                handleClickSaveFilter,
                handleClickResetFilter,

                allData,
                allDataType,
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <CategoryProductMobile
          {...{
            handleToAllProduct,
            tableSearch,
            setTableSearch,
            theme,
            setIsCategoryProductAdd,
            tableTotalItems,
            nextData,
            isHasMorePage,
            tableDatas,
            handleClickRow,
            handleClickName,
          }}
          {...{ isLoadingSubsidiary, subsidiaryDatas }}
          {...{
            typeCategory,
            setTypeCategorys,
            typeCategoryLoading,
            setTypeCategoryLoading,
          }}
          {...{
            filterCategorySubsidiary,
            setFilterCategorySubsidiary,
            filterCategoryType,
            setFilterCategoryType,
          }}
          {...{
            autoCompleteFilter,
            setAutoCompleteFilter,
            isOpenTableFilter,
            setIsOpenTableFilter,
            subsidiaryFilterValue,
            setSubsidiaryFilterValue,
            typeFilterValue,
            setTypeFilterValue,

            handleClickSaveFilter,
            handleClickResetFilter,

            allData,
            allDataType,
          }}
        />
      )}
    </>
  );
};
export default CategoryProduct;
