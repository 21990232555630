import {
  Box,
  Card,
  Grid,
  ListItemIcon,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import {
  addNewRack,
  cancelRackTemporary,
  changeCreateMapPage,
  getIdMiniHubEdit,
  getMiniHubEditAll,
  inputRackTemporary,
  saveRackTemporary,
  inputNewRackTemporary,
  saveNewRackTemporary,
  cancelNewRackTemporary,
  deleteCellFix,
  deleteCellNewFix,
  reduceColCreateMapRight,
  addColCreateMapRight,
  changeIsEntrance,
  addRowCreateMapTop,
  reduceRowCreateMapTop,
  addRowCreateMapBottom,
  reduceRowCreateMapBottom,
  addColCreateMapLeft,
  reduceColCreateMapLeft,
  inputEntrance,
} from '../../../../../../../../store/hub/miniHub/miniHubEdit';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import ListRackMiniHub from './ListRack';
import CustomMenu from '../../../../../../../../components/shared/CustomMenu';
import { ReactComponent as EntranceIcon } from '../../../../../../../../assets/entrance.svg';

const SelectRackMiniHub = () => {
  const dispatch = useDispatch();
  const idMiniHub = useSelector(getIdMiniHubEdit);

  const {
    cell,
    isEntrance,
    entrance,
    cellSelected,
    cellSelectedNew,
    cellSelectedDetailsNew,
    cellSelectedDetails,
  } = useSelector(getMiniHubEditAll);

  const [cellSelectedAll, setCellSelectedAll] = useState([]);

  const [openInsert, setOpenInsert] = useState(null);
  const isOpenInsert = Boolean(openInsert);

  const [openRows, setOpenRows] = useState(null);
  const isOpenRows = Boolean(openRows);

  const [openCols, setOpenCols] = useState(null);
  const isOpenCols = Boolean(openCols);

  const idOpenRows = isOpenRows ? 'row-popover' : undefined;
  const idOpenCols = isOpenCols ? 'col-popover' : undefined;

  const [rackMoment, setRackMoment] = useState([]);
  const [rackSelectedMoment, setRackSelectedMoment] = useState([]);
  const [rackSelectedAddMoment, setRackSelectedAddMoment] = useState([]);
  const [rackDetailMoment, setRackDetailMoment] = useState(null);
  const [rackNewDetailMoment, setRackNewDetailMoment] = useState(null);
  const [rackDetailMomentAdd, setRackDetailMomentAdd] = useState({
    name: '',
    row: 5,
    column: 5,
    description: '',
  });

  const [isOnEdit, setIsOnEdit] = useState(false);
  const [isOnAdd, setIsOnAdd] = useState(false);
  const [isOnNewEdit, setIsOnNewEdit] = useState(false);

  const [isHoldingMouse, setIsHoldingMouse] = useState(false);

  useEffect(() => {
    let cellEdit = cellSelected.flat();
    let cellNew = cellSelectedNew.flat();
    let allCell = [...cellEdit, ...cellNew];
    setCellSelectedAll(allCell.filter((cell) => !rackMoment.includes(cell)));
  }, [cellSelected, rackMoment, cellSelectedNew]);

  useEffect(() => {
    if (rackSelectedAddMoment.length !== 0) {
      if (isOnNewEdit) {
        return setIsOnAdd(false);
      } else {
        return setIsOnAdd(true);
      }
    } else {
      return setIsOnAdd(false);
    }
  }, [rackSelectedAddMoment, isOnNewEdit]);

  const handleEditRack = (data) => {
    const { columnPosition } = data;
    let cell = columnPosition.split(',');
    setRackDetailMoment(data);
    dispatch(inputRackTemporary(data));
    setRackSelectedMoment(cell);
    setRackMoment([]);
    setIsOnEdit(true);
  };

  const handleEditRackSave = () => {
    const { name, description } = rackDetailMoment;
    dispatch(
      saveRackTemporary({
        name: name,
        description: description,
        columnPosition: rackSelectedMoment.join(','),
      })
    );
    setRackSelectedMoment([]);
    setRackDetailMoment({});
    setIsOnEdit(false);
  };

  const handleEditRackCancel = () => {
    setRackSelectedMoment([]);
    setRackDetailMoment({});
    dispatch(cancelRackTemporary());
    setIsOnEdit(false);
  };

  const handleAddNewRack = () => {
    dispatch(
      addNewRack({
        columnPosition: rackSelectedAddMoment,
        details: rackDetailMomentAdd,
        id: idMiniHub,
      })
    );

    setRackSelectedAddMoment([]);
    setRackDetailMomentAdd({ name: '', row: 5, column: 5, description: '' });
  };
  const handleEditRackNew = (data) => {
    const { columnPosition, name, row, column, description } = data;
    let cell = columnPosition.split(',');
    dispatch(inputNewRackTemporary(data));
    setRackNewDetailMoment(data);
    setRackSelectedAddMoment(cell);
    setRackMoment([]);
    setIsOnNewEdit(true);
  };

  const handleSaveEditRackNew = () => {
    dispatch(
      saveNewRackTemporary({
        id: idMiniHub,
        columnPosition: rackSelectedAddMoment,
        data: rackNewDetailMoment,
      })
    );
    setRackNewDetailMoment({});
    setRackSelectedAddMoment([]);
    setIsOnNewEdit(false);
  };
  const handleCancelEditRackNew = () => {
    dispatch(cancelNewRackTemporary());
    setRackNewDetailMoment({});
    setRackSelectedAddMoment([]);
    setIsOnNewEdit(false);
  };

  const handleNext = () => {
    dispatch(changeCreateMapPage('preview'));
  };
  return (
    <Box>
      <Box sx={{ position: 'relative', display: 'flex', gap: 10 }}>
        {/* //* select Rack Moment */}
        <Box sx={{ position: 'absolute', right: '4px', zIndex: '+1' }}>
          <Box>
            {isOnNewEdit && (
              <CustomCard
                sx={{
                  p: '16px 12px',
                  width: '238px',
                  mb: '8px',
                  bgcolor: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                {/* //* Rack Name */}
                <Box>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Rack Name
                  </Typography>
                  <CustomInput
                    value={rackNewDetailMoment.name}
                    onChange={(e) =>
                      setRackNewDetailMoment({
                        ...rackNewDetailMoment,
                        name: e.target.value,
                      })
                    }
                    placeholder="Enter Name"
                    sx={{
                      bgcolor: '#FAFAFA',
                      width: '100%',
                      '.MuiOutlinedInput-root': {
                        fontSize: '12px',
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 16px',
                        backgroundColor: '#FAFAFA',
                        borderRadius: '4px',
                        '&::placeholder': {
                          color: '#9E9D9D',
                          fontSize: '0.75rem',
                          fontWeight: 500,
                        },
                      },
                    }}
                  />
                </Box>
                {/* //* Rack C0l */}
                <Box sx={{ mt: '16px' }}>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Number of columns
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CustomInput
                      value={rackNewDetailMoment.column}
                      onChange={(e) =>
                        setRackNewDetailMoment({
                          ...rackNewDetailMoment,
                          column: e.target.value,
                        })
                      }
                      type="number"
                      placeholder="Enter Column"
                      sx={{
                        bgcolor: '#FAFAFA',
                        width: '130px',
                        '.MuiOutlinedInput-root': {
                          fontSize: '12px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 16px',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '4px',
                          '&::placeholder': {
                            color: '#9E9D9D',
                            fontSize: '0.75rem',
                            fontWeight: 500,
                          },
                        },
                      }}
                    />
                    <Box
                      onClick={() => {
                        if (rackNewDetailMoment.column > 1) {
                          setRackNewDetailMoment({
                            ...rackNewDetailMoment,
                            column: rackNewDetailMoment.column - 1,
                          });
                        }
                      }}
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      -
                    </Box>
                    <Box
                      onClick={() => {
                        if (rackNewDetailMoment.column < 50) {
                          setRackNewDetailMoment({
                            ...rackNewDetailMoment,
                            column: rackNewDetailMoment.column + 1,
                          });
                        }
                      }}
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      +
                    </Box>
                  </Box>
                </Box>
                {/* //* Rack Level */}
                <Box sx={{ mt: '16px' }}>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Number of level
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CustomInput
                      value={rackNewDetailMoment.row}
                      onChange={(e) =>
                        setRackNewDetailMoment({
                          ...rackNewDetailMoment,
                          row: e.target.value,
                        })
                      }
                      type="number"
                      placeholder="Enter level"
                      sx={{
                        bgcolor: '#FAFAFA',
                        width: '130px',
                        '.MuiOutlinedInput-root': {
                          fontSize: '12px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 16px',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '4px',
                          '&::placeholder': {
                            color: '#9E9D9D',
                            fontSize: '0.75rem',
                            fontWeight: 500,
                          },
                        },
                      }}
                    />
                    <Box
                      onClick={() => {
                        if (rackNewDetailMoment.row > 1) {
                          setRackNewDetailMoment({
                            ...rackNewDetailMoment,
                            row: rackNewDetailMoment.row - 1,
                          });
                        }
                      }}
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      -
                    </Box>
                    <Box
                      onClick={() => {
                        if (rackNewDetailMoment.row < 50) {
                          setRackNewDetailMoment({
                            ...rackNewDetailMoment,
                            row: rackNewDetailMoment.row + 1,
                          });
                        }
                      }}
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      +
                    </Box>
                  </Box>
                </Box>
                {/* //* Des */}
                <Box sx={{ mt: '16px' }}>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Description
                  </Typography>
                  <CustomInput
                    value={rackNewDetailMoment.description}
                    onChange={(e) =>
                      setRackNewDetailMoment({
                        ...rackNewDetailMoment,
                        description: e.target.value,
                      })
                    }
                    placeholder="Enter Descripition"
                    sx={{
                      bgcolor: '#FAFAFA',
                      width: '100%',
                      '.MuiOutlinedInput-root': {
                        fontSize: '12px',
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 16px',
                        backgroundColor: '#FAFAFA',
                        borderRadius: '4px',
                        '&::placeholder': {
                          color: '#9E9D9D',
                          fontSize: '0.75rem',
                          fontWeight: 500,
                        },
                      },
                    }}
                  />
                </Box>
                <Grid container columns={2} columnSpacing={0.5}>
                  <Grid item xs={1}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      sx={{ width: '100%' }}
                      onClick={handleCancelEditRackNew}
                    >
                      Cancel
                    </CustomButton>
                  </Grid>
                  <Grid item xs={1}>
                    <CustomButton
                      variant="contained"
                      color="primary"
                      sx={{ width: '100%' }}
                      onClick={handleSaveEditRackNew}
                    >
                      Save
                    </CustomButton>
                  </Grid>
                </Grid>
              </CustomCard>
            )}
            {isOnAdd && (
              <CustomCard
                sx={{
                  p: '16px 12px',
                  width: '238px',
                  mb: '8px',
                  bgcolor: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                {/* //* Rack Name */}
                <Box>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Rack Name
                  </Typography>
                  <CustomInput
                    value={rackDetailMomentAdd.name}
                    onChange={(e) =>
                      setRackDetailMomentAdd({
                        ...rackDetailMomentAdd,
                        name: e.target.value,
                      })
                    }
                    placeholder="Enter Name"
                    sx={{
                      bgcolor: '#FAFAFA',
                      width: '100%',
                      '.MuiOutlinedInput-root': {
                        fontSize: '12px',
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 16px',
                        backgroundColor: '#FAFAFA',
                        borderRadius: '4px',
                        '&::placeholder': {
                          color: '#9E9D9D',
                          fontSize: '0.75rem',
                          fontWeight: 500,
                        },
                      },
                    }}
                  />
                </Box>
                {/* //* Rack C0l */}
                <Box sx={{ mt: '16px' }}>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Number of columns
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CustomInput
                      value={rackDetailMomentAdd.column}
                      onChange={(e) =>
                        setRackDetailMomentAdd({
                          ...rackDetailMomentAdd,
                          column: e.target.value,
                        })
                      }
                      type="number"
                      placeholder="Enter Column"
                      sx={{
                        bgcolor: '#FAFAFA',
                        width: '130px',
                        '.MuiOutlinedInput-root': {
                          fontSize: '12px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 16px',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '4px',
                          '&::placeholder': {
                            color: '#9E9D9D',
                            fontSize: '0.75rem',
                            fontWeight: 500,
                          },
                        },
                      }}
                    />
                    <Box
                      onClick={() => {
                        if (rackDetailMomentAdd.column > 1) {
                          setRackDetailMomentAdd({
                            ...rackDetailMomentAdd,
                            column: rackDetailMomentAdd.column - 1,
                          });
                        }
                      }}
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      -
                    </Box>
                    <Box
                      onClick={() => {
                        if (rackDetailMomentAdd.column < 50) {
                          setRackDetailMomentAdd({
                            ...rackDetailMomentAdd,
                            column: rackDetailMomentAdd.column + 1,
                          });
                        }
                      }}
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      +
                    </Box>
                  </Box>
                </Box>
                {/* //* Rack Level */}
                <Box sx={{ mt: '16px' }}>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Number of level
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CustomInput
                      value={rackDetailMomentAdd.row}
                      onChange={(e) =>
                        setRackDetailMomentAdd({
                          ...rackDetailMomentAdd,
                          row: e.target.value,
                        })
                      }
                      type="number"
                      placeholder="Enter level"
                      sx={{
                        bgcolor: '#FAFAFA',
                        width: '130px',
                        '.MuiOutlinedInput-root': {
                          fontSize: '12px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 16px',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '4px',
                          '&::placeholder': {
                            color: '#9E9D9D',
                            fontSize: '0.75rem',
                            fontWeight: 500,
                          },
                        },
                      }}
                    />
                    <Box
                      onClick={() => {
                        if (rackDetailMomentAdd.row > 1) {
                          setRackDetailMomentAdd({
                            ...rackDetailMomentAdd,
                            row: rackDetailMomentAdd.row - 1,
                          });
                        }
                      }}
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      -
                    </Box>
                    <Box
                      onClick={() => {
                        if (rackDetailMomentAdd.row < 50) {
                          setRackDetailMomentAdd({
                            ...rackDetailMomentAdd,
                            row: rackDetailMomentAdd.row + 1,
                          });
                        }
                      }}
                      sx={{
                        bgcolor: '#51B15C1A',
                        color: '#51B15C',
                        height: '38px',
                        width: '38px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      +
                    </Box>
                  </Box>
                </Box>
                {/* //* Des */}
                <Box sx={{ mt: '16px' }}>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Description
                  </Typography>
                  <CustomInput
                    value={rackDetailMomentAdd.description}
                    onChange={(e) =>
                      setRackDetailMomentAdd({
                        ...rackDetailMomentAdd,
                        description: e.target.value,
                      })
                    }
                    placeholder="Enter Descripition"
                    sx={{
                      bgcolor: '#FAFAFA',
                      width: '100%',
                      '.MuiOutlinedInput-root': {
                        fontSize: '12px',
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 16px',
                        backgroundColor: '#FAFAFA',
                        borderRadius: '4px',
                        '&::placeholder': {
                          color: '#9E9D9D',
                          fontSize: '0.75rem',
                          fontWeight: 500,
                        },
                      },
                    }}
                  />
                </Box>
                <Box>
                  <CustomButton
                    variant="contained"
                    color="primary"
                    sx={{ width: '100%' }}
                    onClick={handleAddNewRack}
                  >
                    Enter
                  </CustomButton>
                </Box>
              </CustomCard>
            )}
            {isOnEdit && (
              <CustomCard
                sx={{
                  p: '16px 12px',
                  width: '238px',
                  mb: '8px',
                  bgcolor: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Rack Name
                  </Typography>
                  <CustomInput
                    value={rackDetailMoment.name}
                    onChange={(e) =>
                      setRackDetailMoment({
                        ...rackDetailMoment,
                        name: e.target.value,
                      })
                    }
                    placeholder="Enter name"
                    sx={{
                      bgcolor: '#FAFAFA',
                      width: '100%',
                      '.MuiOutlinedInput-root': {
                        fontSize: '12px',
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 16px',
                        backgroundColor: '#FAFAFA',
                        borderRadius: '4px',
                        '&::placeholder': {
                          color: '#9E9D9D',
                          fontSize: '0.75rem',
                          fontWeight: 500,
                        },
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '12px', mb: '8px' }}>
                    Description
                  </Typography>
                  <CustomInput
                    value={rackDetailMoment.description}
                    onChange={(e) =>
                      setRackDetailMoment({
                        ...rackDetailMoment,
                        description: e.target.value,
                      })
                    }
                    placeholder="Enter description"
                    sx={{
                      bgcolor: '#FAFAFA',
                      width: '100%',
                      '.MuiOutlinedInput-root': {
                        fontSize: '12px',
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 16px',
                        backgroundColor: '#FAFAFA',
                        borderRadius: '4px',
                        '&::placeholder': {
                          color: '#9E9D9D',
                          fontSize: '0.75rem',
                          fontWeight: 500,
                        },
                      },
                    }}
                  />
                </Box>
                <Grid container columns={2} columnSpacing={0.5}>
                  <Grid item xs={1}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      sx={{ width: '100%' }}
                      onClick={handleEditRackCancel}
                    >
                      Cancel
                    </CustomButton>
                  </Grid>
                  <Grid item xs={1}>
                    <CustomButton
                      variant="contained"
                      color="primary"
                      sx={{ width: '100%' }}
                      onClick={handleEditRackSave}
                    >
                      Save
                    </CustomButton>
                  </Grid>
                </Grid>
              </CustomCard>
            )}
          </Box>
          <Box sx={{ mt: '20px' }}>
            {cellSelectedDetailsNew.map((detail, index) => (
              <ListRackMiniHub
                key={index}
                name={detail.name}
                onMouseOver={() => {
                  let value = detail.columnPosition.split(',');
                  setRackMoment(value);
                }}
                onMouseOut={() => setRackMoment([])}
                handleEdit={() => handleEditRackNew(detail)}
                handleDelete={() => {
                  dispatch(deleteCellNewFix(index));
                  setRackMoment([]);
                }}
              />
            ))}
          </Box>
          <Box sx={{ pb: '100px' }}>
            {cellSelectedDetails.map((detail, index) => (
              <ListRackMiniHub
                key={index}
                name={detail.name}
                onMouseOver={() => {
                  let value = detail.columnPosition.split(',');
                  setRackMoment(value);
                }}
                onMouseOut={() => setRackMoment([])}
                handleEdit={() => handleEditRack(detail)}
                handleDelete={() => {
                  dispatch(deleteCellFix({ id: detail.id, index: index }));
                  setRackMoment([]);
                }}
              />
            ))}
          </Box>
        </Box>

        {/* //* Tool */}
        <Box>
          <CustomButton
            sx={{ border: '1px solid #51B15C', color: '#51B15C' }}
            endIcon={<ArrowDropDownRoundedIcon />}
            onClick={(e) => setOpenInsert(e.currentTarget)}
          >
            Edit
          </CustomButton>
          <CustomMenu
            open={isOpenInsert}
            openMenu={openInsert}
            setOpenMenu={setOpenInsert}
            sx={{
              '.MuiMenuItem-root': {
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: '8px',
              },
            }}
          >
            <MenuItem>
              <ListItemIcon
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
                onClick={() => {
                  dispatch(changeIsEntrance());
                }}
              >
                <EntranceIcon fontSize="small" />
                <Typography
                  sx={{ fontSize: '0.75rem', color: 'primary.black' }}
                >
                  Insert entrance
                </Typography>
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              sx={{
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '0.75rem',
              }}
              onClick={(e) => setOpenRows(e.currentTarget)}
              aria-describedby={idOpenRows}
            >
              <Typography sx={{ fontSize: '0.75rem', color: 'primary.black' }}>
                Rows
              </Typography>

              <ArrowRightRoundedIcon sx={{ padding: 0 }} />
            </MenuItem>
            <Popover
              id={idOpenRows}
              open={isOpenRows}
              anchorEl={openRows}
              onClose={() => setOpenRows(null)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box sx={{ py: 0.5, borderRadius: '8px' }}>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => {
                    if (!isOnAdd && !isOnEdit && !isOnNewEdit) {
                      dispatch(addRowCreateMapTop());
                    }
                  }}
                >
                  Insert 1 row above
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => {
                    if (!isOnAdd && !isOnEdit && !isOnNewEdit) {
                      dispatch(reduceRowCreateMapTop());
                    }
                  }}
                >
                  Delete 1 row above
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => {
                    if (!isOnAdd && !isOnEdit && !isOnNewEdit) {
                      dispatch(addRowCreateMapBottom());
                    }
                  }}
                >
                  Insert 1 row below
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => {
                    if (!isOnAdd && !isOnEdit && !isOnNewEdit) {
                      dispatch(reduceRowCreateMapBottom());
                    }
                  }}
                >
                  Delete 1 row below
                </MenuItem>
              </Box>
            </Popover>
            <MenuItem
              sx={{
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '0.75rem',
              }}
              onClick={(e) => setOpenCols(e.currentTarget)}
              aria-describedby={idOpenCols}
            >
              <Typography sx={{ fontSize: '0.75rem', color: 'primary.black' }}>
                Columns
              </Typography>
              <ArrowRightRoundedIcon sx={{ padding: 0 }} />
            </MenuItem>
            <Popover
              id={idOpenCols}
              open={isOpenCols}
              anchorEl={openCols}
              onClose={() => setOpenCols(null)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box sx={{ py: 0.5, borderRadius: '8px' }}>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => {
                    if (!isOnAdd && !isOnEdit && !isOnNewEdit) {
                      dispatch(addColCreateMapLeft());
                    }
                  }}
                >
                  Insert 1 column left
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => {
                    if (!isOnAdd && !isOnEdit && !isOnNewEdit) {
                      dispatch(reduceColCreateMapLeft());
                    }
                  }}
                >
                  Delete 1 column left
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => {
                    if (!isOnAdd && !isOnEdit && !isOnNewEdit) {
                      dispatch(addColCreateMapRight());
                    }
                  }}
                >
                  Insert 1 column right
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => {
                    if (!isOnAdd && !isOnEdit && !isOnNewEdit) {
                      dispatch(reduceColCreateMapRight());
                    }
                  }}
                >
                  Delete 1 column right
                </MenuItem>
              </Box>
            </Popover>
          </CustomMenu>
        </Box>

        {/* //* Box Mapping */}
        <Box
          onMouseDown={() => {
            setIsHoldingMouse(true);
          }}
          onMouseUp={() => {
            setIsHoldingMouse(false);
          }}
        >
          {cell.map((row, rowIndex) => (
            <Box sx={{ display: 'flex' }} key={rowIndex}>
              {row.map((col, colIndex) => (
                <Box
                  onClick={() => {
                    if (isOnEdit) {
                      if (
                        rowIndex === 0 ||
                        rowIndex === cell.length - 1 ||
                        colIndex === 0 ||
                        colIndex === row.length - 1
                      ) {
                      } else {
                        if (!cellSelectedAll.includes(col)) {
                          if (rackSelectedMoment.includes(col)) {
                            setRackSelectedMoment(
                              rackSelectedMoment.filter((v) => v !== col)
                            );
                          } else {
                            setRackSelectedMoment([...rackSelectedMoment, col]);
                          }
                        }
                      }
                    } else {
                      if (
                        rowIndex === 0 ||
                        rowIndex === cell.length - 1 ||
                        colIndex === 0 ||
                        colIndex === row.length - 1
                      ) {
                        dispatch(inputEntrance(col));
                      } else {
                        if (!cellSelectedAll.includes(col)) {
                          if (rackSelectedAddMoment.includes(col)) {
                            setRackSelectedAddMoment(
                              rackSelectedAddMoment.filter((v) => v !== col)
                            );
                          } else {
                            setRackSelectedAddMoment([
                              ...rackSelectedAddMoment,
                              col,
                            ]);
                          }
                        }
                      }
                    }
                    // if (
                    //   rowIndex === 0 ||
                    //   rowIndex === cell.length - 1 ||
                    //   colIndex === 0 ||
                    //   colIndex === row.length - 1
                    // ) {
                    //   dispatch(addEntrance(col));
                    // } else {
                    //   dispatch(addCellSelectedMoment(col));
                    // }
                  }}
                  // onMouseDown={() => {
                  //   setIsHoldingMouse(true);
                  // }}
                  // onMouseOver={() => {
                  //   // if (cellSelectedAll.includes(col)) {
                  //   //   let value = cellSelected.find((item) =>
                  //   //     item.includes(col);
                  //   //   );
                  //   //   setRackMoment(value);
                  //   // }
                  //   setIsHoldingMouse(false);
                  // }}
                  // onMouseOut={() => {}}
                  // onMouseUp={() => {
                  //   setIsHoldingMouse(false);
                  // }}
                  sx={[
                    {
                      width: '36px',
                      height: '36px',
                      border: 1,
                    },
                    isHoldingMouse
                      ? {
                          cursor: 'grabbing',
                        }
                      : {
                          cursor: 'pointer',
                        },
                    // cellSelectedMoment.includes(col) && {
                    //   bgcolor: '#51B15C',
                    // },
                    rackMoment.includes(col) && {
                      bgcolor: '#51B15C',
                      transition: 'width 0.1s',
                      transitionTimingFunction: 'ease-in-out',
                    },
                    rackSelectedMoment.includes(col) && {
                      bgcolor: '#51B15C',
                      transition: 'width 0.1s',
                      transitionTimingFunction: 'ease-in-out',
                    },
                    rackSelectedAddMoment.includes(col) && {
                      bgcolor: '#51B15C',
                      transition: 'width 0.1s',
                      transitionTimingFunction: 'ease-in-out',
                    },
                    cellSelectedAll.includes(col) && {
                      bgcolor: '#9E9D9D',
                    },
                    !cellSelectedAll.includes(col) && {
                      cursor: 'pointer',
                    },
                    // *  active entrance
                    isEntrance &&
                      rowIndex === 0 && {
                        bgcolor: '#F5F5F5',
                        border: 0,
                      },
                    isEntrance &&
                      rowIndex === cell.length - 1 && {
                        bgcolor: '#F5F5F5',
                        border: 0,
                      },
                    isEntrance &&
                      colIndex === 0 && {
                        bgcolor: '#F5F5F5',
                        border: 0,
                      },
                    isEntrance &&
                      colIndex === row.length - 1 && {
                        bgcolor: '#F5F5F5',
                        border: 0,
                      },
                    // *  active entrance and selected
                    isEntrance &&
                      rowIndex === 0 &&
                      entrance.includes(col) && {
                        bgcolor: '#E4E4E4',
                        border: 0,
                      },
                    isEntrance &&
                      rowIndex === cell.length - 1 &&
                      entrance.includes(col) && {
                        bgcolor: '#E4E4E4',
                        border: 0,
                      },
                    isEntrance &&
                      colIndex === 0 &&
                      entrance.includes(col) && {
                        bgcolor: '#E4E4E4',
                        border: 0,
                      },
                    isEntrance &&
                      colIndex === row.length - 1 &&
                      entrance.includes(col) && {
                        bgcolor: '#E4E4E4',
                        border: 0,
                      },
                    // * non active entrance
                    !isEntrance &&
                      rowIndex === 0 && {
                        display: 'none',
                      },
                    !isEntrance &&
                      rowIndex === cell.length - 1 && {
                        display: 'none',
                      },
                    !isEntrance &&
                      colIndex === 0 && {
                        display: 'none',
                      },
                    !isEntrance &&
                      colIndex === row.length - 1 && {
                        display: 'none',
                      },
                  ]}
                  key={colIndex}
                ></Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>

      {/* //! ---------------- SUBMIT ----------------- */}
      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '18px 32px 18px 240px',
          alignItems: 'center',
          gap: 1,
          zIndex: '+2',
        }}
      >
        <CustomButton
          variant="contained"
          sx={{ width: '190px', height: '48px' }}
          onClick={handleNext}
          // disabled={!isAvailableNext}
        >
          Next
        </CustomButton>
      </Box>
    </Box>
  );
};
export default SelectRackMiniHub;
