import { Box, Grid, useMediaQuery } from '@mui/material';
import Subsidiary from '../../../../../components/shared/Subsidiary';
import { useState } from 'react';
import dayjs from 'dayjs';
import CustomCard from '../../../../../components/shared/CustomCard';
import ChartPerfomanceStock from '../Stock/Component/Chart';
import TablePerfomanceStock from '../Stock/Component/Table';
import TableListAudit from './TableListAudit';
import AuditInventoryMobile from './AuditMobile';

const InventoryAudit = () => {
  const matchTablet = useMediaQuery('(min-width:832px)');

  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);

  // ! grafik & table

  const [timeFrame, setTimeFrame] = useState('daily');

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  return (
    <Box sx={{ mt: 2 }}>
      {!matchTablet ? (
        <AuditInventoryMobile />
      ) : (
        <Grid container columns={12} spacing={2}>
          {/* subsidiary */}
          <Grid item xs={12}>
            <Subsidiary
              subsidiaryChecked={filterSubsidiaryArray}
              setSubsidiaryChecked={setFilterSubsidiaryArray}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomCard sx={{ mt: 2, p: 2 }}>
              <Grid container columns={12} spacing={4}>
                {/* grafik */}
                <Grid item xs={6}>
                  <ChartPerfomanceStock
                    {...{
                      timeFrame,
                      setTimeFrame,
                      dateRange,
                      setDateRange,
                      saveDateRange,
                      setSaveDateRange,
                    }}
                  />
                </Grid>

                {/* table */}
                <Grid item xs={6}>
                  <TablePerfomanceStock />
                </Grid>
              </Grid>
            </CustomCard>
          </Grid>

          {/* list data table */}
          <Grid item xs={12}>
            <TableListAudit />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default InventoryAudit;
