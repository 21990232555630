import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import InventoryAudit from '../..';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePageAudit,
  setNotifAudit,
} from '../../../../../../../store/Routing/auditRouting';
import { Fragment, useState } from 'react';
import { useEffect } from 'react';
import {
  changeHubs,
  changeIdAddAudit,
  changeStartAuditing,
  getAllMasterHubAddAudit,
  getAllMiniHubAddAudit,
  getAllSubHubAddAudit,
  getDataHubAddAudit,
  getDataMiniHubAddAudit,
  getDataScanned,
  getDataSubHubAddAudit,
  getHubsAddAudit,
  getIdAddAudit,
  getStartAuditing,
} from '../../../../../../../store/audit/addAudit';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import {
  EditPen,
  QrCodeScannerRoundedIcon,
} from '../../../../../../../assets/Icons';
import CustomAntSwitch from '../../../../../../../components/shared/CustomAntSwitch';
import { auditCompare, createAudits } from '../../../../../../../api/audit';
import { changeIdDetailAudit } from '../../../../../../../store/audit/detailAudit';

const AddAudit = () => {
  const matchTablet = useMediaQuery('(min-width:832px');
  const dispatch = useDispatch();
  const hubs = useSelector(getHubsAddAudit);
  const idAudit = useSelector(getIdAddAudit);
  const allDataHubs = useSelector(getDataHubAddAudit);
  const allDataSubHubs = useSelector(getDataSubHubAddAudit);
  const allDataMiniHubs = useSelector(getDataMiniHubAddAudit);
  const [mixData, setMixData] = useState([]);
  const startAuditing = useSelector(getStartAuditing);
  const [isDisableAuditing, setIsDisableAuditing] = useState(false);
  const [notifHub, setNotifHub] = useState('');
  const [isDisableCompare, setIsDisableCompare] = useState(false);
  const [isDisableScan, setIsDisableScan] = useState(false);
  const [isDisableHub, setIsDisableHub] = useState(false);
  const [isLoadingAddAudit, setIsLoadingAddAudit] = useState(false);
  const dataScanned = useSelector(getDataScanned);
  const [isLoadingCompare, setIsLoadingCompare] = useState(false);

  useEffect(() => {
    dispatch(getAllMasterHubAddAudit({ pageSize: 1000 }));
    dispatch(getAllSubHubAddAudit({ pageSize: 1000 }));
    dispatch(getAllMiniHubAddAudit({ pageSize: 1000 }));
  }, []);

  // ! mix data
  useEffect(() => {
    const mixs = [].concat(allDataHubs, allDataSubHubs, allDataMiniHubs);
    setMixData(mixs);
  }, [allDataHubs, allDataSubHubs, allDataMiniHubs]);

  // ! validated

  // hub
  useEffect(() => {
    if (idAudit !== null) {
      setIsDisableHub(true);
    }
  }, [idAudit]);

  // auditing
  useEffect(() => {
    if (hubs === null) {
      setIsDisableAuditing(true);
    } else {
      setIsDisableAuditing(false);
    }
  }, [hubs]);

  // compare
  useEffect(() => {
    if (dataScanned?.length !== 0) {
      setIsDisableCompare(false);
    } else {
      setIsDisableCompare(true);
    }
  }, [dataScanned]);

  useEffect(() => {
    if (startAuditing === false) {
      setIsDisableScan(true);
    } else {
      setIsDisableScan(false);
    }
  }, [hubs, startAuditing]);

  // ! ------------- Handler ----------------------------
  const isOptionEqualToValue = (option, value) => {
    return option.id === value?.id;
  };
  const handleToScan = () => {
    if (isLoadingAddAudit !== true) {
      dispatch(changePageAudit('scanQrAudit'));
    }
  };

  const handleStatus = () => {
    setIsLoadingAddAudit(true);
    if (idAudit === null) {
      const createAudit = async () => {
        const params = {};
        params.table = hubs?.typeHub;
        params.id_toko = hubs?.typeHub === 'hub' ? hubs?.id : null;
        params.sub_hub_id = hubs?.typeHub === 'sub_hub' ? hubs?.id : null;
        params.mini_hub_id = hubs?.typeHub === 'mini_hub' ? hubs?.id : null;
        params.description = hubs?.label;
        try {
          const addAudit = await createAudits(params);
          if (
            addAudit?.data.meta.code === 20000 &&
            addAudit?.data.meta.success === true
          ) {
            dispatch(changeIdAddAudit(addAudit?.data.data.id));
          }
          setIsLoadingAddAudit(false);
        } catch (err) {
          console.error(err);
        } finally {
          setIsLoadingAddAudit(false);
        }
      };
      createAudit();
      dispatch(changeStartAuditing(!startAuditing));
    } else {
      dispatch(changeStartAuditing(!startAuditing));
      setIsLoadingAddAudit(false);
    }
  };

  const handleToCompare = () => {
    setIsLoadingCompare(true);
    const compare = async () => {
      try {
        const data = await auditCompare(idAudit);
        setNotifAudit({
          key: 'compareAudit',
          text: 'compare audit succes',
        });
        dispatch(changeIdDetailAudit(idAudit));
        dispatch(changePageAudit('detailsAudits'));
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingCompare(false);
      }
    };

    compare();
  };

  return (
    <>
      {!matchTablet ? (
        <Box
          sx={{
            mt: 2,
            minHeight: '80vh',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            {/* head */}
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <BackButtonHub onClick={() => dispatch(changePageAudit(''))} />
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#000' }}
              >
                Add Audit
              </Typography>
            </Box>

            {/* form */}
            <Grid container columns={12} spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ mt: 2 }}>
                  <Autocomplete
                    disabled={isDisableHub}
                    sx={{ width: '100%' }}
                    options={mixData}
                    value={hubs}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={isOptionEqualToValue}
                    onChange={(_, newValue) => {
                      dispatch(changeHubs(newValue));
                      dispatch(changeStartAuditing(false));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Hub"
                        sx={{
                          bgcolor: '#fafafa',
                          borderRadius: '5px',
                          fontSize: '14px',
                          color: '#9D9D9D',
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    Start auditing
                  </Typography>
                  {!isDisableAuditing ? (
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          borderRadius: '8px',
                        }}
                        control={
                          <CustomAntSwitch
                            checked={startAuditing}
                            // value={startAuditing}
                            onChange={handleStatus}
                            color="secondary"
                          />
                        }
                      />
                    </FormGroup>
                  ) : (
                    <CustomAntSwitch isDisable={true} color="secondary" />
                  )}
                </Box>
              </Grid>

              {dataScanned?.length !== 0 && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      background: '#FAFAFA',
                      borderRadius: '12px',
                      padding: '16px',
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#000',
                      }}
                    >
                      Products
                    </Typography>
                    {dataScanned?.map((item, index) => (
                      <Box key={index}>
                        <Grid
                          container
                          columns={12}
                          spacing={2}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'space-between',
                            mt: 1,
                          }}
                        >
                          <Grid item xs="auto">
                            <Box>
                              <Box
                                component="img"
                                src={
                                  process.env.REACT_APP_API_IMAGE_BASE_URL + '/'
                                }
                                sx={{
                                  width: '45px',
                                  height: '45px',
                                  borderRadius: '12px',
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={7}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 400,
                                  color: '#000',
                                }}
                              >
                                nama
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 600,
                                  color: '#000',
                                }}
                              >
                                {item?.value} {item?.unit}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs="auto">
                            <Box
                              sx={{
                                width: '22px',
                                height: '22px',
                                background: 'rgba(253, 121, 0, 0.1)',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <EditPen />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              width: '100%',
                              borderBottom: '1.5px solid #F1F1F1',
                            }}
                          ></Box>
                        </Grid>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>

          {/* button event */}
          <Box
            sx={{
              display: 'flex',
              gap: 1.5,
              alignItems: 'center',
            }}
          >
            <CustomButton
              disabled={isDisableCompare}
              variant="contained"
              onClick={handleToCompare}
              sx={{ width: '100%', height: '40px' }}
            >
              {isLoadingCompare ? (
                <CircularProgress size={30} />
              ) : (
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 600, color: '#FAFAFA' }}
                >
                  Compare
                </Typography>
              )}
            </CustomButton>
            <CustomButton
              variant="outlined"
              disabled={isDisableScan}
              onClick={handleToScan}
              sx={{
                height: '40px',
                borderColor: isDisableScan ? '#C8C8CC' : '#51B15C',
              }}
            >
              {isLoadingAddAudit ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress size={25} />
                </Box>
              ) : (
                <QrCodeScannerRoundedIcon
                  sx={{
                    color: isDisableScan ? '#C8C8CC' : '#51B15C',
                    borderColor: isDisableScan ? '#C8C8CC' : '#51B15C',
                  }}
                />
              )}
            </CustomButton>
          </Box>
        </Box>
      ) : (
        <InventoryAudit />
      )}
    </>
  );
};
export default AddAudit;
