import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDataDetailAdjustment,
  getDetailDataAdjustment,
  getIdDetailAdjustment,
  getIsLoadingAdjustment,
} from '../../../../../../store/adjustment/detailAdjustment';
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import {
  changePageAdjustment,
  setNotifAdjusment,
} from '../../../../../../store/Routing/addjustment';
import CustomCard from '../../../../../../components/shared/CustomCard';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {
  changeEditNotesAdjustment,
  changeEditTypeAdjustment,
  changeEditlocationAdjustment,
  changeIdAdjustment,
  changeIsEditAdjustment,
  getIsEditAdjustment,
  goToAdjustmentEdit,
  goToEditAdjustment,
} from '../../../../../../store/adjustment/editAdjustment';
import DetailAdjustmentMobile from '../AdjustmentMobile/Detail/DetailAdjustmentMobile';
import EditDiffereceAdjustment from '../Edit/EditDifferenceAdjustment';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import { CloseIcon, DeleteIcon } from '../../../../../../assets/Icons';
import {
  acceptAdjustment,
  cancelAdjustment,
  deleteAdjustment,
} from '../../../../../../api/adjustment';
import EditMissingQrAdjustment from '../Edit/EditMissingQrAdjustment';
import QRCode from 'react-qr-code';

const DetailAdjustment = () => {
  const matchTablet = useMediaQuery('(min-width:832px)');
  const dispatch = useDispatch();
  const role = localStorage.role;

  const idAdjustment = useSelector(getIdDetailAdjustment);
  const isLoading = useSelector(getIsLoadingAdjustment);
  const datas = useSelector(getDetailDataAdjustment);
  const isEdit = useSelector(getIsEditAdjustment);

  // validation
  const [isFilterCancel, setIsFilterCancel] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isOpenAccept, setIsOpenAccept] = useState(false);
  const [isLoadingAccepted, setIsLoadingAccepted] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  useEffect(() => {
    dispatch(fetchDataDetailAdjustment(idAdjustment));
  }, [idAdjustment]);

  // ! ------------------------ Handler -----------------------------
  const handleAccept = async () => {
    setIsLoadingAccepted(true);
    try {
      await acceptAdjustment(idAdjustment);
      setIsLoadingAccepted(false);
      setIsOpenAccept(false);
      dispatch(changePageAdjustment(''));
      dispatch(
        setNotifAdjusment({
          key: 'editAdjustment',
          text: 'Adjustment has been accepted',
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingAccepted(false);
    }
  };

  const handleCancel = async () => {
    setIsLoadingCancel(true);
    try {
      await cancelAdjustment(idAdjustment);
      setIsLoadingCancel(false);
      setIsFilterCancel(false);
      dispatch(changePageAdjustment(''));
      dispatch(
        setNotifAdjusment({
          key: 'editAdjustment',
          text: 'Adjustment has been canceled',
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingCancel(false);
    }
  };

  const handleToEdit = () => {
    dispatch(changeIsEditAdjustment(true));
    dispatch(changeIdAdjustment(idAdjustment));
    dispatch(goToAdjustmentEdit(datas));
    dispatch(goToEditAdjustment(datas?.detail));
    dispatch(goToAdjustmentEdit(datas));
    dispatch(changeEditlocationAdjustment(datas?.location));
    dispatch(changeEditNotesAdjustment(datas?.notes));
    dispatch(changeEditTypeAdjustment(datas?.type));
  };

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    try {
      await deleteAdjustment(idAdjustment);
      setIsLoadingDelete(false);
      setIsDelete(false);
      dispatch(changePageAdjustment(''));
      dispatch(changeIsEditAdjustment(false));
      dispatch(
        setNotifAdjusment({
          key: 'deleteAdjustment',
          text: 'Adjustment has been deleted',
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return !matchTablet ? (
    <DetailAdjustmentMobile />
  ) : (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        position: 'relative',
        minHeight: '80vh',
      }}
    >
      {!isEdit ? (
        isLoading ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '56px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}
            >
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <BackButtonHub
                  onClick={() => dispatch(changePageAdjustment(''))}
                />
                <Box>
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '24px',
                      fontWeight: 600,
                      cursor: 'pointer',
                    }}
                  >
                    {idAdjustment} Details
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  minWidth: '99px',
                  height: '36px',
                  borderRadius: '5px',
                  background: 'rgba(253, 121, 0, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '6px 16px',
                }}
              >
                <Typography
                  sx={{ color: '#FD7900', fontSize: '16px', fontWeight: 600 }}
                >
                  {datas?.status}
                </Typography>
              </Box>
            </Box>
            <Grid container columns={12} spacing={3} sx={{ marginTop: '0px' }}>
              <Grid item xs={6}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: '#000',
                      fontWeight: 500,
                      marginBottom: '12px',
                    }}
                  >
                    Location
                  </Typography>
                  <Box
                    sx={{
                      background: '#FAFAFA',
                      borderRadius: '8px 8px 0px 0px',
                      padding: '16px 12px 14px 14px',
                      height: '54px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                      {datas?.location || '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: '#000',
                      marginBottom: '12px',
                    }}
                  >
                    Condition
                  </Typography>
                  <Box
                    sx={{
                      background: '#FAFAFA',
                      borderRadius: '8px 8px 0px 0px',
                      padding: '16px 12px 14px 14px',
                      height: '54px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                      {datas?.type || '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      marginBottom: '12px',
                      color: '#000',
                    }}
                  >
                    Notes
                  </Typography>
                  <Box
                    sx={{
                      background: '#FAFAFA',
                      borderRadius: '8px 8px 0px 0px',
                      padding: '16px 12px 14px 14px',
                      height: '54px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                      {datas?.notes || '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* items */}
              <Grid item xs={12}>
                <CustomCard>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: '20px',
                      boxShadow: '0px 1px 5px #ccc',
                    }}
                  >
                    <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                      Item(s)
                    </Typography>
                  </Box>

                  <Box>
                    <Grid
                      container
                      columns={12}
                      spacing={4}
                      sx={{ p: '30px 20px' }}
                    >
                      {datas?.detail.map((item, index) => (
                        <Grid item xs={12} sx={{ display: 'flex' }} key={index}>
                          {datas?.type === 'difference' ? (
                            <Grid container columns={12} spacing={3}>
                              {/* product */}
                              <Grid item xs={3}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                    }}
                                  >
                                    Product
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                      height: '54px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.namaProduk || '-'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              {/* system */}
                              <Grid item xs={3}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                    }}
                                  >
                                    System
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                      height: '54px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.system} {item?.systemUnit}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              {/* actual */}
                              <Grid item xs={3}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                    }}
                                  >
                                    Actual
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                      height: '54px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.actual || 0}{' '}
                                      {item?.actualUnit || 'unit'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              {/* different */}
                              <Grid item xs={3}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                    }}
                                  >
                                    Difference
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                      height: '54px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.difference || 0}{' '}
                                      {item?.systemUnit || 'unit'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container columns={12} spacing={3}>
                              {/* product */}
                              <Grid item xs={6}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                      color: '#000',
                                    }}
                                  >
                                    Product
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                      height: '54px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.namaProduk || '-'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              {/* Qr code */}
                              <Grid item xs={1}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                      color: '#000',
                                    }}
                                  >
                                    QrCode
                                  </Typography>
                                  <QRCode
                                    size={54}
                                    style={{
                                      height: '54px',
                                      maxWidth: '54px',
                                      width: '100%',
                                      borderRadius: '4px',
                                    }}
                                    value={item?.encript}
                                    viewBox={`0 0 54 54`}
                                  />
                                </Box>
                              </Grid>
                              {/* rack */}
                              <Grid item xs={2.5}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                      color: '#000',
                                    }}
                                  >
                                    Rack
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                      height: '54px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      Rek - {item?.hubRackItemId}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              {/* missing */}
                              <Grid item xs={2.5}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                      color: '#000',
                                    }}
                                  >
                                    Missing
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                      height: '54px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.system || 0}{' '}
                                      {item?.systemUnit || 'unit'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </CustomCard>
              </Grid>
            </Grid>

            {/* modal/ popup cancel adjustment */}
            <CustomDialog
              isOpen={isFilterCancel}
              setIsOpen={setIsFilterCancel}
              fullWidth
              PaperProps={{
                sx: {
                  borderRadius: '12px',
                },
              }}
            >
              <DialogTitle>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      background: 'rgba(243, 58, 58, 0.1)',
                      borderRadius: '100px',
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '1.25rem', color: '#F33A3A' }} />
                  </Box>
                  <Typography
                    sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}
                  >
                    Cancel Adjustment
                  </Typography>
                </Box>
              </DialogTitle>

              <DialogContent>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#000',
                    mt: 2,
                  }}
                >
                  Are you sure you want to cancel the adjustment ?
                </Typography>

                {/* button */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gap: 3,
                    marginTop: '56px',
                  }}
                >
                  <Typography
                    onClick={() => setIsFilterCancel(false)}
                    sx={{ fontSize: '14px', fontWeight: 600, color: '#9D9D9D' }}
                  >
                    Back
                  </Typography>
                  <CustomButton
                    variant="contained"
                    color={'error'}
                    sx={{ width: '100px', height: '37px' }}
                    onClick={handleCancel}
                  >
                    {isLoadingCancel ? (
                      <CircularProgress size={20} style={{ color: '#FFF' }} />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#FFF',
                        }}
                      >
                        Cancel
                      </Typography>
                    )}
                  </CustomButton>
                </Box>
              </DialogContent>
            </CustomDialog>

            {/* modal/ popup accept adjustment */}
            <CustomDialog
              isOpen={isOpenAccept}
              setIsOpen={setIsOpenAccept}
              fullWidth
              PaperProps={{
                sx: {
                  borderRadius: '12px',
                },
              }}
            >
              <DialogTitle>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      background: 'rgba(243, 58, 58, 0.1)',
                      borderRadius: '100px',
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '1.25rem', color: '#F33A3A' }} />
                  </Box>
                  <Typography
                    sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}
                  >
                    Accept Adjustment
                  </Typography>
                </Box>
              </DialogTitle>

              <DialogContent>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#000',
                    mt: 2,
                  }}
                >
                  Are you sure you want to accept the adjustment ?
                </Typography>

                {/* button */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gap: 3,
                    marginTop: '56px',
                  }}
                >
                  <Typography
                    onClick={() => setIsOpenAccept(false)}
                    sx={{ fontSize: '14px', fontWeight: 600, color: '#9D9D9D' }}
                  >
                    Back
                  </Typography>
                  <CustomButton
                    variant="contained"
                    sx={{ width: '100px', height: '37px' }}
                    onClick={handleAccept}
                  >
                    {isLoadingAccepted ? (
                      <CircularProgress size={20} style={{ color: '#FFF' }} />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#FFF',
                        }}
                      >
                        Accept
                      </Typography>
                    )}
                  </CustomButton>
                </Box>
              </DialogContent>
            </CustomDialog>
            {/* modal / popup delete adjustment */}
            <CustomDialog
              isOpen={isDelete}
              setIsOpen={setIsDelete}
              fullWidth
              PaperProps={{
                sx: {
                  borderRadius: '12px',
                },
              }}
            >
              <DialogTitle>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      background: 'rgba(243, 58, 58, 0.1)',
                      borderRadius: '100px',
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <DeleteIcon
                      sx={{ fontSize: '1.25rem', color: '#F33A3A' }}
                    />
                  </Box>
                  <Typography
                    sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}
                  >
                    Delete Adjustment
                  </Typography>
                </Box>
              </DialogTitle>

              <DialogContent>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#000',
                    mt: 2,
                  }}
                >
                  Are you sure you want to delete {idAdjustment} ?
                </Typography>

                {/* button */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gap: 3,
                    marginTop: '56px',
                  }}
                >
                  <Typography
                    onClick={() => setIsDelete(false)}
                    sx={{ fontSize: '14px', fontWeight: 600, color: '#9D9D9D' }}
                  >
                    Back
                  </Typography>
                  <CustomButton
                    variant="contained"
                    color={'error'}
                    sx={{ width: '100px', height: '37px' }}
                    onClick={handleDelete}
                  >
                    {isLoadingDelete ? (
                      <CircularProgress size={20} style={{ color: '#FFF' }} />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#FFF',
                        }}
                      >
                        Delete
                      </Typography>
                    )}
                  </CustomButton>
                </Box>
              </DialogContent>
            </CustomDialog>

            {/* button event */}

            {role == 2 || role === 3 ? (
              datas?.status === 'pending' && (
                <Box
                  sx={{
                    width: '100%',
                    bgcolor: 'white',
                    position: 'fixed',
                    bottom: '0px',
                    right: '0px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '18px 32px 18px 240px',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CustomButton
                      variant="contained"
                      color={'error'}
                      sx={{ width: '190px', height: '48px' }}
                      onClick={() => setIsDelete(true)}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#FFF',
                        }}
                      >
                        Delete
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      sx={{ width: '190px', height: '48px' }}
                      onClick={() => handleToEdit()}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: '16px',
                          fontWeight: 600,
                        }}
                      >
                        Edit
                      </Typography>
                    </CustomButton>
                  </Box>
                </Box>
              )
            ) : role == 4 ? (
              datas?.status === 'pending' && (
                <Box
                  sx={{
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'end',
                    gap: 2,
                  }}
                >
                  <CustomButton
                    variant="contained"
                    color={'error'}
                    sx={{ width: '190px', height: '48px' }}
                    onClick={() => setIsFilterCancel(true)}
                  >
                    <Typography
                      sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
                    >
                      Cancel
                    </Typography>
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    sx={{ width: '190px', height: '48px' }}
                    onClick={() => setIsOpenAccept(true)}
                  >
                    <Typography
                      sx={{ fontSize: '14px', fontWeight: 600, color: '#FFF' }}
                    >
                      Accept
                    </Typography>
                  </CustomButton>
                </Box>
              )
            ) : (
              <></>
            )}
          </Box>
        )
      ) : datas?.type === 'difference' ? (
        <EditDiffereceAdjustment />
      ) : (
        <EditMissingQrAdjustment />
      )}
    </Box>
  );
};
export default DetailAdjustment;
