import { createSlice } from '@reduxjs/toolkit';
import { getAllQrCode } from '../../../api/qrcode';

export const PurchaseFinishScanAdminState = createSlice({
  name: 'purchaseFinishAdmin',
  initialState: {
    rackId: null,
    dataRackDetail: null,
    scanned: [],
    idScanned: [],
    isExist: false,
    isLoading: false,
    totalData: 0,
    allData: [],
    detail: {},
  },
  reducers: {
    changeScanRackIdAdmin: (state, action) => {
      state.rackId = action.payload;
    },
    scanFinishAdmin: (state, action) => {
      const data = action.payload;
      if (!state.idScanned.includes(data.encript)) {
        state.idScanned = [...state.idScanned, data.encript];
        state.scanned = [...state.scanned, data];
        state.isExist = false;
      } else {
        state.isExist = true;
      }
    },
    changeLoadingPurchaseScan: (state, action) => {
      state.isLoading = action.payload;
    },
    setTotalDataPurchaseScan: (state, action) => {
      state.totalData = action.payload;
    },
    setAllDataPurchaseScan: (state, action) => {
      state.allData = action.payload;
    },
    setDetailPurchase: (state, action) => {
      state.detail = action.payload;
    },
    loadSpesifikDataQrRack: (state, action) => {
      state.dataRackDetail = action.payload;
    },
  },
});

export const {
  changeScanRackIdAdmin,
  scanFinishAdmin,
  changeLoadingPurchaseScan,
  setTotalDataPurchaseScan,
  setAllDataPurchaseScan,
  setDetailPurchase,
  loadSpesifikDataQrRack,
} = PurchaseFinishScanAdminState.actions;

export const getRackIdFinishScanAdmin = (state) =>
  state.purchaseFinishAdmin.rackId;

export const getAllAdminFinishScan = (state) => state.purchaseFinishAdmin;

export const fetchAllDataPurchaseFinishScanAdmin = (id) => (dispatch) => {
  const totalData = 100;
  const fetchTotalApi = async () => {
    dispatch(changeLoadingPurchaseScan(true));
    const params = {
      page: 1,
      pageSize: totalData,
    };
    try {
      const {
        data: {
          data: { purchases },
        },
      } = await getAllQrCode(params, id);
      dispatch(setAllDataPurchaseScan(purchases));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  const fetchApi = async () => {
    dispatch(changeLoadingPurchaseScan(true));
    const params = {
      page: 1,
    };
    try {
      const {
        data: {
          data: {
            pageInfo: { total },
          },
        },
      } = await getAllQrCode(params, id);
      totalData = total;
    } catch (err) {
      console.error(err);
    } finally {
      fetchTotalApi();
    }
  };

  fetchApi();
};

export default PurchaseFinishScanAdminState.reducer;
