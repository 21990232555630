import { kanpaiAlereAxios } from "../utils/api";

export const kanpaiRevenueSummary = ({ startDate, endDate }) =>
  kanpaiAlereAxios.get("admin/invoice/sales-total", {
    params: { startDate, endDate },
  });

export const kanpaiSalesTotal = ({ startDate, endDate, timeframe }) =>
  kanpaiAlereAxios.get("sales", {
    params: { startDate, endDate, timeframe },
  });

export const kanpaiOrderCount = ({ startDate, endDate, timeframe }) =>
  kanpaiAlereAxios.get("sales/orders", {
    params: { startDate, endDate, timeframe },
  });

export const kanpaiCustomerStatistic = ({ startDate, endDate }) =>
  kanpaiAlereAxios.get("admin/invoice/customer-static", {
    params: { startDate, endDate },
  });

export const kanpaiStaffPerformance = ({ startDate, endDate }) =>
  kanpaiAlereAxios.get("admin/invoice/rating-static", {
    params: { startDate, endDate },
  });

export const kanpaiTableSummary = () =>
  kanpaiAlereAxios.get("admin/invoice/amont-table");

export const kanpaiPopularMenu = ({ startDate, endDate, category }) =>
  kanpaiAlereAxios.get("admin/invoice/most-item", {
    params: { startDate, endDate, category, pageSize: 5 },
  });

export const kanpaiLeastPopularMenu = ({ startDate, endDate, category }) =>
  kanpaiAlereAxios.get("admin/invoice/minimum-item", {
    params: { startDate, endDate, category, pageSize: 5 },
  });

export const kanpaiOrderStatus = ({ startDate, endDate }) =>
  kanpaiAlereAxios.get("admin/invoice/report", {
    params: { startDate, endDate },
  });

export const kanpaiRecentOrders = ({ page, pageSize, category }) =>
  kanpaiAlereAxios.get("sales/recent-orders", {
    params: { page, pageSize, category },
  });

export const kanpaiDineIn = ({ startDate, endDate }) =>
  kanpaiAlereAxios.get("admin/invoice/dinein-static", {
    params: { startDate, endDate },
  });

export const kanpaiTakeaway = ({ startDate, endDate }) =>
  kanpaiAlereAxios.get("admin/invoice/takeaway-static", {
    params: { startDate, endDate },
  });

export const kanpaiOperationalHours = ({ startDate, endDate }) =>
  kanpaiAlereAxios.get("admin/invoice/operation-static", {
    params: { startDate, endDate },
  });

export const kanpaiOutletPerformances = ({
  page,
  pageSize,
  startDate,
  endDate,
  orderBy,
  direction,
}) =>
  kanpaiAlereAxios.get("admin/invoice/outlet-performence", {
    params: { page, pageSize, startDate, endDate, orderBy, direction },
  });

export const kanpaiTopCustomers = ({
  startDate,
  endDate,
  page = 1,
  pageSize,
  orderBy,
  direction,
}) =>
  kanpaiAlereAxios.get("admin/invoice/top-customer", {
    params: { startDate, endDate, page, pageSize, orderBy, direction },
  });

export const kanpaiCustomerDetails = ({ customerId, startDate, endDate }) =>
  kanpaiAlereAxios.get(`admin/invoice/${customerId}/detail-customer-buy`, {
    params: { startDate, endDate },
  });

export const kanpaiCustomerAddClick = ({ customerId }) =>
  kanpaiAlereAxios.post(`admin/invoice/${customerId}/amount-click`, {
    headers: { "content-type": "application/json" },
  });

export const kanpaiTableDetails = ({ tableId }) =>
  kanpaiAlereAxios.get(`admin/invoice/${tableId}/customer-table`);

export const kanpaiOrderDetails = ({ startDate, endDate, timeframe }) =>
  kanpaiAlereAxios.get(`admin/invoice/detail-order`, {
    params: { startDate, endDate, timeframe },
  });

export const kanpaiOrderPredictions = ({
  startDate,
  endDate,
  timeframe,
  category,
}) =>
  kanpaiAlereAxios.get(`admin/invoice/forecast`, {
    params: { startDate, endDate, timeframe, category },
  });

export const kanpaiTableHistories = ({ tableId, date }) =>
  kanpaiAlereAxios.get(`admin/invoice/${tableId}/history-table`, {
    params: { date },
  });

export const kanpaiKitchenProcessingTime = ({ startDate, endDate }) =>
  kanpaiAlereAxios.get("admin/invoice/kitchen-processing", {
    params: { startDate, endDate },
  });

export const kanpaiTopSoldPerformance = ({
  startDate,
  endDate,
  timeframe,
  category,
}) =>
  kanpaiAlereAxios.get("admin/invoice/menu-performance", {
    params: { startDate, endDate, timeframe, category },
  });

export const kanpaiTablePerformance = ({ startDate, endDate }) =>
  kanpaiAlereAxios.get("admin/invoice/table-performance", {
    params: { startDate, endDate },
  });

export const kanpaiCustomerRatings = ({ ...params }) =>
  kanpaiAlereAxios.get("admin/invoice/daily-rating", { params: { ...params } });
