import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../../../../../../../components/shared/BackButton';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import {
  changeIsCreateMap,
  changeIsEdit,
  changeName,
  changePhotoPreview,
  changePicChoose,
  changePicNumber,
  changePicSearch,
  changePicSearchDebounce,
  changeVideoPreview,
  clickIsPreviewPhoto,
  clickIsPreviewVideo,
  deleteImage,
  deleteTayangan,
  getIsMasterHubEdit,
  getMasterHubEditAll,
  inputImage,
  inputTayangan,
  changeStatus,
  saveEditMasterHub,
  changeSubsidiaryId,
  changeIsLoadingUpdate,
  clearData,
} from '../../../../../../../../store/hub/masterHub/masterHubEdit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import {
  fetchPic,
  getPicList,
  getPicLoading,
} from '../../../../../../../../store/pic/pic';
import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import CreateMapMasterHubEdit from './CreateMapMasterHubEdit';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomAntSwitch from '../../../../../../../../components/shared/CustomAntSwitch';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import CustomEditConfirm from '../../../../../../../../components/shared/CustomEditConfirm';
import { getIdMasterHub } from '../../../../../../../../store/hub/hirarkiHub';
import { putNotif } from '../../../../../../../../store/hub/masterHub/masterHubDetail';
import CloseIcon from '@mui/icons-material/Close';
import SetupAddress from './AddressMap/SetupAddress';
import { Map } from 'pigeon-maps';
import HubFamily from '../../../HubFamily';
import { inputNotifHub } from '../../../../../../../../store/hub/notifHub';
import {
  addMasterHubRacksMultiple,
  deleteMasterHubRacksMultiple,
  editMasterHub,
  editMasterHubRacksMultiple,
} from '../../../../../../../../api/hubs';

const MasterHubEdit = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const picList = useSelector(getPicList);
  const picLoading = useSelector(getPicLoading);

  const {
    isEdit,
    subsidiaryId,
    name,
    photo,
    isMaxSizePhoto,
    isPhotoPreview,
    photoPreview: linkPhotoPreview,
    video,
    isMaxSizeVideo,
    isVideoPreview,
    videoPreview: linkVideoPreview,
    picChoose,
    picSearch,
    picSearchDebounce,
    picNumber,
    col,
    row,
    entrance,
    isCreateMap,
    status,
    cellSelectedDetails,
    cellSelectedDetailsNew,
    rackDeletes,
    isLoadingUpdate,
    coordinate,
    address,
  } = useSelector(getMasterHubEditAll);

  const idMasterHub = useSelector(getIdMasterHub);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounce(value));
    }, 1000)
  ).current;

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  const handleBackButton = () => {
    dispatch(changeIsEdit(false));
  };

  const [isEditConfirm, setIsEditConfirm] = useState(false);
  const handleSave = () => {
    setIsEditConfirm(!isEditConfirm);
  };

  const [isLoadingOnUpdate, setIsLoadingOnUpdate] = useState(false);
  const handleSaveFix = () => {
    setIsLoadingOnUpdate(true);
    const { idKustomer } = picChoose;
    const upadeteHub = {
      subsidiaryId: subsidiaryId,
      namaToko: name,
      idKustomer: idKustomer,
      gambar: photo.length !== 0 ? photo.join(',') : '',
      videos: video.length !== 0 ? video.join(',') : '',
      mapColumn: col,
      mapRow: row,
      nohp: picNumber,
      entrancePosition: entrance.length !== 0 ? entrance.join(',') : '',
    };
    const deleteRackData = {
      hubRackId: rackDeletes,
    };
    const updateRackData = cellSelectedDetails.map((item) => {
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        columnPosition: item.columnPosition,
      };
    });
    const addRackData = cellSelectedDetailsNew.map((item) => {
      return {
        idToko: idMasterHub,
        subHubId: null,
        miniHubId: null,
        name: item.name,
        images: '',
        videos: '',
        column: item.column,
        row: item.row,
        columnPosition: item.columnPosition,
        description: item.description,
        width: 0.0,
        height: 0.0,
        length: 0.0,
        weight: 0,
      };
    });
    dispatch(changeIsLoadingUpdate(true));

    const updateMasterHub = async () => {
      try {
        await editMasterHub(idMasterHub, upadeteHub);
      } catch (error) {
        console.error(error);
      } finally {
        if (rackDeletes.length !== 0) {
          deleteRack();
        } else {
          updateRack();
        }
      }
    };
    const deleteRack = async () => {
      try {
        await deleteMasterHubRacksMultiple(deleteRackData);
      } catch (error) {
        console.error(error);
      } finally {
        updateRack();
      }
    };
    const updateRack = async () => {
      try {
        await editMasterHubRacksMultiple(updateRackData);
      } catch (error) {
        console.error(error);
      } finally {
        if (cellSelectedDetailsNew.length !== 0) {
          addRacks();
        } else {
          dispatch(changeIsLoadingUpdate(false));
          setIsEditConfirm(false);
          dispatch(clearData(false));
          dispatch(
            inputNotifHub({
              key: 'editmasterhub',
              text: `${name} has been success to be edited`,
            })
          );
        }
      }
    };
    const addRacks = async () => {
      try {
        await addMasterHubRacksMultiple(addRackData);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(changeIsLoadingUpdate(false));
        setIsEditConfirm(false);
        dispatch(clearData(false));
        dispatch(
          inputNotifHub({
            key: 'editmasterhub',
            text: `${name} has been success to be edited`,
          })
        );
      }
    };

    updateMasterHub();
    // const saveEdit = async () => {
    //   try {
    //     await dispatch(
    //       saveEditMasterHub({
    //         id: idMasterHub,
    //         subsidiary: subsidiaryId,
    //         name: name,
    //         gambar: photo.length !== 0 ? photo.join(',') : '',
    //         video: video.length !== 0 ? video.join(',') : '',
    //         pic: picChoose,
    //         column: col,
    //         row: row,
    //         entrance: entrance.length !== 0 ? entrance.join(',') : '',
    //         rackAdd: cellSelectedDetailsNew,
    //         rackUpdate: cellSelectedDetails,
    //         rackDelete: rackDeletes,
    //       })
    //     );
    //   } catch (error) {
    //     console.error(error);
    //   } finally {
    //   }
    // };
    // saveEdit();

    // console.log(rackDeletes);
  };

  // useEffect(() => {
  //   if (isLoadingOnUpdate && !isLoadingUpdate) {
  //     setIsEditConfirm(false);
  //     dispatch(
  //       inputNotifHub({
  //         key: 'editmasterhub',
  //         text: `${name} has been success to be edited`,
  //       })
  //     );
  //   }
  // }, [isLoadingOnUpdate, isLoadingUpdate]);

  // * -- Map Addres
  const [isMapOpen, setIsMapOpen] = useState(false);

  return (
    <>
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleSaveFix}
        title="Edit Product"
        body={
          <Box sx={{ textAlign: 'center', mb: '40px', mt: '8px' }}>
            <Typography>Are you sure you want to save the changes?</Typography>
            {isLoadingUpdate && (
              <CircularProgress size={16} sx={{ mt: '12px' }} />
            )}
          </Box>
        }
      />

      {!isCreateMap ? (
        <>
          {/* //* Back Button */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <BackButton handleClick={handleBackButton} />
            <Box>
              <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                Edit Master Hub
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  color: '#9E9D9D',
                  alignItems: 'center',
                  mt: '4px',
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                  lulu
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', mt: '16px', gap: 3 }}>
            <Box sx={{ width: '200px', bgcolor: '#FAFAFA' }}>
              <HubFamily isEditMaster={true} idEditMaster={idMasterHub} />
            </Box>
            <Box sx={{ position: 'realtive' }}>
              <Grid container columns={12} spacing={4}>
                {/* //* Name */}
                <Grid item xs={12}>
                  <Typography
                    sx={{ mb: '8px', fontSize: '16px', fontWeight: '500' }}
                  >
                    Name*
                  </Typography>
                  <CustomInput
                    sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                    placeholder="Enter hub name"
                    value={name}
                    onChange={(e) => {
                      if (e.target.value.length <= 30) {
                        dispatch(changeName(e.target.value));
                      }
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      Enter at least 5 character
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        color: '#9E9D9D',
                        mt: '4px',
                      }}
                    >
                      {name.length}/30
                    </Typography>
                  </Box>
                </Grid>
                {/* //* Photo */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      pb: `${photo.length === 0 ? '16px' : '9px'}`,
                    }}
                  >
                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                      Photo
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#9E9D9D',
                      }}
                    >
                      ( max size : 2MB )
                    </Typography>
                    {isMaxSizePhoto && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ErrorOutlineIcon
                          sx={{
                            fontSize: '16px',
                            fontWeight: '500',
                            color: theme.palette.warning.main,
                            mr: '4px',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            color: theme.palette.warning.main,
                          }}
                        >
                          The photo can't be more than 2MB
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {photo.length === 0 && (
                        <Box
                          sx={{
                            height: '54px',
                            width: '54px',
                            aspectRatio: '1/1',
                            borderRadius: '8px',
                            bgcolor: '#F5F5F5',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderStyle: 'dashed',
                            borderColor: '#9E9D9D',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            document.getElementById('input_img').click();
                          }}
                        >
                          <ImageOutlinedIcon
                            sx={{ color: '#9E9D9D', fontSize: '28px' }}
                          />
                        </Box>
                      )}
                      {photo.map((input, index) => (
                        <Box
                          sx={{
                            position: 'relative',
                            overflow: 'visible',
                            padding: '7px',
                          }}
                          key={index}
                        >
                          <CancelIcon
                            sx={{
                              position: 'absolute',
                              fontSize: '20px',
                              top: 1,
                              right: 1,
                              zIndex: +1,
                              cursor: 'pointer',
                            }}
                            onClick={() => dispatch(deleteImage(input))}
                          />
                          <Box
                            component="img"
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              '/' +
                              input
                            }
                            sx={{
                              height: '54px',
                              width: '54px',
                              aspectRatio: '1/1',
                              borderRadius: '8px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              dispatch(clickIsPreviewPhoto());
                              dispatch(changePhotoPreview(input));
                            }}
                          />
                        </Box>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        mb: '7px',
                        ml: '20px',
                        alignItems: 'center',
                      }}
                    >
                      {photo.length < 3 && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.primary.main,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            document.getElementById('input_img').click();
                          }}
                        >
                          <input
                            accept="image/*"
                            id="input_img"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => dispatch(inputImage(e))}
                          />
                          <AddIcon />
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: '600' }}
                          >
                            Add More
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                {/* //* Video */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      pb: `${video.length === 0 ? '16px' : '9px'}`,
                    }}
                  >
                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                      Video
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#9E9D9D',
                      }}
                    >
                      ( max size : 100MB )
                    </Typography>
                    {isMaxSizeVideo && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ErrorOutlineIcon
                          sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            color: theme.palette.warning.main,
                            mr: '4px',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '8px',
                            fontWeight: '500',
                            color: theme.palette.warning.main,
                          }}
                        >
                          The video is too big
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {video.length === 0 && (
                        <Box
                          sx={{
                            height: '54px',
                            width: '54px',
                            aspectRatio: '1/1',
                            borderRadius: '8px',
                            bgcolor: '#F5F5F5',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderStyle: 'dashed',
                            borderColor: '#9E9D9D',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            document.getElementById('inputMenu_video').click();
                          }}
                        >
                          <VideocamOutlinedIcon
                            sx={{ color: '#9E9D9D', fontSize: '28px' }}
                          />
                        </Box>
                      )}
                      {video.map((input, index) => (
                        <Box
                          sx={{
                            position: 'relative',
                            overflow: 'visible',
                            padding: '7px',
                          }}
                          key={index}
                        >
                          <CancelIcon
                            sx={{
                              position: 'absolute',
                              fontSize: '20px',
                              top: 1,
                              right: 1,
                              zIndex: +1,
                              cursor: 'pointer',
                            }}
                            onClick={() => dispatch(deleteTayangan(input))}
                          />
                          <Box
                            onClick={() => {
                              dispatch(clickIsPreviewVideo());
                              dispatch(changeVideoPreview(input));
                            }}
                          >
                            <video
                              style={{
                                maxHeight: '54px',
                                maxWidth: '54px',
                                aspectRatio: '1/1',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                backgroundColor: 'black',
                              }}
                            >
                              <source
                                src={
                                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                                  '/' +
                                  input +
                                  '#t=0.5'
                                }
                              />
                            </video>
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        mb: '7px',
                        ml: '20px',
                        alignItems: 'center',
                      }}
                    >
                      {video.length < 3 && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.primary.main,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            document.getElementById('inputMenu_video').click();
                          }}
                        >
                          <input
                            // accept="video/*"
                            id="inputMenu_video"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => dispatch(inputTayangan(e))}
                          />
                          <AddIcon />
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: '600' }}
                          >
                            Add More
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                {/* //* Pic */}
                <Grid item xs={6}>
                  <Typography
                    sx={{ mb: '8px', fontSize: '16px', fontWeight: '500' }}
                  >
                    PIC*
                  </Typography>
                  <Autocomplete
                    options={picList}
                    getOptionLabel={(option) => option.namaLengkap}
                    value={picChoose}
                    onChange={(_, newValue) => {
                      dispatch(changePicChoose(newValue));
                      dispatch(changePicNumber(newValue.telpon));
                    }}
                    inputValue={picSearch}
                    loading={picLoading}
                    onInputChange={(_, newInputValue) => {
                      dispatch(changePicSearch(newInputValue));
                      picSearchInDebounce(newInputValue);
                    }}
                    renderOption={(props, option) => (
                      <li key={option.idKustomer} {...props}>
                        {option.namaLengkap}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search "
                        sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                      />
                    )}
                  />
                </Grid>
                {/* //* Pic Number */}
                <Grid item xs={6}>
                  <Typography
                    sx={{ mb: '8px', fontSize: '16px', fontWeight: '500' }}
                  >
                    Phone Number*
                  </Typography>
                  <CustomInput
                    sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                    placeholder="Enter phone number"
                    type="number"
                    value={picNumber}
                    onChange={(e) => {
                      dispatch(changePicNumber(e.target.value));
                    }}
                  />
                </Grid>
                {/* //* Address */}
                <Grid item xs={6} onClick={() => setIsMapOpen(!isMapOpen)}>
                  <CustomCard
                    sx={{ p: '8px 16px', cursor: 'pointer' }}
                    // onClick={() => setIsMapOpen(!isMapOpen)}
                  >
                    <Box
                      sx={{
                        display: 'center',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Box
                          sx={{
                            width: '66px',
                            height: '66px',
                            bgcolor: 'gray',
                            borderRadius: '8px',
                            position: 'relative',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              bgcolor: 'white',
                              width: '100%',
                              height: '100%',
                              zIndex: '+2',
                              opacity: 0.5,
                            }}
                          ></Box>
                          <Box
                            sx={{
                              maxHeight: '66px',
                              overflow: 'hidden',
                              borderRadius: '8px',
                            }}
                          >
                            <Map
                              defaultCenter={coordinate}
                              height={130}
                              defaultZoom={17}
                              width={66}
                              style={{
                                borderRadius: '8px',
                              }}
                            ></Map>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: '500', mb: '4px', ml: '8px' }}
                          >
                            Address
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.4,
                            }}
                          >
                            <LocationOnIcon color="primary" />
                            <Typography>{address}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </CustomCard>
                </Grid>
                {/* //* Dialog Address */}
                <Dialog
                  open={isMapOpen}
                  onClose={() => {
                    setIsMapOpen(!isMapOpen);
                  }}
                >
                  <DialogTitle
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box>Input Address</Box>
                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setIsMapOpen(false)}
                    />
                  </DialogTitle>
                  <DialogContent dividers>
                    <SetupAddress setIsOpen={setIsMapOpen} />
                  </DialogContent>
                </Dialog>
                {/* //* Rack  */}
                <Grid item xs={6}>
                  <CustomCard
                    sx={{ p: '8px 16px', cursor: 'pointer' }}
                    onClick={() => dispatch(changeIsCreateMap())}
                  >
                    <Box
                      sx={{
                        display: 'center',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Box
                          sx={{
                            height: '66px',
                            borderRadius: '8px',
                          }}
                        ></Box>
                        <Box sx={{ ml: '8px' }}>
                          <Typography sx={{ fontWeight: '500', mb: '4px' }}>
                            Racks Map*
                          </Typography>
                          <Typography
                            sx={{ color: '#9E9D9D', fontSize: '12px' }}
                          >
                            Click to create map
                          </Typography>
                        </Box>
                      </Box>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </CustomCard>
                </Grid>

                {/* publish */}
                <Box
                  sx={{
                    width: '100%',
                    bgcolor: 'white',
                    position: 'fixed',
                    bottom: '0px',
                    right: '0px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '18px 32px 18px 240px',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                      Publish
                    </Typography>
                    <CustomAntSwitch
                      checked={status}
                      onChange={() => dispatch(changeStatus())}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      sx={{ width: '140px', height: '36px' }}
                      // onClick={handleDelete}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: '500',
                        }}
                      >
                        Delete
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      sx={{ width: '140px', height: '36px' }}
                      onClick={() => handleSave()}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: '12px',
                          fontWeight: '500',
                        }}
                      >
                        Save
                      </Typography>
                    </CustomButton>
                  </Box>
                </Box>
              </Grid>
            </Box>
            {/* //* Dialog Photo */}
            <Dialog
              open={isPhotoPreview}
              onClose={() => {
                dispatch(clickIsPreviewPhoto());
                dispatch(changePhotoPreview(''));
              }}
            >
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  '/' +
                  linkPhotoPreview
                }
                sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
              />
            </Dialog>
            {/* //* Video Dialoge */}
            <Dialog
              open={isVideoPreview}
              onClose={() => {
                dispatch(clickIsPreviewVideo());
                dispatch(changeVideoPreview(''));
              }}
            >
              <Box sx={{ bgcolor: 'black' }}>
                <video
                  style={{
                    width: '600px',
                    height: '600px',
                    backgroundColor: 'black',
                  }}
                  controls
                >
                  <source
                    src={
                      process.env.REACT_APP_API_IMAGE_BASE_URL +
                      '/' +
                      linkVideoPreview
                    }
                  />
                </video>
              </Box>
            </Dialog>
          </Box>
        </>
      ) : (
        <CreateMapMasterHubEdit />
      )}
    </>
  );
};
export default MasterHubEdit;
