import { Box, Typography } from '@mui/material';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import {
  changeColCellSelectedMoment,
  changeDesCellSelectedMoment,
  changeNameCellSelectedMoment,
  changeRowCellSelectedMoment,
  getCellSelectedColMomentCreateMap,
  getCellSelectedDesMomentCreateMap,
  getCellSelectedNameMomentCreateMap,
  getCellSelectedRowMomentCreateMap,
  makeCellFix,
} from '../../../../../../../../store/hub/subHub/subHubAdd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../components/shared/CustomButton';

const InsertRackSubHub = () => {
  const dispatch = useDispatch();
  const name = useSelector(getCellSelectedNameMomentCreateMap);
  const col = useSelector(getCellSelectedColMomentCreateMap);
  const row = useSelector(getCellSelectedRowMomentCreateMap);
  const des = useSelector(getCellSelectedDesMomentCreateMap);

  const [isAvailableSubmit, setIsAvailableSubmit] = useState(false);

  useEffect(() => {
    if (name && col && row) {
      return setIsAvailableSubmit(true);
    } else {
      return setIsAvailableSubmit(false);
    }
  }, [name, col, row]);

  return (
    <CustomCard sx={{ p: '8px', bgcolor: 'white' }}>
      {/* Rack name */}
      <Box>
        <Typography sx={{ fontSize: '12px', mb: '8px' }}>Rack Name</Typography>
        <CustomInput
          value={name}
          onChange={(e) =>
            dispatch(changeNameCellSelectedMoment(e.target.value))
          }
          placeholder="Enter Name"
          sx={{
            bgcolor: '#FAFAFA',
            width: '100%',
            '.MuiOutlinedInput-root': {
              fontSize: '12px',
            },
            '& .MuiOutlinedInput-input': {
              padding: '8px 16px',
              backgroundColor: '#FAFAFA',
              borderRadius: '4px',
              '&::placeholder': {
                color: '#9E9D9D',
                fontSize: '0.75rem',
                fontWeight: 500,
              },
            },
          }}
        />
      </Box>

      {/* Rack col */}
      <Box sx={{ mt: '16px' }}>
        <Typography sx={{ fontSize: '12px', mb: '8px' }}>
          Number of columns
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <CustomInput
            value={col}
            onChange={(e) =>
              dispatch(changeColCellSelectedMoment(Number(e.target.value)))
            }
            type="number"
            placeholder="Enter Column"
            sx={{
              bgcolor: '#FAFAFA',
              width: '130px',
              '.MuiOutlinedInput-root': {
                fontSize: '12px',
              },
              '& .MuiOutlinedInput-input': {
                padding: '8px 16px',
                backgroundColor: '#FAFAFA',
                borderRadius: '4px',
                '&::placeholder': {
                  color: '#9E9D9D',
                  fontSize: '0.75rem',
                  fontWeight: 500,
                },
              },
            }}
          />
          <Box
            onClick={() => {
              if (col > 1) {
                dispatch(changeColCellSelectedMoment(Number(col - 1)));
              }
            }}
            sx={{
              bgcolor: '#51B15C1A',
              color: '#51B15C',
              height: '38px',
              width: '38px',
              fontSize: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            -
          </Box>
          <Box
            onClick={() => {
              if (col < 50) {
                dispatch(changeColCellSelectedMoment(Number(col + 1)));
              }
            }}
            sx={{
              bgcolor: '#51B15C1A',
              color: '#51B15C',
              height: '38px',
              width: '38px',
              fontSize: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            +
          </Box>
        </Box>
      </Box>

      {/* Rack level */}
      <Box sx={{ mt: '16px' }}>
        <Typography sx={{ fontSize: '12px', mb: '8px' }}>
          Number of level
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <CustomInput
            value={row}
            onChange={(e) =>
              dispatch(changeRowCellSelectedMoment(Number(e.target.value)))
            }
            type="number"
            placeholder="Enter level"
            sx={{
              bgcolor: '#FAFAFA',
              width: '130px',
              '.MuiOutlinedInput-root': {
                fontSize: '12px',
              },
              '& .MuiOutlinedInput-input': {
                padding: '8px 16px',
                backgroundColor: '#FAFAFA',
                borderRadius: '4px',
                '&::placeholder': {
                  color: '#9E9D9D',
                  fontSize: '0.75rem',
                  fontWeight: 500,
                },
              },
            }}
          />
          <Box
            onClick={() => {
              if (row > 1) {
                dispatch(changeRowCellSelectedMoment(Number(row - 1)));
              }
            }}
            sx={{
              bgcolor: '#51B15C1A',
              color: '#51B15C',
              height: '38px',
              width: '38px',
              fontSize: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            -
          </Box>
          <Box
            onClick={() => {
              if (row < 50) {
                dispatch(changeRowCellSelectedMoment(Number(row + 1)));
              }
            }}
            sx={{
              bgcolor: '#51B15C1A',
              color: '#51B15C',
              height: '38px',
              width: '38px',
              fontSize: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            +
          </Box>
        </Box>
      </Box>

      {/* Desc */}
      <Box sx={{ mt: '16px' }}>
        <Typography sx={{ fontSize: '12px', mb: '8px' }}>
          Description
        </Typography>
        <CustomInput
          value={des}
          onChange={(e) =>
            dispatch(changeDesCellSelectedMoment(e.target.value))
          }
          placeholder="Enter Descripition"
          sx={{
            bgcolor: '#FAFAFA',
            width: '100%',
            '.MuiOutlinedInput-root': {
              fontSize: '12px',
            },
            '& .MuiOutlinedInput-input': {
              padding: '8px 16px',
              backgroundColor: '#FAFAFA',
              borderRadius: '4px',
              '&::placeholder': {
                color: '#9E9D9D',
                fontSize: '0.75rem',
                fontWeight: 500,
              },
            },
          }}
        />
      </Box>

      <CustomButton
        variant="contained"
        sx={{ mt: '16px', width: '100%' }}
        disabled={!isAvailableSubmit}
        onClick={() => dispatch(makeCellFix())}
      >
        Enter
      </CustomButton>
    </CustomCard>
  );
};
export default InsertRackSubHub;
