import { alereAxios } from '../utils/api';

export const getAllDataAudit = (params, search) =>
  alereAxios.put(
    `audits/cek-audit?search=${params.search}&page_size=${params.pageSize}&page=${params.page}`,
    {
      params: params,
    }
  );

export const createAudits = (params) => alereAxios.post('audits', params);
export const auditScanQr = (params) => alereAxios.post('audits/qrs', params);
export const updateAuditScanQr = (idAudit, params) =>
  alereAxios.post(`audits/qrs/${idAudit}`, params);
export const auditCompare = (id) => alereAxios.post(`audits/${id}/compare`);
export const getSpesifikDataAudit = (id, params) =>
  alereAxios.get(`audits/${id}`, { params });
export const getAmountMissingQrCode = (id) =>
  alereAxios.get(`audits/${id}/missing-amount`);
export const getMissingQrCode = (id) =>
  alereAxios.get(`audits/${id}/missing-product`);
export const getMissingQrCodeGroup = (id) =>
  alereAxios.get(`audits/${id}/missing-product-group`);
