import { createSlice } from '@reduxjs/toolkit';

export const purchaseEditFinanceState = createSlice({
  name: 'purchaseEditFinance',
  initialState: {
    idSupplier: 0,
    idTransaksi: 0,
    idPurchase: 0,
    supplierChoose: null,
    paymentMethodChoose: null,
    deskripsi: '',
    products: [],
  },
  reducers: {
    goToPurchaseEdit: (state, action) => {
      const { products, description, idPurchase, supplier, paymentMethod } =
        action.payload;

      const collect = products.map((list) => {
        return {
          idProduk: list?.product?.idProduk,
          name: list?.product?.namaProduk,
          qty: list?.qty,
          unit: list?.unit,
          purchasePrice: list?.purchasePrice || 0,
        };
      });
      state.products = collect;
      state.deskripsi = description;
      state.idPurchase = idPurchase;
      state.supplierChoose = supplier;
      state.paymentMethodChoose = paymentMethod;
    },
    changePrice: (state, action) => {
      const { index, value } = action.payload;
      let collect = state.products;
      collect[index].purchasePrice = value;
      state.products = collect;
    },
    changeValuePruchasEdit: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { goToPurchaseEdit, changePrice, changeValuePruchasEdit } =
  purchaseEditFinanceState.actions;

export const getEditPurchaseAddFinance = (state) => state.purchaseEditFinance;

export default purchaseEditFinanceState.reducer;
