import { alereAxios } from '../utils/api';
import axios from 'axios';

// !------------- Storage -----------------------
export const getImage = (data) => {
  const accessToken = localStorage.getItem('accessToken');

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/storages/images`,
    data: data,
    headers: {
      Access: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getVideo = (data) => {
  const accessToken = localStorage.getItem('accessToken');

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/storages/videos`,
    data: data,
    headers: {
      Access: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const deleteFile = (file) =>
  alereAxios.delete('storages/files', {
    params: {
      path: file,
    },
  });

export const getHubFamilies = () => alereAxios.get('hubs/families');

export const getPic = ({ search, page, pageSize }) =>
  alereAxios.get('users', { params: { search, page, pageSize } });
