import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import {
  ChevronRightIcon,
  QrCodeScannerRoundedIcon,
} from '../../../../../../../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { changePageTransaction } from '../../../../../../../../store/Routing/transactionRouting';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import { getAllSupplierScan } from '../../../../../../../../store/purchase/purchaseSupplier/purchaseSubmitSupplier';
import { useState } from 'react';
import ScannedDataAcceptTransaction from './ScannedDataAcceptTransaction';
import UnscannedDataAcceptTransaction from './UnscannedDataAcceptTransaction';

const AcceptTransactionMobile = () => {
  const dispatch = useDispatch();

  const [isScanned, setIsScanned] = useState(true);
  const { isExist, scanned, idScanned, allData, detail } =
    useSelector(getAllSupplierScan); //nanti diganti ke redux transaction

  return (
    <Box sx={{ paddingTop: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <BackButtonHub
          onClick={() => {
            dispatch(changePageTransaction('detailRequestTransaction'));
          }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 0,
            alignItems: 'center',
            color: '#9E9D9D',
          }}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
            Stock request details
          </Typography>
          <ChevronRightIcon />
          <Typography
            sx={{ fontSize: '14px', fontWeight: 600, color: '#000000' }}
          >
            Accept
          </Typography>
        </Box>
      </Box>

      {/* head */}
      <Grid
        container
        columns={2}
        columnSpacing={0.5}
        sx={{
          mt: '16px',
          p: '4px',
          bgcolor: '#F5F5F5',
          borderRadius: '8px',
        }}
      >
        <Grid item xs={1}>
          <Box
            sx={{
              py: '8px',
              borderRadius: '8px',
              bgcolor: isScanned ? 'white' : '#F5F5F5',
            }}
            onClick={() => setIsScanned(true)}
          >
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: isScanned ? '600' : '500',
                color: isScanned ? '#51B15C' : 'black',
              }}
            >
              Scanned {scanned.length}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              py: '8px',
              borderRadius: '8px',
              bgcolor: !isScanned ? 'white' : '#F5F5F5',
            }}
            onClick={() => setIsScanned(false)}
          >
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: !isScanned ? '600' : '500',
                color: !isScanned ? '#51B15C' : 'black',
              }}
            >
              Unscanned{' '}
              {
                allData.filter((list) => !idScanned.includes(list.encript))
                  .length
              }
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* data scan */}
      <Box sx={{ minHeight: '70vh', bgcolor: 'white' }}>
        {isScanned ? (
          <ScannedDataAcceptTransaction />
        ) : (
          <UnscannedDataAcceptTransaction />
        )}
      </Box>

      {/* button event */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          bgcolor: 'white',
          p: '20px',
          width: '100%',
          display: 'flex',
          gap: 2,
        }}
      >
        <CustomButton
          variant="contained"
          sx={{ width: '100%' }}
          //   onClick={() => handleSend()}
          //   disabled={
          //     allData.filter((list) => !idScanned.includes(list.encript))
          //       .length !== 0
          //   }
        >
          Accept
        </CustomButton>
        <Box
          sx={{
            border: 2,
            borderRadius: '8px',
            borderColor: '#51B15C',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => dispatch(changePageTransaction('ProccessScanAccept'))}
        >
          <QrCodeScannerRoundedIcon sx={{ color: '#51B15C' }} />
        </Box>
      </Box>
    </Box>
  );
};

export default AcceptTransactionMobile;
