import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import { changePageTransaction } from '../../../../../../../../store/Routing/transactionRouting';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import {
  changeIsEditTransaction,
  getAllDataDetailTransaction,
  getIdDetailTransactionRequest,
  getIsLoadingTransactionDetail,
  getSpesifikTransactionRequest,
  getUserEmployeeStatus,
  getisEditTransaction,
} from '../../../../../../../../store/transaction/request/detailTransactionRequest';
import { QrScanner } from '../../../../../../../../assets/Icons';
import { useEffect } from 'react';
import { goToTransactionRequestEdit } from '../../../../../../../../store/transaction/request/editTransactionRequest';
import EditTransactionMobile from '../Edit/EditTransactionMobile';

const DetailTransactionRequestMobile = () => {
  const dispatch = useDispatch();
  const dataDetailTransaction = useSelector(getAllDataDetailTransaction);
  const idTransaction = useSelector(getIdDetailTransactionRequest);
  const isLoading = useSelector(getIsLoadingTransactionDetail);
  const isEdit = useSelector(getisEditTransaction);
  const userEmploye = useSelector(getUserEmployeeStatus);
  const role = localStorage.role;
  const status = dataDetailTransaction?.statusOrder;

  console.log({ dataDetailTransaction });

  useEffect(() => {
    dispatch(getSpesifikTransactionRequest(idTransaction));
  }, [idTransaction]);

  const handleToEdit = () => {
    dispatch(goToTransactionRequestEdit(dataDetailTransaction));
    dispatch(changeIsEditTransaction(true));
  };

  const handleBack = () => {
    if (status === 'Baru') {
      dispatch(changePageTransaction('request'));
      dispatch(changeIsEditTransaction(false));
    } else {
      dispatch(changePageTransaction(''));
      dispatch(changeIsEditTransaction(false));
    }
  };

  const handleAccept = () => {
    console.log('accept');
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : isEdit ? (
        <EditTransactionMobile />
      ) : (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              paddingTop: 2,
              // paddingLeft: 2,
              minHeight: '80vh',
            }}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              {!isEdit ? (
                <BackButtonHub onClick={handleBack} />
              ) : (
                <BackButtonHub
                  onClick={() => {
                    dispatch(changeIsEditTransaction(false));
                  }}
                />
              )}

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                  Stock request details
                </Typography>
                {dataDetailTransaction?.statusOrder === 'sent' ? (
                  <Box
                    sx={{
                      width: '52px',
                      height: '26px',
                      background: 'rgba(234, 205, 87, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#EACD57',
                      }}
                    >
                      sent
                    </Typography>
                  </Box>
                ) : dataDetailTransaction?.statusOrder === 'accept' ? (
                  <Box
                    sx={{
                      width: '83px',
                      height: '26px',
                      background: 'rgba(81, 177, 92, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#51B15C',
                      }}
                    >
                      accept
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            <>
              <Grid
                container
                columns={12}
                spacing={3}
                sx={{ paddingTop: '28px', marginBottom: '100px ' }}
              >
                {/* from */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      background: '#FAFAFA',
                      borderRadius: '8px 8px 0px 0px',
                      padding: '16px 12px 14px 14px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        marginBottom: '8px',
                        color: '#9E9E9E',
                      }}
                    >
                      From
                    </Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                      {dataDetailTransaction?.namaPemberi || '_'}
                    </Typography>
                  </Box>
                </Grid>

                {/* to */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      background: '#FAFAFA',
                      borderRadius: '8px 8px 0px 0px',
                      padding: '16px 12px 14px 14px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        marginBottom: '8px',
                        color: '#9E9E9E',
                      }}
                    >
                      To
                    </Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                      {dataDetailTransaction?.namaTujuan || '_'}
                    </Typography>
                  </Box>
                </Grid>

                {/* items */}
                <Grid item xs={12}>
                  <CustomCard>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: '20px',
                        boxShadow: '0px 1px 5px #ccc',
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                        Item(s)
                      </Typography>
                    </Box>

                    <Box>
                      <Grid
                        container
                        columns={12}
                        spacing={4}
                        sx={{ p: '30px 20px' }}
                      >
                        {dataDetailTransaction?.details.map((item, index) => (
                          <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Grid container columns={12} spacing={3}>
                              {/* product */}
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    background: '#FAFAFA',
                                    borderRadius: '8px 8px 0px 0px',
                                    padding: '16px 12px 14px 14px',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: 500,
                                      marginBottom: '8px',
                                      color: '#9E9E9E',
                                    }}
                                  >
                                    Product*
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: '14px', fontWeight: 500 }}
                                  >
                                    {item?.product?.namaProduk}
                                  </Typography>
                                </Box>
                              </Grid>
                              {/* qty */}
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    background: '#FAFAFA',
                                    borderRadius: '8px 8px 0px 0px',
                                    padding: '16px 12px 14px 14px',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: 500,
                                      marginBottom: '8px',
                                      color: '#9E9E9E',
                                    }}
                                  >
                                    Qty*
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: '14px', fontWeight: 500 }}
                                  >
                                    {item?.jumlah} {item?.unit}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>
            </>
          </Box>
          {/* button event */}
          {(role == 2 || role == 3) && (
            <>
              {dataDetailTransaction?.statusOrder === 'sent' ? (
                <Box
                  sx={{
                    width: '100%',
                    bgcolor: 'white',
                    position: 'absolute',
                    bottom: '0px',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <CustomButton
                      variant="contained"
                      sx={{ width: '100%', height: '40px' }}
                      onClick={() =>
                        dispatch(
                          changePageTransaction('acceptTransactionMobile')
                        )
                      }
                    >
                      <QrScanner />
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: '14px',
                          ml: 1,
                          fontWeight: 600,
                        }}
                      >
                        Accept
                      </Typography>
                    </CustomButton>
                  </Box>
                </Box>
              ) : dataDetailTransaction?.statusOrder === 'accept' ? (
                <></>
              ) : (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CustomButton
                      variant="contained"
                      sx={{
                        width: '100%',
                        height: '40px',
                        background: '#F33A3A',
                      }}
                      // onClick={handleDelete}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: '14px',
                          fontWeight: 600,
                        }}
                      >
                        Reject
                      </Typography>
                    </CustomButton>
                    {userEmploye === 'tujuan' ? (
                      <CustomButton
                        variant="contained"
                        sx={{ width: '100%', height: '40px' }}
                        onClick={() => handleToEdit()}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '14px',
                            ml: 1,
                            fontWeight: 600,
                          }}
                        >
                          Edit
                        </Typography>
                      </CustomButton>
                    ) : (
                      <CustomButton
                        variant="contained"
                        sx={{ height: '40px', width: '100%' }}
                        onClick={() =>
                          dispatch(
                            changePageTransaction('sendTransactionMobile')
                          )
                        }
                      >
                        <QrScanner />
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '14px',
                            ml: 1,
                            fontWeight: 600,
                          }}
                        >
                          Send
                        </Typography>
                      </CustomButton>
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default DetailTransactionRequestMobile;
