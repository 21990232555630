import React from 'react';
import { Box } from '@mui/material';

const CustomCard = ({ children, sx, widthRef, ...props }) => {
  return (
    <Box
      ref={widthRef}
      sx={{
        borderRadius: '12px',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default CustomCard;
