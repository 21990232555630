import { useDispatch, useSelector } from 'react-redux';
import {
  changeIsEditTransaction,
  getAllDataDetailTransaction,
  getIdDetailTransactionRequest,
  getIsLoadingTransactionDetail,
  getSpesifikTransactionRequest,
  getisEditTransaction,
} from '../../../../../../../store/transaction/request/detailTransactionRequest';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { changePageTransaction } from '../../../../../../../store/Routing/transactionRouting';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import { useEffect } from 'react';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import { goToTransactionRequestEdit } from '../../../../../../../store/transaction/request/editTransactionRequest';
import EditTransactionRequest from '../Edit/EditTransactionRequest';
import DetailTransactionRequestMobile from '../../TtansactionMobile/Request/Details/DetailTransactionRequestMobile';

const DetailRequestTransaction = () => {
  const matchTablet = useMediaQuery('(min-width:832px');
  const dispatch = useDispatch();
  const idTransaction = useSelector(getIdDetailTransactionRequest);
  const isLoading = useSelector(getIsLoadingTransactionDetail);
  const dataDetailTransaction = useSelector(getAllDataDetailTransaction);
  const isEdit = useSelector(getisEditTransaction);

  useEffect(() => {
    dispatch(getSpesifikTransactionRequest(idTransaction));
  }, [idTransaction]);

  const handleToEdit = () => {
    dispatch(goToTransactionRequestEdit(dataDetailTransaction));
    dispatch(changeIsEditTransaction(true));
  };

  return (
    <>
      {!matchTablet ? (
        <DetailTransactionRequestMobile />
      ) : (
        <Box sx={{ paddingTop: 2, paddingLeft: 3 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {!isEdit ? (
              <BackButtonHub
                onClick={() => {
                  dispatch(changePageTransaction('request'));
                  dispatch(changeIsEditTransaction(false));
                }}
              />
            ) : (
              <BackButtonHub
                onClick={() => {
                  dispatch(changeIsEditTransaction(false));
                }}
              />
            )}

            <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
              {!isEdit ? 'Stock request details' : 'Stock request edit'}
            </Typography>
          </Box>

          {/* main */}
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '50vh',
              }}
            >
              <CircularProgress />
            </Box>
          ) : !isEdit ? (
            <>
              <Grid
                container
                columns={12}
                spacing={3}
                sx={{ paddingTop: '28px', marginBottom: '100px ' }}
              >
                {/* from */}
                <Grid item xs={6}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        marginBottom: '12px',
                      }}
                    >
                      From
                    </Typography>
                    <Box
                      sx={{
                        background: '#FAFAFA',
                        borderRadius: '8px 8px 0px 0px',
                        padding: '16px 12px 14px 14px',
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                        {dataDetailTransaction?.namaTujuan || '_'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* to */}
                <Grid item xs={6}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        marginBottom: '12px',
                      }}
                    >
                      To
                    </Typography>
                    <Box
                      sx={{
                        background: '#FAFAFA',
                        borderRadius: '8px 8px 0px 0px',
                        padding: '16px 12px 14px 14px',
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                        {dataDetailTransaction?.namaPemberi || '_'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* items */}
                <Grid item xs={12}>
                  <CustomCard>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: '20px',
                        boxShadow: '0px 1px 5px #ccc',
                      }}
                    >
                      <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                        Item(s)
                      </Typography>
                    </Box>

                    <Box>
                      <Grid
                        container
                        columns={12}
                        spacing={4}
                        sx={{ p: '30px 20px' }}
                      >
                        {dataDetailTransaction?.details.map((item, index) => (
                          <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Grid container columns={12} spacing={3}>
                              {/* product */}
                              <Grid item xs={6}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                    }}
                                  >
                                    Product*
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.product?.namaProduk}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              {/* qty */}
                              <Grid item xs={6}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                    }}
                                  >
                                    Qty*
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.jumlah} {item?.unit}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>
              {/* button event */}
              <Box
                sx={{
                  width: '100%',
                  bgcolor: 'white',
                  position: 'fixed',
                  bottom: '0px',
                  right: '0px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '18px 32px 18px 240px',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <CustomButton
                    variant="contained"
                    sx={{
                      width: '190px',
                      height: '48px',
                      background: '#F33A3A',
                    }}
                    // onClick={handleDelete}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Delete
                    </Typography>
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    sx={{ width: '190px', height: '48px' }}
                    onClick={() => handleToEdit()}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Edit
                    </Typography>
                  </CustomButton>
                </Box>
              </Box>
            </>
          ) : (
            <EditTransactionRequest />
          )}
        </Box>
      )}
    </>
  );
};
export default DetailRequestTransaction;
