import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePageMasterHub,
  setNotifMasterHub,
} from '../../../../../../../../store/Routing/hubs';
import {
  AddIcon,
  CancelIcon,
  CloseIcon,
  ErrorOutlineIcon,
  EyeIcon,
  ImageOutlinedIcon,
  KeyboardArrowDownIcon,
  VideocamOutlinedIcon,
} from '../../../../../../../../assets/Icons';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import {
  changeName,
  changePhotoPreview,
  changePicChoose,
  changePicNumber,
  changePicSearch,
  changePicSearchDebounce,
  changeStatus,
  changeSubsidiaryId,
  changeVideoPreview,
  clickIsPreviewPhoto,
  clickIsPreviewVideo,
  deleteImage,
  deleteTayangan,
  getAddressFix,
  getCoordinateFix,
  getIsMaxSizePhotoMasterHubAdd,
  getIsMaxSizeVideoMasterHubAdd,
  getIsPhotoPreviewMasterHubAdd,
  getIsVideoPreviewMasterHubAdd,
  getMasterHubAddAllState,
  getNameMasterHubAdd,
  getPhotoMasterHubAdd,
  getPhotoPreviewMasterHubAdd,
  getPicNumberMasterHubAdd,
  getPichChooseMasterHubAdd,
  getPichSearchDebounceMasterHubAdd,
  getPichSearchMasterHubAdd,
  getStatusMasterHubAdd,
  getSubsidiaryMasterHubAdd,
  getVideoMasterHubAdd,
  getVideoPreviewMasterHubAdd,
  inputImage,
  inputTayangan,
} from '../../../../../../../../store/hub/masterHub/masterHubAdd';
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from '../../../../../../../../store/subsidiary/subsidiary';
import {
  fetchPic,
  getPicList,
  getPicLoading,
} from '../../../../../../../../store/pic/pic';
import debounce from 'lodash.debounce';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import { Map } from 'pigeon-maps';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import CustomAntSwitch from '../../../../../../../../components/shared/CustomAntSwitch';
import SetupAddAddressMobile from './AddressMap/SetupAddAddressMobile';
import { addNewMasterHub } from '../../../../../../../../api/hubs';

const AddMasterHubMobile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const {
    mapInputType,
    placeGetByName,
    placeGetByCoordinate,
    isUploadImage,
    isUploadVideo,
    coordinateFix,
    adressFix,
  } = useSelector(getMasterHubAddAllState);

  const subsidiaryList = useSelector(getSubsidiaryList);
  const subsidiaryId = useSelector(getSubsidiaryMasterHubAdd);
  const [newSubsidiary, setNewSubsidiary] = useState(null);
  const name = useSelector(getNameMasterHubAdd);

  const photo = useSelector(getPhotoMasterHubAdd);
  const isMaxSizePhoto = useSelector(getIsMaxSizePhotoMasterHubAdd);
  const linkPhotoPreview = useSelector(getPhotoPreviewMasterHubAdd);
  const isPhotoPreview = useSelector(getIsPhotoPreviewMasterHubAdd);

  const video = useSelector(getVideoMasterHubAdd);
  const isMaxSizeVideo = useSelector(getIsMaxSizeVideoMasterHubAdd);
  const linkVideoPreview = useSelector(getVideoPreviewMasterHubAdd);
  const isVideoPreview = useSelector(getIsVideoPreviewMasterHubAdd);

  const picList = useSelector(getPicList);
  const picLoading = useSelector(getPicLoading);
  const picChoose = useSelector(getPichChooseMasterHubAdd);
  const picSearch = useSelector(getPichSearchMasterHubAdd);
  const picSearchDebounce = useSelector(getPichSearchDebounceMasterHubAdd);
  const picNumber = useSelector(getPicNumberMasterHubAdd);

  const status = useSelector(getStatusMasterHubAdd);

  const coordinate = useSelector(getCoordinateFix);
  const address = useSelector(getAddressFix);

  useEffect(() => {
    dispatch(fetchSubsidiaryList());
  }, []);

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounce(value));
    }, 1000)
  ).current;

  // !--------------------- Map Address ---------------------------
  const [isMapOpen, setIsMapOpen] = useState(false);

  // ! ------------------- Validation -----------------------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
  useEffect(() => {
    newSubsidiary !== null &&
    name.length >= 5 &&
    photo.length !== 0 &&
    picChoose !== null &&
    picNumber !== '' &&
    adressFix !== ''
      ? setIsSubmitAvailable(true)
      : setIsSubmitAvailable(false);
  }, [name, subsidiaryId, photo, video, picChoose, picNumber, adressFix]);

  useEffect(() => {
    picChoose !== null
      ? dispatch(changePicNumber(picChoose.telpon))
      : dispatch(changePicNumber(''));
  }, [picChoose]);

  const handleSave = () => {
    const params = {};

    params.subsidiaryId = newSubsidiary?.id;
    params.namaToko = name;
    // params.gambar = photo;
    // params.videos = video;
    params.idKustomer = picChoose?.idKustomer;
    params.nohp = picChoose?.telpon;
    params.status = status ? 'aktif' : 'pending';
    params.alamat = adressFix === '' ? address : adressFix;
    params.lat = coordinateFix[0].toString();
    params.lon = coordinateFix[1].toString();
    params.typeInput = mapInputType;
    // params.cityDistrict = placeGetByName?.formatted;

    if (photo.length !== 0) {
      params['gambar'] = photo.join(',');
    }
    if (video.length !== 0) {
      params['videos'] = video.join(',');
    }

    if (mapInputType === 'manual') {
      const { formatted: citydistric } = placeGetByName;
      params['cityDistrict'] = citydistric;
    } else {
      if (!placeGetByCoordinate?.statusCode) {
        const { formatted: citydistric } = placeGetByCoordinate;
        params['cityDistrict'] = citydistric;
      }
    }

    const createHub = async () => {
      setIsLoadingSubmit(true);
      try {
        const {
          data: { data },
        } = await addNewMasterHub(params);
        setIsLoadingSubmit(false);
        dispatch(changePageMasterHub(''));
        dispatch(
          setNotifMasterHub({
            key: 'addHubs',
            text: 'hubs success added',
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingSubmit(false);
      }
    };

    createHub();
  };

  return (
    <Box sx={{ position: 'relative', minHeight: '86vh' }}>
      <Grid container columns={12} spacing={3}>
        {/* back button */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              gap: 1.5,
              alignItems: 'center',
              mt: 2,
              mb: 2,
            }}
          >
            <BackButtonHub onClick={() => dispatch(changePageMasterHub(''))} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#000' }}
              >
                Add Master Hub
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <EyeIcon />
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 600, color: '#51B15C' }}
                >
                  Preview
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* subsidiary */}
        <Grid item xs={12}>
          <Autocomplete
            options={subsidiaryList}
            value={newSubsidiary}
            getOptionLabel={(option) => option.name}
            // loading={isLoadingSubsidiary}
            onChange={(_, newValue) => {
              setNewSubsidiary(newValue);
              dispatch(changeSubsidiaryId(newValue?.id));
            }}
            renderInput={(params) => (
              <TextField
                label="Subsidiary"
                variant="filled"
                {...params}
                sx={{
                  bgcolor: '#fafafa',
                  borderRadius: '5px',
                  fontSize: '14px',
                  color: '#9D9D9D',
                }}
              />
            )}
          />
        </Grid>

        {/* Name */}
        <Grid item xs={12}>
          <CustomInput
            label="Name*"
            variant="filled"
            sx={{ width: '100%', bgcolor: '#FAFAFA', fontSize: '14px' }}
            placeholder="Enter hub name"
            value={name}
            onChange={(e) => {
              if (e.target.value.length <= 30) {
                dispatch(changeName(e.target.value));
              }
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: '400',
                color:
                  name.length > 0 && name.length < 5 ? '#F33A3A' : '#9E9D9D',
                mt: '4px',
              }}
            >
              Enter at least 5 character
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: '400',
                color: '#9E9D9D',
                mt: '4px',
              }}
            >
              {name.length}/30
            </Typography>
          </Box>
        </Grid>

        {/*  Photo */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              pb: `${photo.length === 0 ? '16px' : '9px'}`,
            }}
          >
            <Typography
              sx={{ fontSize: '14px', fontWeight: 400, color: '#000' }}
            >
              Photo*
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: '#9E9D9D',
              }}
            >
              ( max size : 2MB, max file : 3 )
            </Typography>
            {isMaxSizePhoto && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: theme.palette.warning.main,
                    mr: '4px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '10px',
                    fontWeight: 500,
                    color: theme.palette.warning.main,
                  }}
                >
                  The photo can't be more than 2MB
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {photo.length === 0 && (
                <Box
                  sx={{
                    height: '46px',
                    width: '46px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    bgcolor: '#F5F5F5',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderStyle: 'dashed',
                    borderColor: '#9E9D9D',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('input_img').click();
                  }}
                >
                  <ImageOutlinedIcon
                    sx={{ color: '#9E9D9D', fontSize: '28px' }}
                  />
                </Box>
              )}
              {photo.map((input, index) => (
                <Box
                  sx={{
                    position: 'relative',
                    overflow: 'visible',
                    padding: '7px',
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: 'absolute',
                      fontSize: '20px',
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: 'pointer',
                    }}
                    onClick={() => dispatch(deleteImage(input))}
                  />
                  <Box
                    component="img"
                    src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + input}
                    sx={{
                      height: '46px',
                      width: '46px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      dispatch(clickIsPreviewPhoto());
                      dispatch(changePhotoPreview(input));
                    }}
                  />
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                mb: '7px',
                ml: '20px',
                alignItems: 'center',
              }}
            >
              {photo.length < 3 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('input_img').click();
                  }}
                >
                  <input
                    accept="image/*"
                    id="input_img"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => dispatch(inputImage(e))}
                  />
                  {isUploadImage ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <CircularProgress size={18} />
                      <Typography> uploading...</Typography>
                    </Box>
                  ) : (
                    <>
                      <AddIcon />
                      <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                        Add More
                      </Typography>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        {/*  Video */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              pb: `${video.length === 0 ? '16px' : '9px'}`,
            }}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
              Video
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: '#9E9D9D',
              }}
            >
              ( max size : 100MB , max file : 3 )
            </Typography>
            {isMaxSizeVideo && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: theme.palette.warning.main,
                    mr: '4px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '8px',
                    fontWeight: '500',
                    color: theme.palette.warning.main,
                  }}
                >
                  The video is too big
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {video.length === 0 && (
                <Box
                  sx={{
                    height: '46px',
                    width: '46px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    bgcolor: '#F5F5F5',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderStyle: 'dashed',
                    borderColor: '#9E9D9D',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('inputMenu_video').click();
                  }}
                >
                  <VideocamOutlinedIcon
                    sx={{ color: '#9E9D9D', fontSize: '28px' }}
                  />
                </Box>
              )}
              {video.map((input, index) => (
                <Box
                  sx={{
                    position: 'relative',
                    overflow: 'visible',
                    padding: '7px',
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: 'absolute',
                      fontSize: '20px',
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: 'pointer',
                    }}
                    onClick={() => dispatch(deleteTayangan(input))}
                  />
                  <Box
                    onClick={() => {
                      dispatch(clickIsPreviewVideo());
                      dispatch(changeVideoPreview(input));
                    }}
                  >
                    <video
                      style={{
                        maxHeight: '46px',
                        maxWidth: '46px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        backgroundColor: 'black',
                      }}
                    >
                      <source
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                          '/' +
                          input +
                          '#t=0.5'
                        }
                      />
                    </video>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                mb: '7px',
                ml: '20px',
                alignItems: 'center',
              }}
            >
              {video.length < 3 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('inputMenu_video').click();
                  }}
                >
                  <input
                    // accept="video/*"
                    id="inputMenu_video"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => dispatch(inputTayangan(e))}
                  />
                  {isUploadVideo ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <CircularProgress size={18} />
                      <Typography> uploading...</Typography>
                    </Box>
                  ) : (
                    <>
                      <AddIcon />
                      <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                        Add More
                      </Typography>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        {/*  Pic */}
        <Grid item xs={12}>
          <Autocomplete
            options={picList}
            getOptionLabel={(option) => option.namaLengkap}
            value={picChoose}
            onChange={(_, newValue) => {
              dispatch(changePicChoose(newValue));
            }}
            inputValue={picSearch}
            loading={picLoading}
            onInputChange={(_, newInputValue) => {
              dispatch(changePicSearch(newInputValue));
              picSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idKustomer} {...props}>
                {option.namaLengkap}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="PIC*"
                variant="filled"
                sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              />
            )}
          />
        </Grid>

        {/* Phone Number */}
        <Grid item xs={12}>
          <Box
            sx={{
              background: '#F0F0F0',
              borderRadius: '8px 8px 0px 0px',
              padding: '16px 12px 14px 14px',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                marginBottom: '8px',
                color: '#9E9E9E',
              }}
            >
              Phone Number
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}
            >
              {picChoose?.telpon || '-'}
            </Typography>
          </Box>
        </Grid>

        {/* Address */}
        <Grid item xs={12}>
          <CustomCard
            sx={{ p: '8px 16px', cursor: 'pointer' }}
            onClick={() => setIsMapOpen(!isMapOpen)}
          >
            <Box
              sx={{
                display: 'center',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    width: '66px',
                    height: '66px',
                    bgcolor: 'gray',
                    borderRadius: '8px',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      bgcolor: 'white',
                      width: '100%',
                      height: '100%',
                      zIndex: '+2',
                      opacity: 0.5,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      maxHeight: '66px',
                      overflow: 'hidden',
                      borderRadius: '8px',
                    }}
                  >
                    <Map
                      defaultCenter={[3.546958571415798, 98.6901696840363]}
                      height={130}
                      defaultZoom={17}
                      width={66}
                      style={{
                        borderRadius: '8px',
                      }}
                    ></Map>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      mb: '4px',
                      ml: '8px',
                    }}
                  >
                    Address
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.4,
                    }}
                  >
                    <LocationOnIcon color="primary" />
                    <Typography>
                      {address ? address : 'Click to enter address'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>

        {/* status */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '16px',
            }}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
              Status
            </Typography>
            <CustomAntSwitch
              value={status}
              onChange={() => dispatch(changeStatus())}
            />
          </Box>
        </Grid>
      </Grid>

      {/* button submit */}
      <Box
        sx={{
          width: '100%',
          bgcolor: '#FFFFFF',
          position: 'sticky',
          bottom: 0,
          padding: '8px 0px',
          zIndex: 99,
        }}
      >
        <CustomButton
          variant="contained"
          sx={{ width: '100%', height: '36px' }}
          onClick={() => handleSave()}
          disabled={!isSubmitAvailable}
        >
          {isLoadingSubmit ? (
            <CircularProgress size={18} sx={{ color: '#FFF' }} />
          ) : (
            <Typography
              sx={{
                color: 'white',
                fontSize: '12px',
                fontWeight: '500',
              }}
            >
              Save
            </Typography>
          )}
        </CustomButton>
      </Box>

      {/* ----------------------------------------------------------------- */}
      {/* dialog */}
      {/* ----------------------------------------------------------------- */}
      {/*  Dialog Photo */}
      <Dialog
        open={isPhotoPreview}
        onClose={() => {
          dispatch(clickIsPreviewPhoto());
          dispatch(changePhotoPreview(''));
        }}
      >
        <Box
          component="img"
          src={
            process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + linkPhotoPreview
          }
          sx={{ maxWidth: '300px', maxHeight: '300px', aspectRation: '1/1' }}
        />
      </Dialog>

      {/* Video Dialoge */}
      <Dialog
        open={isVideoPreview}
        onClose={() => {
          dispatch(clickIsPreviewVideo());
          dispatch(changeVideoPreview(''));
        }}
      >
        <Box sx={{ bgcolor: 'black' }}>
          <video
            style={{
              maxWidth: '300px',
              maxHeight: '300px',
              backgroundColor: 'black',
            }}
            controls
          >
            <source
              src={
                process.env.REACT_APP_API_IMAGE_BASE_URL +
                '/' +
                linkVideoPreview
              }
            />
          </video>
        </Box>
      </Dialog>

      {/* Dialog Address */}
      <Dialog
        open={isMapOpen}
        onClose={() => {
          setIsMapOpen(!isMapOpen);
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>Input Address</Box>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsMapOpen(false)}
          />
        </DialogTitle>
        <DialogContent dividers>
          <SetupAddAddressMobile setIsOpen={setIsMapOpen} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default AddMasterHubMobile;
