import { Box } from '@mui/material';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllRack } from '../../../../../../api/rack';
import CustomTable2 from '../../../../../../components/shared/shared2/CustomTable2';
import {
  getAllRackhub,
  goToRackHubDetail,
} from '../../../../../../store/racks/rackHub';

const headTables = [
  {
    key: 'photo',
    altKey: '',
    name: 'Photo',
    maxWidht: '12px',
    align: 'start',
    short: false,
  },
  {
    key: 'code',
    altKey: 'name',
    name: 'Code*',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'row',
    altKey: '',
    name: 'Level',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'column',
    altKey: '',
    name: 'Column',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'sku',
    altKey: '',
    name: 'SKU',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'cogs',
    altKey: '',
    name: 'COGS(Rp)*',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'width',
    altKey: '',
    name: 'width',
    maxWidht: '',
    align: 'end',
    short: false,
  },
  {
    key: 'lenght',
    altKey: 'length',
    name: 'length',
    maxWidht: '',
    align: 'end',
    short: false,
  },
  {
    key: 'weight',
    altKey: '',
    name: 'weight',
    maxWidht: '',
    align: 'end',
    short: false,
  },
  {
    key: 'height',
    altKey: '',
    name: 'height',
    maxWidht: '',
    align: 'end',
    short: false,
  },
];

const adjustFilterType = [
  { key: 'photo', name: 'Photo*', adjustable: false, default: true },
  { key: 'code', name: 'Code*', adjustable: false, default: true },
  { key: 'row', name: 'Level', adjustable: true, default: true },
  { key: 'column', name: 'Column', adjustable: true, default: true },
  { key: 'sku', name: 'SKU', adjustable: true, default: true },
  { key: 'cogs', name: 'COGS', adjustable: true, default: true },
  { key: 'width', name: 'width', adjustable: true, default: false },
  { key: 'Length', name: 'Length', adjustable: true, default: false },
  { key: 'weight', name: 'Weight', adjustable: true, default: false },
  { key: 'height', name: 'Height', adjustable: true, default: false },
];

const RackHubMain = () => {
  const dispatch = useDispatch();
  const { id, path } = useSelector(getAllRackhub);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [dataTables, setDataTables] = useState([]);
  const [tablePage, setTablePage] = useState(1);
  const [tablePageSize, setTablePageSize] = useState(5);
  const [totalTableData, setTotalTableData] = useState(1);
  const [sortBased, setSortBased] = useState('code');
  const [isSortAsc, setIsSortAsc] = useState(true);
  const [searchTable, setSearchTable] = useState('');
  const fetchApi = async (
    page,
    pageSize,
    id,
    resource,
    sort,
    isAsc,
    search
  ) => {
    setIsTableLoading(true);
    const datas = {
      page: page,
      pageSize: pageSize,
      id: id,
      resource: resource,
      search: search,
    };
    datas[sort] = isAsc ? 'asc' : 'desc';
    try {
      const {
        data: {
          data: {
            data,
            pageInfo: { total },
          },
        },
      } = await getAllRack(datas);
      setDataTables(data);
      setTotalTableData(total);
    } catch (error) {
      console.error(error);
    } finally {
      setIsTableLoading(false);
    }
  };
  useEffect(() => {
    fetchApi(
      tablePage,
      tablePageSize,
      id,
      path,
      sortBased,
      isSortAsc,
      searchTable
    );
  }, [tablePage, tablePageSize, id, path, sortBased, isSortAsc, searchTable]);
  return (
    <Box>
      <CustomTable2
        title="Racks List"
        totalData={totalTableData}
        datas={dataTables}
        headTables={headTables}
        loading={isTableLoading}
        handleChangePageSizeTable={(value) => setTablePageSize(value)}
        handleBackPage={(value) => setTablePage(value)}
        handleNextPage={(value) => setTablePage(value)}
        handleInputPage={(value) => setTablePage(value)}
        handleSortPage={(key, isAsc) => {
          setSortBased(key);
          setIsSortAsc(isAsc);
        }}
        handleSearchPage={(value) => {
          setSearchTable(value);
        }}
        defaultShort={sortBased}
        adjustFilterType={adjustFilterType}
        keyCell="code"
        handleClickRow={(id) => dispatch(goToRackHubDetail(id))}
        handleClickCell={(id) => dispatch(goToRackHubDetail(id))}
      />
    </Box>
  );
};
export default RackHubMain;
