import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Subsidiary from '../../../../../../components/shared/Subsidiary';
import CustomCard from '../../../../../../components/shared/CustomCard';
import {
  ToLineChart,
  ToBarChart,
  SearchIcon,
  FilterAltIcon,
  ExportIcon,
  UserIcon,
  Warehouse,
} from '../../../../../../assets/Icons';
import CustomLineChart from '../../../../../../components/shared/CustomLineChart';
import CustomBarChart from '../../../../../../components/shared/CustomBarChart';
import CustomTableMobile from '../../../../../../components/shared/CustomTableMobile';
import { useRef } from 'react';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';
import CustomButton from '../../../../../../components/shared/CustomButton';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  endDateAdjustment,
  fetchDataAdjustment,
  getDataAdjusment,
  isLoadingFetchAdjusment,
  locationFilterAdjustment,
  startDateAdjustment,
  statusFilterAdjustment,
  userFilterAdjustment,
} from '../../../../../../store/adjustment/dataAdjustment';
import DialogFilterAdjustmentDashboard from './DialogFilter';
import { changeIdDetailAdjustment } from '../../../../../../store/adjustment/detailAdjustment';
import { changePageAdjustment } from '../../../../../../store/Routing/addjustment';

// data dummy chart
const datas = {
  labels: ['Pending', 'Accepted', 'Canceled'],
  data: [
    {
      label: 'ID Qty',
      chart: [30, 110, 140, 100, 240],
    },
    {
      label: 'SKU',
      chart: [5, 35, 100, 90, 150],
    },
  ],
};
const color = ['#51B15C', '#FEDD25'];

const colorData = {
  cancle: '#F33A3A',
  accept: '#51B15C',
  pending: '#FD7900',
};

const background = {
  cancle: 'rgba(243, 58, 58, 0.1)',
  accept: 'rgba(81, 177, 92, 0.1)',
  pending: 'rgba(253, 121, 0, 0.1)',
};

const DashboardAjustmentMobile = () => {
  const dispatch = useDispatch();
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);
  const [chartType, setChartType] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const dataAdjustment = useSelector(getDataAdjusment);
  const isLoading = useSelector(isLoadingFetchAdjusment);

  // !search
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');
  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  // ! filter
  const location = useSelector(locationFilterAdjustment);
  const status = useSelector(statusFilterAdjustment);
  const user = useSelector(userFilterAdjustment);
  const startDate = useSelector(startDateAdjustment);
  const endDate = useSelector(endDateAdjustment);
  const [fixUser, setFixUser] = useState([]);

  useEffect(() => {
    const sliceUser = user.map((item, _) => {
      return item?.id;
    });

    setFixUser(sliceUser);
  }, [user]);

  // ! -------------------- Fetching Data Adjustment --------------------
  useEffect(() => {
    const params = {};
    params.search = purchaseKeyword;
    params.location_filter = location;
    params.statusFilter = status;
    params.user_filter = fixUser;
    params.start_date_filter = startDate;
    params.end_date_filter = endDate;
    params.page_size = 100;
    dispatch(fetchDataAdjustment(params));
  }, [purchaseKeyword, location, status, fixUser, startDate, endDate]);

  //!  ----------------------------- Toolbabr ----------------------------
  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row-reverse',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <ExportIcon />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon stroke="#33a" sx={{ width: '14px' }} />
      </CustomButton>
      <DialogFilterAdjustmentDashboard {...{ isFilter, setIsFilter }} />
    </Box>
  );

  const handleToDetail = (id) => {
    dispatch(changeIdDetailAdjustment(id));
    dispatch(changePageAdjustment('detailAdjustment'));
  };

  return (
    <Grid container columns={12} spacing={2} sx={{ mt: 1.5 }}>
      {/* subsidiary */}
      <Grid item xs={12}>
        <Subsidiary
          subsidiaryChecked={filterSubsidiaryArray}
          setSubsidiaryChecked={setFilterSubsidiaryArray}
        />
      </Grid>
      {/* grafik */}
      <Grid item xs={12}>
        <CustomCard sx={{ p: 2 }}>
          {/* <Typography
            sx={{ color: '#51B15C', fontSize: '8px', fontWeight: 700, mb: 2 }}
          >
            COGS (Rp)*
          </Typography> */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{ color: '#51B15C', fontSize: '8px', fontWeight: 700 }}
            >
              COGS (Rp)*
            </Typography>
            <Box
              sx={{
                width: '16px',
                height: '16px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => setChartType(!chartType)}
            >
              {chartType ? (
                <ToLineChart stroke="#51B15C" />
              ) : (
                <ToBarChart stroke="#51B15C" />
              )}
            </Box>
            <Box></Box>
          </Box>
          {chartType ? (
            <CustomBarChart datas={datas} color={color} />
          ) : (
            <CustomLineChart datas={datas} color={color} />
          )}
        </CustomCard>
      </Grid>
      {/* tabel grafik  */}
      <Grid item xs={12}>
        <CustomTableMobile
          rootValue={datas?.labels}
          datas={datas.data}
          color={color}
          rootName="Status"
        />
      </Grid>

      {/* list data adjustment */}
      <Grid item xs={12}>
        <CustomCard sx={{ p: 2 }}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: '12px', color: '#000' }}
                  >
                    Adjustment
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    placeholder={'Search'}
                    size="small"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      debounceOnChange(e.target.value);
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingLeft: '6px',
                        fontSize: '8px',
                      },
                      bgcolor: '#FAFAFA',
                      minHeight: '22px',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ width: '12px' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box sx={{ marginLeft: 'auto' }}>{toolbar}</Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <UserIcon />
                  <Typography
                    sx={{ fontSize: '8px', fontWeight: 400, color: '#000' }}
                  >
                    User
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Warehouse />
                  <Typography
                    sx={{ fontSize: '8px', fontWeight: 400, color: '#000' }}
                  >
                    Warehouse
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* datas */}
            {isLoading ? (
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              </Grid>
            ) : dataAdjustment.length === 0 ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '50px',
                  }}
                >
                  <Typography sx={{ fontSize: '12px', color: '#F33A3A' }}>
                    No data found
                  </Typography>
                </Box>
              </Grid>
            ) : (
              dataAdjustment?.map((item, index) => (
                <Grid item xs={6} key={index}>
                  <Box
                    sx={{
                      background: '#FAFAFA',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                    onClick={() => handleToDetail(item?.id)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '10px',
                          fontWeight: 600,
                          color: '#000',
                        }}
                      >
                        ADJ- {item.id}
                      </Typography>
                      <Box
                        sx={{
                          borderRadius: '5px',
                          padding: '4px',
                          background:
                            item.status === 'pending'
                              ? background.pending
                              : item.status === 'accepted'
                              ? background.accept
                              : item.status === 'cancelled'
                              ? background.cancle
                              : '',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '10px',
                            fontWeight: 600,
                            minWidth: '56px',
                            color:
                              item.status === 'pending'
                                ? colorData.pending
                                : item.status === 'accepted'
                                ? colorData.accept
                                : item.status === 'cancelled'
                                ? colorData.cancle
                                : '#000',
                            textAlign: 'center',
                          }}
                        >
                          {item.status}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        mt: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '8px',
                          fontWeight: 500,
                          color: '#9E9D9D',
                        }}
                      >
                        {dayjs(item.createdAt).format('DD/MMM/YY')}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mt: 2,
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                      >
                        <UserIcon />
                        <Typography
                          sx={{
                            fontSize: '8px',
                            fontWeight: 400,
                            color: '#000',
                          }}
                        >
                          {item.namaLengkap}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                      >
                        <Warehouse />
                        <Typography
                          sx={{
                            fontSize: '8px',
                            fontWeight: 400,
                            color: '#000',
                          }}
                        >
                          {item.location}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </CustomCard>
      </Grid>
    </Grid>
  );
};
export default DashboardAjustmentMobile;
