import { alereAxios } from "../utils/api";

export const fetchRevenueSummary = ({ startDate, endDate }) =>
  alereAxios.get("revenue-summary", { params: { startDate, endDate } });

export const fetchBedSummary = () => alereAxios.get("bed/overview");

export const fetchOrderActivities = ({ startDate, endDate }) =>
  alereAxios.get("orders/activities", { params: { startDate, endDate } });

export const fetchPopularOrders = ({ startDate, endDate }) =>
  alereAxios.get("treatments/popular", {
    params: { startDate, endDate, page: 1, pageSize: 3 },
  });

export const fetchTreatmentWaitingTimes = ({ startDate, endDate }) =>
  alereAxios.get("treatments/waiting", { params: { startDate, endDate } });

export const fetchTreatmentProcessTimes = ({ startDate, endDate }) =>
  alereAxios.get("treatments/duration", { params: { startDate, endDate } });

export const fetchIncentives = ({ startDate, endDate }) =>
  alereAxios.get("incentives", { params: { startDate, endDate, size: 3 } });

export const fetchPatientStatistics = ({ startDate, endDate }) =>
  alereAxios.get("patient-statistics", { params: { startDate, endDate } });

export const fetchOrders = ({ page, pageSize }) =>
  alereAxios.get("orders", { params: { page, pageSize } });

export const fetchSalesTotal = ({ startDate, endDate, timeframe }) =>
  alereAxios.get("orderpayments/sales-total", {
    params: { startDate, endDate, timeframe },
  });

export const fetchOrderCount = ({ startDate, endDate, timeframe }) =>
  alereAxios.get("orderpayments/count", {
    params: { startDate, endDate, timeframe },
  });

export const fetchStaffPerformances = ({ startDate, endDate }) =>
  alereAxios.get("staff-performances", {
    params: { startDate, endDate },
  });

export const fetchClinicPerformances = ({
  page,
  pageSize,
  startDate,
  endDate,
  orderBy,
  direction,
}) =>
  alereAxios.get("clinic-performances", {
    params: { page, pageSize, startDate, endDate, orderBy, direction },
  });

export const fetchStocks = ({ startDate, endDate }) =>
  alereAxios.get("stocks/most-used", {
    params: { startDate, endDate, page: 1, pageSize: 3 },
  });
