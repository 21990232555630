import { useMediaQuery } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Chart as ChartJS, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import MobileDashboard from './pages/MobileDashboard';
import MobileLayout from './pages/MobileLayout';
import MobileSalesDashboard from './pages/MobileSalesDashboard';
import MobileTreatmentDashboard from './pages/MobileTreatmentDashboard';
import RequireAuth from './components/RequireAuth';
import useAuth from './hooks/useAuth';
import DesktopLayout from './pages/DesktopLayout';
import InventoryComponent from './pages/Assets/CurrentAssets/Inventory';
import { HubProvider } from './context/HubContext';
import InfiniteScrollTest from './pages/InfiniteScrollTest';
import LoginLog from './pages/LoginLog';

ChartJS.register(...registerables, annotationPlugin);

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);

const App = () => {
  const matchTablet = useMediaQuery('(min-width:832px)');
  const { auth } = useAuth();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateFormats={{ keyboardDate: 'DD/MMM/YY' }}
    >
      <HubProvider>
        <Routes>
          <Route
            index
            element={
              auth?.accessToken ? (
                <Navigate to="dashboard" replace />
              ) : (
                <Login />
              )
            }
          />

          <Route element={<RequireAuth />}>
            <Route element={matchTablet ? <DesktopLayout /> : <MobileLayout />}>
              <Route
                path="dashboard"
                element={matchTablet ? <Dashboard /> : <MobileDashboard />}
              />
              {auth?.companyId !== 9 && (
                <>
                  <Route
                    path="treatment-dashboard"
                    element={<MobileTreatmentDashboard />}
                  />
                  <Route
                    path="sales-dashboard"
                    element={<MobileSalesDashboard />}
                  />
                </>
              )}
              <Route path="assets">
                <Route
                  index
                  path="inventory"
                  element={<InventoryComponent matchTablet={matchTablet} />}
                />
              </Route>
              <Route path="infinite-scroll" element={<InfiniteScrollTest />} />
              <Route path="login-log" element={<LoginLog />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </HubProvider>
    </LocalizationProvider>
  );
};

export default App;
