import { alereAxios } from '../utils/api';

export const fetchAllTransaction = (params) =>
  alereAxios.get('transactions', {
    params: params,
  });

export const getDestinationTransaction = (params) =>
  alereAxios.post('transactions/cek-stock', params);

export const insertDataTransaction = (params) =>
  alereAxios.post('transactions', params);

export const getDetailTransactionRequest = (id) =>
  alereAxios.get(`transactions/${id}`);

export const editDetailTransactionRequest = (idTransaction, params) =>
  alereAxios.put(`transactions/edit-transaction/${idTransaction}`, params);

export const getListRequestDashboard = (params) =>
  alereAxios.get('transactions/dashboard-stocks-request', { params: params });
