import { Box } from '@mui/material';
import InventoryAudit from '..';
import { useSelector } from 'react-redux';
import { getAllAuditRouting } from '../../../../../../store/Routing/auditRouting';
import NotifAudit from './NotifAudit';
import DetailsAudit from '../Details';
import MissingQrCode from '../MissingQrCode/MissingQrCode';
import AddAudit from '../AuditMobile/AddAudit';
import ScanQrAudit from '../AuditMobile/AddAudit/Scan/ScanQr';

const RoutingAudit = ({ value, setValue }) => {
  const { page } = useSelector(getAllAuditRouting);
  return (
    <Box>
      <Box>
        <NotifAudit />
      </Box>
      {page === '' && <InventoryAudit />}
      {page === 'detailsAudits' && <DetailsAudit {...{ value, setValue }} />}
      {page === 'missingQrCode' && <MissingQrCode {...{ value, setValue }} />}
      {page === 'addAudit' && <AddAudit />}
      {page === 'scanQrAudit' && <ScanQrAudit />}
    </Box>
  );
};
export default RoutingAudit;
