import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  GridOn,
} from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from '../../../../../../../store/subsidiary/subsidiary';
import {
  changeDescription,
  changeIsHubOpen,
  changeIsSelectHub,
  changeProductChoose,
  changeProductSearch,
  changeProductSearchDebounce,
  changePurchaseName,
  changePurchaseQty,
  changeSubsidiaryId,
  clearData,
  fetchMasterHub,
  getAllFixHubs,
  getDataMasterHubPurchase,
  getDataUnits,
  getDataUnitsPurchase,
  getDatalistProduct,
  getDescriptionPurchase,
  getIsHubOpen,
  getIsSelectHub,
  getListProduct,
  getMasterHubId,
  getMasterHubPurchase,
  getMiniHubId,
  getProductChoosePurchase,
  getProductLoading,
  getProductSearchDebouncePurchase,
  getProductSearchPurchase,
  getProductTest,
  getPurchaseName,
  getPurchaseQty,
  getSubHubId,
  getSubsidiaryId,
} from '../../../../../../../store/purchase/addRequestPurchase';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import SelectHubPurchase from './SelectHub';
import CustomInput from '../../../../../../../components/shared/CustomInput';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import AutoCompleteProduct from '../../../Menu/MenuMenuAdd/components/AutoCompleteProduct';
import QtyUnitSelect from '../../../Menu/MenuMenuAdd/components/QtyUnitSelect';
import { useTheme } from '@emotion/react';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import debounce from 'lodash.debounce';
import { createRequestPurchase } from '../../../../../../../api/purchase';
import { fetchUnits } from '../../../../../../../api/products';
import ProductSelect from './component/ProductSelect';
import QtySelect from './component/QtySelect';
import {
  changePage,
  setNotifPurchase,
} from '../../../../../../../store/Routing/purchaseRouting';
import BottomMenu from '../../../../../../../components/shared/BottomMenu';
import { LoadingComponent } from '../../../../../../../components/shared/shared2';
import AddRequestPurchseMobile from '../../PurchaseMobile/RequestPurchase/Add/AddRequestPurchseMobile';

const AddRequestPurchase = ({ setPurchaseRequest, setAddRequestPurchase }) => {
  const matchTablet = useMediaQuery('(min-width:832px)');
  const userRole = localStorage.getItem('role');
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState();
  const [message, setMessage] = useState('');
  const dataHubs = useSelector(getAllFixHubs);
  const subsidiaryList = useSelector(getSubsidiaryList);
  const subsidiaryId = useSelector(getSubsidiaryId);
  const idToko = useSelector(getMasterHubId);
  const subHubId = useSelector(getSubHubId);
  const miniHubId = useSelector(getMiniHubId);
  const purchaseDescription = useSelector(getDescriptionPurchase);
  const purchaseQty = useSelector(getPurchaseQty);
  const [unitDatas, setUnitDatas] = useState([]);
  const [unit, setUnit] = useState('');

  const [isSelectHub, setIsSelectHub] = useState(false);
  const [productAddInputDatas, setProductAddInputDatas] = useState(true);

  // subsidiary
  useEffect(() => {
    dispatch(fetchSubsidiaryList());
    dispatch(getDatalistProduct());
  }, []);

  useEffect(() => {
    const getUnits = async () => {
      try {
        const {
          data: { data: dataFetchUnits },
        } = await fetchUnits({
          pageSize: 100,
          page: 1,
        });
        setUnitDatas(dataFetchUnits.units);
      } catch (err) {
        console.error();
        setUnitDatas([]);
      }
    };
    getUnits();
  }, []);

  // handle product

  // product
  const product = useSelector(getListProduct);
  const productLoading = useSelector(getProductLoading);
  const productChoose = useSelector(getProductChoosePurchase);
  const productSearch = useSelector(getProductSearchPurchase);
  const productSearchDebounce = useSelector(getProductSearchDebouncePurchase);
  const [productChoose0, setProductChoose0] = useState(null);
  const [productChoose1, setProductChoose1] = useState(null);

  const productSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changeProductSearchDebounce(value));
    }, 1000)
  ).current;

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [isDeleteIngredientRow, setIsDeleteIngredientRow] = useState(false);
  const [tambahItem, setTambahItem] = useState([
    {
      namaProduk: '',
      idProduk: 0,
      qty: 0,
      unit: '',
    },
  ]);

  const handleAddItemCardIngredient = () => {
    setTambahItem([
      ...tambahItem,
      { namaProduk: '', idProduk: 0, qty: 0, unit: '' },
    ]);
  };

  // validasi submit
  const [allowSubmit, setAllowSubmit] = useState(false);
  useEffect(() => {
    if (userRole === 1) {
      if (
        subsidiaryId !== 0 &&
        purchaseDescription !== '' &&
        idToko !== null &&
        tambahItem[0].idProduk !== 0 &&
        tambahItem[0].namaProduk !== '' &&
        tambahItem[0].qty !== 0 &&
        tambahItem[0].unit !== ''
      ) {
        setAllowSubmit(true);
      } else {
        setAllowSubmit(false);
      }
    } else {
      if (
        purchaseDescription !== '' &&
        idToko !== null &&
        tambahItem[0].idProduk !== 0 &&
        tambahItem[0].namaProduk !== '' &&
        tambahItem[0].qty !== 0 &&
        tambahItem[0].unit !== ''
      ) {
        setAllowSubmit(true);
      } else {
        setAllowSubmit(false);
      }
    }
  }, [subsidiaryId, purchaseDescription, idToko, tambahItem]);

  // -------- submit data ----------------------

  const handleSubmit = () => {
    let datas = {
      subsidiaryId: subsidiaryId,
      idToko: idToko,
      keterangan: purchaseDescription,
      subHubId: subHubId,
      miniHubId: miniHubId,
      products: tambahItem,
    };

    const createPurchase = async () => {
      setLoadingSubmit(true);
      try {
        const data = await createRequestPurchase(datas);

        dispatch(
          setNotifPurchase({
            key: 'addRequest',
            text: 'has been success added',
          })
        );
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingSubmit(false);
      }
    };

    createPurchase();
    dispatch(clearData());
    setTambahItem({ idProduk: '', qty: '', unit: '' });
    setUnit('');
    setMessage('Data berhasil di upload');
    dispatch(changePage('request'));
    handleBackButton();
    // setAddRequestPurchase(false);
    // setPurchaseRequest(true);
  };

  const handleBackButton = () => {
    dispatch(changePage('request'));
  };

  return (
    <>
      <Box sx={{ pb: '54px' }}>
        {message !== '' && (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
            }}
          >
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {message}
            </Alert>
          </Box>
        )}

        {!matchTablet ? (
          (subsidiaryId,
          (
            <AddRequestPurchseMobile
              {...{
                subsidiaryList,
                subsidiaryId,
                dataHubs,
                purchaseDescription,
                tambahItem,
                setTambahItem,
                handleAddItemCardIngredient,
                isDeleteIngredientRow,
                setIsDeleteIngredientRow,
                idToko,
                subHubId,
                miniHubId,
                setLoadingSubmit,
                setNotifPurchase,
                setUnit,
                setMessage,
              }}
            />
          ))
        ) : (
          <Box sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <BackButtonHub
                onClick={() => {
                  handleBackButton();
                }}
              />
              <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                Add purchase request
              </Typography>
            </Box>

            <Grid container columns={12} spacing={2} sx={{ mt: 2 }}>
              {/* //* Subsidiary */}
              {userRole == 1 && (
                <Grid item xs={6}>
                  <Typography
                    sx={{ mb: '8px', fontSize: '16px', fontWeight: 500 }}
                  >
                    Subsidiary*
                  </Typography>
                  {subsidiaryList.length !== 0 && (
                    <FormControl>
                      <RadioGroup
                        row
                        value={subsidiaryId}
                        onChange={(e) => {
                          dispatch(changeSubsidiaryId(e.target.value));
                        }}
                      >
                        {subsidiaryList?.map((item, index) => (
                          <FormControlLabel
                            key={index}
                            value={item.id}
                            label={item.name}
                            control={<Radio />}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                </Grid>
              )}

              {/* hub */}
              {userRole == 1 && (
                <Grid item xs={6}>
                  <CustomCard
                    sx={{ padding: '16px 14px', cursor: 'pointer' }}
                    onClick={() => setIsSelectHub(true)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ fontSize: '16px', fontWeight: 500 }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                          Hub*
                        </Typography>
                        <Box
                          sx={{
                            color: '#9E9D9D',
                            mt: '4px',
                          }}
                        >
                          {dataHubs.length === 0 ? (
                            <Typography
                              sx={{ fontSize: '14px', fontWeight: 500 }}
                            >
                              Click to Select hub, sub and mini
                            </Typography>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                color: 'black',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <Typography sx={{ fontSize: '12px' }}>
                                {dataHubs[0].namaToko}
                              </Typography>
                              {dataHubs[1] !== null ? (
                                <>
                                  <ChevronRightIcon sx={{ fontSize: '12px' }} />
                                  <Typography sx={{ fontSize: '12px' }}>
                                    {dataHubs[1].name}
                                  </Typography>
                                </>
                              ) : (
                                '_'
                              )}
                              {dataHubs[2] !== null ? (
                                <>
                                  <ChevronRightIcon sx={{ fontSize: '12px' }} />
                                  <Typography sx={{ fontSize: '12px' }}>
                                    {dataHubs[2].name}
                                  </Typography>
                                </>
                              ) : (
                                '_'
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </CustomCard>

                  {/* dialog hub */}
                  <Dialog
                    // maxWidth
                    fullWidth
                    // maxWidth="lg"
                    open={isSelectHub}
                    onClose={() => {
                      setIsSelectHub(false);
                    }}
                  >
                    <DialogTitle
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box>Select Hub</Box>
                      <CloseIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setIsSelectHub(false)}
                      />
                    </DialogTitle>
                    <DialogContent dividers>
                      <SelectHubPurchase setIsSelectHub={setIsSelectHub} />
                    </DialogContent>
                  </Dialog>
                </Grid>
              )}

              {/* name */}
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Typography
                  sx={{ fontSize: '17px', fontWeight: 'bold', mb: 2 }}
                >
                  Description
                </Typography>
                <CustomInput
                  fullWidth
                  sx={{
                    with: '100%',
                    bgcolor: '#fafafa',
                    '& .MuiOutlinedInput-input': {
                      padding: '17px 16px',
                      borderRadius: '10px',
                      '&::placeholder': {
                        color: '#111',
                        fontSize: '16px',
                      },
                    },
                    borderRadius: '5px',
                  }}
                  placeholder="enter description"
                  value={purchaseDescription}
                  onChange={(e) => {
                    if (e.target.value.length <= 250) {
                      dispatch(changeDescription(e.target.value));
                    }
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '17px',
                      mt: '5px',
                      ml: '15px',
                      color: '#9E9D9D',
                    }}
                  >
                    {purchaseDescription.length > 20
                      ? ''
                      : 'Enter at least 20 characters'}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      mt: '5px',
                      ml: '15px',
                      color: '#9E9D9D',
                    }}
                  >
                    {purchaseDescription.length}/250
                  </Typography>
                </Box>
              </Grid>

              {/* items */}
              <Grid item xs={12} sx={{ mt: 2 }}>
                <CustomCard>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: '20px',
                      boxShadow: '0px 1px 5px #ccc',
                    }}
                  >
                    <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                      Item(s)
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        color: '#51B15C',
                        gap: 1,
                        cursor: 'pointer',
                      }}
                      onClick={handleAddItemCardIngredient}
                    >
                      <AddIcon />
                      Add more
                    </Typography>
                  </Box>

                  <Box>
                    <Grid
                      container
                      columns={12}
                      spacing={4}
                      sx={{ p: '30px 20px' }}
                    >
                      {tambahItem.map((item, index) => (
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                          <Grid container columns={12} gap={4}>
                            {/* product */}
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  mb: 2,
                                }}
                              >
                                Product*
                              </Typography>
                              <ProductSelect
                                firstValueId={item.idProduk}
                                firstValue={item.namaProduk}
                                isReset={isDeleteIngredientRow}
                                setIsReset={setIsDeleteIngredientRow}
                                handleValue={(name, id) => {
                                  const memo = tambahItem.map((item, idx) => {
                                    if (index === idx) {
                                      return {
                                        ...item,
                                        idProduk: id,
                                        namaProduk: name,
                                      };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setTambahItem(memo);
                                }}
                              />
                            </Grid>
                            {/* qty */}
                            <Grid item xs={5}>
                              <Box sx={{ display: 'flex' }}>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    mb: 2,
                                  }}
                                >
                                  Qty*
                                </Typography>
                              </Box>
                              <QtySelect
                                firstValueQty={item.qty}
                                firstValueUnit={item.unit}
                                isReset={isDeleteIngredientRow}
                                setIsReset={setIsDeleteIngredientRow}
                                handleValue={(qty, unit) => {
                                  const memo = tambahItem.map((item, idx) => {
                                    if (index === idx) {
                                      return {
                                        ...item,
                                        qty: qty,
                                        unit: unit,
                                      };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setTambahItem(memo);
                                }}
                              />
                            </Grid>

                            {/* hapus item */}
                            <Grid
                              item
                              xs="auto"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 4,
                                color: 'red',
                              }}
                            >
                              {tambahItem.length === 1 ? (
                                <></>
                              ) : (
                                <DeleteIcon
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setTambahItem(
                                      tambahItem.filter(
                                        (_, idx) => idx !== index
                                      )
                                    );
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </CustomCard>
              </Grid>

              {/* button event */}
              <BottomMenu
                handleGreen={handleSubmit}
                disableGreen={!allowSubmit}
                isGreen={true}
                greenText={'Submit'}
              />
              <Box sx={{ position: 'fixed', bottom: 1, right: 1 }}>
                {/* {subsidiaryId !== 0 ? setAllowSubmit(true) : setAllowSubmit(false)} */}
                {/* <CustomButton
              variant="contained"
              disabled={!allowSubmit}
              onClick={handleSubmit}
              sx={{ width: '140px', height: '36px', m: '18px 32px' }}
            >
              <Typography
                sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
              >
                Submit
              </Typography>
            </CustomButton> */}
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
      {loadingSubmit && <LoadingComponent />}
    </>
  );
};
export default AddRequestPurchase;
