import { IconButton } from '@mui/material';
import { ArrowBackIosNew as ArrowBackIosNewIcon } from '@mui/icons-material';

const BackButtonHub = ({ onClick }) => {
  return (
    <IconButton
      sx={{
        bgcolor: 'primary.main',
        '&:hover': {
          bgcolor: 'primary.main',
        },
      }}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon
        sx={{ color: 'primary.contrastText', fontSize: '0.75rem' }}
      />
    </IconButton>
  );
};

export default BackButtonHub;
