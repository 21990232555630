import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomEditConfirm from '../../../../../../../../components/shared/CustomEditConfirm';
import { useEffect, useState } from 'react';
import {
  changeImagePreviewEdit,
  changeNameEdit,
  changePicChooseEdit,
  changePicPhoneEdit,
  changePicSearchDebounceEdit,
  changePicSearchEdit,
  changeStatus,
  changeSubsidiaryIdSubHub,
  changeVideoPreviewEdit,
  clickIsPreviewImageEdit,
  clickIsVideoPreviewEdit,
  deleteImage,
  deleteTayangan,
  fetchDetailDataSubHubEdit,
  getDetailDataSubHubEdit,
  getLoadingDetailSubHubEdit,
  getSubHubEditAll,
  inputImageEdit,
  inputSubHubImageEdit,
  inputTayangan,
} from '../../../../../../../../store/hub/subHub/subHubEdit';
import {
  changeIsEditSubHub,
  getIdSubHub,
} from '../../../../../../../../store/hub/subHub/subHubDetails';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import {
  AddIcon,
  CancelIcon,
  CloseIcon,
  ErrorOutlineIcon,
  EyeIcon,
  ImageOutlinedIcon,
  KeyboardArrowDownIcon,
  VideocamOutlinedIcon,
} from '../../../../../../../../assets/Icons';
import { getSubsidiary } from '../../../../../../../../api/subsidiary';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import { useRef } from 'react';
import debounce from 'lodash.debounce';
import {
  fetchPic,
  getPicList,
  getPicLoading,
} from '../../../../../../../../store/pic/pic';
import { Map } from 'pigeon-maps';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import CustomCard from '../../../../../../../../components/shared/CustomCard';
import CustomAntSwitch from '../../../../../../../../components/shared/CustomAntSwitch';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import { editStatusSubHub, editSubHub } from '../../../../../../../../api/hubs';
import SetupAddressSubHubEditMobile from './SetupAddressSubHubEditMobile';
import { changePageSubHub } from '../../../../../../../../store/Routing/hubs';

const EditSubHubMobile = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isEditConfirm, setIsEditConfirm] = useState(false);
  const loading = useSelector(getLoadingDetailSubHubEdit);
  const dataDetailsSubHub = useSelector(getDetailDataSubHubEdit);
  const picList = useSelector(getPicList);
  const picLoading = useSelector(getPicLoading);
  const [isMapOpen, setIsMapOpen] = useState(false);

  const {
    isLoadingUpdate,
    subsidiary,
    name,
    image,
    isMaxSizeImage,
    isUploadImage,
    imagePreview: linkImagePreview,
    isImagePreview,
    video,
    isMaxSizeVideo,
    isUploadVideo,
    videoPreview: linkVideoPreview,
    isVideoPreview,
    picChoose,
    picSearchDebounce,
    picSearch,
    picPhone,
    coordinate,
    address,
    coordinateFix,
    addressFix,
    subsidiaryId,
    mapInputType,
    placeGetByName,
  } = useSelector(getSubHubEditAll);
  const [subsidiaryDatas, setSubsidiaryDatas] = useState([]);
  const [isLoadingSubsidiary, setIsLoadingSubsidiary] = useState(false);
  const [newSubsidiary, setNewSubsidiary] = useState(subsidiary);
  const [statusHub, setStatusHub] = useState(false);
  const idSubHub = useSelector(getIdSubHub);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  useEffect(() => {
    dispatch(fetchDetailDataSubHubEdit(idSubHub));
  }, [idSubHub]);

  useEffect(() => {
    if (!loading) {
      if (dataDetailsSubHub.status === 'active') {
        return setStatusHub(true);
      } else {
        return setStatusHub(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    const getSubsidiaries = async () => {
      setIsLoadingSubsidiary(true);
      try {
        const {
          data: {
            data: { subsidiaries },
          },
        } = await getSubsidiary();
        setSubsidiaryDatas(subsidiaries);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingSubsidiary(false);
      }
    };

    getSubsidiaries();
  }, []);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounceEdit(value));
    }, 1000)
  ).current;

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  const handleEditStatus = () => {
    if (statusHub) {
      editStatusSubHub(idSubHub, { status: 'inactive' });
      setStatusHub(false);
    } else {
      editStatusSubHub(idSubHub, { status: 'active' });
      setStatusHub(true);
    }
  };

  const handleSave = () => {
    setIsEditConfirm(!isEditConfirm);
  };

  useEffect(() => {
    picChoose !== null
      ? dispatch(changePicPhoneEdit(picChoose?.telpon))
      : dispatch(changePicPhoneEdit(''));
  }, [picChoose]);

  const handlePic = (newValue) => {
    dispatch(changePicChooseEdit(newValue));
  };

  const handleSaveFix = () => {
    const { idKustomer } = picChoose;
    const dataToUpdate = {
      _method: 'PUT',
      name: name,
      subsidiaryId: subsidiaryId,
      idKustomer: idKustomer,
      images: image.length !== 0 ? image.join(',') : '',
      videos: video.length !== 0 ? video.join(',') : '',
      nohp: picPhone,
      address: addressFix === '' ? address : addressFix,
      lat: coordinateFix[0].toString(),
      lon: coordinateFix[1].toString(),
      typeInput: mapInputType,
      cityDistrict: placeGetByName?.formatted,
    };

    const UpdateSubHub = async () => {
      setLoadingUpdate(true);

      try {
        await editSubHub(idSubHub, dataToUpdate);
        setLoadingUpdate(false);
        dispatch(changeIsEditSubHub(false));
        dispatch(changePageSubHub('subHub'));
      } catch (err) {
        console.error(err);
      }
    };

    UpdateSubHub();
  };

  return (
    <>
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleSaveFix}
        title="Edit Sub Hub"
        body={
          <Box sx={{ textAlign: 'center', mb: '40px', mt: '8px' }}>
            <Typography>Are you sure you want to save the changes?</Typography>
          </Box>
        }
        isLoading={loadingUpdate}
      />

      {/* edit data */}
      <Box sx={{ position: 'relative' }}>
        <Grid container columns={12} spacing={2} sx={{ pb: 3 }}>
          {/*  Back Button */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                gap: 1.5,
                alignItems: 'center',
                mt: 2,
                mb: 2,
              }}
            >
              <BackButtonHub onClick={() => dispatch(changeIsEditSubHub())} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 600, color: '#000' }}
                >
                  Edit Master Hub
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <EyeIcon />
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 600, color: '#51B15C' }}
                  >
                    Preview
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* subsidiary */}
          <Grid item xs={12}>
            <Autocomplete
              options={subsidiaryDatas}
              value={newSubsidiary}
              getOptionLabel={(option) => option.name}
              loading={isLoadingSubsidiary}
              onChange={(_, newValue) => {
                setNewSubsidiary(newValue);
                dispatch(changeSubsidiaryIdSubHub(newValue?.id));
              }}
              renderInput={(params) => (
                <TextField
                  label="Subsidiary"
                  variant="filled"
                  {...params}
                  sx={{
                    bgcolor: '#fafafa',
                    borderRadius: '5px',
                    fontSize: '14px',
                    color: '#9D9D9D',
                  }}
                />
              )}
            />
          </Grid>
          {/*  Name */}
          <Grid item xs={12}>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter sub hub name"
              variant="filled"
              label="Name*"
              value={name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  dispatch(changeNameEdit(e.target.value));
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color:
                    name.length < 5 && name.length > 0 ? '#F33A31' : '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 5 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {name.length}/30
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pb: `${image.length === 0 ? '16px' : '9px'}`,
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                Photo
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#9E9D9D',
                }}
              >
                ( max size : 2MB, max file : 3 )
              </Typography>
              {isMaxSizeImage && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                      mr: '4px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                    }}
                  >
                    The image can't be more than 2MB
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {image.length === 0 && (
                  <Box
                    sx={{
                      height: '54px',
                      width: '54px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      bgcolor: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderStyle: 'dashed',
                      borderColor: '#9E9D9D',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('input_img').click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '28px' }}
                    />
                  </Box>
                )}
                {image.map((input, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      padding: '7px',
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: 'absolute',
                        fontSize: '20px',
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: 'pointer',
                      }}
                      onClick={() => dispatch(deleteImage(input))}
                    />
                    <Box
                      component="img"
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + input
                      }
                      sx={{
                        height: '54px',
                        width: '54px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        dispatch(clickIsPreviewImageEdit());
                        dispatch(changeImagePreviewEdit(input));
                      }}
                    />
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  mb: '7px',
                  ml: '20px',
                  alignItems: 'center',
                }}
              >
                {image.length < 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('input_img').click();
                    }}
                  >
                    <input
                      accept="image/*"
                      id="input_img"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => dispatch(inputSubHubImageEdit(e))}
                    />
                    {!isUploadImage ? (
                      <>
                        <AddIcon />
                        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                          Add More
                        </Typography>
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <CircularProgress size={16} />
                        <Typography> uploading...</Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/* Video */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pb: `${video.length === 0 ? '16px' : '9px'}`,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Video
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#9E9D9D',
                }}
              >
                ( max size : 100MB, max file : 3 )
              </Typography>
              {isMaxSizeVideo && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                      mr: '4px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '8px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                    }}
                  >
                    The video is too big
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {video.length === 0 && (
                  <Box
                    sx={{
                      height: '54px',
                      width: '54px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      bgcolor: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderStyle: 'dashed',
                      borderColor: '#9E9D9D',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputMenu_video').click();
                    }}
                  >
                    <VideocamOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '28px' }}
                    />
                  </Box>
                )}
                {video.map((input, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      padding: '7px',
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: 'absolute',
                        fontSize: '20px',
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: 'pointer',
                      }}
                      onClick={() => dispatch(deleteTayangan(input))}
                    />
                    <Box
                      onClick={() => {
                        dispatch(clickIsVideoPreviewEdit());
                        dispatch(changeVideoPreviewEdit(input));
                      }}
                    >
                      <video
                        style={{
                          maxHeight: '54px',
                          maxWidth: '54px',
                          aspectRatio: '1/1',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          backgroundColor: 'black',
                        }}
                      >
                        <source
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            '/' +
                            input +
                            '#t=0.5'
                          }
                        />
                      </video>
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  mb: '7px',
                  ml: '20px',
                  alignItems: 'center',
                }}
              >
                {video.length < 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputMenu_video').click();
                    }}
                  >
                    <input
                      // accept="video/*"
                      id="inputMenu_video"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => dispatch(inputTayangan(e))}
                    />
                    {!isUploadVideo ? (
                      <>
                        <AddIcon />
                        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                          Add More
                        </Typography>
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <CircularProgress size={16} />
                        <Typography> uploading...</Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/*  Pic */}
          <Grid item xs={12}>
            <Typography sx={{ mb: '8px', fontSize: '16px', fontWeight: '500' }}>
              PIC*
            </Typography>
            <Autocomplete
              options={picList}
              getOptionLabel={(option) => option.namaLengkap}
              value={picChoose}
              onChange={(_, newValue) => handlePic(newValue)}
              inputValue={picSearch}
              loading={picLoading}
              onInputChange={(_, newInputValue) => {
                dispatch(changePicSearchEdit(newInputValue));
                picSearchInDebounce(newInputValue);
              }}
              renderOption={(props, option) => (
                <Box key={option.idKustomer} {...props}>
                  <Box
                    sx={{
                      padding: '12px 12px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      gap: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                      {option.namaLengkap}
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                      {option.telpon}
                    </Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search "
                  sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                />
              )}
            />
          </Grid>
          {/*  Pic Number */}
          <Grid item xs={12}>
            <Typography sx={{ mb: '8px', fontSize: '16px', fontWeight: '500' }}>
              Phone Number*
            </Typography>
            <CustomInput
              disabled
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter phone number"
              type="number"
              value={picPhone}
              onChange={(e) => {
                dispatch(changePicPhoneEdit(e.target.value));
              }}
            />
          </Grid>
          {/* //* Address */}
          <Grid item xs={12} onClick={() => setIsMapOpen(!isMapOpen)}>
            <CustomCard
              sx={{ p: '8px 16px', cursor: 'pointer' }}
              // onClick={() => setIsMapOpen(!isMapOpen)}
            >
              <Box
                sx={{
                  display: 'center',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{
                      width: '66px',
                      height: '66px',
                      bgcolor: 'gray',
                      borderRadius: '8px',
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        bgcolor: 'white',
                        width: '100%',
                        height: '100%',
                        zIndex: '+2',
                        opacity: 0.5,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        maxHeight: '66px',
                        overflow: 'hidden',
                        borderRadius: '8px',
                      }}
                    >
                      <Map
                        defaultCenter={coordinate}
                        height={130}
                        defaultZoom={17}
                        width={66}
                        style={{
                          borderRadius: '8px',
                        }}
                      ></Map>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      sx={{ fontWeight: '500', mb: '4px', ml: '8px' }}
                    >
                      Address
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.4,
                      }}
                    >
                      <LocationOnIcon color="primary" />
                      <Typography>{address}</Typography>
                    </Box>
                  </Box>
                </Box>
                <KeyboardArrowDownIcon />
              </Box>
            </CustomCard>
          </Grid>
          {/* status */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Publish
              </Typography>
              <CustomAntSwitch
                checked={statusHub}
                onChange={handleEditStatus}
              />
            </Box>
          </Grid>
        </Grid>

        {/* button event */}
        <Box
          sx={{
            width: '100%',
            bgcolor: 'white',
            position: 'sticky',
            bottom: '0px',
            right: '0px',
            zIndex: 99,
          }}
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <CustomButton
              variant="contained"
              color="error"
              sx={{ width: '100%', height: '36px' }}
              // onClick={handleDelete}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '12px',
                  fontWeight: '500',
                }}
              >
                Delete
              </Typography>
            </CustomButton>
            <CustomButton
              variant="contained"
              sx={{ width: '100%', height: '36px' }}
              onClick={() => handleSave()}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '12px',
                  fontWeight: '500',
                }}
              >
                Save
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      </Box>

      {/* Dialog Photo */}
      <Dialog
        open={isImagePreview}
        onClose={() => {
          dispatch(clickIsPreviewImageEdit());
          dispatch(changeImagePreviewEdit(''));
        }}
      >
        <Box
          component="img"
          src={
            process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + linkImagePreview
          }
          sx={{ width: '300px', maxHeight: '600px', aspectRation: '1/1' }}
        />
      </Dialog>

      {/* Video Dialoge */}
      <Dialog
        open={isVideoPreview}
        onClose={() => {
          dispatch(clickIsVideoPreviewEdit());
          dispatch(changeVideoPreviewEdit(''));
        }}
      >
        <Box sx={{ bgcolor: 'black' }}>
          <video
            style={{
              width: '300px',
              maxHeight: '600px',
              backgroundColor: 'black',
            }}
            controls
          >
            <source
              src={
                process.env.REACT_APP_API_IMAGE_BASE_URL +
                '/' +
                linkVideoPreview
              }
            />
          </video>
        </Box>
      </Dialog>

      {/*  Dialog Address */}
      <Dialog
        open={isMapOpen}
        onClose={() => {
          setIsMapOpen(!isMapOpen);
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>Input Address</Box>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsMapOpen(false)}
          />
        </DialogTitle>
        <DialogContent dividers>
          <SetupAddressSubHubEditMobile setIsOpen={setIsMapOpen} />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default EditSubHubMobile;
