import { alereAxios } from '../utils/api';

export const createAdjustment = (params) =>
  alereAxios.post('adjustments', params);
export const updateAdjustment = (params, id) =>
  alereAxios.put(`adjustments/${id}`, params);
export const getAllDataAdjustment = (params) =>
  alereAxios.put(`adjustments/cek-adjusment?search=${params.search}`, params);
export const getDetailAdjustment = (id) => alereAxios.get(`adjustments/${id}`);
export const getDifferentProductAdjustment = (params) =>
  alereAxios.get('adjustments/different-product-list', { params });
export const getAuditMissingProductList = (params) =>
  alereAxios.get('adjustments/missing-product-list', { params });
export const getUsersAdjustment = () =>
  alereAxios.put('adjustments/cek-filter-adjustment');
export const cancelAdjustment = (id) =>
  alereAxios.post(`adjustments/cancel/${id}`);
export const acceptAdjustment = (id) =>
  alereAxios.post(`adjustments/accepted/${id}`);
export const deleteAdjustment = (id) => alereAxios.delete(`adjustments/${id}`);
