import { useDispatch, useSelector } from 'react-redux';
import {
  getAllAuditRouting,
  setCloseNotif,
} from '../../../../../../store/Routing/auditRouting';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';

const NotifAudit = () => {
  const dispatch = useDispatch();
  const { keyNotif, textNotif } = useSelector(getAllAuditRouting);

  const keys = ['addAudit', 'editAudit', 'deleteAudit', 'compareAudit'];

  return (
    <>
      {keys.map((key, index) => (
        <CustomNotifSuccess
          text={textNotif}
          open={keyNotif === key}
          onClose={() => {
            dispatch(setCloseNotif());
          }}
          key={index}
        />
      ))}
    </>
  );
};
export default NotifAudit;
