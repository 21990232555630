import React from 'react';
import { Box } from '@mui/material';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import { useEffect } from 'react';
import { getRackMasterHub } from '../../../../../../../../api/hubs';

const PreviewComponent = ({
  setIsCreateGridOpen,
  setIsSelectRacksOpen,
  setIsPreviewOpen,
  numberOfRows,
  numberOfColumns,
  masterHubData,
}) => {
  useEffect(() => {
    const getData = async () => {
      try {
        const {
          data: { data: dataRack },
        } = await getRackMasterHub(masterHubData.masterHubId);
      } catch {
      } finally {
      }
    };
    getData();
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Box>ss</Box>

      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '18px 32px 18px 240px',
          alignItems: 'center',
        }}
      >
        <CustomButton
          sx={{
            bgcolor: 'primary.contrastText',
            color: 'primary.main',
            border: '1px solid #51B15C',
            '&:hover': {
              bgcolor: 'primary.contrastText',
              color: 'primary.main',
              border: '1px solid #51B15C',
            },
            fontWeight: 600,
            fontSize: '0.875rem',
            px: 6,
            py: 1,
          }}
          onClick={() => {
            setIsCreateGridOpen(false);
            setIsSelectRacksOpen(true);
            setIsPreviewOpen(false);
          }}
        >
          Back
        </CustomButton>
      </Box>
    </Box>
  );
};

export default PreviewComponent;
