import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import BackButtonDetailPurchase from '../PurchaseDetailFinance/BackButtonDetailFinance';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePage,
  getAllPurchaseRouting,
  setNotifPurchase,
} from '../../../../../../store/Routing/purchaseRouting';
import {
  getPurchaseRequestDetail,
  rejectPurchaseRequest,
  updatePurchaseRequest,
} from '../../../../../../api/purchase';
import LineDiv from '../../../../../../components/shared/LineDiv';
import CustomCard from '../../../../../../components/shared/CustomCard';
import dayjs from 'dayjs';
import BottomMenu from '../../../../../../components/shared/BottomMenu';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import BackButtonPurchaseDetailFInanceSuppllierBid from './BackButtonPurchaseDetailFInanceSuppllierBid';
import { FillText } from '../../../../../../components/shared/shared2';
import CustomInputWithMin from '../../../../../../components/shared/CustomInputWithMin';

const PurchaseDetailFinanceSupplierBid = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { idPurchase, statusPurchase } = useSelector(getAllPurchaseRouting);
  const [isRejectConfirm, setIsRejectConfirm] = useState(false);
  const [datas, setDatas] = useState(null);

  const fetchApi = async (id) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getPurchaseRequestDetail(id);
      setDatas(data);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchReject = async (id) => {
    setIsLoading(true);
    let params = {
      status: 'reject',
    };
    try {
      await rejectPurchaseRequest(id, params);
      dispatch(
        setNotifPurchase({
          key: 'rejectPurchaseBySupplier',
          text: 'Purchase has been success rejected',
        })
      );
      dispatch(changePage(''));
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApi(idPurchase);
  }, [idPurchase]);

  const fetchUpdatePurchase = async (id, data) => {
    setIsLoading(true);
    try {
      await updatePurchaseRequest(id, data);
      dispatch(
        setNotifPurchase({
          key: 'purchaseProcess',
          text: 'purchase has been success processed',
        })
      );
      dispatch(changePage(''));
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceFix, setTotalPriceFix] = useState(0);

  useEffect(() => {
    const totalss = datas?.details.reduce((accum, next) => {
      return next.discountFormat !== 'percentage'
        ? accum +
            Number(next.purchasePrice) * Number(next.qty) -
            Number(next.discount)
        : accum +
            Number(next.purchasePrice * next.qty) -
            Number((next.discount / 100) * next.purchasePrice * next.qty);
    }, 0);

    setTotalPrice(totalss);
  }, [datas]);

  useEffect(() => {
    if (datas?.discount) {
      if (datas?.discountFormat === 'currency') {
        setTotalPriceFix(totalPrice - datas?.discount);
      } else {
        const disc = (totalPrice / 100) * datas?.discount;
        setTotalPriceFix(totalPrice - disc);
      }
    }
  }, [datas, totalPrice]);

  const handleApprove = () => {
    const collectProduct = datas.details.map((list) => {
      return {
        idProduk: list.idProduk,
        purchasePrice: list.purchasePrice,
        qty: list.qty,
        unit: list.unit,
        discount: list?.discount,
        discountFormat: list?.discountFormat,
      };
    });
    const collect = {
      nomorfaktur: datas?.nomorfaktur || 'asdasd',
      tgglfaktur: dayjs(datas?.tgglfaktur).format('YYYY-MM-DD'),
      products: collectProduct,
      idToko: datas.idToko,
      idSuppliers: datas.isSuppliers,
      subsidiaryId: datas.subsidiaryId,
      idtransaksi: datas.idtransaksi,
      subHubId: null,
      miniHubId: null,
      keterangan: datas.keterangan,
      discount: datas.discount,
      discountFormat: datas.discountFormat,
      // status: 'processed',
    };

    fetchUpdatePurchase(idPurchase, collect);
  };
  const handleReject = () => {
    setIsRejectConfirm(true);
  };

  const handleRejectFix = () => {
    fetchReject(idPurchase);
    setIsRejectConfirm(false);
  };

  const desktopMode = useMediaQuery('(min-width:832px)');

  return (
    <Box sx={{ mt: '16px' }}>
      {!isLoading && !isError && (
        <>
          <CustomDeleteConfirm
            open={isRejectConfirm}
            onClose={() => setIsRejectConfirm(!isRejectConfirm)}
            handleClickCancel={() => setIsRejectConfirm(!isRejectConfirm)}
            handleClickDelete={handleRejectFix}
            title="Reject Purchase"
            body={
              <Box sx={{ textAlign: 'center' }}>
                <Typography>
                  Are you sure you want to reject "
                  <Typography component="span" sx={{ fontWeight: '600' }}>
                    This Purchase
                  </Typography>
                  " ?
                </Typography>
                <Typography sx={{ mb: '52px' }}>
                  You can’t undo this action.
                </Typography>
              </Box>
            }
            redText="Reject"
          />
          <BackButtonPurchaseDetailFInanceSuppllierBid />
          {desktopMode && (
            <Grid
              container
              columns={6}
              spacing={4}
              sx={{ mt: '16px', pb: '54px' }}
            >
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Invoice ID*
                </Typography>
                <LineDiv value={datas?.nomorfaktur || '-'} />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Date*
                </Typography>
                <LineDiv
                  value={
                    datas?.tgglfaktur
                      ? dayjs(datas?.tgglfaktur).format('DD/MMM/YY')
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Discount
                </Typography>
                {datas?.discount && datas?.discountFormat === 'percentage' && (
                  <LineDiv value={`${datas?.discount} %`} />
                )}

                {datas?.discount && datas?.discountFormat === 'currency' && (
                  <LineDiv value={`Rp. ${datas?.discount}`} />
                )}
                {!datas?.discount && <LineDiv value={`-`} />}
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Supplier*
                </Typography>
                <LineDiv value={datas?.suppliers?.namaLengkap || '-'} />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Payment Method*
                </Typography>
                <LineDiv value={datas?.paymentMethod?.ket || '-'} />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Description
                </Typography>
                <LineDiv value={datas?.keterangan || '-'} />
              </Grid>
              <Grid item xs={6}>
                <CustomCard>
                  <CustomCard sx={{ p: '20px' }}>
                    <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                      Item(s)
                    </Typography>
                  </CustomCard>
                  <Grid container columns={1} rowSpacing={4} sx={{ p: '20px' }}>
                    {datas?.details.map((list, idx) => {
                      return (
                        <Grid item xs={1} key={idx}>
                          <Grid container columns={6} columnSpacing={4}>
                            <Grid item xs={2}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                }}
                              >
                                Product
                              </Typography>
                              <LineDiv value={list?.name || '-'} />
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                  display: 'flex',
                                  gap: 0.5,
                                }}
                              >
                                <Typography>Qty </Typography>
                                <Typography sx={{ color: '#51B15C' }}>
                                  (Pcs)
                                </Typography>
                              </Box>
                              <LineDiv value={list?.qty || '-'} />
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                  display: 'flex',
                                  gap: 0.5,
                                }}
                              >
                                <Typography>Disc </Typography>
                                <Typography sx={{ color: '#51B15C' }}>
                                  (
                                  {list?.discountFormat === 'percentage'
                                    ? '%'
                                    : 'Rp'}
                                  )
                                </Typography>
                              </Box>
                              <LineDiv value={list?.discount || '-'} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                }}
                              >
                                Price*
                              </Typography>
                              <LineDiv value={list.purchasePrice} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                }}
                              >
                                Subtotal*
                              </Typography>
                              <LineDiv
                                value={`Rp. ${
                                  list?.discountFormat !== 'percentage'
                                    ? Number(list?.purchasePrice) *
                                        Number(list?.qty) -
                                      Number(list?.discount)
                                    : Number(list?.purchasePrice) *
                                        Number(list?.qty) -
                                      Number(list?.discount / 100) *
                                        Number(list?.purchasePrice * list?.qty)
                                }`}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: '20px',
                    }}
                  >
                    <Typography sx={{ fontWeight: '600', fontSize: '20px' }}>
                      Grand Total
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      {datas?.discount ? (
                        <>
                          <Typography
                            sx={{
                              fontWeight: '600',
                              fontSize: '14px',
                              color: '#9E9D9D',
                              textDecoration: 'line-through',
                            }}
                          >
                            Rp. {totalPrice || '-'}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: '600', fontSize: '20px' }}
                          >
                            Rp. {totalPriceFix || '-'}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          sx={{ fontWeight: '600', fontSize: '20px' }}
                        >
                          Rp. {totalPrice || '-'}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </CustomCard>
              </Grid>
            </Grid>
          )}
          {!desktopMode && (
            <Grid
              container
              columns={7}
              spacing={2}
              sx={{ mt: '16px', pb: '54px' }}
            >
              <Grid item xs={7}>
                <FillText
                  title={'Invoice ID*'}
                  value={datas?.nomorfaktur || '-'}
                />
              </Grid>
              <Grid item xs={4}>
                <FillText
                  title={'Date*'}
                  value={
                    datas?.tgglfaktur
                      ? dayjs(datas?.tgglfaktur).format('DD/MMM/YY')
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={3}>
                {datas?.discount && datas?.discountFormat === 'percentage' && (
                  <FillText title={'Disc'} value={`${datas?.discount} %`} />
                )}

                {datas?.discount && datas?.discountFormat === 'currency' && (
                  <FillText title={'Disc'} value={`Rp. ${datas?.discount}`} />
                )}
                {!datas?.discount && <FillText title={'Disc'} value={`-`} />}
              </Grid>
              <Grid item xs={7}>
                <FillText
                  title={'Supplier'}
                  value={datas?.suppliers?.namaLengkap || '-'}
                />
              </Grid>
              <Grid item xs={7}>
                <FillText
                  title={'Payment Method'}
                  value={datas?.paymentMethod?.ket || '-'}
                />
              </Grid>
              <Grid item xs={7}>
                <FillText
                  title={'Description'}
                  value={datas?.keterangan || '-'}
                />
              </Grid>
              <Grid item xs={7}>
                <CustomCard>
                  <CustomCard sx={{ p: '16px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
                      Item(s)
                    </Typography>
                  </CustomCard>
                  <Grid
                    container
                    columns={1}
                    rowSpacing={4}
                    sx={{ p: '20px 16px' }}
                  >
                    {datas?.details.map((list, idx) => {
                      return (
                        <Grid item xs={1} key={idx}>
                          <Grid
                            container
                            columns={6}
                            columnSpacing={2}
                            rowSpacing={2}
                          >
                            <Grid item xs={4}>
                              <FillText
                                title={'Product'}
                                value={list?.name || '-'}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <FillText
                                title={'Qty'}
                                value={list?.qty || '-'}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <FillText
                                title={'Price'}
                                value={list.purchasePrice}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <FillText
                                title={'Subtotal'}
                                value={`Rp. ${
                                  Number(list?.purchasePrice) *
                                  Number(list?.qty)
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: '16px' }} />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: '16px',
                    }}
                  >
                    <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>
                      Grand Total
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      {datas?.discount ? (
                        <>
                          <Typography
                            sx={{
                              fontWeight: '600',
                              fontSize: '12px',
                              color: '#9E9D9D',
                              textDecoration: 'line-through',
                            }}
                          >
                            Rp. {totalPrice || '-'}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: '600', fontSize: '16px' }}
                          >
                            Rp. {totalPriceFix || '-'}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          sx={{ fontWeight: '600', fontSize: '16px' }}
                        >
                          Rp. {totalPrice || '-'}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </CustomCard>
              </Grid>
            </Grid>
          )}
          <BottomMenu
            isRed={true}
            isGreen={true}
            greenText="Process"
            redText="Reject"
            handleGreen={handleApprove}
            handleRed={handleReject}
          />
        </>
      )}
    </Box>
  );
};
export default PurchaseDetailFinanceSupplierBid;
