import { Box, Divider, Grid, Typography } from '@mui/material';
import { getAllAdminScan } from '../../../../../../../store/purchase/purchaseAdmin/purchaseProcessedAdmin';
import { useSelector } from 'react-redux';
import { NoImage } from '../../../../../../../assets/Icons';

const ScannedDataAdmin = () => {
  const { isExist, scanned, allData } = useSelector(getAllAdminScan);

  return (
    <Grid container columns={12}>
      {scanned.map((list, index) => (
        <Grid item xs={12} key={index}>
          <Box
            sx={{
              p: '14px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              {list?.gambar ? (
                <Box
                  sx={{
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#F5F5F5',
                    width: '45px',
                    height: '45px',
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_API_IMAGE_BASE_URL +
                      '/' +
                      list?.gambar
                    }
                    style={{
                      width: '45px',
                      height: '45px',
                      borderRadius: '8px',
                    }}
                    alt="gbr"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#F5F5F5',
                    width: '45px',
                    height: '45px',
                  }}
                >
                  <NoImage />
                </Box>
              )}
              <Box>
                <Typography sx={{ fontSize: '12px' }}>
                  {list?.namaProduk}
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '600', mt: '4px' }}
                >
                  1 {list?.unit}
                </Typography>
              </Box>
            </Box>
            <Box sx={{}}>
              {/* <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                Rp.1.000.000
              </Typography> */}
            </Box>
          </Box>
          <Divider />
        </Grid>
      ))}
    </Grid>
  );
};
export default ScannedDataAdmin;
