import { Box, Grid, useMediaQuery } from '@mui/material';
import Subsidiary from '../../../../../components/shared/Subsidiary';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import TableListAdjustment from './TableListAdjustment';
import DashboardAjustmentMobile from './AdjustmentMobile';

const InventoryAdjusment = () => {
  const dispatch = useDispatch();
  const matchTablet = useMediaQuery('(min-width : 832px)');
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);

  // ! grafik & table

  const [timeFrame, setTimeFrame] = useState('daily');

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);
  return !matchTablet ? (
    <DashboardAjustmentMobile />
  ) : (
    <Box sx={{ mt: 2 }}>
      <Grid container columns={12} spacing={2}>
        {/* subsidiary */}
        <Grid item xs={12}>
          <Subsidiary
            subsidiaryChecked={filterSubsidiaryArray}
            setSubsidiaryChecked={setFilterSubsidiaryArray}
          />
        </Grid>

        {/* list data table */}
        <Grid item xs={12}>
          <TableListAdjustment />
        </Grid>
      </Grid>
    </Box>
  );
};
export default InventoryAdjusment;
