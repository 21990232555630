import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import {
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
  ColumnFilterIcon,
  ExportIcon,
  FilterAltIcon,
  SearchIcon,
  SyncAltIcon,
} from '../../../../../assets/Icons';
import { useState } from 'react';
import { useRef } from 'react';
import debounce from 'lodash.debounce';
import CustomCard from '../../../../../components/shared/CustomCard';
import CustomMenu from '../../../../../components/shared/CustomMenu';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeSortAsc,
  getAllDataTransaction,
  getTransactionTable,
  getTransactionTableIsLoading,
  getTransactionTableSortAsc,
  getTransactionTableSortIn,
  getTransactionTableTotalItem,
} from '../../../../../store/transaction/dataTable';
import { useEffect } from 'react';
import DialogFilterTransaction from './DialogFilter';
import {
  getDataFilterFrom,
  getDataFilterTo,
  getDataHubTransaction,
  getDataMiniHubTransaction,
  getDataSubHubTransaction,
} from '../../../../../store/transaction/request/dataTableRequest';

const color = {
  accepted: '#51B15C',
  sent: '#FD7900',
};

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
});

const TableTransaction = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoading = useSelector(getTransactionTableIsLoading);
  const tableDatas = useSelector(getTransactionTable);
  const from = useSelector(getDataFilterFrom);
  const to = useSelector(getDataFilterTo);

  // !  search
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setTransactionKeyword] = useState('');

  const debounceOnChange = useRef(
    debounce((value) => {
      setTransactionKeyword(value);
    }, 1000)
  ).current;

  const pageSizeList = [5, 10, 15, 20, 25];
  const [pageSizeTable, setPageSizeTable] = useState(pageSizeList[0]);
  const [currentPageTable, setCurrentPageTable] = useState(1);
  const totalItemTable = useSelector(getTransactionTableTotalItem);

  //! filter adjust
  const [filterAdjust, setFilterAdjust] = useState(null);
  const isFilterAdjust = Boolean(filterAdjust);
  const [filterAdjustSelected, setFilterAdjustSelected] = useState([
    'sku',
    'from',
    'to',
    'status',
    'date',
  ]);

  // ! sorting
  const sortInTable = useSelector(getTransactionTableSortIn);
  const sortAscTable = useSelector(getTransactionTableSortAsc);

  const [sortTable, setSortTable] = useState('');
  const [asc, setAsc] = useState('asc');

  // ! ------------------  API -------------
  useEffect(() => {
    let datas = {};
    datas.page = currentPageTable;
    datas.pageSize = Number(pageSizeTable);
    datas.search = purchaseKeyword;
    datas.sku_sort = sortTable !== 'sortSku' ? 'asc' : asc;
    datas.from_sort = sortTable !== 'sortFrom' ? 'asc' : asc;
    datas.to_sort = sortTable !== 'sortTo' ? 'asc' : asc;
    datas.sort_status = sortTable !== 'sortStatus' ? 'asc' : asc;
    datas.date_sort = sortTable !== 'sortDate' ? 'asc' : asc;
    datas.from = from;
    datas.to = to;
    datas.status = ['sent', 'remaining'];
    dispatch(getAllDataTransaction(datas));
  }, [
    currentPageTable,
    pageSizeTable,
    purchaseKeyword,
    sortTable,
    asc,
    from,
    to,
  ]);

  // !----------------- Handler --------------------------

  const handlePageSize = (e) => {
    setPageSizeTable(e.target.value);
  };

  const handleForwardArrow = () => {
    setCurrentPageTable(
      currentPageTable >= Math.ceil(totalItemTable / pageSizeTable)
        ? currentPageTable
        : currentPageTable + 1
    );
  };

  const handleBackArrow = () => {
    setCurrentPageTable(
      currentPageTable <= 1 ? currentPageTable : currentPageTable - 1
    );
  };

  const handleInputCurrentPage = (event) => {
    if (event.target.value >= Math.ceil(totalItemTable / pageSizeTable)) {
      setCurrentPageTable(Math.ceil(totalItemTable / pageSizeTable));
    } else if (event.target.value <= 1) {
      setCurrentPageTable(1);
    } else {
      setCurrentPageTable(event.target.value);
    }
  };

  //! -------------- TOOLBAR -------------------
  const [isFilter, setIsFilter] = useState(false);
  const toolbar = (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row-reverse' }}>
      <IconButton
        sx={{
          border: '1.5px solid #51B15C',
          borderRadius: '8px',
          p: 1,
        }}
        onClick={(e) => setFilterAdjust(e.currentTarget)}
        aria-haspopup="true"
      >
        <ColumnFilterIcon fill="#51B15C" />
      </IconButton>
      <CustomMenu
        open={isFilterAdjust}
        openMenu={filterAdjust}
        setOpenMenu={setFilterAdjust}
        sx={{
          '.MuiMenuItem-root': {
            paddingLeft: 0,
            paddingRight: '20px',
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', p: '12px' }}
        >
          <Typography sx={{ fontWeight: '400', fontSize: '12px' }}>
            Max 7
          </Typography>
          <Typography
            color="primary"
            sx={{
              fontWeight: '600',
              fontSize: '12px',
              cursor: 'pointer',
            }}
            onClick={() =>
              setFilterAdjustSelected([
                'sku',
                'date',
                'from',
                'to',
                'status',
                'date',
              ])
            }
          >
            Reset
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem>
          <Checkbox checked={true} />
          ID*
        </MenuItem>
        <MenuItem>
          <Checkbox checked={true} />
          SKU
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('from')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'from')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'from']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('from')} />
          From
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('to')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'to')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'to']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('to')} />
          To
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('status')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'status')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'status']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('status')} />
          Status
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('date')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'date')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'date']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('date')} />
          Date
        </MenuItem>
      </CustomMenu>
      {/* export */}
      <CustomCard
        sx={{
          p: '10px 20px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <ExportIcon sx={{ fontSize: '20px' }} />
        <Typography
          sx={{ fontSize: '14px', fontWeight: 500, color: '#000000' }}
        >
          Export
        </Typography>
      </CustomCard>
      {/* //* Filter */}
      <CustomCard
        sx={{
          p: '10px 20px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ fontSize: '20px' }} />
        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}>
          Filter
        </Typography>
      </CustomCard>
      <DialogFilterTransaction {...{ isFilter, setIsFilter }} />
    </Box>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Grid container columnSpacing={4} alignItems="center">
        <Grid item xs>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
              Transaction List
            </Typography>
            <TextField
              placeholder={'Search'}
              size="small"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                debounceOnChange(e.target.value);
              }}
              sx={{
                width: '250px',
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '6px',
                  fontSize: '0.875rem',
                },
                bgcolor: '#FAFAFA',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ width: '18px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid item xs="auto">
          {toolbar}
        </Grid>
      </Grid>

      {/* fetch data */}
      <Table sx={{ mt: 1 }}>
        <TableHead>
          <TableRow sx={{ bgcolor: '#FAFAFA' }}>
            <CustomTableCells>No.</CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                // onClick={() => {
                //   setSortTable('sortInvoice');
                //   dispatch(changeSortAsc(!sortAscTable));
                //   if (sortAscTable === true) {
                //     setAsc('asc');
                //   } else {
                //     setAsc('desc');
                //   }
                // }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  ID
                </Typography>
                {/* {sortTable === 'sortInvoice' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )} */}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortAmount');
                  dispatch(changeSortAsc(!sortAscTable));
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  SKU
                </Typography>
                {sortTable === 'sortAmount' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            {filterAdjustSelected.includes('from') && (
              <CustomTableCells>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('sortFrom');
                    dispatch(changeSortAsc(!sortAscTable));
                    if (sortAscTable === true) {
                      setAsc('asc');
                    } else {
                      setAsc('desc');
                    }
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    From
                  </Typography>
                  {sortTable === 'sortFrom' ? (
                    sortAscTable ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {filterAdjustSelected.includes('to') && (
              <CustomTableCells>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('sortTo');
                    dispatch(changeSortAsc(!sortAscTable));
                    if (sortAscTable === true) {
                      setAsc('asc');
                    } else {
                      setAsc('desc');
                    }
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    To
                  </Typography>
                  {sortTable === 'sortTo' ? (
                    sortAscTable ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {filterAdjustSelected.includes('status') && (
              <CustomTableCells>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('sortStatus');
                    dispatch(changeSortAsc(!sortAscTable));
                    if (sortAscTable === true) {
                      setAsc('asc');
                    } else {
                      setAsc('desc');
                    }
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    Status
                  </Typography>
                  {sortTable === 'sortStatus' ? (
                    sortAscTable ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {filterAdjustSelected.includes('date') && (
              <CustomTableCells>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('sortDate');
                    dispatch(changeSortAsc(!sortAscTable));
                    if (sortAscTable === true) {
                      setAsc('asc');
                    } else {
                      setAsc('desc');
                    }
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    Date
                  </Typography>
                  {sortTable === 'sortDate' ? (
                    sortAscTable ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
          </TableRow>
        </TableHead>

        {!isLoading && (
          <TableBody>
            {tableDatas?.map((tableData, index) => (
              <TableRow
                key={index}
                sx={{ cursor: 'pointer' }}
                // onClick={() => {
                //   handleClickRow(
                //     tableData.iDpurchase,
                //     tableData.subsidary,
                //     tableData.location,
                //     tableData.status
                //   );
                // }}
              >
                <CustomTableCells sx={{ width: '40px' }}>
                  {(currentPageTable - 1) * pageSizeTable + (index + 1)}
                </CustomTableCells>
                <CustomTableCells>
                  TR {tableData.idOrders ? tableData.idOrders : '-'}
                </CustomTableCells>
                <CustomTableCells>
                  {tableData.sku ? tableData.sku : '-'}
                </CustomTableCells>
                {filterAdjustSelected.includes('from') && (
                  <CustomTableCells>
                    {tableData.lokasiPemberi ? tableData.lokasiPemberi : '-'}
                  </CustomTableCells>
                )}
                {filterAdjustSelected.includes('to') && (
                  <CustomTableCells>
                    {tableData.lokasiTujuan ? tableData.lokasiTujuan : '-'}
                  </CustomTableCells>
                )}
                {filterAdjustSelected.includes('status') && (
                  <CustomTableCells
                    sx={{
                      color:
                        tableData.status === 'accepted'
                          ? color.pending
                          : tableData.status === 'sent'
                          ? color.rejected
                          : 'balck',
                    }}
                  >
                    {tableData.statusOrder ? `${tableData.statusOrder}` : '-'}
                  </CustomTableCells>
                )}
                {filterAdjustSelected.includes('date') && (
                  <CustomTableCells>
                    {tableData.tglOrder
                      ? dayjs(tableData.tglOrder).format('DD/MMM/YY')
                      : '-'}
                  </CustomTableCells>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>

      {/* loading data */}
      {isLoading && (
        <Box
          sx={{
            height: `${50 * pageSizeTable}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {!isLoading && tableDatas.length === 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            p: 4,
            mt: 1,
            background: 'rgba(243, 58, 58, 0.03)',
          }}
        >
          <Typography sx={{ color: '#F33A3A' }}>No data found</Typography>
        </Box>
      )}

      {/* pagination */}
      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: '10px' }}
      >
        <Grid item xs={6} sx={{ fontSize: '14px', fontWeight: '500' }}>
          Showing {currentPageTable * pageSizeTable - (pageSizeTable - 1)} to{' '}
          {currentPageTable * pageSizeTable >= totalItemTable
            ? totalItemTable
            : currentPageTable * pageSizeTable}{' '}
          of {totalItemTable} entries
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ fontSize: '14px', fontWeight: '500' }}>
            Rows Per Page :
            <Select
              value={pageSizeTable}
              variant="filled"
              onChange={handlePageSize}
              sx={{
                fontSize: '14px',
                lineHeight: '-1px',
                '.MuiFilledInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                '&:before': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&:after': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                borderRadius: '5px',
                marginLeft: '8px',
              }}
            >
              {pageSizeList.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sx={{ ml: '35px' }}>
            <Box sx={{ display: 'inline', mr: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleBackArrow}>
                <ArrowBackIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
              <Box sx={{ display: 'inline', mr: '20px' }}>
                <OutlinedInput
                  variant="outlined"
                  type="number"
                  value={currentPageTable}
                  onChange={handleInputCurrentPage}
                  sx={{
                    border: '1px solid #9E9D9D',
                    width: '36px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    '& .MuiOutlinedInput-input': {
                      padding: '5px 8px 5px 8px',
                      fontSize: '0.875rem',
                      boxShadow: 'none',
                      textAlign: 'center',
                      fontWeight: 600,
                      width: '25px',
                    },
                    '&:before': {
                      borderRadius: '5px',
                      borderBottom: 0,
                    },
                    '&:after': {
                      borderRadius: '5px',
                      borderBottom: 0,
                    },
                    '&.Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        boxShadow: 'none',
                      },
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'inline',
                  fontWeight: '600',
                  fontSize: '14px',
                  color: '#9E9D9D',
                }}
              >
                of {Math.ceil(totalItemTable / pageSizeTable)}
              </Box>
              <Box sx={{ display: 'inline', ml: '10px' }}>
                <IconButton sx={{ p: 0 }} onClick={handleForwardArrow}>
                  <ArrowForwardIosIcon
                    sx={{ color: '#323232', width: '16px' }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TableTransaction;
