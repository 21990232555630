import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ChevronRightIcon, StockAlert } from '../../../../../../assets/Icons';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import { changePageAudit } from '../../../../../../store/Routing/auditRouting';
import { useDispatch, useSelector } from 'react-redux';
import CustomCard from '../../../../../../components/shared/CustomCard';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useEffect, useState } from 'react';
import { getIdDetailAudit } from '../../../../../../store/audit/detailAudit';
import { getMissingQrCodeGroup } from '../../../../../../api/audit';
import TableListMissingQrCode from './TableListMissingQrCode';
import MissingQrCodeMobile from '../AuditMobile/MissingQrCode/MissingQrCodeMobile';
// import 'react-horizontal-scrolling-menu/dist/styles.css';
const MissingQrCode = ({ setValue }) => {
  const matchTablet = useMediaQuery('(min-width:832px');
  const dispatch = useDispatch();
  const idAudit = useSelector(getIdDetailAudit);
  const [dataMissingQr, setDataMissingQr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // ! --------------------------------- API --------------------

  const fetchMissingQrCodeGroup = async (id) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getMissingQrCodeGroup(id);
      setDataMissingQr(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMissingQrCodeGroup(idAudit);
  }, [idAudit]);

  return (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      {!matchTablet ? (
        <MissingQrCodeMobile {...{ setValue }} />
      ) : (
        <>
          <Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <BackButtonHub
                onClick={() => dispatch(changePageAudit('detailsAudits'))}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography
                  sx={{
                    color: '#9E9D9D',
                    fontSize: '24px',
                    fontWeight: 600,
                  }}
                >
                  ( id ) Details
                </Typography>

                <ChevronRightIcon sx={{ width: '24px', color: '#9E9D9D' }} />
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '24px',
                    fontWeight: 600,
                  }}
                >
                  Missing Qr Code
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* Main */}
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12}>
              <CustomCard
                sx={{
                  background: '#FAFAFA',
                  p: 2,
                  mt: 2,
                  width: `85vw`,
                }}
              >
                <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                  Missing Product
                </Typography>
                {/* slider */}
                <Box
                  sx={{
                    display: 'flex',
                    overflow: 'auto',
                    position: 'relative',
                    pb: 2,
                  }}
                >
                  {isLoading ? (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ScrollMenu>
                      {dataMissingQr?.map((item, idx) => (
                        <Box
                          sx={{
                            width: '320px',
                            height: '48px',
                            display: 'flex',
                            borderRight: '1px solid #9E9D9D',
                            gap: 2,
                            alignItems: 'center',
                            mt: 3,
                            pr: 2,
                            marginRight: '12px',
                          }}
                        >
                          <Box
                            component="img"
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              '/' +
                              item?.gambar
                            }
                            sx={{
                              minWidth: '48px',
                              width: '48px',
                              height: '48px',
                              borderRadius: '8px',
                            }}
                          />
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                height: '48px',
                                display: 'flex',
                                flexFlow: 'column',
                                justifyContent: 'space-around',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  color: '#000000',
                                }}
                              >
                                {item?.namaProduk}
                              </Typography>
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    color: '#9E9D9D',
                                  }}
                                >
                                  {item?.hub}
                                </Typography>
                                <ChevronRightIcon
                                  sx={{ fontSize: '16px', color: '#9E9D9D' }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    color: '#9E9D9D',
                                  }}
                                >
                                  {item?.rackName}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#000000',
                              }}
                            >
                              {item?.jumlah} {item?.systemUnit}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </ScrollMenu>
                  )}
                </Box>
              </CustomCard> 
            </Grid>

            {/* list qr */}
            <Grid item xs={12}>
              <TableListMissingQrCode setValue={setValue} />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
export default MissingQrCode;
