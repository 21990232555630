import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { CloseIcon } from '../../../../assets/Icons';

const CustomCard2 = ({ children, title, onClose, open, ...props }) => {
  return (
    <Dialog onClose={onClose} open={open} {...props}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>{title}</Typography>
          <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
        </Box>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};
export default CustomCard2;
