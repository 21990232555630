import { Box, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import BackButtonPurchaseProcessDetailSupplier from './BackButtonPurchaseProcessDetailSupplier';
import {
  FillText,
  LoadingComponent,
} from '../../../../../../components/shared/shared2';
import { getPurchaseRequestDetail } from '../../../../../../api/purchase';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPurchaseRouting } from '../../../../../../store/Routing/purchaseRouting';
import dayjs from 'dayjs';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {
  NoImageIcon,
  QrCodeIcon,
  QrCodeIconWhite,
} from '../../../../../../assets/Icons';
import NetworkProcess from './NetworkProcess';
import { changePage } from '../../../../../../store/Routing/purchaseRouting';
import {
  fetchAllDataPurchaseScan,
  getAllSupplierScan,
  setDetailPurchase,
} from '../../../../../../store/purchase/purchaseSupplier/purchaseSubmitSupplier';

const PurchaseProcessDetailSupplier = () => {
  const dispatch = useDispatch();
  const { idPurchase, statusPurchase } = useSelector(getAllPurchaseRouting);

  useEffect(() => {}, []);

  useEffect(() => {
    dispatch(fetchAllDataPurchaseScan(idPurchase));
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [datas, setDatas] = useState(null);

  const fetchApi = async (id) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getPurchaseRequestDetail(id);
      setDatas(data);
      dispatch(setDetailPurchase(data));
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApi(idPurchase);
  }, [idPurchase]);

  const handleToScan = () => {
    if (statusPurchase === 'processed') {
      dispatch(changePage('purchaseProcessScanSupplier'));
    }
  };

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceFix, setTotalPriceFix] = useState(0);

  useEffect(() => {
    const totalss = datas?.details.reduce((accum, next) => {
      return next.discountFormat !== 'percentage'
        ? accum +
            Number(next.purchasePrice) * Number(next.qty) -
            Number(next.discount)
        : accum +
            Number(next.purchasePrice * next.qty) -
            Number((next.discount / 100) * next.purchasePrice * next.qty);
    }, 0);

    setTotalPrice(totalss);
  }, [datas]);

  useEffect(() => {
    if (datas?.discount) {
      if (datas.discountFormat === 'currency') {
        setTotalPriceFix(totalPrice - datas.discount);
      } else {
        const disc = (totalPrice / 100) * datas.discount;
        setTotalPriceFix(totalPrice - disc);
      }
    }
  }, [datas, totalPrice]);

  return (
    <Box sx={{ mt: '16px' }}>
      {!isLoading && !isError && (
        <>
          <BackButtonPurchaseProcessDetailSupplier />
          <Grid
            container
            columns={1}
            spacing={2}
            sx={{ mt: '8px', pb: '54px' }}
          >
            <Grid item xs={1}>
              <FillText
                title={'Invoice ID'}
                value={datas?.nomorfaktur || '-'}
              />
            </Grid>
            <Grid item xs={1}>
              <FillText
                title={'Date'}
                value={
                  datas?.tgglfaktur
                    ? dayjs(datas?.tgglfaktur).format('DD/MMM/YY')
                    : '-'
                }
              />
            </Grid>
            <Grid item xs={1}>
              <FillText
                title={'Supplier'}
                value={datas?.supplier?.namaLengkap || '-'}
              />
            </Grid>
            <Grid item xs={1}>
              <FillText
                title={'Description'}
                value={datas?.keterangan || '-'}
              />
            </Grid>
            {/* //* Scan n Status Header */}
            <Grid item xs={1}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography>Status</Typography>
                <CustomButton
                  variant="contained"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 0.8,
                    py: '8px',
                  }}
                  disabled={statusPurchase !== 'processed'}
                  onClick={() => handleToScan()}
                >
                  <QrCodeIconWhite />
                  <Typography ax={{ fontSize: '12px' }}>Send</Typography>
                </CustomButton>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <NetworkProcess />
            </Grid>
            <Grid item xs={1}>
              <Box sx={{ bgcolor: '#FAFAFA', p: '12px', borderRadius: '12px' }}>
                <Box sx={{ mt: '12px' }}>
                  <Typography sx={{ fontSIze: '14px', fontWeight: '600' }}>
                    Item(s)
                  </Typography>
                </Box>
                <Grid container columns={1}>
                  {datas?.details.map((list, index) => {
                    return (
                      <Grid item xs={1} key={index}>
                        <Box
                          sx={{
                            my: '12px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justiContent: 'center',
                              alignItems: 'center',
                              gap: 1.5,
                            }}
                          >
                            {list?.product?.gambar ? (
                              <Box
                                sx={{
                                  position: 'relative',
                                  width: '45px',
                                  height: '45px',
                                  borderRadius: '8px',
                                }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_API_IMAGE_BASE_URL +
                                    '/' +
                                    list?.product?.gambar
                                  }
                                  style={{
                                    width: '45px',
                                    height: '45px',
                                    borderRadius: '8px',
                                  }}
                                  alt="gbr"
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  position: 'relative',
                                  width: '45px',
                                  height: '45px',
                                  borderRadius: '8px',
                                  border: 1,
                                }}
                              >
                                <NoImageIcon />
                              </Box>
                            )}
                            <Box>
                              <Typography sx={{ fontSize: '12px' }}>
                                {list?.product?.namaProduk || '-'}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '600',
                                  mt: '2px',
                                }}
                              >
                                {list?.qty || '-'} x
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            {/* {list.discount && (
                              <>
                                {list.discountFormat === 'percentage' ? (
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      color: '#9E9D9D',
                                      textAlign: 'end',
                                      mb: '4px',
                                      textDecoration: 'line-through',
                                    }}
                                  >
                                    Rp.
                                    {((list?.purchasePrice * list?.qty) / 100) *
                                      list?.discount}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      color: '#9E9D9D',
                                      textAlign: 'end',
                                      mb: '4px',
                                      textDecoration: 'line-through',
                                    }}
                                  >
                                    Rp.{list?.discount}
                                  </Typography>
                                )}
                              </>
                            )} */}
                            {/* {list.discount ? (
                              <>
                                {list.discountFormat === 'percentage' ? (
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      textAlign: 'end',
                                    }}
                                  >
                                    Rp.
                                    {list?.purchasePrice * list?.qty -
                                      ((list?.purchasePrice * list?.qty) /
                                        100) *
                                        list?.discount}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      textAlign: 'end',
                                    }}
                                  >
                                    Rp.
                                    {list?.purchasePrice * list?.qty -
                                      list?.discount}
                                  </Typography>
                                )}
                              </>
                            ) : (
                              <>
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textAlign: 'end',
                                  }}
                                >
                                  Rp.{list?.purchasePrice * list?.qty}
                                </Typography>
                              </>
                            )} */}

                            {list?.discount !== 0 &&
                            list?.discountFormat !== 'percentage' ? (
                              <Typography
                                sx={{ fontSize: '10px', color: '#9E9D9D' }}
                              >
                                Rp {list?.discount}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{ fontSize: '10px', color: '#9E9D9D' }}
                              >
                                {list?.discount} %
                              </Typography>
                            )}

                            {list?.discount !== 0 ? (
                              list?.discountFormat !== 'percentage' ? (
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textAlign: 'end',
                                  }}
                                >
                                  Rp.
                                  {list?.purchasePrice * list?.qty -
                                    list?.discount}
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textAlign: 'end',
                                  }}
                                >
                                  Rp.
                                  {list?.purchasePrice * list?.qty -
                                    (list?.discount / 100) *
                                      list?.purchasePrice *
                                      list?.qty}
                                </Typography>
                              )
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  textAlign: 'end',
                                }}
                              >
                                Rp.{list?.purchasePrice * list?.qty}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        <Divider />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box sx={{ bgcolor: '#FAFAFA', p: '12px', borderRadius: '12px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                  Payment Detail
                </Typography>
                <Box
                  sx={{
                    my: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontSize: '12px' }}>Subtotal</Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                      Rp.{totalPrice}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontSize: '12px' }}>Discount</Typography>
                    <Typography sx={{ fontSize: '12px', color: '#4FD240' }}>
                      {datas?.discountFormat === 'percentage' ? (
                        <>RP. {datas?.bayar - datas?.totalbayar}</>
                      ) : (
                        <>Rp.{datas?.discount || '-'}</>
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: '12px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Grand Total
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    {datas?.discountFormat === 'currency' && 'Rp.'}
                    {totalPriceFix}
                    {datas?.discountFormat === 'precentage' && '%'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      {isLoading && (
        <Box sx={{ minHeight: '90vh', bgcolor: 'white' }}>
          <LoadingComponent />
        </Box>
      )}
    </Box>
  );
};
export default PurchaseProcessDetailSupplier;
