import { createSlice } from '@reduxjs/toolkit';
import {
  detailMiniHub,
  getRackMiniHub,
  getRackMiniHubPreview,
} from '../../../api/hubs';

export const miniHubDetailState = createSlice({
  name: 'miniHubDetail',
  initialState: {
    isLoading: false,
    idMiniHub: null,
    detailsData: {},

    racks: [],
    racksPreview: [],
    racksPositionPreview: [],
    racksPositionPreviewAll: [],
    racksPositionTemporary: [],
  },
  reducers: {
    changeIdMiniHub: (state, action) => {
      state.idMiniHub = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadDetailDataMiniHub: (state, action) => {
      state.detailsData = action.payload;
    },
    loadDataRacks: (state, action) => {
      state.racks = action.payload;
    },
    loadDataRacksPositionPreview: (state, action) => {
      state.racksPositionPreview = action.payload;
      state.racksPositionPreviewAll = action.payload.flat();
    },
    loadDataRacksPreview: (state, action) => {
      state.racksPreview = action.payload;
    },
    hoverRacks: (state, action) => {},
  },
});

export const {
  changeIdMiniHub,
  changeIsLoading,
  loadDetailDataMiniHub,
  loadDataRacks,
  loadDataRacksPreview,
  loadDataRacksPositionPreview,
} = miniHubDetailState.actions;

export const getIdMiniHub = (state) => state.miniHubDetail.idMiniHub;
export const getLoadingDetailMiniHub = (state) => state.miniHubDetail.isLoading;
export const getDetailDataMiniHub = (state) => state.miniHubDetail.detailsData;
export const getRacksPositionPreviewMiniHub = (state) =>
  state.miniHubDetail.racksPositionPreview;
export const getRacksPositionPreviewAllMiniHub = (state) =>
  state.miniHubDetail.racksPositionPreviewAll;
export const getRacksPreviewMiniHub = (state) =>
  state.miniHubDetail.racksPreview;

// API get detail sub hub
export const fetchDetailDataMiniHub = (id) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await detailMiniHub(id);
      dispatch(loadDetailDataMiniHub(data));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };
  fetchData();
};

export const fetchMiniHubRacks = (id) => (dispatch) => {
  const fetchApi = async () => {
    try {
      const {
        data: {
          data: { data },
        },
      } = await getRackMiniHub(id);
      dispatch(loadDataRacks(data));
    } catch (err) {
      dispatch(loadDataRacks([]));
      console.error(err);
    } finally {
    }
  };

  fetchApi();
};

export const fetchMiniHubRacksPreview = (id) => (dispatch) => {
  const fetchApi = async () => {
    try {
      const {
        data: {
          data: { data },
        },
      } = await getRackMiniHubPreview(id);
      let rackPosition = data.map((item) => {
        return item.columnPosition.split(',');
      });
      dispatch(loadDataRacksPositionPreview(rackPosition));
      dispatch(loadDataRacksPreview(data));
    } catch (err) {
      dispatch(loadDataRacksPositionPreview([]));
      dispatch(loadDataRacksPreview([]));
      console.error(err);
    } finally {
    }
  };

  fetchApi();
};

export default miniHubDetailState.reducer;
