import { createSlice } from '@reduxjs/toolkit';
import { getDetailHubs } from '../../../api/hubs';

export const masterHubDetailState = createSlice({
  name: 'masterHubDetail',
  initialState: {
    idHubs: null,
    datas: [],
    isLoading: false,
    notif: false,
    textNotif: '',
  },
  reducers: {
    loadDataDetailHubs: (state, action) => {
      state.datas = action.payload;
    },
    changeIsLoadingDetailHubs: (state, action) => {
      state.isLoading = action.payload;
    },
    changeIdMasterHubs: (state, action) => {
      state.idHubs = action.payload;
    },
    closeNotif: (state) => {
      state.textNotif = '';
      state.notif = false;
    },
    putNotif: (state, action) => {
      state.textNotif = action.payload;
      state.notif = true;
    },
  },
});

export const {
  loadDataDetailHubs,
  changeIsLoadingDetailHubs,
  changeIdMasterHubs,
  closeNotif,
  putNotif,
} = masterHubDetailState.actions;

export const getAllMasterHubDetail = (state) => state.masterHubDetail;

// ! -------------------------- API -------------------------------
export const fetchDataDetailHubs = (id) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsLoadingDetailHubs(true));
    try {
      const {
        data: { data },
      } = await getDetailHubs(id);
      dispatch(loadDataDetailHubs(data));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingDetailHubs(false));
    }
  };

  fetchData();
};

export default masterHubDetailState.reducer;
