import {
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  LoadingComponent,
  ScanQrTool,
} from '../../../../../../../../components/shared/shared2';
import { useState } from 'react';
import useWindowDimensions from '../../../../../../../../hooks/useWindowDimensions';
import {
  DataNotFound,
  FlashIcon,
  ScanTargetIcon,
} from '../../../../../../../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBackIosNew as ArrowBackIosNewIcon } from '@mui/icons-material';
import { changePageAudit } from '../../../../../../../../store/Routing/auditRouting';
import CustomBottomDrawer from '../../../../../../../../components/shared/CustomBottomDrawer';
import {
  auditScanQr,
  updateAuditScanQr,
} from '../../../../../../../../api/audit';
import { useEffect } from 'react';
import {
  changeIsQrExist,
  changeScannedData,
  getDataScanned,
  getIdAddAudit,
  getIsQrExist,
} from '../../../../../../../../store/audit/addAudit';

const ScanQrAudit = () => {
  const { width: widthScreen } = useWindowDimensions();
  const dispatch = useDispatch();
  const idAudit = useSelector(getIdAddAudit);
  const isQrNotExist = useSelector(getIsQrExist);
  const [isLoadingScan, setIsLoadingScan] = useState(false);
  const [isDetailProduct, setIsDetailProduct] = useState(false);
  const [detailProduct, setDetailProduct] = useState(null);
  const [value, setValue] = useState(0);
  const [idToUpdate, setIdToUpdate] = useState('');
  const listScanned = useSelector(getDataScanned);
  const [isLoadingUpdateScan, setIsLoadingUpdateScan] = useState(false);
  const [notifLoopScan, setNotifLoopScan] = useState('');

  const fetchDatasProductScan = async (params) => {
    setIsLoadingScan(true);
    try {
      const {
        data: { data },
      } = await auditScanQr(params);
      setDetailProduct(data);
      setValue(data?.quantityProduct);
      setIdToUpdate(data?.auditQrId);
      setIsDetailProduct(true);
    } catch (err) {
      console.error(err);
      dispatch(changeIsQrExist(true));
    } finally {
      setIsLoadingScan(false);
      // setScanRes('');
    }
  };

  const [scanRes, setScanRes] = useState('');
  const handleScan = (qrValue) => {
    if (qrValue) {
      setScanRes(qrValue);
    }
  };
  // console.log({ scanRes });

  const handleTorchlight = () => {};

  useEffect(() => {
    const params = {};
    params.audit_id = idAudit;
    params.order_purchase_qr_id = scanRes;
    if (scanRes) {
      fetchDatasProductScan(params);
    }
  }, [scanRes, idAudit]);

  const handleUpdateScan = () => {
    setIsLoadingUpdateScan(true);
    const updateQrScan = async () => {
      const params = {};
      params.value = value;
      params.unit = detailProduct?.unit;
      try {
        const data = await updateAuditScanQr(idToUpdate, params);
        const newData = data?.data.data;
        dispatch(changeScannedData(newData));
        setIsDetailProduct(false);
        dispatch(changePageAudit('addAudit'));
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingUpdateScan(false);
      }
    };
    updateQrScan();
  };

  return (
    <Box>
      <Box
        sx={{
          padding: '12px 0px',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <ArrowBackIosNewIcon
          sx={{ color: '#51B15C', fontSize: '16px' }}
          onClick={() => dispatch(changePageAudit('addAudit'))}
        />
        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}>
          Scan
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          minHeight: '80vh',
          width: '100%',
          left: 0,
          bgcolor: 'black',
        }}
      >
        <Box sx={{ width: '100%', position: 'realtive' }}>
          {isLoadingScan && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                zIndex: 10,
                width: widthScreen,
                height: '600px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'white',
                opacity: 0.5,
              }}
            >
              <LoadingComponent />
            </Box>
          )}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ScanTargetIcon />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '315px',
                height: '370px',
                borderRadius: '25px',
                bgcolor: 'white',
                opacity: 0.2,
              }}
            ></Box>
          </Box>
          <ScanQrTool
            width={widthScreen}
            height={500}
            onChange={(value) => handleScan(value)}
            delay={1000}
          />
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 20,
          left: 0,
          width: '100%',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            width: '42px',
            height: '42px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            bgcolor: 'white',
          }}
          onClick={handleTorchlight}
        >
          <FlashIcon />
        </Box>
      </Box>

      {/* data empty || not found */}
      <CustomBottomDrawer
        open={isQrNotExist}
        title={'Oops!'}
        handleClose={() => dispatch(changeIsQrExist(false))}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <Box
            sx={{
              width: '45px',
              height: '45px',
              bgcolor: '#F33A3A1A',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DataNotFound />
          </Box>
          {notifLoopScan ? (
            <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
              {notifLoopScan}
            </Typography>
          ) : (
            <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
              The product isn’t in the hub.
            </Typography>
          )}
        </Box>
        <Divider sx={{ my: '20px' }} />
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Typography
            sx={{ fontSize: '14px', color: '#51B15C', fontWeight: 600 }}
            onClick={() => dispatch(changeIsQrExist(false))}
          >
            Re-scan
          </Typography>
        </Box>
      </CustomBottomDrawer>

      {/* data detail product */}
      <CustomBottomDrawer
        open={isDetailProduct}
        title={'Product'}
        handleClose={() => setIsDetailProduct(false)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <Box
            component="img"
            src={
              process.env.REACT_APP_API_IMAGE_BASE_URL +
              '/' +
              detailProduct?.product?.gambar
            }
            sx={{
              width: '45px',
              height: '45px',
              bgcolor: '#F33A3A1A',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
          <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
            {detailProduct?.product?.namaProduk}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            id="filled-helperText"
            label="Quantity*"
            value={value}
            type="number"
            variant="filled"
            onChange={(event) => setValue(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {detailProduct?.unit || 'pcs'}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider sx={{ background: '#51B15C' }} />
        {isLoadingUpdateScan ? (
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 1,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              mb: 1,
            }}
          >
            <Typography
              sx={{ fontSize: '14px', color: '#51B15C', fontWeight: 600 }}
              onClick={handleUpdateScan}
            >
              OK
            </Typography>
            <Typography
              sx={{ fontSize: '14px', color: '#51B15C', fontWeight: 600 }}
              onClick={() => {
                setIsDetailProduct(false);
                setDetailProduct(null);
                setValue(0);
              }}
            >
              Re-scan
            </Typography>
          </Box>
        )}
      </CustomBottomDrawer>
    </Box>
  );
};
export default ScanQrAudit;
