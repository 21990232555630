import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import {
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  AddIcon,
  ChevronRightIcon,
  ExportIcon,
  SearchIcon,
  Telephone,
  ToBarChart,
  ToLineChart,
  UserIcon,
} from '../../../../../../../assets/Icons';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { changePageSubHub } from '../../../../../../../store/Routing/hubs';
import { changeIdSubHub } from '../../../../../../../store/hub/subHub/subHubDetails';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import {
  getAllDataHubToSubHub,
  getAllDataSubHub,
} from '../../../../../../../store/hub/subHub/subHub';
import CustomTableMobile from '../../../../../../../components/shared/CustomTableMobile';
import CustomBarChart from '../../../../../../../components/shared/CustomBarChart';
import CustomLineChart from '../../../../../../../components/shared/CustomLineChart';
// data dummy chart
const datas = {
  labels: ['Hub 1', 'Hub 2', 'Hub 3', 'Hub 4', 'Hub 5'],
  data: [
    {
      label: 'Equal',
      chart: [30, 110, 140, 100, 240],
    },
    {
      label: 'Different',
      chart: [0, 35, 100, 90, 150],
    },
    {
      label: 'Missing',
      chart: [40, 70, 160, 120, 200],
    },
  ],
};
const color = ['#6B4BB4', '#EACD57', '#FD7900'];

const SubHubMobile = () => {
  const dispatch = useDispatch();
  const { masterHubName, idMasterHub, subHubData, isLoadingSubHub } =
    useSelector(getAllDataHubToSubHub);
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');
  const [chartType, setChartType] = useState(false);

  //search
  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  //hendle DetailSubHub
  const heandleSubHubDetail = (id) => {
    dispatch(changePageSubHub('detailSubHub'));
    dispatch(changeIdSubHub(id));
  };

  //! -------------------- API ------------------
  useEffect(() => {
    const params = {};
    params.page_size = 4;
    params.idToko = idMasterHub;
    params.search = purchaseKeyword;
    dispatch(getAllDataSubHub(params));
  }, [purchaseKeyword, idMasterHub]);

  //! ---------------------- toolbar -----------------------------------
  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row-reverse',
        gap: 1.7,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'right',
      }}
    >
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '20px',
          height: '20px',
          borderRadius: '4px',
          bgcolor: '#51B15C',
          color: '#ffffff',
          '&:hover': {
            bgcolor: '#51B15C',
            color: '#ffffff',
          },
          fontSize: '0.875rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <AddIcon
          sx={{ color: 'white', width: '8px', height: '8px' }}
          onClick={() => dispatch(changePageSubHub('AddSubHub'))}
        />
      </CustomButton>
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <ExportIcon />
      </CustomButton>
    </Box>
  );

  return (
    <Grid container columns={12} spacing={2} sx={{ mt: 0 }}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <BackButtonHub onClick={() => dispatch(changePageSubHub(''))} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 600, color: '#9E9D9D' }}
              onClick={() => dispatch(changePageSubHub(''))}
            >
              Master Hub
            </Typography>
            <ChevronRightIcon
              sx={{ fontSize: '16px', color: '#9E9D9D', fontWeight: 600 }}
            />
            <Typography
              sx={{ fontSize: '14px', fontWeight: 600, color: '#000' }}
            >
              {masterHubName}
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* grafik */}
      <Grid item xs={12}>
        <CustomCard sx={{ p: 2 }}>
          <Typography
            sx={{ color: '#000', fontSize: '10px', fontWeight: 600, mb: 2 }}
          >
            Hub Audit
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontSize: '8px',
                color: '#6B4BB4',
                fontWeight: 600,
                mb: 1,
              }}
            >
              SKU
            </Typography>
            <Box
              sx={{
                width: '16px',
                height: '16px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => setChartType(!chartType)}
            >
              {chartType ? (
                <ToLineChart stroke="#51B15C" />
              ) : (
                <ToBarChart stroke="#51B15C" />
              )}
            </Box>
            <Box></Box>
          </Box>
          {chartType ? (
            <CustomBarChart datas={datas} color={color} />
          ) : (
            <CustomLineChart datas={datas} color={color} />
          )}
        </CustomCard>
      </Grid>

      {/* table grafik */}
      <Grid item xs={12}>
        <CustomTableMobile
          rootValue={datas?.labels}
          datas={datas.data}
          color={color}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomCard sx={{ padding: '16px' }}>
          <Grid container columns={12} spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: ' #000000',
                      display: 'inline-flex',
                      fontSize: '12px',
                      fontWeight: 600,
                    }}
                  >
                    SubHub List
                  </Typography>
                  <TextField
                    placeholder="Search"
                    size="small"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      debounceOnChange(e.target.value);
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingLeft: '6px',
                        fontSize: '8px',
                      },
                      bgcolor: '#FAFAFA',
                      minHeight: '22px',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ width: '12px' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {toolbar}
              </Box>
            </Grid>
            {/* ----------------------- mapping data ---------------------------- */}
            {isLoadingSubHub ? (
              <Grid item xs={12}>
                <Box sx={{ textAlign: 'center', p: 4 }}>
                  <CircularProgress size={24} />
                </Box>
              </Grid>
            ) : subHubData.length === 0 ? (
              <Grid
                container
                columns={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'red',
                  pt: '8px',
                  p: 5,
                }}
              >
                <Typography sx={{ fontSize: '12px' }}>data no found</Typography>
              </Grid>
            ) : (
              subHubData?.map((item, idx) => (
                <Grid item xs={6} key={idx} onClick={() => console.log('test')}>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '10px',
                      borderRadius: '8px',
                      background: '#FAFAFA',
                    }}
                  >
                    <Grid container columns={12} spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          Height: '147px',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            alignContent: 'center',
                          }}
                        >
                          <Box
                            component="img"
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              '/' +
                              item?.images.split(',')[0]
                            }
                            sx={{
                              minWidth: '28px',
                              width: '28px',
                              height: '28px',
                              borderRadius: '8px',
                            }}
                            onClick={(event) => {
                              heandleSubHubDetail(item.id);
                              event.stopPropagation();
                            }}
                          />

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-around',
                              height: '28px',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '8px',
                                display: 'flex',
                                fontWeight: 600,
                              }}
                            >
                              {item?.subsidiary}
                            </Typography>
                            <Typography
                              sx={{ fontSize: '10px', fontWeight: 600 }}
                            >
                              {item?.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                        >
                          <Box>
                            <UserIcon />
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-around',
                              height: '28px',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '8px',
                                display: 'flex',
                                fontWeight: 600,
                              }}
                            >
                              {item?.picName}
                            </Typography>
                          </Box>

                          <Box>
                            <Telephone />
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-around',
                              height: '28px',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '8px',
                                display: 'flex',
                                fontWeight: 600,
                              }}
                            >
                              {item?.picPhone}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
          {/* akhir */}
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default SubHubMobile;
