import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ReactComponent as ColumnFilterIcon } from '../../../../../assets/colum-filter-icon.svg';
import dayjs from 'dayjs';
import CustomMenu from '../../../../../components/shared/CustomMenu';
import CustomCard from '../../../../../components/shared/CustomCard';
import {
  changeSortAsc,
  changeSortIn,
  changeStatusPurchase,
  getAllDataPurchase,
  getEndDateFilter,
  getPaymentFilter,
  getPurchaseTable,
  getPurchaseTableIsLoading,
  getPurchaseTableSortAsc,
  getPurchaseTableSortIn,
  getPurchaseTableStatusFilter,
  getPurchaseTableTotalItem,
  getSingleFilter,
  getStartDateFilter,
  inputStatusFilter,
} from '../../../../../store/purchase/dataTable';
import { useEffect, useRef, useState } from 'react';
import DialogFilterPurchase from './DialogFilter';
import {
  changePage,
  changePurchaseRoutingState,
  changeStatusForPurchase,
  setHirarkiPurchase,
} from '../../../../../store/Routing/purchaseRouting';
import {
  changeIdPurchaseDetail,
  getIdPurchaseDetail,
} from '../../../../../store/purchase/detailPurchase';
import debounce from 'lodash.debounce';

const color = {
  pending: '#FD7900',
  bid: '#6342E8',
  processing: '#2191FB',
  sent: '#EACD57',
  accepted: '#51B15C',
  returned: '#F33A3A',
  finished: '#FF439D',
  unpaid: '#9E9D9D',
  paid: '#000000',
  rejected: '#FF0000',
};

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
});
const TablePurchase = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const tableDatas = useSelector(getPurchaseTable);
  const isLoading = useSelector(getPurchaseTableIsLoading);

  const [currentPageTable, setCurrentPageTable] = useState(1);
  const totalItemTable = useSelector(getPurchaseTableTotalItem);
  const sortInTable = useSelector(getPurchaseTableSortIn);
  const sortAscTable = useSelector(getPurchaseTableSortAsc);

  const statusFilter = useSelector(getPurchaseTableStatusFilter);
  const singleFilter = useSelector(getSingleFilter);
  const paymentFilter = useSelector(getPaymentFilter);
  const startDate = useSelector(getStartDateFilter);
  const endDate = useSelector(getEndDateFilter);

  const pageSizeList = [5, 10, 15, 20, 25];
  const [pageSizeTable, setPageSizeTable] = useState(pageSizeList[0]);

  const handleForwardArrow = () => {
    setCurrentPageTable(
      currentPageTable >= Math.ceil(totalItemTable / pageSizeTable)
        ? currentPageTable
        : currentPageTable + 1
    );
  };

  const handleBackArrow = () => {
    setCurrentPageTable(
      currentPageTable <= 1 ? currentPageTable : currentPageTable - 1
    );
  };

  const handleInputCurrentPage = (event) => {
    if (event.target.value >= Math.ceil(totalItemTable / pageSizeTable)) {
      setCurrentPageTable(Math.ceil(totalItemTable / pageSizeTable));
    } else if (event.target.value <= 1) {
      setCurrentPageTable(1);
    } else {
      setCurrentPageTable(event.target.value);
    }
  };

  const [filterAdjust, setFilterAdjust] = useState(null);
  const isFilterAdjust = Boolean(filterAdjust);
  const [filterAdjustSelected, setFilterAdjustSelected] = useState([
    'sku',
    'date',
  ]);

  const [isFilter, setIsFilter] = useState(false);
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  const [sortTable, setSortTable] = useState('');
  const [asc, setAsc] = useState('asc');

  // !----------------- API ------------------------
  useEffect(() => {
    let datas = {};

    if (singleFilter !== '' && paymentFilter !== '' && startDate !== '') {
      datas.page = currentPageTable;
      datas.pageSize = Number(pageSizeTable);
      datas.search = purchaseKeyword;
      datas.status = statusFilter;
      datas.invoiceStatus = singleFilter;
      datas.paymentStatus = paymentFilter;
      datas.startDate = startDate;
      datas.endDate = endDate;
    } else if (singleFilter !== '') {
      datas.page = currentPageTable;
      datas.pageSize = Number(pageSizeTable);
      datas.search = purchaseKeyword;
      datas.status = statusFilter;
      datas.invoiceStatus = singleFilter;
    } else if (paymentFilter !== '') {
      datas.page = currentPageTable;
      datas.pageSize = Number(pageSizeTable);
      datas.search = purchaseKeyword;
      datas.status = statusFilter;
      datas.paymentStatus = paymentFilter;
    } else if (startDate !== '') {
      datas.page = currentPageTable;
      datas.pageSize = Number(pageSizeTable);
      datas.search = purchaseKeyword;
      datas.status = statusFilter;
      datas.startDate = startDate;
      datas.endDate = endDate;
    } else {
      datas.page = currentPageTable;
      datas.pageSize = Number(pageSizeTable);
      datas.search = purchaseKeyword;
      datas.sortInvoice = sortTable !== 'sortInvoice' ? '' : asc;
      datas.sortAmount = sortTable !== 'sortAmount' ? '' : asc;
      datas.sortSku = sortTable !== 'sortSku' ? '' : asc;
      datas.sortDate = sortTable !== 'sortDate' ? '' : asc;
      datas.sortSubsidiary = sortTable !== 'sortSubsidiary' ? '' : asc;
      datas.sortLocation = sortTable !== 'sortLocation' ? '' : asc;
      datas.sortSupplier = sortTable !== 'sortSupplier' ? '' : asc;
      datas.status = statusFilter;
    }
    datas[sortInTable] = sortAscTable === true ? 'asc' : 'desc ';
    dispatch(getAllDataPurchase(datas));
  }, [
    currentPageTable,
    pageSizeTable,
    sortInTable,
    sortAscTable,
    statusFilter,
    singleFilter,
    paymentFilter,
    startDate,
    endDate,
    purchaseKeyword,
    sortTable,
    asc,
  ]);

  // !----------------- Handler --------------------------

  const handlePageSize = (e) => {
    // dispatch(changePageSize(e.target.value));
    setPageSizeTable(e.target.value);
    // dispatch(inputPage(Number(1)));
  };

  //! -------------- TOOLBAR -------------------
  const toolbar = (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row-reverse' }}>
      <IconButton
        sx={{
          border: '1.5px solid #51B15C',
          borderRadius: '8px',
          p: 1,
        }}
        onClick={(e) => setFilterAdjust(e.currentTarget)}
        aria-haspopup="true"
      >
        <ColumnFilterIcon fill="#51B15C" />
      </IconButton>
      <CustomMenu
        open={isFilterAdjust}
        openMenu={filterAdjust}
        setOpenMenu={setFilterAdjust}
        sx={{
          '.MuiMenuItem-root': {
            paddingLeft: 0,
            paddingRight: '20px',
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', p: '12px' }}
        >
          <Typography sx={{ fontWeight: '400', fontSize: '12px' }}>
            Max 7
          </Typography>
          <Typography
            color="primary"
            sx={{
              fontWeight: '600',
              fontSize: '12px',
              cursor: 'pointer',
            }}
            onClick={() => setFilterAdjustSelected(['sku', 'date'])}
          >
            Reset
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem>
          <Checkbox checked={true} />
          Invoice ID*
        </MenuItem>
        <MenuItem>
          <Checkbox checked={true} />
          Amount*
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('sku')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'sku')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'sku']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('sku')} />
          SKU
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('date')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'date')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'date']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('date')} />
          Date
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('subsidiary')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'subsidiary')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'subsidiary']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('subsidiary')} />
          Subsidiary
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('location')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'location')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'location']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('location')} />
          Location
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (filterAdjustSelected.includes('supplier')) {
              setFilterAdjustSelected(
                filterAdjustSelected.filter((item) => item !== 'supplier')
              );
            } else {
              if (filterAdjustSelected.length < 5) {
                setFilterAdjustSelected((prev) => [...prev, 'supplier']);
              }
            }
          }}
        >
          <Checkbox checked={filterAdjustSelected.includes('supplier')} />
          Supplier
        </MenuItem>
      </CustomMenu>
      {/* //* Filter */}
      <CustomCard
        sx={{
          p: '10px 20px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ fontSize: '20px' }} />
        <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
          Filter
        </Typography>
      </CustomCard>
      <DialogFilterPurchase {...{ isFilter, setIsFilter }} />
    </Box>
  );

  const desktopMode = useMediaQuery('(min-width:832px)');
  const role = localStorage.getItem('role');
  const handleClickRow = (id, subsidaryName, hubName, status) => {
    if (role == 4) {
      if (status === 'supplier bid') {
        dispatch(changePurchaseRoutingState({ key: 'idPurchase', value: id }));
        dispatch(changePage('purchaseDetailFinanceSupplierBid'));
        dispatch(
          setHirarkiPurchase({ subsidiary: subsidaryName, hub: hubName })
        );
        dispatch(changeStatusForPurchase(status));
      } else {
        dispatch(changePurchaseRoutingState({ key: 'idPurchase', value: id }));
        dispatch(changePage('purchaseDetailFinance'));
        dispatch(
          setHirarkiPurchase({ subsidiary: subsidaryName, hub: hubName })
        );
        dispatch(changeStatusForPurchase(status));
      }
    } else if (role == 5) {
      if (status === 'finance bid') {
        dispatch(changePurchaseRoutingState({ key: 'idPurchase', value: id }));
        dispatch(changePage('purchaseDetailSupplier'));
        dispatch(
          setHirarkiPurchase({ subsidiary: subsidaryName, hub: hubName })
        );
        dispatch(changeStatusForPurchase(status));
      } else if (
        status === 'processed' ||
        status === 'sent' ||
        (status === 'accepted' && !desktopMode)
      ) {
        dispatch(changePurchaseRoutingState({ key: 'idPurchase', value: id }));
        dispatch(changePage('purchaseProcessDetailSupplier'));
        dispatch(
          setHirarkiPurchase({ subsidiary: subsidaryName, hub: hubName })
        );
        dispatch(changeStatusForPurchase(status));
      } else {
        dispatch(changePage('detail'));
        dispatch(changeIdPurchaseDetail(id));
      }
    } else {
      dispatch(changePage('detail'));
      dispatch(changeIdPurchaseDetail(id));
      dispatch(changePurchaseRoutingState({ key: 'idPurchase', value: id }));
      dispatch(setHirarkiPurchase({ subsidiary: subsidaryName, hub: hubName }));
      dispatch(changeStatusForPurchase(status));
    }
  };

  return (
    <Box>
      {/* search */}
      <Grid container columnSpacing={4} alignItems="center">
        <Grid item xs="auto">
          <Typography
            sx={{ color: '#000000', fontWeight: 600, fontSize: '20px' }}
          >
            Purchase
          </Typography>
        </Grid>

        <Grid item xs>
          <TextField
            placeholder={'Search'}
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              debounceOnChange(e.target.value);
            }}
            sx={{
              width: '250px',
              '& .MuiOutlinedInput-root': {
                paddingLeft: '6px',
                fontSize: '0.875rem',
              },
              bgcolor: '#FAFAFA',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ width: '18px' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs="auto">
          {toolbar}
        </Grid>
      </Grid>

      {/* //!------------------------ Filter Shortcut ---------------------------- */}
      <Box sx={{ display: 'flex', gap: 3, mt: '15px' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 0.3,
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => dispatch(inputStatusFilter('finance bid'))}
        >
          <FiberManualRecordIcon
            sx={{ color: color.pending, fontSize: '8px' }}
          />
          <Typography
            color={color.pending}
            sx={{
              userSelect: 'none',
              textDecoration: statusFilter.includes('finance bid')
                ? 'none'
                : 'line-through',
              fontSize: '12px',
            }}
          >
            Pending
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 0.3,
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => dispatch(inputStatusFilter('supplier bid'))}
        >
          <FiberManualRecordIcon sx={{ color: color.bid, fontSize: '8px' }} />
          <Typography
            color={color.bid}
            sx={{
              userSelect: 'none',
              textDecoration: statusFilter.includes('supplier bid')
                ? 'none'
                : 'line-through',
              fontSize: '12px',
            }}
          >
            Bid
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 0.3,
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => dispatch(inputStatusFilter('processed'))}
        >
          <FiberManualRecordIcon
            sx={{ color: color.processing, fontSize: '8px' }}
          />
          <Typography
            color={color.processing}
            sx={{
              userSelect: 'none',
              textDecoration: statusFilter.includes('processed')
                ? 'none'
                : 'line-through',
              fontSize: '12px',
            }}
          >
            Processing
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 0.3,
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => dispatch(inputStatusFilter('sent'))}
        >
          <FiberManualRecordIcon sx={{ color: color.sent, fontSize: '8px' }} />
          <Typography
            color={color.sent}
            sx={{
              userSelect: 'none',
              textDecoration: statusFilter.includes('sent')
                ? 'none'
                : 'line-through',
              fontSize: '12px',
            }}
          >
            Sent
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 0.3,
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => dispatch(inputStatusFilter('accepted'))}
        >
          <FiberManualRecordIcon
            sx={{ color: color.accepted, fontSize: '8px' }}
          />
          <Typography
            color={color.accepted}
            sx={{
              userSelect: 'none',
              textDecoration: statusFilter.includes('accepted')
                ? 'none'
                : 'line-through',
              fontSize: '12px',
            }}
          >
            Accepted
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 0.3,
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => dispatch(inputStatusFilter('retur'))}
        >
          <FiberManualRecordIcon
            sx={{ color: color.returned, fontSize: '8px' }}
          />
          <Typography
            color={color.returned}
            sx={{
              userSelect: 'none',
              textDecoration: statusFilter.includes('retur')
                ? 'none'
                : 'line-through',
              fontSize: '12px',
            }}
          >
            Returned
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 0.3,
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => dispatch(inputStatusFilter('finish'))}
        >
          <FiberManualRecordIcon
            sx={{ color: color.finished, fontSize: '8px' }}
          />
          <Typography
            color={color.finished}
            sx={{
              userSelect: 'none',
              textDecoration: statusFilter.includes('finish')
                ? 'none'
                : 'line-through',
              fontSize: '12px',
            }}
          >
            Finished
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 0.3,
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => dispatch(inputStatusFilter('reject'))}
        >
          <FiberManualRecordIcon
            sx={{ color: color.rejected, fontSize: '8px' }}
          />
          <Typography
            color={color.rejected}
            sx={{
              userSelect: 'none',
              textDecoration: statusFilter.includes('reject')
                ? 'none'
                : 'line-through',
              fontSize: '12px',
            }}
          >
            Rejected
          </Typography>
        </Box>
      </Box>

      {/* fetch data */}
      <Table sx={{ mt: 1 }}>
        {/* //! ----------------------------- Head ------------------------------------- */}
        <TableHead>
          <TableRow sx={{ bgcolor: '#FAFAFA' }}>
            <CustomTableCells>No.</CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortInvoice');
                  dispatch(changeSortAsc(!sortAscTable));
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Invoice ID
                </Typography>
                {sortTable === 'sortInvoice' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortAmount');
                  dispatch(changeSortAsc(!sortAscTable));
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Amount (Rp)*
                </Typography>
                {sortTable === 'sortAmount' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            {filterAdjustSelected.includes('sku') && (
              <CustomTableCells>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('sortSku');
                    // dispatch(changeIsSortAscRequestPurchase(!isSortAsc));
                    // dispatch(changeSortTableRequestPurchase('sortRequest'));
                    dispatch(changeSortAsc(!sortAscTable));
                    if (sortAscTable === true) {
                      setAsc('asc');
                    } else {
                      setAsc('desc');
                    }
                    // dispatch(inputPage(1));
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    SKU
                  </Typography>
                  {sortTable === 'sortSku' ? (
                    sortAscTable ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {filterAdjustSelected.includes('date') && (
              <CustomTableCells>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('sortDate');
                    dispatch(changeSortAsc(!sortAscTable));
                    if (sortAscTable === true) {
                      setAsc('asc');
                    } else {
                      setAsc('desc');
                    }
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    Date
                  </Typography>
                  {sortTable === 'sortDate' ? (
                    sortAscTable ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {filterAdjustSelected.includes('subsidiary') && (
              <CustomTableCells>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('sortSubsidiary');
                    dispatch(changeSortAsc(!sortAscTable));
                    if (sortAscTable === true) {
                      setAsc('asc');
                    } else {
                      setAsc('desc');
                    }
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    Subsidiary
                  </Typography>
                  {sortTable === 'sortSubsidiary' ? (
                    sortAscTable ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {filterAdjustSelected.includes('location') && (
              <CustomTableCells>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('sortLocation');
                    dispatch(changeSortAsc(!sortAscTable));
                    if (sortAscTable === true) {
                      setAsc('asc');
                    } else {
                      setAsc('desc');
                    }
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    Location
                  </Typography>
                  {sortTable === 'sortLocation' ? (
                    sortAscTable ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {filterAdjustSelected.includes('supplier') && (
              <CustomTableCells>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('sortSupplier');
                    dispatch(changeSortAsc(!sortAscTable));
                    if (sortAscTable === true) {
                      setAsc('asc');
                    } else {
                      setAsc('desc');
                    }
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    Supllier
                  </Typography>
                  {sortTable === 'sortSupplier' ? (
                    sortAscTable ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
          </TableRow>
        </TableHead>
        {/* //! ----------------------------- Body ------------------------------------- */}
        {!isLoading && (
          <TableBody>
            {tableDatas.map((tableData, index) => (
              <TableRow
                key={index}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClickRow(
                    tableData.iDpurchase,
                    tableData.subsidary,
                    tableData.location,
                    tableData.status
                  );
                }}
              >
                <CustomTableCells sx={{ width: '40px' }}>
                  {(currentPageTable - 1) * pageSizeTable + (index + 1)}
                </CustomTableCells>
                <CustomTableCells
                  sx={{
                    color:
                      tableData.status === 'finance bid'
                        ? color.pending
                        : tableData.status === 'supplier bid'
                        ? color.bid
                        : tableData.status === 'processed'
                        ? color.processing
                        : tableData.status === 'sent'
                        ? color.sent
                        : tableData.status === 'accepted'
                        ? color.accepted
                        : tableData.status === 'retur'
                        ? color.returned
                        : tableData.status === 'finish'
                        ? color.finished
                        : tableData.status === 'reject'
                        ? color.rejected
                        : 'balck',
                  }}
                >
                  {tableData.invoiceid ? `#${tableData.invoiceid}` : '# -'}
                </CustomTableCells>
                <CustomTableCells>
                  {tableData.amount ? tableData.amount : '-'}
                </CustomTableCells>

                {filterAdjustSelected.includes('sku') && (
                  <CustomTableCells>
                    {tableData.sku ? tableData.sku : '-'}
                  </CustomTableCells>
                )}
                {filterAdjustSelected.includes('date') && (
                  <CustomTableCells>
                    {tableData.date
                      ? dayjs(tableData.date).format('DD/MMM/YY')
                      : '-'}
                  </CustomTableCells>
                )}
                {filterAdjustSelected.includes('subsidiary') && (
                  <CustomTableCells>
                    {tableData.subsidary ? tableData.subsidary : '-'}
                  </CustomTableCells>
                )}
                {filterAdjustSelected.includes('location') && (
                  <CustomTableCells>
                    {tableData.location ? tableData.location : '-'}
                  </CustomTableCells>
                )}
                {filterAdjustSelected.includes('supplier') && (
                  <CustomTableCells>
                    {tableData?.supplier?.namaSuppliers
                      ? tableData?.supplier?.namaSuppliers
                      : '-'}
                  </CustomTableCells>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>

      {isLoading && (
        <Box
          sx={{
            height: `${50 * pageSizeTable}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: '30px' }}
      >
        <Grid item xs={6} sx={{ fontSize: '14px', fontWeight: '500' }}>
          Showing {currentPageTable * pageSizeTable - (pageSizeTable - 1)} to{' '}
          {currentPageTable * pageSizeTable >= totalItemTable
            ? totalItemTable
            : currentPageTable * pageSizeTable}{' '}
          of {totalItemTable} entries
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ fontSize: '14px', fontWeight: '500' }}>
            Rows Per Page :
            <Select
              value={pageSizeTable}
              variant="filled"
              onChange={handlePageSize}
              sx={{
                fontSize: '14px',
                lineHeight: '-1px',
                '.MuiFilledInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                '&:before': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&:after': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                borderRadius: '5px',
                marginLeft: '8px',
              }}
            >
              {pageSizeList.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sx={{ ml: '35px' }}>
            <Box sx={{ display: 'inline', mr: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleBackArrow}>
                <ArrowBackIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
              <Box sx={{ display: 'inline', mr: '20px' }}>
                <OutlinedInput
                  variant="outlined"
                  type="number"
                  value={currentPageTable}
                  onChange={handleInputCurrentPage}
                  sx={{
                    border: '1px solid #9E9D9D',
                    width: '36px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    '& .MuiOutlinedInput-input': {
                      padding: '5px 8px 5px 8px',
                      fontSize: '0.875rem',
                      boxShadow: 'none',
                      textAlign: 'center',
                      fontWeight: 600,
                      width: '25px',
                    },
                    '&:before': {
                      borderRadius: '5px',
                      borderBottom: 0,
                    },
                    '&:after': {
                      borderRadius: '5px',
                      borderBottom: 0,
                    },
                    '&.Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        boxShadow: 'none',
                      },
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'inline',
                  fontWeight: '600',
                  fontSize: '14px',
                  color: '#9E9D9D',
                }}
              >
                of {Math.ceil(totalItemTable / pageSizeTable)}
              </Box>
              <Box sx={{ display: 'inline', ml: '10px' }}>
                <IconButton sx={{ p: 0 }} onClick={handleForwardArrow}>
                  <ArrowForwardIosIcon
                    sx={{ color: '#323232', width: '16px' }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TablePurchase;
