import { alereAxios } from '../utils/api';

export const getAllQrCode = (params, id) =>
  alereAxios.get(`purchases/qr-codes/printall/${id}`, {
    params: params,
  });

export const getDetailQrCode = (link, id) =>
  alereAxios.get(`purchases/qr-codes/get-spesific/${link}`, {
    params: {
      idPurchase: id,
    },
  });
