import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  CustomCard2,
  LoadingComponent,
} from '../../../../../../components/shared/shared2';
import CustomInputWithMin from '../../../../../../components/shared/CustomInputWithMin';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeValue,
  getAllRackHubEdit,
} from '../../../../../../store/racks/rackHubEdit';
import CustomCard from '../../../../../../components/shared/CustomCard';
import {
  CloseIcon,
  DeleteIcon,
  KeyboardArrowDownIcon,
} from '../../../../../../assets/Icons';
import PhotoAddComponent from '../../../../../../components/shared/PhotoAddComponent';
import VideoAddComponent from '../../../../../../components/shared/VideoAddComponent';
import BottomMenu from '../../../../../../components/shared/BottomMenu';
import RackMapPosition from '../components/RackMapPosition';
import {
  deleteRackMaster,
  updateSpesifikRack,
} from '../../../../../../api/hubs';
import {
  goBackMainRack,
  goToRackHubDetail,
} from '../../../../../../store/racks/rackHub';

const RackHubEdit = () => {
  const dispatch = useDispatch();
  const {
    idToko,
    subHubId,
    miniHubId,
    idRack,
    name,
    photo,
    video,
    description,
    width,
    height,
    length,
    weight,
    cellPosition,
    row,
    column,
  } = useSelector(getAllRackHubEdit);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOpenEditCard, setIsOpenEditCard] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleSave = async () => {
    const params = {};
    params.idToko = idToko;
    params.subHubId = subHubId;
    params.miniHubId = miniHubId;
    params.name = name;
    params.width = width;
    params.height = height;
    params.length = length;
    params.weight = weight;
    params.description = description;

    if (photo.length !== 0) {
      params['images'] = photo.join(',');
    }
    if (video.length !== 0) {
      params['videos'] = video.join(',');
    }

    try {
      await updateSpesifikRack(idRack, params);
      dispatch(goToRackHubDetail(idRack));
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRackMaster(idRack);
      dispatch(goBackMainRack());
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      {isLoading && <LoadingComponent />}
      {!isLoading && !isError && (
        <Grid container columns={12} columnSpacing={4}>
          <Grid item xs={5}>
            <Box
              sx={{
                bgcolor: '#FAFAFA',
                borderRadius: '12px',
                px: '12px',
                py: '16px',
                overflow: 'auto',
              }}
            >
              <RackMapPosition
                col={column}
                row={row}
                cellPosition={cellPosition}
              />
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Grid container columns={2} rowSpacing={2}>
              {/* //* Code */}
              <Grid item xs={2}>
                <CustomInputWithMin
                  title="Code"
                  value={name}
                  onChange={(e) =>
                    dispatch(
                      changeValue({ key: 'name', value: e.target.value })
                    )
                  }
                  min={5}
                  placeholder="Enter code"
                />
              </Grid>
              {/* //* Wiidht */}
              <Grid item xs={2}>
                <CustomCard
                  sx={{
                    p: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsOpenEditCard(true)}
                >
                  <Box sx={{}}>
                    <Typography>Size</Typography>
                    <Typography sx={{ mt: '4px' }}>
                      {width} cm - {height} cm - {length} cm - {weight} kg
                    </Typography>
                  </Box>
                  <Box>
                    <KeyboardArrowDownIcon />
                  </Box>
                </CustomCard>
              </Grid>
              {/* //* Photo */}
              <Grid item xs={1}>
                <PhotoAddComponent
                  resource={'hubrack'}
                  data={photo}
                  handleAddImage={(link) =>
                    dispatch(
                      changeValue({ key: 'photo', value: [...photo, link] })
                    )
                  }
                  isEdit={true}
                  handleDeleteImage={(link) =>
                    dispatch(
                      changeValue({
                        key: 'photo',
                        value: photo.filter((val) => val !== link),
                      })
                    )
                  }
                />
              </Grid>
              {/* //* Video */}
              <Grid item xs={1}>
                <VideoAddComponent
                  resource={'hubrack'}
                  data={video}
                  handleAddVideo={(link) =>
                    dispatch(
                      changeValue({ key: 'video', value: [...video, link] })
                    )
                  }
                  isEdit={true}
                  handleDeleteVideo={(link) =>
                    dispatch(
                      changeValue({
                        key: 'video',
                        value: video.filter((val) => val !== link),
                      })
                    )
                  }
                />
              </Grid>
              {/* //* Desctiption */}
              <Grid item xs={2}>
                <CustomInputWithMin
                  title="Description"
                  value={description || ''}
                  min={5}
                  onChange={(e) =>
                    dispatch(
                      changeValue({ key: 'description', value: e.target.value })
                    )
                  }
                  placeholder="Enter Description"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <CustomCard2
        title={'Rack Size'}
        open={isOpenEditCard}
        onClose={() => setIsOpenEditCard(false)}
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xs={1}>
            <CustomInputWithMin
              title="Width*"
              value={width}
              type="number"
              min={5}
              onChange={(e) =>
                dispatch(changeValue({ key: 'width', value: e.target.value }))
              }
              isMinSeen={false}
              placeholder="Enter Width"
            />
          </Grid>
          <Grid item xs={1}>
            <CustomInputWithMin
              title="Height*"
              value={height}
              type="number"
              min={5}
              onChange={(e) =>
                dispatch(changeValue({ key: 'height', value: e.target.value }))
              }
              isMinSeen={false}
              placeholder="Enter Height"
            />
          </Grid>
          <Grid item xs={1}>
            <CustomInputWithMin
              title="Length*"
              value={length}
              type="number"
              min={5}
              onChange={(e) =>
                dispatch(changeValue({ key: 'length', value: e.target.value }))
              }
              isMinSeen={false}
              placeholder="Enter Length"
            />
          </Grid>
          <Grid item xs={1}>
            <CustomInputWithMin
              title="Weight*"
              value={weight}
              type="number"
              min={5}
              onChange={(e) =>
                dispatch(changeValue({ key: 'weight', value: e.target.value }))
              }
              isMinSeen={false}
              placeholder="Enter Weight"
            />
          </Grid>
        </Grid>
      </CustomCard2>

      <BottomMenu
        isPublish={false}
        isGreen={true}
        greenText="Save"
        isRed={true}
        redText="Delete"
        handleGreen={handleSave}
        handleRed={() => setConfirmDelete(!confirmDelete)}
      />

      {/* Dialog */}
      {/* delete */}
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(!confirmDelete)}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box
              sx={{
                background: 'rgba(243, 58, 58, 0.1)',
                width: '50px',
                height: '50px',
                borderRadius: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DeleteIcon sx={{ color: '#F33A3A' }} />
            </Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              Delete
            </Typography>
          </Box>
          <CloseIcon
            onClick={() => setConfirmDelete(!confirmDelete)}
            sx={{ cursor: 'pointer' }}
          />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
              Are you sure you want to delete &nbsp;
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              "{name}" ?
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, textAlign: 'center' }}
          >
            you can't undo this action.
          </Typography>

          <Box
            sx={{
              mt: 5,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              sx={{
                color: '#9E9D9D',
                fontSize: '14px',
                fontWeight: 600,
                cursor: 'pointer',
              }}
              onClick={() => setConfirmDelete(!confirmDelete)}
            >
              Cancel
            </Typography>
            <BottomMenu
              isRed={true}
              redText="Delete"
              handleRed={handleDelete}
              sx={{
                position: 'relative',
                padding: 0,
                margin: 0,
                width: '50%',
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default RackHubEdit;
