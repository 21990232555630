import CustomInput from './CustomInput';
import { Box, TextField, Typography, useMediaQuery } from '@mui/material';

const CustomInputWithMin = ({
  title, //? Judul (String)
  isWithTitle = true,
  type = 'string',
  value, //? valu input (Number)
  onChange, //? function kembalian onchange iunput (function (event))
  min = 0, //? Minimal character (Number)
  max = 30, //? Maximal character (Number)
  placeholder = '', //? placholder (string)
  isMinSeen = true, //? true -> tulisan dibawah input tidak akan kelihatan ,false -> kebalikan (Boolean)
  variant,
  ...props
}) => {
  const desktopMode = useMediaQuery('(min-width:832px)');
  return (
    <>
      {desktopMode && (
        <>
          {isWithTitle && (
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              {title}
            </Typography>
          )}
          <CustomInput
            sx={{ width: '100%', bgcolor: '#FAFAFA' }}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={(e) => {
              if (e.target.value.length < max) {
                onChange(e);
              }
            }}
            {...props}
          />
          {isMinSeen && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least {min} character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {value?.length}/{max}
              </Typography>
            </Box>
          )}
          {!desktopMode && (
            <>
              {variant === 'filled' && (
                <TextField
                  id="filled-basic"
                  label={title}
                  variant="filled"
                  sx={{
                    '.MuiFilledInput-root': {
                      bgcolor: '#FAFAFA',
                      height: '54px',
                    },
                    '.MuiInputLabel-root': {
                      color: '#9E9E9E',
                    },
                  }}
                  type={type}
                  value={value}
                  onChange={(e) => {
                    if (e.target.value.length < max) {
                      onChange(e);
                    }
                  }}
                  {...props}
                />
              )}
              {variant !== 'filled' && (
                <CustomInput
                  sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                  placeholder={placeholder}
                  type={type}
                  value={value}
                  onChange={(e) => {
                    if (e.target.value.length < max) {
                      onChange(e);
                    }
                  }}
                  {...props}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default CustomInputWithMin;
