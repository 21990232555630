import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomDialog from '../../../../../components/shared/CustomDialog';
import {
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  CloseIcon,
} from '../../../../../assets/Icons';
import { useEffect } from 'react';
import {
  changeFilterHubs,
  getAllMasterHub,
  getAllMiniHubData,
  getAllSubHubData,
  getDataHubAudit,
  getDataMiniHubAudit,
  getDataSubHubAudit,
} from '../../../../../store/audit/dataTableAudit';
import { useState } from 'react';
import CustomButton from '../../../../../components/shared/CustomButton';

const DialogFilterAudit = ({ isFilter, setIsFilter }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const allDataHubs = useSelector(getDataHubAudit);
  const allDataSubHubs = useSelector(getDataSubHubAudit);
  const allDataMiniHubs = useSelector(getDataMiniHubAudit);
  const [mixData, setMixData] = useState([]);
  const [fixMixData, setFixMixData] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  // ! filter data
  const [hubs, setHubs] = useState();

  useEffect(() => {
    setFixMixData(mixData.flat());
  }, [mixData]);

  useEffect(() => {
    dispatch(getAllMasterHub({ pageSize: 1000 }));
    dispatch(getAllSubHubData({ pageSize: 1000 }));
    dispatch(getAllMiniHubData({ pageSize: 1000 }));
  }, []);

  // ! mix data
  useEffect(() => {
    const mixs = [].concat(allDataHubs, allDataSubHubs, allDataMiniHubs);
    setMixData(mixs);
  }, [allDataHubs, allDataSubHubs, allDataMiniHubs]);

  const handleSaveFilter = () => {
    dispatch(changeFilterHubs(hubs));
    setIsFilter(false);
  };

  const handleResetFilter = () => {
    setHubs();
    dispatch(changeFilterHubs());
    setIsFilter(false);
  };

  return (
    <CustomDialog
      isOpen={isFilter}
      setIsOpen={setIsFilter}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitle
        sx={{
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px 12px 0px 0px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: '#000000',
            }}
            component="span"
          >
            Filter
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setIsFilter(!isFilter)}
            sx={{
              color: '#323232',
              p: 0,
            }}
          >
            <CloseIcon sx={{ fontSize: '1.25rem' }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container columns={12} spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ mt: 3, width: '100%' }}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={mixData}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                onChange={(_, newValue) => {
                  setHubs(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Hub" placeholder="" />
                )}
              />
            </Box>
          </Grid>
        </Grid>

        {/* button event */}
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}
        >
          <CustomButton
            sx={{
              color: '#51B15C',
              fontWeight: 600,
              fontSize: '0.875rem',
              px: 3,
              py: 1,
            }}
            onClick={handleResetFilter}
          >
            Reset
          </CustomButton>
          <CustomButton
            sx={{
              bgcolor: '#51B15C',
              color: '#ffffff',
              fontWeight: 600,
              fontSize: '0.875rem',
              borderRadius: '8px',
              '&:hover': {
                bgcolor: '#51B15C',
                color: '#ffffff',
              },
              px: 4,
              py: 1,
            }}
            onClick={handleSaveFilter}
          >
            Save
          </CustomButton>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
export default DialogFilterAudit;
