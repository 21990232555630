import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from '../../../../../../../store/subsidiary/subsidiary';
import {
  changeIsEdit,
  getDataDetailPurchase,
  getDetailPurchase,
  getIdPurchase,
  getIsEditPurchaseRequest,
  getIsLoadingPurchaseDetail,
} from '../../../../../../../store/purchase/purchaseAdmin/detailRequestPurchase';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import PurchaseRequestEdit from '../PurchaseRequestEdit/PurchaseRequestEdit';
import { goToPurchaseRequestEdit } from '../../../../../../../store/purchase/purchaseAdmin/editPurchaseRequest';
import {
  changePage,
  setNotifPurchase,
} from '../../../../../../../store/Routing/purchaseRouting';
import CustomDeleteConfirm from '../../../../../../../components/shared/CustomDeleteConfirm';
import PurchaseRequestDetailMobile from '../../PurchaseMobile/RequestPurchase/Detail/RequestPurchaseDetailMobile';
import { deletePurchase } from '../../../../../../../api/purchase';

const PurchaseRequestDetails = ({
  setIsDetailRequestPurchase,
  setPurchaseRequest,
}) => {
  const matchTablet = useMediaQuery('(min-width:832px)');
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingPurchaseDetail);
  const isEdit = useSelector(getIsEditPurchaseRequest);
  const idPurchase = useSelector(getIdPurchase);
  const subsidiaryList = useSelector(getSubsidiaryList);
  const dataDetailPurchase = useSelector(getDataDetailPurchase);
  const subsidiaryId = dataDetailPurchase?.subsidiaryId;
  const [isError, setIsError] = useState(false);

  // get data detail purchase request
  useEffect(() => {
    dispatch(getDetailPurchase(idPurchase));
    dispatch(fetchSubsidiaryList());
  }, [idPurchase]);

  // handle button

  // handle delete
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };

  const deletePurchaseFix = async (id) => {
    let params = {
      status: 'reject',
    };
    try {
      await deletePurchase(id, params);
      dispatch(
        setNotifPurchase({
          key: 'deletePurchaseByAdmin',
          text: 'Purchase has been success deleted',
        })
      );
      dispatch(changePage('request'));
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
    }
  };
  const handleDeleteFix = () => {
    deletePurchaseFix(idPurchase);
    setIsDeleteConfirm(false);
  };

  // handle edit
  const handleToEdit = () => {
    dispatch(goToPurchaseRequestEdit(dataDetailPurchase));

    dispatch(changeIsEdit(true));
  };
  return (
    <Box sx={{ mt: 3, pb: '54px' }}>
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Product"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete with ID "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {dataDetailPurchase?.iDpurchase}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      {!isEdit ? (
        !matchTablet ? (
          <PurchaseRequestDetailMobile />
        ) : (
          <>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <BackButtonHub
                onClick={() => {
                  dispatch(changePage('request'));
                }}
              />
              <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                Purchase request detail
              </Typography>
            </Box>

            <Grid container columns={12} spacing={2} sx={{ mt: 2 }}>
              {/* //* Subsidiary */}
              <Grid item xs={6}>
                <Typography
                  sx={{ mb: '8px', fontSize: '16px', fontWeight: 500 }}
                >
                  Subsidiary*
                </Typography>
                {subsidiaryList.length !== 0 && (
                  <FormControl sx={{ fontSize: '14px', fontWeight: 600 }}>
                    <RadioGroup defaultValue={subsidiaryId} row>
                      {subsidiaryList?.map((item, index) => (
                        <FormControlLabel
                          disabled
                          key={index}
                          value={item.id}
                          label={item.name}
                          control={<Radio />}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
              </Grid>

              {/* hub */}
              <Grid item xs={6}>
                <CustomCard sx={{ padding: '16px 14px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ fontSize: '16px', fontWeight: 500 }}>
                      <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                        Hub*
                      </Typography>
                      <Box
                        sx={{
                          color: '#9E9D9D',
                          mt: '4px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            gap: 1,
                            mt: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: '14px' }}>
                            {dataDetailPurchase?.hub?.namaToko}
                          </Typography>
                          <ChevronRightIcon sx={{ fontSize: '14px' }} />
                          <Typography sx={{ fontSize: '14px' }}>
                            {dataDetailPurchase?.subHub?.name}
                          </Typography>
                          {dataDetailPurchase !== '' ? (
                            <>
                              <ChevronRightIcon sx={{ fontSize: '14px' }} />
                              <Typography sx={{ fontSize: '14px' }}>
                                {dataDetailPurchase?.miniHub?.name}
                              </Typography>
                            </>
                          ) : (
                            '_'
                          )}
                        </Box>
                      </Box>
                    </Box>
                    {/* <KeyboardArrowDownIcon /> */}
                  </Box>
                </CustomCard>
              </Grid>

              {/* description */}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                  Description
                </Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {dataDetailPurchase?.keterangan}
                  </Typography>
                </Box>
              </Grid>

              {/* items */}
              <Grid item xs={12} sx={{ mt: 2 }}>
                <CustomCard>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: '20px',
                      boxShadow: '0px 1px 5px #ccc',
                    }}
                  >
                    <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                      Item(s)
                    </Typography>
                  </Box>

                  <Box>
                    <Grid
                      container
                      columns={12}
                      spacing={4}
                      sx={{ p: '30px 20px' }}
                    >
                      {dataDetailPurchase?.details.map((item, index) => (
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                          <Grid container columns={12} gap={4}>
                            {/* product */}
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  mb: 3,
                                }}
                              >
                                Product*
                              </Typography>
                              <Box
                                sx={{
                                  mt: '12px',
                                  width: '100%',
                                  padding: '14px 16px',
                                  bgcolor: '#FAFAFA',
                                  borderRadius: '8px',
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: '14px', fontWeight: 500 }}
                                >
                                  {
                                    dataDetailPurchase?.details[index]?.product
                                      ?.namaProduk
                                  }
                                </Typography>
                              </Box>
                            </Grid>
                            {/* qty */}
                            <Grid item xs={5}>
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    mb: 2,
                                  }}
                                >
                                  Qty*
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    color: '#51B15C',
                                  }}
                                >
                                  ({dataDetailPurchase?.details[index]?.unit})
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  mt: '12px',
                                  width: '100%',
                                  padding: '14px 16px',
                                  bgcolor: '#FAFAFA',
                                  borderRadius: '8px',
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: '14px', fontWeight: 500 }}
                                >
                                  {dataDetailPurchase?.details[index]?.qty}
                                </Typography>
                              </Box>
                            </Grid>

                            {/* hapus item */}
                            <Grid
                              item
                              xs="auto"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 4,
                                color: 'red',
                              }}
                            ></Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </CustomCard>
              </Grid>
            </Grid>

            {/* button event */}
            <Box
              sx={{
                width: '100%',
                bgcolor: 'white',
                position: 'fixed',
                bottom: '0px',
                right: '0px',
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '18px 32px 18px 240px',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                <CustomButton
                  variant="contained"
                  color="error"
                  sx={{ width: '140px', height: '36px' }}
                  onClick={handleDelete}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: '12px',
                      fontWeight: '500',
                    }}
                  >
                    Delete
                  </Typography>
                </CustomButton>
                <CustomButton
                  variant="contained"
                  sx={{ width: '140px', height: '36px' }}
                  onClick={() => handleToEdit()}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: '12px',
                      fontWeight: '500',
                    }}
                  >
                    Edit
                  </Typography>
                </CustomButton>
              </Box>
            </Box>
          </>
        )
      ) : (
        <PurchaseRequestEdit />
      )}
    </Box>
  );
};

export default PurchaseRequestDetails;
