import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import { changePageAudit } from '../../../../../../../store/Routing/auditRouting';
import {
  AddIcon,
  ChevronRightIcon,
  CloseIcons,
} from '../../../../../../../assets/Icons';
import CustomInput from '../../../../../../../components/shared/CustomInput';
import { useRef, useState } from 'react';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import debounce from 'lodash.debounce';
import {
  addNewItemAdjustment,
  getDataSelectedAdjustment,
  getIdDetailAuditToAdjustment,
  getlocationDetailAuditToAdjustment,
  goToAddAdjustment,
  loadNewDataToSelect,
} from '../../../../../../../store/adjustment/addAdjustment';
import CustomSelectProduct from '../Common/CustomSelectproduct';
import { useEffect } from 'react';
import {
  createAdjustment,
  getDifferentProductAdjustment,
} from '../../../../../../../api/adjustment';
import BottomMenu from '../../../../../../../components/shared/BottomMenu';
import {
  changePageAdjustment,
  setNotifAdjusment,
} from '../../../../../../../store/Routing/addjustment';

const AddDifferentAdjustmentMobile = ({ setValue }) => {
  const dispatch = useDispatch();
  const temporaryData = useSelector(getDataSelectedAdjustment);
  const location = useSelector(getlocationDetailAuditToAdjustment);
  const idAudit = useSelector(getIdDetailAuditToAdjustment);
  const [notes, setNotes] = useState('');
  const [tambahItem, setTambahItem] = useState([]);
  // store data to option select
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setTambahItem(temporaryData);
  }, [temporaryData]);

  // validation
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);

  useEffect(() => {
    const cek = tambahItem.find((item, _) => item?.auditQrId === 0);
    if (notes !== '' && notes.length >= 5 && cek === undefined) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [tambahItem, notes]);

  // ! ----------------------- API ------------------------------

  // get data not selected
  useEffect(() => {
    const fetchDifferentAdjust = async () => {
      const id = temporaryData
        .map((item, _) => {
          if (item?.auditQrId !== 0) {
            return item?.auditQrId;
          }
          return null;
        })
        .filter((id) => id !== null);
      const params = {};
      params.audit_qr_id = id;
      params.audit_id = idAudit;
      try {
        const {
          data: { data },
        } = await getDifferentProductAdjustment(params);
        setNewData(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDifferentAdjust();
  }, [temporaryData]);

  // ! -----------------  handler ------------------------

  const handleAddItem = () => {
    dispatch(
      addNewItemAdjustment({
        namaProduk: '',
        idProduk: 0,
        system: 0,
        systemUnit: '',
        hubRackItemId: 0,
        different: 0,
        auditQrId: 0,
        auditId: 0,
        actual: 0,
        actualUnit: '',
        qty: 0,
        unit: '',
      })
    );
  };

  const handleCancelAdjust = () => {
    setValue(6);
    dispatch(changePageAudit('detailsAudits'));
    dispatch(changePageAdjustment(''));
  };

  const handleSubmit = async () => {
    const params = {};
    const newHubRackItemId = tambahItem?.map((item) => item.hubRackItemId);
    const newAuditQrId = tambahItem?.map((item) => item.auditQrId);

    params.hub_rack_item_id = newHubRackItemId;
    params.id_audit_qrs = newAuditQrId;
    params.notes = notes;
    params.idAudit = idAudit;
    setIsLoadingSubmit(true);
    try {
      const data = await createAdjustment(params);
      if (data.status === 200) {
        setIsLoadingSubmit(false);
        dispatch(changePageAdjustment(''));
        dispatch(
          setNotifAdjusment({
            key: 'addAdjustment',
            text: 'Adjustment successfully added',
          })
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            mt: 2,
          }}
        >
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <BackButtonHub onClick={handleCancelAdjust} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  color: '#9E9D9D',
                  fontSize: '14px',
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
              >
                Details
              </Typography>
              <ChevronRightIcon sx={{ width: '14px' }} />
              <Typography
                sx={{
                  color: '#000000',
                  fontSize: '14px',
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
              >
                Add Adjustment
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            gap: 1,
            height: '54px',
            background: '#FAFAFA',
            padding: '0px 16px',
            borderRadius: '8px',
            mt: 2,
          }}
        >
          <Typography
            sx={{ fontSize: '12px', fontWeight: 500, color: '#9E9E9E' }}
          >
            Location
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}>
            {location}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}>
          Condition*
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0, m: 0 }}>
          <Radio checked size="small" sx={{ paddingLeft: '0px' }} />
          <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#000' }}>
            Different
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <CustomInput
          label={'Notes*'}
          variant={'filled'}
          fullWidth
          sx={{
            bgcolor: '#fafafa',
            '& .MuiOutlinedInput-input': {
              padding: '17px 16px',
              borderRadius: '10px',
              '&::placeholder': {
                color: 'red',
                fontSize: '4px',
              },
            },
            borderRadius: '5px',
          }}
          value={notes}
          onChange={(e) => {
            if (e.target.value.length <= 100) {
              setNotes(e.target.value);
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: '10px',
              mt: '5px',
              color: '#9E9D9D',
            }}
          >
            {notes.length > 5 ? '' : 'Enter at least 5 characters'}
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              mt: '5px',
              ml: '15px',
              color: '#9E9D9D',
              fontWeight: 400,
            }}
          >
            {notes.length}/100
          </Typography>
        </Box>
      </Grid>

      {/* items */}
      <Grid item xs={12} sx={{ mt: 2, marginBottom: '56px' }}>
        <CustomCard>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '15px',
              boxShadow: '0px 1px 5px #ccc',
            }}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
              Item(s)
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                color: '#51B15C',
                gap: 1,
                cursor: 'pointer',
              }}
              onClick={handleAddItem}
            >
              <AddIcon />
              Add more
            </Typography>
          </Box>

          <Box>
            <Grid container columns={12} spacing={2} sx={{ p: '15px 10px' }}>
              {tambahItem.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Grid container columns={12} spacing={2}>
                    {/* product */}
                    <Grid item xs={10}>
                      <CustomSelectProduct
                        newData={newData}
                        defaultValue={item}
                        handleValue={(dataUpdate) => {
                          const memo = tambahItem.map((item, idx) => {
                            if (index === idx) {
                              return {
                                ...item,
                                ...dataUpdate,
                              };
                            } else {
                              return item;
                            }
                          });
                          setTambahItem(memo);
                          dispatch(goToAddAdjustment(memo));
                        }}
                      />
                    </Grid>
                    {/* hapus item */}
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        // mt: 4,
                        color: 'red',
                      }}
                    >
                      {tambahItem.length === 1 ? (
                        <></>
                      ) : (
                        <CloseIcons
                          sx={{
                            cursor: 'pointer',
                            width: '20px',
                          }}
                          onClick={() => {
                            const newItem = [...temporaryData];
                            newItem.splice(index, 1);
                            dispatch(goToAddAdjustment(newItem));
                          }}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                          justifyContent: 'space-between',
                          borderBottom: '1px solid #E4E4E4',
                          pb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            background: '#FAFAFA',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            width: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: 500,
                              color: '#9E9E9E',
                            }}
                          >
                            System
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              color: '#000000',
                              mt: 0.5,
                            }}
                          >
                            {item?.system || 0} {item?.systemUnit || 'unit'}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            background: '#FAFAFA',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            width: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: 500,
                              color: '#9E9E9E',
                            }}
                          >
                            Actual
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              color: '#000000',
                              mt: 0.5,
                            }}
                          >
                            {item?.actual || 0} {item?.actualUnit || 'unit'}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            background: '#FAFAFA',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            width: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: 500,
                              color: '#9E9E9E',
                            }}
                          >
                            Difference
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              color: '#000000',
                              mt: 0.5,
                            }}
                          >
                            {item?.difference || 0} {item?.systemUnit || 'unit'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CustomCard>
      </Grid>

      {/* button event */}

      <BottomMenu
        handleGreen={handleSubmit}
        disableGreen={!allowSubmit}
        isGreen={true}
        greenText={
          isLoadingSubmit ? (
            <CircularProgress size={20} sx={{ color: '#FFFFFF' }} />
          ) : (
            'Submit'
          )
        }
      />
    </Grid>
  );
};
export default AddDifferentAdjustmentMobile;
