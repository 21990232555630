import { Box } from '@mui/material';
import InventoryAdjusment from '..';
import { useSelector } from 'react-redux';
import { getAllAdjustmentRouting } from '../../../../../../store/Routing/addjustment';
import NotifAdjustment from './NotifAdjustment';
import DetailAdjustment from '../Detail/DetailAdjustment';
import AddDifferenceAdjustment from '../Add/AddDifferenceAdjustment';
import AddMissingQrAdjustment from '../Add/AddMissingQrAdjustment';

const RoutingAdjustment = ({ setValue }) => {
  const { page } = useSelector(getAllAdjustmentRouting);
  return (
    <Box>
      <Box>
        <NotifAdjustment />
      </Box>
      {page === '' && <InventoryAdjusment />}
      {page === 'detailAdjustment' && <DetailAdjustment />}
      {page === 'addDifferenceAdjustment' && (
        <AddDifferenceAdjustment {...{ setValue }} />
      )}
      {page === 'addMissingQrAdjustment' && (
        <AddMissingQrAdjustment {...{ setValue }} />
      )}
    </Box>
  ); 
};
export default RoutingAdjustment;
